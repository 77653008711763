import {LitElement, css} from 'lit';
import { html } from "lit/html.js"

import { SbAdmin2Styles } from "../../css/sb-admin-2-styles.js";
import { FontawesomeIcons } from "../../css/fontawesome-icons.js";

import "@polymer/paper-progress";

class BsModal extends LitElement {
    static get styles() {
        return [
            SbAdmin2Styles,
            FontawesomeIcons,
            css`
                paper-progress {
                    width: 100%;
                    --paper-progress-container-color: #fff;
                }
            `,
        ];
    }

    static get properties() {
        return {
            large: { type: Boolean },
            locked: { type: Boolean },
            modalTitle: { type: String },
            extraLarge: { type: Boolean },
            extraWidth: { type: Boolean },
            progressActive: { type: Boolean },
            size: { type: String },
        };
    }

    _renderCloseButton() {
        if (!this.locked) {
            return html`
                <button class="close" type="button" @click="${this.close}">
                    <span>×</span>
                </button>
            `;
        }
    }

    render() {
        return html`
            <div
                class="modal fade"
                tabindex="-1"
                role="dialog"
                style="overflow: auto;"
            >
                <div
                    class="modal-dialog ${this.large ? "modal-lg" : ""} ${this
                        .extraWidth
                        ? "modal-width-large"
                        : ""} ${this.extraLarge ? "modal-xl" : ""}"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">${this.modalTitle}</h5>
                            ${this._renderCloseButton()}
                        </div>
                        <paper-progress
                            indeterminate
                            ?disabled="${!this.progressActive}"
                        ></paper-progress>
                        <div class="modal-body">
                            <slot name="modal-body"></slot>
                        </div>
                        <div class="modal-footer">
                            <slot name="modal-footer"></slot>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    constructor() {
        super();
        this.large = false;
        this.locked = false;
        this.modalTitle = "";
        this.extraLarge = false;
        this.extraWidth = false;
        this.progressActive = false;
    }

    show() {
        const modal = this.shadowRoot.querySelector(".modal");
        modal.classList.toggle("show");
        if (modal.classList.contains("show")) {
            modal.style.display = "block";
            modal.style.paddingRight = "17px";
            const div = document.createElement("div");
            div.classList.add("modal-backdrop", "fade", "show");
            document.querySelector("body").append(div);

            const modalFooterSlot = this.shadowRoot.querySelector(
                    'slot[name="modal-footer"]'
                ),
                modalFooterDiv = this.shadowRoot.querySelector(".modal-footer");
            if (modalFooterSlot.assignedNodes().length > 0) {
                modalFooterDiv.style.display = "";
            } else {
                modalFooterDiv.style.display = "none";
            }
        }
    }

    close() {
        if (!this.locked) {
            const modal = this.shadowRoot.querySelector(".modal");
            modal.classList.toggle("show");
            if (!modal.classList.contains("show")) {
                modal.style.display = "none";
                modal.style.paddingRight = 0;
                document.querySelector("div.modal-backdrop").remove();
                this.dispatchEvent(new Event("closed"));
            }
        }
    }
}

window.customElements.define("bs-modal", BsModal);
