import {
    FETCH_RESET_MINDESTLOHNERKLAERUNGEN_ARCHIV_SUCCESS,
    FETCH_MINDESTLOHNERKLAERUNGEN_ARCHIV_ACTION_BEGIN,
    FETCH_MINDESTLOHNERKLAERUNGEN_ARCHIV_ACTION_FAIL,
    FETCH_MINDESTLOHNERKLAERUNGEN_ARCHIV_SUCCESS,
    FETCH_RESET_EUFEFA_ARCHIV_SUCCESS,
    FETCH_EUFEFA_ARCHIV_ACTION_BEGIN,
    FETCH_EUFEFA_ARCHIV_ACTION_FAIL,
    FETCH_EUFEFA_ARCHIV_SUCCESS,
    FETCH_RESET_EUFEMA_ARCHIV_SUCCESS,
    FETCH_EUFEMA_ARCHIV_ACTION_BEGIN,
    FETCH_EUFEMA_ARCHIV_ACTION_FAIL,
    FETCH_EUFEMA_ARCHIV_SUCCESS,
    MINDESTLOHNERKLAERUNG_WIEDERHERSTELLEN_ACTION_BEGIN,
    MINDESTLOHNERKLAERUNG_WIEDERHERSTELLEN_ACTION_FAIL,
    MINDESTLOHNERKLAERUNG_WIEDERHERSTELLEN_SUCCESS,
    EUFEFA_WIEDERHERSTELLEN_ACTION_BEGIN,
    EUFEFA_WIEDERHERSTELLEN_ACTION_FAIL,
    EUFEFA_WIEDERHERSTELLEN_SUCCESS,
    EUFEMA_WIEDERHERSTELLEN_ACTION_BEGIN,
    EUFEMA_WIEDERHERSTELLEN_ACTION_FAIL,
    EUFEMA_WIEDERHERSTELLEN_SUCCESS,
} from "../../actions/zuko/archiv.js";

const archivState = {
    error: null,
    mindestlohnerklaerungArchivIstLoading: false,
    mindestlohnerklaerungArchiv: {},
    eufefaArchivIstLoading: false,
    eufefaArchiv: {},
    eufemaArchivIstLoading: false,
    eufemaArchiv: {},
};
const savedState =
    localStorage.getItem("savedState") || JSON.stringify({ ...archivState });
const INITIAL_STATE = JSON.parse(savedState).archivState || archivState;

const archiv = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_RESET_MINDESTLOHNERKLAERUNGEN_ARCHIV_SUCCESS:
        case MINDESTLOHNERKLAERUNG_WIEDERHERSTELLEN_ACTION_FAIL:
        case FETCH_MINDESTLOHNERKLAERUNGEN_ARCHIV_ACTION_FAIL:
            return {
                ...state,
                error: null,
                mindestlohnerklaerungArchivIstLoading: false,
                mindestlohnerklaerungArchiv: {},
            };
        case MINDESTLOHNERKLAERUNG_WIEDERHERSTELLEN_ACTION_BEGIN:
        case FETCH_MINDESTLOHNERKLAERUNGEN_ARCHIV_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                mindestlohnerklaerungArchivIstLoading: true,
                mindestlohnerklaerungArchiv: {},
            };
        case MINDESTLOHNERKLAERUNG_WIEDERHERSTELLEN_SUCCESS:
        case FETCH_MINDESTLOHNERKLAERUNGEN_ARCHIV_SUCCESS:
            return {
                ...state,
                error: null,
                mindestlohnerklaerungArchivIstLoading: false,
                mindestlohnerklaerungArchiv: action.payload,
            };
        case FETCH_RESET_EUFEFA_ARCHIV_SUCCESS:
        case EUFEFA_WIEDERHERSTELLEN_ACTION_FAIL:
        case FETCH_EUFEFA_ARCHIV_ACTION_FAIL:
            return {
                ...state,
                error: null,
                eufefaArchivIstLoading: false,
                eufefaArchiv: {},
            };
        case EUFEFA_WIEDERHERSTELLEN_ACTION_BEGIN:
        case FETCH_EUFEFA_ARCHIV_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                eufefaArchivIstLoading: true,
                eufefaArchiv: {},
            };
        case EUFEFA_WIEDERHERSTELLEN_SUCCESS:
        case FETCH_EUFEFA_ARCHIV_SUCCESS:
            return {
                ...state,
                error: null,
                eufefaArchivIstLoading: false,
                eufefaArchiv: action.payload,
            };
        case FETCH_RESET_EUFEMA_ARCHIV_SUCCESS:
        case EUFEMA_WIEDERHERSTELLEN_ACTION_FAIL:
        case FETCH_EUFEMA_ARCHIV_ACTION_FAIL:
            return {
                ...state,
                error: null,
                eufemaArchivIstLoading: false,
                eufemaArchiv: {},
            };
        case EUFEMA_WIEDERHERSTELLEN_ACTION_BEGIN:
        case FETCH_EUFEMA_ARCHIV_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                eufemaArchivIstLoading: true,
                eufemaArchiv: {},
            };
        case EUFEMA_WIEDERHERSTELLEN_SUCCESS:
        case FETCH_EUFEMA_ARCHIV_SUCCESS:
            return {
                ...state,
                error: null,
                eufemaArchivIstLoading: false,
                eufemaArchiv: action.payload,
            };
        default:
            return state;
    }
};
export default archiv;
