import { css } from "lit";

export const AlgoStyles = css`
    body {
        margin: 0;
        font-family: Roboto, "Nunito", -apple-system, BlinkMacSystemFont,
            "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
            "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #858796;
        text-align: left;
        background-color: #fff;
    }

    .administration {
        float: right;
        width: 33%;
        padding-left: 20px;
        margin-bottom: 20px;
    }

    .de-DE {
        content: url(/images/german.png);
        width: 25px;
        height: 25px;
        border-radius: 50%;
    }

    .en-EN {
        content: url(/images/england.png);
        width: 25px;
        height: 25px;
        border-radius: 50%;
    }

    .selected-language {
        height: 30px !important;
        width: 30px !important;
    }

    .dashboard {
        float: left;
        width: 67%;
        box-shadow: inset #858796 -3px 0px 0px 0px;
        padding-right: 23px;
        min-height: 80vh;
        margin-bottom: 20px;
    }

    .text-color {
        color: #6e707eff !important;
    }

    .btn-icon-split {
        display: block;
    }

    .btn-icon-split .icon {
        float: left !important;
        width: 44px !important;
    }

    .view {
        display: none;
    }

    .view[active] {
        display: block;
    }

    .page {
        display: none;
    }

    .page[active] {
        display: block;
    }

    bs-card {
        width: 100%;
    }

    .tooltip {
        font-family: Roboto;
    }

    .popover {
        font-family: Roboto;
    }

    .item-header-text-color {
        color: rgb(245, 187, 0) !important;
        padding-left: 30px !important;
    }

    .nav-icons {
        height: 24px;
    }

    .text-primary {
        color: #4e73df;
    }

    .page-link {
        color: #414142 !important;
    }

    .action-btn {
        color: #414142 !important;
    }

    .page-link:hover {
        color: #414142 !important;
        background-color: #c8c8c8;
    }

    .page-link:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    }

    .page-item.active .page-link {
        background-color: #414142;
        border-color: #414142;
        text-decoration: none;
    }

    a.no-arrow::after {
        display: none !important;
    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #f8f9fc;
    }

    ::-webkit-scrollbar-thumb {
        background: #aaaaaa;
    }

    ::-webkit-scrollbar-button:single-button {
        background-color: #bbbbbb;
        display: block;
        border-style: solid;
        border-width: 1px;
    }

    ::-webkit-scrollbar-button:horizontal:end:increment {
        background: linear-gradient(210deg, #aaaaaa 40%, rgba(0, 0, 0, 0) 41%),
            linear-gradient(330deg, #aaaaaa 40%, rgba(0, 0, 0, 0) 41%),
            linear-gradient(90deg, #aaaaaa 30%, rgba(0, 0, 0, 0) 31%);
        background-color: #f8f9fc;
    }

    ::-webkit-scrollbar-button:horizontal:start:decrement {
        background: linear-gradient(30deg, #aaaaaa 40%, rgba(0, 0, 0, 0) 41%),
            linear-gradient(150deg, #aaaaaa 40%, rgba(0, 0, 0, 0) 41%),
            linear-gradient(270deg, #aaaaaa 30%, rgba(0, 0, 0, 0) 31%);
        background-color: #f8f9fc;
    }

    .content-center {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translate(0%, -50%);
        transform: translate(0%, -50%);
        width: 100%;
    }

    a.no-arrow::after {
        display: none !important;
    }
    .identity-container {
        height: 600px;
    }

    .identity-center {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-width: 1400px;
    }

    a {
        color: #fff;
        cursor: pointer;
    }

    a.nav-link {
        color: #6e707e !important;
    }

    .action-container {
        justify-content: space-evenly;
        display: flex;
    }
    .edit,
    .copy,
    .navigation,
    .update,
    .sperren,
    .show-file,
    .download,
    .download-vorlage,
    .pruefen,
    .entpruefen,
    .sperren,
    .entsperren,
    .print,
    .upload,
    .send-mail {
        color: rgb(64, 64, 64) !important;
    }
    .actions {
        pointer-events: all;
        padding-right: 2.5px;
        padding-left: 2.5px;
    }

    .erstellen-icon::before {
        background-color: #939393 !important;
        color: rgb(64, 64, 64) !important;
        border-radius: 50% !important;
        border: 1px solid #939393 !important;
    }

    a[disabled] {
        color: gray !important;
        opacity: 0.65 !important;
        cursor: not-allowed !important;
        pointer-events: none !important;
    }
    .dt-down-arrow {
        visibility: hidden;
    }

    .actions > i {
        font-size: 21px;
    }

    a:not([href]):not([tabindex]) {
        color: #fff;
        text-decoration: none;
    }

    a:not([href]):not([tabindex]):hover,
    a:not([href]):not([tabindex]):focus {
        color: #fff;
        text-decoration: none;
    }
    .tool-tip[disabled],
    .tool-tip:disabled {
        color: gray !important;
        opacity: 0.65 !important;
        cursor: not-allowed !important;
    }

    a.btn[disabled] {
        background-color: gray;
        border-color: gray;
    }

    .sidebar-dark .nav-item .nav-link {
        color: #a2a2a2 !important;
    }

    .sidebar-dark .nav-item .nav-link i {
        color: #a2a2a2;
    }

    .sidebar-dark .nav-item.active .nav-link {
        color: #fff !important;
    }

    .sidebar-dark .nav-item .nav-link:hover {
        color: #fff !important;
    }

    .sidebar-dark .nav-item.active .nav-link i {
        color: #fff !important;
    }

    .bg-algo-dark {
        background-color: #414142;
    }

    .bg-algo-medium {
        background-color: #585656;
    }

    .bg-algo-light {
        background-color: #c8c8c8;
    }

    .bg-gradient-primary {
        background-color: rgb(64, 64, 64);
        background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(10%, #424241),
            to(#222222)
        );
        background-image: linear-gradient(
            180deg,
            rgb(64, 64, 64) 10%,
            #222222 100%
        );
        background-size: cover;
    }

    .dropdown-item:active,
    .dropdown-ausweis-item:active {
        background-color: #c8c8c8;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #585656;
        background-color: #585656;
    }

    .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: #c8c8c8 0px 0px 0px 0.2rem;
    }

    .custom-checkbox
        .custom-control-input:disabled:checked
        ~ .custom-control-label::before {
        background-color: rgba(78, 78, 78, 0.5);
    }

    .custom-checkbox
        .custom-control-input:indeterminate
        ~ .custom-control-label::before {
        border-color: #585656;
        background-color: #585656;
    }

    .custom-select:focus {
        box-shadow: #c8c8c8 0px 0px 0px 0.2rem;
        border-color: #c8c8c8;
    }

    button:focus {
        outline: 0;
    }

    .rounded-circle {
        border: 1px solid #cccccc;
    }

    .sidebar .nav-item .nav-link {
        padding: 0 1rem 0.75rem 1rem;
    }

    .sidebar hr.sidebar-divider {
        margin: 0 1rem 0.75rem 1rem;
    }

    .sidebar-heading {
        margin-bottom: 0.75rem;
    }

    .sidebar.toggled .nav-item .nav-link {
        padding: 0 1rem 0.75rem 1rem !important;
    }

    label.col-sm-4 {
        text-align: right !important;
    }

    .asd-sub-col-label {
        font-size: small !important;
        font-weight: bold !important;
        padding-left: 20px !important;
    }
    .asd-sub-sub-col-label {
        font-size: small !important;
        font-weight: bold !important;
        padding-left: 82px !important;
    }

    .asd-sub-sub-sub-col-label {
        font-size: small !important;
        font-weight: bold !important;
        padding-left: 144px !important;
    }
    .asd-sub-sub-sub-sub-col-label {
        font-size: small !important;
        font-weight: bold !important;
        padding-left: 205px !important;
    }
    .asd-sub-col-radio {
        padding-left: 10px !important;
    }
    .asd-sub-sub-col-radio {
        padding-left: 82px !important;
    }

    .asd-sub-sub-sub-col-radio {
        padding-left: 144px !important;
    }
    .asd-sub-sub-sub-sub-col-radio {
        padding-left: 205px !important;
    }

    label.asd-col-link {
        color: #858796ff !important;
        font-size: small !important;
        font-weight: bold !important;
        padding-left: 5px !important;
        text-decoration: underline !important;
    }
    label.asd-helper-text-title {
        font-size: 16px !important;
        color: #858796ff !important;
        font-weight: bold !important;
    }
    .form-control.number {
        float: right;
        width: 75px;
    }

    .fading-hr-70 {
        margin: 26px 0px;
        height: 1px;
        border: 0px;
        background: -webkit-gradient(
            linear,
            0 0,
            70% 0,
            from(rgb(255, 255, 255)),
            to(rgb(255, 255, 255)),
            color-stop(0.5, rgb(194, 194, 194))
        );
    }

    .fading-hr {
        margin: 25px 0;
        height: 1px;
        border: 0;
        background: black;
        background: -webkit-gradient(
            linear,
            0 0,
            100% 0,
            from(#ffffff),
            to(#ffffff),
            color-stop(50%, #c2c2c2)
        );
    }

    .fading-hr-projekt {
        margin: 25px 0;
        height: 1px;
        border: 0;
        background: black;
        background: -webkit-gradient(
            linear,
            0 0,
            100% 0,
            from(#ffffff),
            to(#ffffff),
            color-stop(50%, #823d8f)
        );
    }

    .fading-hr-zuko {
        margin: 25px 0;
        height: 1px;
        border: 0;
        background: black;
        background: -webkit-gradient(
            linear,
            0 0,
            100% 0,
            from(#ffffff),
            to(#ffffff),
            color-stop(50%, #d34a4a)
        );
    }

    label.col-sm-4 {
        font-weight: bold;
    }

    @media (min-width: 900px) {
        .sidebar .nav-item .nav-link {
            padding: 0 1rem 0.75rem 1rem;
        }
        .sidebar.toggled .nav-item .nav-link {
            padding: 0 1rem 0.75rem 1rem;
        }
    }

    /* cyrillic-ext */
    /* cyrillic-ext */
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        src: local("Roboto"), local("Roboto-Regular"),
            url(../css/fonts/roboto/KFOmCnqEu92Fr1Mu72xKOzY.woff2)
                format("woff2");
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF,
            U+A640-A69F, U+FE2E-FE2F;
    }
    /* cyrillic */
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        src: local("Roboto"), local("Roboto-Regular"),
            url(../css/fonts/roboto/KFOmCnqEu92Fr1Mu5mxKOzY.woff2)
                format("woff2");
        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }
    /* greek-ext */
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        src: local("Roboto"), local("Roboto-Regular"),
            url(../css/fonts/roboto/KFOmCnqEu92Fr1Mu7mxKOzY.woff2)
                format("woff2");
        unicode-range: U+1F00-1FFF;
    }
    /* greek */
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        src: local("Roboto"), local("Roboto-Regular"),
            url(../css/fonts/roboto/KFOmCnqEu92Fr1Mu4WxKOzY.woff2)
                format("woff2");
        unicode-range: U+0370-03FF;
    }
    /* vietnamese */
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        src: local("Roboto"), local("Roboto-Regular"),
            url(../css/fonts/roboto/KFOmCnqEu92Fr1Mu7WxKOzY.woff2)
                format("woff2");
        unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
    }
    /* latin-ext */
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        src: local("Roboto"), local("Roboto-Regular"),
            url(../css/fonts/roboto/KFOmCnqEu92Fr1Mu7GxKOzY.woff2)
                format("woff2");
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
            U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        src: local("Roboto"), local("Roboto-Regular"),
            url(../css/fonts/roboto/KFOmCnqEu92Fr1Mu4mxK.woff2) format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
            U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* cyrillic-ext */
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        src: local("Roboto Bold"), local("Roboto-Bold"),
            url(../css/fonts/roboto/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2)
                format("woff2");
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF,
            U+A640-A69F, U+FE2E-FE2F;
    }
    /* cyrillic */
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        src: local("Roboto Bold"), local("Roboto-Bold"),
            url(../css/fonts/roboto/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2)
                format("woff2");
        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }
    /* greek-ext */
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        src: local("Roboto Bold"), local("Roboto-Bold"),
            url(../css/fonts/roboto/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2)
                format("woff2");
        unicode-range: U+1F00-1FFF;
    }
    /* greek */
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        src: local("Roboto Bold"), local("Roboto-Bold"),
            url(../css/fonts/roboto/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2)
                format("woff2");
        unicode-range: U+0370-03FF;
    }
    /* vietnamese */
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        src: local("Roboto Bold"), local("Roboto-Bold"),
            url(../css/fonts/roboto/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2)
                format("woff2");
        unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
    }
    /* latin-ext */
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        src: local("Roboto Bold"), local("Roboto-Bold"),
            url(../css/fonts/roboto/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2)
                format("woff2");
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
            U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        src: local("Roboto Bold"), local("Roboto-Bold"),
            url(../css/fonts/roboto/KFOlCnqEu92Fr1MmWUlfBBc4.woff2)
                format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
            U+2212, U+2215, U+FEFF, U+FFFD;
    }
`;
