import {
    DASHBOARD_EINSTELLUNGEN_BEGIN,
    FETCH_EINSTELLUNGEN_RESET_SUCCESS,
    DASHBOARD_EINSTELLUNGEN_ACTION_FAIL,
    FETCH_DASHBOARD_EINSTELLUNGEN_SUCCCES,
    ERSTELLE_DASHBOARD_EINSTELLUNGEN_SUCCCES,
    AKTUALISIERE_DASHBOARD_EINSTELLUNGEN_SUCCCES,
    LOESCHE_DASHBOARD_EINSTELLUNGEN_SUCCCES,
} from "../../actions/dashboard/einstellungen";

const einstellungenState = {
    error: null,
    loading: false,
    projektbeteiligte: {},
};

const savedState =
    localStorage.getItem("savedState") ||
    JSON.stringify({ ...einstellungenState });
const INITIAL_STATE =
    JSON.parse(savedState).meineProjekteState || einstellungenState;

const dashboardEinstellungen = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_EINSTELLUNGEN_RESET_SUCCESS:
            return einstellungenState;
        case DASHBOARD_EINSTELLUNGEN_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case DASHBOARD_EINSTELLUNGEN_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FETCH_DASHBOARD_EINSTELLUNGEN_SUCCCES:
            return {
                ...state,
                error: null,
                loading: false,
                projektbeteiligte: action.payload,
            };
        case ERSTELLE_DASHBOARD_EINSTELLUNGEN_SUCCCES:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case AKTUALISIERE_DASHBOARD_EINSTELLUNGEN_SUCCCES:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case LOESCHE_DASHBOARD_EINSTELLUNGEN_SUCCCES:
            return {
                ...state,
                error: null,
                loading: false,
            };
        default:
            return state;
    }
};

export default dashboardEinstellungen;
