import {
    FIRMEN_ACTION_FAIL,
    FIRMEN_ACTION_BEGIN,
    FETCH_FIRMEN_SUCCESS,
    RESET_FIRMEN_SUCCESS,
    FETCH_FIRMEN_AUSWERTUNGEN_ACTION_BEGIN,
    FETCH_FIRMEN_AUSWERTUNGEN_ACTION_FAIL,
    FETCH_FIRMEN_AUSWERTUNGEN_SUCCESS,
    RESET_FIRMEN_AUSWERTUNGEN_SUCCESS,
    FETCH_FIRMENHIERARCHIE_SUCCESS,
    RESET_FIRMENHIERARCHIE_SUCCESS,
    FETCH_FIRMENHIERARCHIE_NETZWERK_SUCCESS,
    FETCH_FIRMENHIERACHIE_ACTION_BEGIN,
    FETCH_FIRMENHIERACHIE_ACTION_FAIL
} from "../../actions/zuko/firmen.js";

const firmenState = {
    firmen: [],
    error: null,
    loading: false,
    auswertungen: [],
    istFirmenZutrittsereignisseAuswertungenLoading: false,
    istFirmenhierachieLoading: false,
    firmenhierarchie: [],
};

const savedState =
    localStorage.getItem("savedState") || JSON.stringify({ ...firmenState });
const INITIAL_STATE = JSON.parse(savedState).firmenState || firmenState;

const firmen = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_FIRMENHIERARCHIE_SUCCESS:
            return {
                ...state,
                error: null,
                istFirmenhierachieLoading: false,
                firmenhierarchie: [],
            };
        case FIRMEN_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case FIRMEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FETCH_FIRMEN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                firmen: action.payload,
            };
        case RESET_FIRMEN_SUCCESS:
            return {
                ...state,
                firmen: [],
                error: null,
            };
            case FETCH_FIRMEN_AUSWERTUNGEN_ACTION_FAIL:
                return {
                    ...state,
                    istFirmenZutrittsereignisseAuswertungenLoading: false,
                    error: action.error,
                };
            case FETCH_FIRMEN_AUSWERTUNGEN_ACTION_BEGIN:
                return {
                    ...state,
                    error: null,
                    istFirmenZutrittsereignisseAuswertungenLoading: true,
                };
        case FETCH_FIRMEN_AUSWERTUNGEN_SUCCESS:
            return {
                ...state,
                error: null,
                istFirmenZutrittsereignisseAuswertungenLoading: false,
                auswertungen: action.payload,
            };
        case RESET_FIRMEN_AUSWERTUNGEN_SUCCESS:
            return {
                ...state,
                error: null,
                istFirmenZutrittsereignisseAuswertungenLoading: false,
                auswertungen: [],
            };
        case FETCH_FIRMENHIERACHIE_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                istFirmenhierachieLoading: true,
            };
        case FETCH_FIRMENHIERACHIE_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                firmenhierarchie: [],
                istFirmenhierachieLoading: false,
            };
        case FETCH_FIRMENHIERARCHIE_SUCCESS:
            return {
                ...state,
                error: null,
                firmenhierarchie: action.payload,
                istFirmenhierachieLoading: false,
            };
        case FETCH_FIRMENHIERARCHIE_NETZWERK_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                firmennetzwerk: action.payload,
            };
        case RESET_FIRMENHIERARCHIE_SUCCESS:
            return {
                ...state,
                error: null,
                firmenhierarchie: [],
            };
        default:
            return state;
    }
};

export default firmen;
