import {
    PROJEKTROLLE_ACTION_FAIL,
    PROJEKTROLLE_ACTION_BEGIN,
    FETCH_PROJEKTROLLE_SUCCESS,
    RESET_PROJEKTROLLE_SUCCESS,
    LOESCHE_PROJEKTROLLE_COMMAND_SUCCESS,
    AKTUALISIERE_PROJEKTROLLE_COMMAND_SUCCESS
} from '../../actions/projekt/projektrolle.js';

const projektrolleState = {
    error: null,
    loading: false,
    projektrolle: {},
};

const savedState = localStorage.getItem('savedState') || JSON.stringify({ ...projektrolleState });
const INITIAL_STATE = JSON.parse(savedState).projektrolleState || projektrolleState;

const projektrolle = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PROJEKTROLLE_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case PROJEKTROLLE_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FETCH_PROJEKTROLLE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                projektrolle: action.payload
            };
        case RESET_PROJEKTROLLE_SUCCESS:
        case LOESCHE_PROJEKTROLLE_COMMAND_SUCCESS:
            return projektrolleState;
        case AKTUALISIERE_PROJEKTROLLE_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                projektrolle: action.payload
            };
        default:
            return state;
    }
};

export default projektrolle;