export const ALGO_APP_ACTION_FAIL = 'ALGO_APP_ACTION_FAIL';
export const ALGO_APP_ACTION_BEGIN = 'ALGO_APP_ACTION_BEGIN';
export const ALGO_APP_NAVIGATE_SUCCESS = 'ALGO_APP_NAVIGATE_SUCCESS';
export const ALGO_APP_NAVIGATE_BACK = 'ALGO_APP_NAVIGATE_BACK';
export const ALGO_APP_SHOW_TOAST_SUCCESS = 'ALGO_APP_SHOW_TOAST_SUCCESS';
export const ALGO_APP_RESET_TOAST_SUCCESS = 'ALGO_APP_RESET_TOAST_SUCCESS';
export const ALGO_APP_TOGGLE_SIDEBAR_SUCCESS = 'ALGO_APP_TOGGLE_SIDEBAR_SUCCESS';
export const ALGO_APP_SET_PROJEKT_ID_SUCCESS = 'ALGO_APP_SET_PROJEKT_ID_SUCCESS';

const appInsights = window.Algo.appInsights;

export const resetToast = () => (dispatch) => {
    dispatch({ type: ALGO_APP_RESET_TOAST_SUCCESS });
};

export const navigate = (data, type = ALGO_APP_NAVIGATE_SUCCESS) => (dispatch) => {
    window.onbeforeunload = null;
    appInsights.startTrackPage(data.view);
    if(type !== ALGO_APP_NAVIGATE_BACK) {
        window.history.pushState(data, 'Algo');
    }

    dispatch({ type: ALGO_APP_NAVIGATE_SUCCESS, payload: data });
    appInsights.stopTrackPage(data.view);
};

export const toggleSidebar = () => (dispatch) => {
    dispatch({ type: ALGO_APP_TOGGLE_SIDEBAR_SUCCESS });
    appInsights.trackEvent({ name: 'toggleSidebar' });
};

export const showToast = (data) => (dispatch) => {
    dispatch({ type: ALGO_APP_SHOW_TOAST_SUCCESS, payload: data });
    appInsights.trackEvent({ name: 'showToast' });
};

export const setProjektId = (projektId) => (dispatch) => {
    dispatch({ type: ALGO_APP_SET_PROJEKT_ID_SUCCESS, payload: projektId });
    appInsights.trackEvent({ name: 'setProjektId' });
};