import {
    PROJEKT_ACTION_FAIL,
    PROJEKT_ACTION_BEGIN,
    FETCH_PROJEKT_SUCCESS,
    RESET_PROJEKT_SUCCESS,
    POST_PROJEKT_ERSTELLEN_SUCCESS,
    FETCH_PROJEKT_KONFIGURATION_ACTION_BEGIN,
    FETCH_PROJEKT_KONFIGURATION_ACTION_FAIL,
    FETCH_PROJEKT_KONFIGURATION_ACTION_SUCCESS,
    RESET_PROJEKT_STAMMDATEN_SUCCESS,
} from "../../actions/projekt/projekt.js";

const projektState = {
    projekt: {},
    error: null,
    loading: false,
    projektKonfigurationIstLoading: false,
    projektKonfiguration: {},
};

const savedState =
    localStorage.getItem("savedState") || JSON.stringify({ ...projektState });
const INITIAL_STATE = JSON.parse(savedState).projektState || projektState;

const projekt = (state = INITIAL_STATE, action) => {
    switch (action?.type) {
        case PROJEKT_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case PROJEKT_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FETCH_PROJEKT_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                projekt: action.payload,
            };
        case RESET_PROJEKT_SUCCESS:
            return {
                ...state,
                error: null,
                projekt: {},
                loading: false,
            };
        case POST_PROJEKT_ERSTELLEN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case FETCH_PROJEKT_KONFIGURATION_ACTION_BEGIN: {
            return {
                ...state,
                error: null,
                projektKonfigurationIstLoading: true,
            };
        }
        case FETCH_PROJEKT_KONFIGURATION_ACTION_FAIL:
            return {
                ...state,
                error: action.payload,
                projektKonfigurationIstLoading: false,
            };

        case FETCH_PROJEKT_KONFIGURATION_ACTION_SUCCESS:
            return {
                ...state,
                error: null,
                projektKonfigurationIstLoading: false,
                projektKonfiguration: action.payload
            };
        case RESET_PROJEKT_STAMMDATEN_SUCCESS:
            return {
                ...state,
                error: null,
                projektKonfigurationIstLoading: false,
                projektKonfiguration: {},
            };
        default:
            return state;
    }
};

export default projekt;
