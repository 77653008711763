export function getContentType(dateiName) {
    let contentType = "application/pdf";
    if (!dateiName.endsWith("pdf")) {
        if (dateiName.endsWith(".jpeg")) contentType = "image/jpeg";
        if (dateiName.endsWith(".jpg")) contentType = "image/jpg";
        if (dateiName.endsWith(".png")) contentType = "image/png";
        if (dateiName.endsWith(".bmp")) contentType = "image/bmp";
    }
    return contentType;
}
