import {
    ZUKO_EINSTELLUNGEN_ACTION_FAIL,
    ZUKO_EINSTELLUNGEN_ACTION_BEGIN,
    FETCH_ZUKO_EINSTELLUNGEN_SUCCESS,
    RESET_ZUKO_EINSTELLUNGEN_SUCCESS,
    FETCH_EINSTELLUNGEN_RESET_SUCCESS,
    ZUKO_AKTUALISIERE_EINSTELLUNGEN_SUCCESS,
    FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_SUCCESS,
    FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ACTION_FAIL,
    FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ACTION_BEGIN,
    ZUKO_EINSTELLUNGEN_SYNCHRONISIERE_ZUTRITTSEREIGNISSE_SUCCESS,
    FETCH_EINSTELLUNGEN_ZEITZONEN_ACTION_BEGIN,
    FETCH_EINSTELLUNGEN_ZEITZONEN_ACTION_FAIL,
    FETCH_EINSTELLUNGEN_ZEITZONEN_SUCCESS,
    FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPEN_ACTION_BEGIN,
    FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
    FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPEN_SUCCESS,
    FETCH_EINSTELLUNGEN_MINDESTLOHNKONFIGURATOR_ACTION_BEGIN,
    FETCH_EINSTELLUNGEN_MINDESTLOHNKONFIGURATOR_ACTION_FAIL,
    FETCH_EINSTELLUNGEN_MINDESTLOHNKONFIGURATOR_SUCCESS,
    FETCH_EINSTELLUNGEN_FIRMENUNTERLAGEN_ACTION_BEGIN,
    FETCH_EINSTELLUNGEN_FIRMENUNTERLAGEN_ACTION_FAIL,
    FETCH_EINSTELLUNGEN_FIRMENUNTERLAGEN_SUCCESS,
    FETCH_EINSTELLUNGEN_PERSONALUNTERLAGEN_ACTION_BEGIN,
    FETCH_EINSTELLUNGEN_PERSONALUNTERLAGEN_ACTION_FAIL,
    FETCH_EINSTELLUNGEN_PERSONALUNTERLAGEN_SUCCESS,
    FETCH_EINSTELLUNGEN_ZUKOSERVER_ACTION_BEGIN,
    FETCH_EINSTELLUNGEN_ZUKOSERVER_ACTION_FAIL,
    FETCH_EINSTELLUNGEN_ZUKOSERVER_SUCCESS,
    FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ADMINISTRATION_ACTION_BEGIN,
    FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ADMINISTRATION_ACTION_FAIL,
    FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ADMINISTRATION_SUCCESS,
    FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPE_ADMINISTRATION_ACTION_BEGIN,
    FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPE_ADMINISTRATION_ACTION_FAIL,
    FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPE_ADMINISTRATION_SUCCESS,
    FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_VORLAGEN_ACTION_BEGIN,
    FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_VORLAGEN_ACTION_FAIL,
    FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_VORLAGEN_SUCCESS,
    FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_ACTION_BEGIN,
    FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_ACTION_FAIL,
    FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_SUCCESS,
    AKTUALISIERE_ZUTRITTSEREIGNISSE_KONFIGURATION_ACTION_BEGIN,
    AKTUALISIERE_ZUTRITTSEREIGNISSE_KONFIGURATION_ACTION_FAIL,
    AKTUALISIERE_ZUTRITTSEREIGNISSE_KONFIGURATION_SUCCESS,
    AKTUALISIERE_BILDVERIFIZIERUNG_KONFIGURATION_ACTION_BEGIN,
    AKTUALISIERE_BILDVERIFIZIERUNG_KONFIGURATION_ACTION_FAIL,
    AKTUALISIERE_BILDVERIFIZIERUNG_KONFIGURATION_SUCCESS,
    FETCH_EINSTELLUNGEN_DASHBOARD_BILDVERIFIZIERUNG_AKTIVIERT_SUCCESS,
    FETCH_EINSTELLUNGEN_DASHBOARD_BILDVERIFIZIERUNG_AKTIVIERT_ACTION_FAIL
} from "../../actions/zuko/einstellungen.js";

const zukoEinstellungenState = {
    zukoEinstellungen: {},
    syncZurittsereignisse: {},
    zutrittspunkte: {},
    zutrittspunkteIstLoading: false,
    zeitzonen: {},
    zeitzonenIstLoading: false,
    berechtigungsgruppen: {},
    berechtigungsgruppenIstLoading: false,
    mindestlohnkonfiguratorIstLoading: false,
    mindestlohnkonfigurator: {},
    firmenunterlagenIstLoading: false,
    firmenunterlagen: {},
    personalunterlagenIstLoading: false,
    personalunterlagen: {},
    zukoServerIstLoading: false,
    zukoServer: {},
    zutrittspunkteAdministrationIstLoading: false,
    zutrittspunkteAdministration: {},
    berechtigungsgruppeAdministrationIstLoading: false,
    berechtigungsgruppeAdministration: {},
    ausweisVorlagenIstLoading: false,
    ausweisVorlagen: {},
    ausweisGueltigkeitIstLoading: false,
    ausweisGueltigkeit: {},
    sindZutrittszeitenAktiviertIstLoading: false,
    sindZutrittszeitenAktiviert: false,
    sindZutrittszeitenAktiviert: false,
    sindZutrittszeitenAktiviertIstLoading: false,
    error: null,
    loading: false,
};

const savedState =
    localStorage.getItem("savedState") ||
    JSON.stringify({ ...zukoEinstellungenState });
const INITIAL_STATE =
    JSON.parse(savedState).zukoEinstellungenState || zukoEinstellungenState;

const zukoEinstellungen = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_EINSTELLUNGEN_RESET_SUCCESS:
            return zukoEinstellungenState;
        case ZUKO_EINSTELLUNGEN_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ZUKO_EINSTELLUNGEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FETCH_ZUKO_EINSTELLUNGEN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                zukoEinstellungen: action.payload,
            };
        case FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                zutrittspunkteIstLoading: true,
            };
        case FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_SUCCESS:
            return {
                ...state,
                error: null,
                zutrittspunkteIstLoading: false,
                zutrittspunkte: action.payload,
            };
        case FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ACTION_FAIL:
            return {
                ...state,
                zutrittspunkteIstLoading: false,
                error: action.error,
            };
        case FETCH_EINSTELLUNGEN_ZEITZONEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                zeitzonenIstLoading: true,
            };
        case FETCH_EINSTELLUNGEN_ZEITZONEN_ACTION_FAIL:
            return {
                ...state,
                error: null,
                zeitzonenIstLoading: false,
                zeitzonen: action.payload,
            };
        case FETCH_EINSTELLUNGEN_ZEITZONEN_SUCCESS:
            return {
                ...state,
                zeitzonenIstLoading: false,
                error: action.error,
            };
        case FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                berechtigungsgruppenIstLoading: true,
            };
        case FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPEN_ACTION_FAIL:
            return {
                ...state,
                error: null,
                berechtigungsgruppenIstLoading: false,
                berechtigungsgruppen: action.payload,
            };
        case FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPEN_SUCCESS:
            return {
                ...state,
                berechtigungsgruppenIstLoading: false,
                error: action.error,
            };
        case FETCH_EINSTELLUNGEN_MINDESTLOHNKONFIGURATOR_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                mindestlohnkonfiguratorIstLoading: true,
            };
        case FETCH_EINSTELLUNGEN_MINDESTLOHNKONFIGURATOR_ACTION_FAIL:
            return {
                ...state,
                error: null,
                mindestlohnkonfiguratorIstLoading: false,
                mindestlohnkonfigurator: action.payload,
            };
        case FETCH_EINSTELLUNGEN_MINDESTLOHNKONFIGURATOR_SUCCESS:
            return {
                ...state,
                mindestlohnkonfiguratorIstLoading: false,
                error: action.error,
            };
        case FETCH_EINSTELLUNGEN_FIRMENUNTERLAGEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                firmenunterlagenIstLoading: true,
            };
        case FETCH_EINSTELLUNGEN_FIRMENUNTERLAGEN_ACTION_FAIL:
            return {
                ...state,
                error: null,
                firmenunterlagenIstLoading: false,
                firmenunterlagen: action.payload,
            };
        case FETCH_EINSTELLUNGEN_FIRMENUNTERLAGEN_SUCCESS:
            return {
                ...state,
                firmenunterlagenIstLoading: false,
                error: action.error,
            };
        case FETCH_EINSTELLUNGEN_PERSONALUNTERLAGEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                personalunterlagenIstLoading: true,
            };
        case FETCH_EINSTELLUNGEN_PERSONALUNTERLAGEN_ACTION_FAIL:
            return {
                ...state,
                error: null,
                personalunterlagenIstLoading: false,
                personalunterlagen: action.payload,
            };
        case FETCH_EINSTELLUNGEN_PERSONALUNTERLAGEN_SUCCESS:
            return {
                ...state,
                personalunterlagenIstLoading: false,
                error: action.error,
            };
        case FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPE_ADMINISTRATION_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                berechtigungsgruppeAdministrationIstLoading: true,
                berechtigungsgruppeAdministration: {},
            };
        case FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPE_ADMINISTRATION_ACTION_FAIL:
            return {
                ...state,
                error: null,
                berechtigungsgruppeAdministrationIstLoading: false,
                error: action.error,
            };
        case FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPE_ADMINISTRATION_SUCCESS:
            return {
                ...state,
                berechtigungsgruppeAdministrationIstLoading: false,
                berechtigungsgruppeAdministration: action.payload,
            };
        case FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_VORLAGEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                ausweisVorlagenIstLoading: true,
                ausweisVorlagen: {},
            };
        case FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_VORLAGEN_ACTION_FAIL:
            return {
                ...state,
                error: null,
                ausweisVorlagenIstLoading: false,
                error: action.error,
            };
        case FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_VORLAGEN_SUCCESS:
            return {
                ...state,
                ausweisVorlagenIstLoading: false,
                ausweisVorlagen: action.payload,
            };
        case FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                ausweisGueltigkeitIstLoading: true,
                ausweisGueltigkeit: {},
            };
        case FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_ACTION_FAIL:
            return {
                ...state,
                error: null,
                ausweisGueltigkeitIstLoading: false,
                error: action.error,
            };
        case FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_SUCCESS:
            return {
                ...state,
                ausweisGueltigkeitIstLoading: false,
                ausweisGueltigkeit: action.payload,
            };
        case ZUKO_EINSTELLUNGEN_SYNCHRONISIERE_ZUTRITTSEREIGNISSE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                syncZurittsereignisse: action.payload,
            };
        case FETCH_EINSTELLUNGEN_ZUKOSERVER_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                zukoServerIstLoading: true,
                zukoServer: {},
            };
        case FETCH_EINSTELLUNGEN_ZUKOSERVER_ACTION_FAIL:
            return {
                ...state,
                zukoServerIstLoading: false,
                error: action.error,
            };
        case FETCH_EINSTELLUNGEN_ZUKOSERVER_SUCCESS:
            return {
                ...state,
                error: null,
                zukoServerIstLoading: false,
                zukoServer: action.payload,
            };
        case FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ADMINISTRATION_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                zutrittspunkteAdministrationIstLoading: true,
                zutrittspunkteAdministration: {},
            };
        case FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ADMINISTRATION_ACTION_FAIL:
            return {
                ...state,
                zutrittspunkteAdministrationIstLoading: false,
                error: action.error,
            };
        case FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ADMINISTRATION_SUCCESS:
            return {
                ...state,
                error: null,
                zutrittspunkteAdministrationIstLoading: false,
                zutrittspunkteAdministration: action.payload,
            };
        case AKTUALISIERE_ZUTRITTSEREIGNISSE_KONFIGURATION_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                sindZutrittszeitenAktiviert: false,
                sindZutrittszeitenAktiviertIstLoading: true,
            };
        case AKTUALISIERE_ZUTRITTSEREIGNISSE_KONFIGURATION_ACTION_FAIL:
            return {
                ...state,
                sindZutrittszeitenAktiviertIstLoading: false,
                error: action.error,
            };
        case AKTUALISIERE_ZUTRITTSEREIGNISSE_KONFIGURATION_SUCCESS:
            return {
                ...state,
                error: null,
                sindZutrittszeitenAktiviert: action.payload,
                sindZutrittszeitenAktiviertIstLoading: false,
            };
        case AKTUALISIERE_BILDVERIFIZIERUNG_KONFIGURATION_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                istBildverifizierungAktiviert: false,
                istBildverifizierungAktiviertIstLoading: true,
            };
        case AKTUALISIERE_BILDVERIFIZIERUNG_KONFIGURATION_ACTION_FAIL:
            return {
                ...state,
                istBildverifizierungAktiviertIstLoading: false,
                error: action.error,
            };
        case AKTUALISIERE_BILDVERIFIZIERUNG_KONFIGURATION_SUCCESS:
            return {
                ...state,
                error: null,
                istBildverifizierungAktiviert: action.payload,
                istBildverifizierungAktiviertIstLoading: false,
            };
        case RESET_ZUKO_EINSTELLUNGEN_SUCCESS:
            return {
                ...state,
                error: null,
                zukoEinstellungen: {},
                loading: false,
            };
        case ZUKO_AKTUALISIERE_EINSTELLUNGEN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case FETCH_EINSTELLUNGEN_DASHBOARD_BILDVERIFIZIERUNG_AKTIVIERT_ACTION_FAIL:
            return {
                ...state,
                error: null,
                istBildverifizierungAktiviert: action.payload?.istBildverifizierungAktiviert,
                istBildverifizierungAktiviertIstLoading: false,
            };
        case FETCH_EINSTELLUNGEN_DASHBOARD_BILDVERIFIZIERUNG_AKTIVIERT_SUCCESS:
            return {
                ...state,
                error: null,
                istBildverifizierungAktiviert: action.payload,
                istBildverifizierungAktiviertIstLoading: false,
            };
        default:
            return state;
    }
};

export default zukoEinstellungen;
