export const TRANSLATION_EN_NUTZUNGSBEDINGUNGEN = {
    AUSSERGERICHTLICHES: {
        TEIL1_TEXT:
            "CPC does not participate in dispute resolution proceedings before a consumer arbitration board.",
        TEIL1_UEBERSCHRIFT: "16.1 Dispute Resolution",
        TEIL2_TEXT1:
            "However, as required by EU Regulation No. 524/2013, it is noted that the European Commission under",
        TEIL2_TEXT2:
            "operates a platform for the extrajudicial online resolution of disputes (so-called ODR platform).",
        TEIL2_UEBERSCHRIFT: "16.2 Online dispute resolution",
        UEBERSCHRIFT: "16. EXTERNAL DISPUTE RESOLUTION",
    },
    HAFTUNG: {
        TEIL1_TEXT:
            "Unless otherwise provided in Part I, CPC shall be liable for any breach of duty in accordance with the statutory provisions.",
        TEIL1_UEBERSCHRIFT: "13.1 Breach of duty",
        TEIL2_TEXT:
            "CPC is liable for damages - irrespective of the legal grounds - within the scope of fault liability in case of intent and gross negligence. In the case of ordinary negligence, CPC is liable, subject to a milder standard of liability in accordance with statutory provisions (e.g. for due care in its own affairs) only ",
        TEIL2_UEBERSCHRIFT: "13.2 Compensation for damages",
        TEIL3_TEXT: "The limitations of liability resulting from item I also apply to breaches of duty by or in favor of persons for whose fault CPC is responsible according to statutory provisions. They do not apply insofar as CPC has fraudulently concealed a defect or has assumed a guarantee for the condition and for any claims under the Product Liability Act.",
        TEIL3_UEBERSCHRIFT: "13.3 Limitations of liability",
        UEBERSCHRIFT: "13. LIABILITY",
    },
    KOMMUNIKATION: {
        TEXT: "Communications from CPC to the user shall be made by e-mail.",
        UEBERSCHRIFT: "14. COMMUNICATION",
    },
    REGISTRIERUNG: {
        TEIL1_LISTE1: "Surname, Name",
        TEIL1_LISTE2: "Email adress",
        TEIL1_LISTE3: "Company",
        TEIL1_TEXT1:
            "The use of the ALGO web application and/or ALGO app is only possible after personal registration of the user by providing the following mandatory data:",
        TEIL1_UEBERSCHRIFT: "10.1 Compulsory data",
        TEIL2_TEXT:
            "The user must set a username and password. The use of ALGO web application and/or ALGO app is possible only by entering the personal username and password.",
        TEIL2_UEBERSCHRIFT: "10.2 Username/Password",
        TEIL3_TEXT:
            "The user can log in to the respective construction site projects within the application or app by entering the data marked as mandatory in the login mask:",
        TEIL3_UEBERSCHRIFT: "10.3 Login screen",
        UEBERSCHRIFT: "10. REGISTRATION",
    },
    SORGFALTSPFLICHTEN: {
        TEIL1_TEXT:
            "The user may not disclose the password to third parties and must store it carefully to prevent misuse.",
        TEIL1_UEBERSCHRIFT: "11.1 Password",
        TEIL2_TEXT:
            "The user is obliged to inform the construction site operator or CPC immediately if the password has been lost or if the user becomes aware that third parties not authorized by him have gained knowledge of the password. Unless the user provides evidence that a third party has used the access to the platform without his consent, all entries made via the access are attributed to the user.",
        TEIL2_UEBERSCHRIFT:
            "11.2 Duty to inform about lost password",
        TEIL3_TEXT:
            "The user is liable for any misuse by third parties, unless he can prove that he is not at fault.",
        TEIL3_UEBERSCHRIFT: "11.3 Liability",
        TEIL4_TEXT:
            "CPC has the right to deactivate the registration of any user in the event of misuse or fraudulent use of the access authorization.",
        TEIL4_UEBERSCHRIFT: "11.4 Deactivation of the user account",
        TEIL5_TEXT:
            "The user can view his registration data under the heading ['My profile - Master data'] at any time and correct/change it there..",
        TEIL5_UEBERSCHRIFT:
            "11.5 Viewing registered data",
        UEBERSCHRIFT: "11. DUTIES OF CARE OF THE USER",
    },
    UEBERSCHRIFT: "II. GENERAL TERMS OF USE",
    VERHAELTNISSE: {
        TEXT: "CPC has not submitted to any specific codes of conduct.",
        UEBERSCHRIFT: "15. Codes of Conduct",
    },
    VERTRAGSVERHAELTNIS: {
        TEXT: "CPC provides the ALGO web application and the ALGO app to the user only on behalf of and within the scope of the instructions of the site operator. No contractual relationship exists between the user of the ALGO web application or the ALGO app and CPC. The app is used by the user exclusively for the implementation or fulfillment of the contractual or other legal relationship between the construction site operator of the construction project for which the registration is made and the user.",
        UEBERSCHRIFT: "9. CONTRACTUAL RELATIONSHIP",
    },
    ZUGRIFFSBERECHTIGUNG: {
        TEIL1_TEXT:
            "The scope of access authorization is determined by the construction site operator according to project roles (e.g. owner, construction management, project coordinators, subcontractors). The user expressly has no claim against CPC for the granting of access authorization.",
        TEIL1_UEBERSCHRIFT: "12.1 Scope",
        TEIL2_TEXT:
            "The construction site operator may informally instruct CPC to delete a user's access authorization at any time without stating reasons. In this case, the registration of the user concerned and thus his authorization to use the system will expire with immediate effect or, if applicable, with effect from the time at which the construction site operator withdraws the authorization to use the system.",
        TEIL2_UEBERSCHRIFT: "12.2 Expiration",
        UEBERSCHRIFT: "12. SCOPE AND EXPIRATION OF THE ACCESS AUTHORIZATION",
    },
};
export default TRANSLATION_EN_NUTZUNGSBEDINGUNGEN;
