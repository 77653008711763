const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;
export const fetchEufeb = async (sprache, projektId, eufebId) => {
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/download-eufeb-vorlage/?eufebId=${eufebId}`,
        {
            method: "GET",
        }
    );
    return response;
};

export const erstelleEufeb = async(sprache, projektId, formData) =>{
    appInsights.startTrackEvent("erstelleEufeb");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/erstelle-eufeb`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("erstelleEufeb", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("erstelleEufeb", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("erstelleEufeb", {
            success: json.success,
        });
        return json;
    }
}

export const aktualisiereEufeb = async(sprache, projektId, formData) =>{
    appInsights.startTrackEvent("erstelleEufeb");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/aktualisiere-eufeb`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("aktualisiereEufeb", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("aktualisiereEufeb", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("aktualisiereEufeb", {
            success: json.success,
        });
        return json;
    }
}

export const archiviereBuchungsunterlage = async(sprache, projektId, formData) => {
    appInsights.startTrackEvent("archiviereBuchungsunterlage");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/archiviere-eufeb`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("archiviereBuchungsunterlage", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("archiviereBuchungsunterlage", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("archiviereBuchungsunterlage", {
            success: json.success,
        });
        return json;
    }
}

