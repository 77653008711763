import { showToast } from "../algo-app";
import errorHandler from "../errorHandling.js";
import { translate } from "../../../../src/i18n.js";
export const FETCH_UNTERLAGEN_RESET_SUCCESS = "FETCH_UNTERLAGEN_RESET_SUCCESS";

//#region Firmen
export const FETCH_UNTERLAGEN_AUSWERTUNGEN_FIRMEN_ACTION_BEGIN =
    "FETCH_UNTERLAGEN_FIRMEN_AUSWERTUNGEN_ACTION_BEGIN";
export const FETCH_UNTERLAGEN_AUSWERTUNGEN_FIRMEN_ACTION_FAIL =
    "FETCH_UNTERLAGEN_AUSWERTUNGEN_FIRMEN_ACTION_FAIL";
export const FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_SUCCESS =
    "FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_SUCCESS";
//#endregion

//#region Personal
export const FETCH_DASHBOARD_UNTERLAGEN_AUSWERTUNGEN_PERSONAL_ACTION_BEGIN =
    "FETCH_DASHBOARD_UNTERLAGEN_AUSWERTUNGEN_PERSONAL_ACTION_BEGIN";
export const FETCH_DASHBOARD_UNTERLAGEN_AUSWERTUNGEN_PERSONAL_ACTION_FAIL =
    "FETCH_DASHBOARD_UNTERLAGEN_AUSWERTUNGEN_PERSONAL_ACTION_FAIL";
export const FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_SUCCESS =
    "FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_SUCCESS";
export const FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_ACTION_BEGIN =
    " FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_ACTION_BEGIN";
export const FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_ACTION_FAIL =
    "FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_ACTION_FAIL";
export const FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_SUCCESS =
    "FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_SUCCESS";
//#endregion

//#region Mindestlohnerklärungen
export const FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_UNTERLAGEN_ACTION_BEGIN =
    "FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_UNTERLAGEN_ACTION_BEGIN";
export const FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_UNTERLAGEN_ACTION_FAIL =
    "FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_UNTERLAGEN_ACTION_FAIL";
export const FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_UNTERLAGEN_SUCCESS =
    "FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_UNTERLAGEN_SUCCESS";
//#endregion

//#region DashboardFirmenUnterlagen prüfen
export const FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_PRUEFEN_ACTION_BEGIN =
    "FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_PRUEFEN_ACTION_BEGIN";
export const FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_PRUEFEN_ACTION_FAIL =
    "FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_PRUEFEN_ACTION_FAIL";
export const FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_PRUEFEN_SUCCESS =
    "FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_PRUEFEN_SUCCESS";
//#endregion

//#region DashboardPersonalUnterlagen prüfen
export const FETCH_PERSONAL_UNTERLAGEN_PRUEFEN_ACTION_BEGIN =
    "FETCH_PERSONAL_UNTERLAGEN_PRUEFEN_ACTION_BEGIN";
export const FETCH_PERSONAL_UNTERLAGEN_PRUEFEN_ACTION_FAIL =
    "FETCH_PERSONAL_UNTERLAGEN_PRUEFEN_ACTION_FAIL";
export const FETCH_PERSONAL_UNTERLAGEN_PRUEFEN_SUCCESS =
    "FETCH_PERSONAL_UNTERLAGEN_PRUEFEN_SUCCESS";
//#endregion

//#region DashboardFirmenUnterlagenFETCH_UNTERLAGEN_PRUEFEN_SUCCESS
export const FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_ACTION_BEGIN =
    "FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_ACTION_BEGIN";
export const FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_ACTION_FAIL =
    "FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_ACTION_FAIL";
export const FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_SUCCESS =
    "FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_SUCCESS";
//#endregion

//#region Mindestlohnerklärungen prüfen
export const FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_PRUEFEN_ACTION_BEGIN =
    "FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_PRUEFEN_ACTION_BEGIN";
export const FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_PRUEFEN_ACTION_FAIL =
    "FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_PRUEFEN_ACTION_FAIL";
export const FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_PRUEFEN_SUCCESS =
    "FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_PRUEFEN_SUCCESS";
//#endregion

//#region PersonalUnterlagenAuswertungen
export const FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_BEGIN =
    "FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_BEGIN";
export const FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_FAIL =
    "FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_FAIL";
export const FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_SUCCESS =
    "FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_SUCCESS";
export const FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_BEGIN =
    "FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_BEGIN";
export const FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_FAIL =
    "FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_FAIL";
export const FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_SUCCESS =
    "FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_SUCCESS";
export const FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_RESET_SUCCESS =
    "FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_RESET_SUCCESS";

//#endregion

//#region FirmenUnterlagenAuswertungen
export const FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_BEGIN =
    "FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_BEGIN";
export const FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_FAIL =
    "FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_FAIL";
export const FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_SUCCESS =
    "FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_SUCCESS";
export const FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_BEGIN =
    "FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_BEGIN";
export const FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_FAIL =
    "FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_FAIL";
export const FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_SUCCESS =
    "FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_SUCCESS";
export const FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_RESET_SUCCESS =
    "FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_RESET_SUCCESS";
//#endregion

//#region FirmenUnterlagen prüfen
export const FETCH_UNTERLAGEN_ACTION_BEGIN = "FETCH_UNTERLAGEN_ACTION_BEGIN";
export const FETCH_UNTERLAGEN_ACTION_FAIL = "FETCH_UNTERLAGEN_ACTION_FAIL";
export const FETCH_UNTERLAGEN_SUCCESS = "FETCH_UNTERLAGEN__SUCCESS";
//#endregion

//#region Mindestlohnerklärungen
export const FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_COLUMNS_ACTION_BEGIN =
    "FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_COLUMNS_ACTION_BEGIN";
export const FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_COLUMNS_ACTION_FAIL =
    "FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_COLUMNS_ACTION_FAIL";
export const FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_COLUMNS_SUCCESS =
    "FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_COLUMNS_SUCCESS";
export const FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_DATATABLE_ACTION_BEGIN =
    "FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_DATATABLE_ACTION_BEGIN";
export const FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_DATATABLE_ACTION_FAIL =
    "FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_DATATABLE_ACTION_FAIL";
export const FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_DATATABLE_SUCCESS =
    "FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_DATATABLE_SUCCESS";
export const FETCH_MINDESTLOHNERKLAERUNGEN_RESET_SUCCESS =
    "FETCH_MINDESTLOHNERKLAERUNGEN_RESET_SUCCESS";

//#endregion

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;
let abortUnterlagenAuswertungenFirmen,
    abortDashboardFirmenUnterlagen,
    abortUnterlagenAuswertungenPersonal,
    abortDashboardPersonalUnterlagen,
    abortMindestlohnerklaerungen,
    abortNichtGeprüfteFirmenUnterlagen,
    abortNichtGeprüftePersonalUnterlagen,
    abortNichtGeprüfteMindestlohnerklaerungen,
    abortPersonalUnterlagenAuswertungenColumns,
    abortPersonalUnterlagenAuswertungenDatatable,
    abortFirmenUnterlagenAuswertungenColumns,
    abortFirmenUnterlagenAuswertungenDatatable,
    abortMindestlohnerklaerungenUnterlagenDatatable,
    abortMindestlohnerklaerungenUnterlagenColumns;

export const cancelUnterlagenRequests = () => (dispatch) => {
    try {
        abortUnterlagenAuswertungenFirmen?.abort();
        abortDashboardFirmenUnterlagen?.abort();
        abortUnterlagenAuswertungenPersonal?.abort();
        abortDashboardPersonalUnterlagen?.abort();
        abortMindestlohnerklaerungen?.abort();
        abortNichtGeprüfteFirmenUnterlagen?.abort();
        abortNichtGeprüftePersonalUnterlagen?.abort();
        abortNichtGeprüfteMindestlohnerklaerungen.abort();
    } catch (error) {
        console.log(error);
    }
    dispatch({ type: FETCH_UNTERLAGEN_RESET_SUCCESS });
    appInsights.trackEvent({ name: "cancelUnterlagenRequests" });
};

export const cancelPersonalUnterlagenAuswertungen = () => (dispatch) => {
    try {
        abortPersonalUnterlagenAuswertungenColumns?.abort();
        abortPersonalUnterlagenAuswertungenDatatable?.abort();
    } catch (error) {
        console.log(error);
    }
    dispatch({ type: FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_RESET_SUCCESS });
    appInsights.trackEvent({ name: "cancelPersonalUnterlagenAuswertungen" });
};

export const cancelFirmenUnterlagenAuswertungen = () => (dispatch) => {
    try {
        abortFirmenUnterlagenAuswertungenColumns?.abort();
        abortFirmenUnterlagenAuswertungenDatatable?.abort();
    } catch (error) {
        console.log(error);
    }
    dispatch({ type: FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_RESET_SUCCESS });
    appInsights.trackEvent({ name: "cancelFirmenUnterlagenAuswertungen" });
};

export const cancelMindestlohnerklaerungen = () => (dispatch) => {
    try {
        abortMindestlohnerklaerungenUnterlagenDatatable?.abort();
        abortMindestlohnerklaerungenUnterlagenColumns?.abort();
    } catch (error) {
        console.log(error);
    }
    dispatch({ type: FETCH_MINDESTLOHNERKLAERUNGEN_RESET_SUCCESS });
    appInsights.trackEvent({ name: "cancelMindestlohnerklaerungen" });
};

//#region Firmen (Unterlagen-Auswertungen und Unterlagen)
export const fetchUnterlagenAuswertungenFirmen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchUnterlagenAuswertungenFirmen");
    dispatch({ type: FETCH_UNTERLAGEN_AUSWERTUNGEN_FIRMEN_ACTION_BEGIN });
    abortUnterlagenAuswertungenFirmen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-unterlagen-firmen-auswertungen`,
        { method: "GET", signal: abortUnterlagenAuswertungenFirmen.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_UNTERLAGEN_AUSWERTUNGEN_FIRMEN_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchUnterlagenAuswertungenFirmen", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_UNTERLAGEN_AUSWERTUNGEN_FIRMEN_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchUnterlagenAuswertungenFirmen", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchUnterlagenAuswertungenFirmen", {
            success: json.success,
        });
        return json.payload;
    }
};

export const fetchFirmenUnterlagenAuswertungenExport = (data) => (dispatch) => {
    dispatch(showToast({ text: translate("DASHBOARD.EXPORT_TOAST") }));
    const a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-unterlagen-firmen-auswertungen-export?projektName=${data.projektName}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "fetchFirmenUnterlagenAuswertungenExport" });
};

export const fetchDashboardFirmenUnterlagen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchDashboardFirmenUnterlagen");
    dispatch({ type: FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_ACTION_BEGIN });
    abortDashboardFirmenUnterlagen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-unterlagen-firmen`,
        { method: "GET", signal: abortDashboardFirmenUnterlagen.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchDashboardFirmenUnterlagen", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchDashboardFirmenUnterlagen", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchDashboardFirmenUnterlagen", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

//#region Personal

export const fetchUnterlagenAuswertungenPersonal =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("fetchUnterlagenAuswertungenPersonal");
        dispatch({
            type: FETCH_DASHBOARD_UNTERLAGEN_AUSWERTUNGEN_PERSONAL_ACTION_BEGIN,
        });
        abortUnterlagenAuswertungenPersonal =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-unterlagen-mitarbeiter-auswertungen`,
            {
                method: "GET",
                signal: abortUnterlagenAuswertungenPersonal.signal,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_DASHBOARD_UNTERLAGEN_PERSONAL_AUSWERTUNGEN_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent(
                    "fetchUnterlagenAuswertungenPersonal",
                    {
                        success: json.success,
                        error: { errorMessage: json },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_DASHBOARD_UNTERLAGEN_AUSWERTUNGEN_PERSONAL_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent(
                    "fetchUnterlagenAuswertungenPersonal",
                    {
                        success: response.ok,
                        error: { errorMessage: response },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("fetchUnterlagenAuswertungenPersonal", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const fetchPersonalUnterlagenAuswertungenExport =
    (data) => (dispatch) => {
        dispatch(showToast({ text: translate("DASHBOARD.EXPORT_TOAST") }));
        const a = document.createElement("a");
        a.setAttribute(
            "href",
            `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-unterlagen-mitarbeiter-auswertungen-export?projektName=${data.projektName}`
        );
        a.dispatchEvent(new MouseEvent("click"));
        appInsights.trackEvent({
            name: "fetchPersonalUnterlagenAuswertungenExport",
        });
    };

export const fetchDashboardPersonalUnterlagen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchDashboardPersonalUnterlagen");
    dispatch({ type: FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_ACTION_BEGIN });
    abortDashboardPersonalUnterlagen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-unterlagen-mitarbeiter`,
        { method: "GET", signal: abortDashboardPersonalUnterlagen.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchDashboardPersonalUnterlagen", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchDashboardPersonalUnterlagen", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchDashboardPersonalUnterlagen", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

//#region Mindeestlohnerklärungen
export const fetchMindestlohnerklaerungen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchMindestlohnerklaerungen");
    dispatch({
        type: FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_UNTERLAGEN_ACTION_BEGIN,
    });
    abortMindestlohnerklaerungen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-unterlagen-mindestlohnerklaerungen`,
        { method: "GET", signal: abortMindestlohnerklaerungen.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_UNTERLAGEN_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchMindestlohnerklaerungen", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_UNTERLAGEN_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchMindestlohnerklaerungen", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_UNTERLAGEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchMindestlohnerklaerungen", {
            success: json.success,
        });
        return json.payload;
    }
};

export const fetchMindestlohnerklaerungenExport = (data) => (dispatch) => {
    dispatch(showToast({ text: translate("DASHBOARD.EXPORT_TOAST") }));
    const a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-unterlagen-mindestlohnerklaerungen-export?projektName=${data.projektName}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({
        name: "fetchMindestlohnerklaerungenExport",
    });
};

export const fetchMindestlohnerklaerung = (data) => async (dispatch) => {
    const response = await fetch(
        `${apiHost}/api/zuko/v2/${data.projektId}/mindestlohnerklaerung/?mindestlohnerklaerungId=${data.mindestlohnerklaerungId}`,
        getRequestOptions
    );
    let json;
    try {
        return await response.blob();
    } catch (error) {
        dispatch(errorHandler(error));
    }
};
//#endregion

//#region NichtGeprüfteFirmenUnterlagen
export const fetchNichtGeprüfteFirmenUnterlagen =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("fetchNichtGeprüfteFirmenUnterlagen");
        dispatch({
            type: FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_PRUEFEN_ACTION_BEGIN,
        });
        abortNichtGeprüfteFirmenUnterlagen =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-unterlagen-firmen-unterlagen-pruefen`,
            { method: "GET", signal: abortNichtGeprüfteFirmenUnterlagen.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_PRUEFEN_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent(
                    "fetchNichtGeprüfteFirmenUnterlagen",
                    {
                        success: json.success,
                        error: { errorMessage: json },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_PRUEFEN_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent(
                    "fetchNichtGeprüfteFirmenUnterlagen",
                    {
                        success: response.ok,
                        error: { errorMessage: response },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_PRUEFEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("fetchNichtGeprüfteFirmenUnterlagen", {
                success: json.success,
            });
            return json.payload;
        }
    };
//#endregion

//#region NichtGeprüftePersonalUnterlagen
export const fetchNichtGeprüftePersonalUnterlagen =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("fetchNichtGeprüfteFirmenUnterlagen");
        dispatch({ type: FETCH_PERSONAL_UNTERLAGEN_PRUEFEN_ACTION_BEGIN });
        abortNichtGeprüftePersonalUnterlagen =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-unterlagen-personal-unterlagen-pruefen`,
            {
                method: "GET",
                signal: abortNichtGeprüftePersonalUnterlagen.signal,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_PERSONAL_UNTERLAGEN_PRUEFEN_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent(
                    "fetchNichtGeprüftePersonalUnterlagen",
                    {
                        success: json.success,
                        error: { errorMessage: json },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_PERSONAL_UNTERLAGEN_PRUEFEN_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent(
                    "fetchNichtGeprüftePersonalUnterlagen",
                    {
                        success: response.ok,
                        error: { errorMessage: response },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_PERSONAL_UNTERLAGEN_PRUEFEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("fetchNichtGeprüftePersonalUnterlagen", {
                success: json.success,
            });
            return json.payload;
        }
    };
//#endregion

//#region NichtGeprüfteMindestlohnerklaerungen
export const fetchNichtGeprüfteMindestlohnerklaerungen =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchNichtGeprüfteMindestlohnerklaerungen"
        );
        dispatch({
            type: FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_PRUEFEN_ACTION_BEGIN,
        });
        abortNichtGeprüfteMindestlohnerklaerungen =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-unterlagen-mindestlohnerklaerungen-pruefen`,
            {
                method: "GET",
                signal: abortNichtGeprüfteMindestlohnerklaerungen.signal,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_PRUEFEN_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent(
                    "fetchNichtGeprüfteMindestlohnerklaerungen",
                    {
                        success: json.success,
                        error: { errorMessage: json },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_PRUEFEN_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent(
                    "fetchNichtGeprüfteMindestlohnerklaerungen",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_PRUEFEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchNichtGeprüfteMindestlohnerklaerungen",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };
//#endregion

//#region Requests für die Personal-Unterlagen-Auswertungen-View
export const fetchPersonalUnterlagenAuswertungenSpalten =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchPersonalUnterlagenAuswertungenSpalten"
        );
        dispatch({
            type: FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_BEGIN,
        });
        abortPersonalUnterlagenAuswertungenColumns =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/personal-unterlagen-auswertungen-spalten`,
            {
                method: "GET",
                signal: abortPersonalUnterlagenAuswertungenColumns.signal,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent(
                    "fetchPersonalUnterlagenAuswertungenSpalten",
                    {
                        success: json.success,
                        error: { errorMessage: json },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent(
                    "fetchPersonalUnterlagenAuswertungenSpalten",
                    {
                        success: response.ok,
                        error: { errorMessage: response },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchPersonalUnterlagenAuswertungenSpalten",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchPersonalUnterlagenAuswertungenDataTable =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchPersonalUnterlagenAuswertungenDataTable"
        );
        dispatch({
            type: FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_BEGIN,
        });
        abortPersonalUnterlagenAuswertungenDatatable =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/personal-unterlagen-auswertungen`,
            {
                method: "POST",
                signal: abortPersonalUnterlagenAuswertungenDatatable.signal,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent(
                    "fetchPersonalUnterlagenAuswertungen",
                    {
                        success: json.success,
                        error: { errorMessage: json },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent(
                    "fetchPersonalUnterlagenAuswertungen",
                    {
                        success: response.ok,
                        error: { errorMessage: response },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchPersonalUnterlagenAuswertungenDataTable",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };
//#endregion

//#region Requests für die Firmen-Unterlagen-Auswertungen-View
export const fetchFirmenUnterlagenAuswertungenSpalten =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("fetchFirmenUnterlagenAuswertungenSpalten");
        dispatch({
            type: FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_BEGIN,
        });
        abortFirmenUnterlagenAuswertungenColumns =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/firmen-unterlagen-auswertungen-spalten`,
            {
                method: "GET",
                signal: abortFirmenUnterlagenAuswertungenColumns.signal,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent(
                    "fetchFirmenUnterlagenAuswertungenSpalten",
                    {
                        success: json.success,
                        error: { errorMessage: json },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent(
                    "fetchFirmenUnterlagenAuswertungenSpalten",
                    {
                        success: response.ok,
                        error: { errorMessage: response },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchFirmenUnterlagenAuswertungenSpalten",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchFirmenUnterlagenAuswertungenDataTable =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchFirmenUnterlagenAuswertungenDataTable"
        );
        dispatch({
            type: FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_BEGIN,
        });
        const queryParams = new URLSearchParams();
        queryParams.append('istGeprueft', data.istGeprueft);
        queryParams.append('istNichtGeprueft', data.istNichtGeprueft);
        queryParams.append('istHochgeladen', data.istHochgeladen);
        queryParams.append('istNichtHochgeladen', data.istNichtHochgeladen);
        queryParams.append('ablaufDiesenMonat', data.ablaufDiesenMonat);
        abortFirmenUnterlagenAuswertungenDatatable =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/firmen-unterlagen-auswertungen?${queryParams.toString()}`,
            {
                method: "GET",
                signal: abortFirmenUnterlagenAuswertungenDatatable.signal,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent(
                    "fetchFirmenUnterlagenAuswertungenDataTable",
                    {
                        success: json.success,
                        error: { errorMessage: json },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent(
                    "fetchFirmenUnterlagenAuswertungenDataTable",
                    {
                        success: response.ok,
                        error: { errorMessage: response },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchFirmenUnterlagenAuswertungenDataTable",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };
//#endregion

//#region Requests für die Mindestlohnerklärungen-Unterlagen-View
export const fetchUnterlagenMindestlohnerklaerungenSpalten =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchUnterlagenMindestlohnerklaerungenSpalten"
        );
        dispatch({
            type: FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_COLUMNS_ACTION_BEGIN,
        });
        abortMindestlohnerklaerungenUnterlagenColumns =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/personal-mindestlohnerklaerungen-spalten`,
            {
                method: "GET",
                signal: abortMindestlohnerklaerungenUnterlagenColumns.signal,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_COLUMNS_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent(
                    "fetchUnterlagenMindestlohnerklaerungenSpalten",
                    {
                        success: json.success,
                        error: { errorMessage: json },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_COLUMNS_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent(
                    "fetchUnterlagenMindestlohnerklaerungenSpalten",
                    {
                        success: response.ok,
                        error: { errorMessage: response },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_COLUMNS_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchUnterlagenMindestlohnerklaerungenSpalten",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchUnterlagenMindestlohnerklaerungenDataTable =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchUnterlagenMindestlohnerklaerungenDataTable"
        );
        dispatch({
            type: FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_DATATABLE_ACTION_BEGIN,
        });
        abortMindestlohnerklaerungenUnterlagenDatatable =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/personal-mindestlohnerklaerungen`,
            {
                method: "GET",
                signal: abortMindestlohnerklaerungenUnterlagenDatatable.signal,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_DATATABLE_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent(
                    "fetchUnterlagenMindestlohnerklaerungenDataTable",
                    {
                        success: json.success,
                        error: { errorMessage: json },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_DATATABLE_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent(
                    "fetchUnterlagenMindestlohnerklaerungenDataTable",
                    {
                        success: response.ok,
                        error: { errorMessage: response },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_DATATABLE_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchUnterlagenMindestlohnerklaerungenDataTable",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };
export const resetUnterlagenMindestlohnerklaerungen = () => (dispatch) => {
    dispatch({ type: FETCH_MINDESTLOHNERKLAERUNGEN_RESET_SUCCESS });
    appInsights.trackEvent({ name: "resetMindestlohnerklaerungenUnterlagen" });
};
//#endregion
