import { createStore, compose, applyMiddleware, combineReducers } from "redux";

import thunk from "redux-thunk";
import { lazyReducerEnhancer } from "pwa-helpers/lazy-reducer-enhancer.js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const devCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function getInstrumentationKey() {
    let enviroment = window.location.host.toLowerCase();
    switch (enviroment) {
        case "development":
            return "3249972f-3016-447a-834c-b74a3bc5da74";
        case "staging":
            return "ccc001a3-7bf5-4a10-b308-c9baef0e7c86";
        case "production":
            return "09fb2b54-62df-4299-b383-7db850b99d26";
        default:
            return "3249972f-3016-447a-834c-b74a3bc5da74";
    }
}
export const store = createStore(
    (state) => state,
    devCompose(lazyReducerEnhancer(combineReducers), applyMiddleware(thunk))
);

window.Algo.appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: getInstrumentationKey(), 
        disableCookiesUsage: true,
    },
});
window.Algo.appInsights.loadAppInsights();
window.Algo.appInsights.trackEvent({ name: "appInsights geladen" });
