const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;

export const fetchNewsNachrichten = async (sprache, projektId) => {
    appInsights.startTrackEvent("fetchNewsNachrichten");
    const response = await fetch(
        `${apiHost}/api/news/v1/${sprache}/${projektId}/nachrichten-uebersicht`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchNewsNachrichten", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchNewsNachrichten", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchNewsNachrichten", {
            success: json.success,
        });
        return json;
    }
};

export const erstelleNewsNachricht= async (
    formData,
    sprache,
    projektId
) => {
    appInsights.startTrackEvent("erstelleNewsNachricht");
    const response = await fetch(
        `${apiHost}/api/news/v1/${sprache}/${projektId}/erstelle-nachricht`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("erstelleNewsNachricht", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("erstelleNewsNachricht", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("erstelleNewsNachricht", {
            success: json.success,
        });
        return json;
    }
};

export const aktualisiereNewsNachricht = async (
    formData,
    language,
    projektId
) => {
    appInsights.startTrackEvent("aktualisiereNewsNachricht");
    const response = await fetch(
        `${apiHost}/api/news/v1/${language}/${projektId}/aktualisiere-nachricht`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("aktualisiereNewsNachricht", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("aktualisiereNewsNachricht", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("aktualisiereNewsNachricht", {
            success: json.success,
        });
        return json;
    }
};

export const archiviereNewsNachricht = async (
    formData,
    language,
    projektId
) => {
    appInsights.startTrackEvent("archiviereNewsNachricht");
    const response = await fetch(
        `${apiHost}/api/news/v1/${language}/${projektId}/archiviere-nachricht`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("archiviereNewsNachricht", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("archiviereNewsNachricht", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("archiviereNewsNachricht", {
            success: json.success,
        });
        return json;
    }
};

export const fetchNewsDashboardNachrichten = async (sprache, sortierung) => {
    appInsights.startTrackEvent("fetchNewsDashboardNachrichten");
    const response = await fetch(
        `${apiHost}/api/news/v1/${sprache}/dashboard-nachrichten?sortierung=${sortierung}`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchNewsDashboardNachrichten", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchNewsDashboardNachrichten", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchNewsDashboardNachrichten", {
            success: json.success,
        });
        return json;
    }
};
