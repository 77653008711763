const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;

export const fetchAusstattungViewData = async (sprache, projektId) => {
    appInsights.startTrackEvent("fetchAusstattungViewData");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/ausstattung-view-data`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchAusstattungViewData", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchAusstattungViewData", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchAusstattungViewData", {
            success: json.success,
        });
        return json;
    }
};

export const erstelleAusstattung = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("erstelleAusstattung");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/erstelle-ausstattung`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("erstelleAusstattung", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("erstelleAusstattung", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("erstelleAusstattung", {
            success: json.success,
        });
        return json;
    }
};

export const aktualisiereAusstattung = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("aktualisiereAusstattung");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/aktualisiere-ausstattung`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("aktualisiereAusstattung", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("aktualisiereAusstattung", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("aktualisiereAusstattung", {
            success: json.success,
        });
        return json;
    }
};

export const archiviereAusstattung = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("archiviereAusstattung");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/archiviere-ausstattung`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("archiviereAusstattung", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("archiviereAusstattung", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("archiviereAusstattung", {
            success: json.success,
        });
        return json;
    }
};
