export const TRANSLATIONS_EN_LAENDER = {
    DEUTSCHLAND: "Germany",
    POLEN: "Poland",
    TSCHECHIEN: "Czech Republic",
    RUMÄNIEN: "Romania",
    ALBANIEN: "Albania",
    BULGARIEN: "Bulgaria",
    GRIECHENLAND: "Greece",
    ITALIEN: "Italy",
    KROATIEN: "Croatia",
    LETTLAND: "Latvia",
    MAZEDONIEN: "Macedonia",
    NIEDERLANDE: "Netherlands",
    PORTUGAL: "Portugal",
    FRANKREICH: "France",
    RUSSISCHE_FÖDERATION: "Russian Federation",
    TÜRKEI: "Turkey",
    AFGHANISTAN: "Afghanistan",
    ÄGYPTEN: "Egypt",
    ALGERIEN: "Algeria",
    ANDORRA: "Andorra",
    ANGOLA: "Angola",
    ANTIGUA_UND_BARBUDA: "Antigua und Barbuda",
    ARABISCHE_EMIRATE: "United Arab Emirates",
    ÄQUATORIALGUINEA: "Equatorial Guinea",
    ARGENTINIEN: "Argentina",
    ARMENIEN: "Armenia",
    ASERBAIDSCHAN: "Azerbaijan",
    ÄTHIOPIEN: "Ethiopia",
    AUSTRALIEN: "Australia",
    BAHAMAS: "Bahamas",
    BAHRAIN: "Bahrain",
    BANGLADESCH: "Bangladesh",
    BARBADOS: "Barbados",
    BELGIEN: "Belgium",
    BELIZE: " Belize",
    BENIN: "Benin",
    BHUTAN: "Bhutan",
    BOLIVIEN: "Bolivia",
    BOSNIEN_UND_HERZEGOWINA: "Bosnia and Herzegowina",
    BOTSUANA: "Botswana",
    BRASILIEN: "Brazil",
    BRUNEI_DARUSSALAM: "Brunei Darussalam",
    BULGARIEN: "Bulgaria",
    BURKINA_FASO: "Burkina Faso",
    BURUNDI: "Burundi",
    CABO_VERDE: "Cape Verde",
    CHILE: "Chile",
    CHINA: "China",
    COOKINSELN: "Cook Islands",
    COSTA_RICA: "Costa Rica",
    CÔTE_DIVOIRE: "Côte d’Ivoire",
    DEMOKRATISCHE_VOLKSREPUBLIK_LAOS: "Laos People's Democratic Republic",
    DOMINICA: "Dominica",
    DOMINIKANISCHE_REPUBLIK: "Dominican Republic",
    DSCHIBUTI: "Djibouti",
    DÄNEMARK: "Denmark",
    ECUADOR: "Ecuador",
    EL_SALVADOR: "El Salvador",
    ERITREA: "Eritrea",
    ESTLAND: "Estonia",
    ESWATINI: "Eswatini",
    FIDSCHI: "Fiji",
    FINNLAND: "Finland",
    FÖDERIERTE_STAATEN_VON_MIKRONESIEN: "Federated States of Micronesia",
    GABUN: "Gabon",
    GAMBIA: "Gambia",
    GEORGIEN: "Georgia",
    GHANA: "Ghana",
    GRENADA: "Granada",
    GUATEMALA: "Guatemala",
    GUINEA: "Guinea",
    GUINEA_BISSAU: "Guinea-Bissau",
    GUYANA: "Guyana",
    HAITI: "Haiti",
    HONDURAS: "Honduras",
    INDIEN: "India",
    INDONESIEN: "Indonesia",
    IRAK: "Iraq",
    IRAN: "Iran",
    IRLAND: "Ireland",
    ISLAND: "Iceland",
    ISRAEL: "Israel",
    ITALIEN: "Italy",
    JAMAIKA: "Jamaica",
    JAPAN: "Japan",
    JEMEN: "Yemen",
    JORDANIEN: "Jordan",
    KAMBODSCHA: "Cambodia",
    KAMERUN: "Cameroon",
    KANADA: "Canada",
    KASACHSTAN: "Kazakhstan",
    KATAR: "Qatar",
    KENIA: "Kenya",
    KIRGISISTAN: "Kyrgyzstan",
    KIRIBATI: "Kiribati",
    KOLUMBIEN: "Colombia",
    KOMOREN: "Comoros",
    KONGO_DEMOKRATISCHE_REPUBLIK: "Congo, Democratic Republic",
    KONGO_REPUBLIK: "Congo, Republic",
    KOREA_DEMOKRATISCHE_VOLKSREPUBLIK: "Korea, People's Democratic Republic",
    KOREA_REPUBLIK: "Korea, Republic",
    KOSOVO: "Kosovo",
    KROATIEN: "Croatia",
    KUBA: "Cuba",
    KUWAIT: "Kuwait",
    LESOTHO: "Lesotho",
    LETTLAND: "Latvia",
    LIBANON: "Lebanon",
    LIBERIA: "Liberia",
    LIBYEN: "Libya",
    LIECHTENSTEIN: "Liechtenstein",
    LITAUEN: "Lithuania",
    LUXEMBURG: "Luxembourg",
    MADAGASKAR: "Madagascar",
    MALAWI: "Malawi",
    MALAYSIA: "Malaysia",
    MALEDIVEN: "Maldives",
    MALI: "Mali",
    MALTA: "Malta",
    MAROKKO: "Morocco",
    MARSHALLINSELN: "Marshall Islands",
    MAURETANIEN: "Mauritania",
    MAURITIUS: "Mauritius",
    MAZEDONIEN: "Macedonia",
    MEXIKO: "Mexico",
    MOLDAWISCH: "Moldovan",
    MONACO: "Monaco",
    MONGOLEI: "Mongolia",
    MONTENEGRO: "Montenegro",
    MOSAMBIK: "Mozambique",
    MYANMAR: "Myanmar",
    NAMIBIA: "Namibia",
    NAURU: "Nauru",
    NEPAL: "Nepal",
    NEUSEELAND: "New Zealand",
    NICARAGUA: "Nicaragua",
    NIGER: "Niger",
    NIGERIA: "Nigeria",
    NIUE: "Niue",
    NORWEGEN: "Norway",
    ÖSTERREICH: "Austria",
    OMAN: "Oman",
    PAKISTAN: "Pakistan",
    PALAU: "Palau",
    PALÄSTINA: "Palestine",
    PANAMA: "Panama",
    PAPUA_NEUGUINEA: "Papua New Guinea",
    PARAGUAY: "Paraguay",
    PERU: "Peru",
    PHILIPPINEN: "Philippines",
    POLEN: "Poland",
    RUANDA: "Rwanda",
    SALOMONEN: "Solomon Islands",
    SAMBIA: "Zambia",
    SAMOA: "Samoa",
    SAN_MARINO: "San Marino",
    SAUDI_ARABIEN: "Saudi Arabia",
    SCHWEDEN: "Sweden",
    SCHWEIZ: "Switzerland",
    SENEGAL: "Senegal",
    SERBIEN: "Serbia",
    SEYCHELLEN: "Seychelles",
    SIERRA_LEONE: "Sierra Leone",
    SIMBABWE: "Zimbabwe",
    SINGAPUR: "Singapore",
    SLOWAKEI: "Slovakia",
    SLOWENIEN: "Slovenia",
    SOMALIA: "Somalia",
    SPANIEN: "Spain",
    SRI_LANKA: "Sri Lanka",
    ST_KITTS_UND_NEVIS: "St. Kitts and Nevis",
    ST_LUCIA: "St. Lucia",
    ST_VINCENT_UND_DIE_GRENADINEN: "St. Vincent and the Grenadines",
    STAATENLOS: "Stateless",
    SUDAN: "Sudan",
    SURINAME: "Suriname",
    SYRIEN: "Syria",
    SÃO_TOMÉ_UND_PRÍNCIPE: "Sao Tome and Principe",
    SÜDAFRIKA: "South Africa",
    SÜDSUDAN: "South Sudan",
    TADSCHIKISTAN: "Tajikistan",
    TANSANIA: "Tanzania",
    THAILAND: "Thailand",
    TIMOR_LESTE: "Timor Leste",
    TOGO: "Togo",
    TONGA: "Tonga",
    TRINIDAD_UND_TOBAGO: "Trinidad und Tobago",
    TSCHAD: "Chad",
    TUNESIEN: "Tunisia",
    TURKMENISTAN: "Turkmenistan",
    TUVALU: "Tuvalu",
    TÜRKEI: "Turkey",
    UGANDA: "Uganda",
    UKRAINE: "Ukraine",
    UNGARN: "Hungary",
    URUGUAY: "Uruguay",
    USBEKISTAN: "Uzbekistan",
    VANUATU: "Vanuatu",
    VATIKANSTADT: "Vatican City",
    VENEZUELA: "Venezuela",
    VEREINIGTE_STAATEN_VON_AMERIKA: "United States of America",
    VEREINIGTES_KÖNIGREICH: "United Kingdom",
    VIETNAM: "Vietnam",
    WEISSRUSSLAND: "White Russian",
    ZENTRALAFRIKANISCHE_REPUBLIK: "Central African Republic",
    ZYPERN: "Cyprus",
    SPRACHEN: {
        ALBANISCH: "Albanian",
        ARABISCH: "Arabic",
        BOSNISCH: "Bosnian",
        BULGARISCH: "Bulgarian",
        DEUTSCH: "German",
        ENGLISCH: "English",
        FRANZÖSISCH: "French",
        GRIECHISCH: "Greek",
        ITALIENISCH: "Italian",
        KROATISCH: "Croatian",
        LETTISCH: "Latvian",
        MAZEDONISCH: "Macedonian",
        NIEDERLÄNDISCH: "Dutch",
        PERSISCH: "Persian",
        POLNISCH: "Polish",
        PORTUGIESISCH: "Portugese",
        RUMÄNISCH: "Romanian",
        RUSSISCH: "Russian",
        SERBISCH: "Serbian",
        SLOWAKISCH: "Slovak",
        SLOWENISCH: "Slovenian",
        SPANISCH: "Spanish",
        TSCHECHISCH: "Czech",
        TÜRKISCH: "Turkish",
        UNGARISCH: "Hungarian",
    },
};
export default TRANSLATIONS_EN_LAENDER;
