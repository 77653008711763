import { showToast } from "../algo-app";
import errorHandler from "../errorHandling.js";
import { translate } from "../../../../src/i18n.js";

export const FETCH_ORGANISATION_RESET_SUCCESS =
    "FETCH_ORGANISATION_RESET_SUCCESS";
export const RESET_GESAMTES_PERSONAL_SUCCESS =
    "RESET_GESAMTES_PERSONAL_SUCCESS";
export const RESET_ALLE_FIRMEN_SUCCESS = "RESET_ALLE_FIRMEN_SUCCESS";
//#region Dashboard
//#region Firmen
export const FETCH_ORGANISATION_FIRMEN_ACTION_BEGIN =
    "FETCH_ORGANISATION_FIRMEN_ACTION_BEGIN";
export const FETCH_ORGANISATION_FIRMEN_ACTION_FAIL =
    "FETCH_ORGANISATION_FIRMEN_ACTION_FAIL";
export const FETCH_FIRMEN_ORANISATION_SUCCESS =
    "FETCH_FIRMEN_ORANISATION_SUCCESS";
//#endregion

//#region Personal
export const FETCH_ORGANISATION_PERSONAL_ACTION_BEGIN =
    "FETCH_ORGANISATION_PERSONAL_ACTION_BEGIN";
export const FETCH_ORGANISATION_PERSONAL_ACTION_FAIL =
    "FETCH_ORGANISATION_PERSONAL_ACTION_FAIL";
export const FETCH_PERSONAL_ORANISATION_SUCCESS =
    "FETCH_PERSONAL_ORANISATION_SUCCESS";
//#endregion

//#region Firmenhierachie
export const FETCH_ORGANISATION_FIRMENHIERACHIE_ACTION_BEGIN =
    "FETCH_ORGANISATION_FIRMENHIERACHIE_ACTION_BEGIN";
export const FETCH_ORGANISATION_FIRMENHIERACHIE_ACTION_FAIL =
    "FETCH_ORGANISATION_FIRMENHIERACHIE_ACTION_FAIL";
export const FETCH_FIRMENHIERACHIE_ORANISATION_SUCCESS =
    "FETCH_FIRMENHIERACHIE_ORANISATION_SUCCESS";
//#endregion
//#endregion

//#region gesamtes Personal
export const FETCH_GESAMTES_PERSONAL_ACTION_BEGIN =
    "FETCH_GESAMTES_PERSONAL_ACTION_BEGIN";
export const FETCH_GESAMTES_PERSONAL_ACTION_FAIL =
    "FETCH_GESAMTES_PERSONAL_ACTION_FAIL";
export const FETCH_GESAMTES_PERSONAL_SUCCESS =
    "FETCH_GESAMTES_PERSONAL_SUCCESS";
//#endregion

//#region alle Firmen
export const FETCH_ALLE_FIRMEN_ACTION_BEGIN = "FETCH_ALLE_FIRMEN_ACTION_BEGIN";
export const FETCH_ALLE_FIRMEN_ACTION_FAIL = "FETCH_ALLE_FIRMEN_ACTION_FAIL";
export const FETCH_ALLE_FETCH_FIRMEN_SUCCESS =
    "FETCH_ALLE_FETCH_FIRMEN_SUCCESS";
//#endregion

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;

let abortOrganisationFirmen,
    abortOrganisationPersonal,
    abortOrganisationFirmenHierachie,
    abortGesamtesPersonal,
    abortAlleFirmen;

export const cancelOrganisationRequests = () => (dispatch) => {
    try {
        abortOrganisationFirmen?.abort();
        abortOrganisationPersonal?.abort();
        abortOrganisationFirmenHierachie?.abort();
    } catch (error) {
        console.log(error);
    }

    dispatch({ type: FETCH_ORGANISATION_RESET_SUCCESS });
    appInsights.trackEvent({ name: "cancelOrganisationRequests" });
};

export const cancelGesamtesPersonal = () => (dispatch) => {
    try {
        abortGesamtesPersonal?.abort();
    } catch (e) {
        console.log(e);
    }
    dispatch({ type: RESET_GESAMTES_PERSONAL_SUCCESS });
    appInsights.trackEvent({ name: "cancelGesamtesPersonal" });
};

export const cancelAlleFirmenRequest = () => (dispatch) => {
    dispatch({ type: RESET_ALLE_FIRMEN_SUCCESS });
    try {
        abortAlleFirmen?.abort();
    } catch (e) {
        console.log(e);
    }
    appInsights.trackEvent({ name: "cancelAlleFirmenRequest" });
};
//#region Dashboard
//#region Firmen
export const fetchFirmenUebersichtExport = (data) => (dispatch) => {
    dispatch(showToast({ text: translate("DASHBOARD.EXPORT_TOAST") }));
    const a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-organisation-firmen-export?projektName=${data.projektName}&raumzonenName="${data.raumzonenName}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "fetchFirmenUebersichtExport" });
};

export const fetchOrganisationFirmen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchOrganisationFirmen");
    dispatch({ type: FETCH_ORGANISATION_FIRMEN_ACTION_BEGIN });
    abortOrganisationFirmen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-organisation-firmen`,
        { method: "GET", signal: abortOrganisationFirmen.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_ORGANISATION_FIRMEN_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchOrganisationFirmen", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_ORGANISATION_FIRMEN_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchOrganisationFirmen", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_FIRMEN_ORANISATION_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchOrganisationFirmen", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

//#region Personal
export const fetchPersonalUebersichtExport = (data) => (dispatch) => {
    dispatch(showToast({ text: translate("DASHBOARD.EXPORT_TOAST") }));
    const a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-organisation-mitarbeiter-export?projektName=${data.projektName}&raumzonenName="${data.raumzonenName}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "fetchPersonalUebersichtExport" });
};

export const fetchOrganisationPersonal = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchOrganisationPersonal");
    dispatch({ type: FETCH_ORGANISATION_PERSONAL_ACTION_BEGIN });
    abortOrganisationPersonal =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-organisation-personal`,
        { method: "GET", signal: abortOrganisationPersonal.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_ORGANISATION_PERSONAL_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchOrganisationPersonal", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_ORGANISATION_PERSONAL_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchOrganisationPersonal", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_PERSONAL_ORANISATION_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchOrganisationPersonal", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

//#region Firmenhierachie
export const fetchOrganisationFirmenhierachie = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchOrganisationFirmenhierachie");
    dispatch({ type: FETCH_ORGANISATION_FIRMENHIERACHIE_ACTION_BEGIN });
    abortOrganisationFirmenHierachie =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-organisation-firmenhierarchie`,
        { method: "GET", signal: abortOrganisationFirmenHierachie.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_ORGANISATION_FIRMENHIERACHIE_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchOrganisationFirmenhierachie", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_ORGANISATION_FIRMENHIERACHIE_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchOrganisationFirmenhierachie", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_FIRMENHIERACHIE_ORANISATION_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchOrganisationFirmenhierachie", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion
//#endregion

//#region Übersicht gesamtes Personal
export const fetchGesamtesPersonal = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchGesamtesPersonal");
    dispatch({ type: FETCH_GESAMTES_PERSONAL_ACTION_BEGIN });
    abortGesamtesPersonal =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v3/${data.projektId}/mehrere-mitarbeiter?raumzonenName=${data.raumzonenName}`,
        { method: "GET", signal: abortGesamtesPersonal.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json?.succes) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_GESAMTES_PERSONAL_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("fetchGesamtesPersonal", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_GESAMTES_PERSONAL_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("fetchGesamtesPersonal", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_GESAMTES_PERSONAL_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchGesamtesPersonal", {
            success: json.success,
        });
        return json.payload;
    }
};

export const fetchGesamtesPersonalFilter = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchGesamtesPersonalFilter");
    dispatch({ type: FETCH_GESAMTES_PERSONAL_ACTION_BEGIN });
    abortGesamtesPersonal =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/mehrere-mitarbeiter-filter`,
        { method: "GET", signal: abortGesamtesPersonal.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json?.succes) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_GESAMTES_PERSONAL_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("fetchGesamtesPersonalFilter", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_GESAMTES_PERSONAL_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("fetchGesamtesPersonalFilter", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_GESAMTES_PERSONAL_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchGesamtesPersonalFilter", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

//#region Alle Firmen
export const fetchAlleFirmen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchAlleFirmen");
    dispatch({ type: FETCH_ALLE_FIRMEN_ACTION_BEGIN });

    abortAlleFirmen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v3/${data.projektId}/firmen?raumzonenName=${data.raumzonenName}`,
        { method: "GET", signal: abortAlleFirmen.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }

    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_ALLE_FIRMEN_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("fetchAlleFirmen", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_ALLE_FIRMEN_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("fetchAlleFirmen", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_ALLE_FETCH_FIRMEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchAlleFirmen", {
            success: json.success,
        });
        return json.payload;
    }
};

export const fetchAlleFirmenFilter = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchAlleFirmenFilter");
    dispatch({ type: FETCH_ALLE_FIRMEN_ACTION_BEGIN });

    abortAlleFirmen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/firmen-filter`,
        { method: "GET", signal: abortAlleFirmen.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }

    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_ALLE_FIRMEN_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("fetchAlleFirmenFilter", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_ALLE_FIRMEN_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("fetchAlleFirmenFilter", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_ALLE_FETCH_FIRMEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchAlleFirmenFilter", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion
