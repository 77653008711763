export const TRANSLATIONS_DE_PROJEKT = {
    ABRECHNUNGSKONFIGURATOR: "Abrechnungskonfigurator",
    ABRECHNUNGSKONFIGURATOR_GETRENNT: "Abrechnungs-konfigurator",
    ABRECHNUNGSMODELL: {
        BUCHHALTUNG_ODER_AUFTRAGGEBER:
            "Wähle den Empfänger-Typ Auftraggeber oder Buchhaltung aus.",
        TITEL: "Abrechnungsmodell",
        HELPER_1:
            "Wählen Sie hier, ob das Projekt nachdem Lizenz- oder Pauschalmodell abgerechnet werden soll?",
        HELPER_2:
            "Eingetragne E-Mailadressen erhalten monatliche Mails mit den Auflistung der aktiven Ausweisen.",
        LABEL_1: "Welches Abrechnungsmodell möchten Sie auswählen?",
        MAIL_EXISTIERT: "Email wurde bereits hinzugefügt.",
        MAILTYP: "Mailtyp",
    },
    AKTIVIEREN: "Aktivieren",
    ALLE: "Alle",
    AUFTRAGGEBER: "Auftraggeber",
    AUSWAEHLEN_PROJEKTROLLE: "Projektrolle auswählen",
    AUSWERTUNGEN: "Auswertungen",
    AVIS: "Avisierung",
    BENUTZER: "Benutzer*in",
    BITTE_AUSWAEHLEN: "Bitte auswählen",
    BUCHHALTUNG: "Buchhaltung",
    CONTAINER: "Container",
    CONTAINER_HELPER_TEXT_1:
        "Hier können Sie Rechte zum Container-Modul („Navigationspunkt Container“) vornehmen.",
    CONTAINER_HELPER_TEXT_2: "Buchungen betrachten",
    CONTAINER_HELPER_TEXT_TITLE_2:
        "Wählen Sie „Alle“,wenn die Rolle alle Buchungen betrachten können soll.",
    CONTAINER_HELPER_TEXT_3:
        "Buchungen detailliert betrachten wählen Sie „Ja“, dann haben Sie weitere Optionen die Sie der Rolle vergeben können.",
    CONTAINER_HELPER_TEXT_TITLE_3: "Buchungen detailliert betrachten",
    CONTAINER_HELPER_TEXT_TITLE_4:
        "Wählen Sie „Ja“, wenn die Rolle Unterlagen betrachten. Sollten Sie „Ja“ wählen können Sie anschließend weiter Rechte vergeben können.",
    CONTAINER_HELPER_TEXT_TEXT_4: "Unterlagen betrachten",
    CONTAINER_HELPER_TEXT_TITLE_5:
        "Wählen Sie „Ja“, wenn die Rolle Buchungsunterlagen-Konfiguration betrachten. Sollten Sie „Ja“ wählen können Sie anschließend weiter Rechte vergeben können.",
    CONTAINER_HELPER_TEXT_TEXT_5: "Buchungsunterlagen-Konfiguration betrachten",
    CONTAINER_HELPER_TEXT_TITLE_6:
        "Wählen Sie „Ja“, wenn die Rolle Containerburg-Konfiguration betrachten. Sollten Sie „Ja“ wählen können Sie anschließend weiter Rechte vergeben können.",
    CONTAINER_HELPER_TEXT_TEXT_6: "Containerburg-Konfiguration betrachten",
    CONTAINER_HELPER_TEXT_TITLE_7:
        "Wählen Sie „Ja“, wenn die Rolle Container-Konfiguration betrachten. Sollten Sie „Ja“ wählen können Sie anschließend weiter Rechte vergeben können.",
    CONTAINER_HELPER_TEXT_TEXT_7: "Container-Konfiguration betrachten",
    DASHBOARD: "Dashboard",
    DASHBOARD_HELPER_TEXT_1:
        "Hier können Sie Rechte zum Dashboard-Modul („Navigationspunkt Dashboard“) vornehmen.",
    DASHBOARD_HELPER_TEXT_TITLE_2: "Auswertungen betrachten",
    DASHBOARD_HELPER_TEXT_2:
        "Wählen Sie „Ja“, wenn die Rolle die Möglichkeit haben soll, den Navigationspunkt Auswertungen mit ihrem Inhalt einzusehen sowie diese zu exportieren.",
    DASHBOARD_HELPER_TEXT_TITLE_3: "Export Konfiguration betrachten",
    DASHBOARD_HELPER_TEXT_TEXT_3:
        "Wählen Sie „Ja“, wenn die Rolle die Möglichkeit haben soll, den Navigationspunkt Einstellungen " +
        " mit den konfigurierten Emailadressen zu sehen.",
    DASHBOARD_HELPER_TEXT_TEXT_4:
        "Sollten Sie  „Ja“ gewählt haben, haben Sie weitere Optionen",
    DASHBOARD_HELPER_TEXT_TITLE_5: "Export Konfiguration bearbeiten",
    DASHBOARD_HELPER_TEXT_TEXT_5:
        "Wählen Sie „Ja“, wenn die Rolle die Möglichkeit haben soll, die konfigurierten Email-Adressen bearbeiten sowie neue hinzuzufügen und bestehende zu entfernen.",
    DEAKTIVIEREN: "Deaktivieren",
    DSGVO_OEFFNEN: "Datenschutzeinwilligung öffnen",
    EINSTELLUNGEN: "Einstellungen",
    HELPER_TEXT_VERERBUNG:
        "Sollte eine Antwortmöglichkeit nicht anklickbar sein, fehlt der übergeordneten Elternrolle, von welcher diese Rolle erbt, das entsprechende Recht.",
    JA: "Ja",
    KEINE_MAIL_HINZUGEFUEGT: "Keine Mail dem Verteiler hinzugefügt",
    KONFIGURATION: "Konfiguration",
    KONFIGURATION_AKTUALISIEREN: "Konfiguration aktualisieren",
    KONFIGURATION_ERFOLGREICH_AKTUALSIIERT:
        "Koniguration erfolgreich aktualisiert",
    KONFIGURATION_ERFOLGREICH_GESPEICHERT:
        "Konfiguration wurde erfolgreich gespeichert",
    KONFIGURATION_ERSTELLEN: "Konfiguration erstellen",
    LIZENZMODELL: "Lizenzmodell",
    MAIL: "E-Mail",
    MAIL_EINGEBEN: "Bitte E-Mail eintragen",
    MAILVERTEILER: "Mailverteiler",
    MEINE: "Meine",
    MEINE_UND_GEERBTE: "Meine & Geerbte",
    MEINE_PROJEKTE: "Meine Projekte",
    MIT_ALLEN_FIRMEN: "mit allen Firmen inkl. gesamten Personal",
    MIT_MEINEN_FIRMEN: "mit meinen Firmen inkl. ihrem Personal",
    NACHNAME: "Nachname",
    NAME: "Name",
    NAME_EXISTIERT: "Name existiert bereits",
    NAME_PROJEKTBETEILIGTER: "Name der Projektbeteiligten Person",
    NEIN: "Nein",
    NEWS: "News",
    PAUSCHALMODELL: "Pauschalmodell",
    PARENT_ROLE: "Eltern-Projektrolle",
    PARENT_PROJEKTROLLE_AUSGEWAEHLT: "Warnung.Eltern-Projektrolle ausgewählt.",
    PFLICHTFELDER: "Pflichtfeldedr wurden nicht ausgefüllt.",
    PROJEKT: "Projekt",
    PROJEKT_AKTUALISIEREN: "Projekt aktualisieren",
    PROJEKTBETEILIGTEN_EINLADEN: "Projektbeteiligte Person einladen",
    PROJEKTBETEILIGTER_ERFOLGREICH_HINZUGEFUEGT:
        "Projektbeteiligte Person wurde erfolgreich eingeladen",
    PROJEKTBETEILIGTEN_MODAL_BODY: "Was möchten Sie als Nächstes tun?",
    PROJEKTBETEILIGTEN_TITEL: "Projektbeteiligte Personen",
    PROJEKTBETEILIGTEN_UEBERSICHT: "Übersicht der projektbeteiligten Personen",
    PROJEKTBETEILIGTER_AKTUALISIEREN: "Projektbeteiligte Person  aktualisieren",
    PROJEKTBETEILIGTER_ERFOLGREICH_ERSTELLT:
        "Projektbeteiligte Person erfolgreich eingeladen",
    PROJEKTBETEILIGTER_GELOESCHT: "Projektbeteiligte Person gelöscht",
    PROJEKTBETEILIGTER_LOESCHEN: "Projektbeteiligte Person löschen",
    PROJEKTBETEILIGTER_MODAL_BODY:
        "Wollen Sie diese projektbeteiligte Person wirklich löschen?",
    PROJEKTBETEILIGTER_MODAL_TITEL: "Projektbeteiligte Person löschen",
    PROJEKTBETEILIGTE_BETRACHTEN:
        "Projektbeteiligte Personen detailliert betrachten",
    PROJEKTBETEILIGTE_EINLADEN: "Projektbeteiligte Person einladen",
    PROJEKTBETEILIGTE_LOESCHEN: "Projektbeteiligte Person löschen",
    PROJEKTBETEILIGTE_TITEL: "Projektbeteiligte Personen",
    PROJEKTE: "Projekte",
    PROJEKTNAME: "Projektname",
    PROJEKTNAME_EINGABE: "Bitte Projektname eingeben",
    PROJEKTROLLEN: "Projektrollen",
    PROJEKTROLLEN_BEARBEITEN: "Projektrollen bearbeiten",
    PROJEKTROLLEN_BETRACHTEN: "Alle Projektrollen betrachten",
    PROJEKTROLLEN_DETAILIERT_BETRACHTEN: "Projektrollen detailliert betrachten",
    PROJEKTROLLEN_ERSTELLEN: "Projektrollen erstellen",
    PROJEKTROLLEN_LOESCHEN: "Projektrollen löschen",
    PROJEKTROLLEN_NAME_EINGEBEN: "Bitte Name der Projektrolle eingeben",
    PROJEKTROLLE_AKTUALISIEREN: "Projektrolle aktualisieren",
    PROJEKTROLLE_AKTUALISIERT: "Projektrolle aktualisiert",
    PROJEKTROLLE_ERSTELLEN: "Projektrolle erstellen",
    PROJEKTROLLE_GELOESCHT: "Projektrolle gelöscht",
    PROJEKTROLLE_LOESCHEN: "Projektrolle löschen",
    PROJEKTROLLE_MIT_BENUTZERN_FEHLERHAFT:
        "Projektrolle mit Benutzern kann nicht gelöscht werden",
    PROJEKTROLLE_MODAL_BODY: "Wollen Sie diese Projektrolle wirklich löschen?",
    PROJEKTROLLE_MODAL_TITEL: "Übersicht Ihrer eingegeben Daten",
    PROJEKTROLLE_TITEL: "Projektrolle",
    PROJEKTROLLE_AKTUALISIEREN_TITEL: "Projektrolle aktualisieren",
    PROJEKTROLLE_ZUWEISEN:
        "Projektrolle einer projektbeteiligten Person zuweisen",
    PROJEKT_ERSTELLEN: "Projekt erstellen",
    PROJEKT_ERSTELLEN_MODAL_BODY_TEXT1: "Was möchten Sie als Nächstes tun?",
    PROJEKT_ERSTELLEN_MODAL_BODY_TEXT2: "Weitere Projektrolle erstellen",
    PROJEKT_ERSTELLEN_MODAL_BODY_TEXT3: "Erstellte Projektrolle betrachten",
    PROJEKT_ERSTELLEN_MODAL_BODY_TEXT4: "Projektrollenübersicht",
    PROJEKT_ERSTELLEN_MODAL_TITEL: "Projektrolle erfolgreich erstellt",
    PROJEKT_ERSTELLEN_VIEW: {
        AVIS_MODUL: "Avisierung",
        BAUSTELLENGROESSE: "Baustellengröße",
        BAUSTELLE_OEFFNUNGSZEITEN: "Öffnungszeiten der Baustelle",
        BRUTTORAUMINHALT: "Bruttorauminhalt",
        BRUTTORAUMINHALT_EINGEBEN: "Bitte Bruttorauminhalt eingeben",
        BRUTTOGESCHLOSSFLAECHE: "Bruttogeschossfläche",
        BRUTTOGESCHLOSSFLAECHE_EINGEBEN: "Bitte Bruttogeschossfläche eingeben",
        CONTAINER_MODUL: "Container",
        CONTAINERBURG_ADMIN_GRAFIK_BETRACHTEN: "Administrative Containerburgabbildung betrachten",
        DASHBOARD_MODUL: "Dashboard",
        DATENSCHUTZ: "Datenschutz",
        DATENSCHUTZVERORDNUNGFILEDOWNLOADNAME:
            "DatenschutzverordnungFileDownloadName",
        DEBITORENNUMMER: "Debitorennummer",
        DSGVO: "Datenschutzeinwilligung",
        DEBITORENNUMMER_EINGEBEN: "Bitte Debitorennummer eingeben",
        DIENSTAG: "Dienstag",
        DONNERSTAG: "Donnerstag",
        ENDDATUM: "Enddatum",
        FREITAG: "Freitag",
        FUENF_ZEICHEN_FORMAT: "Format müssen 5 Zahlen sein",
        KOSTENSTELLE: "Kostenstelle",
        KOSTENSTELLE_EINGEBEN: "Bitte Kostenstelle eingeben",
        LIEFERADRESSE: "Lieferadresse",
        MITTWOCH: "Mittwoch",
        MODULE: "Module",
        MONTAG: "Montag",
        NEWS_MODUL: "News",
        OEFFNUNG: "Öffnung",
        PLZ: "Postleitzahl",
        PLZ_EINGEBEN: "Bitte Postleitzahl eingeben",
        PLZ_ERROR: "Bitte geben Sie eine fünfstellige Postleitzahl ein",
        PROJEKTADRESSE: "Projektadresse",
        PROJEKTBILD: "Projektbild",
        PROJEKTLEITER: "Projektleiter",
        PROJEKTLEITER_EINGABE: "Bitte Projektleiter eingeben",
        PROJEKTSTAMMDATEN: "Projektstammdaten",
        PROJEKTZEITRAUM: "Projektzeitraum",
        SAMSTAG: "Samstag",
        SCHLIESSUNG: "Schließung",
        SECHS_ZEICHEN_FORMAT: "Format müssen 6 Zahlen sein",
        SONNTAG: "Sonntag",
        STARTDATUM: "Startdatum",
        STADT: "Stadt",
        STADT_EINGEBEN: "Bitte Stadt eingeben",
        STRASSE: "Straße",
        STRASSE_EINGEBEN: "Bitte Straße eingeben",
        ZUKO_MODUL: "Zutrittskontroll",
    },
    PROJEKT_HELPER_TEXT_1:
        "Hier können Sie Rechte zum Projekt-Modul („Navigationspunkt Projekt“) vornehmen.",
    PROJEKT_HELPER_TEXT_TITLE_2: "Projektbeteiligte Personen betrachten",
    PROJEKT_HELPER_TEXT_2:
        "Hier können Sie zwischen „Alle“ und „Meine“ wählen, alle folge Punkte beziehen sich dann auf die gewählte Option.",
    PROJEKT_HELPER_TEXT_TITLE_3: "Projektrollen betrachten",
    PROJEKT_HELPER_TEXT_3:
        "Hier können Sie zwischen „Meine & Geerbte“ sowie „Meine“ auswählen, die folgenden Punkte beziehen sich auf die gewählte Option. ",
    PROJEKT_HELPER_TEXT_TITLE_3: "Projektkonfiguration betrachten",
    PROJEKT_HELPER_TEXT_3:
        "Wählen Sie „Ja“, wenn die Rolle die Möglichkeit haben soll, die Projektkonfiguration einzusehen. Sollten Sie „Ja“ wählen, haben Sie eine weitere Option, damit die Rolle den Projekt-Konfigurator bearbeiten kann.",
    PROJEKT_HELPER_TEXT_TITLE_4: "Abrechnungskonfiguration betrachten",
    PROJEKT_HELPER_TEXT_4:
        "Wählen Sie „Ja“, wenn die Rolle die Möglichkeit haben soll, die Abrechnungskonfiguration einzusehen. Sollten Sie „Ja“ wählen, haben Sie eine weitere Option, damit die Rolle den Abrechnungs-Konfigurator bearbeiten kann.",
    PROJEKT_RECHTE: {
        ABRECHNUNG_KONFIGURATION_BETRACHTEN:
            "Abrechnungskonfigurator betrachten",
        ABRECHNUNG_KONFIGURATION_BEARBEITEN:
            "Abrechnungskonfigurator bearbeiten",
        ABRECHNUNGSDETAILS_BETRACHTEN: "Abrechnungsdetails betrachten",
        ABTRANSPORT_ANMELDEN: "Abtransport anmelden",
        AKTUELLE_BESETZUNG_BETRACHTEN: "Aktuelle Besetzung betrachten",
        ALLE_BAUSTELLENAUSWEISE_SPERREN_BEARBEITEN:
            "Alle Baustellenausweise sperren",
        ALLE_BUCHUNGEN_BETRACHTEN: "Buchungen betrachten",
        ALLE_PROJEKTROLLEN_BETRACHTEN: "Alle Projektrollen betrachten",
        ALLE_TRANSPORTE_BETRACHTEN: "Transporte betrachten",
        ANLIEFERUNG_ANMELDEN: "Anlieferung anmelden",
        AUSSTATTUNG_KONFIGURATION_BETRACHTEN:
            "Konfiguration Ausstatttung betrachten",
        AUSSTATTUNG_KONFIGURATION_BEARBEITEN:
            "Konfiguration Ausstatttung bearbeiten",
        AUSWEISDESIGN_KONFIGURATION_BETRACHTEN:
            "Ausweisdesign-Konfiguration betrachten",
        AUSWEISDESIGN_KONFIGURATION_BEARBEITEN:
            "Ausweisdesign-Konfiguration bearbeiten",
        AUSWEISGUELTIGKEIT_KONFIGURATION_BEARBEITEN:
            "Ausweisgültigkeit-Konfiguration bearbeiten",
        AUSWEISGUELTIGKEIT_KONFIGURATION_BETRACHTEN:
            "Ausweisgültigkeit-Konfiguration betrachten",
        AUSWERTUNGEN_BETRACHTEN: "Auswertungen betrachten",
        BAUSTELLENAUSWEIS_ENTSPERREN: "Baustellenausweise entsperren",
        BAUSTELLENAUSWEIS_EINSTELLUNGEN_SETZEN:
            "Baustellenausweis-Einstellungen setzen",
        BAUSTELLENAUSWEIS_ERSTELLEN_UND_DRUCKEN:
            "Baustellenausweise erstellen & drucken",
        BAUSTELLENAUSWEIS_LOESCHEN: "Baustellenausweis löschen",
        BAUSTELLENAUSWEISE_UEBERSICHT_BETRACHTEN:
            "Baustellenausweise betrachten",
        BAUSTELLENAUSWEIS_SPERREN: "Baustellenausweise sperren",
        BERECHTIGUNGSGRUPPEN_KONFIGURATION_BETRACHTEN:
            "Berechtigunsgruppen-Konfiguration betrachten",
        BERECHTIGUNGSGRUPPEN_KONFIGURATION_BEARBEITEN:
            "Berechtigunsgruppen-Konfiguration bearbeiten",
        BESTIMMUNGSORT_KONFIGURATION_BEARBEITEN: "Bestimmungsort bearbeiten",
        BESTIMMUNGSORT_KONFIGURATION_BETRACHTEN: "Bestimmungsort betrachten",
        BILDVERIFIZIERUNG_BETRACHTEN: "Bildverifizierung betrachten",
        BUCHUNGEN_ARCHIVIEREN: "Buchungen archivieren",
        BUCHUNGEN_BETRACHTEN: "Buchungen betrachten",
        BUCHUNGEN_BEARBEITEN: "Buchungen bearbeiten",
        BUCHUNGEN_DETAILLIERT_BETRACHTEN: "Buchungen detailliert betrachten",
        BUCHUNGEN_ERSTELLEN: "Buchungen erstellen",
        BUCHUNGEN_GENEHMIGEN_UND_STORNIEREN:
            "Buchungen genehmigen & stornieren",
        BUCHUNGSUNTERLAGEN_ARCHIVIEREN: "Buchungsunterlagen archivieren",
        BUCHUNGSUNTERLAGEN_HOCHLADEN: "Buchungsunterlagen hochladen",
        BUCHUNGSUNTERLAGEN_KONFIGURATION_BEARBEITEN:
            "Buchungsunterlagen-Konfiguration bearbeiten",
        BUCHUNGSUNTERLAGEN_KONFIGURATION_BETRACHTEN:
            "Buchungsunterlagen-Konfiguration betrachten",
        BURG_KONFIGURATION_BEARBEITEN: "Containerburg-Konfiguration bearbeiten",
        BURG_KONFIGURATION_BETRACHTEN: "Containerburg-Konfiguration betrachten",
        CONTAINER_KONFIGURATION_BEARBEITEN:
            "Container-Konfiguration bearbeiten",
        CONTAINER_KONFIGURATION_BETRACHTEN:
            "Container-Konfiguration betrachten",
        CONTAINER_BUCHUNGSUNTERLAGE_ARCHIVIEREN:
            "Containerbuchungsunterlage archivieren",
        CONTAINERBUCHUNGSUNTERLAGEN_ARCHIVIEREN:
            "Containerbuchungsunterlagen archivieren",
        CONTAINERBUCHUNGSUNTERLAGEN_HOCHLADEN:
            "Containerbuchungsunterlagen hochladen",
        CONTAINERBUCHUNGSUNTERLAGEN_KONFIGURATION_BEARBEITEN:
            "Containerbuchungsunterlagen-Konfiguration bearbeiten",
        CONTAINERBUCHUNGSUNTERLAGEN_KONFIGURATION_BETRACHTEN:
            "Containerbuchungsunterlagen-Konfiguration betrachten",
        CONTAIENRBURG_ADMIN_GRAFIK_BETRACHTEN:
            "Grafische Admin-Containerburg betrachten",
        CONTAINERBURG_BILD_BETRACHTEN: "Containerburgbild betrachten",
        DISPOSITIONEN_BETRACHTEN: "Dispositionen betrachten",
        DISPOSITIONEN_ABSCHLIESSEN: "Dispositionen abschliessen",
        EIGENE_PROJEKTROLLEN_BETRACHTEN: "Eigene Projektrollen betrachten",
        EIGENE_UND_GEERBTE_PROJEKTROLLEN_BETRACHTEN:
            "Eigene und geerbte Projektrollen betrachten",
        EINSTELLUNGEN_BETRACHTEN: "Einstellungen betrachten",
        EINSTELLUNGEN_BEARBEITEN: "Einstellungen bearbeiten",
        EXPORT_KONFIGURATION_BEARBEITEN: "Export Konfiguration bearbeiten",
        EXPORT_KONFIGURATION_BETRACHTEN: "Export Konfiguration betrachten",
        FIRMEN_BEARBEITEN: "Firmen bearbeiten",
        FIRMEN_DETAILLIERT_BETRACHTEN: "Firmen detailliert betrachten",
        FIRMEN_ERSTELLEN: "Firmen erstellen",
        FIRMEN_LOESCHEN: "Firmen löschen",
        FIRMENHIERARCHIE_BETRACHTEN: "Firmenhierarchie betrachten",
        FIRMEN_MIT_PERSONAL_BETRACHTEN:
            "Firmen mit zugehörigem Personal betrachten",
        FIRMEN_UNTERLAGEN_ARCHIVIEREN: "Firmenunterlagen archivieren",
        FIRMEN_UNTERLAGEN_HOCHLADEN: "Firmenunterlagen hochladen",
        FIRMEN_UNTERLAGEN_PRUEFEN_UND_WIDERRUFEN:
            "Firmenunterlagen prüfen & widerrufen",
        FIRMEN_UNTERLAGEN_KONFIGURATION_BETRACHTEN:
            "Firmenunterlagen-Konfiguration betrachten",
        FIRMEN_UNTERLAGEN_KONFIGURATION_BEARBEITEN:
            "Firmenunterlagen-Konfiguration bearbeiten",
        FIRMEN_UNTERLAGEN_UEBERSICHT_BETRACHTEN: "Firmenunterlagen betrachten",
        FIRMEN_UNTERLAGEN_WIEDERHERSTELLEN: "Firmenunterlagen wiederherstellen",
        FIRMEN_ZUTRITTSAUSWERTUNGEN_BETRACHTEN:
            "Firmenzutrittsauswertungen betrachten",
        KONFIGURATION_BETRACHTEN: "Projektkonfiguration betrachten",
        KONFIGURATION_BEARBEITEN: "Projektkonfiguration bearbeiten",
        MINDESTLOHN_KONFIGURATION_BETRACHTEN:
            "Mindestlohn-Konfiguration betrachten",
        MINDESTLOHN_KONFIGURATION_BEARBEITEN:
            "Mindestlohn-Konfiguration bearbeiten",
        MINDESTLOHNERKLAERUNGEN_HOCHLADEN: "Mindestlohnerklärungen hochladen",
        MINDESTLOHNERKLAERUNGEN_PRUEFEN_UND_WIDERRUFEN:
            "Mindestlohnerklärungen prüfen & wiederrufen",
        MINDESTLOHNERKLAERUNGEN_ARCHIVIEREN:
            "Mindestlohnerklärungen archivieren",
        MINDESTLOHNERKLAERUNGEN_UEBERSICHT_BETRACHTEN:
            "Mindestlohnerklärungen betrachten",
        MINDESTLOHNERKLAERUNGEN_WIEDERHERSTELLEN:
            "Mindestlohnerklärungen wiederherstellen",
        MODULANGABEN_KONFIGURATION_BETRACHTEN: "Modulangaben betrachten",
        MODULANGABEN_KONFIGURATION_BEARBEITEN: "Modulangaben bearbeiten",
        MODULUNTERLAGEN_BETRACHTEN: "Modulunterlagen betrachten",
        MODULUNTERLAGEN_KONFIGURATION_BEARBEITEN: "Modulunterlagen bearbeiten",
        MODULUNTERLAGEN_KONFIGURATION_BETRACHTEN: "Modulunterlagen betrachten",
        MODULVERANTWORTLICHER_KONFIGURATION_BEARBEITEN:
            "Moduldulverantwortlicher bearbeiten",
        MODULVERANTWORTLICHER_KONFIGURATION_BETRACHTEN:
            "Modulverantwortlicher betrachten",
        NACHRICHT_ARCHIVIEREN: "Nachricht archivieren",
        NACHRICHTEN_BETRACHTEN: "Nachricht betrachten",
        NACHRICHT_BEARBEITEN: "Nachricht bearbeiten",
        NACHRICHT_ERSTELLEN: "Nachricht erstellen",
        PERSONAL_BEARBEITEN: "Personal bearbeiten",
        PERSONAL_DETAILLIERT_BETRACHTEN: "Personal detailliert betrachten",
        PERSONAL_ERSTELLEN: "Personal erstellen",
        PERSONAL_LOESCHEN: "Personal löschen",
        PERSONAL_UNTERLAGEN_ARCHIVIEREN: "Personalunterlagen archivieren",
        PERSONAL_UNTERLAGEN_HOCHLADEN: "Personalunterlagen hochladen",
        PERSONAL_UNTERLAGEN_KONFIGURATION_BETRACHTEN:
            "Personalunterlagen-Konfiguration betrachten",
        PERSONAL_UNTERLAGEN_KONFIGURATION_BEARBEITEN:
            "Personalunterlagen-Konfiguration bearbeiten",
        PERSONAL_UNTERLAGEN_PRUEFEN_UND_WIDERRUFEN:
            "Personalunterlagen prüfen & widerrufen",
        PERSONAL_UNTERLAGEN_UEBERSICHT_BETRACHTEN:
            "Personalunterlagen betrachten",
        PERSONAL_UNTERLAGEN_WIEDERHERSTELLEN:
            "Personalunterlagen wiederherstellen",
        PERSONAL_ZUTRITTSAUSWERTUNGEN_BETRACHTEN:
            "Personalzutrittsauswertungen betrachten",
        PROJEKTBETEILIGTE_BEARBEITEN: "Projektbeteiligte bearbeiten",
        PROJEKTBETEILIGTE_BETRACHTEN: "Projektbeteiligte Personen betrachten",
        PROJEKTBETEILIGTEN_BETRACHTEN: "Projektbeteiligten Personen betrachten",
        PROJEKTBETEILIGTE_DETAILLIERT_BETRACHTEN:
            "Projetktebteiligte Personen detailliert betrachten",
        PROJEKTBETEILIGTE_EINLADEN: "Projektbeteiligte Person einladen",
        PROJEKTBETEILIGTE_HINZUFUEGEN: "Projektbeteiligte hinzufügen",
        PROJEKTBETEILIGTE_LOESCHEN: "Projektbeteiligte Personen löschen",
        PROJEKTBETEILIGTE_ROLLENZUWEISUNG_AENDERN:
            "Rollenzuweisung projektbeteiligte Person ändern",
        PROJEKTROLLEN_BETRACHTEN: "Projektrollen betrachten",
        PROJEKTROLLEN_DETAILLIERT_BETRACHTEN:
            "Projektrollen detalliert betrachten",
        PROJEKTROLLEN_ERSTELLEN: "Projektrolle erstellen",
        PROJEKTROLLEN_BEARBEITEN: "Projektrolle bearbeiten",
        PROJEKTROLLEN_LOESCHEN: "Projektrolle löschen",
        RESSOURCEN_KONFIGURATION_BETRACHTEN: "Ressourcen betrachten",
        RESSOURCEN_KONFIGURATION_BEARBEITEN: "Resourcen bearbeiten",
        TRANSPORT_ALLE_BETRACHTEN: "Transporte betrachten",
        TRANSPORT_BEARBEITEN: "Transport bearbeiten",

        TRANSPORT_DETAILLIERT_BETRACHTEN: "Transporte detailliert betrachten",
        TRANSPORT_GENEHMIGEN_UND_STORNIEREN:
            "Transport genehmigen und stornieren",
        UNTERLAGEN_BETRACHTEN: "Unterlagen betrachten",
        UNTERLAGEN_KONFIGURATION_BEARBEITEN:
            "Unterlagen-Konfiguration bearbeiten",
        UNTERLAGEN_KONFIGURATION_BETRACHTEN:
            "Unterlagen-Konfiguration betrachten",
        ZEITZONEN_KONFIGURATION_BETRACHTEN:
            "Zeitzonen-Konfiguration betrachten",
        ZEITZONEN_KONFIGURATION_BEARBEITEN:
            "Zeitzonen-Konfiguration bearbeiten",
        ZEITSLOTS_KONFIGURATION_BETRACHTEN: "Zeitslot-Konfiguration betrachten",
        ZEITSLOTS_KONFIGURATION_BEARBEITEN: "Zeitslot-Konfiguration bearbeiten",
        ZIELORT_KONFIGURATION_BEARBEITEN: "Zielort bearbeiten",
        ZIELORT_KONFIGURATION_BETRACHTEN: "Zielort betrachten",
        ZUKO_SERVER_KONFIGURATION_BETRACHTEN: "Zuko-Server betrachten",
        ZUKO_SERVER_KONFIGURATION_BEARBEITEN: "Zuko-Server bearbeiten",
        ZUTRITTSEREIGNISSE_SYNCHRONISIEREN_BEARBEITEN:
            "Zutrittsereignisse synchronisieren",
        ZUTRITTSPUNKTE_KONFIGURATION_BEARBEITEN:
            "Zutrittspunkte-Konfiguration bearbeiten",
        ZUTRITTSPUNKTE_KONFIGURATION_BETRACHTEN:
            "Zutrittspunkte-Konfiguration betrachten",
    },
    STAMMDATEN: "Stammdaten",
    STAMMDATEN_ERFOLGREICH_AKTUALISIERT:
        "Stammdaten wurden erfolgreich aktualisiert",
    STAMMDATEN_HELPER_TEXT_1:
        "Beachten Sie das der Name der Projektrolle noch nicht existiert.",
    STAMMDATEN_HELPER_TEXT_2:
        "Von dieser Rolle erbt die zu erstellende Rolle. Das bedeutet, die zu erstellende Rolle hat weniger Rechte als die Eltern-Projektrolle.",
    STAMMDATEN_HELPER_TEXT_3:
        "Sollten Sie eine System-Rolle ausgewählt haben, können Sie die nur betrachten und nicht aktualisieren.",
    UEBERSICHTSSEITEN: "Übersichtsseiten",
    UNTERNEHMEN: "Unternehmen",
    VORNAME: "Vorname",
    WARNUNG_PARENT_PROJEKTROLLE_BEFUELLEN:
        "Soll die Projektrolle wirklich von der angegebenen Projektrolle erben? Dann wählen Sie Ja.",
    WEITEREN_BETEILIGTEN_EINLADEN: "Weitere projektbeteiligte Person einladen",
    ZUKO_HELPER_TEXT_1:
        "Hier können Sie Rechte zum Zutrittskontroll-Modul („Navigationspunkt Zutrittskontrolle“) vornehmen.",
    ZUKO_HELPER_TEXT_TITLE_2: "Firmen mit zugehörigem Personal betrachten",
    ZUKO_HELPER_TEXT_2:
        "Hier können Sie zwischen „Alle“ und „Meine“ wählen, alle folgende Punkte beziehen sich auf diese Auswahl.",
    ZUKO_HELPER_TEXT_UEBERSICHTSSEITEN:
        "Übersichtsseiten mit meinen Firmen inkl. Personal ",
    ZUKO_HELPER_TEXT_UEBERSICHTSSEITEN_1:
        "Hier können Sie die Rechte für alle Übersichtsseiten, keine detaillierte Betrachtung von Personal oder Firmen setzen. ",
    ZUKO_HELPER_TEXT_UEBERSICHTSSEITEN_2:
        "Angezeigte Werte sind abhängig von „Meine“ oder „Alle“ Firmen mit zugehörigem Personal.",
    ZUKO_HELPER_TEXT_EINSTELLUNGEN:
        "Hierbei handelt es sich um die Konfiguration des Zutrittskontrollmoduls und die Vergabe dieser Rechte sollte genau geprüft werden ",

    ZUTRITTSKONTROLLE: "Zutrittskontrolle",
};
export default TRANSLATIONS_DE_PROJEKT;
