import {
  FETCH_EUFEMAS_SUCCESS,
  RESET_EUFEMAS_SUCCESS,
  LOESCHE_EUFEMAS_SUCCESS,
  ERSTELLE_EUFEMAS_SUCCESS,
  ZUKO_EUFEMAS_ACTION_FAIL,
  ZUKO_EUFEMAS_ACTION_BEGIN,
  AKTUALISIERE_EUFEMAS_SUCCESS,
} from "../../actions/zuko/eufemas.js";

const eufemaState = {
  loading: false,
  error: null,
  eufemas: [],
};

const savedState =
  localStorage.getItem("savedState") || JSON.stringify({ ...eufemaState });
const INITIAL_STATE = JSON.parse(savedState).eufefaState || eufemaState;

const eufemas = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ZUKO_EUFEMAS_ACTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ZUKO_EUFEMAS_ACTION_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case FETCH_EUFEMAS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        eufemas: action.payload,
      };
    case RESET_EUFEMAS_SUCCESS:
      return {
        ...state,
        error: null,
        eufemas: [],
        loading: false,
      };
    case LOESCHE_EUFEMAS_SUCCESS:
    case ERSTELLE_EUFEMAS_SUCCESS:
    case AKTUALISIERE_EUFEMAS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default eufemas;
