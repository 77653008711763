import {
    FIRMA_ERSTELLEN_COMMAND_SUCCESS,
    FIRMA_ERSTELLEN_VIEW_DATA_ACTION_FAIL,
    FIRMA_ERSTELLEN_VIEW_DATA_ACTION_BEGIN,
    FETCH_FIRMA_ERSTELLEN_VIEW_DATA_SUCCESS,
    RESET_FIRMA_ERSTELLEN_VIEW_DATA_SUCCESS,
    EXISTIERT_FIRMA_SUCCESS,
} from '../../actions/zuko/firma-erstellen-view-data.js';

const firmaErstellenViewDataState = {
    firmaId: '',
    error: null,
    loading: false,
    firmaErstellenViewData: {},
    exisitiertFirma: false,
};

const savedState = localStorage.getItem('savedState') || JSON.stringify({ ...firmaErstellenViewDataState });
const INITIAL_STATE = JSON.parse(savedState).firmaErstellenViewDataState || firmaErstellenViewDataState;

const firmaErstellenViewData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FIRMA_ERSTELLEN_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                firmaId: action.payload
            };
        case FIRMA_ERSTELLEN_VIEW_DATA_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case FIRMA_ERSTELLEN_VIEW_DATA_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FETCH_FIRMA_ERSTELLEN_VIEW_DATA_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                firmaErstellenViewData: action.payload
            };
        case EXISTIERT_FIRMA_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                exisitiertFirma: action.payload.result
            };
        case RESET_FIRMA_ERSTELLEN_VIEW_DATA_SUCCESS:
            return firmaErstellenViewDataState;
        default:
            return state;
    }
};

export default firmaErstellenViewData;