import {
    ERSTELLE_PROJEKTBETEILIGTEN_COMMAND_SUCCESS,
    PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_FAIL,
    PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_BEGIN,
    FETCH_PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_SUCCESS,
    RESET_PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_SUCCESS,
} from '../../actions/projekt/projekt-projektbeteiligten-erstellen-view-data.js';

const projektProjektbeteiligtenErstellenViewDataState = {
    error: null,
    loading: false,
    projektrollen: [],
};

const savedState = localStorage.getItem('savedState') || JSON.stringify({ ...projektProjektbeteiligtenErstellenViewDataState });
const INITIAL_STATE = JSON.parse(savedState).projektProjektbeteiligtenErstellenViewDataState || projektProjektbeteiligtenErstellenViewDataState;

const projektProjektbeteiligtenErstellenViewData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ERSTELLE_PROJEKTBETEILIGTEN_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FETCH_PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                projektrollen: action.payload
            };
        case RESET_PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_SUCCESS:
            return projektProjektbeteiligtenErstellenViewDataState;
        default:
            return state;
    }
};

export default projektProjektbeteiligtenErstellenViewData;