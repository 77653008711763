export const TRANSLATION_DE_DATENSCHUTZ = {
    BETROFFENRECHT: {
        TEIL1_LISTE1_ANFANG: "Ein ",
        TEIL1_LISTE1_ENDE:
            "unter anderem über die Kategorien der " +
            "verarbeiteten Daten, der Verarbeitungszwecke, die Speicherdauer " +
            "sowie etwaige Empfänger, gemäß Art. 15 DSGVO und § 34 BDSG. ",
        TEIL1_LISTE1_TEILFETT: "Recht auf Auskunft ",
        TEIL1_LISTE2_ANFANG: "Ein ",
        TEIL1_LISTE2_ENDE:
            "unrichtiger bzw. unvollständiger Daten, gemäß Art. 16 und 17 DSGVO und § 35 BDSG.",
        TEIL1_LISTE2_TEILFETT: "Recht auf Berichtigung oder Löschung ",
        TEIL1_LISTE3_ANFANG:
            "Unter den Voraussetzungen des Art. 18 DSGVO oder § 35 Abs. 1 S. 2 BDSG ein",
        TEIL1_LISTE3_TEILFETT: "Recht auf Einschränkung der Verarbeitung ",
        TEIL1_LISTE4_ANFANG: "Ein ",
        TEIL1_LISTE4_ENDE:
            "gegen die Verarbeitung gemäß Art. 21 " +
            "Abs.1 DSGVO, soweit die Datenverarbeitung aufgrund eines " +
            "berechtigten Interesses erfolgte.",
        TEIL1_LISTE4_TEILFETT: "Recht auf Widerspruch",
        TEIL1_LISTE5_ANFANG: "Ein ",
        TEIL1_LISTE5_ENDE:
            "einer abgegebenen Einwilligung mit " +
            "Wirkung für die Zukunft gemäß Art. 7 Abs. 3 DSGVO.",
        TEIL1_LISTE5_TEILFETT: "Recht auf Widerruf ",
        TEIL1_LISTE6_ANFANG: "Ein ",
        TEIL1_LISTE6_ENDE: "in einem gängigen Format gemäß Art. 20 DSGVO.",
        TEIL1_LISTE6_TEILFETT: "Recht auf Datenübertragbarkeit ",
        TEIL1_LISTE7_ANFANG: " Sie haben gemäß Art. 22 DSGVO ein ",
        TEIL1_LISTE7_ENDE:
            "die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in " +
            "ähnlicher Weise erheblich beeinträchtigt. Dies umfasst auch " +
            "Profiling im Sinne des Art. 4 Nr. 4 DSGVO.",
        TEIL1_LISTE7_TEILFETT:
            "Recht darauf, nicht einer ausschließlich auf einer " +
            "automatisierten Verarbeitung beruhenden Entscheidung unterworfen " +
            "zu werden, ",
        TEIL1_LISTE8_ANFANG:
            "Sie haben zudem das Recht, sich gemäß Art. 77 DSGVO bei einer ",
        TEIL1_LISTE8_ENDE:
            "über die Verarbeitung Ihrer " +
            "personenbezogenen Daten durch uns zu beschweren, insbesondere in dem " +
            "Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres " +
            "Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes.",
        TEIL1_LISTE8_TEILFETT: "Datenschutz-Aufsichtsbehörde ",
        TEIL1_TEXT:
            "Als betroffene Person haben Sie uns gegenüber folgende Rechte " +
            "hinsichtlich Ihrer personenbezogenen Daten. Sie haben:",
        TEIL1_UEBERSCHRIFT: "4.1 Ihre Rechte als betroffene Person",
        TEIL2_TEXT:
            "Wenn Sie Ihre Rechte nach der DSGVO und dem BDSG uns gegenüber geltend " +
            "machen, werden wir die von Ihnen dabei an uns übermittelten Daten " +
            "verarbeiten, um Ihren Anspruch zu erfüllen. Im Anschluss speichern wir " +
            "die von Ihnen an uns übermittelten Daten und die von uns an Sie im " +
            "Gegenzug übermittelten Daten zum Zwecke der Dokumentation bis zum Ablauf " +
            "der ordnungswidrigkeitenrechtlichen Verjährungsfrist (3 Jahre). Die " +
            "Rechtsgrundlage für die Verarbeitung und Speicherung der Daten ist Art. " +
            "6 Abs. 1 S. 1 Buchstabe f) DSGVO (Berechtigtes Interesse an der " +
            "Datenverarbeitung). Das berechtigte Interesse ergibt sich aus unserer " +
            "Verpflichtung Ihrem Anliegen nachzukommen und dem Bedarf, uns in einem " +
            "möglichen Bußgeldverfahren entlasten zu können, indem wir nachweisen, " +
            "dass wir Ihrem Anliegen ordnungsgemäß nachgekommen sind. Der " +
            "Verarbeitung Ihrer Daten auf Grundlage unseres berechtigten Interesses " +
            "können Sie jederzeit unter den Voraussetzungen des Art. 21 DSGVO " +
            "widersprechen. Nutzen Sie hierfür bitte die im Impressum genannten " +
            "Kontaktdaten. Wir weisen aber darauf hin, dass die Verarbeitung Ihrer " +
            "Daten zum Nachweis der Einhaltung der Betroffenenrechte zwingend im " +
            "Sinne des Art. 21 Abs. 1 DSGVO ist, da andere Nachweismöglichkeiten " +
            "nicht bestehen bzw. nicht gleichermaßen geeignet sind.",
        TEIL2_UEBERSCHRIFTT: "4.2 Verfahren",
        UEBERSCHRIFT: "4. BETROFFENENRECHTE",
    },
    DATENSCHUTZMAßNAHMEN: {
        TEXT:
            "Wir sichern unser Webseite und sonstigen Systeme – und damit auch Ihre " +
            "Daten – durch technische und organisatorische Maßnahmen gegen Verlust, " +
            "Zerstörung, Zugriff, Veränderung oder Verbreitung durch unbefugte " +
            "Personen ab. Insbesondere werden Ihre persönlichen Daten verschlüsselt " +
            "durch das Internet übertragen. Wir bedienen uns dabei des " +
            "Codierungssystems TLS (Transport Layer Security). Jedoch ist die " +
            "Übertragung von Informationen über das Internet nie vollständig sicher, " +
            "weshalb wir die Sicherheit, der von unserer Webseite übermittelten " +
            "Daten nicht zu 100% garantieren können.",
        UEBERSCHRIFT: "5. DATENSCHUTZMAßNAHMEN",
    },
    DATENSCHUTZ_ALLGEMEINES: {
        INFORMATION: "I. Informationen zum Datenschutz",
        TEXT:
            "Die Einhaltung der Datenschutzgesetze ist für die cpc baulogistik GmbH " +
            "nicht nur gesetzliche Pflicht, sondern auch ein wichtiger " +
            "Vertrauensfaktor. Mit den nachfolgenden Datenschutzbestimmungen möchten " +
            "wir Sie deshalb transparent über Art, Umfang und Zweck der von Ihnen " +
            "erhobenen und verarbeiteten personenbezogenen Daten innerhalb dieses " +
            "Internetauftritts sowie Ihre Rechte informieren. ",
        UEBERSCHRIFT: "1. ALLGEMEINES",
    },
    DATENSCHUTZ_BEAUFTRAGTER: {
        TEIL0: "Verantwortlicher Datenschutzbeauftragter ist:",
        TEIL1: "Süddeutsche Datenschutzgesellschaft GmbH",
        TEIL2: "Ansprechpartner",
        UEBERSCHRIFT: "3. DATENSCHUTZBEAUFTRAGTER",
    },
    DATENSCHUTZ_VERANTWORTLICHKEIT: {
        TEIL1:
            "Die cpc baulogistik GmbH, Hauptstraße 65, 12159 Berlin, Deutschland (Im " +
            "Folgenden: „Wir“) ist als betreiber des Webportals",
        TEIL2:
            ", Verantwortlicher gem. Art. 4 Nr. 7 der EU-Datenschutz-Grundverordnung " +
            "(DSGVO). Bei Fragen können Sie sich an",
        TEIL3: "wenden.",
        UEBERSCHRIFT: "2. VERANTWORTLICHKEIT FÜR DIE DATENVERARBEITUNG",
    },
    DATENVERARBEITUNG: {
        TEIL1_TEXT:
            "Wir verarbeiten Ihre personenbezogenen Daten, soweit sie für die " +
            "Begründung, inhaltliche Ausgestaltung oder Änderung eines " +
            "Vertragsverhältnisses zwischen uns und Ihnen erforderlich sind " +
            "(Bestandsdaten). Bestandsdaten können insbesondere sein: Name, Anrede, " +
            "Kontaktdaten (Postadresse, Telefon, E-Mail-Adresse), Geburtsdatum usw. " +
            "Ferner verarbeiten wir Ihre Nutzungsdaten. Nutzungsdaten sind Daten, die " +
            "durch Ihr Verhalten bei der Nutzung unseres Webangebots und unserer " +
            "Dienste anfallen, insbesondere Ihre IP-Adresse, Beginn und Ende Ihres " +
            "Besuchs auf unserer Webseite und Informationen darüber, welche Inhalte " +
            "Sie auf unserer Webseite abgerufen haben. Die genannten Daten erheben " +
            "wir entweder direkt bei Ihnen (z.B. durch den Besuch der Webseite) " +
            "oder, soweit dies nach den Datenschutzgesetzen zulässig ist, bei Dritten " +
            "bzw. aus öffentlich zugänglichen Quellen (z.B. Handels- und " +
            "Vereinsregister, Presse, Medien, Internet).",
        TEIL1_UEBERSCHRIFT:
            "6.1 Quellen und Kategorien von personenbezogenen Daten",
        TEIL2_TEXT:
            "Alle Informationen, die wir von Ihnen oder über Sie erhalten, werden " +
            "grundsätzlich auf Servern innerhalb der Europäischen Union verarbeitet. " +
            "Eine Übermittlung Ihrer Daten an oder eine Verarbeitung Ihrer Daten in " +
            "Drittstaaten erfolgt ohne Ihre ausdrückliche Einwilligung lediglich, " +
            "sofern dies gesetzlich vorgesehen oder gestattet ist, in dem Drittstaat " +
            "ein hierfür angemessenes Datenschutzniveau sichergestellt ist oder " +
            "vertragliche Verpflichtungen durch sogenannte " +
            "Standarddatenschutzklauseln der EU bestehen. Betreffend Datenübertragungen " +
            "in die USA hat die europäische Kommission einen Angemessenheitsbeschluss " +
            "namens EU-U.S. Data Privacy Framework (deutsch: EU-US-Datenschutzrahmen) " +
            "erlassen, durch den ein angemessenes Schutzniveau für den Datentransfer " +
            "personenbezogener Daten durch Unternehmen gewährleistet wird, die am EU-USA " +
            "Datenschutzrahmen teilnehmen. Soweit wir Dienste verwenden, die personenbezogene Daten in die" +
            "USA übermitteln, steht bei dem jeweiligen Dienst, ob das Unternehmen durch den EU-U.S. " +
            "Datenschutzrahmen zertifiziert ist.",
        TEIL2_UEBERSCHRIFT:
            "6.2 Datenübermittlung an Drittstaaten außerhalb der EU",
        TEIL3_TEXT:
            "Wir geben Ihre personenbezogenen Daten niemals unberechtigt an Dritte " +
            "  weiter. Wir können Ihre Daten aber insbesondere an Dritte weitergeben, " +
            "wenn Sie in die Datenweitergabe eingewilligt haben, wenn die Weitergabe " +
            "zur Erfüllung unserer rechtlichen Verpflichtungen notwendig ist oder " +
            "wenn wir aufgrund gesetzlicher Bestimmungen oder behördlicher oder " +
            "gerichtlicher Anordnungen zu einer Datenweitergabe berechtigt oder " +
            "verpflichtet sind. Dabei kann es sich insbesondere um die " +
            "Auskunftserteilung für Zwecke der Strafverfolgung, zur Gefahrenabwehr " +
            "oder zur Durchsetzung geistiger Eigentumsrechte handeln. Unter Umständen übermitteln " +
            "wir Ihre Daten außerdem an externe Dienstleister, die Daten in unserem Auftrag " +
            "und nach unserer Weisung verarbeiten (Auftragsverarbeiter), um unsere eigene " +
            "Datenverarbeitung zu vereinfachen oder zu entlasten. Jeder Auftragsverarbeiter wird durch einen " +
            "Vertrag nach Maßgabe des Art. 28 DSGVO verpflichtet. Das heißt insbesondere, dass der " +
            "Auftragsverarbeiter hinreichend Garantien dafür zu bieten hat, dass durch ihn geeignete technische und organisatorische " +
            "Maßnahmen so durchgeführt werden, dass die Verarbeitung im Einklang mit " +
            "den Anforderungen der DSGVO erfolgt und der Schutz Ihrer Rechte als " +
            "betroffene Person gewährleistet ist. Trotz Beauftragung von " +
            "Auftragsverarbeitenden bleiben wir für die Verarbeitung Ihrer " +
            "personenbezogenen Daten die verantwortliche Stelle im Sinne der " +
            "Datenschutzgesetze.",
        TEIL3_UEBERSCHRIFT: "6.3 Weitergabe von Daten, Auftragsverarbeitung",
        TEIL4_TEXT:
            "Wir nutzen die Daten grundsätzlich nur zu dem Zweck, zu dem die Daten " +
            "von Ihnen erhoben wurden. Wir können die Daten zu einem anderen Zweck " +
            "weiterverarbeiten, wenn nicht dieser andere Zweck mit dem ursprünglichen " +
            "Zweck unvereinbar ist (Art. 5 Abs. 1 Lit. c) DSGVO).",
        TEIL4_UEBERSCHRIFT: "6.4 Zweck der Datenverarbeitung",
        TEIL5_TEXT:
            "Soweit im Einzelnen nichts anderes angegeben ist, speichern wir von " +
            "Ihnen erhobene Daten nur so lange, wie es für den jeweiligen Zweck " +
            "erforderlich ist, es sei denn es stehen gesetzliche " +
            "Aufbewahrungspflichten der Löschung entgegen, z.B. aus dem Handelsrecht " +
            "oder dem Steuerrecht.",
        TEIL5_UEBERSCHRIFT: "6.5 Speicherdauer",
        UEBERSCHRIFT: "6. MODALITÄTEN DER DATENVERARBEITUNG",
    },
    DIENST_DRITTER: {
        TEIL1_TEIL1_TEXT1:
            "Wir nutzen den Dienst „Visual Studios“ der Firma Microsoft Ireland Operations Limited, One " +
            "Microsoft Place, South County Business Park, Leopardstown, Dublin 18 D18 P521, Irland. Microsoft " +
            "Ireland Operations Limited ist eine Tochtergesellschaft der Firma Microsoft Corporation " +
            "(„Microsoft“), One Microsoft Way, Redmond, WA 98052-6399, USA.",
        TEIL1_TEIL1_TEXT2:
            "Es ist nicht ausgeschlossen, dass Microsoft Daten in die USA überträgt. Die Firma Microsoft " +
            "Corporation erfüllt die Anforderungen des „EU-U.S. Data Privacy Frameworks“ (zu Deutsch: EU-US-" +
            "Datenschutzrahmen). Das Privacy-Framework-Abkommen regelt den Schutz personenbezogener " +
            "Daten, die aus einem Mitgliedsstaat der Europäischen Union in die USA übertragen werden. Es stellt " +
            "sicher, dass die übertragenen Daten dort ebenfalls einem der Europäischen Union vergleichbaren " +
            "Datenschutzniveau unterliegen. Die Liste zertifizierter Unternehmen können Sie hier abrufen:",
        TEIL1_TEIL1_TEXT3:
            "Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von " +
            "Microsoft:",
        TEIL1_TEIL1_UEBERSCHRIFT: "8.1.1 Microsoft Visual Studios",
        TEIL1_TEIL2_TEIL1_TEXT1:
            "Um die Zuverlässigkeit und Stabilität unseres Webportals zu " +
            "gewährleisten, nutzen wir den Dienst Microsoft Insights der Firma " +
            "Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA. " +
            "Hierdurch werden Daten an den Anbieter übermittelt und dort in der Regel " +
            "gespeichert, z.B. Ihre IP-Adresse, Produkt- und Versionsinformationen " +
            "über den verwendeten Browser und das Betriebssystem (sog. user agent), " +
            "die Internetseite von welcher Ihr Zugriff stattfand (sog. Referer), " +
            "Datum und Uhrzeit der Anfrage und u.U. Ihr Internet-Service-Provider. " +
            "Die Rechtsgrundlage für die Nutzung des Dienstes ist Art. 6 Abs. 1 S. 1 " +
            "Buchstabe f) DSGVO (Berechtigtes Interesse an der Datenverarbeitung). " +
            "Wir verfolgen mit dem Dienst das Interesse, unser Webportal konstant zu " +
            "warten und verfügbar zu halten. Der Verarbeitung Ihrer Daten auf " +
            "Grundlage unseres berechtigten Interesses können Sie jederzeit unter den " +
            "Voraussetzungen des Art. 21 DSGVO widersprechen. Nutzen Sie hierfür " +
            "bitte die im Impressum genannten Kontaktdaten.",
        TEIL1_TEIL2_TEIL1_TEXT2:
            "Mehr Informationen zum " +
            "Umgang mit personenbezogenen Nutzungsdaten finden Sie in der Datenschutzerklärung von " +
            "Microsoft:",
        TEIL1_TEIL2_TEIL1_TEXT3: "und",
        TEIL1_TEIL2_TEIL1_TEXT4: "Stand der Datenschutzbestimmungen:",
        TEIL1_TEIL2_TEIL1_TEXT5: "Quelle",
        TEIL1_TEIL2_TEIL1_UEBERSCHRIFT: "8.2.1 Jira Issue Collector",
        TEIL1_TEIL2_UEBERSCHRIFT: "8.2 Cloud-Anwendungen",
        TEIL1_TEXT1:
            "Unsere Webseite basiert auf einem sogenannten Website-Baukasten. Dies ist ein Service, bei dem " +
            "uns ohne tiefere Kenntnisse von Website-Programmierung ermöglicht wird, eine Webseite " +
            "anzubieten, indem die Erstellung und Wartung der Webseite über eine cloudbasierte " +
            "Benutzeroberfläche vereinfacht wird.",
        TEIL1_TEXT2:
            "Der Begriff „cloudbasiert“ bedeutet, dass uns die Wartungsoberfläche von einem Drittanbieter auf " +
            "dessen Servern für uns bereitgestellt wird. Bei der Nutzung dieser Anwendung fallen bei dem " +
            "Drittanbieter persönliche Daten an, die dieser in unserem Auftrag nach unseren und seinen " +
            "Datenschutzbestimmungen verarbeiten kann. Gleichzeitig stellt der Anbieter in der Regel die " +
            "Infrastruktur bereit, die zum Betrieb der Webseite benötigt wird.",
        TEIL1_TEXT3:
            "Bei jedem Aufruf der Anwendung werden allgemeine Informationen von Ihrem Browser automatisch " +
            "an den Server übermittelt (sog. Server-Log-Dateien). Nähere Informationen hierzu siehe oben unter " +
            "„Server-Log-Dateien“.",
        TEIL1_TEXT4:
            "Die Rechtsgrundlage für die Nutzung von cloudbasierten Website-Baukästen samt Hosting-Dienstleistung " +
            "und die damit verbundene Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 S. 1 Buchstabe f) " +
            "DSGVO (Berechtigtes Interesse an der Datenverarbeitung), soweit bei dem jeweiligen Dienst nichts " +
            "anderes angegeben ist. Das berechtigte Interesse ergibt sich aus unserem Bedarf an einer technisch " +
            "einwandfreien Darstellung unserer Webseite, ohne dass wir dafür vertiefte Kenntnisse der " +
            "Programmierung von Webseiten und Wartung von IT-Systemen benötigen. Der Verarbeitung Ihrer " +
            "Daten auf Grundlage unseres berechtigten Interesses können Sie jederzeit unter den " +
            "Voraussetzungen des Art. 21 DSGVO widersprechen. Nutzen Sie hierfür bitte die im Impressum " +
            "genannten Kontaktdaten.",
        TEIL1_TEXT5: "Wir verwenden:",
        TEIL1_UEBERSCHRIFT: "8.1 Website-Baukästen",
        TEIL1:
            "Wir nutzen, zur Vereinfachung unserer Datenverarbeitung und um den Funktionsumfang unserer " +
            "Webseite zu erweitern, Dienstleistungen/Ressourcen von Dritten, beispielsweise Plugins, externe " +
            "Inhalte, Software oder sonstige externe Dienstleister (Dienste). Dabei werden möglicherweise auch " +
            "personenbezogene Daten an den Diensteanbieter übermittelt. Um Ihre Daten zu schützen, haben wir " +
            "die Diensteanbieter erforderlichenfalls gem. Art. 28 DSGVO vertraglich verpflichtet, Ihre Daten nur " +
            "nach unserer Anweisung zu verarbeiten.",
        TEIL2:
            "Wir weisen ausdrücklich darauf hin, dass wir regelmäßig nur für die Datenerhebung und " +
            "Übermittlung durch den Dienst verantwortlich im Sinne der DSGVO sind, nicht aber für eine eventuell " +
            "später erfolgende Verarbeitung durch den jeweiligen Diensteanbieter.",
        TEIL2_TEXT1:
            "Unsere Webseite bedient sich cloudbasierter Anwendungen. Unter cloudbasierten Anwendungen " +
            "sind zum Beispiel Webshops zu verstehen, die von einem Drittanbieter auf dessen Servern für uns " +
            "bereitgestellt werden. Bei der Nutzung dieser Anwendungen durch Sie fallen bei dem Drittanbieter " +
            "persönliche Daten an, die dieser in unserem Auftrag nach unseren und seinen " +
            "Datenschutzbestimmungen verarbeiten kann.",
        TEIL2_TEXT2:
            "Bei jedem Aufruf der Anwendung werden allgemeine Informationen von Ihrem Browser automatisch " +
            "an den Server übermittelt (sog. Server-Log-Dateien). Nähere Informationen hierzu siehe oben unter " +
            "„Server-Log-Dateien“.",
        TEIL2_TEXT3:
            "Die Rechtsgrundlage für die Nutzung von Cloudanwendungen und die Übermittlung Ihrer Daten an " +
            "diese ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO (Berechtigtes Interesse an der Datenverarbeitung), " +
            "soweit bei dem jeweiligen Dienst nichts anderes angegeben ist. Das berechtigte Interesse ergibt sich " +
            "aus unserem Bedarf an einer technisch einwandfreien und schnellen Darstellung unserer Webseite " +
            "und der Entlastung unserer IT-Infrastruktur. Der Verarbeitung Ihrer Daten auf Grundlage unseres " +
            "berechtigten Interesses können Sie jederzeit unter den Voraussetzungen des Art. 21 DSGVO " +
            "widersprechen. Nutzen Sie hierfür bitte die im Impressum genannten Kontaktdaten.",
        TEIL2_TEXT4: "Wir verwenden:",
        TEIL2_TEIL1_TEXT1:
            "Unsere Webseite nutzt den Dienst „Jira Issue Collector“ der Atlassian. Pty Ltd., Level 6, 341 George " +
            "Street, Sydney NSW 2000, Australien.",
        TEIL2_TEIL1_TEXT2:
            "Jira Issue Collector dient dazu, benutzerdefinierte Formulare zum Einreichen von Problemen zu " +
            "generieren, ohne dass Nutzer für die Problemmeldung die Seite verlassen müssen.",
        TEIL2_TEIL1_TEXT3:
            "Durch den Dienst können wir Informationen zu Problemen auf einfachem Weg sammeln, was uns " +
            "hilft, unsere Webseite stabil zu betreiben und die Nutzerfreundlichkeit zu verbessern.",
        TEIL2_TEIL1_TEXT4:
            "Wir haben mit dem Anbieter einen Auftragsverarbeitungsvertrag abgeschlossen. Um ein " +
            "angemessenes Datenschutzniveau bei der Übermittlung von Daten nach Australien zu gewährleisten, " +
            "haben wir mit Atlassian die EU-Standardvertragsklauseln abgeschlossen.",
        TEIL2_TEIL1_TEXT5:
            "Die Rechtsgrundlage für die Nutzung von Jira Issue Collector ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO " +
            "(Berechtigtes Interesse an der Datenverarbeitung). Das berechtigte Interesse ergibt sich aus unserem " +
            "Bedarf an einer technisch einwandfreien und sicheren Webseite, und der Möglichkeit, dass Nutzer " +
            "Probleme auf einfache Art und Weise an uns übermitteln können. Der Verarbeitung Ihrer Daten auf " +
            "Grundlage unseres berechtigten Interesses können Sie jederzeit unter den Voraussetzungen des " +
            "Art. 21 DSGVO widersprechen. Nutzen Sie hierfür bitte die im Impressum genannten Kontaktdaten",
        TEIL2_TEIL1_TEXT6:
            "Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Atlassian: ",
        TEIL3: "Im Einzelnen nutzen wir folgende Dienste:",
        TEIL3_TEXT1:
            "Wir nutzen bestimmte Dienste, die Daten von Webseitenbesuchern aufzeichnen und uns zur Analyse " +
            "zur Verfügung stellen. Diese Daten nutzen wir zur nutzungsorientierten Verbesserung unserer " +
            "Webseite, unserer Dienstleistungen und Angebote. Insbesondere können folgende Informationen " +
            "zum Besucherverhalten gesammelt werden: Ihre IP-Adresse, Produkt- und Versionsinformationen " +
            "über den verwendeten Browser und das Betriebssystem (sog. user agent), die Internetseite von " +
            "welcher Ihr Zugriff stattfand (sog. Referer), Datum und Uhrzeit der Anfrage und u.U. Ihr Internet-" +
            "Service-Provider, ungefähre Herkunft (Land und Stadt), Sprache, Klicks auf Inhalte und Ansichtsdauer.",
        TEIL3_TEXT2:
            "Die Rechtsgrundlage für die Nutzung von Analyse-Tools ist Art. 6 Abs. 1 S. 1 Buchstabe a) DSGVO " +
            "(Einwilligung des Betroffenen). Wir bitten Sie beim ersten Aufruf der Webseite über einen " +
            "eingeblendeten Hinweistext um Ihr Einverständnis zur Verwendung des Dienstes. Ihre Einwilligung " +
            "können Sie jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie den Cookie-Banner erneut " +
            "aufrufen und getroffenen Einstellungen ändern.",
        TEIL3_UEBERSCHRIFT: "8.3 Analyse-Tools",
        TEIL4_TEXT1: "Unsere Webseite nutzt den Dienst „Application Insights“ der Firma Microsoft Ireland Operations " +
        "Limited, One Microsoft Place, South County Business Park, Leopardstown, Dublin 18 D18 P521, " +
        "Irland. Microsoft Ireland Operations Limited ist eine Tochtergesellschaft der Firma Microsoft " +
        "Corporation („Microsoft“), One Microsoft Way, Redmond, WA 98052-6399, USA.",
        TEIL4_TEXT2: "Der Dienst ermöglicht es uns, eine Analyse des Nutzerverhaltens auf der Webseite durchzuführen. " +
        "Dabei werden Daten, wie beispielsweise die IP-Adresse, Häufigkeit des Besuchs, Ort und Zeit der " +
        "Anfrage erhoben und an Server von Microsoft in den USA übertragen. " +
        "Wir nutzen dabei die Anonymisierungsfunktion, wodurch sämtliche erhobenen Daten direkt nach der " +
        "Erhebung anonymisiert werden.",
        TEIL4_TEXT3: "Die Firma Microsoft Corporation erfüllt die Anforderungen des „EU-U.S. Data Privacy Frameworks“ " +
        "(zu Deutsch: EU-US-Datenschutzrahmen). Das Privacy-Framework-Abkommen regelt den Schutz " +
        "personenbezogener Daten, die aus einem Mitgliedsstaat der Europäischen Union in die USA " +
        "übertragen werden. Es stellt sicher, dass die übertragenen Daten dort ebenfalls einem der " +
        "Europäischen Union vergleichbaren Datenschutzniveau unterliegen. Die Liste zertifizierter " +
        "Unternehmen können Sie hier abrufen",
        TEIL4_TEXT4: "Bei der Nutzung des Diensts werden Cookies am Endgerät des Webseitenbesuchers gespeichert. " +
        "Wir haben mit dem Anbieter einen Auftragsverarbeitungsvertrag abgeschlossen.",
        TEIL4_TEXT5: "Weitere Informationen zum Datenschutz bei Microsoft finden Sie unter:",
        TEIL4_TEXT6: "Stand der Datenschutzerklärung: ",
        TEIL4_UEBERSCHRIFT: "8.3.1 Microsoft Application Insights",
        UEBERSCHRIFT: "8. DIENSTE-DRITTER",
    },
    NUTZUNGBEDINGUNGEN_UEBERSCHRITFT: "Allgemeine Nutzungsbedingungen",
    UEBERSCHRITFT: "Datenschutzerklärung",
    VERARBEITUNGSTAETIGKEITEN: {
        TABELLE_HEADER_COLUMN1: "Name",
        TABELLE_HEADER_COLUMN2: "Erläuterung",
        TABELLE_HEADER_COLUMN3: "Herkunft (Domain)",
        TABELLE_HEADER_COLUMN4: "Gültigkeit/ Speicherdauer",
        TABELLE_HEADER_COLUMN5: "Zugriff Dritter",
        TABELLE_ROW1_COLUMN1: "Ai_session",
        TABELLE_ROW1_COLUMN2:
            "Dieses Cookie dient dazu, zu erkennen, wie viele Sitzungen der Benutzeraktivität " +
            "bestimmte Seiten und Funktionen der App einbezogen haben. ",
        TABELLE_ROW1_COLUMN3: "algo.cpc-baulogistik.de",
        TABELLE_ROW1_COLUMN4: "30 Minuten",
        TABELLE_ROW1_COLUMN5: "Ja, Microsoft",
        TABELLE_ROW2_COLUMN1: "Ai_user",
        TABELLE_ROW2_COLUMN2:
            "Dieses Cookie dient dazu, zu ermitteln, wie viele Personen die App und ihre " +
            "Funktionen genutzt haben. Die Nutzer werden mit anonymen IDs gezählt. ",
        TABELLE_ROW2_COLUMN3: "algo.cpc-baulogistik.de",
        TABELLE_ROW2_COLUMN4: "1 Jahr",
        TABELLE_ROW2_COLUMN5: "Ja, Microsoft",
        TABELLE_ROW3_COLUMN1: "ARRAffinity- SameSite",
        TABELLE_ROW3_COLUMN2:
            "Dieses Cookie wird verwendet, um das effektive Laden der Website " +
            "zu steuern und sicherzustellen, dass alle vom Nutzer getätigeten Anfragen " +
            "jeder Browsersitzung an denselben Server geleitet werden. ",
        TABELLE_ROW3_COLUMN3: "algo.cpc-baulogistik.de",
        TABELLE_ROW3_COLUMN4: "Sitzungsende",
        TABELLE_ROW3_COLUMN5: "Ja, Microsoft",
        TABELLE_ROW4_COLUMN1: "Token",
        TABELLE_ROW4_COLUMN2:
            "Diese Cookies dienen der Sicherheit der Webseite und dem Schutz des Nutzers " +
            "gegen CSRF- (Cross-Site-Request-Forgery) Attacken, in dem sie jede Anfrage " +
            "vom Client an den Server mit einem eindeutigen „Token“ versehen, der sicher " +
            "stellt, dass der Request vom Client kommt. Die einzelnen hier aufgeführten " +
            "Cookies werden bei der jeweiligen Ausführung der spezifischen Handlung " +
            "(z.B. Newsletteranmeldung, Profiländerungen, betätigung der Schaltflächen beim Formular) gesetzt " +
            "und dienen dem Csrf-Schutz der jeweiligen spezifischen Handlung.",
        TABELLE_ROW4_COLUMN3: "algo.cpc-baulogistik.de",
        TABELLE_ROW4_COLUMN4: "Sitzungsende",
        TABELLE_ROW4_COLUMN5: "Nein",
        TABELLE_NEW_ROW4_COLUMN1: "ARRAffinity",
        TABELLE_NEW_ROW4_COLUMN2:
            "Dieses Cookie wird verwendet, um den Verkehr auf der Website auf mehreren " +
            "Servern zu verteilen, um die Antwortzeiten zu optimieren.",
        TABELLE_NEW_ROW4_COLUMN3: "algo.cpc-baulogistik.de",
        TABELLE_NEW_ROW4_COLUMN4: "Sitzungsende",
        TABELLE_NEW_ROW4_COLUMN5: "Ja, Microsoft",
        TEIL1_TEXT:
            "Bei jedem Aufruf eines Webportals und bei jedem Abruf von Daten von " +
            "einem Server werden allgemeine Informationen automatisch an den " +
            "bereitstellenden Server übermittelt. Diese Datenübermittlung erfolgt " +
            "automatisch und ist ein grundlegender Bestandteil der Kommunikation " +
            "zwischen Geräten im Internet. Die standardmäßig übertragenen Daten " +
            "umfassen unter anderem folgende Informationen: Ihre IP-Adresse, " +
            "Produkt- und Versionsinformationen über den verwendeten Browser und " +
            "das Betriebssystem (sog. user agent), die Internetseite von welcher " +
            "Ihr Zugriff stattfand (sog. referer), Datum und Uhrzeit der Anfrage " +
            "(sog. timestamp). Außerdem werden der http-Status und die übertragene " +
            "Datenmenge im Rahmen dieser Anfrage erfasst. Diese Informationen " +
            "werden vom Server protokolliert, in einer Tabelle abgelegt und dort " +
            "kurzfristig gespeichert (sog. Server-Log-Dateien). Durch die Analyse " +
            "dieser Log-Dateien können wir Fehler der Webseite feststellen und " +
            "anschließend beseitigen, die Auslastung der Webseite zu bestimmten " +
            "Zeiten feststellen und darauf aufbauend Anpassungen oder " +
            "Verbesserungen vornehmen sowie die Sicherheit des Servers gewährleisten, " +
            "indem wir nachvollziehen können, von welcher IP-Adresse Angriffe auf " +
            "unseren Server ausgeführt wurden. Ihre IP-Adresse wird nur für die " +
            "Zeit Ihrer Nutzung der Webseite gespeichert und im Anschluss daran " +
            "unverzüglich gelöscht oder durch Kürzung teilweise unkenntlich " +
            "gemacht. Die übrigen Daten werden für eine begrenzte Zeitdauer (meist " +
            "7 Tage) gespeichert. Die Rechtsgrundlage für die Nutzung der " +
            "Server-Log-Dateien ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO " +
            "(Berechtigtes Interesse an der Datenverarbeitung). Das berechtigte " +
            "Interesse ergibt sich aus der Notwendigkeit für den Betrieb und die " +
            "Wartung unserer Webseite, wie wir vorstehend erläutert haben. Der " +
            "Verarbeitung Ihrer Daten auf Grundlage unseres berechtigten " +
            "Interesses können Sie jederzeit unter den Voraussetzungen des Art. " +
            "21 DSGVO widersprechen. Nutzen Sie hierfür bitte die im Impressum " +
            "genannten Kontaktdaten. Wir weisen aber bereits im Vorfeld darauf hin, " +
            "dass die Verarbeitung Ihrer Daten in Server-Log-Dateien zwingend im " +
            "Sinne des Art. 21 Abs. 1 DSGVO ist, da die Webseite ansonsten " +
            "überhaupt nicht betrieben werden kann.",
        TEIL1_UEBERSCHRIFT: "7.1 Server-Log-Dateien",
        TEIL2_TEIL1_TEXT1: "Was Cookies sind",
        TEIL2_TEIL1_TEXT10:
            "Im Einzelnen setzt unser Webportal folgende Cookies:",
        TEIL2_TEIL1_TEXT11: "Rechtsgrundlage",
        TEIL2_TEIL1_TEXT12:
            "Die Rechtsgrundlage für die Nutzung von Cookies, die für die Funktion " +
            "der Webseite zwingend erforderlich sind (z.B. Warenkorb-Cookie, Session-Cookie) ist Art. 6 Abs. 1 S. 1 Buchstabe f) " +
            "DSGVO (Berechtigtes Interesse an der Datenverarbeitung) sowie §25 Abs. 2 Nr. 2 TTDSG (unbedingte Erforderlichkeit zur " +
            "Bereitstellung eines vom Nutzer ausdrücklich gewünschten Telemediendienstes). Das berechtigte Interesse ergibt sich aus unserem Bedarf " +
            "Ihnen eine funktionierende Webseite anbieten zu können. Cookies sind dazu erforderlich, weil sie ein integraler " +
            "Bestandteil der aktuellen Internet-Technologie sind und ohne Cookies viele Funktionen aktueller Webseiten nicht zur Verfügung stünden. " +
            "Wir benötigen daher Cookies, um Ihnen die Webseite auf Ihre Anfrage zur Verfügung stellen zu können. Der Verarbeitung Ihrer Daten auf Grundlage " +
            "unseres berechtigten Interesses können Sie jederzeit unter den Voraussetzungen des Art. 21 DSGVO widersprechen. " +
            "Nutzen Sie hierfür bitte die im Impressum genannten Kontaktdaten. Wir weisen aber darauf hin, dass die Verarbeitung Ihrer Daten in bestimmten Cookies " +
            "zwingend im Sinne des Art. 21 Abs. 1 DSGVO ist, da die Webseite ansonsten überhaupt nicht betrieben werden kann und wir technisch nicht die Möglichkeit " +
            "haben, das Setzen von Cookies auf bestimmten individuellen Endgeräten zu unterbinden. Sie können das in Ihrem Browser aber möglicherweise selbst tun. " +
            "Werfen Sie für weitere Informationen hierzu bitte einen Blick in die Anleitung Ihres Browsers. " +
            "Die Rechtsgrundlage für die Nutzung von Cookies, die für die Funktion der Webseite nicht zwingend erforderlich sind, ist Art. 6 Abs. 1 S. 1 Buchstabe a) " +
            " DSGVO (Einwilligung des Betroffenen). Wir bitten Sie beim ersten Aufruf der Webseite über einen eingeblendeten Hinweistext um Ihr Einverständnis zur " +
            "Verwendung von nicht notwendigen Cookies. Ihre Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie den Cookie-Banner " +
            "erneut aufrufen und getroffenen Einstellungen ändern.",
        TEIL2_TEIL1_TEXT2:
            "Sehr vereinfacht gesagt ist ein Cookie eine kleine Textdatei, die Daten " +
            "über besuchte Webseiten speichert. Cookies können in vielfacher Weise " +
            "eingesetzt werden. Sie können zum Beispiel eine Art „Benutzerprofil“ " +
            "speichern, also Dinge wie Ihre bevorzugte Sprache und andere " +
            "Seiteneinstellungen, die von unserer Webseite benötigt werden, um Ihnen " +
            "bestimmte Dienste anbieten zu können. Die Cookie-Datei wird auf Ihrem " +
            "Endgerät gespeichert und kann auch dabei helfen, Sie bei einem erneuten " +
            "Aufruf unseres Webportals wiederzuerkennen.",
        TEIL2_TEIL1_TEXT3:
            "Unter Umständen können wir durch die Cookies außerdem Informationen über " +
            "Ihre bevorzugten Aktivitäten auf unserer Webseite erhalten und unsere " +
            "Webseite so an Ihren individuellen Interessen ausrichten oder sogar die " +
            "Geschwindigkeit der Navigation auf unserer Webseite erhöhen.",
        TEIL2_TEIL1_TEXT4: "Wie Sie Cookies vermeiden können",
        TEIL2_TEIL1_TEXT5:
            "Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers " +
            "jederzeit manuell löschen.",
        TEIL2_TEIL1_TEXT6:
            " Sie können die Speicherung von Cookies aber auch durch eine " +
            "entsprechende Einstellung Ihres Browsers von vornherein verhindern. " +
            "Bitte beachten Sie aber, dass Sie dann möglicherweise nicht sämtliche " +
            "Funktionen unserer Webseite vollumfänglich nutzen können oder dass es " +
            "gegebenenfalls zu Fehlern in der Darstellung und Nutzung der Webseite " +
            "kommen kann.",
        TEIL2_TEIL1_TEXT7: "Cookies von Drittanbietern",
        TEIL2_TEIL1_TEXT8:
            "Es ist möglich, dass Drittanbieter, mithilfe derer wir unsere Seite " +
            "gestalten und betreiben, insbesondere durch sogenannte Plugins (siehe " +
            "unten im Abschnitt „Dienste Dritter“), selbständig eigene Cookies auf " +
            "Ihrem Endgerät speichern. Sollten Sie nur unsere eigenen Cookies, nicht " +
            "aber Cookies dieser Dritten, akzeptieren wollen, können Sie die " +
            "Speicherung dieser Cookies durch die entsprechende Browsereinstellung " +
            "„Cookies von Drittanbietern blockieren” verhindern.",
        TEIL2_TEIL1_TEXT9: "Welche Cookies eingesetzt werden",
        TEIL2_TEIL1_UEBERSCHRIFT: "7.2.1 Cookies",
        TEIL2_TEIL2_TEXT1: "Was ist der Web Storage",
        TEIL2_TEIL2_TEXT2:
            "Der Web Storage ist eine Technik für Webanwendungen, mit der Daten in " +
            "einem Webbrowser gespeichert werden. Der Web Storage kann vereinfacht " +
            "als eine Weiterentwicklung von Cookies angesehen werden, unterscheidet " +
            "sich von diesen jedoch in einigen Punkten.",
        TEIL2_TEIL2_TEXT3:
            "Im Gegensatz zu Cookies, auf die sowohl Server als auch Client zugreifen " +
            "können, wird der Web Storage vollständig vom Client gesteuert. Das " +
            "heißt, es werden hierbei nicht mit jedem Aufruf der Webseite Daten zum " +
            "Server übertragen. Der Zugriff erfolgt ausschließlich lokal über Skripte " +
            "auf der Webseite. Konkret bedeutet das, dass ein Zugriff Dritter auf " +
            "die gespeicherten Informationen über die Webseite ausgeschlossen ist. " +
            "Nur Sie und wir können auf die lokal gespeicherten Daten zugreifen. ",
        TEIL2_TEIL2_TEXT4: "Rechtsgrundlage",
        TEIL2_TEIL2_TEXT5:
            " Die Rechtsgrundlage für die Nutzung des Web Storage, die für die " +
            "Funktion der Webseite zwingend erforderlich ist, ist Art. 6 Abs. 1 S.1 Buchstabe f) DSGVO (Berechtigtes Interesse an der Datenverarbeitung). " +
            "sowie §25 Abs. 2 Nr. 2 TTDSG (unbedingte Erforderlichkeit zur Bereitstellung eines vom Nutzer ausdrücklich gewünschten Telemediendienstes). " +
            "Das berechtigte Interesse ergibt sich aus unserem Bedarf Ihnen eine funktionierende Webseite anbieten zu können. Der Web Storage ist dazu  erforderlich, weil er ein integraler Bestandteil der aktuellen  Internet-Technologie ist und ohne ihn viele Funktionen aktueller  Webseiten nicht zur Verfügung stünden. Wir benötigen daher den Web  Storage, um Ihnen die Webseite auf Ihre Anfrage zur Verfügung stellen  zu können. Der Verarbeitung Ihrer Daten auf Grundlage unseres  berechtigten Interesses können Sie jederzeit unter den Voraussetzungen  des Art. 21 DSGVO widersprechen. Nutzen Sie hierfür bitte die im  Impressum genannten Kontaktdaten. Wir weisen aber darauf hin, dass die  Verarbeitung Ihrer Daten im Web Storage unter Umständen zwingend im  Sinne des Art. 21 Abs. 1 DSGVO ist, da die Webseite ansonsten überhaupt  nicht betrieben werden kann und wir technisch nicht die Möglichkeit haben, die Nutzung auf bestimmten individuellen Endgeräten zu  unterbinden. Sie können das in Ihrem Browser aber möglicherweise selbst  tun. Werfen Sie für weitere Informationen hierzu bitte einen Blick in die Anleitung Ihres Browsers." +
            "Die Rechtsgrundlage für die Nutzung des Web Storage, die für die Funktion der Webseite nicht " +
            "zwingend erforderlich ist, ist Art. 6 Abs. 1 S.1 Buchstabe a) DSGVO (Einwilligung des Betroffenen). " +
            "Wir bitten Sie beim ersten Aufruf der Webseite über einen eingeblendeten Hinweistext um Ihr " +
            "Einverständnis zur Verwendung. Ihre Einwilligung können Sie jederzeit mit Wirkung für die Zukunft " +
            "widerrufen, indem Sie den Cookie-Banner erneut aufrufen und getroffenen Einstellungen ändern.",
        TEIL2_TEIL2_UEBERSCHRIFT: "7.2.2 Webstorage",
        TEIL2_TEXT:
            "Wir benutzen zur Verbesserung der Nutzerfreundlichkeit auf unserer " +
            "Webseite sogenannte „Cookies“ bzw. den „Web Storage“ Ihres Browsers.",
        TEIL2_UEBERSCHRIFT: "7.2 Cookies und Web-Storage",
        TEIL3_TEXT1:
            "Unsere Webseite bietet Ihnen die Möglichkeit, sich für weitere, " +
            "personalisierte Funktionen der Seite zu registrieren. Die Registrierung ist nur für Nutzer möglich, die im vornherein von der cpc baulogistik " +
            "GmbH freigeschaltet wurden. Bei der Registrierung werden personenbezogene Daten (z.B. Email-Adresse und " +
            "Name) erhoben und zum Zweck der Bereitstellung der personalisierten " +
            "Dienste verarbeitet.",
        TEIL3_TEXT2:
            "Folgende Angaben benötigen wir von Ihnen zwingend, um ein Kundenkonto anlegen zu können:",
        TEIL3_TEXT3: "Vorname, Nachname, E-Mail-Adresse, Unternehmen. ",
        TEIL3_TEXT4:
            "Die Rechtsgrundlage für die Verarbeitung von verpflichtend anzugebenden Daten ist Art. 6 Abs. 1 lit. " +
            "b) DSGVO (Datenverarbeitung zur Erfüllung eines Vertrags und zur Durchführung vorvertraglicher " +
            "Maßnahmen). Sie haben auch selbst die Möglichkeit, die im Registrierungsprozess angegebenen Daten jederzeit " +
            "zu ändern oder vollständig zu löschen.",
        TEIL3_UEBERSCHRIFT: "7.5 Registrierungsbereich",
        TEIL4_TEXT1:
            "Unsere Webportal bietet die Möglichkeiten an, die Zutrittskontrolle für " +
            "Baustellen zu verwalten. Dies geschieht ausschließlich im Rahmen der Auftragsverarbeitung für den jeweiligen Auftraggeber.",
        TEIL4_TEXT2:
            "Die Rechtsgrundlage für die Übermittlung derjenigen Daten (Name, " +
            "Vorname, Staatsangehörigkeit, Geburtstag, Daten zur " +
            "Aufenthaltsgenehmigung, Daten zum Arbeitsverhältnis und ggf. weitere " +
            "personenbezogene Daten) die für das Baustellenzutrittsmanagement " +
            "erforderlich sind, ist Art. 6 Abs. 1 S. 1 Buchstabe b) DSGVO " +
            "(Datenverarbeitung zur Erfüllung eines Vertrags). ",
        TEIL4_TEXT3:
            "Die für das Zutrittsmanagement übermittelten Daten werden für die Dauer " +
            "der Baustelle gespeichert und im Anschluss gelöscht, es sei denn es " +
            "stehen gesetzliche Aufbewahrungspflichten der Löschung entgegen, z.B. " +
            "aus dem Handelsrecht, dem Steuerrecht, dem Arbeitnehmerentsendegesetz, " +
            "dem Mindestlohngesetz oder dem Schwarzarbeitsbekämpfungsgesetz.",
        TEIL4_UEBERSCHRIFT: "7.4 Zutrittskontrolle",
        TEXT:
            "Wir möchten Ihnen im Folgenden so transparent wie möglich darstellen, " +
            "welche Daten wir von Ihnen zu welcher Gelegenheit, auf welcher Grundlage " +
            "und zu welchem Zweck verarbeiten.",
        UEBERSCHRIFT: "7. EINZELNE VERARBEITUNGSTÄTIGKEITEN",
        TEIL5_TEXT1:
            "Unser Portal bietet die Möglichkeit an, Transporte direkt bei uns anzumelden. Dies geschieht " +
            "ausschließlich im Rahmen der Auftragsverarbeitung für den jeweiligen Auftraggeber. ",
        TEIL5_TEXT2:
            "Folgende Daten werden im Rahmen des Formulars verarbeitet:",
        TEIL5_TEXT3:
            "Vorname, Nachname, Unternehmen, E-Mail, Telefonnummer, Startort, Zielort, Kennzeichen, " +
            "Informationen zum Transport, Fracht, und Beladung.",
        TEIL5_TEXT4:
            "Die Rechtsgrundlage für die Nutzung der von Ihnen durch die Transportanmeldung an uns " +
            "übermittelten Daten ist Art. 6 Abs. 1 S. 1 Buchstabe b) DSGVO (Datenverarbeitung zur Erfüllung eines Vertrags).",
        TEIL5_TEXT5:
            "Die von Ihnen im Rahmen der Transportanmeldung an uns übermittelten Daten werden für die" +
            "Dauer der Baustelle gespeichert und im Anschluss gelöscht, es sei denn es stehen gesetzliche " +
            "Aufbewahrungspflichten der Löschung entgegen, z.B. aus dem Handelsrecht, dem Steuerrecht, dem " +
            "Arbeitnehmerentsendegesetz, dem Mindestlohngesetz oder dem Schwarzarbeitsbekämpfungsgesetz.",
        TEIL5_UEBERSCHRIFTEN: "7.3 Transportanmeldung",
        TEIL6_TEXT1:
            "Unsere Webseite wird auf Servern von externen Anbietern gehostet, um die Zurverfügungstellung " +
            "der Webseite effizient und sicher zu gewährleisten.",
        TEIL6_TEXT2:
            "Bei jedem Aufruf der Webseite werden allgemeine Informationen von Ihrem Browser automatisch an " +
            "den Server übermittelt (sog. Server-Log-Dateien). Nähere Informationen hierzu siehe oben unter ",
        TEIL6_TEXT3: "„Server-Log-Dateien“.",
        TEIL6_TEXT4:
            "Die Rechtsgrundlage für die Nutzung von Hosting-Dienstleistung und die damit verbundene " +
            "Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO (Berechtigtes Interesse an der " +
            "Datenverarbeitung), soweit bei dem jeweiligen Dienst nichts anderes angegeben ist. Das berechtigte " +
            "Interesse ergibt sich aus unserem Bedarf an einer technisch einwandfreien Darstellung unserer " +
            "Webseite, ohne dass wir dafür vertiefte Kenntnisse der Programmierung von Webseiten und " +
            "Wartung von IT-Systemen benötigen. Der Verarbeitung Ihrer Daten auf Grundlage unseres " +
            "berechtigten Interesses können Sie jederzeit unter den Voraussetzungen des Art. 21 DSGVO " +
            "widersprechen. Nutzen Sie hierfür bitte die im Impressum genannten Kontaktdaten.",
        TEIL6_UEBERSCHRIFTEN: "7.6 Hosting-Dienstleistungen",
    },
};
export default TRANSLATION_DE_DATENSCHUTZ;
