import {
  FETCH_EUFEFAS_SUCCESS,
  RESET_EUFEFAS_SUCCESS,
  LOESCHE_EUFEFAS_SUCCESS,
  ZUKO_EUFEFAS_ACTION_FAIL,
  ERSTELLE_EUFEFAS_SUCCESS,
  ZUKO_EUFEFAS_ACTION_BEGIN,
  AKTUALISIERE_EUFEFAS_SUCCESS,
} from "../../actions/zuko/eufefas.js";

const eufefaState = {
  error: null,
  loading: false,
  eufefas: {},
};

const savedState =
  localStorage.getItem("savedState") || JSON.stringify({ ...eufefaState });
const INITIAL_STATE = JSON.parse(savedState).eufefaState || eufefaState;

const eufefas = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ZUKO_EUFEFAS_ACTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ZUKO_EUFEFAS_ACTION_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case FETCH_EUFEFAS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        eufefas: action.payload,
      };
    case RESET_EUFEFAS_SUCCESS:
      return {
        ...state,
        error: null,
        eufefas: {},
        loading: false,
      };
    case LOESCHE_EUFEFAS_SUCCESS:
    case ERSTELLE_EUFEFAS_SUCCESS:
    case AKTUALISIERE_EUFEFAS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default eufefas;
