const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;

export const erstelleContainerburg = async (formData, language, projektId) => {
    appInsights.startTrackEvent("erstelleContainerburg");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/erstelle-containerburg`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("erstelleContainerburg", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("erstelleContainerburg", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("erstelleContainerburg", {
            success: json.success,
        });
        return json;
    }
};

export const aktualisiereContainerburg = async (
    formData,
    language,
    projektId
) => {
    appInsights.startTrackEvent("aktualisiereContainerburg");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/aktualisiere-containerburg`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("aktualisiereContainerburg", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("aktualisiereContainerburg", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("aktualisiereContainerburg", {
            success: json.success,
        });
        return json;
    }
};

export const archiviereContainerburg = async (
    formData,
    language,
    projektId
) => {
    appInsights.startTrackEvent("archiviereContainerburg");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/archiviere-containerburg`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("archiviereContainerburg", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("archiviereContainerburg", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("archiviereContainerburg", {
            success: json.success,
        });
        return json;
    }
};

export const fetchContainerburgBild = async (sprache, projektId, containerburgId) => {
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/download-containerburg-bild/?containerburgId=${containerburgId}`,
        {
            method: "GET",
        }
    );
    return response;
};