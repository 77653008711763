import {
    PROJEKTROLLEN_ACTION_FAIL,
    PROJEKTROLLEN_ACTION_BEGIN,
    FETCH_PROJEKTROLLEN_SUCCESS,
    RESET_PROJEKTROLLEN_SUCCESS,
    PROJEKTROLLENBEZIEHUNGEN_ACTION_FAIL,
    PROJEKTROLLENBEZIEHUNGEN_ACTION_BEGIN,
    FETCH_PROJEKTROLLENBEZIEHUNGEN_SUCCESS,
} from "../../actions/projekt/projektrollen.js";

const projektrollenState = {
    error: null,
    loading: false,
    projektrollen: [],
    projektrollenbziehungenIstLoading: false,
    projektrollenbeziehungen: {},
};

const savedState =
    localStorage.getItem("savedState") ||
    JSON.stringify({ ...projektrollenState });
const INITIAL_STATE =
    JSON.parse(savedState).projektrollenState || projektrollenState;

const projektrollen = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PROJEKTROLLEN_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case PROJEKTROLLEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FETCH_PROJEKTROLLEN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                projektrollen: action.payload,
            };
        case PROJEKTROLLENBEZIEHUNGEN_ACTION_FAIL:
            return {
                ...state,
                projektrollenbziehungenIstLoading: false,
                error: action.error,
            };
        case PROJEKTROLLENBEZIEHUNGEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                projektrollenbziehungenIstLoading: true,
            };
        case FETCH_PROJEKTROLLENBEZIEHUNGEN_SUCCESS:
            return {
                ...state,
                error: null,
                projektrollenbziehungenIstLoading: false,
                projektrollenbziehungen: action.payload,
            };
        case RESET_PROJEKTROLLEN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                projektrollen: [],
            };
        default:
            return state;
    }
};

export default projektrollen;
