import errorHandler from "../errorHandling";
export const ZUKOSERVER_ACTION_FAIL = "ZUKOSERVER_ACTION_FAIL";
export const ZUKOSERVER_ACTION_BEGIN = "ZUKOSERVER_ACTION_BEGIN";
export const FETCH_ZUKOSERVER_SUCCESS = "FETCH_ZUKOSERVER_SUCCESS";
export const RESET_ZUKOSERVER_SUCCESS = "RESET_ZUKOSERVER_SUCCESS";
export const ERSTELLE_ZUKOSERVER_SUCCESS = "ERSTELLE_ZUKOSERVER_SUCCESS";
export const FETCH_ZUKOSERVER_ENPOINT_PINGTEST_SUCCESS =
  "FETCH_ZUKOSERVER_ENPOINT_PINGTEST_SUCCESS";

const apiHost = window.Algo.apiHost,
  appInsights = window.Algo.appInsights,
  getRequestOptions = window.Algo.getRequestOptions,
  postRequestOptions = window.Algo.postRequestOptions;

export const resetMehrereZukoServer = () => (dispatch) => {
  dispatch({ type: RESET_ZUKOSERVER_SUCCESS });
  appInsights.trackEvent({ name: "resetMehrereZukoServer" });
};

export const fetchMehrereZukoServer = () => async (dispatch) => {
  appInsights.startTrackEvent("fetchMehrereZukoServer");
  dispatch({ type: ZUKOSERVER_ACTION_BEGIN });
  const response = await fetch(
    `${apiHost}/api/zuko/admin/v1/zuko-server/`,
    getRequestOptions
  );
  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(error));
  }
  if (!response.ok) {
    if (json && !json.success) {
      dispatch(errorHandler(json));
      dispatch({ type: ZUKOSERVER_ACTION_FAIL, error: json });
      appInsights.stopTrackEvent("fetchMehrereZukoServer", {
        success: json.success,
        error: json,
      });
    } else {
      dispatch(errorHandler(response));
      dispatch({ type: ZUKOSERVER_ACTION_FAIL, error: response });
      appInsights.stopTrackEvent("fetchMehrereZukoServer", {
        success: response.ok,
        error: response,
      });
    }
  }
  if (json?.success) {
    dispatch({ type: FETCH_ZUKOSERVER_SUCCESS, payload: json.payload });
    appInsights.stopTrackEvent("fetchMehrereZukoServer", {
      success: json.success,
    });
  }
};

export const zukoServerEndpointPingTest = (formData) => async (dispatch) => {
  appInsights.startTrackEvent("zukoServerEndpointPingTest");
  dispatch({ type: ZUKOSERVER_ACTION_BEGIN });
  const url = `/api/zuko/admin/v1/endpoint-ping-test`;
  const response = await fetch(url, {
    method: "POST",
    body: formData,
  });
  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(error));
  }
  if (!response.ok) {
    if (json && !json.success) {
      dispatch(errorHandler(json));
      dispatch({ type: ZUKOSERVER_ACTION_FAIL, error: json });
      appInsights.stopTrackEvent("zukoServerEndpointPingTest", {
        success: json.success,
        error: json,
      });
    } else {
      dispatch(errorHandler(response));
      dispatch({ type: ZUKOSERVER_ACTION_FAIL, error: response });
      appInsights.stopTrackEvent("zukoServerEndpointPingTest", {
        success: response.ok,
        error: response,
      });
    }
  }
  if (json?.success) {
    dispatch({
      type: FETCH_ZUKOSERVER_ENPOINT_PINGTEST_SUCCESS,
      payload: json.payload,
    });
    appInsights.stopTrackEvent("fetchMehrereZukoServer", {
      success: json.success,
    });
    return json.payload;
  }
};

export const erstelleZukoServerCommand = (e) => async (dispatch) => {
  appInsights.startTrackEvent("erstelleZukoServerCommand");
  dispatch({ type: ZUKOSERVER_ACTION_BEGIN });
  const form = e.srcElement || e.target;
  const url = form.dataset.url;
  const formData = new FormData(form);
  const response = await fetch(url, {
    method: "POST",
    body: formData,
  });

  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(error));
  }
  if (!response.ok) {
    if (json && !json.success) {
      dispatch(errorHandler(json));
      dispatch({ type: ZUKOSERVER_ACTION_FAIL, error: json });
      appInsights.stopTrackEvent("erstelleZukoServerCommand", {
        success: json.success,
        error: json,
      });
    } else {
      dispatch(errorHandler(response));
      dispatch({ type: ZUKOSERVER_ACTION_FAIL, error: response });
      appInsights.stopTrackEvent("erstelleZukoServerCommand", {
        success: response.ok,
        error: response,
      });
    }
  }
  if (json?.success) {
    dispatch({
      type: ERSTELLE_ZUKOSERVER_SUCCESS,
      payload: json.payload,
    });
    appInsights.stopTrackEvent("erstelleZukoServerCommand", {
      success: json.success,
    });
    return json.payload;
  }
};
