import {
    FETCH_UNTERLAGEN_RESET_SUCCESS,
    FETCH_UNTERLAGEN_AUSWERTUNGEN_FIRMEN_ACTION_BEGIN,
    FETCH_UNTERLAGEN_AUSWERTUNGEN_FIRMEN_ACTION_FAIL,
    FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_SUCCESS,
    FETCH_DASHBOARD_UNTERLAGEN_AUSWERTUNGEN_PERSONAL_ACTION_BEGIN,
    FETCH_DASHBOARD_UNTERLAGEN_AUSWERTUNGEN_PERSONAL_ACTION_FAIL,
    FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_SUCCESS,
    FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_ACTION_BEGIN,
    FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_ACTION_FAIL,
    FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_SUCCESS,
    FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_UNTERLAGEN_ACTION_BEGIN,
    FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_UNTERLAGEN_ACTION_FAIL,
    FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_UNTERLAGEN_SUCCESS,
    FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_PRUEFEN_ACTION_BEGIN,
    FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_PRUEFEN_ACTION_FAIL,
    FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_PRUEFEN_SUCCESS,
    FETCH_PERSONAL_UNTERLAGEN_PRUEFEN_ACTION_BEGIN,
    FETCH_PERSONAL_UNTERLAGEN_PRUEFEN_ACTION_FAIL,
    FETCH_PERSONAL_UNTERLAGEN_PRUEFEN_SUCCESS,
    FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_PRUEFEN_ACTION_BEGIN,
    FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_PRUEFEN_ACTION_FAIL,
    FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_PRUEFEN_SUCCESS,
    FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_BEGIN,
    FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_FAIL,
    FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_SUCCESS,
    FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_BEGIN,
    FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_FAIL,
    FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_SUCCESS,
    FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_BEGIN,
    FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_FAIL,
    FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_SUCCESS,
    FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_RESET_SUCCESS,
    FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_RESET_SUCCESS,
    FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_ACTION_BEGIN,
    FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_ACTION_FAIL,
    FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_SUCCESS,
    FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_BEGIN,
    FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_FAIL,
    FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_SUCCESS,
    FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_COLUMNS_ACTION_BEGIN,
    FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_COLUMNS_ACTION_FAIL,
    FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_COLUMNS_SUCCESS,
    FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_DATATABLE_ACTION_BEGIN,
    FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_DATATABLE_ACTION_FAIL,
    FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_DATATABLE_SUCCESS,
    FETCH_MINDESTLOHNERKLAERUNGEN_RESET_SUCCESS,
} from "../../actions/zuko/unterlagen";

const unterlagenState = {
    error: null,
    dashboardMindestlohnerklaerungenIstLoading: false,
    dashboardPersonalUnterlagenIstLoading: false,
    dashboardFirmenUnterlagenIstLoading: false,
    dashboardMindestlohnerklaerungenPruefenLoading: false,
    dashboardPersonalUnterlagenPruefenLoading: false,
    dashboardFirmenUnterlagenPreufenIstLoading: false,
    dashboardPersonalUnterlagenAuswertungenIstLoading: false,
    dashboardFirmenUnterlagenAuswertungenIstLoading: false,
    personalUnterlagenAuswertungenColumnsIstLoading: false,
    personalUnterlagenAuswertungenDataTableIstLoading: false,
    mindestlohnerklaerungenUnterlagenDataTableIstLoading: false,
    mindestlohnerklaerungenUnterlagenColumnsIstLoading: false,
    firmenUnterlagenAuswertungenDataTableIstLoading: false,
    firmenUnterlagenAuswertungenColumnsIstLoading: false,
};

const savedState =
    localStorage.getItem("savedState") ||
    JSON.stringify({ ...unterlagenState });
const INITIAL_STATE = JSON.parse(savedState).unterlagenState || unterlagenState;

const unterlagen = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_UNTERLAGEN_RESET_SUCCESS:
            return unterlagenState;
        case FETCH_UNTERLAGEN_AUSWERTUNGEN_FIRMEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                dashboardFirmenUnterlagenAuswertungenIstLoading: true,
            };
        case FETCH_UNTERLAGEN_AUSWERTUNGEN_FIRMEN_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                dashboardFirmenUnterlagenAuswertungenIstLoading: false,
            };
        case FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_SUCCESS:
            return {
                ...state,
                error: null,
                dashboardFirmenUnterlagenAuswertungenIstLoading: false,
            };
        case FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                dashboardFirmenUnterlagenIstLoading: true,
            };
        case FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                dashboardFirmenUnterlagenIstLoading: false,
            };
        case FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_SUCCESS:
            return {
                ...state,
                error: null,
                dashboardFirmenUnterlagenIstLoading: false,
            };
        case FETCH_DASHBOARD_UNTERLAGEN_AUSWERTUNGEN_PERSONAL_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                dashboardPersonalUnterlagenAuswertungenIstLoading: true,
            };
        case FETCH_DASHBOARD_UNTERLAGEN_AUSWERTUNGEN_PERSONAL_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                dashboardPersonalUnterlagenAuswertungenIstLoading: false,
            };
        case FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_SUCCESS:
            return {
                ...state,
                error: null,
                dashboardPersonalUnterlagenAuswertungenIstLoading: false,
            };
        case FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                dashboardPersonalUnterlagenIstLoading: true,
            };
        case FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                dashboardPersonalUnterlagenIstLoading: false,
            };
        case FETCH_DASHBOARD_PERSONAL_UNTERLAGEN_SUCCESS:
            return {
                ...state,
                error: null,
                dashboardPersonalUnterlagenIstLoading: false,
            };
        case FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_UNTERLAGEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                dashboardMindestlohnerklaerungenIstLoading: true,
            };
        case FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_UNTERLAGEN_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                dashboardMindestlohnerklaerungenIstLoading: false,
            };
        case FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_UNTERLAGEN_SUCCESS:
            return {
                ...state,
                error: null,
                dashboardMindestlohnerklaerungenIstLoading: false,
            };
        case FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_PRUEFEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                dashboardFirmenUnterlagenPruefenLoading: true,
            };
        case FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_PRUEFEN_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                dashboardFirmenUnterlagenPruefenLoading: false,
            };
        case FETCH_DASHBOARD_FIRMEN_UNTERLAGEN_PRUEFEN_SUCCESS:
            return {
                ...state,
                error: null,
                dashboardFirmenUnterlagenPruefenLoading: false,
                dashboardFirmenUnterlagen: action.payload,
            };
        case FETCH_PERSONAL_UNTERLAGEN_PRUEFEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                dashboardPersonalUnterlagenPruefenLoading: true,
            };
        case FETCH_PERSONAL_UNTERLAGEN_PRUEFEN_ACTION_FAIL:
            return {
                ...state,
                error: null,
                dashboardPersonalUnterlagenPruefenLoading: false,
            };
        case FETCH_PERSONAL_UNTERLAGEN_PRUEFEN_SUCCESS:
            return {
                ...state,
                error: null,
                dashboardPersonalUnterlagenPruefenLoading: false,
            };
        case FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_PRUEFEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                dashboardMindestlohnerklaerungenPruefenLoading: true,
            };
        case FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_PRUEFEN_ACTION_FAIL:
            return {
                ...state,
                error: null,
                dashboardMindestlohnerklaerungenPruefenLoading: false,
            };
        case FETCH_DASHBOARD_MINDESTLOHNERKLAERUNGEN_PRUEFEN_SUCCESS:
            return {
                ...state,
                error: null,
                dashboardMindestlohnerklaerungenPruefenLoading: false,
            };
        case FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                personalUnterlagenAuswertungenColumnsIstLoading: true,
            };
        case FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                personalUnterlagenAuswertungenColumnsIstLoading: false,
            };
        case FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_SUCCESS:
            return {
                ...state,
                error: null,
                personalUnterlagenAuswertungenColumnsIstLoading: false,
            };
        case FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                personalUnterlagenAuswertungenDataTableIstLoading: true,
            };
        case FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
            };
        case FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_RESET_SUCCESS:
        case FETCH_PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_SUCCESS:
            return {
                ...state,
                error: null,
                personalUnterlagenAuswertungenDataTableIstLoading: false,
            };
        case FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_COLUMNS_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                mindestlohnerklaerungenUnterlagenColumnsIstLoading: true,
            };
        case FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_COLUMNS_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                mindestlohnerklaerungenUnterlagenColumnsIstLoading: false,
            };
        case FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_COLUMNS_SUCCESS:
            return {
                ...state,
                error: null,
                mindestlohnerklaerungenUnterlagenColumnsIstLoading: false,
            };
        case FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_DATATABLE_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                mindestlohnerklaerungenUnterlagenDataTableIstLoading: true,
            };
        case FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_DATATABLE_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                mindestlohnerklaerungenUnterlagenDataTableIstLoading: false,
            };
        case FETCH_UNTERLAGEN_MINDESTLOHNERKLAERUNGEN_DATATABLE_SUCCESS:
            return {
                ...state,
                error: null,
                mindestlohnerklaerungenUnterlagenDataTableIstLoading: false,
            };
        case FETCH_MINDESTLOHNERKLAERUNGEN_RESET_SUCCESS:
            return {
                ...state,
                error: null,
                mindestlohnerklaerungenUnterlagenDataTableIstLoading: false,
            };
        case FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                firmenUnterlagenAuswertungenDataTableIstLoading: true,
            };
        case FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                firmenUnterlagenAuswertungenDataTableIstLoading: false,
            };
        case FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_RESET_SUCCESS:
        case FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DATATABLE_SUCCESS:
            return {
                ...state,
                error: null,
                firmenUnterlagenAuswertungenDataTableIstLoading: false,
            };
        case FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                firmenUnterlagenAuswertungenColumnsIstLoading: false,
            };
        case FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                firmenUnterlagenAuswertungenColumnsIstLoading: false,
            };
        case FETCH_FIRMEN_UNTERLAGEN_AUSWERTUNGEN_COLUMNS_SUCCESS:
            return {
                ...state,
                error: null,
                firmenUnterlagenAuswertungenColumnsIstLoading: false,
            };
        default:
            return state;
    }
};
export default unterlagen;
