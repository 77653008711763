import { LitElement, css } from "lit";
import { html } from "lit/html.js";
import { connect } from "pwa-helpers/connect-mixin.js";

import { store } from "../../../store.js";
import { SbAdmin2Styles } from "../../../css/sb-admin-2-styles.js";
import { AlgoStyles } from "../../../css/algo-styles.js";
import { FontawesomeIcons } from "../../../css/fontawesome-icons.js";

import {
    resetBerechtigungsgruppenAdmin,
    fetchBerechtigungsgruppenAdmin,
    loescheBerechtigungsgruppeCommand,
    erstelleBerechtigungsgruppeCommand,
    aktualisiereBerechtigungsgruppeCommand,
    synchronisiereBerechtigungsgruppenCommand,
} from "../../../rdx/actions/zuko/berechtigungsgruppen.js";

import { showToast } from "../../../rdx/actions/algo-app.js";

import algoApp from "../../../rdx/reducers/algo-app.js";
import projekt from "../../../rdx/reducers/projekt/projekt.js";
import berechtigungsgruppen from "../../../rdx/reducers/zuko/berechtigungsgruppen.js";

store.addReducers({
    algoApp,
    projekt,
    berechtigungsgruppen,
});

import "../../../dt/data-table.js";
import "../../../bs/components/bs-modal.js";

import { translate } from "../../../../src/i18n";
import moment from "moment";

// https://docs.microsoft.com/en-us/dotnet/api/system.dayofweek?redirectedfrom=MSDN&view=net-5.0
class ZukoEinstellungenUebersichtViewBerechtigungsgruppenPage extends connect(
    store
)(LitElement) {
    static get styles() {
        return [SbAdmin2Styles, AlgoStyles, FontawesomeIcons];
    }

    static get properties() {
        return {
            _acl: { type: Object },
            _raumzonen: { type: Array },
            _zeitzonen: { type: Array },
            _raumzonen: { type: Array },
            _dtOptions: { type: Object },
            _projektId: { type: String },
            _leseeinheiten: { type: Array },
            _berechtigungsgruppen: { type: Array },
            _erstelleBerechtigungen: { type: Array },
            _selectedBerechtigungen: { type: Array },
            _selectedBerechtigungsgruppe: { type: Array },
            _berechtigungsgruppenCounter: { type: String },
            _loading: { type: Boolean },
            active: { type: Boolean },
        };
    }

    _renderLoescheBerechtigungsgruppeModal() {
        return html`
            <bs-modal
                id="loesche-berechtigungsgruppe"
                modalTitle="Berechtigungsgruppe löschen"
            >
                <p slot="modal-body">
                    Wollen Sie die Berechtigungsgruppe wirklich löschen?
                </p>
                <button
                    slot="modal-footer"
                    class="btn btn-danger"
                    role="button"
                    @click="${this._onBerechtigungsgruppeLoeschen}"
                >
                    Löschen
                </button>
            </bs-modal>
        `;
    }

    _renderErstelleBerechtigungsgruppeModal() {
        const disabled = this._loading;

        return html`
            <bs-modal
                id="erstelle-berechtigungsgruppe"
                modalTitle="Berechtigungsgruppe hinzufügen"
                large
                @closed="${this._onCloseErstelleBerechtigungsgruppeModal}"
            >
                <form
                    slot="modal-body"
                    @submit="${this._onBerechtigungsgruppeHinzufuegen}"
                >
                    <div class="form-group row">
                        <label for="name" class="col-sm-4 col-form-label"
                            >Bezeichnung</label
                        >
                        <div class="col-sm-8">
                            <input
                                required
                                ?disabled="${disabled}"
                                type="text"
                                class="form-control"
                                id="Name"
                                name="Name"
                                placeholder="Mitarbeiter"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="name" class="col-sm-4 col-form-label"
                            >Einstellungen</label
                        >
                        <div class="col-sm-8">
                            <div class="form-group row">
                                <div
                                    class="col-sm-6 col-form-label font-weight-bold"
                                >
                                    Raumzone
                                </div>
                                <div
                                    class="col-sm-5 col-form-label font-weight-bold"
                                >
                                    Zeitzone
                                </div>
                            </div>
                            ${this._erstelleBerechtigungen?.map(
                                (x, i) => html`
                                    <div
                                        class="form-group row"
                                        data-index="${i}"
                                    >
                                        <div class="col-sm-6">
                                            <input
                                                required
                                                hidden
                                                type="text"
                                                class="form-control"
                                                id="RaumzoneId"
                                                name="RaumzoneId"
                                                value="${x.raumzoneId}"
                                            />
                                            <input
                                                required
                                                disabled
                                                type="text"
                                                class="form-control"
                                                id="RaumzoneName"
                                                name="RaumzoneName"
                                                value="${x.raumzoneName}"
                                            />
                                        </div>
                                        <div class="col-sm-6">
                                            <select
                                                ?disabled="${disabled}"
                                                required
                                                class="custom-select zeitzone"
                                                @input="${this
                                                    ._onChangeRowErstelleModal}"
                                            >
                                                <option
                                                    disabled
                                                    selected
                                                    value=""
                                                >
                                                    --
                                                </option>
                                                ${this._zeitzonen?.map(
                                                    (y) => html`
                                                        <option
                                                            ?selected="${x.zeitzoneId ===
                                                            y.zeitzoneId}"
                                                            value="${y.zeitzoneId}"
                                                        >
                                                            ${y.zeitzoneName}
                                                        </option>
                                                    `
                                                )};
                                            </select>
                                        </div>
                                    </div>
                                `
                            )}
                        </div>
                    </div>
                    <hr class="fading-hr" />
                    <button
                        ?disabled="${disabled}"
                        type="submit"
                        slot="modal-footer"
                        class="btn btn-success float-right"
                        role="button"
                    >
                        Berechtigungsgruppe hinzufügen
                    </button>
                </form>
            </bs-modal>
        `;
    }

    _renderAktualisiereBerechtigungsgruppeModal() {
        const disabled = this._loading,
            selectedBerechtigungsgruppe =
                this._selectedBerechtigungsgruppe || {},
            selectedBerechtigungen = this._selectedBerechtigungen || [];

        return html`
            <bs-modal
                id="aktualisiere-berechtigungsgruppe"
                modalTitle="Berechtigungsgruppe aktualisieren"
                large
            >
                <form
                    slot="modal-body"
                    @submit="${this._onBerechtigungsgruppeAktualisieren}"
                >
                    <div class="form-group row" hidden>
                        <label
                            for="berechtigungsgruppeId"
                            class="col-sm-4 col-form-label"
                            >BerechtigungsgruppeId</label
                        >
                        <div class="col-sm-8">
                            <input
                                required
                                type="text"
                                class="form-control"
                                id="BerechtigungsgruppeId"
                                name="BerechtigungsgruppeId"
                                value="${selectedBerechtigungsgruppe.berechtigungsgruppeId}"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="name" class="col-sm-4 col-form-label"
                            >Bezeichnung</label
                        >
                        <div class="col-sm-8">
                            <input
                                required
                                ?disabled="${disabled}"
                                type="text"
                                class="form-control"
                                id="Name"
                                name="Name"
                                placeholder="Mitarbeiter"
                                value="${selectedBerechtigungsgruppe.berechtigungsgruppeName}"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="name" class="col-sm-4 col-form-label"
                            >Einstellungen</label
                        >
                        <div class="col-sm-8">
                            <div class="form-group row">
                                <div
                                    class="col-sm-6 col-form-label font-weight-bold"
                                >
                                    Raumzone
                                </div>
                                <div
                                    class="col-sm-5 col-form-label font-weight-bold"
                                >
                                    Zeitzone
                                </div>
                            </div>
                            ${selectedBerechtigungen?.map(
                                (x, i) => html`
                                    <div
                                        class="form-group row"
                                        data-index="${i}"
                                    >
                                        <div class="col-sm-6">
                                            <input
                                                required
                                                hidden
                                                type="text"
                                                class="form-control"
                                                id="RaumzoneId"
                                                name="RaumzoneId"
                                                value="${x.raumzoneId}"
                                            />
                                            <input
                                                required
                                                disabled
                                                type="text"
                                                class="form-control"
                                                id="RaumzoneName"
                                                name="RaumzoneName"
                                                value="${x.raumzoneName}"
                                            />
                                        </div>
                                        <div class="col-sm-6">
                                            <select
                                                ?disabled="${disabled}"
                                                required
                                                class="custom-select zeitzone"
                                                @input="${this
                                                    ._onChangeRowAktualisiereModal}"
                                            >
                                                <option
                                                    disabled
                                                    selected
                                                    value=""
                                                >
                                                    --
                                                </option>
                                                ${this._zeitzonen?.map(
                                                    (y) => html`
                                                        <option
                                                            ?selected="${x.zeitzoneId ===
                                                            y.zeitzoneId}"
                                                            value="${y.zeitzoneId}"
                                                        >
                                                            ${y.zeitzoneName}
                                                        </option>
                                                    `
                                                )};
                                            </select>
                                        </div>
                                    </div>
                                `
                            )}
                        </div>
                    </div>
                    <hr class="fading-hr" />
                    <button
                        ?disabled="${disabled}"
                        type="submit"
                        slot="modal-footer"
                        class="btn btn-dark float-right"
                        role="button"
                    >
                        Berechtigungsgruppe aktualisieren
                    </button>
                </form>
            </bs-modal>
        `;
    }

    render() {
        let label = {
            text: this._berechtigungsgruppenCounter
                ? "Status: " + this._berechtigungsgruppenCounter
                : "Status: 0 / 0 / 250",
            tooltip: "Erstellt/Synchronisiert/Berechtigungsgruppenlimit",
        };
        return html`
            ${this._renderErstelleBerechtigungsgruppeModal()}
            ${this._renderAktualisiereBerechtigungsgruppeModal()}
            ${this._renderLoescheBerechtigungsgruppeModal()}
            <data-table
                .label="${label}"
                .options="${this._dtOptions}"
            ></data-table>
        `;
    }

    constructor() {
        super();
        const _this = this;

        this._acl = {};
        this._loading = false;
        this._projektId = "";
        this._berechtigungsgruppen = [];
        this._leseeinheiten = [];
        this._zeitzonen = [];
        this._raumzonen = [];
        this._erstelleBerechtigungen = [];
        this._selectedBerechtigungsgruppe = {};
        this._selectedBerechtigungen = [];
        this._dtOptions = {
            dom: "Blfrtip",
            buttons: [
                {
                    text:
                        '<span class="icon text-white-50 d-block">' +
                        '<i class="fas fa-plus" style="font-size: larger !important"></i>' +
                        "</span>" +
                        '<span class="text">' +
                        translate("ZUKO.BERECHTIGUNGSGRUPPE_HINZUFUEGEN") +
                        "</span>",
                    title: translate("ZUKO.BERECHTIGUNGSGRUPPE_HINZUFUEGEN"),
                    className:
                        "btn btn-dark btn-icon-split mb-4 mr-2 align-middle float-left",
                    action: () => {
                        _this._erstelleArrayFuerErstelleModal();
                        _this.shadowRoot
                            .querySelector("#erstelle-berechtigungsgruppe")
                            .show();
                    },
                },
                {
                    text:
                        '<span class="icon text-white-50 d-block">' +
                        '<i class="fas fa-sync" style="font-size: larger !important"></i>' +
                        "</span>" +
                        '<span class="text">' +
                        translate("ZUKO.BERECHTIGUNGSGRUPPEN_SYNCHRONISIEREN") +
                        "</span>",
                    title: translate(
                        "ZUKO.BERECHTIGUNGSGRUPPEN_SYNCHRONISIEREN"
                    ),
                    className:
                        "btn btn-dark btn-icon-split mb-4 mr-2 align-middle float-left",
                    action: () => {
                        _this._synchronisiereBerechtigungsgruppen();
                    },
                },
            ],
            columns: [
                {
                    title: "Name",
                    data: "name",
                },
                {
                    title: "Berechtigungen",
                    data: "berechtigungen",
                },
                {
                    title: "Letzte<br>Änderung",
                    data: "zuletztGeaendertAm",
                },
                {
                    title: "Letzte<br>Synchronisierung",
                    data: "zuletztSynchronisiertAm",
                },
                {
                    title: "Ist<br>Synchronisiert",
                    data: "istSynchronisiert",
                },
                {
                    title: "Mitarbeiter",
                    data: "mitarbeiterCount",
                },
                {
                    title: "",
                    data: "actions",
                },
            ],
        };
    }

    _erstelleArrayFuerErstelleModal() {
        this._erstelleBerechtigungen = [];
        this._raumzonen.forEach((x) => {
            this._erstelleBerechtigungen = [
                ...this._erstelleBerechtigungen,
                {
                    raumzoneId: x.raumzoneId,
                    raumzoneName: x.raumzoneName,
                    zeitzoneId: null,
                },
            ];
        });
    }

    _onChangeRowErstelleModal(e) {
        e.preventDefault();
        this._loading = true;

        const src = e.srcElement || e.target,
            formRow = src.closest(".row"),
            index = formRow.dataset.index,
            zeitzoneId = formRow.querySelector(".zeitzone");

        this._erstelleBerechtigungen[index].zeitzoneId = zeitzoneId.value;

        this._loading = false;
    }

    _onChangeRowAktualisiereModal(e) {
        e.preventDefault();
        this._loading = true;

        const src = e.srcElement || e.target,
            formRow = src.closest(".row"),
            index = formRow.dataset.index,
            zeitzoneId = formRow.querySelector(".zeitzone");

        this._selectedBerechtigungen[index].zeitzoneId = zeitzoneId.value;

        this._loading = false;
    }

    async _synchronisiereBerechtigungsgruppen() {
        this._loading = true;
        const formData = new FormData();
        formData.append("ProjektId", this._projektId);

        const data = await store.dispatch(
            synchronisiereBerechtigungsgruppenCommand(this._projektId)
        );

        if (data) {
            store.dispatch(
                showToast({
                    text: "Berechtigungsgruppen wurden erfolgreich synchronisiert",
                })
            );
            store.dispatch(fetchBerechtigungsgruppenAdmin(this._projektId));
        }
        this._loading = false;
    }

    _onCloseErstelleBerechtigungsgruppeModal() {
        const modal = this.shadowRoot.querySelector(
                "#erstelle-berechtigungsgruppe"
            ),
            form = modal.querySelector("form");
        form.reset();
        this._erstelleBerechtigungen = [];
    }

    async _onBerechtigungsgruppeHinzufuegen(e) {
        e.preventDefault();

        const modal = this.shadowRoot.querySelector(
                "#erstelle-berechtigungsgruppe"
            ),
            button = modal.querySelector("button"),
            form = e.srcElement || e.target,
            formData = new FormData(form);

        if (
            this._berechtigungsgruppen.some(
                (x) => x.berechtigungsgruppeName === formData.get("Name")
            )
        ) {
            store.dispatch(
                showToast({
                    text: "Berechtigungsgruppenname existiert bereits!",
                })
            );
            return;
        }

        for (var i = 0; i < this._erstelleBerechtigungen.length; ++i) {
            formData.append(
                `berechtigungen[${i}].RaumzoneId`,
                this._erstelleBerechtigungen[i].raumzoneId
            );
            formData.append(
                `berechtigungen[${i}].ZeitzoneId`,
                this._erstelleBerechtigungen[i].zeitzoneId
            );
        }

        if (form.checkValidity()) {
            this._loading = true;

            const data = await store.dispatch(
                erstelleBerechtigungsgruppeCommand(formData, this._projektId)
            );

            modal.setAttribute("locked", true);
            button.setAttribute("disabled", true);

            if (data) {
                store.dispatch(
                    showToast({
                        text: "Berechtigungsgruppe wurde erfolgreich hinzugefügt",
                    })
                );
                store.dispatch(fetchBerechtigungsgruppenAdmin(this._projektId));
            }

            this._loading = false;
            form.reset();
            this._erstelleBerechtigungen = [];
            modal.removeAttribute("locked");
            button.removeAttribute("disabled");
            modal.close();
        }
    }

    async _onBerechtigungsgruppeAktualisieren(e) {
        e.preventDefault();

        const modal = this.shadowRoot.querySelector(
                "#aktualisiere-berechtigungsgruppe"
            ),
            button = modal.querySelector("button"),
            form = e.srcElement || e.target,
            formData = new FormData(form);

        if (
            this._berechtigungsgruppen.some(
                (x) =>
                    x.berechtigungsgruppeName === formData.get("Name") &&
                    x.berechtigungsgruppeId !==
                        this._selectedBerechtigungsgruppe.berechtigungsgruppeId
            )
        ) {
            store.dispatch(
                showToast({
                    text: "Berechtigungsgruppenname existiert bereits!",
                })
            );
            return;
        }

        for (var i = 0; i < this._selectedBerechtigungen.length; ++i) {
            formData.append(
                `berechtigungen[${i}].RaumzoneId`,
                this._selectedBerechtigungen[i].raumzoneId
            );
            formData.append(
                `berechtigungen[${i}].ZeitzoneId`,
                this._selectedBerechtigungen[i].zeitzoneId
            );
        }

        if (form.checkValidity()) {
            this._loading = true;

            const data = await store.dispatch(
                aktualisiereBerechtigungsgruppeCommand(
                    formData,
                    this._projektId
                )
            );

            modal.setAttribute("locked", true);
            button.setAttribute("disabled", true);

            if (data) {
                store.dispatch(
                    showToast({
                        text: "Berechtigungsgruppe wurde erfolgreich aktualisiert",
                    })
                );
                store.dispatch(fetchBerechtigungsgruppenAdmin(this._projektId));
            }

            this._loading = false;
            form.reset();
            this._selectedBerechtigungsgruppe = {};
            modal.removeAttribute("locked");
            button.removeAttribute("disabled");
            modal.close();
        }
    }

    async _onBerechtigungsgruppeLoeschen(e) {
        e.preventDefault();

        const formData = new FormData(),
            modal = this.shadowRoot.querySelector(
                "#loesche-berechtigungsgruppe"
            ),
            button = modal.querySelector("button.btn-danger");

        formData.append(
            "BerechtigungsgruppeId",
            this._selectedBerechtigungsgruppe.berechtigungsgruppeId
        );

        this._loading = true;
        modal.setAttribute("locked", true);
        button.setAttribute("disabled", true);

        const data = await store.dispatch(
            loescheBerechtigungsgruppeCommand(formData, this._projektId)
        );
        if (data) {
            store.dispatch(
                showToast({
                    text: "Berechtigungsgruppe wurde erfolgreich gelöscht",
                })
            );
            store.dispatch(fetchBerechtigungsgruppenAdmin(this._projektId));
        }

        this._loading = false;
        modal.removeAttribute("locked");
        button.removeAttribute("disabled");
        modal.close();
    }

    _updateDataTable() {
        const dataTable = this.shadowRoot.querySelector("data-table");

        class dataItem {
            constructor(item) {
                this.id = item.berechtigungsgruppeId;
                this.name = item.berechtigungsgruppeName;
                this.zuletztGeaendertAm =
                    item.zuletztGeaendertAm != null
                        ? moment
                              .utc(item.zuletztGeaendertAm)
                              .local()
                              .format("DD.MM.YYYY HH:mm")
                        : "";
                this.zuletztSynchronisiertAm =
                    item.zuletztSynchronisiertAm != null
                        ? moment(item.zuletztSynchronisiertAm)
                              .local()
                              .format("DD.MM.YYYY HH:mm")
                        : "";
                this.istSynchronisiert = "";
                this.berechtigungen = "";
                this.mitarbeiterCount = item.mitarbeiterCount;

                this.istSynchronisiert = () => {
                    return `
                        <p class="${
                            item.istSynchronisiert
                                ? "text-success"
                                : "text-danger"
                        }">${item.istSynchronisiert ? "ja" : "nein"}</p>
                    `;
                };

                this.berechtigungen = () => {
                    const array = item.berechtigungen;
                    array.sort((a, b) => a.LeseeinheitName - b.LeseeinheitName);
                    if (array.length === 0) return `-`;
                    return `
                        <table class="table-borderless">
                            <thead>
                                <tr>
                                    <th><small><b>Leseeinheit</b></small></th>
                                    <th><small><b>Geht in Raumzone</b></small></th>
                                    <th><small><b>Zeitzone</b></small></th>
                                </tr>
                            </thead>
                            <tbody>
                                ${array
                                    ?.map(
                                        (x) => `
                                    <tr>
                                        <td>${x.leseeinheitName}</td>
                                        <td>${x.raumzoneName}</td>
                                        <td>${x.zeitzoneName}</td>
                                    </tr>`
                                    )
                                    .join("")}
                            </tbody>
                        </table>
                    `;
                };

                this.actions = () => {
                    if (
                        item.projektId ===
                        "84abce91-d673-46cb-8041-fbb2fb996a8a"
                    )
                        return ``;
                    return `
                        <div class="text-right" style="white-space: nowrap; overflow: hidden;">
                            <span class="tool-tip" data-toggle="tooltip" title="Berechtigungsgruppe aktualisieren">
                                <a data-id="${item.berechtigungsgruppeId}" title="Berechtigungsgruppe aktualisieren" class="actions update" role="button" title="Aktualisieren">
                                    <i class="fas fa-pen"></i>
                                </a>
                            </span>
                            <span class="tool-tip" data-toggle="tooltip" title="BerechtigungsgruppenId kopieren ">
                            <a data-id="${item.berechtigungsgruppeId}" title="BerechtigungsgruppenId kopieren " class="actions copy" role="button" title="Aktualisieren">
                                <i class="fas fa-copy"></i>
                            </a>
                           </span>
                            <span class="tool-tip" data-toggle="tooltip" title="Berechtigungsgruppe löschen">
                                <a data-id="${item.berechtigungsgruppeId}" title="Berechtigungsgruppe löschen" class="actions delete text-danger" role="button" title="Löschen">
                                    <i class="fas fa-trash"></i>
                                </a>
                            </span>
                        </div>`;
                };
            }
        }

        if (dataTable && this._berechtigungsgruppen)
            dataTable.updateData(
                this._berechtigungsgruppen?.map((x) => new dataItem(x))
            );
    }

    _updateEventListener() {
        const _this = this,
            dataTable = this.shadowRoot.querySelector("data-table"),
            updateButtons = [
                ...dataTable.shadowRoot.querySelectorAll("a.actions.update"),
            ],
            deleteButtons = [
                ...dataTable.shadowRoot.querySelectorAll("a.actions.delete"),
            ],
            copyButtons = [
                ...dataTable.shadowRoot.querySelectorAll("a.actions.copy"),
            ];

        copyButtons?.map((x) =>
            x.addEventListener("click", () => {
                const id = x.getAttribute("data-id");
                navigator.clipboard.writeText(id);
                store.dispatch(showToast({ text: "Id kopiert" }));
                _this._updateDataTable();
                _this._updateEventListener();
            })
        );
        updateButtons?.map((x) =>
            x.addEventListener("click", () => {
                const id = x.getAttribute("data-id"),
                    modal = _this.shadowRoot.querySelector(
                        "#aktualisiere-berechtigungsgruppe"
                    );

                _this._selectedBerechtigungsgruppe =
                    _this._berechtigungsgruppen.find(
                        (x) => x.berechtigungsgruppeId === id
                    ) || {};
                _this._selectedBerechtigungen =
                    _this._selectedBerechtigungsgruppe.raumzonen || [];

                modal.show();
                _this._updateDataTable();
                _this._updateEventListener();
            })
        );

        deleteButtons?.map((x) =>
            x.addEventListener("click", () => {
                const id = x.getAttribute("data-id"),
                    modal = _this.shadowRoot.querySelector(
                        "#loesche-berechtigungsgruppe"
                    );

                _this._selectedBerechtigungsgruppe =
                    _this._berechtigungsgruppen.find(
                        (x) => x.berechtigungsgruppeId === id
                    ) || {};

                modal.show();
                _this._updateDataTable();
                _this._updateEventListener();
            })
        );
    }

    updated(properties) {
        properties.forEach((oldValue, propName) => {
            if (propName === "_berechtigungsgruppen") {
                if (this._berechtigungsgruppen !== oldValue) {
                    this._updateDataTable();
                    this._updateEventListener();
                }
            }
            if (propName === "active") {
                if (this.active !== oldValue) {
                    if (this.active) {
                        store.dispatch(
                            fetchBerechtigungsgruppenAdmin(this._projektId)
                        );
                    } else {
                        store.dispatch(resetBerechtigungsgruppenAdmin());
                    }
                }
            }
        });
    }

    stateChanged(state) {
        this._projektId = state.algoApp.projektId;
        this._loading = state.berechtigungsgruppen.loading;
        this._berechtigungsgruppen =
            state.berechtigungsgruppen?.berechtigungsgruppenAdmin
                ?.berechtigungsgruppen || [];
        this._berechtigungsgruppenCounter =
            state.berechtigungsgruppen?.berechtigungsgruppenAdmin?.berechtigungsgruppenCounter;
        this._leseeinheiten =
            state.berechtigungsgruppen.berechtigungsgruppenAdmin
                ?.leseeinheiten || [];
        this._zeitzonen =
            state.berechtigungsgruppen.berechtigungsgruppenAdmin?.zeitzonen ||
            [];
        this._raumzonen =
            state.berechtigungsgruppen.berechtigungsgruppenAdmin?.raumzonen ||
            [];
    }
}

window.customElements.define(
    "zuko-einstellungen-uebersicht-view-berechtigungsgruppen-page",
    ZukoEinstellungenUebersichtViewBerechtigungsgruppenPage
);
