export const MITARBEITER_ERSTELLEN_COMMAND_SUCCESS =
    "MITARBEITER_ERSTELLEN_COMMAND_SUCCESS";
export const MITARBEITER_ERSTELLEN_VIEW_DATA_ACTION_FAIL =
    "MITARBEITER_ERSTELLEN_VIEW_DATA_ACTION_FAIL";
export const MITARBEITER_ERSTELLEN_VIEW_DATA_ACTION_BEGIN =
    "MITARBEITER_ERSTELLEN_VIEW_DATA_ACTION_BEGIN";
export const FETCH_MITARBEITER_ERSTELLEN_VIEW_DATA_SUCCESS =
    "FETCH_MITARBEITER_ERSTELLEN_VIEW_DATA_SUCCESS";
export const RESET_MITARBEITER_ERSTELLEN_VIEW_DATA_SUCCESS =
    "RESET_MITARBEITER_ERSTELLEN_VIEW_DATA_SUCCESS";

import errorHandler from "../errorHandling.js";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;

export const mitarbeiterErstellenCommand = (data) => async (dispatch) => {
    appInsights.startTrackEvent("mitarbeiterErstellenCommand");
    dispatch({ type: MITARBEITER_ERSTELLEN_VIEW_DATA_ACTION_BEGIN });

    let headers = new Headers();
    headers.append("Accept", "application/json");

    let formData = new FormData();
    for (const [key, value] of Object.entries(data.stammdaten)) {
        if (value) formData.append(key, value);
    }

    data.unterlagen.forEach((x) => {
        if (x.file?.name)
            formData.append(x.eufemaId, x.file, x.file.name);
    });

    let request = new Request(
        `${apiHost}/api/zuko/v3/${data.projektId}/erstelle-mitarbeiter/`,
        {
            method: "POST",
            body: formData,
            headers: headers,
            credentials: "include",
        }
    );
    const response = await fetch(request);
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: MITARBEITER_ERSTELLEN_VIEW_DATA_ACTION_FAIL,
                error: json.payload,
            });
            appInsights.stopTrackEvent("mitarbeiterErstellenCommand", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: MITARBEITER_ERSTELLEN_VIEW_DATA_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("mitarbeiterErstellenCommand", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: MITARBEITER_ERSTELLEN_COMMAND_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("mitarbeiterErstellenCommand", {
            success: json.success,
        });
        return json.payload;
    }
};

export const resetMitarbeiterErstellenViewData = () => (dispatch) => {
    dispatch({ type: RESET_MITARBEITER_ERSTELLEN_VIEW_DATA_SUCCESS });
    appInsights.trackEvent({ name: "resetMitarbeiterErstellenViewData" });
};

export const fetchMitarbeiterErstellenViewData = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchMitarbeiterErstellenViewData");
    dispatch({ type: MITARBEITER_ERSTELLEN_VIEW_DATA_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/v3/${data.projektId}/mitarbeiter-erstellen-view-data/`,
        getRequestOptions
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: MITARBEITER_ERSTELLEN_VIEW_DATA_ACTION_FAIL,
                error: { errorMessage: json.toString() },
            });
            appInsights.stopTrackEvent("fetchMitarbeiterErstellenViewData", {
                success: json.success,
                error: { errorMessage: json.toString() },
            });
        } else {
            dispatch(errorHandler(response));
            appInsights.stopTrackEvent("fetchMitarbeiterErstellenViewData", {
                success: response.ok,
                error: { errorMessage: response.toString() },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_MITARBEITER_ERSTELLEN_VIEW_DATA_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchMitarbeiterErstellenViewData", {
            success: json.success,
        });
    }
};
