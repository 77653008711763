import {
  ZEITZONEN_ACTION_BEGIN,
  ZEITZONEN_ACTION_FAIL,
  FETCH_ZEITZONEN_SUCCESS,
  RESET_ZEITZONEN_SUCCESS,
  LOESCHE_ZEITZONEN_SUCCESS,
  ERSTELLE_ZEITZONEN_SUCCESS,
  RESET_ZEITZONEN_ADMIN_SUCCESS,
  FETCH_ZEITZONEN_ADMIN_SUCCESS,
  AKTUALISIERE_ZEITZONEN_SUCCESS,
  SYNCHRONISIERE_ZEITZONEN_SUCCESS,
} from "../../actions/zuko/zeitzonen.js";

const zeitzonenState = {
  error: null,
  loading: false,
  zeitzonen: [],
  zeitzonenAdmin:[]
};

const savedState =
  localStorage.getItem("savedState") || JSON.stringify({ ...zeitzonenState });
const INITIAL_STATE = JSON.parse(savedState).zeitzonenState || zeitzonenState;

const zeitzonen = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ZEITZONEN_ACTION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ZEITZONEN_ACTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_ZEITZONEN_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        zeitzonen: action.payload,
      };
    case FETCH_ZEITZONEN_ADMIN_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        zeitzonenAdmin: action.payload,
      };
    case RESET_ZEITZONEN_SUCCESS:
      return {
        ...state,
        error: null,
        zeitzonen: [],
        loading: false,
      };
    case RESET_ZEITZONEN_ADMIN_SUCCESS:
        return{
            ...state,
            error: null,
            zeitzonenAdmin: [],
            loading: false
        };
    case LOESCHE_ZEITZONEN_SUCCESS:
    case ERSTELLE_ZEITZONEN_SUCCESS:
    case AKTUALISIERE_ZEITZONEN_SUCCESS:
    case SYNCHRONISIERE_ZEITZONEN_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default zeitzonen;
