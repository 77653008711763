import { css } from "lit";

export const SbAdmin2Styles = css`
    /*!
* Start Bootstrap - SB Admin 2 v4.0.2 (https://startbootstrap.com/template-overviews/sb-admin-2)
* Copyright 2013-2019 Start Bootstrap
* Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-sb-admin-2/blob/master/LICENSE)
*/

    /*!
* Bootstrap v4.3.1 (https://getbootstrap.com/)
* Copyright 2011-2019 The Bootstrap Authors
* Copyright 2011-2019 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
*/
    :root {
        --white: #fff;
        --gray-dark: #404040;
        --primary: #4e73df;
        --secondary: #858796;
        --success: #3ca085;
        --info: #36b9cc;
        --warning: #f5bb00;
        --danger: #d34a4aff;
        --light: #f8f9fc;
        --white-grey: #d9d9d9;
        --dark: #5a5c69;
        --breakpoint-xs: 0;
        --breakpoint-sm: 576px;
        --breakpoint-md: 768px;
        --breakpoint-lg: 992px;
        --breakpoint-xl: 1200px;
        --font-family-sans-serif: "Nunito", -apple-system, BlinkMacSystemFont,
            "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
            "Noto Color Emoji";
        --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
            "Liberation Mono", "Courier New", monospace;
    }

    *,
    *::before,
    *::after {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    html {
        font-family: sans-serif;
        line-height: 1.15;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .handbuch {
        text-decoration: none;
    }
    .handbuch:focus {
        text-decoration: underline;
    }

    article,
    aside,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    nav,
    section {
        display: block;
    }

    body {
        margin: 0;
        font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
            "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--secondary);
        text-align: left;
        background-color: var(--white);
        overflow-x: scroll;
    }
    #progressContrainer {
        padding-left: 15px;
    }

    .input-group {
        display: inherit !important;
    }

    .invalid-feedback {
        display: inline;
        padding-left: 5px;
    }

    input.form-control {
        overflow: hidden !important;
    }

    input[type="file"]::file-selector-button {
        display: none;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
    }
    .fa-spin {
        color: var(--gray-dark) !important;
    }

    .details > tbody > tr > th,
    .details > tbody > tr {
        background: white !important;
        background-color: white !important;
        color: var(--gray-dark) !important;
    }

    table.details td {
        border: none !important;
    }

    table.details th:first-child {
        border-left: none !important;
    }

    table.details th:last-child {
        border-right: none !important;
    }

    table.details tfoot th:last-child,
    table.details td:last-child,
    table.details td:last-child {
        border-right: none !important;
    }

    table.details tfoot th:first-child,
    table.dataTable td:first-child,
    table.dataTable td:first-child {
        border-left: none !important;
    }
    [tabindex="-1"]:focus {
        outline: 0 !important;
    }

    hr {
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        height: 0;
        overflow: visible;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: 0.5rem;
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    abbr[title],
    abbr[data-original-title] {
        text-decoration: underline;
        -webkit-text-decoration: underline dotted;
        text-decoration: underline dotted;
        cursor: help;
        border-bottom: 0;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
    }

    address {
        margin-bottom: 1rem;
        font-style: normal;
        line-height: inherit;
    }

    ol,
    ul,
    dl {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    ol ol,
    ul ul,
    ol ul,
    ul ol {
        margin-bottom: 0;
    }

    dt {
        font-weight: 700;
    }

    dd {
        margin-bottom: 0.5rem;
        margin-left: 0;
    }

    blockquote {
        margin: 0 0 1rem;
    }

    b,
    strong {
        font-weight: bolder;
    }

    small {
        font-size: 0, 835rem;
    }

    sub,
    sup {
        position: relative;
        font-size: 75%;
        line-height: 0;
        vertical-align: baseline;
    }

    sub {
        bottom: -0.25em;
    }

    sup {
        top: -0.5em;
    }

    a {
        color: var(--gray-dark) !important;
        background-color: transparent;
    }

    a:hover {
        color: var(--warning) !important;
        text-decoration: underline;
    }

    a:not([href]):not([tabindex]) {
        color: inherit;
        text-decoration: none;
    }

    a:not([href]):not([tabindex]):hover,
    a:not([href]):not([tabindex]):focus {
        color: inherit;
        text-decoration: none;
    }

    a:not([href]):not([tabindex]):focus {
        outline: 0;
    }

    .link {
        text-decoration: underline !important;
    }

    .link:hover {
        text-decoration: underline !important;
    }

    pre,
    code,
    kbd,
    samp {
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
            "Courier New", monospace;
        font-size: 1em;
    }

    pre {
        margin-top: 0;
        margin-bottom: 1rem;
        overflow: auto;
    }

    figure {
        margin: 0 0 1rem;
    }

    img {
        vertical-align: middle;
        border-style: none;
    }

    svg {
        overflow: hidden;
        vertical-align: middle;
    }

    caption {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        color: var(--secondary);
        text-align: left;
        caption-side: bottom;
    }

    th {
        text-align: inherit;
    }

    span.text {
        max-width: 225px;
    }

    span.text,
    label.col-form-label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    label {
        display: inline-block;
        margin-bottom: 0.5rem;
    }

    .transparentBtn {
        border: none;
        background-color: transparent;
    }

    .transparentBtn:hover {
        color: var(--warning);
    }

    button {
        border-radius: 0;
    }

    button:focus {
        outline: 1px dotted;
        outline: 5px auto -webkit-focus-ring-color;
    }

    input,
    button,
    select,
    optgroup,
    textarea {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    button,
    input {
        overflow: visible;
    }

    button,
    select {
        text-transform: none;
    }

    select {
        word-wrap: normal;
    }

    button,
    [type="button"],
    [type="reset"],
    [type="submit"] {
        -webkit-appearance: button;
    }

    button:not(:disabled),
    [type="button"]:not(:disabled),
    [type="reset"]:not(:disabled),
    [type="submit"]:not(:disabled) {
        cursor: pointer;
    }

    button::-moz-focus-inner,
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner {
        padding: 0;
        border-style: none;
    }

    input[type="radio"],
    input[type="checkbox"] {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0;
    }

    input[type="date"],
    input[type="time"],
    input[type="datetime-local"],
    input[type="month"] {
        -webkit-appearance: listbox;
    }

    textarea {
        overflow: auto;
        resize: vertical;
    }

    fieldset {
        min-width: 0;
        padding: 0;
        margin: 0;
        border: 0;
    }

    legend {
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        line-height: inherit;
        color: inherit;
        white-space: normal;
    }

    progress {
        vertical-align: baseline;
    }

    [type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
        height: auto;
    }

    [type="search"] {
        outline-offset: -2px;
        -webkit-appearance: none;
    }

    [type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    ::-webkit-file-upload-button {
        font: inherit;
        -webkit-appearance: button;
    }

    output {
        display: inline-block;
    }

    summary {
        display: list-item;
        cursor: pointer;
    }

    template {
        display: none;
    }

    [hidden] {
        display: none !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        margin-bottom: 0.5rem;
        font-weight: 400;
        line-height: 1.2;
    }

    h1,
    .h1 {
        font-size: 2.5rem;
    }

    h2,
    .h2 {
        font-size: 2rem;
    }

    h3,
    .h3 {
        font-size: 1.75rem;
    }

    h4,
    .h4 {
        font-size: 1.5rem;
    }

    h5,
    .h5 {
        font-size: 1.25rem;
    }

    h6,
    .h6 {
        font-size: 1rem;
    }

    .container-kacheln {
        border: 1px solid rgb(40, 40, 40);
        min-width: 45px;
        min-height: 25px;
        max-height: 25px;
        text-align: center;
    }

    .grau {
        background-color: var(--gray-dark);
    }
    .gelb {
        background-color: var(--warning);
        color: white;
    }

    .weiss {
        background-color: var(--white-grey);
    }

    .rot {
        background-color: var(--danger);
        color: white;
    }

    .gruen {
        background-color: var(--success);
        color: white;
    }

    .blau {
        background-color: var(--primary);
        color: white;
    }

    .lead {
        font-size: 1.25rem;
        font-weight: 300;
    }

    .display-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
    }

    .display-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
    }

    .display-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
    }

    .display-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
    }

    hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    small,
    .small {
        font-size: 0.835rem;
        font-weight: 400;
    }

    mark,
    .mark {
        padding: 0.2em;
        background-color: #fcf8e3;
    }

    .list-unstyled {
        padding-left: 0;
        list-style: none;
    }

    .list-inline {
        padding-left: 0;
        list-style: none;
    }

    .list-inline-item {
        display: inline-block;
    }

    .list-inline-item:not(:last-child) {
        margin-right: 0.5rem;
    }

    .initialism {
        font-size: 90%;
        text-transform: uppercase;
    }

    .blockquote {
        margin-bottom: 1rem;
        font-size: 1.25rem;
    }

    .blockquote-footer {
        display: block;
        font-size: 80%;
        color: var(--secondary);
    }

    .blockquote-footer::before {
        content: "\\2014\\00A0";
    }

    .img-fluid {
        max-width: 100%;
        height: auto;
    }

    .img-thumbnail {
        padding: 0.25rem;
        background-color: var(--white);
        border: 1px solid #dddfeb;
        border-radius: 0.35rem;
        max-width: 100%;
        height: auto;
    }

    .figure {
        display: inline-block;
    }

    .figure-img {
        margin-bottom: 0.5rem;
        line-height: 1;
    }

    .figure-caption {
        font-size: 90%;
        color: var(--secondary);
    }

    a > code {
        color: inherit;
    }

    kbd {
        padding: 0.2rem 0.4rem;
        font-size: 87.5%;
        color: var(--white);
        background-color: #3a3b45;
        border-radius: 0.2rem;
    }

    kbd kbd {
        padding: 0;
        font-size: 100%;
        font-weight: 700;
    }

    pre {
        display: block;
        font-size: 87.5%;
        color: #3a3b45;
    }

    pre code {
        font-size: inherit;
        color: inherit;
        word-break: normal;
    }

    .pre-scrollable {
        max-height: 340px;
        overflow-y: scroll;
    }

    .container {
        width: 100%;
        padding-right: 0.75rem;
        padding-left: 0.75rem;
        margin-right: auto;
        margin-left: auto;
    }

    @media (min-width: 576px) {
        .container {
            max-width: 540px;
        }
    }

    @media (min-width: 768px) {
        .container {
            max-width: 720px;
        }
    }

    @media (min-width: 992px) {
        .container {
            max-width: 960px;
        }
    }

    @media (min-width: 1200px) {
        .container {
            max-width: 100%;
        }
    }

    .container-fluid {
        width: 100%;
        padding-right: 0.75rem;
        padding-left: 0.75rem;
        margin-right: auto;
        margin-left: auto;
    }

    .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -0.75rem;
        margin-left: -0.75rem;
    }

    .no-gutters {
        margin-right: 0;
        margin-left: 0;
    }

    .no-gutters > .col,
    .no-gutters > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-auto,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-sm-auto,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-md-auto,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-lg-auto,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl,
    .col-xl-auto {
        position: relative;
        width: 100%;
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }

    .col {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .order-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }

    .order-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }

    .order-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }

    .order-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }

    .order-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }

    .order-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }

    .order-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }

    .order-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }

    .order-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }

    .order-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }

    .order-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }

    .order-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }

    .order-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }

    .order-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }

    .offset-1 {
        margin-left: 8.33333%;
    }

    .offset-2 {
        margin-left: 16.66667%;
    }

    .offset-3 {
        margin-left: 25%;
    }

    .offset-4 {
        margin-left: 33.33333%;
    }

    .offset-5 {
        margin-left: 41.66667%;
    }

    .offset-6 {
        margin-left: 50%;
    }

    .offset-7 {
        margin-left: 58.33333%;
    }

    .offset-8 {
        margin-left: 66.66667%;
    }

    .offset-9 {
        margin-left: 75%;
    }

    .offset-10 {
        margin-left: 83.33333%;
    }

    .offset-11 {
        margin-left: 91.66667%;
    }

    @media (min-width: 576px) {
        .col-sm {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
        }
        .col-sm-auto {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
        .col-sm-1 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
            max-width: 8.33333%;
        }
        .col-sm-2 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
        }
        .col-sm-3 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
        .col-sm-4 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }
        .col-sm-5 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
            max-width: 41.66667%;
        }
        .col-sm-6 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
        .col-sm-7 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
            max-width: 58.33333%;
        }
        .col-sm-8 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
        }
        .col-sm-9 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 75%;
            flex: 0 0 75%;
            max-width: 75%;
        }
        .col-sm-10 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
        }
        .col-sm-11 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
            max-width: 91.66667%;
        }
        .col-sm-12 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        .order-sm-first {
            -webkit-box-ordinal-group: 0;
            -ms-flex-order: -1;
            order: -1;
        }
        .order-sm-last {
            -webkit-box-ordinal-group: 14;
            -ms-flex-order: 13;
            order: 13;
        }
        .order-sm-0 {
            -webkit-box-ordinal-group: 1;
            -ms-flex-order: 0;
            order: 0;
        }
        .order-sm-1 {
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1;
        }
        .order-sm-2 {
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
        }
        .order-sm-3 {
            -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
            order: 3;
        }
        .order-sm-4 {
            -webkit-box-ordinal-group: 5;
            -ms-flex-order: 4;
            order: 4;
        }
        .order-sm-5 {
            -webkit-box-ordinal-group: 6;
            -ms-flex-order: 5;
            order: 5;
        }
        .order-sm-6 {
            -webkit-box-ordinal-group: 7;
            -ms-flex-order: 6;
            order: 6;
        }
        .order-sm-7 {
            -webkit-box-ordinal-group: 8;
            -ms-flex-order: 7;
            order: 7;
        }
        .order-sm-8 {
            -webkit-box-ordinal-group: 9;
            -ms-flex-order: 8;
            order: 8;
        }
        .order-sm-9 {
            -webkit-box-ordinal-group: 10;
            -ms-flex-order: 9;
            order: 9;
        }
        .order-sm-10 {
            -webkit-box-ordinal-group: 11;
            -ms-flex-order: 10;
            order: 10;
        }
        .order-sm-11 {
            -webkit-box-ordinal-group: 12;
            -ms-flex-order: 11;
            order: 11;
        }
        .order-sm-12 {
            -webkit-box-ordinal-group: 13;
            -ms-flex-order: 12;
            order: 12;
        }
        .offset-sm-0 {
            margin-left: 0;
        }
        .offset-sm-1 {
            margin-left: 8.33333%;
        }
        .offset-sm-2 {
            margin-left: 16.66667%;
        }
        .offset-sm-3 {
            margin-left: 25%;
        }
        .offset-sm-4 {
            margin-left: 33.33333%;
        }
        .offset-sm-5 {
            margin-left: 41.66667%;
        }
        .offset-sm-6 {
            margin-left: 50%;
        }
        .offset-sm-7 {
            margin-left: 58.33333%;
        }
        .offset-sm-8 {
            margin-left: 66.66667%;
        }
        .offset-sm-9 {
            margin-left: 75%;
        }
        .offset-sm-10 {
            margin-left: 83.33333%;
        }
        .offset-sm-11 {
            margin-left: 91.66667%;
        }
    }

    @media (min-width: 768px) {
        .col-md {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
        }
        .col-md-auto {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
        .col-md-1 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
            max-width: 8.33333%;
        }
        .col-md-2 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
        }
        .col-md-3 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
        .col-md-4 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }
        .col-md-5 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
            max-width: 41.66667%;
        }
        .col-md-6 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
        .col-md-7 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
            max-width: 58.33333%;
        }
        .col-md-8 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
        }
        .col-md-9 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 75%;
            flex: 0 0 75%;
            max-width: 75%;
        }
        .col-md-10 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
        }
        .col-md-11 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
            max-width: 91.66667%;
        }
        .col-md-12 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        .order-md-first {
            -webkit-box-ordinal-group: 0;
            -ms-flex-order: -1;
            order: -1;
        }
        .order-md-last {
            -webkit-box-ordinal-group: 14;
            -ms-flex-order: 13;
            order: 13;
        }
        .order-md-0 {
            -webkit-box-ordinal-group: 1;
            -ms-flex-order: 0;
            order: 0;
        }
        .order-md-1 {
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1;
        }
        .order-md-2 {
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
        }
        .order-md-3 {
            -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
            order: 3;
        }
        .order-md-4 {
            -webkit-box-ordinal-group: 5;
            -ms-flex-order: 4;
            order: 4;
        }
        .order-md-5 {
            -webkit-box-ordinal-group: 6;
            -ms-flex-order: 5;
            order: 5;
        }
        .order-md-6 {
            -webkit-box-ordinal-group: 7;
            -ms-flex-order: 6;
            order: 6;
        }
        .order-md-7 {
            -webkit-box-ordinal-group: 8;
            -ms-flex-order: 7;
            order: 7;
        }
        .order-md-8 {
            -webkit-box-ordinal-group: 9;
            -ms-flex-order: 8;
            order: 8;
        }
        .order-md-9 {
            -webkit-box-ordinal-group: 10;
            -ms-flex-order: 9;
            order: 9;
        }
        .order-md-10 {
            -webkit-box-ordinal-group: 11;
            -ms-flex-order: 10;
            order: 10;
        }
        .order-md-11 {
            -webkit-box-ordinal-group: 12;
            -ms-flex-order: 11;
            order: 11;
        }
        .order-md-12 {
            -webkit-box-ordinal-group: 13;
            -ms-flex-order: 12;
            order: 12;
        }
        .offset-md-0 {
            margin-left: 0;
        }
        .offset-md-1 {
            margin-left: 8.33333%;
        }
        .offset-md-2 {
            margin-left: 16.66667%;
        }
        .offset-md-3 {
            margin-left: 25%;
        }
        .offset-md-4 {
            margin-left: 33.33333%;
        }
        .offset-md-5 {
            margin-left: 41.66667%;
        }
        .offset-md-6 {
            margin-left: 50%;
        }
        .offset-md-7 {
            margin-left: 58.33333%;
        }
        .offset-md-8 {
            margin-left: 66.66667%;
        }
        .offset-md-9 {
            margin-left: 75%;
        }
        .offset-md-10 {
            margin-left: 83.33333%;
        }
        .offset-md-11 {
            margin-left: 91.66667%;
        }
    }

    @media (min-width: 992px) {
        .col-lg {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
        }
        .col-lg-auto {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
        .col-lg-1 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
            max-width: 8.33333%;
        }
        .col-lg-2 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
        }
        .col-lg-3 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
        .col-lg-4 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }
        .col-lg-5 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
            max-width: 41.66667%;
        }
        .col-lg-6 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
        .col-lg-7 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
            max-width: 58.33333%;
        }
        .col-lg-8 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
        }
        .col-lg-9 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 75%;
            flex: 0 0 75%;
            max-width: 75%;
        }
        .col-lg-10 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
        }
        .col-lg-11 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
            max-width: 91.66667%;
        }
        .col-lg-12 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        .order-lg-first {
            -webkit-box-ordinal-group: 0;
            -ms-flex-order: -1;
            order: -1;
        }
        .order-lg-last {
            -webkit-box-ordinal-group: 14;
            -ms-flex-order: 13;
            order: 13;
        }
        .order-lg-0 {
            -webkit-box-ordinal-group: 1;
            -ms-flex-order: 0;
            order: 0;
        }
        .order-lg-1 {
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1;
        }
        .order-lg-2 {
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
        }
        .order-lg-3 {
            -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
            order: 3;
        }
        .order-lg-4 {
            -webkit-box-ordinal-group: 5;
            -ms-flex-order: 4;
            order: 4;
        }
        .order-lg-5 {
            -webkit-box-ordinal-group: 6;
            -ms-flex-order: 5;
            order: 5;
        }
        .order-lg-6 {
            -webkit-box-ordinal-group: 7;
            -ms-flex-order: 6;
            order: 6;
        }
        .order-lg-7 {
            -webkit-box-ordinal-group: 8;
            -ms-flex-order: 7;
            order: 7;
        }
        .order-lg-8 {
            -webkit-box-ordinal-group: 9;
            -ms-flex-order: 8;
            order: 8;
        }
        .order-lg-9 {
            -webkit-box-ordinal-group: 10;
            -ms-flex-order: 9;
            order: 9;
        }
        .order-lg-10 {
            -webkit-box-ordinal-group: 11;
            -ms-flex-order: 10;
            order: 10;
        }
        .order-lg-11 {
            -webkit-box-ordinal-group: 12;
            -ms-flex-order: 11;
            order: 11;
        }
        .order-lg-12 {
            -webkit-box-ordinal-group: 13;
            -ms-flex-order: 12;
            order: 12;
        }
        .offset-lg-0 {
            margin-left: 0;
        }
        .offset-lg-1 {
            margin-left: 8.33333%;
        }
        .offset-lg-2 {
            margin-left: 16.66667%;
        }
        .offset-lg-3 {
            margin-left: 25%;
        }
        .offset-lg-4 {
            margin-left: 33.33333%;
        }
        .offset-lg-5 {
            margin-left: 41.66667%;
        }
        .offset-lg-6 {
            margin-left: 50%;
        }
        .offset-lg-7 {
            margin-left: 58.33333%;
        }
        .offset-lg-8 {
            margin-left: 66.66667%;
        }
        .offset-lg-9 {
            margin-left: 75%;
        }
        .offset-lg-10 {
            margin-left: 83.33333%;
        }
        .offset-lg-11 {
            margin-left: 91.66667%;
        }
    }

    @media (min-width: 1200px) {
        .col-xl {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
        }
        .col-xl-auto {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
        .col-xl-1 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
            max-width: 8.33333%;
        }
        .col-xl-2 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
        }
        .col-xl-3 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
        .col-xl-4 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }
        .col-xl-5 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
            max-width: 41.66667%;
        }
        .col-xl-6 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
        .col-xl-7 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
            max-width: 58.33333%;
        }
        .col-xl-8 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
        }
        .col-xl-9 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 75%;
            flex: 0 0 75%;
            max-width: 75%;
        }
        .col-xl-10 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
        }
        .col-xl-11 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
            max-width: 91.66667%;
        }
        .col-xl-12 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        .order-xl-first {
            -webkit-box-ordinal-group: 0;
            -ms-flex-order: -1;
            order: -1;
        }
        .order-xl-last {
            -webkit-box-ordinal-group: 14;
            -ms-flex-order: 13;
            order: 13;
        }
        .order-xl-0 {
            -webkit-box-ordinal-group: 1;
            -ms-flex-order: 0;
            order: 0;
        }
        .order-xl-1 {
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1;
        }
        .order-xl-2 {
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
        }
        .order-xl-3 {
            -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
            order: 3;
        }
        .order-xl-4 {
            -webkit-box-ordinal-group: 5;
            -ms-flex-order: 4;
            order: 4;
        }
        .order-xl-5 {
            -webkit-box-ordinal-group: 6;
            -ms-flex-order: 5;
            order: 5;
        }
        .order-xl-6 {
            -webkit-box-ordinal-group: 7;
            -ms-flex-order: 6;
            order: 6;
        }
        .order-xl-7 {
            -webkit-box-ordinal-group: 8;
            -ms-flex-order: 7;
            order: 7;
        }
        .order-xl-8 {
            -webkit-box-ordinal-group: 9;
            -ms-flex-order: 8;
            order: 8;
        }
        .order-xl-9 {
            -webkit-box-ordinal-group: 10;
            -ms-flex-order: 9;
            order: 9;
        }
        .order-xl-10 {
            -webkit-box-ordinal-group: 11;
            -ms-flex-order: 10;
            order: 10;
        }
        .order-xl-11 {
            -webkit-box-ordinal-group: 12;
            -ms-flex-order: 11;
            order: 11;
        }
        .order-xl-12 {
            -webkit-box-ordinal-group: 13;
            -ms-flex-order: 12;
            order: 12;
        }
        .offset-xl-0 {
            margin-left: 0;
        }
        .offset-xl-1 {
            margin-left: 8.33333%;
        }
        .offset-xl-2 {
            margin-left: 16.66667%;
        }
        .offset-xl-3 {
            margin-left: 25%;
        }
        .offset-xl-4 {
            margin-left: 33.33333%;
        }
        .offset-xl-5 {
            margin-left: 41.66667%;
        }
        .offset-xl-6 {
            margin-left: 50%;
        }
        .offset-xl-7 {
            margin-left: 58.33333%;
        }
        .offset-xl-8 {
            margin-left: 66.66667%;
        }
        .offset-xl-9 {
            margin-left: 75%;
        }
        .offset-xl-10 {
            margin-left: 83.33333%;
        }
        .offset-xl-11 {
            margin-left: 91.66667%;
        }
    }

    .table {
        width: 100%;
        margin-bottom: 1rem;
        color: var(--secondary);
    }

    .table th,
    .table td {
        padding: 0.75rem;
        vertical-align: middle;
    }

    .table thead th {
        vertical-align: bottom;
    }

    .table tbody + tbody {
        border-top: 2px solid #e3e6f0;
    }

    .table-sm th,
    .table-sm td {
        padding: 0.3rem;
    }

    .table-bordered {
        border: 1px solid #e3e6f0;
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid #e3e6f0;
    }

    .table-bordered thead th,
    .table-bordered thead td {
        border-bottom-width: 2px;
    }

    .table-borderless th,
    .table-borderless td,
    .table-borderless thead th,
    .table-borderless tbody + tbody {
        border: 0;
    }

    .table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.05);
    }

    .table-hover tbody tr:hover {
        color: var(--secondary);
        background-color: rgba(0, 0, 0, 0.075);
    }

    .table-primary,
    .table-primary > th,
    .table-primary > td {
        background-color: #cdd8f6;
    }

    .table-primary th,
    .table-primary td,
    .table-primary thead th,
    .table-primary tbody + tbody {
        border-color: #a3b6ee;
    }

    .table-hover .table-primary:hover {
        background-color: #b7c7f2;
    }

    .table-hover .table-primary:hover > td,
    .table-hover .table-primary:hover > th {
        background-color: #b7c7f2;
    }

    .table-secondary,
    .table-secondary > th,
    .table-secondary > td {
        background-color: #dddde2;
    }

    .table-secondary th,
    .table-secondary td,
    .table-secondary thead th,
    .table-secondary tbody + tbody {
        border-color: #c0c1c8;
    }

    .table-hover .table-secondary:hover {
        background-color: #cfcfd6;
    }

    .table-hover .table-secondary:hover > td,
    .table-hover .table-secondary:hover > th {
        background-color: #cfcfd6;
    }

    .table-success,
    .table-success > th,
    .table-success > td {
        background-color: #bff0de;
    }

    .table-success th,
    .table-success td,
    .table-success thead th,
    .table-success tbody + tbody {
        border-color: #89e2c2;
    }

    .table-hover .table-success:hover {
        background-color: #aaebd3;
    }

    .table-hover .table-success:hover > td,
    .table-hover .table-success:hover > th {
        background-color: #aaebd3;
    }

    .table-info,
    .table-info > th,
    .table-info > td {
        background-color: #c7ebf1;
    }

    .table-info th,
    .table-info td,
    .table-info thead th,
    .table-info tbody + tbody {
        border-color: #96dbe4;
    }

    .table-hover .table-info:hover {
        background-color: #b3e4ec;
    }

    .table-hover .table-info:hover > td,
    .table-hover .table-info:hover > th {
        background-color: #b3e4ec;
    }

    .table-warning,
    .table-warning > th,
    .table-warning > td {
        background-color: #fceec9;
    }

    .table-warning th,
    .table-warning td,
    .table-warning thead th,
    .table-warning tbody + tbody {
        border-color: #fadf9b;
    }

    .table-hover .table-warning:hover {
        background-color: #fbe6b1;
    }

    .table-hover .table-warning:hover > td,
    .table-hover .table-warning:hover > th {
        background-color: #fbe6b1;
    }

    .table-danger,
    .table-danger > th,
    .table-danger > td {
        background-color: #f8ccc8;
    }

    .table-danger th,
    .table-danger td,
    .table-danger thead th,
    .table-danger tbody + tbody {
        border-color: #f3a199;
    }

    .table-hover .table-danger:hover {
        background-color: #f5b7b1;
    }

    .table-hover .table-danger:hover > td,
    .table-hover .table-danger:hover > th {
        background-color: #f5b7b1;
    }

    .table-light,
    .table-light > th,
    .table-light > td {
        background-color: #fdfdfe;
    }

    .table-light th,
    .table-light td,
    .table-light thead th,
    .table-light tbody + tbody {
        border-color: #fbfcfd;
    }

    .table-hover .table-light:hover {
        background-color: #ececf6;
    }

    .table-hover .table-light:hover > td,
    .table-hover .table-light:hover > th {
        background-color: #ececf6;
    }

    .table-dark,
    .table-dark > th,
    .table-dark > td {
        background-color: #d1d1d5;
    }

    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
        border-color: #a9aab1;
    }

    .table-hover .table-dark:hover {
        background-color: #c4c4c9;
    }

    .table-hover .table-dark:hover > td,
    .table-hover .table-dark:hover > th {
        background-color: #c4c4c9;
    }

    .table-active,
    .table-active > th,
    .table-active > td {
        background-color: rgba(0, 0, 0, 0.075);
    }

    .table-hover .table-active:hover {
        background-color: rgba(0, 0, 0, 0.075);
    }

    .table-hover .table-active:hover > td,
    .table-hover .table-active:hover > th {
        background-color: rgba(0, 0, 0, 0.075);
    }

    .table .thead-dark th {
        color: var(--white);
        background-color: var(--gray-dark);
        border-color: var(--gray-dark);
    }

    .table .thead-light th {
        color: var(--gray-dark);
        background-color: #eaecf4;
        border-color: #e3e6f0;
    }

    .table-dark {
        color: var(--white);
        background-color: var(--gray-dark);
    }

    .table-dark th,
    .table-dark td,
    .table-dark thead th {
        border-color: #6c6e7e;
    }

    .table-dark.table-bordered {
        border: 0;
    }

    .table-dark.table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(255, 255, 255, 0.05);
    }

    .table-dark.table-hover tbody tr:hover {
        color: var(--white);
        background-color: rgba(255, 255, 255, 0.075);
    }

    @media (max-width: 575.98px) {
        .table-responsive-sm {
            display: block;
            -webkit-overflow-scrolling: touch;
            overflow-x: auto;
            width: 100%;
        }
        .table-responsive-sm > .table-bordered {
            border: 0;
        }
    }

    @media (max-width: 767.98px) {
        .table-responsive-md {
            display: block;
            -webkit-overflow-scrolling: touch;
            overflow-x: auto;
            width: 100%;
        }
        .table-responsive-md > .table-bordered {
            border: 0;
        }
    }

    @media (max-width: 991.98px) {
        .table-responsive-lg {
            display: block;
            -webkit-overflow-scrolling: touch;
            overflow-x: auto;
            width: 100%;
        }
        .table-responsive-lg > .table-bordered {
            border: 0;
        }
    }

    @media (max-width: 1199.98px) {
        .table-responsive-xl {
            display: block;
            -webkit-overflow-scrolling: touch;
            overflow-x: auto;
            width: 100%;
        }
        .table-responsive-xl > .table-bordered {
            border: 0;
        }
    }

    .table-responsive {
        display: block;
        -webkit-overflow-scrolling: touch;
        width: 100%;
        overflow-x: auto;
    }

    .table-responsive > .table-bordered {
        border: 0;
    }

    .form-control {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: var(--white);
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 1.35rem;
        -webkit-transition: border-color 0.15s ease-in-out,
            -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out,
            -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
            -webkit-box-shadow 0.15s ease-in-out;
    }

    @media (prefers-reduced-motion: reduce) {
        .form-control {
            -webkit-transition: none;
            transition: none;
        }
    }

    .form-control::-ms-expand {
        background-color: transparent;
        border: 0;
    }

    .form-control:focus {
        color: #6e707e;
        background-color: var(--white);
        border-color: #bac8f3;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    }

    .form-control::-webkit-input-placeholder {
        color: var(--secondary);
        opacity: 1;
    }

    .form-control:-ms-input-placeholder {
        color: var(--secondary);
        opacity: 1;
    }

    .form-control::-ms-input-placeholder {
        color: var(--secondary);
        opacity: 1;
    }

    .form-control::placeholder {
        color: var(--secondary);
        opacity: 1;
    }

    .form-control:disabled,
    .form-control[readonly] {
        background-color: #eaecf4;
        opacity: 1;
    }

    select.form-control:focus::-ms-value {
        color: #6e707e;
        background-color: var(--white);
    }

    .form-control-file,
    .form-control-range {
        display: block;
        width: 100%;
    }

    .col-form-label {
        padding-top: calc(0.375rem + 1px);
        padding-bottom: calc(0.375rem + 1px);
        margin-bottom: 0;
        font-size: inherit;
        line-height: 1.5;
    }

    .col-form-label-lg {
        padding-top: calc(0.5rem + 1px);
        padding-bottom: calc(0.5rem + 1px);
        font-size: 1.25rem;
        line-height: 1.5;
    }

    .col-form-label-sm {
        padding-top: calc(0.25rem + 1px);
        padding-bottom: calc(0.25rem + 1px);
        font-size: 0.875rem;
        line-height: 1.5;
    }

    .form-control-plaintext {
        display: block;
        width: 100%;
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
        margin-bottom: 0;
        line-height: 1.5;
        color: var(--secondary);
        background-color: transparent;
        border: solid transparent;
        border-width: 1px 0;
    }

    .form-control-plaintext.form-control-sm,
    .form-control-plaintext.form-control-lg {
        padding-right: 0;
        padding-left: 0;
    }

    .form-control-sm {
        height: calc(1.5em + 0.5rem + 2px);
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
    }

    .form-control-lg {
        height: calc(1.5em + 1rem + 2px);
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
        line-height: 1.5;
        border-radius: 0.3rem;
    }

    select.form-control[size],
    select.form-control[multiple] {
        height: auto;
    }

    textarea.form-control {
        height: auto;
    }

    .form-group {
        margin-bottom: 1rem;
    }

    .form-text {
        display: block;
        margin-top: 0.25rem;
    }

    .form-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -5px;
        margin-left: -5px;
    }

    .form-row > .col,
    .form-row > [class*="col-"] {
        padding-right: 5px;
        padding-left: 5px;
    }

    .form-check {
        position: relative;
        display: block;
        padding-left: 1.25rem;
    }

    .form-check-input {
        position: absolute;
        margin-top: 0.3rem;
        margin-left: -1.25rem;
    }

    .form-check-input:disabled ~ .form-check-label {
        color: var(--secondary);
    }

    .form-check-label {
        margin-bottom: 0;
    }

    .form-check-inline {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-left: 0;
        margin-right: 0.75rem;
    }

    .form-check-inline .form-check-input {
        position: static;
        margin-top: 0;
        margin-right: 0.3125rem;
        margin-left: 0;
    }

    .valid-feedback {
        display: none;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: var(--success);
    }

    .valid-tooltip {
        position: absolute;
        top: 100%;
        z-index: 5;
        display: none;
        max-width: 100%;
        padding: 0.25rem 0.5rem;
        margin-top: 0.1rem;
        font-size: 0.875rem;
        line-height: 1.5;
        color: var(--white);
        background-color: rgba(28, 200, 138, 0.9);
        border-radius: 1.35rem;
    }

    .was-validated .form-control:valid,
    .form-control.is-valid {
        border-color: var(--success);
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231cc88a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: center right calc(0.375em + 0.1875rem);
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .was-validated .form-control:valid:focus,
    .form-control.is-valid:focus {
        border-color: var(--success);
        -webkit-box-shadow: 0 0 0 0.2rem rgba(var(--success), 0.25);
        box-shadow: 0 0 0 0.2rem rgba((var(--success)), 0.25);
    }

    .was-validated .form-control:valid ~ .valid-feedback,
    .was-validated .form-control:valid ~ .valid-tooltip,
    .form-control.is-valid ~ .valid-feedback,
    .form-control.is-valid ~ .valid-tooltip {
        display: block;
    }

    .was-validated textarea.form-control:valid,
    textarea.form-control.is-valid {
        padding-right: calc(1.5em + 0.75rem);
        background-position: top calc(0.375em + 0.1875rem) right
            calc(0.375em + 0.1875rem);
    }

    .was-validated .custom-select:valid,
    .custom-select.is-valid {
        border-color: var(--success);
        padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%235a5c69' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
                no-repeat right 0.75rem center/8px 10px,
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231cc88a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
                var(--white) no-repeat center right 1.75rem /
                calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .was-validated .custom-select:valid:focus,
    .custom-select.is-valid:focus {
        border-color: var(--success);
        -webkit-box-shadow: 0 0 0 0.2rem rgba(var(--success), 0.25);
        box-shadow: 0 0 0 0.2rem rgba(var(--success), 0.25);
    }

    .was-validated .custom-select:valid ~ .valid-feedback,
    .was-validated .custom-select:valid ~ .valid-tooltip,
    .custom-select.is-valid ~ .valid-feedback,
    .custom-select.is-valid ~ .valid-tooltip {
        display: block;
    }

    .was-validated .form-control-file:valid ~ .valid-feedback,
    .was-validated .form-control-file:valid ~ .valid-tooltip,
    .form-control-file.is-valid ~ .valid-feedback,
    .form-control-file.is-valid ~ .valid-tooltip {
        display: block;
    }

    .was-validated .form-check-input:valid ~ .form-check-label,
    .form-check-input.is-valid ~ .form-check-label {
        color: var(--success);
    }

    .was-validated .form-check-input:valid ~ .valid-feedback,
    .was-validated .form-check-input:valid ~ .valid-tooltip,
    .form-check-input.is-valid ~ .valid-feedback,
    .form-check-input.is-valid ~ .valid-tooltip {
        display: block;
    }

    .was-validated .custom-control-input:valid ~ .custom-control-label,
    .custom-control-input.is-valid ~ .custom-control-label {
        color: var(--success);
    }

    .was-validated .custom-control-input:valid ~ .custom-control-label::before,
    .custom-control-input.is-valid ~ .custom-control-label::before {
        border-color: var(--success);
    }

    .was-validated .custom-control-input:valid ~ .valid-feedback,
    .was-validated .custom-control-input:valid ~ .valid-tooltip,
    .custom-control-input.is-valid ~ .valid-feedback,
    .custom-control-input.is-valid ~ .valid-tooltip {
        display: block;
    }

    .was-validated
        .custom-control-input:valid:checked
        ~ .custom-control-label::before,
    .custom-control-input.is-valid:checked ~ .custom-control-label::before {
        border-color: var(--success);
        background-color: var(--success);
    }

    .was-validated
        .custom-control-input:valid:focus
        ~ .custom-control-label::before,
    .custom-control-input.is-valid:focus ~ .custom-control-label::before {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(var(--success), 0.25);
        box-shadow: 0 0 0 0.2rem rgba(var(--success), 0.25);
    }

    .was-validated
        .custom-control-input:valid:focus:not(:checked)
        ~ .custom-control-label::before,
    .custom-control-input.is-valid:focus:not(:checked)
        ~ .custom-control-label::before {
        border-color: var(--success);
    }

    .was-validated .custom-file-input:valid ~ .custom-file-label,
    .custom-file-input.is-valid ~ .custom-file-label {
        border-color: var(--success);
    }

    .was-validated .custom-file-input:valid ~ .valid-feedback,
    .was-validated .custom-file-input:valid ~ .valid-tooltip,
    .custom-file-input.is-valid ~ .valid-feedback,
    .custom-file-input.is-valid ~ .valid-tooltip {
        display: block;
    }

    .was-validated .custom-file-input:valid:focus ~ .custom-file-label,
    .custom-file-input.is-valid:focus ~ .custom-file-label {
        border-color: var(--success);
        -webkit-box-shadow: 0 0 0 0.2rem rgba(var(--success), 0.25);
        box-shadow: 0 0 0 0.2rem rgba(var(--success), 0.25);
    }

    .invalid-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: var(--danger);
    }

    .invalid-tooltip {
        position: absolute;
        top: 100%;
        z-index: 5;
        display: none;
        max-width: 100%;
        padding: 0.25rem 0.5rem;
        margin-top: 0.1rem;
        font-size: 0.875rem;
        line-height: 1.5;
        color: var(--white);
        background-color: rgba(231, 74, 59, 0.9);
        border-radius: 1.35rem;
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid {
        border-color: var(--danger);
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23e74a3b' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23e74a3b' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
        background-repeat: no-repeat;
        background-position: center right calc(0.375em + 0.1875rem);
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .was-validated .form-control:invalid:focus,
    .form-control.is-invalid:focus {
        border-color: var(--danger);
        -webkit-box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.25);
    }

    .was-validated .form-control:invalid ~ .invalid-feedback,
    .was-validated .form-control:invalid ~ .invalid-tooltip,
    .form-control.is-invalid ~ .invalid-feedback,
    .form-control.is-invalid ~ .invalid-tooltip {
        display: block;
    }

    .was-validated textarea.form-control:invalid,
    textarea.form-control.is-invalid {
        padding-right: calc(1.5em + 0.75rem);
        background-position: top calc(0.375em + 0.1875rem) right
            calc(0.375em + 0.1875rem);
    }

    .was-validated .custom-select:invalid,
    .custom-select.is-invalid {
        border-color: var(--danger);
        padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%235a5c69' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
                no-repeat right 0.75rem center/8px 10px,
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23e74a3b' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23e74a3b' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
                var(--white) no-repeat center right 1.75rem /
                calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .was-validated .custom-select:invalid:focus,
    .custom-select.is-invalid:focus {
        border-color: var(--danger);
        -webkit-box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.25);
    }

    .was-validated .custom-select:invalid ~ .invalid-feedback,
    .was-validated .custom-select:invalid ~ .invalid-tooltip,
    .custom-select.is-invalid ~ .invalid-feedback,
    .custom-select.is-invalid ~ .invalid-tooltip {
        display: block;
    }

    .was-validated .form-control-file:invalid ~ .invalid-feedback,
    .was-validated .form-control-file:invalid ~ .invalid-tooltip,
    .form-control-file.is-invalid ~ .invalid-feedback,
    .form-control-file.is-invalid ~ .invalid-tooltip {
        display: block;
    }

    .was-validated .form-check-input:invalid ~ .form-check-label,
    .form-check-input.is-invalid ~ .form-check-label {
        color: var(--danger);
    }

    .was-validated .form-check-input:invalid ~ .invalid-feedback,
    .was-validated .form-check-input:invalid ~ .invalid-tooltip,
    .form-check-input.is-invalid ~ .invalid-feedback,
    .form-check-input.is-invalid ~ .invalid-tooltip {
        display: block;
    }

    .was-validated .custom-control-input:invalid ~ .custom-control-label,
    .custom-control-input.is-invalid ~ .custom-control-label {
        color: var(--danger);
    }

    .was-validated
        .custom-control-input:invalid
        ~ .custom-control-label::before,
    .custom-control-input.is-invalid ~ .custom-control-label::before {
        border-color: var(--danger);
    }

    .was-validated .custom-control-input:invalid ~ .invalid-feedback,
    .was-validated .custom-control-input:invalid ~ .invalid-tooltip,
    .custom-control-input.is-invalid ~ .invalid-feedback,
    .custom-control-input.is-invalid ~ .invalid-tooltip {
        display: block;
    }

    .was-validated
        .custom-control-input:invalid:checked
        ~ .custom-control-label::before,
    .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
        border-color: #ed7468;
        background-color: #ed7468;
    }

    .was-validated
        .custom-control-input:invalid:focus
        ~ .custom-control-label::before,
    .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.25);
    }

    .was-validated
        .custom-control-input:invalid:focus:not(:checked)
        ~ .custom-control-label::before,
    .custom-control-input.is-invalid:focus:not(:checked)
        ~ .custom-control-label::before {
        border-color: var(--danger);
    }

    .was-validated .custom-file-input:invalid ~ .custom-file-label,
    .custom-file-input.is-invalid ~ .custom-file-label {
        border-color: var(--danger);
    }

    .was-validated .custom-file-input:invalid ~ .invalid-feedback,
    .was-validated .custom-file-input:invalid ~ .invalid-tooltip,
    .custom-file-input.is-invalid ~ .invalid-feedback,
    .custom-file-input.is-invalid ~ .invalid-tooltip {
        display: block;
    }

    .was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
    .custom-file-input.is-invalid:focus ~ .custom-file-label {
        border-color: var(--danger);
        -webkit-box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.25);
    }

    .form-inline {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .form-inline .form-check {
        width: 100%;
    }

    @media (min-width: 576px) {
        .form-inline label {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-bottom: 0;
        }
        .form-inline .form-group {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-bottom: 0;
        }
        .form-inline .form-control {
            display: inline-block;
            width: auto;
            vertical-align: middle;
        }
        .form-inline .form-control-plaintext {
            display: inline-block;
        }
        .form-inline .input-group,
        .form-inline .custom-select {
            width: auto;
        }
        .form-inline .form-check {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            width: auto;
            padding-left: 0;
        }
        .form-inline .form-check-input {
            position: relative;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            margin-top: 0;
            margin-right: 0.25rem;
            margin-left: 0;
        }
        .form-inline .custom-control {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }
        .form-inline .custom-control-label {
            margin-bottom: 0;
        }
    }

    .btn {
        display: inline-block;
        font-weight: 400;
        color: var(--secondary);
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 1.35rem;
        -webkit-transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            -webkit-box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
            -webkit-box-shadow 0.15s ease-in-out;
    }

    @media (prefers-reduced-motion: reduce) {
        .btn {
            -webkit-transition: none;
            transition: none;
        }
    }

    .btn-success.footer-button-card {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    .btn-success.footer-button-card {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }

    .btn:hover {
        color: var(--white) !important;
        background-color: var(--warning);
        text-decoration: none;
    }

    a.btn:hover {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }

    .btn:focus,
    .btn.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    }

    .btn.disabled,
    .btn:disabled {
        opacity: 0.65;
    }

    a.btn.disabled,
    fieldset:disabled a.btn {
        pointer-events: none;
    }

    .btn-primary {
        color: var(--white);
        background-color: rgb(60 160 133) !important;
        border-color: rgb(60 160 133) !important;
    }

    .btn-primary:hover {
        color: white !important;
        background-color: var(--warning) !important;
        border-color: var(--warning) !important;
    }

    .btn-primary:focus,
    .btn-primary.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
    }

    .btn-primary.disabled,
    .btn-primary:disabled {
        color: var(--white);
        background-color: var(--primary);
        border-color: var(--primary);
    }

    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
        color: var(--white);
        background-color: #2653d4;
        border-color: #244ec9;
    }

    .btn-primary:not(:disabled):not(.disabled):active:focus,
    .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
    }

    .btn-secondary {
        color: var(--white);
        background-color: var(--secondary);
        border-color: var(--secondary);
        max-height: 36px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .btn-secondary:hover {
        color: var(--white);
        background-color: var(--warning) !important;
        border-color: var(--warning) !important;
    }

    .btn-secondary:focus,
    .btn-secondary.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
    }

    .btn-secondary.disabled,
    .btn-secondary:disabled {
        color: var(--white);
        background-color: var(--secondary);
        border-color: var(--secondary);
    }

    .btn-secondary:not(:disabled):not(.disabled):active,
    .btn-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-secondary.dropdown-toggle {
        color: var(--white);
        background-color: #6b6d7d;
        border-color: #656776;
    }

    .btn-secondary:not(:disabled):not(.disabled):active:focus,
    .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
    }

    .btn-success {
        color: var(--white);
        background-color: var(--success);
        border-color: var(--success);
        max-height: 36px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .btn-success:hover {
        color: var(--white) !important;
        background-color: var(--warning) !important;
        border-color: var(--warning) !important;
    }
    .btn-success:focus,
    .btn-success.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
    }

    .btn-success.disabled,
    .btn-success:not(:disabled):not(.disabled).active,
    .show > .btn-success.dropdown-toggle {
        color: var(--white);
        background-color: #169b6b;
        border-color: #149063;
    }

    .btn-success:not(:disabled):not(.disabled):active:focus,
    .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
    }

    .btn-filter {
        color: var(--white);
        background-color: var(--info);
        border-color: var(--info);
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
    }

    .btn-info {
        color: var(--white);
        background-color: var(--info);
        border-color: var(--info);
    }

    .btn-info:hover {
        color: var(--white);
        background-color: var(--warning) !important;
        border-color: var(--warning) !important;
    }

    .btn-info:focus,
    .btn-info.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(84, 196, 212, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(84, 196, 212, 0.5);
    }

    .btn-info.disabled,
    .btn-info:disabled {
        color: var(--white);
        background-color: var(--info);
        border-color: var(--info);
    }

    .btn-info:not(:disabled):not(.disabled):active,
    .btn-info:not(:disabled):not(.disabled).active,
    .show > .btn-info.dropdown-toggle {
        color: var(--white);
        background-color: #2a96a5;
        border-color: #278c9b;
    }

    .btn-info:not(:disabled):not(.disabled):active:focus,
    .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(84, 196, 212, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(84, 196, 212, 0.5);
    }

    .btn-warning {
        color: var(--white);
        background-color: var(--warning);
        border-color: var(--warning);
    }

    .btn-warning:hover {
        color: var(--white);
        background-color: #f4b619;
        border-color: #f4b30d;
    }

    .btn-warning:focus,
    .btn-warning.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(247, 203, 91, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(247, 203, 91, 0.5);
    }

    .btn-warning.disabled,
    .btn-warning:disabled {
        color: var(--white);
        background-color: var(--warning);
        border-color: var(--warning);
    }

    .btn-warning:not(:disabled):not(.disabled):active,
    .btn-warning:not(:disabled):not(.disabled).active,
    .show > .btn-warning.dropdown-toggle {
        color: var(--white);
        background-color: #f4b30d;
        border-color: #e9aa0b;
    }

    .btn-warning:not(:disabled):not(.disabled):active:focus,
    .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(247, 203, 91, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(247, 203, 91, 0.5);
    }

    .btn-danger {
        color: var(--white);
        background-color: var(--danger);
        border-color: var(--danger);
    }

    .btn-danger:hover {
        color: var(--white);
        background-color: var(--warning) !important;
        border-color: var(--warning) !important;
    }

    .btn-danger:focus,
    .btn-danger.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
    }

    .btn-danger.disabled,
    .btn-danger:disabled {
        color: var(--white);
        background-color: var(--danger);
        border-color: var(--danger);
    }

    .btn-danger:not(:disabled):not(.disabled):active,
    .btn-danger:not(:disabled):not(.disabled).active,
    .show > .btn-danger.dropdown-toggle {
        color: var(--white);
        background-color: #d52a1a;
        border-color: #ca2819;
    }

    .btn-danger:not(:disabled):not(.disabled):active:focus,
    .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
    }

    .btn-light {
        color: #3a3b45;
        background-color: var(--light);
        border-color: var(--light);
    }

    .btn-light:hover {
        color: #3a3b45;
        background-color: var(--warning) !important;
        border-color: var(--warning) !important;
    }

    .btn-light:focus,
    .btn-light.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 221, 225, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(220, 221, 225, 0.5);
    }

    .btn-light.disabled,
    .btn-light:disabled {
        color: #3a3b45;
        background-color: var(--light);
        border-color: var(--light);
    }

    .btn-light:not(:disabled):not(.disabled):active,
    .btn-light:not(:disabled):not(.disabled).active,
    .show > .btn-light.dropdown-toggle {
        color: #3a3b45;
        background-color: #d4daed;
        border-color: #cbd3e9;
    }

    .btn-light:not(:disabled):not(.disabled):active:focus,
    .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 221, 225, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(220, 221, 225, 0.5);
    }

    .btn-dark {
        color: var(--white);
        background-color: var(--gray-dark) !important;
        border-color: var(--gray-dark) !important;
    }

    .btn-dark:hover {
        color: var(--white);
        background-color: var(--warning) !important;
        border-color: var(--warning) !important;
    }

    .btn-dark:focus,
    .btn-dark.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(115, 116, 128, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(115, 116, 128, 0.5);
    }

    .btn-dark.disabled,
    .btn-dark:disabled {
        color: var(--white);
        background-color: var(--dark);
        border-color: var(--dark);
    }

    .btn-dark:not(:disabled):not(.disabled):active,
    .btn-dark:not(:disabled):not(.disabled).active,
    .show > .btn-dark.dropdown-toggle {
        color: var(--white);
        background-color: #42444e;
        border-color: #3d3e47;
    }

    .btn-dark:not(:disabled):not(.disabled):active:focus,
    .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(115, 116, 128, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(115, 116, 128, 0.5);
    }

    .btn-outline-primary {
        color: var(--primary);
        border-color: var(--primary);
    }

    .btn-outline-primary:hover {
        color: var(--white);
        background-color: var(--primary);
        border-color: var(--primary);
    }

    .btn-outline-primary:focus,
    .btn-outline-primary.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.5);
    }

    .btn-outline-primary.disabled,
    .btn-outline-primary:disabled {
        color: var(--primary);
        background-color: transparent;
    }

    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show > .btn-outline-primary.dropdown-toggle {
        color: var(--white);
        background-color: var(--primary);
        border-color: var(--primary);
    }

    .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
    .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.5);
    }

    .btn-outline-secondary {
        color: var(--secondary);
        border-color: var(--secondary);
    }

    .btn-outline-secondary:hover {
        color: var(--white);
        background-color: var(--secondary);
        border-color: var(--secondary);
    }

    .btn-outline-secondary:focus,
    .btn-outline-secondary.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
    }

    .btn-outline-secondary.disabled,
    .btn-outline-secondary:disabled {
        color: var(--secondary);
        background-color: transparent;
    }

    .btn-outline-secondary:not(:disabled):not(.disabled):active,
    .btn-outline-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-outline-secondary.dropdown-toggle {
        color: var(--white);
        background-color: var(--secondary);
        border-color: var(--secondary);
    }

    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
    .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
    }

    .btn-outline-success {
        color: var(--success);
        border-color: var(--success);
    }

    .btn-outline-success:hover {
        color: var(--white);
        background-color: var(--success);
        border-color: var(--success);
    }

    .btn-outline-success:focus,
    .btn-outline-success.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.5);
    }

    .btn-outline-success.disabled,
    .btn-outline-success:disabled {
        color: var(--success);
        background-color: transparent;
    }

    .btn-outline-success:not(:disabled):not(.disabled):active,
    .btn-outline-success:not(:disabled):not(.disabled).active,
    .show > .btn-outline-success.dropdown-toggle {
        color: var(--white);
        background-color: var(--success);
        border-color: var(--success);
    }

    .btn-outline-success:not(:disabled):not(.disabled):active:focus,
    .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.5);
    }

    .btn-outline-info {
        color: var(--info);
        border-color: var(--info);
    }

    .btn-outline-info:hover {
        color: var(--white);
        background-color: var(--info);
        border-color: var(--info);
    }

    .btn-outline-info:focus,
    .btn-outline-info.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(54, 185, 204, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(54, 185, 204, 0.5);
    }

    .btn-outline-info.disabled,
    .btn-outline-info:disabled {
        color: var(--info);
        background-color: transparent;
    }

    .btn-outline-info:not(:disabled):not(.disabled):active,
    .btn-outline-info:not(:disabled):not(.disabled).active,
    .show > .btn-outline-info.dropdown-toggle {
        color: var(--white);
        background-color: var(--info);
        border-color: var(--info);
    }

    .btn-outline-info:not(:disabled):not(.disabled):active:focus,
    .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(54, 185, 204, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(54, 185, 204, 0.5);
    }

    .btn-outline-warning {
        color: var(--warning);
        border-color: var(--warning);
    }

    .btn-outline-warning:hover {
        color: var(--white);
        background-color: var(--warning);
        border-color: var(--warning);
    }

    .btn-outline-warning:focus,
    .btn-outline-warning.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 194, 62, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(246, 194, 62, 0.5);
    }

    .btn-outline-warning.disabled,
    .btn-outline-warning:disabled {
        color: var(--warning);
        background-color: transparent;
    }

    .btn-outline-warning:not(:disabled):not(.disabled):active,
    .btn-outline-warning:not(:disabled):not(.disabled).active,
    .show > .btn-outline-warning.dropdown-toggle {
        color: var(--white);
        background-color: var(--warning);
        border-color: var(--warning);
    }

    .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
    .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 194, 62, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(246, 194, 62, 0.5);
    }

    .btn-outline-danger {
        color: var(--danger);
        border-color: var(--danger);
    }

    .btn-outline-danger:hover {
        color: var(--white);
        background-color: var(--danger);
        border-color: var(--danger);
    }

    .btn-outline-danger:focus,
    .btn-outline-danger.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.5);
    }

    .btn-outline-danger.disabled,
    .btn-outline-danger:disabled {
        color: var(--danger);
        background-color: transparent;
    }

    .btn-outline-danger:not(:disabled):not(.disabled):active,
    .btn-outline-danger:not(:disabled):not(.disabled).active,
    .show > .btn-outline-danger.dropdown-toggle {
        color: var(--white);
        background-color: var(--danger);
        border-color: var(--danger);
    }

    .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
    .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.5);
    }

    .btn-outline-light {
        color: var(--light);
        border-color: var(--light);
    }

    .btn-outline-light:hover {
        color: #3a3b45;
        background-color: var(--light);
        border-color: var(--light);
    }

    .btn-outline-light:focus,
    .btn-outline-light.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
    }

    .btn-outline-light.disabled,
    .btn-outline-light:disabled {
        color: var(--light);
        background-color: transparent;
    }

    .btn-outline-light:not(:disabled):not(.disabled):active,
    .btn-outline-light:not(:disabled):not(.disabled).active,
    .show > .btn-outline-light.dropdown-toggle {
        color: #3a3b45;
        background-color: var(--light);
        border-color: var(--light);
    }

    .btn-outline-light:not(:disabled):not(.disabled):active:focus,
    .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
    }

    .btn-outline-dark {
        color: var(--gray-dark);
        border-color: var(--gray-dark);
    }

    .btn-outline-dark:hover {
        color: var(--white);
        background-color: var(--success);
        border-color: var(--success);
    }

    .btn-outline-dark:focus,
    .btn-outline-dark.focus {
        -webkit-box-shadow: 0 0 0 0.2rem var(--gray-dark);
        box-shadow: 0 0 0 0.2rem var(--gray-dark);
    }

    .btn-outline-dark.disabled,
    .btn-outline-dark:disabled {
        color: var(--gray-dark);
        background-color: transparent;
    }

    .btn-outline-dark:not(:disabled):not(.disabled):active,
    .btn-outline-dark:not(:disabled):not(.disabled).active,
    .show > .btn-outline-dark.dropdown-toggle {
        color: var(--white);
        background-color: var(--success);
        border-color: var(--success);
    }

    .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
    .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem var(--success);
        box-shadow: 0 0 0 0.2rem var(--success);
    }

    .btn-link {
        font-weight: 400;
        color: var(--primary);
        text-decoration: none;
    }

    .btn-link:hover {
        color: var(--warning) !important;
        text-decoration: underline;
    }

    .btn-link:focus,
    .btn-link.focus {
        text-decoration: underline;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .btn-link:disabled,
    .btn-link.disabled {
        color: var(--secondary);
        pointer-events: none;
    }

    .btn-lg,
    .btn-group-lg > .btn {
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
        line-height: 1.5;
        border-radius: 0.3rem;
    }

    .btn-sm,
    .btn-group-sm > .btn {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
    }

    .btn-block {
        display: block;
        width: 100%;
    }

    .btn-block + .btn-block {
        margin-top: 0.5rem;
    }

    input[type="submit"].btn-block,
    input[type="reset"].btn-block,
    input[type="button"].btn-block {
        width: 100%;
    }

    .fade {
        -webkit-transition: opacity 0.15s linear;
        transition: opacity 0.15s linear;
    }

    @media (prefers-reduced-motion: reduce) {
        .fade {
            -webkit-transition: none;
            transition: none;
        }
    }

    .fade:not(.show) {
        opacity: 0;
    }

    .collapse:not(.show) {
        display: none;
    }

    .collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        -webkit-transition: height 0.15s ease;
        transition: height 0.15s ease;
    }

    @media (prefers-reduced-motion: reduce) {
        .collapsing {
            -webkit-transition: none;
            transition: none;
        }
    }

    .dropup,
    .dropright,
    .dropdown,
    .dropleft {
        position: relative;
    }

    .dropdown-toggle {
        white-space: nowrap;
    }

    .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }

    .dropdown-toggle:empty::after {
        margin-left: 0;
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 10rem;
        padding: 0.5rem 0;
        margin: 0.125rem 0 0;
        font-size: 0.85rem;
        color: var(--secondary);
        text-align: left;
        list-style: none;
        background-color: var(--white);
        background-clip: padding-box;
        border: 1px solid #e3e6f0;
        border-radius: 1.35rem;
    }

    .dropdown-menu-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-right {
        right: 0;
        left: auto;
    }

    @media (min-width: 576px) {
        .dropdown-menu-sm-left {
            right: auto;
            left: 0;
        }
        .dropdown-menu-sm-right {
            right: 0;
            left: auto;
        }
    }

    @media (min-width: 768px) {
        .dropdown-menu-md-left {
            right: auto;
            left: 0;
        }
        .dropdown-menu-md-right {
            right: 0;
            left: auto;
        }
    }

    @media (min-width: 992px) {
        .dropdown-menu-lg-left {
            right: auto;
            left: 0;
        }
        .dropdown-menu-lg-right {
            right: 0;
            left: auto;
        }
    }

    @media (min-width: 1200px) {
        .dropdown-menu-xl-left {
            right: auto;
            left: 0;
        }
        .dropdown-menu-xl-right {
            right: 0;
            left: auto;
        }
    }

    .dropup .dropdown-menu {
        top: auto;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: 0.125rem;
    }

    .dropup .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0;
        border-right: 0.3em solid transparent;
        border-bottom: 0.3em solid;
        border-left: 0.3em solid transparent;
    }

    .dropup .dropdown-toggle:empty::after {
        margin-left: 0;
    }

    .dropright .dropdown-menu {
        top: 0;
        right: auto;
        left: 100%;
        margin-top: 0;
        margin-left: 0.125rem;
    }

    .dropright .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid transparent;
        border-right: 0;
        border-bottom: 0.3em solid transparent;
        border-left: 0.3em solid;
    }

    .dropright .dropdown-toggle:empty::after {
        margin-left: 0;
    }

    .dropright .dropdown-toggle::after {
        vertical-align: 0;
    }

    .dropleft .dropdown-menu {
        top: 0;
        right: 100%;
        left: auto;
        margin-top: 0;
        margin-right: 0.125rem;
    }

    .dropleft .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
    }

    .dropleft .dropdown-toggle::after {
        display: none;
    }

    .dropleft .dropdown-toggle::before {
        display: inline-block;
        margin-right: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid transparent;
        border-right: 0.3em solid;
        border-bottom: 0.3em solid transparent;
    }

    .dropleft .dropdown-toggle:empty::after {
        margin-left: 0;
    }

    .dropleft .dropdown-toggle::before {
        vertical-align: 0;
    }

    .dropdown-menu[x-placement^="top"],
    .dropdown-menu[x-placement^="right"],
    .dropdown-menu[x-placement^="bottom"],
    .dropdown-menu[x-placement^="left"] {
        right: auto;
        bottom: auto;
    }

    .dropdown-divider {
        height: 0;
        margin: 0.5rem 0;
        overflow: hidden;
        border-top: 1px solid #eaecf4;
    }

    .dropdown-item {
        display: block;
        width: 100%;
        padding: 0.25rem 1.5rem;
        clear: both;
        font-weight: 400;
        color: #3a3b45;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border-radius: 1.35rem;
        border: 0;
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
        color: var(--warning) !important;
        text-decoration: none;
        background-color: var(--light);
    }

    .dropdown-item.active,
    .dropdown-item:active {
        color: var(--white);
        text-decoration: none;
        background-color: var(--primary);
    }

    .dropdown-item.disabled,
    .dropdown-item:disabled {
        color: var(--secondary);
        pointer-events: none;
        background-color: transparent;
    }

    .dropdown-menu.show {
        display: block;
    }

    .dropdown-header {
        display: block;
        padding: 0.5rem 1.5rem;
        margin-bottom: 0;
        font-size: 0.875rem;
        color: var(--secondary);
        white-space: nowrap;
    }

    .dropdown-item-text {
        display: block;
        padding: 0.25rem 1.5rem;
        color: #3a3b45;
    }

    .btn-group,
    .btn-group-vertical {
        position: relative;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        vertical-align: middle;
    }

    .btn-group > .btn,
    .btn-group-vertical > .btn {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }

    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
        z-index: 1;
    }

    .btn-group > .btn:focus,
    .btn-group > .btn:active,
    .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
        z-index: 1;
    }

    .btn-toolbar {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .btn-toolbar .input-group {
        width: auto;
    }

    .btn-group > .btn:not(:first-child),
    .btn-group > .btn-group:not(:first-child) {
        margin-left: -1px;
    }

    .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
    .btn-group > .btn-group:not(:last-child) > .btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .btn-group > .btn:not(:first-child),
    .btn-group > .btn-group:not(:first-child) > .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .dropdown-toggle-split {
        padding-right: 0.5625rem;
        padding-left: 0.5625rem;
    }

    .dropdown-toggle-split::after,
    .dropup .dropdown-toggle-split::after,
    .dropright .dropdown-toggle-split::after {
        margin-left: 0;
    }

    .dropleft .dropdown-toggle-split::before {
        margin-right: 0;
    }

    .btn-sm + .dropdown-toggle-split,
    .btn-group-sm > .btn + .dropdown-toggle-split {
        padding-right: 0.375rem;
        padding-left: 0.375rem;
    }

    .btn-lg + .dropdown-toggle-split,
    .btn-group-lg > .btn + .dropdown-toggle-split {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }

    .btn-group-vertical {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .btn-group-vertical > .btn,
    .btn-group-vertical > .btn-group {
        width: 100%;
    }

    .btn-group-vertical > .btn:not(:first-child),
    .btn-group-vertical > .btn-group:not(:first-child) {
        margin-top: -1px;
    }

    .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
    .btn-group-vertical > .btn-group:not(:last-child) > .btn {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .btn-group-vertical > .btn:not(:first-child),
    .btn-group-vertical > .btn-group:not(:first-child) > .btn {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .btn-group-toggle > .btn,
    .btn-group-toggle > .btn-group > .btn {
        margin-bottom: 0;
    }

    .btn-group-toggle > .btn input[type="radio"],
    .btn-group-toggle > .btn input[type="checkbox"],
    .btn-group-toggle > .btn-group > .btn input[type="radio"],
    .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
    }

    .input-group {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 100%;
    }

    .input-group > .form-control,
    .input-group > .form-control-plaintext,
    .input-group > .custom-select,
    .input-group > .custom-file {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
    }

    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
        margin-left: -1px;
    }

    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
        z-index: 3;
    }

    .input-group > .custom-file .custom-file-input:focus {
        z-index: 4;
    }

    .input-group > .form-control:not(:last-child),
    .input-group > .custom-select:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group > .form-control:not(:first-child),
    .input-group > .custom-select:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group > .custom-file {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group > .custom-file:not(:first-child) .custom-file-label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group-text.arrow {
        border-top-right-radius: 1.35rem !important;
        border-bottom-right-radius: 1.35rem !important;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
    }
    .input-group-prepend,
    .input-group-append {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .input-group-prepend .btn,
    .input-group-append .btn {
        position: relative;
        z-index: 2;
    }

    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
        z-index: 3;
    }

    .input-group-text > i {
        margin: auto !important;
    }

    .input-group-text {
        min-width: 46px !important;
        max-width: 46px !important;
    }

    .input-group-prepend {
        margin-right: -1px;
    }

    .input-group-append {
        margin-left: -1px;
    }

    .input-group-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0.375rem 0.75rem;
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        text-align: center;
        white-space: nowrap;
        background-color: #eaecf4;
        border: 1px solid #d1d3e2;
        border-radius: 1.35rem;
    }

    .input-group-text input[type="radio"],
    .input-group-text input[type="checkbox"] {
        margin-top: 0;
    }

    .input-group-lg > .form-control:not(textarea),
    .input-group-lg > .custom-select {
        height: calc(1.5em + 1rem + 2px);
    }

    .input-group-lg > .form-control,
    .input-group-lg > .custom-select,
    .input-group-lg > .input-group-prepend > .input-group-text,
    .input-group-lg > .input-group-append > .input-group-text,
    .input-group-lg > .input-group-prepend > .btn,
    .input-group-lg > .input-group-append > .btn {
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
        line-height: 1.5;
        border-radius: 0.3rem;
    }

    .input-group-sm > .form-control:not(textarea),
    .input-group-sm > .custom-select {
        height: calc(1.5em + 0.5rem + 2px);
    }

    .input-group-sm > .form-control,
    .input-group-sm > .custom-select,
    .input-group-sm > .input-group-prepend > .input-group-text,
    .input-group-sm > .input-group-append > .input-group-text,
    .input-group-sm > .input-group-prepend > .btn,
    .input-group-sm > .input-group-append > .btn {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
    }

    .input-group-lg > .custom-select,
    .input-group-sm > .custom-select {
        padding-right: 1.75rem;
    }

    .input-group > .input-group-prepend > .btn,
    .input-group > .input-group-prepend > .input-group-text,
    .input-group > .input-group-append:not(:last-child) > .btn,
    .input-group > .input-group-append:not(:last-child) > .input-group-text,
    .input-group
        > .input-group-append:last-child
        > .btn:not(:last-child):not(.dropdown-toggle),
    .input-group
        > .input-group-append:last-child
        > .input-group-text:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .custom-control {
        position: relative;
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5rem;
    }

    .custom-control-inline {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin-right: 1rem;
    }

    .custom-control-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        color: var(--white);
        border-color: var(--primary);
        background-color: var(--primary);
    }

    .custom-control-input:focus ~ .custom-control-label::before {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    }

    .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: #bac8f3;
    }

    .custom-control-input:not(:disabled):active
        ~ .custom-control-label::before {
        color: var(--white);
        background-color: #e5ebfa;
        border-color: #e5ebfa;
    }

    .custom-control-input:disabled ~ .custom-control-label {
        color: var(--secondary);
    }

    .custom-control-input:disabled ~ .custom-control-label::before {
        background-color: #eaecf4;
    }

    .custom-control-label {
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
    }

    .custom-control-label::before {
        position: absolute;
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        pointer-events: none;
        content: "";
        background-color: var(--white);
        border: #b7b9cc solid 1px;
    }

    .custom-control-label::after {
        position: absolute;
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        content: "";
        background: no-repeat 50% / 50% 50%;
    }

    .custom-checkbox .custom-control-label::before {
        border-radius: 0.35rem;
    }

    .custom-checkbox
        .custom-control-input:checked
        ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }

    .custom-checkbox
        .custom-control-input:indeterminate
        ~ .custom-control-label::before {
        border-color: var(--primary);
        background-color: var(--primary);
    }

    .custom-checkbox
        .custom-control-input:indeterminate
        ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
    }

    .custom-checkbox
        .custom-control-input:disabled:checked
        ~ .custom-control-label::before {
        background-color: rgba(78, 115, 223, 0.5);
    }

    .custom-checkbox
        .custom-control-input:disabled:indeterminate
        ~ .custom-control-label::before {
        background-color: rgba(78, 115, 223, 0.5);
    }

    .custom-radio .custom-control-label::before {
        border-radius: 50%;
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }

    .custom-radio
        .custom-control-input:disabled:checked
        ~ .custom-control-label::before {
        background-color: rgba(78, 115, 223, 0.5);
    }

    .custom-switch {
        padding-left: 2.25rem;
    }

    .custom-switch .custom-control-label::before {
        left: -2.25rem;
        width: 1.75rem;
        pointer-events: all;
        border-radius: 0.5rem;
    }

    .custom-switch .custom-control-label::after {
        top: calc(0.25rem + 2px);
        left: calc(-2.25rem + 2px);
        width: calc(1rem - 4px);
        height: calc(1rem - 4px);
        background-color: #b7b9cc;
        border-radius: 0.5rem;
        -webkit-transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out,
            -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out,
            -webkit-box-shadow 0.15s ease-in-out;
        transition: transform 0.15s ease-in-out,
            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        transition: transform 0.15s ease-in-out,
            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out,
            -webkit-box-shadow 0.15s ease-in-out;
    }

    @media (prefers-reduced-motion: reduce) {
        .custom-switch .custom-control-label::after {
            -webkit-transition: none;
            transition: none;
        }
    }

    .custom-switch
        .custom-control-input:checked
        ~ .custom-control-label::after {
        background-color: var(--white);
        -webkit-transform: translateX(0.75rem);
        transform: translateX(0.75rem);
    }

    .custom-switch
        .custom-control-input:disabled:checked
        ~ .custom-control-label::before {
        background-color: rgba(78, 115, 223, 0.5);
    }

    .custom-select {
        display: inline-block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 1.75rem 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        vertical-align: middle;
        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%235a5c69' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            no-repeat right 0.75rem center/8px 10px;
        background-color: var(--white);
        border: 1px solid #d1d3e2;
        border-radius: 1.35rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-bottom-left-radius: 0px !important;
        border-top-left-radius: 0px !important;
    }

    .custom-select:focus {
        border-color: #bac8f3;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    }

    .custom-select:focus::-ms-value {
        color: #6e707e;
        background-color: var(--white);
    }

    .custom-select[multiple],
    .custom-select[size]:not([size="1"]) {
        height: auto;
        padding-right: 0.75rem;
        background-image: none;
    }

    .custom-select:disabled {
        color: var(--secondary);
        background-color: #eaecf4;
    }

    .custom-select::-ms-expand {
        display: none;
    }

    .custom-select-sm {
        height: calc(1.5em + 0.5rem + 2px);
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        padding-left: 0.5rem;
        font-size: 0.875rem;
    }

    .custom-select-lg {
        height: calc(1.5em + 1rem + 2px);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1rem;
        font-size: 1.25rem;
    }

    .custom-file {
        position: relative;
        display: inline-block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        margin-bottom: 0;
    }

    .custom-file-input {
        position: relative;
        z-index: 2;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        margin: 0;
        opacity: 0;
    }

    .custom-file-input:focus ~ .custom-file-label {
        border-color: #bac8f3;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    }

    .custom-file-input:disabled ~ .custom-file-label {
        background-color: #eaecf4;
    }

    .custom-file-input:lang(en) ~ .custom-file-label::after {
        content: "Browse";
    }

    .custom-file-input ~ .custom-file-label[data-browse]::after {
        content: attr(data-browse);
    }

    .custom-file-label {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: var(--white);
        border: 1px solid #d1d3e2;
        border-radius: 1.35rem;
    }

    .custom-file-label::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        display: block;
        height: calc(1.5em + 0.75rem);
        padding: 0.375rem 0.75rem;
        line-height: 1.5;
        color: #6e707e;
        content: "Browse";
        background-color: #eaecf4;
        border-left: inherit;
        border-radius: 0 1.35rem 0.35rem 0;
    }

    .custom-range {
        width: 100%;
        height: calc(1rem + 0.4rem);
        padding: 0;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .custom-range:focus {
        outline: none;
    }

    .custom-range:focus::-webkit-slider-thumb {
        -webkit-box-shadow: 0 0 0 1px var(--white),
            0 0 0 0.2rem rgba(78, 115, 223, 0.25);
        box-shadow: 0 0 0 1px var(--white),
            0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    }

    .custom-range:focus::-moz-range-thumb {
        box-shadow: 0 0 0 1px var(--white),
            0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    }

    .custom-range:focus::-ms-thumb {
        box-shadow: 0 0 0 1px var(--white),
            0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    }

    .custom-range::-moz-focus-outer {
        border: 0;
    }

    .custom-range::-webkit-slider-thumb {
        width: 1rem;
        height: 1rem;
        margin-top: -0.25rem;
        background-color: var(--primary);
        border: 0;
        border-radius: 1rem;
        -webkit-transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
            -webkit-box-shadow 0.15s ease-in-out;
        -webkit-appearance: none;
        appearance: none;
    }

    @media (prefers-reduced-motion: reduce) {
        .custom-range::-webkit-slider-thumb {
            -webkit-transition: none;
            transition: none;
        }
    }

    .custom-range::-webkit-slider-thumb:active {
        background-color: #e5ebfa;
    }

    .custom-range::-webkit-slider-runnable-track {
        width: 100%;
        height: 0.5rem;
        color: transparent;
        cursor: pointer;
        background-color: #dddfeb;
        border-color: transparent;
        border-radius: 1rem;
    }

    .custom-range::-moz-range-thumb {
        width: 1rem;
        height: 1rem;
        background-color: var(--primary);
        border: 0;
        border-radius: 1rem;
        -webkit-transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
            -webkit-box-shadow 0.15s ease-in-out;
        -moz-appearance: none;
        appearance: none;
    }

    @media (prefers-reduced-motion: reduce) {
        .custom-range::-moz-range-thumb {
            -webkit-transition: none;
            transition: none;
        }
    }

    .custom-range::-moz-range-thumb:active {
        background-color: #e5ebfa;
    }

    .custom-range::-moz-range-track {
        width: 100%;
        height: 0.5rem;
        color: transparent;
        cursor: pointer;
        background-color: #dddfeb;
        border-color: transparent;
        border-radius: 1rem;
    }

    .custom-range::-ms-thumb {
        width: 1rem;
        height: 1rem;
        margin-top: 0;
        margin-right: 0.2rem;
        margin-left: 0.2rem;
        background-color: var(--primary);
        border: 0;
        border-radius: 1rem;
        -webkit-transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
            -webkit-box-shadow 0.15s ease-in-out;
        appearance: none;
    }

    @media (prefers-reduced-motion: reduce) {
        .custom-range::-ms-thumb {
            -webkit-transition: none;
            transition: none;
        }
    }

    .custom-range::-ms-thumb:active {
        background-color: #e5ebfa;
    }

    .custom-range::-ms-track {
        width: 100%;
        height: 0.5rem;
        color: transparent;
        cursor: pointer;
        background-color: transparent;
        border-color: transparent;
        border-width: 0.5rem;
    }

    .custom-range::-ms-fill-lower {
        background-color: #dddfeb;
        border-radius: 1rem;
    }

    .custom-range::-ms-fill-upper {
        margin-right: 15px;
        background-color: #dddfeb;
        border-radius: 1rem;
    }

    .custom-range:disabled::-webkit-slider-thumb {
        background-color: #b7b9cc;
    }

    .custom-range:disabled::-webkit-slider-runnable-track {
        cursor: default;
    }

    .custom-range:disabled::-moz-range-thumb {
        background-color: #b7b9cc;
    }

    .custom-range:disabled::-moz-range-track {
        cursor: default;
    }

    .custom-range:disabled::-ms-thumb {
        background-color: #b7b9cc;
    }

    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        -webkit-transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
            -webkit-box-shadow 0.15s ease-in-out;
    }

    @media (prefers-reduced-motion: reduce) {
        .custom-control-label::before,
        .custom-file-label,
        .custom-select {
            -webkit-transition: none;
            transition: none;
        }
    }

    .fa-person-digging {
        background-color: white;
        -webkit-mask: url(../images/fontawesome-v6/person-digging-solid.svg)
            no-repeat center;
        mask: url(../images/fontawesome-v6/person-digging-solid.svg) no-repeat
            center;
    }

    .nav-tabs-icon .nav-link.active > .zuko,
    .nav-tabs-icon .nav-link.active > .projekt,
    .nav-tabs-icon .nav-link.active > .fa-stack.fa-1x > .fa-person-digging {
        background-color: var(--gray-dark) !important;
    }

    .nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .nav-link {
        display: block;
        padding: 0.5rem 1rem;
    }

    .nav-item > .nav-link.nav-btn {
        text-decoration: none !important;
    }
    .tab-text {
        text-decoration: none !important;
    }

    .nav-link .tab-text {
        min-height: 45px;
        height: 45px;
        color: white !important;
    }

    .nav-link.active .tab-text {
        color: var(--gray-dark) !important;
    }

    .nav-link:hover,
    .nav-link:focus {
        text-decoration: none;
    }

    .nav-tabs-icon .nav-link.disabled {
        color: var(--secondary);
        pointer-events: none;
        cursor: default;
    }

    .nav-tabs-icon {
        border-bottom: 1px solid #dddfeb;
    }

    .small-height-item .nav-link {
        max-height: 75px;
    }

    .nav-tabs-icon .nav-link {
        background-color: var(--gray-dark) !important;
        border-top-left-radius: 0.35rem;
        border-top-right-radius: 0.35rem;
    }

    .nav-tabs-icon .nav-link .fa-stack {
        color: white !important;
        border-color: white !important;
    }

    .nav-tabs-icon .nav-link.active {
        background-color: white !important;
        color: var(--gray-dark) !important;
        border-color: var(--gray-dark) !important;
        border-bottom: none !important;
    }

    .nav-link.active .tab-icon {
        color: var(--gray-dark) !important;
    }

    .nav-tabs-icon .nav-link.active > .fa-stack,
    .nav-tabs-icon .nav-link.active > .fa-stack {
        color: var(--gray-dark) !important;
    }

    .small-height-item {
        min-height: 80px !important;
        height: 80px !important;
    }

    .small-height-link {
        max-height: 75px !important;
    }

    .nav-tabs-icon .nav-item {
        width: 181px;
        min-height: 92px;
        height: 92px;
        text-align: center;
        word-wrap: break-word;
        margin: 1px;
        margin-bottom: 2px;
        border-bottom: 0px !important;
    }

    .nav-tabs-icon > .nav-item > .nav-link.active {
        background-color: rgb(255, 255, 255) !important;
        border-right: 1px solid rgb(221, 223, 235) !important;
        border-left: 1px solid rgb(221, 223, 235) !important;
        border-top: 1px solid rgb(221, 223, 235) !important;
        border-bottom: 1px solid rgb(255, 255, 255) !important;
        border-top-left-radius: 0.35rem;
        border-top-right-radius: 0.35rem;
    }

    .nav-tabs-icon .nav-link:hover,
    .nav-tabs-icon .nav-link:focus {
        border-color: #eaecf4 #eaecf4 #dddfeb;
    }

    .nav-tabs-icon .nav-link.disabled {
        color: var(--secondary);
        background-color: transparent;
        border-color: transparent;
    }

    .nav-tabs-icon .nav-link.active > .tab-icon {
        color: white !important;
    }
    .nav-tabs .dropdown-menu {
        margin-top: -1px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .nav-pills .nav-link {
        border-radius: 0.35rem;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
        color: var(--white);
        background-color: var(--primary);
    }

    .nav-fill .nav-item {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        text-align: center;
    }

    .nav-justified .nav-item {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        text-align: center;
    }

    .tab-content > .tab-pane {
        display: none;
    }

    .tab-content > .active {
        display: block;
    }

    .navbar {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 0.5rem 1rem;
    }

    .navbar > .container,
    .navbar > .container-fluid {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .navbar-brand {
        display: inline-block;
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
        margin-right: 1rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
    }

    .navbar-brand:hover,
    .navbar-brand:focus {
        text-decoration: none;
    }

    .navbar-nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

    .navbar-nav .dropdown-menu {
        position: static;
        float: none;
    }

    .navbar-text {
        display: inline-block;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .navbar-collapse {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .navbar-toggler {
        padding: 0.25rem 0.75rem;
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 0.35rem;
    }

    .navbar-toggler:hover,
    .navbar-toggler:focus {
        text-decoration: none;
    }

    .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        content: "";
        background: no-repeat center center;
        background-size: 100% 100%;
    }

    @media (max-width: 575.98px) {
        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid {
            padding-right: 0;
            padding-left: 0;
        }
    }

    @media (min-width: 576px) {
        .navbar-expand-sm {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
        }
        .navbar-expand-sm .navbar-nav {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }
        .navbar-expand-sm .navbar-nav .dropdown-menu {
            position: absolute;
        }
        .navbar-expand-sm .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid {
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        }
        .navbar-expand-sm .navbar-collapse {
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
            -ms-flex-preferred-size: auto;
            flex-basis: auto;
        }
        .navbar-expand-sm .navbar-toggler {
            display: none;
        }
    }

    @media (max-width: 767.98px) {
        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid {
            padding-right: 0;
            padding-left: 0;
        }
    }

    @media (min-width: 768px) {
        .navbar-expand-md {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
        }
        .navbar-expand-md .navbar-nav {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }
        .navbar-expand-md .navbar-nav .dropdown-menu {
            position: absolute;
        }
        .navbar-expand-md .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid {
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        }
        .navbar-expand-md .navbar-collapse {
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
            -ms-flex-preferred-size: auto;
            flex-basis: auto;
        }
        .navbar-expand-md .navbar-toggler {
            display: none;
        }
    }

    @media (max-width: 991.98px) {
        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid {
            padding-right: 0;
            padding-left: 0;
        }
    }

    @media (min-width: 992px) {
        .navbar-expand-lg {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
        }
        .navbar-expand-lg .navbar-nav {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }
        .navbar-expand-lg .navbar-nav .dropdown-menu {
            position: absolute;
        }
        .navbar-expand-lg .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid {
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        }
        .navbar-expand-lg .navbar-collapse {
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
            -ms-flex-preferred-size: auto;
            flex-basis: auto;
        }
        .navbar-expand-lg .navbar-toggler {
            display: none;
        }
    }

    @media (max-width: 1199.98px) {
        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid {
            padding-right: 0;
            padding-left: 0;
        }
    }

    @media (min-width: 1200px) {
        .navbar-expand-xl {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
        }
        .navbar-expand-xl .navbar-nav {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }
        .navbar-expand-xl .navbar-nav .dropdown-menu {
            position: absolute;
        }
        .navbar-expand-xl .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid {
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        }
        .navbar-expand-xl .navbar-collapse {
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
            -ms-flex-preferred-size: auto;
            flex-basis: auto;
        }
        .navbar-expand-xl .navbar-toggler {
            display: none;
        }
    }

    .navbar-expand {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar-expand > .container,
    .navbar-expand > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }

    .navbar-expand .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .navbar-expand .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand > .container,
    .navbar-expand > .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .navbar-expand .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .navbar-expand .navbar-toggler {
        display: none;
    }

    .navbar-light .navbar-brand {
        color: rgba(0, 0, 0, 0.9);
    }

    .navbar-light .navbar-brand:hover,
    .navbar-light .navbar-brand:focus {
        color: rgba(0, 0, 0, 0.9);
    }

    .navbar-light .navbar-nav .nav-link {
        color: rgba(0, 0, 0, 0.5);
    }

    .navbar-light .navbar-nav .nav-link:hover,
    .navbar-light .navbar-nav .nav-link:focus {
        color: rgba(0, 0, 0, 0.7);
    }

    .navbar-light .navbar-nav .nav-link.disabled {
        color: rgba(0, 0, 0, 0.3);
    }

    .navbar-light .navbar-nav .show > .nav-link,
    .navbar-light .navbar-nav .active > .nav-link,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .nav-link.active {
        color: rgba(0, 0, 0, 0.9);
    }

    .navbar-light .navbar-toggler {
        color: rgba(0, 0, 0, 0.5);
        border-color: rgba(0, 0, 0, 0.1);
    }

    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }

    .navbar-light .navbar-text {
        color: rgba(0, 0, 0, 0.5);
    }

    .navbar-light .navbar-text a {
        color: rgba(0, 0, 0, 0.9);
    }

    .navbar-light .navbar-text a:hover,
    .navbar-light .navbar-text a:focus {
        color: rgba(0, 0, 0, 0.9);
    }

    .navbar-dark .navbar-brand {
        color: var(--white);
    }

    .navbar-dark .navbar-brand:hover,
    .navbar-dark .navbar-brand:focus {
        color: var(--white);
    }

    .navbar-dark .navbar-nav .nav-link {
        color: rgba(255, 255, 255, 0.5);
    }

    .navbar-dark .navbar-nav .nav-link:hover,
    .navbar-dark .navbar-nav .nav-link:focus {
        color: rgba(255, 255, 255, 0.75);
    }

    .navbar-dark .navbar-nav .nav-link.disabled {
        color: rgba(255, 255, 255, 0.25);
    }

    .navbar-dark .navbar-nav .show > .nav-link,
    .navbar-dark .navbar-nav .active > .nav-link,
    .navbar-dark .navbar-nav .nav-link.show,
    .navbar-dark .navbar-nav .nav-link.active {
        color: var(--white);
    }

    .navbar-dark .navbar-toggler {
        color: rgba(255, 255, 255, 0.5);
        border-color: rgba(255, 255, 255, 0.1);
    }

    .navbar-dark .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }

    .navbar-dark .navbar-text {
        color: rgba(255, 255, 255, 0.5);
    }

    .navbar-dark .navbar-text a {
        color: var(--white);
    }

    .navbar-dark .navbar-text a:hover,
    .navbar-dark .navbar-text a:focus {
        color: var(--white);
    }

    .card {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: var(--white);
        background-clip: border-box;
        border: 1px solid #e3e6f0;
        border-radius: 1.35rem;
    }
    .card.shadow.h-100 {
        border-top-right-radius: 0px !important;
        border-top-left-radius: 0px !important;
    }

    .card > hr {
        margin-right: 0;
        margin-left: 0;
    }

    .card > .list-group:first-child .list-group-item:first-child {
        border-top-left-radius: 0.35rem;
        border-top-right-radius: 0.35rem;
    }

    .card > .list-group:last-child .list-group-item:last-child {
        border-bottom-right-radius: 0.35rem;
        border-bottom-left-radius: 0.35rem;
    }

    .card-body {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1.25rem;
    }

    .card-title {
        margin-bottom: 0.75rem;
    }

    .card-subtitle {
        margin-top: -0.375rem;
        margin-bottom: 0;
    }

    .card-text:last-child {
        margin-bottom: 0;
    }

    .card-link:hover {
        text-decoration: none;
    }

    .card-link + .card-link {
        margin-left: 1.25rem;
    }

    .card-header {
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        background-color: var(--light);
        border-bottom: 1px solid #e3e6f0;
    }

    .card-header:first-child {
        border-radius: calc(1.35rem - 1px) calc(1.35rem - 1px) 0 0;
    }

    .card-header + .list-group .list-group-item:first-child {
        border-top: 0;
    }

    .card-footer {
        padding: 0.75rem 1.25rem;
        background-color: var(--light);
        border-top: 1px solid #e3e6f0;
    }

    .card-footer:last-child {
        border-radius: 0 0 calc(1.35rem - 1px) calc(1.35rem - 1px);
    }

    .card-header-tabs {
        margin-right: -0.625rem;
        margin-bottom: -0.75rem;
        margin-left: -0.625rem;
        border-bottom: 0;
    }

    .card-header-pills {
        margin-right: -0.625rem;
        margin-left: -0.625rem;
    }

    .card-img-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 1.25rem;
    }

    .card-img {
        width: 100%;
        border-radius: calc(1.35rem - 1px);
    }

    .card-img-top {
        width: 100%;
        border-top-left-radius: calc(1.35rem - 1px);
        border-top-right-radius: calc(1.35rem - 1px);
    }

    .card-img-bottom {
        width: 100%;
        border-bottom-right-radius: calc(1.35rem - 1px);
        border-bottom-left-radius: calc(1.35rem - 1px);
    }

    .card-deck {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .card-deck .card {
        margin-bottom: 0.75rem;
    }

    @media (min-width: 576px) {
        .card-deck {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
            margin-right: -0.75rem;
            margin-left: -0.75rem;
        }
        .card-deck .card {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex: 1;
            -ms-flex: 1 0 0%;
            flex: 1 0 0%;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            margin-right: 0.75rem;
            margin-bottom: 0;
            margin-left: 0.75rem;
        }
    }

    .card-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .card-group > .card {
        margin-bottom: 0.75rem;
    }

    @media (min-width: 576px) {
        .card-group {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
        }
        .card-group > .card {
            -webkit-box-flex: 1;
            -ms-flex: 1 0 0%;
            flex: 1 0 0%;
            margin-bottom: 0;
        }
        .card-group > .card + .card {
            margin-left: 0;
            border-left: 0;
        }
        .card-group > .card:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0;
        }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0;
        }
        .card-group > .card:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0;
        }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0;
        }
    }

    .card-columns .card {
        margin-bottom: 0.75rem;
    }

    @media (min-width: 576px) {
        .card-columns {
            -webkit-column-count: 3;
            column-count: 3;
            -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
            orphans: 1;
            widows: 1;
        }
        .card-columns .card {
            display: inline-block;
            width: 100%;
        }
    }

    .accordion > .card {
        overflow: hidden;
    }

    .accordion > .card:not(:first-of-type) .card-header:first-child {
        border-radius: 0;
    }

    .accordion > .card:not(:first-of-type):not(:last-of-type) {
        border-bottom: 0;
        border-radius: 0;
    }

    .accordion > .card:first-of-type {
        border-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .accordion > .card:last-of-type {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .accordion > .card .card-header {
        margin-bottom: -1px;
    }

    .breadcrumb {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 0.75rem 1rem;
        margin-bottom: 1rem;
        list-style: none;
        background-color: #eaecf4;
        border-radius: 0.35rem;
    }

    .breadcrumb-item + .breadcrumb-item {
        padding-left: 0.5rem;
    }

    .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        color: var(--secondary);
        content: "/";
    }

    .breadcrumb-item + .breadcrumb-item:hover::before {
        text-decoration: underline;
    }

    .breadcrumb-item + .breadcrumb-item:hover::before {
        text-decoration: none;
    }

    .breadcrumb-item.active {
        color: var(--secondary);
    }

    .pagination {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-left: 0;
        list-style: none;
        border-radius: 1.35rem;
    }

    .page-link {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        color: var(--primary);
        background-color: var(--white);
        border: 1px solid #dddfeb;
    }

    .page-link:hover {
        z-index: 2;
        color: #224abe;
        text-decoration: none;
        background-color: #eaecf4;
        border-color: #dddfeb;
    }

    .page-link:focus {
        z-index: 2;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    }

    .page-item:first-child .page-link {
        margin-left: 0;
        border-top-left-radius: 1.35rem;
        border-bottom-left-radius: 1.35rem;
    }

    .page-item:last-child .page-link {
        border-top-right-radius: 1.35rem;
        border-bottom-right-radius: 1.35rem;
    }

    .page-item.active .page-link {
        z-index: 1;
        color: var(--white) !important;
        background-color: var(--gray-dark) !important;
        border-color: var(--gray-dark) !important;
        text-decoration: none;
    }

    .page-item.disabled .page-link {
        color: var(--secondary);
        pointer-events: none;
        cursor: auto;
        background-color: var(--white);
        border-color: #dddfeb;
    }

    .pagination-lg .page-link {
        padding: 0.75rem 1.5rem;
        font-size: 1.25rem;
        line-height: 1.5;
    }

    .pagination-lg .page-item:first-child .page-link {
        border-top-left-radius: 1.35rem;
        border-bottom-left-radius: 1.35rem;
    }

    .pagination-lg .page-item:last-child .page-link {
        border-top-right-radius: 1.35rem;
        border-bottom-right-radius: 1.35rem;
    }

    .pagination-sm .page-link {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
    }

    .pagination-sm .page-item:first-child .page-link {
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
    }

    .pagination-sm .page-item:last-child .page-link {
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
    }

    .badge {
        display: inline-block;
        padding: 0.25em 0.4em;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 1.35rem;
        -webkit-transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            -webkit-box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
            -webkit-box-shadow 0.15s ease-in-out;
    }

    @media (prefers-reduced-motion: reduce) {
        .badge {
            -webkit-transition: none;
            transition: none;
        }
    }

    a.badge:hover,
    a.badge:focus {
        text-decoration: none;
    }

    .badge:empty {
        display: none;
    }

    .btn .badge {
        position: relative;
        top: -1px;
    }

    .badge-pill {
        padding-right: 0.6em;
        padding-left: 0.6em;
        border-radius: 10rem;
    }

    .badge-primary {
        color: var(--white);
        background-color: var(--primary);
    }

    a.badge-primary:hover,
    a.badge-primary:focus {
        color: var(--white);
        background-color: #2653d4;
    }

    a.badge-primary:focus,
    a.badge-primary.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.5);
    }

    .badge-secondary {
        color: var(--white);
        background-color: var(--secondary);
    }

    a.badge-secondary:hover,
    a.badge-secondary:focus {
        color: var(--white);
        background-color: #6b6d7d;
    }

    a.badge-secondary:focus,
    a.badge-secondary.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
    }

    .badge-success {
        color: var(--white);
        background-color: var(--success);
    }

    a.badge-success:hover,
    a.badge-success:focus {
        color: var(--white);
        background-color: #169b6b;
    }

    a.badge-success:focus,
    a.badge-success.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.5);
    }

    .badge-info {
        color: var(--white);
        background-color: var(--info);
    }

    a.badge-info:hover,
    a.badge-info:focus {
        color: var(--white);
        background-color: #2a96a5;
    }

    a.badge-info:focus,
    a.badge-info.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(54, 185, 204, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(54, 185, 204, 0.5);
    }

    .badge-warning {
        color: var(--white);
        background-color: var(--warning);
    }

    a.badge-warning:hover,
    a.badge-warning:focus {
        color: var(--white);
        background-color: #f4b30d;
    }

    a.badge-warning:focus,
    a.badge-warning.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 194, 62, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(246, 194, 62, 0.5);
    }

    .badge-danger {
        color: var(--white);
        background-color: var(--danger);
    }

    a.badge-danger:hover,
    a.badge-danger:focus {
        color: var(--white);
        background-color: #d52a1a;
    }

    a.badge-danger:focus,
    a.badge-danger.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.5);
    }

    .badge-light {
        color: #3a3b45;
        background-color: var(--light);
    }

    a.badge-light:hover,
    a.badge-light:focus {
        color: #3a3b45;
        background-color: #d4daed;
    }

    a.badge-light:focus,
    a.badge-light.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
    }

    .badge-dark {
        color: var(--white);
        background-color: var(--gray-dark);
    }

    a.badge-dark:hover,
    a.badge-dark:focus {
        color: var(--white);
        background-color: #42444e;
    }

    a.badge-dark:focus,
    a.badge-dark.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(90, 92, 105, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(90, 92, 105, 0.5);
    }

    .jumbotron {
        padding: 2rem 1rem;
        margin-bottom: 2rem;
        background-color: #eaecf4;
        border-radius: 0.3rem;
    }

    @media (min-width: 576px) {
        .jumbotron {
            padding: 4rem 2rem;
        }
    }

    .jumbotron-fluid {
        padding-right: 0;
        padding-left: 0;
        border-radius: 0;
    }

    .alert {
        position: relative;
        padding: 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 1.35rem;
    }

    .alert-heading {
        color: inherit;
    }

    .alert-link {
        font-weight: 700;
    }

    .alert-dismissible {
        padding-right: 4rem;
    }

    .alert-dismissible .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.75rem 1.25rem;
        color: inherit;
    }

    .alert-primary {
        color: #293c74;
        background-color: #dce3f9;
        border-color: #cdd8f6;
    }

    .alert-primary hr {
        border-top-color: #b7c7f2;
    }

    .alert-primary .alert-link {
        color: #1c294e;
    }

    .alert-secondary {
        color: #45464e;
        background-color: #e7e7ea;
        border-color: #dddde2;
    }

    .alert-secondary hr {
        border-top-color: #cfcfd6;
    }

    .alert-secondary .alert-link {
        color: #2d2e33;
    }

    .alert-success {
        color: #0f6848;
        background-color: #d2f4e8;
        border-color: #bff0de;
    }

    .alert-success hr {
        border-top-color: #aaebd3;
    }

    .alert-success .alert-link {
        color: #093b29;
    }

    .alert-info {
        color: #1c606a;
        background-color: #d7f1f5;
        border-color: #c7ebf1;
    }

    .alert-info hr {
        border-top-color: #b3e4ec;
    }

    .alert-info .alert-link {
        color: #113b42;
    }

    .alert-warning {
        color: #806520;
        background-color: #fdf3d8;
        border-color: #fceec9;
    }

    .alert-warning hr {
        border-top-color: #fbe6b1;
    }

    .alert-warning .alert-link {
        color: #574516;
    }

    .alert-danger {
        color: #78261f;
        background-color: #fadbd8;
        border-color: #f8ccc8;
    }

    .alert-danger hr {
        border-top-color: #f5b7b1;
    }

    .alert-danger .alert-link {
        color: #4f1915;
    }

    .alert-light {
        color: #818183;
        background-color: #fefefe;
        border-color: #fdfdfe;
    }

    .alert-light hr {
        border-top-color: #ececf6;
    }

    .alert-light .alert-link {
        color: #686869;
    }

    .alert-dark {
        color: #2f3037;
        background-color: #dedee1;
        border-color: #d1d1d5;
    }

    .alert-dark hr {
        border-top-color: #c4c4c9;
    }

    .alert-dark .alert-link {
        color: #18181c;
    }

    @-webkit-keyframes progress-bar-stripes {
        from {
            background-position: 1rem 0;
        }
        to {
            background-position: 0 0;
        }
    }

    @keyframes progress-bar-stripes {
        from {
            background-position: 1rem 0;
        }
        to {
            background-position: 0 0;
        }
    }

    .progress {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 1rem;
        overflow: hidden;
        font-size: 0.75rem;
        background-color: #eaecf4;
        border-radius: 1.35rem;
    }

    .progress-bar {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: var(--white);
        text-align: center;
        white-space: nowrap;
        background-color: var(--warning);
        -webkit-transition: width 0.6s ease;
        transition: width 0.6s ease;
    }

    @media (prefers-reduced-motion: reduce) {
        .progress-bar {
            -webkit-transition: none;
            transition: none;
        }
    }

    .progress-bar-striped {
        background-image: linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.15) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.15) 50%,
            rgba(255, 255, 255, 0.15) 75%,
            transparent 75%,
            transparent
        );
        background-size: 1rem 1rem;
    }

    .progress-bar-animated {
        -webkit-animation: progress-bar-stripes 1s linear infinite;
        animation: progress-bar-stripes 1s linear infinite;
    }

    @media (prefers-reduced-motion: reduce) {
        .progress-bar-animated {
            -webkit-animation: none;
            animation: none;
        }
    }

    .media {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .media-body {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .list-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
    }

    .list-group-item-action {
        width: 100%;
        color: #6e707e;
        text-align: inherit;
    }

    .list-group-item-action:hover,
    .list-group-item-action:focus {
        z-index: 1;
        color: #6e707e;
        text-decoration: none;
        background-color: var(--light);
    }

    .list-group-item-action:active {
        color: var(--secondary);
        background-color: #eaecf4;
    }

    .list-group-item {
        position: relative;
        display: block;
        padding: 0.75rem 1.25rem;
        margin-bottom: -1px;
        background-color: var(--white);
        border: 1px solid rgba(0, 0, 0, 0.125);
    }

    .list-group-item:first-child {
        border-top-left-radius: 0.35rem;
        border-top-right-radius: 0.35rem;
    }

    .list-group-item:last-child {
        margin-bottom: 0;
        border-bottom-right-radius: 0.35rem;
        border-bottom-left-radius: 0.35rem;
    }

    .list-group-item.disabled,
    .list-group-item:disabled {
        color: var(--secondary);
        pointer-events: none;
        background-color: var(--white);
    }

    .list-group-item.active {
        z-index: 2;
        color: var(--white);
        background-color: var(--primary);
        border-color: var(--primary);
    }

    .list-group-horizontal {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .list-group-horizontal .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }

    .list-group-horizontal .list-group-item:first-child {
        border-top-left-radius: 0.35rem;
        border-bottom-left-radius: 0.35rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.35rem;
        border-bottom-right-radius: 0.35rem;
        border-bottom-left-radius: 0;
    }

    @media (min-width: 576px) {
        .list-group-horizontal-sm {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }
        .list-group-horizontal-sm .list-group-item {
            margin-right: -1px;
            margin-bottom: 0;
        }
        .list-group-horizontal-sm .list-group-item:first-child {
            border-top-left-radius: 0.35rem;
            border-bottom-left-radius: 0.35rem;
            border-top-right-radius: 0;
        }
        .list-group-horizontal-sm .list-group-item:last-child {
            margin-right: 0;
            border-top-right-radius: 0.35rem;
            border-bottom-right-radius: 0.35rem;
            border-bottom-left-radius: 0;
        }
    }

    @media (min-width: 768px) {
        .list-group-horizontal-md {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }
        .list-group-horizontal-md .list-group-item {
            margin-right: -1px;
            margin-bottom: 0;
        }
        .list-group-horizontal-md .list-group-item:first-child {
            border-top-left-radius: 0.35rem;
            border-bottom-left-radius: 0.35rem;
            border-top-right-radius: 0;
        }
        .list-group-horizontal-md .list-group-item:last-child {
            margin-right: 0;
            border-top-right-radius: 0.35rem;
            border-bottom-right-radius: 0.35rem;
            border-bottom-left-radius: 0;
        }
    }

    @media (min-width: 992px) {
        .list-group-horizontal-lg {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }
        .list-group-horizontal-lg .list-group-item {
            margin-right: -1px;
            margin-bottom: 0;
        }
        .list-group-horizontal-lg .list-group-item:first-child {
            border-top-left-radius: 0.35rem;
            border-bottom-left-radius: 0.35rem;
            border-top-right-radius: 0;
        }
        .list-group-horizontal-lg .list-group-item:last-child {
            margin-right: 0;
            border-top-right-radius: 0.35rem;
            border-bottom-right-radius: 0.35rem;
            border-bottom-left-radius: 0;
        }
    }

    @media (min-width: 1200px) {
        .list-group-horizontal-xl {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }
        .list-group-horizontal-xl .list-group-item {
            margin-right: -1px;
            margin-bottom: 0;
        }
        .list-group-horizontal-xl .list-group-item:first-child {
            border-top-left-radius: 0.35rem;
            border-bottom-left-radius: 0.35rem;
            border-top-right-radius: 0;
        }
        .list-group-horizontal-xl .list-group-item:last-child {
            margin-right: 0;
            border-top-right-radius: 0.35rem;
            border-bottom-right-radius: 0.35rem;
            border-bottom-left-radius: 0;
        }
    }

    .list-group-flush .list-group-item {
        border-right: 0;
        border-left: 0;
        border-radius: 0;
    }

    .list-group-flush .list-group-item:last-child {
        margin-bottom: -1px;
    }

    .list-group-flush:first-child .list-group-item:first-child {
        border-top: 0;
    }

    .list-group-flush:last-child .list-group-item:last-child {
        margin-bottom: 0;
        border-bottom: 0;
    }

    .list-group-item-primary {
        color: #293c74;
        background-color: #cdd8f6;
    }

    .list-group-item-primary.list-group-item-action:hover,
    .list-group-item-primary.list-group-item-action:focus {
        color: #293c74;
        background-color: #b7c7f2;
    }

    .list-group-item-primary.list-group-item-action.active {
        color: var(--white);
        background-color: #293c74;
        border-color: #293c74;
    }

    .list-group-item-secondary {
        color: #45464e;
        background-color: #dddde2;
    }

    .list-group-item-secondary.list-group-item-action:hover,
    .list-group-item-secondary.list-group-item-action:focus {
        color: #45464e;
        background-color: #cfcfd6;
    }

    .list-group-item-secondary.list-group-item-action.active {
        color: var(--white);
        background-color: #45464e;
        border-color: #45464e;
    }

    .list-group-item-success {
        color: #0f6848;
        background-color: #bff0de;
    }

    .list-group-item-success.list-group-item-action:hover,
    .list-group-item-success.list-group-item-action:focus {
        color: #0f6848;
        background-color: #aaebd3;
    }

    .list-group-item-success.list-group-item-action.active {
        color: var(--white);
        background-color: #0f6848;
        border-color: #0f6848;
    }

    .list-group-item-info {
        color: #1c606a;
        background-color: #c7ebf1;
    }

    .list-group-item-info.list-group-item-action:hover,
    .list-group-item-info.list-group-item-action:focus {
        color: #1c606a;
        background-color: #b3e4ec;
    }

    .list-group-item-info.list-group-item-action.active {
        color: var(--white);
        background-color: #1c606a;
        border-color: #1c606a;
    }

    .list-group-item-warning {
        color: #806520;
        background-color: #fceec9;
    }

    .list-group-item-warning.list-group-item-action:hover,
    .list-group-item-warning.list-group-item-action:focus {
        color: #806520;
        background-color: #fbe6b1;
    }

    .list-group-item-warning.list-group-item-action.active {
        color: var(--white);
        background-color: #806520;
        border-color: #806520;
    }

    .list-group-item-danger {
        color: #78261f;
        background-color: #f8ccc8;
    }

    .list-group-item-danger.list-group-item-action:hover,
    .list-group-item-danger.list-group-item-action:focus {
        color: #78261f;
        background-color: #f5b7b1;
    }

    .list-group-item-danger.list-group-item-action.active {
        color: var(--white);
        background-color: #78261f;
        border-color: #78261f;
    }

    .list-group-item-light {
        color: #818183;
        background-color: #fdfdfe;
    }

    .list-group-item-light.list-group-item-action:hover,
    .list-group-item-light.list-group-item-action:focus {
        color: #818183;
        background-color: #ececf6;
    }

    .list-group-item-light.list-group-item-action.active {
        color: var(--white);
        background-color: #818183;
        border-color: #818183;
    }

    .list-group-item-dark {
        color: #2f3037;
        background-color: #d1d1d5;
    }

    .list-group-item-dark.list-group-item-action:hover,
    .list-group-item-dark.list-group-item-action:focus {
        color: #2f3037;
        background-color: #c4c4c9;
    }

    .list-group-item-dark.list-group-item-action.active {
        color: var(--white);
        background-color: #2f3037;
        border-color: #2f3037;
    }

    .close {
        float: right;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 var(--white);
        opacity: 0.5;
    }

    .close:hover {
        color: #000;
        text-decoration: none;
    }

    .close:not(:disabled):not(.disabled):hover,
    .close:not(:disabled):not(.disabled):focus {
        opacity: 0.75;
    }

    button.close {
        padding: 0;
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    a.close.disabled {
        pointer-events: none;
    }

    .toast {
        max-width: 350px;
        overflow: hidden;
        font-size: 0.875rem;
        background-color: rgba(255, 255, 255, 0.85);
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        border-radius: 0.25rem;
    }

    .toast:not(:last-child) {
        margin-bottom: 0.75rem;
    }

    .toast.showing {
        opacity: 1;
    }

    .toast.show {
        display: block;
        opacity: 1;
    }

    .toast.hide {
        display: none;
    }

    .toast-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0.25rem 0.75rem;
        color: var(--secondary);
        background-color: rgba(255, 255, 255, 0.85);
        background-clip: padding-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    .toast-body {
        padding: 0.75rem;
    }

    .modal-open {
        overflow: hidden;
    }

    .modal-open .modal {
        overflow-x: hidden;
        overflow-y: auto;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1050;
        display: none;
        width: 100%;
        height: 100%;
        overflow: hidden;
        outline: 0;
    }

    .modal-dialog {
        position: relative;
        width: auto;
        margin: 0.5rem;
        pointer-events: none;
    }

    .modal.fade .modal-dialog {
        -webkit-transition: -webkit-transform 0.3s ease-out;
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
        -webkit-transform: translate(0, -50px);
        transform: translate(0, -50px);
    }

    @media (prefers-reduced-motion: reduce) {
        .modal.fade .modal-dialog {
            -webkit-transition: none;
            transition: none;
        }
    }

    .modal.show .modal-dialog {
        -webkit-transform: none;
        transform: none;
    }

    .modal-dialog-scrollable {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        max-height: calc(100% - 1rem);
    }

    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 1rem);
        overflow: hidden;
    }

    .modal-dialog-scrollable .modal-header,
    .modal-dialog-scrollable .modal-footer {
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }

    .modal-dialog-scrollable .modal-body {
        overflow-y: auto;
    }

    .modal-dialog-centered {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        min-height: calc(100% - 1rem);
    }

    .modal-dialog-centered::before {
        display: block;
        height: calc(100vh - 1rem);
        content: "";
    }

    .modal-dialog-centered.modal-dialog-scrollable {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 100%;
    }

    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
        max-height: none;
    }

    .modal-dialog-centered.modal-dialog-scrollable::before {
        content: none;
    }

    .modal-content {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: var(--white);
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
        outline: 0;
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1040;
        width: 100vw;
        height: 100vh;
        background-color: #000;
    }

    .modal-backdrop.fade {
        opacity: 0;
    }

    .modal-backdrop.show {
        opacity: 0.5;
    }

    .modal-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-bottom: 1px solid #e3e6f0;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
    }

    .modal-header .close {
        padding: 1rem 1rem;
        margin: -1rem -1rem -1rem auto;
    }

    .modal-title {
        margin-bottom: 0;
        line-height: 1.5;
    }

    .modal-body {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1rem;
    }

    .modal-footer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 1rem;
        border-top: 1px solid #e3e6f0;
        border-bottom-right-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
    }

    .modal-footer > :not(:first-child) {
        margin-left: 0.25rem;
    }

    .modal-footer > :not(:last-child) {
        margin-right: 0.25rem;
    }

    .modal-scrollbar-measure {
        position: absolute;
        top: -9999px;
        width: 50px;
        height: 50px;
        overflow: scroll;
    }

    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 500px;
            margin: 1.75rem auto;
        }
        .modal-dialog-scrollable {
            max-height: calc(100% - 3.5rem);
        }
        .modal-dialog-scrollable .modal-content {
            max-height: calc(100vh - 3.5rem);
        }
        .modal-dialog-centered {
            min-height: calc(100% - 3.5rem);
        }
        .modal-dialog-centered::before {
            height: calc(100vh - 3.5rem);
        }
        .modal-sm {
            max-width: 300px;
        }
    }

    @media (min-width: 992px) {
        .modal-lg,
        .modal-xl {
            max-width: 800px;
        }
    }

    @media (min-width: 1200px) {
        .modal-xl {
            max-width: 1140px;
        }
    }

    .modal-width-large {
        width: 90vw !important;
        max-width: 90vw !important;
    }

    .tooltip {
        position: absolute;
        z-index: 1070;
        display: block;
        margin: 0;
        font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
            "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        text-align: left;
        text-align: start;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        letter-spacing: normal;
        word-break: normal;
        word-spacing: normal;
        white-space: normal;
        line-break: auto;
        font-size: 0.875rem;
        word-wrap: break-word;
        opacity: 0;
    }

    .tooltip.show {
        opacity: 0.9;
    }

    .tooltip .arrow {
        position: absolute;
        display: block;
        width: 0.8rem;
        height: 0.4rem;
    }

    .tooltip .arrow::before {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid;
    }

    .bs-tooltip-top,
    .bs-tooltip-auto[x-placement^="top"] {
        padding: 0.4rem 0;
    }

    .bs-tooltip-top .arrow,
    .bs-tooltip-auto[x-placement^="top"] .arrow {
        bottom: 0;
    }

    .bs-tooltip-top .arrow::before,
    .bs-tooltip-auto[x-placement^="top"] .arrow::before {
        top: 0;
        border-width: 0.4rem 0.4rem 0;
        border-top-color: #000;
    }

    .bs-tooltip-right,
    .bs-tooltip-auto[x-placement^="right"] {
        padding: 0 0.4rem;
    }

    .bs-tooltip-right .arrow,
    .bs-tooltip-auto[x-placement^="right"] .arrow {
        left: 0;
        width: 0.4rem;
        height: 0.8rem;
    }

    .bs-tooltip-right .arrow::before,
    .bs-tooltip-auto[x-placement^="right"] .arrow::before {
        right: 0;
        border-width: 0.4rem 0.4rem 0.4rem 0;
        border-right-color: #000;
    }

    .bs-tooltip-bottom,
    .bs-tooltip-auto[x-placement^="bottom"] {
        padding: 0.4rem 0;
    }

    .bs-tooltip-bottom .arrow,
    .bs-tooltip-auto[x-placement^="bottom"] .arrow {
        top: 0;
    }

    .bs-tooltip-bottom .arrow::before,
    .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
        bottom: 0;
        border-width: 0 0.4rem 0.4rem;
        border-bottom-color: #000;
    }

    .bs-tooltip-left,
    .bs-tooltip-auto[x-placement^="left"] {
        padding: 0 0.4rem;
    }

    .bs-tooltip-left .arrow,
    .bs-tooltip-auto[x-placement^="left"] .arrow {
        right: 0;
        width: 0.4rem;
        height: 0.8rem;
    }

    .bs-tooltip-left .arrow::before,
    .bs-tooltip-auto[x-placement^="left"] .arrow::before {
        left: 0;
        border-width: 0.4rem 0 0.4rem 0.4rem;
        border-left-color: #000;
    }

    .tooltip-inner {
        max-width: 200px;
        padding: 0.25rem 0.5rem;
        color: var(--white);
        text-align: center;
        background-color: #000;
        border-radius: 1.35rem;
    }
    /*
    .popover {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1060;
        display: block;
        max-width: 276px;
        font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
            "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        text-align: left;
        text-align: start;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        letter-spacing: normal;
        word-break: normal;
        word-spacing: normal;
        white-space: normal;
        line-break: auto;
        font-size: 0.875rem;
        word-wrap: break-word;
        background-color: var(--white);
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
    }

    .popover .arrow {
        position: absolute;
        display: block;
        width: 1rem;
        height: 0.5rem;
        margin: 0 0.3rem;
    }

    .popover .arrow::before,
    .popover .arrow::after {
        position: absolute;
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid;
    }

    .bs-popover-top,
    .bs-popover-auto[x-placement^="top"] {
        margin-bottom: 0.5rem;
    }

    .bs-popover-top > .arrow,
    .bs-popover-auto[x-placement^="top"] > .arrow {
        bottom: calc((0.5rem + 1px) * -1);
    }

    .bs-popover-top > .arrow::before,
    .bs-popover-auto[x-placement^="top"] > .arrow::before {
        bottom: 0;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-top > .arrow::after,
    .bs-popover-auto[x-placement^="top"] > .arrow::after {
        bottom: 1px;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: var(--white);
    }

    .bs-popover-right,
    .bs-popover-auto[x-placement^="right"] {
        margin-left: 0.5rem;
    }

    .bs-popover-right > .arrow,
    .bs-popover-auto[x-placement^="right"] > .arrow {
        left: calc((0.5rem + 1px) * -1);
        width: 0.5rem;
        height: 1rem;
        margin: 0.3rem 0;
    }

    .bs-popover-right > .arrow::before,
    .bs-popover-auto[x-placement^="right"] > .arrow::before {
        left: 0;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-right > .arrow::after,
    .bs-popover-auto[x-placement^="right"] > .arrow::after {
        left: 1px;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: var(--white);
    }

    .bs-popover-bottom,
    .bs-popover-auto[x-placement^="bottom"] {
        margin-top: 0.5rem;
    }

    .bs-popover-bottom > .arrow,
    .bs-popover-auto[x-placement^="bottom"] > .arrow {
        top: calc((0.5rem + 1px) * -1);
    }

    .bs-popover-bottom > .arrow::before,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
        top: 0;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-bottom > .arrow::after,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
        top: 1px;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: var(--white);
    }

    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: 1rem;
        margin-left: -0.5rem;
        content: "";
        border-bottom: 1px solid #f7f7f7;
    }

    .bs-popover-left,
    .bs-popover-auto[x-placement^="left"] {
        margin-right: 0.5rem;
    }

    .bs-popover-left > .arrow,
    .bs-popover-auto[x-placement^="left"] > .arrow {
        right: calc((0.5rem + 1px) * -1);
        width: 0.5rem;
        height: 1rem;
        margin: 0.3rem 0;
    }

    .bs-popover-left > .arrow::before,
    .bs-popover-auto[x-placement^="left"] > .arrow::before {
        right: 0;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-left > .arrow::after,
    .bs-popover-auto[x-placement^="left"] > .arrow::after {
        right: 1px;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: var(--white);
    }

    .popover-header {
        padding: 0.5rem 0.75rem;
        margin-bottom: 0;
        font-size: 1rem;
        background-color: #f7f7f7;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
    }

    .popover-header:empty {
        display: none;
    }

    .popover-body {
        padding: 0.5rem 0.75rem;
        color: var(--secondary);
    }
*/
    .row.header {
        display: flex;
        flex-wrap: wrap;
        margin-right: 0.8rem;
        margin-left: 0.8rem;
        margin-bottom: 15px;
    }

    .carousel {
        position: relative;
    }

    .carousel.pointer-event {
        -ms-touch-action: pan-y;
        touch-action: pan-y;
    }

    .carousel-inner {
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .carousel-inner::after {
        display: block;
        clear: both;
        content: "";
    }

    .carousel-item {
        position: relative;
        display: none;
        float: left;
        width: 100%;
        margin-right: -100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.6s ease-in-out;
        transition: -webkit-transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out,
            -webkit-transform 0.6s ease-in-out;
    }

    @media (prefers-reduced-motion: reduce) {
        .carousel-item {
            -webkit-transition: none;
            transition: none;
        }
    }

    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
        display: block;
    }

    .carousel-item-next:not(.carousel-item-left),
    .active.carousel-item-right {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }

    .carousel-item-prev:not(.carousel-item-right),
    .active.carousel-item-left {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    .carousel-fade .carousel-item {
        opacity: 0;
        -webkit-transition-property: opacity;
        transition-property: opacity;
        -webkit-transform: none;
        transform: none;
    }

    .carousel-fade .carousel-item.active,
    .carousel-fade .carousel-item-next.carousel-item-left,
    .carousel-fade .carousel-item-prev.carousel-item-right {
        z-index: 1;
        opacity: 1;
    }

    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        z-index: 0;
        opacity: 0;
        -webkit-transition: 0s 0.6s opacity;
        transition: 0s 0.6s opacity;
    }

    @media (prefers-reduced-motion: reduce) {
        .carousel-fade .active.carousel-item-left,
        .carousel-fade .active.carousel-item-right {
            -webkit-transition: none;
            transition: none;
        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 15%;
        color: var(--white);
        text-align: center;
        opacity: 0.5;
        -webkit-transition: opacity 0.15s ease;
        transition: opacity 0.15s ease;
    }

    @media (prefers-reduced-motion: reduce) {
        .carousel-control-prev,
        .carousel-control-next {
            -webkit-transition: none;
            transition: none;
        }
    }

    .carousel-control-prev:hover,
    .carousel-control-prev:focus,
    .carousel-control-next:hover,
    .carousel-control-next:focus {
        color: var(--white);
        text-decoration: none;
        outline: 0;
        opacity: 0.9;
    }

    .carousel-control-prev {
        left: 0;
    }

    .carousel-control-next {
        right: 0;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: no-repeat 50% / 100% 100%;
    }

    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
    }

    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
    }

    .carousel-indicators {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 15;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding-left: 0;
        margin-right: 15%;
        margin-left: 15%;
        list-style: none;
    }

    .carousel-indicators li {
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        width: 30px;
        height: 3px;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: -999px;
        cursor: pointer;
        background-color: var(--white);
        background-clip: padding-box;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        opacity: 0.5;
        -webkit-transition: opacity 0.6s ease;
        transition: opacity 0.6s ease;
    }

    @media (prefers-reduced-motion: reduce) {
        .carousel-indicators li {
            -webkit-transition: none;
            transition: none;
        }
    }

    .carousel-indicators .active {
        opacity: 1;
    }

    .carousel-caption {
        position: absolute;
        right: 15%;
        bottom: 20px;
        left: 15%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: var(--white);
        text-align: center;
    }

    @-webkit-keyframes spinner-border {
        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes spinner-border {
        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .spinner-border {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        vertical-align: text-bottom;
        border: 0.25em solid currentColor;
        border-right-color: transparent;
        border-radius: 50%;
        -webkit-animation: spinner-border 0.75s linear infinite;
        animation: spinner-border 0.75s linear infinite;
    }

    .spinner-border-sm {
        width: 1rem;
        height: 1rem;
        border-width: 0.2em;
    }

    @-webkit-keyframes spinner-grow {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        50% {
            opacity: 1;
        }
    }

    @keyframes spinner-grow {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        50% {
            opacity: 1;
        }
    }

    .spinner-grow {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        vertical-align: text-bottom;
        background-color: currentColor;
        border-radius: 50%;
        opacity: 0;
        -webkit-animation: spinner-grow 0.75s linear infinite;
        animation: spinner-grow 0.75s linear infinite;
    }

    .spinner-grow-sm {
        width: 1rem;
        height: 1rem;
    }

    .align-baseline {
        vertical-align: baseline !important;
    }

    .align-top {
        vertical-align: top !important;
    }

    .align-middle {
        vertical-align: middle !important;
    }

    .align-bottom {
        vertical-align: bottom !important;
    }

    .align-text-bottom {
        vertical-align: text-bottom !important;
    }

    .align-text-top {
        vertical-align: text-top !important;
    }

    .bg-primary {
        background-color: var(--primary) !important;
    }

    a.bg-primary:hover,
    a.bg-primary:focus,
    button.bg-primary:hover,
    button.bg-primary:focus {
        background-color: #2653d4 !important;
    }

    .bg-secondary {
        background-color: var(--secondary) !important;
    }

    a.bg-secondary:hover,
    a.bg-secondary:focus,
    button.bg-secondary:hover,
    button.bg-secondary:focus {
        background-color: #6b6d7d !important;
    }

    .bg-success {
        background-color: var(--success) !important;
    }

    a.bg-success:hover,
    a.bg-success:focus,
    button.bg-success:hover,
    button.bg-success:focus {
        background-color: #169b6b !important;
    }

    .bg-info {
        background-color: var(--info) !important;
    }

    a.bg-info:hover,
    a.bg-info:focus,
    button.bg-info:hover,
    button.bg-info:focus {
        background-color: #2a96a5 !important;
    }

    .bg-warning {
        background-color: var(--warning) !important;
    }

    a.bg-warning:hover,
    a.bg-warning:focus,
    button.bg-warning:hover,
    button.bg-warning:focus {
        background-color: #f4b30d !important;
    }

    .bg-danger {
        background-color: var(--danger) !important;
    }

    a.bg-danger:hover,
    a.bg-danger:focus,
    button.bg-danger:hover,
    button.bg-danger:focus {
        background-color: #d52a1a !important;
    }

    .bg-light {
        background-color: var(--light) !important;
    }

    a.bg-light:hover,
    a.bg-light:focus,
    button.bg-light:hover,
    button.bg-light:focus {
        background-color: #d4daed !important;
    }

    .bg-dark {
        background-color: var(--gray-dark) !important;
    }

    a.bg-dark:hover,
    a.bg-dark:focus,
    button.bg-dark:hover,
    button.bg-dark:focus {
        background-color: #42444e !important;
    }

    .bg-white {
        background-color: var(--white) !important;
    }

    .bg-transparent {
        background-color: transparent !important;
    }

    .border {
        border: 1px solid #e3e6f0 !important;
    }

    .border-top {
        border-top: 1px solid #e3e6f0 !important;
    }

    .border-right {
        border-right: 1px solid #e3e6f0 !important;
    }

    .border-bottom {
        border-bottom: 1px solid #e3e6f0 !important;
    }

    .border-left {
        border-left: 1px solid #e3e6f0 !important;
    }

    .border-0 {
        border: 0 !important;
    }

    .border-top-0 {
        border-top: 0 !important;
    }

    .border-right-0 {
        border-right: 0 !important;
    }

    .border-bottom-0 {
        border-bottom: 0 !important;
    }

    .border-left-0 {
        border-left: 0 !important;
    }

    .border-primary {
        border-color: var(--primary) !important;
    }

    .border-secondary {
        border-color: var(--secondary) !important;
    }

    .border-success {
        border-color: var(--success) !important;
    }

    .border-info {
        border-color: var(--info) !important;
    }

    .border-warning {
        border-color: var(--warning) !important;
    }

    .border-danger {
        border-color: var(--danger) !important;
    }

    .border-light {
        border-color: var(--light) !important;
    }

    .border-dark {
        border-color: var(--gray-dark) !important;
    }

    .border-white {
        border-color: var(--white) !important;
    }

    .rounded-sm {
        border-radius: 0.2rem !important;
    }

    .rounded {
        border-radius: 1.35rem !important;
    }

    .rounded-top {
        border-top-left-radius: 0.35rem !important;
        border-top-right-radius: 0.35rem !important;
    }

    .rounded-right {
        border-top-right-radius: 0.35rem !important;
        border-bottom-right-radius: 0.35rem !important;
    }

    .rounded-bottom {
        border-bottom-right-radius: 0.35rem !important;
        border-bottom-left-radius: 0.35rem !important;
    }

    .rounded-left {
        border-top-left-radius: 0.35rem !important;
        border-bottom-left-radius: 0.35rem !important;
    }

    .rounded-lg {
        border-radius: 0.3rem !important;
    }

    .rounded-circle {
        border-radius: 50% !important;
    }

    .rounded-pill {
        border-radius: 50rem !important;
    }

    .rounded-0 {
        border-radius: 0 !important;
    }

    .clearfix::after {
        display: block;
        clear: both;
        content: "";
    }

    .d-none {
        display: none !important;
    }

    .d-inline {
        display: inline !important;
    }

    .d-inline-block {
        display: inline-block !important;
    }

    .d-block {
        display: block !important;
    }

    .d-table {
        display: table !important;
    }

    .d-table-row {
        display: table-row !important;
    }

    .d-table-cell {
        display: table-cell !important;
    }

    .d-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }

    @media (min-width: 576px) {
        .d-sm-none {
            display: none !important;
        }
        .d-sm-inline {
            display: inline !important;
        }
        .d-sm-inline-block {
            display: inline-block !important;
        }
        .d-sm-block {
            display: block !important;
        }
        .d-sm-table {
            display: table !important;
        }
        .d-sm-table-row {
            display: table-row !important;
        }
        .d-sm-table-cell {
            display: table-cell !important;
        }
        .d-sm-flex {
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
        }
        .d-sm-inline-flex {
            display: -webkit-inline-box !important;
            display: -ms-inline-flexbox !important;
            display: inline-flex !important;
        }
    }

    @media (min-width: 900px) {
        .d-md-none {
            display: none !important;
        }
        .d-md-inline {
            display: inline !important;
        }
        .d-md-inline-block {
            display: inline-block !important;
        }
        .d-md-block {
            display: block !important;
        }
        .d-md-table {
            display: table !important;
        }
        .d-md-table-row {
            display: table-row !important;
        }
        .d-md-table-cell {
            display: table-cell !important;
        }
        .d-md-flex {
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
        }
        .d-md-inline-flex {
            display: -webkit-inline-box !important;
            display: -ms-inline-flexbox !important;
            display: inline-flex !important;
        }
    }

    @media (min-width: 992px) {
        .d-lg-none {
            display: none !important;
        }
        .d-lg-inline {
            display: inline !important;
        }
        .d-lg-inline-block {
            display: inline-block !important;
        }
        .d-lg-block {
            display: block !important;
        }
        .d-lg-table {
            display: table !important;
        }
        .d-lg-table-row {
            display: table-row !important;
        }
        .d-lg-table-cell {
            display: table-cell !important;
        }
        .d-lg-flex {
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
        }
        .d-lg-inline-flex {
            display: -webkit-inline-box !important;
            display: -ms-inline-flexbox !important;
            display: inline-flex !important;
        }
    }

    @media (min-width: 1200px) {
        .d-xl-none {
            display: none !important;
        }
        .d-xl-inline {
            display: inline !important;
        }
        .d-xl-inline-block {
            display: inline-block !important;
        }
        .d-xl-block {
            display: block !important;
        }
        .d-xl-table {
            display: table !important;
        }
        .d-xl-table-row {
            display: table-row !important;
        }
        .d-xl-table-cell {
            display: table-cell !important;
        }
        .d-xl-flex {
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
        }
        .d-xl-inline-flex {
            display: -webkit-inline-box !important;
            display: -ms-inline-flexbox !important;
            display: inline-flex !important;
        }
    }

    @media print {
        .d-print-none {
            display: none !important;
        }
        .d-print-inline {
            display: inline !important;
        }
        .d-print-inline-block {
            display: inline-block !important;
        }
        .d-print-block {
            display: block !important;
        }
        .d-print-table {
            display: table !important;
        }
        .d-print-table-row {
            display: table-row !important;
        }
        .d-print-table-cell {
            display: table-cell !important;
        }
        .d-print-flex {
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
        }
        .d-print-inline-flex {
            display: -webkit-inline-box !important;
            display: -ms-inline-flexbox !important;
            display: inline-flex !important;
        }
    }

    .embed-responsive {
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
    }

    .embed-responsive::before {
        display: block;
        content: "";
    }

    .embed-responsive .embed-responsive-item,
    .embed-responsive iframe,
    .embed-responsive embed,
    .embed-responsive object,
    .embed-responsive video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    .embed-responsive-21by9::before {
        padding-top: 42.85714%;
    }

    .embed-responsive-16by9::before {
        padding-top: 56.25%;
    }

    .embed-responsive-4by3::before {
        padding-top: 75%;
    }

    .embed-responsive-1by1::before {
        padding-top: 100%;
    }

    .flex-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }

    @media (min-width: 576px) {
        .flex-sm-row {
            -webkit-box-orient: horizontal !important;
            -webkit-box-direction: normal !important;
            -ms-flex-direction: row !important;
            flex-direction: row !important;
        }
        .flex-sm-column {
            -webkit-box-orient: vertical !important;
            -webkit-box-direction: normal !important;
            -ms-flex-direction: column !important;
            flex-direction: column !important;
        }
        .flex-sm-row-reverse {
            -webkit-box-orient: horizontal !important;
            -webkit-box-direction: reverse !important;
            -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
        }
        .flex-sm-column-reverse {
            -webkit-box-orient: vertical !important;
            -webkit-box-direction: reverse !important;
            -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
        }
        .flex-sm-wrap {
            -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
        }
        .flex-sm-nowrap {
            -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
        }
        .flex-sm-wrap-reverse {
            -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
        }
        .flex-sm-fill {
            -webkit-box-flex: 1 !important;
            -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
        }
        .flex-sm-grow-0 {
            -webkit-box-flex: 0 !important;
            -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
        }
        .flex-sm-grow-1 {
            -webkit-box-flex: 1 !important;
            -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
        }
        .flex-sm-shrink-0 {
            -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important;
        }
        .flex-sm-shrink-1 {
            -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important;
        }
        .justify-content-sm-start {
            -webkit-box-pack: start !important;
            -ms-flex-pack: start !important;
            justify-content: flex-start !important;
        }
        .justify-content-sm-end {
            -webkit-box-pack: end !important;
            -ms-flex-pack: end !important;
            justify-content: flex-end !important;
        }
        .justify-content-sm-center {
            -webkit-box-pack: center !important;
            -ms-flex-pack: center !important;
            justify-content: center !important;
        }
        .justify-content-sm-between {
            -webkit-box-pack: justify !important;
            -ms-flex-pack: justify !important;
            justify-content: space-between !important;
        }
        .justify-content-sm-around {
            -ms-flex-pack: distribute !important;
            justify-content: space-around !important;
        }
        .align-items-sm-start {
            -webkit-box-align: start !important;
            -ms-flex-align: start !important;
            align-items: flex-start !important;
        }
        .align-items-sm-end {
            -webkit-box-align: end !important;
            -ms-flex-align: end !important;
            align-items: flex-end !important;
        }
        .align-items-sm-center {
            -webkit-box-align: center !important;
            -ms-flex-align: center !important;
            align-items: center !important;
        }
        .align-items-sm-baseline {
            -webkit-box-align: baseline !important;
            -ms-flex-align: baseline !important;
            align-items: baseline !important;
        }
        .align-items-sm-stretch {
            -webkit-box-align: stretch !important;
            -ms-flex-align: stretch !important;
            align-items: stretch !important;
        }
        .align-content-sm-start {
            -ms-flex-line-pack: start !important;
            align-content: flex-start !important;
        }
        .align-content-sm-end {
            -ms-flex-line-pack: end !important;
            align-content: flex-end !important;
        }
        .align-content-sm-center {
            -ms-flex-line-pack: center !important;
            align-content: center !important;
        }
        .align-content-sm-between {
            -ms-flex-line-pack: justify !important;
            align-content: space-between !important;
        }
        .align-content-sm-around {
            -ms-flex-line-pack: distribute !important;
            align-content: space-around !important;
        }
        .align-content-sm-stretch {
            -ms-flex-line-pack: stretch !important;
            align-content: stretch !important;
        }
        .align-self-sm-auto {
            -ms-flex-item-align: auto !important;
            align-self: auto !important;
        }
        .align-self-sm-start {
            -ms-flex-item-align: start !important;
            align-self: flex-start !important;
        }
        .align-self-sm-end {
            -ms-flex-item-align: end !important;
            align-self: flex-end !important;
        }
        .align-self-sm-center {
            -ms-flex-item-align: center !important;
            align-self: center !important;
        }
        .align-self-sm-baseline {
            -ms-flex-item-align: baseline !important;
            align-self: baseline !important;
        }
        .align-self-sm-stretch {
            -ms-flex-item-align: stretch !important;
            align-self: stretch !important;
        }
    }

    @media (min-width: 768px) {
        .flex-md-row {
            -webkit-box-orient: horizontal !important;
            -webkit-box-direction: normal !important;
            -ms-flex-direction: row !important;
            flex-direction: row !important;
        }
        .flex-md-column {
            -webkit-box-orient: vertical !important;
            -webkit-box-direction: normal !important;
            -ms-flex-direction: column !important;
            flex-direction: column !important;
        }
        .flex-md-row-reverse {
            -webkit-box-orient: horizontal !important;
            -webkit-box-direction: reverse !important;
            -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
        }
        .flex-md-column-reverse {
            -webkit-box-orient: vertical !important;
            -webkit-box-direction: reverse !important;
            -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
        }
        .flex-md-wrap {
            -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
        }
        .flex-md-nowrap {
            -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
        }
        .flex-md-wrap-reverse {
            -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
        }
        .flex-md-fill {
            -webkit-box-flex: 1 !important;
            -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
        }
        .flex-md-grow-0 {
            -webkit-box-flex: 0 !important;
            -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
        }
        .flex-md-grow-1 {
            -webkit-box-flex: 1 !important;
            -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
        }
        .flex-md-shrink-0 {
            -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important;
        }
        .flex-md-shrink-1 {
            -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important;
        }
        .justify-content-md-start {
            -webkit-box-pack: start !important;
            -ms-flex-pack: start !important;
            justify-content: flex-start !important;
        }
        .justify-content-md-end {
            -webkit-box-pack: end !important;
            -ms-flex-pack: end !important;
            justify-content: flex-end !important;
        }
        .justify-content-md-center {
            -webkit-box-pack: center !important;
            -ms-flex-pack: center !important;
            justify-content: center !important;
        }
        .justify-content-md-between {
            -webkit-box-pack: justify !important;
            -ms-flex-pack: justify !important;
            justify-content: space-between !important;
        }
        .justify-content-md-around {
            -ms-flex-pack: distribute !important;
            justify-content: space-around !important;
        }
        .align-items-md-start {
            -webkit-box-align: start !important;
            -ms-flex-align: start !important;
            align-items: flex-start !important;
        }
        .align-items-md-end {
            -webkit-box-align: end !important;
            -ms-flex-align: end !important;
            align-items: flex-end !important;
        }
        .align-items-md-center {
            -webkit-box-align: center !important;
            -ms-flex-align: center !important;
            align-items: center !important;
        }
        .align-items-md-baseline {
            -webkit-box-align: baseline !important;
            -ms-flex-align: baseline !important;
            align-items: baseline !important;
        }
        .align-items-md-stretch {
            -webkit-box-align: stretch !important;
            -ms-flex-align: stretch !important;
            align-items: stretch !important;
        }
        .align-content-md-start {
            -ms-flex-line-pack: start !important;
            align-content: flex-start !important;
        }
        .align-content-md-end {
            -ms-flex-line-pack: end !important;
            align-content: flex-end !important;
        }
        .align-content-md-center {
            -ms-flex-line-pack: center !important;
            align-content: center !important;
        }
        .align-content-md-between {
            -ms-flex-line-pack: justify !important;
            align-content: space-between !important;
        }
        .align-content-md-around {
            -ms-flex-line-pack: distribute !important;
            align-content: space-around !important;
        }
        .align-content-md-stretch {
            -ms-flex-line-pack: stretch !important;
            align-content: stretch !important;
        }
        .align-self-md-auto {
            -ms-flex-item-align: auto !important;
            align-self: auto !important;
        }
        .align-self-md-start {
            -ms-flex-item-align: start !important;
            align-self: flex-start !important;
        }
        .align-self-md-end {
            -ms-flex-item-align: end !important;
            align-self: flex-end !important;
        }
        .align-self-md-center {
            -ms-flex-item-align: center !important;
            align-self: center !important;
        }
        .align-self-md-baseline {
            -ms-flex-item-align: baseline !important;
            align-self: baseline !important;
        }
        .align-self-md-stretch {
            -ms-flex-item-align: stretch !important;
            align-self: stretch !important;
        }
    }

    @media (min-width: 992px) {
        .flex-lg-row {
            -webkit-box-orient: horizontal !important;
            -webkit-box-direction: normal !important;
            -ms-flex-direction: row !important;
            flex-direction: row !important;
        }
        .flex-lg-column {
            -webkit-box-orient: vertical !important;
            -webkit-box-direction: normal !important;
            -ms-flex-direction: column !important;
            flex-direction: column !important;
        }
        .flex-lg-row-reverse {
            -webkit-box-orient: horizontal !important;
            -webkit-box-direction: reverse !important;
            -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
        }
        .flex-lg-column-reverse {
            -webkit-box-orient: vertical !important;
            -webkit-box-direction: reverse !important;
            -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
        }
        .flex-lg-wrap {
            -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
        }
        .flex-lg-nowrap {
            -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
        }
        .flex-lg-wrap-reverse {
            -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
        }
        .flex-lg-fill {
            -webkit-box-flex: 1 !important;
            -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
        }
        .flex-lg-grow-0 {
            -webkit-box-flex: 0 !important;
            -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
        }
        .flex-lg-grow-1 {
            -webkit-box-flex: 1 !important;
            -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
        }
        .flex-lg-shrink-0 {
            -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important;
        }
        .flex-lg-shrink-1 {
            -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important;
        }
        .justify-content-lg-start {
            -webkit-box-pack: start !important;
            -ms-flex-pack: start !important;
            justify-content: flex-start !important;
        }
        .justify-content-lg-end {
            -webkit-box-pack: end !important;
            -ms-flex-pack: end !important;
            justify-content: flex-end !important;
        }
        .justify-content-lg-center {
            -webkit-box-pack: center !important;
            -ms-flex-pack: center !important;
            justify-content: center !important;
        }
        .justify-content-lg-between {
            -webkit-box-pack: justify !important;
            -ms-flex-pack: justify !important;
            justify-content: space-between !important;
        }
        .justify-content-lg-around {
            -ms-flex-pack: distribute !important;
            justify-content: space-around !important;
        }
        .align-items-lg-start {
            -webkit-box-align: start !important;
            -ms-flex-align: start !important;
            align-items: flex-start !important;
        }
        .align-items-lg-end {
            -webkit-box-align: end !important;
            -ms-flex-align: end !important;
            align-items: flex-end !important;
        }
        .align-items-lg-center {
            -webkit-box-align: center !important;
            -ms-flex-align: center !important;
            align-items: center !important;
        }
        .align-items-lg-baseline {
            -webkit-box-align: baseline !important;
            -ms-flex-align: baseline !important;
            align-items: baseline !important;
        }
        .align-items-lg-stretch {
            -webkit-box-align: stretch !important;
            -ms-flex-align: stretch !important;
            align-items: stretch !important;
        }
        .align-content-lg-start {
            -ms-flex-line-pack: start !important;
            align-content: flex-start !important;
        }
        .align-content-lg-end {
            -ms-flex-line-pack: end !important;
            align-content: flex-end !important;
        }
        .align-content-lg-center {
            -ms-flex-line-pack: center !important;
            align-content: center !important;
        }
        .align-content-lg-between {
            -ms-flex-line-pack: justify !important;
            align-content: space-between !important;
        }
        .align-content-lg-around {
            -ms-flex-line-pack: distribute !important;
            align-content: space-around !important;
        }
        .align-content-lg-stretch {
            -ms-flex-line-pack: stretch !important;
            align-content: stretch !important;
        }
        .align-self-lg-auto {
            -ms-flex-item-align: auto !important;
            align-self: auto !important;
        }
        .align-self-lg-start {
            -ms-flex-item-align: start !important;
            align-self: flex-start !important;
        }
        .align-self-lg-end {
            -ms-flex-item-align: end !important;
            align-self: flex-end !important;
        }
        .align-self-lg-center {
            -ms-flex-item-align: center !important;
            align-self: center !important;
        }
        .align-self-lg-baseline {
            -ms-flex-item-align: baseline !important;
            align-self: baseline !important;
        }
        .align-self-lg-stretch {
            -ms-flex-item-align: stretch !important;
            align-self: stretch !important;
        }
    }

    @media (min-width: 1200px) {
        .flex-xl-row {
            -webkit-box-orient: horizontal !important;
            -webkit-box-direction: normal !important;
            -ms-flex-direction: row !important;
            flex-direction: row !important;
        }
        .flex-xl-column {
            -webkit-box-orient: vertical !important;
            -webkit-box-direction: normal !important;
            -ms-flex-direction: column !important;
            flex-direction: column !important;
        }
        .flex-xl-row-reverse {
            -webkit-box-orient: horizontal !important;
            -webkit-box-direction: reverse !important;
            -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
        }
        .flex-xl-column-reverse {
            -webkit-box-orient: vertical !important;
            -webkit-box-direction: reverse !important;
            -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
        }
        .flex-xl-wrap {
            -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
        }
        .flex-xl-nowrap {
            -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
        }
        .flex-xl-wrap-reverse {
            -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
        }
        .flex-xl-fill {
            -webkit-box-flex: 1 !important;
            -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
        }
        .flex-xl-grow-0 {
            -webkit-box-flex: 0 !important;
            -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
        }
        .flex-xl-grow-1 {
            -webkit-box-flex: 1 !important;
            -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
        }
        .flex-xl-shrink-0 {
            -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important;
        }
        .flex-xl-shrink-1 {
            -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important;
        }
        .justify-content-xl-start {
            -webkit-box-pack: start !important;
            -ms-flex-pack: start !important;
            justify-content: flex-start !important;
        }
        .justify-content-xl-end {
            -webkit-box-pack: end !important;
            -ms-flex-pack: end !important;
            justify-content: flex-end !important;
        }
        .justify-content-xl-center {
            -webkit-box-pack: center !important;
            -ms-flex-pack: center !important;
            justify-content: center !important;
        }
        .justify-content-xl-between {
            -webkit-box-pack: justify !important;
            -ms-flex-pack: justify !important;
            justify-content: space-between !important;
        }
        .justify-content-xl-around {
            -ms-flex-pack: distribute !important;
            justify-content: space-around !important;
        }
        .align-items-xl-start {
            -webkit-box-align: start !important;
            -ms-flex-align: start !important;
            align-items: flex-start !important;
        }
        .align-items-xl-end {
            -webkit-box-align: end !important;
            -ms-flex-align: end !important;
            align-items: flex-end !important;
        }
        .align-items-xl-center {
            -webkit-box-align: center !important;
            -ms-flex-align: center !important;
            align-items: center !important;
        }
        .align-items-xl-baseline {
            -webkit-box-align: baseline !important;
            -ms-flex-align: baseline !important;
            align-items: baseline !important;
        }
        .align-items-xl-stretch {
            -webkit-box-align: stretch !important;
            -ms-flex-align: stretch !important;
            align-items: stretch !important;
        }
        .align-content-xl-start {
            -ms-flex-line-pack: start !important;
            align-content: flex-start !important;
        }
        .align-content-xl-end {
            -ms-flex-line-pack: end !important;
            align-content: flex-end !important;
        }
        .align-content-xl-center {
            -ms-flex-line-pack: center !important;
            align-content: center !important;
        }
        .align-content-xl-between {
            -ms-flex-line-pack: justify !important;
            align-content: space-between !important;
        }
        .align-content-xl-around {
            -ms-flex-line-pack: distribute !important;
            align-content: space-around !important;
        }
        .align-content-xl-stretch {
            -ms-flex-line-pack: stretch !important;
            align-content: stretch !important;
        }
        .align-self-xl-auto {
            -ms-flex-item-align: auto !important;
            align-self: auto !important;
        }
        .align-self-xl-start {
            -ms-flex-item-align: start !important;
            align-self: flex-start !important;
        }
        .align-self-xl-end {
            -ms-flex-item-align: end !important;
            align-self: flex-end !important;
        }
        .align-self-xl-center {
            -ms-flex-item-align: center !important;
            align-self: center !important;
        }
        .align-self-xl-baseline {
            -ms-flex-item-align: baseline !important;
            align-self: baseline !important;
        }
        .align-self-xl-stretch {
            -ms-flex-item-align: stretch !important;
            align-self: stretch !important;
        }
    }

    .float-left {
        float: left !important;
    }

    .float-right {
        float: right !important;
    }

    .float-none {
        float: none !important;
    }

    @media (min-width: 576px) {
        .float-sm-left {
            float: left !important;
        }
        .float-sm-right {
            float: right !important;
        }
        .float-sm-none {
            float: none !important;
        }
    }

    @media (min-width: 768px) {
        .float-md-left {
            float: left !important;
        }
        .float-md-right {
            float: right !important;
        }
        .float-md-none {
            float: none !important;
        }
    }

    @media (min-width: 992px) {
        .float-lg-left {
            float: left !important;
        }
        .float-lg-right {
            float: right !important;
        }
        .float-lg-none {
            float: none !important;
        }
    }

    @media (min-width: 1200px) {
        .float-xl-left {
            float: left !important;
        }
        .float-xl-right {
            float: right !important;
        }
        .float-xl-none {
            float: none !important;
        }
    }

    .overflow-auto {
        overflow: auto !important;
    }

    .overflow-hidden {
        overflow: hidden !important;
    }

    .position-static {
        position: static !important;
    }

    .position-relative {
        position: relative !important;
    }

    .position-absolute {
        position: absolute !important;
    }

    .position-fixed {
        position: fixed !important;
    }

    .position-sticky {
        position: -webkit-sticky !important;
        position: sticky !important;
    }

    .fixed-top {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
    }

    .fixed-bottom {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
    }

    @supports ((position: -webkit-sticky) or (position: sticky)) {
        .sticky-top {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 1020;
        }
    }

    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    }

    .sr-only-focusable:active,
    .sr-only-focusable:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;
    }

    .shadow-sm {
        -webkit-box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
        box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
    }

    .shadow {
        -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
        box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
    }

    .shadow-lg {
        -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    }

    .shadow-none {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    .w-25 {
        width: 25% !important;
    }

    .w-50 {
        width: 50% !important;
    }

    .w-75 {
        width: 75% !important;
    }

    .w-100 {
        width: 100% !important;
    }

    .w-auto {
        width: auto !important;
    }

    .h-25 {
        height: 25% !important;
    }

    .h-50 {
        height: 50% !important;
    }

    .h-75 {
        height: 75% !important;
    }

    .h-100 {
        height: 100% !important;
    }

    .h-auto {
        height: auto !important;
    }

    .mw-100 {
        max-width: 100% !important;
    }

    .mh-100 {
        max-height: 100% !important;
    }

    .min-vw-100 {
        min-width: 100vw !important;
    }

    .min-vh-100 {
        min-height: 100vh !important;
    }

    .vw-100 {
        width: 100vw !important;
    }

    .vh-100 {
        height: 100vh !important;
    }

    .stretched-link::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        pointer-events: auto;
        content: "";
        background-color: rgba(0, 0, 0, 0);
    }

    .m-0 {
        margin: 0 !important;
    }

    .mt-0,
    .my-0 {
        margin-top: 0 !important;
    }

    .mr-0,
    .mx-0 {
        margin-right: 0 !important;
    }

    .mb-0,
    .my-0 {
        margin-bottom: 0 !important;
    }

    .ml-0,
    .mx-0 {
        margin-left: 0 !important;
    }

    .m-1 {
        margin: 0.25rem !important;
    }

    .mt-1,
    .my-1 {
        margin-top: 0.25rem !important;
    }

    .mr-1,
    .mx-1 {
        margin-right: 0.25rem !important;
    }

    .mb-1,
    .my-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-1,
    .mx-1 {
        margin-left: 0.25rem !important;
    }

    .m-2 {
        margin: 0.5rem !important;
    }

    .mt-2,
    .my-2 {
        margin-top: 0.5rem !important;
    }

    .mr-2,
    .mx-2 {
        margin-right: 0.5rem !important;
    }

    .mb-2,
    .my-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-2,
    .mx-2 {
        margin-left: 0.5rem !important;
    }

    .m-3 {
        margin: 1rem !important;
    }

    .mt-3,
    .my-3 {
        margin-top: 1rem !important;
    }

    .mr-3,
    .mx-3 {
        margin-right: 1rem !important;
    }

    .mb-3,
    .my-3 {
        margin-bottom: 1rem !important;
    }

    .ml-3,
    .mx-3 {
        margin-left: 1rem !important;
    }

    .m-4 {
        margin: 1.5rem !important;
    }

    .mt-4,
    .my-4 {
        margin-top: 1.5rem !important;
    }

    .mr-4,
    .mx-4 {
        margin-right: 1.5rem !important;
    }

    .mb-4,
    .my-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-4,
    .mx-4 {
        margin-left: 1.5rem !important;
    }

    .m-5 {
        margin: 3rem !important;
    }

    .mt-5,
    .my-5 {
        margin-top: 3rem !important;
    }

    .mr-5,
    .mx-5 {
        margin-right: 3rem !important;
    }

    .mb-5,
    .my-5 {
        margin-bottom: 3rem !important;
    }

    .ml-5,
    .mx-5 {
        margin-left: 3rem !important;
    }

    .p-0 {
        padding: 0 !important;
    }

    .pt-0,
    .py-0 {
        padding-top: 0 !important;
    }

    .pr-0,
    .px-0 {
        padding-right: 0 !important;
    }

    .pb-0,
    .py-0 {
        padding-bottom: 0 !important;
    }

    .pl-0,
    .px-0 {
        padding-left: 0 !important;
    }

    .p-1 {
        padding: 0.25rem !important;
    }

    .pt-1,
    .py-1 {
        padding-top: 0.25rem !important;
    }

    .pr-1,
    .px-1 {
        padding-right: 0.25rem !important;
    }

    .pb-1,
    .py-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-1,
    .px-1 {
        padding-left: 0.25rem !important;
    }

    .p-2 {
        padding: 0.5rem !important;
    }

    .pt-2,
    .py-2 {
        padding-top: 0.5rem !important;
    }

    .pr-2,
    .px-2 {
        padding-right: 0.5rem !important;
    }

    .pb-2,
    .py-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-2,
    .px-2 {
        padding-left: 0.5rem !important;
    }

    .p-3 {
        padding: 1rem !important;
    }

    .pt-3,
    .py-3 {
        padding-top: 1rem !important;
    }

    .pr-3,
    .px-3 {
        padding-right: 1rem !important;
    }

    .pb-3,
    .py-3 {
        padding-bottom: 1rem !important;
    }

    .pl-3,
    .px-3 {
        padding-left: 1rem !important;
    }

    .p-4 {
        padding: 1.5rem !important;
    }

    .pt-4,
    .py-4 {
        padding-top: 1.5rem !important;
    }

    .pr-4,
    .px-4 {
        padding-right: 1.5rem !important;
    }

    .pb-4,
    .py-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-4,
    .px-4 {
        padding-left: 1.5rem !important;
    }

    .p-5 {
        padding: 3rem !important;
    }

    .pt-5,
    .py-5 {
        padding-top: 3rem !important;
    }

    .pr-5,
    .px-5 {
        padding-right: 3rem !important;
    }

    .pb-5,
    .py-5 {
        padding-bottom: 3rem !important;
    }

    .pl-5,
    .px-5 {
        padding-left: 3rem !important;
    }

    .m-n1 {
        margin: -0.25rem !important;
    }

    .mt-n1,
    .my-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-n1,
    .mx-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-n1,
    .my-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-n1,
    .mx-n1 {
        margin-left: -0.25rem !important;
    }

    .m-n2 {
        margin: -0.5rem !important;
    }

    .mt-n2,
    .my-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-n2,
    .mx-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-n2,
    .my-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-n2,
    .mx-n2 {
        margin-left: -0.5rem !important;
    }

    .m-n3 {
        margin: -1rem !important;
    }

    .mt-n3,
    .my-n3 {
        margin-top: -1rem !important;
    }

    .mr-n3,
    .mx-n3 {
        margin-right: -1rem !important;
    }

    .mb-n3,
    .my-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-n3,
    .mx-n3 {
        margin-left: -1rem !important;
    }

    .m-n4 {
        margin: -1.5rem !important;
    }

    .mt-n4,
    .my-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-n4,
    .mx-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-n4,
    .my-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-n4,
    .mx-n4 {
        margin-left: -1.5rem !important;
    }

    .m-n5 {
        margin: -3rem !important;
    }

    .mt-n5,
    .my-n5 {
        margin-top: -3rem !important;
    }

    .mr-n5,
    .mx-n5 {
        margin-right: -3rem !important;
    }

    .mb-n5,
    .my-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-n5,
    .mx-n5 {
        margin-left: -3rem !important;
    }

    .m-auto {
        margin: auto !important;
    }

    .mt-auto,
    .my-auto {
        margin-top: auto !important;
    }

    .mr-auto,
    .mx-auto {
        margin-right: auto !important;
    }

    .mb-auto,
    .my-auto {
        margin-bottom: auto !important;
    }

    .ml-auto,
    .mx-auto {
        margin-left: auto !important;
    }

    @media (min-width: 576px) {
        .m-sm-0 {
            margin: 0 !important;
        }
        .mt-sm-0,
        .my-sm-0 {
            margin-top: 0 !important;
        }
        .mr-sm-0,
        .mx-sm-0 {
            margin-right: 0 !important;
        }
        .mb-sm-0,
        .my-sm-0 {
            margin-bottom: 0 !important;
        }
        .ml-sm-0,
        .mx-sm-0 {
            margin-left: 0 !important;
        }
        .m-sm-1 {
            margin: 0.25rem !important;
        }
        .mt-sm-1,
        .my-sm-1 {
            margin-top: 0.25rem !important;
        }
        .mr-sm-1,
        .mx-sm-1 {
            margin-right: 0.25rem !important;
        }
        .mb-sm-1,
        .my-sm-1 {
            margin-bottom: 0.25rem !important;
        }
        .ml-sm-1,
        .mx-sm-1 {
            margin-left: 0.25rem !important;
        }
        .m-sm-2 {
            margin: 0.5rem !important;
        }
        .mt-sm-2,
        .my-sm-2 {
            margin-top: 0.5rem !important;
        }
        .mr-sm-2,
        .mx-sm-2 {
            margin-right: 0.5rem !important;
        }
        .mb-sm-2,
        .my-sm-2 {
            margin-bottom: 0.5rem !important;
        }
        .ml-sm-2,
        .mx-sm-2 {
            margin-left: 0.5rem !important;
        }
        .m-sm-3 {
            margin: 1rem !important;
        }
        .mt-sm-3,
        .my-sm-3 {
            margin-top: 1rem !important;
        }
        .mr-sm-3,
        .mx-sm-3 {
            margin-right: 1rem !important;
        }
        .mb-sm-3,
        .my-sm-3 {
            margin-bottom: 1rem !important;
        }
        .ml-sm-3,
        .mx-sm-3 {
            margin-left: 1rem !important;
        }
        .m-sm-4 {
            margin: 1.5rem !important;
        }
        .mt-sm-4,
        .my-sm-4 {
            margin-top: 1.5rem !important;
        }
        .mr-sm-4,
        .mx-sm-4 {
            margin-right: 1.5rem !important;
        }
        .mb-sm-4,
        .my-sm-4 {
            margin-bottom: 1.5rem !important;
        }
        .ml-sm-4,
        .mx-sm-4 {
            margin-left: 1.5rem !important;
        }
        .m-sm-5 {
            margin: 3rem !important;
        }
        .mt-sm-5,
        .my-sm-5 {
            margin-top: 3rem !important;
        }
        .mr-sm-5,
        .mx-sm-5 {
            margin-right: 3rem !important;
        }
        .mb-sm-5,
        .my-sm-5 {
            margin-bottom: 3rem !important;
        }
        .ml-sm-5,
        .mx-sm-5 {
            margin-left: 3rem !important;
        }
        .p-sm-0 {
            padding: 0 !important;
        }
        .pt-sm-0,
        .py-sm-0 {
            padding-top: 0 !important;
        }
        .pr-sm-0,
        .px-sm-0 {
            padding-right: 0 !important;
        }
        .pb-sm-0,
        .py-sm-0 {
            padding-bottom: 0 !important;
        }
        .pl-sm-0,
        .px-sm-0 {
            padding-left: 0 !important;
        }
        .p-sm-1 {
            padding: 0.25rem !important;
        }
        .pt-sm-1,
        .py-sm-1 {
            padding-top: 0.25rem !important;
        }
        .pr-sm-1,
        .px-sm-1 {
            padding-right: 0.25rem !important;
        }
        .pb-sm-1,
        .py-sm-1 {
            padding-bottom: 0.25rem !important;
        }
        .pl-sm-1,
        .px-sm-1 {
            padding-left: 0.25rem !important;
        }
        .p-sm-2 {
            padding: 0.5rem !important;
        }
        .pt-sm-2,
        .py-sm-2 {
            padding-top: 0.5rem !important;
        }
        .pr-sm-2,
        .px-sm-2 {
            padding-right: 0.5rem !important;
        }
        .pb-sm-2,
        .py-sm-2 {
            padding-bottom: 0.5rem !important;
        }
        .pl-sm-2,
        .px-sm-2 {
            padding-left: 0.5rem !important;
        }
        .p-sm-3 {
            padding: 1rem !important;
        }
        .pt-sm-3,
        .py-sm-3 {
            padding-top: 1rem !important;
        }
        .pr-sm-3,
        .px-sm-3 {
            padding-right: 1rem !important;
        }
        .pb-sm-3,
        .py-sm-3 {
            padding-bottom: 1rem !important;
        }
        .pl-sm-3,
        .px-sm-3 {
            padding-left: 1rem !important;
        }
        .p-sm-4 {
            padding: 1.5rem !important;
        }
        .pt-sm-4,
        .py-sm-4 {
            padding-top: 1.5rem !important;
        }
        .pr-sm-4,
        .px-sm-4 {
            padding-right: 1.5rem !important;
        }
        .pb-sm-4,
        .py-sm-4 {
            padding-bottom: 1.5rem !important;
        }
        .pl-sm-4,
        .px-sm-4 {
            padding-left: 1.5rem !important;
        }
        .p-sm-5 {
            padding: 3rem !important;
        }
        .pt-sm-5,
        .py-sm-5 {
            padding-top: 3rem !important;
        }
        .pr-sm-5,
        .px-sm-5 {
            padding-right: 3rem !important;
        }
        .pb-sm-5,
        .py-sm-5 {
            padding-bottom: 3rem !important;
        }
        .pl-sm-5,
        .px-sm-5 {
            padding-left: 3rem !important;
        }
        .m-sm-n1 {
            margin: -0.25rem !important;
        }
        .mt-sm-n1,
        .my-sm-n1 {
            margin-top: -0.25rem !important;
        }
        .mr-sm-n1,
        .mx-sm-n1 {
            margin-right: -0.25rem !important;
        }
        .mb-sm-n1,
        .my-sm-n1 {
            margin-bottom: -0.25rem !important;
        }
        .ml-sm-n1,
        .mx-sm-n1 {
            margin-left: -0.25rem !important;
        }
        .m-sm-n2 {
            margin: -0.5rem !important;
        }
        .mt-sm-n2,
        .my-sm-n2 {
            margin-top: -0.5rem !important;
        }
        .mr-sm-n2,
        .mx-sm-n2 {
            margin-right: -0.5rem !important;
        }
        .mb-sm-n2,
        .my-sm-n2 {
            margin-bottom: -0.5rem !important;
        }
        .ml-sm-n2,
        .mx-sm-n2 {
            margin-left: -0.5rem !important;
        }
        .m-sm-n3 {
            margin: -1rem !important;
        }
        .mt-sm-n3,
        .my-sm-n3 {
            margin-top: -1rem !important;
        }
        .mr-sm-n3,
        .mx-sm-n3 {
            margin-right: -1rem !important;
        }
        .mb-sm-n3,
        .my-sm-n3 {
            margin-bottom: -1rem !important;
        }
        .ml-sm-n3,
        .mx-sm-n3 {
            margin-left: -1rem !important;
        }
        .m-sm-n4 {
            margin: -1.5rem !important;
        }
        .mt-sm-n4,
        .my-sm-n4 {
            margin-top: -1.5rem !important;
        }
        .mr-sm-n4,
        .mx-sm-n4 {
            margin-right: -1.5rem !important;
        }
        .mb-sm-n4,
        .my-sm-n4 {
            margin-bottom: -1.5rem !important;
        }
        .ml-sm-n4,
        .mx-sm-n4 {
            margin-left: -1.5rem !important;
        }
        .m-sm-n5 {
            margin: -3rem !important;
        }
        .mt-sm-n5,
        .my-sm-n5 {
            margin-top: -3rem !important;
        }
        .mr-sm-n5,
        .mx-sm-n5 {
            margin-right: -3rem !important;
        }
        .mb-sm-n5,
        .my-sm-n5 {
            margin-bottom: -3rem !important;
        }
        .ml-sm-n5,
        .mx-sm-n5 {
            margin-left: -3rem !important;
        }
        .m-sm-auto {
            margin: auto !important;
        }
        .mt-sm-auto,
        .my-sm-auto {
            margin-top: auto !important;
        }
        .mr-sm-auto,
        .mx-sm-auto {
            margin-right: auto !important;
        }
        .mb-sm-auto,
        .my-sm-auto {
            margin-bottom: auto !important;
        }
        .ml-sm-auto,
        .mx-sm-auto {
            margin-left: auto !important;
        }
    }

    @media (min-width: 768px) {
        .m-md-0 {
            margin: 0 !important;
        }
        .mt-md-0,
        .my-md-0 {
            margin-top: 0 !important;
        }
        .mr-md-0,
        .mx-md-0 {
            margin-right: 0 !important;
        }
        .mb-md-0,
        .my-md-0 {
            margin-bottom: 0 !important;
        }
        .ml-md-0,
        .mx-md-0 {
            margin-left: 0 !important;
        }
        .m-md-1 {
            margin: 0.25rem !important;
        }
        .mt-md-1,
        .my-md-1 {
            margin-top: 0.25rem !important;
        }
        .mr-md-1,
        .mx-md-1 {
            margin-right: 0.25rem !important;
        }
        .mb-md-1,
        .my-md-1 {
            margin-bottom: 0.25rem !important;
        }
        .ml-md-1,
        .mx-md-1 {
            margin-left: 0.25rem !important;
        }
        .m-md-2 {
            margin: 0.5rem !important;
        }
        .mt-md-2,
        .my-md-2 {
            margin-top: 0.5rem !important;
        }
        .mr-md-2,
        .mx-md-2 {
            margin-right: 0.5rem !important;
        }
        .mb-md-2,
        .my-md-2 {
            margin-bottom: 0.5rem !important;
        }
        .ml-md-2,
        .mx-md-2 {
            margin-left: 0.5rem !important;
        }
        .m-md-3 {
            margin: 1rem !important;
        }
        .mt-md-3,
        .my-md-3 {
            margin-top: 1rem !important;
        }
        .mr-md-3,
        .mx-md-3 {
            margin-right: 1rem !important;
        }
        .mb-md-3,
        .my-md-3 {
            margin-bottom: 1rem !important;
        }
        .ml-md-3,
        .mx-md-3 {
            margin-left: 1rem !important;
        }
        .m-md-4 {
            margin: 1.5rem !important;
        }
        .mt-md-4,
        .my-md-4 {
            margin-top: 1.5rem !important;
        }
        .mr-md-4,
        .mx-md-4 {
            margin-right: 1.5rem !important;
        }
        .mb-md-4,
        .my-md-4 {
            margin-bottom: 1.5rem !important;
        }
        .ml-md-4,
        .mx-md-4 {
            margin-left: 1.5rem !important;
        }
        .m-md-5 {
            margin: 3rem !important;
        }
        .mt-md-5,
        .my-md-5 {
            margin-top: 3rem !important;
        }
        .mr-md-5,
        .mx-md-5 {
            margin-right: 3rem !important;
        }
        .mb-md-5,
        .my-md-5 {
            margin-bottom: 3rem !important;
        }
        .ml-md-5,
        .mx-md-5 {
            margin-left: 3rem !important;
        }
        .p-md-0 {
            padding: 0 !important;
        }
        .pt-md-0,
        .py-md-0 {
            padding-top: 0 !important;
        }
        .pr-md-0,
        .px-md-0 {
            padding-right: 0 !important;
        }
        .pb-md-0,
        .py-md-0 {
            padding-bottom: 0 !important;
        }
        .pl-md-0,
        .px-md-0 {
            padding-left: 0 !important;
        }
        .p-md-1 {
            padding: 0.25rem !important;
        }
        .pt-md-1,
        .py-md-1 {
            padding-top: 0.25rem !important;
        }
        .pr-md-1,
        .px-md-1 {
            padding-right: 0.25rem !important;
        }
        .pb-md-1,
        .py-md-1 {
            padding-bottom: 0.25rem !important;
        }
        .pl-md-1,
        .px-md-1 {
            padding-left: 0.25rem !important;
        }
        .p-md-2 {
            padding: 0.5rem !important;
        }
        .pt-md-2,
        .py-md-2 {
            padding-top: 0.5rem !important;
        }
        .pr-md-2,
        .px-md-2 {
            padding-right: 0.5rem !important;
        }
        .pb-md-2,
        .py-md-2 {
            padding-bottom: 0.5rem !important;
        }
        .pl-md-2,
        .px-md-2 {
            padding-left: 0.5rem !important;
        }
        .p-md-3 {
            padding: 1rem !important;
        }
        .pt-md-3,
        .py-md-3 {
            padding-top: 1rem !important;
        }
        .pr-md-3,
        .px-md-3 {
            padding-right: 1rem !important;
        }
        .pb-md-3,
        .py-md-3 {
            padding-bottom: 1rem !important;
        }
        .pl-md-3,
        .px-md-3 {
            padding-left: 1rem !important;
        }
        .p-md-4 {
            padding: 1.5rem !important;
        }
        .pt-md-4,
        .py-md-4 {
            padding-top: 1.5rem !important;
        }
        .pr-md-4,
        .px-md-4 {
            padding-right: 1.5rem !important;
        }
        .pb-md-4,
        .py-md-4 {
            padding-bottom: 1.5rem !important;
        }
        .pl-md-4,
        .px-md-4 {
            padding-left: 1.5rem !important;
        }
        .p-md-5 {
            padding: 3rem !important;
        }
        .pt-md-5,
        .py-md-5 {
            padding-top: 3rem !important;
        }
        .pr-md-5,
        .px-md-5 {
            padding-right: 3rem !important;
        }
        .pb-md-5,
        .py-md-5 {
            padding-bottom: 3rem !important;
        }
        .pl-md-5,
        .px-md-5 {
            padding-left: 3rem !important;
        }
        .m-md-n1 {
            margin: -0.25rem !important;
        }
        .mt-md-n1,
        .my-md-n1 {
            margin-top: -0.25rem !important;
        }
        .mr-md-n1,
        .mx-md-n1 {
            margin-right: -0.25rem !important;
        }
        .mb-md-n1,
        .my-md-n1 {
            margin-bottom: -0.25rem !important;
        }
        .ml-md-n1,
        .mx-md-n1 {
            margin-left: -0.25rem !important;
        }
        .m-md-n2 {
            margin: -0.5rem !important;
        }
        .mt-md-n2,
        .my-md-n2 {
            margin-top: -0.5rem !important;
        }
        .mr-md-n2,
        .mx-md-n2 {
            margin-right: -0.5rem !important;
        }
        .mb-md-n2,
        .my-md-n2 {
            margin-bottom: -0.5rem !important;
        }
        .ml-md-n2,
        .mx-md-n2 {
            margin-left: -0.5rem !important;
        }
        .m-md-n3 {
            margin: -1rem !important;
        }
        .mt-md-n3,
        .my-md-n3 {
            margin-top: -1rem !important;
        }
        .mr-md-n3,
        .mx-md-n3 {
            margin-right: -1rem !important;
        }
        .mb-md-n3,
        .my-md-n3 {
            margin-bottom: -1rem !important;
        }
        .ml-md-n3,
        .mx-md-n3 {
            margin-left: -1rem !important;
        }
        .m-md-n4 {
            margin: -1.5rem !important;
        }
        .mt-md-n4,
        .my-md-n4 {
            margin-top: -1.5rem !important;
        }
        .mr-md-n4,
        .mx-md-n4 {
            margin-right: -1.5rem !important;
        }
        .mb-md-n4,
        .my-md-n4 {
            margin-bottom: -1.5rem !important;
        }
        .ml-md-n4,
        .mx-md-n4 {
            margin-left: -1.5rem !important;
        }
        .m-md-n5 {
            margin: -3rem !important;
        }
        .mt-md-n5,
        .my-md-n5 {
            margin-top: -3rem !important;
        }
        .mr-md-n5,
        .mx-md-n5 {
            margin-right: -3rem !important;
        }
        .mb-md-n5,
        .my-md-n5 {
            margin-bottom: -3rem !important;
        }
        .ml-md-n5,
        .mx-md-n5 {
            margin-left: -3rem !important;
        }
        .m-md-auto {
            margin: auto !important;
        }
        .mt-md-auto,
        .my-md-auto {
            margin-top: auto !important;
        }
        .mr-md-auto,
        .mx-md-auto {
            margin-right: auto !important;
        }
        .mb-md-auto,
        .my-md-auto {
            margin-bottom: auto !important;
        }
        .ml-md-auto,
        .mx-md-auto {
            margin-left: auto !important;
        }
    }

    @media (min-width: 992px) {
        .m-lg-0 {
            margin: 0 !important;
        }
        .mt-lg-0,
        .my-lg-0 {
            margin-top: 0 !important;
        }
        .mr-lg-0,
        .mx-lg-0 {
            margin-right: 0 !important;
        }
        .mb-lg-0,
        .my-lg-0 {
            margin-bottom: 0 !important;
        }
        .ml-lg-0,
        .mx-lg-0 {
            margin-left: 0 !important;
        }
        .m-lg-1 {
            margin: 0.25rem !important;
        }
        .mt-lg-1,
        .my-lg-1 {
            margin-top: 0.25rem !important;
        }
        .mr-lg-1,
        .mx-lg-1 {
            margin-right: 0.25rem !important;
        }
        .mb-lg-1,
        .my-lg-1 {
            margin-bottom: 0.25rem !important;
        }
        .ml-lg-1,
        .mx-lg-1 {
            margin-left: 0.25rem !important;
        }
        .m-lg-2 {
            margin: 0.5rem !important;
        }
        .mt-lg-2,
        .my-lg-2 {
            margin-top: 0.5rem !important;
        }
        .mr-lg-2,
        .mx-lg-2 {
            margin-right: 0.5rem !important;
        }
        .mb-lg-2,
        .my-lg-2 {
            margin-bottom: 0.5rem !important;
        }
        .ml-lg-2,
        .mx-lg-2 {
            margin-left: 0.5rem !important;
        }
        .m-lg-3 {
            margin: 1rem !important;
        }
        .mt-lg-3,
        .my-lg-3 {
            margin-top: 1rem !important;
        }
        .mr-lg-3,
        .mx-lg-3 {
            margin-right: 1rem !important;
        }
        .mb-lg-3,
        .my-lg-3 {
            margin-bottom: 1rem !important;
        }
        .ml-lg-3,
        .mx-lg-3 {
            margin-left: 1rem !important;
        }
        .m-lg-4 {
            margin: 1.5rem !important;
        }
        .mt-lg-4,
        .my-lg-4 {
            margin-top: 1.5rem !important;
        }
        .mr-lg-4,
        .mx-lg-4 {
            margin-right: 1.5rem !important;
        }
        .mb-lg-4,
        .my-lg-4 {
            margin-bottom: 1.5rem !important;
        }
        .ml-lg-4,
        .mx-lg-4 {
            margin-left: 1.5rem !important;
        }
        .m-lg-5 {
            margin: 3rem !important;
        }
        .mt-lg-5,
        .my-lg-5 {
            margin-top: 3rem !important;
        }
        .mr-lg-5,
        .mx-lg-5 {
            margin-right: 3rem !important;
        }
        .mb-lg-5,
        .my-lg-5 {
            margin-bottom: 3rem !important;
        }
        .ml-lg-5,
        .mx-lg-5 {
            margin-left: 3rem !important;
        }
        .p-lg-0 {
            padding: 0 !important;
        }
        .pt-lg-0,
        .py-lg-0 {
            padding-top: 0 !important;
        }
        .pr-lg-0,
        .px-lg-0 {
            padding-right: 0 !important;
        }
        .pb-lg-0,
        .py-lg-0 {
            padding-bottom: 0 !important;
        }
        .pl-lg-0,
        .px-lg-0 {
            padding-left: 0 !important;
        }
        .p-lg-1 {
            padding: 0.25rem !important;
        }
        .pt-lg-1,
        .py-lg-1 {
            padding-top: 0.25rem !important;
        }
        .pr-lg-1,
        .px-lg-1 {
            padding-right: 0.25rem !important;
        }
        .pb-lg-1,
        .py-lg-1 {
            padding-bottom: 0.25rem !important;
        }
        .pl-lg-1,
        .px-lg-1 {
            padding-left: 0.25rem !important;
        }
        .p-lg-2 {
            padding: 0.5rem !important;
        }
        .pt-lg-2,
        .py-lg-2 {
            padding-top: 0.5rem !important;
        }
        .pr-lg-2,
        .px-lg-2 {
            padding-right: 0.5rem !important;
        }
        .pb-lg-2,
        .py-lg-2 {
            padding-bottom: 0.5rem !important;
        }
        .pl-lg-2,
        .px-lg-2 {
            padding-left: 0.5rem !important;
        }
        .p-lg-3 {
            padding: 1rem !important;
        }
        .pt-lg-3,
        .py-lg-3 {
            padding-top: 1rem !important;
        }
        .pr-lg-3,
        .px-lg-3 {
            padding-right: 1rem !important;
        }
        .pb-lg-3,
        .py-lg-3 {
            padding-bottom: 1rem !important;
        }
        .pl-lg-3,
        .px-lg-3 {
            padding-left: 1rem !important;
        }
        .p-lg-4 {
            padding: 1.5rem !important;
        }
        .pt-lg-4,
        .py-lg-4 {
            padding-top: 1.5rem !important;
        }
        .pr-lg-4,
        .px-lg-4 {
            padding-right: 1.5rem !important;
        }
        .pb-lg-4,
        .py-lg-4 {
            padding-bottom: 1.5rem !important;
        }
        .pl-lg-4,
        .px-lg-4 {
            padding-left: 1.5rem !important;
        }
        .p-lg-5 {
            padding: 3rem !important;
        }
        .pt-lg-5,
        .py-lg-5 {
            padding-top: 3rem !important;
        }
        .pr-lg-5,
        .px-lg-5 {
            padding-right: 3rem !important;
        }
        .pb-lg-5,
        .py-lg-5 {
            padding-bottom: 3rem !important;
        }
        .pl-lg-5,
        .px-lg-5 {
            padding-left: 3rem !important;
        }
        .m-lg-n1 {
            margin: -0.25rem !important;
        }
        .mt-lg-n1,
        .my-lg-n1 {
            margin-top: -0.25rem !important;
        }
        .mr-lg-n1,
        .mx-lg-n1 {
            margin-right: -0.25rem !important;
        }
        .mb-lg-n1,
        .my-lg-n1 {
            margin-bottom: -0.25rem !important;
        }
        .ml-lg-n1,
        .mx-lg-n1 {
            margin-left: -0.25rem !important;
        }
        .m-lg-n2 {
            margin: -0.5rem !important;
        }
        .mt-lg-n2,
        .my-lg-n2 {
            margin-top: -0.5rem !important;
        }
        .mr-lg-n2,
        .mx-lg-n2 {
            margin-right: -0.5rem !important;
        }
        .mb-lg-n2,
        .my-lg-n2 {
            margin-bottom: -0.5rem !important;
        }
        .ml-lg-n2,
        .mx-lg-n2 {
            margin-left: -0.5rem !important;
        }
        .m-lg-n3 {
            margin: -1rem !important;
        }
        .mt-lg-n3,
        .my-lg-n3 {
            margin-top: -1rem !important;
        }
        .mr-lg-n3,
        .mx-lg-n3 {
            margin-right: -1rem !important;
        }
        .mb-lg-n3,
        .my-lg-n3 {
            margin-bottom: -1rem !important;
        }
        .ml-lg-n3,
        .mx-lg-n3 {
            margin-left: -1rem !important;
        }
        .m-lg-n4 {
            margin: -1.5rem !important;
        }
        .mt-lg-n4,
        .my-lg-n4 {
            margin-top: -1.5rem !important;
        }
        .mr-lg-n4,
        .mx-lg-n4 {
            margin-right: -1.5rem !important;
        }
        .mb-lg-n4,
        .my-lg-n4 {
            margin-bottom: -1.5rem !important;
        }
        .ml-lg-n4,
        .mx-lg-n4 {
            margin-left: -1.5rem !important;
        }
        .m-lg-n5 {
            margin: -3rem !important;
        }
        .mt-lg-n5,
        .my-lg-n5 {
            margin-top: -3rem !important;
        }
        .mr-lg-n5,
        .mx-lg-n5 {
            margin-right: -3rem !important;
        }
        .mb-lg-n5,
        .my-lg-n5 {
            margin-bottom: -3rem !important;
        }
        .ml-lg-n5,
        .mx-lg-n5 {
            margin-left: -3rem !important;
        }
        .m-lg-auto {
            margin: auto !important;
        }
        .mt-lg-auto,
        .my-lg-auto {
            margin-top: auto !important;
        }
        .mr-lg-auto,
        .mx-lg-auto {
            margin-right: auto !important;
        }
        .mb-lg-auto,
        .my-lg-auto {
            margin-bottom: auto !important;
        }
        .ml-lg-auto,
        .mx-lg-auto {
            margin-left: auto !important;
        }
    }

    @media (min-width: 1200px) {
        .m-xl-0 {
            margin: 0 !important;
        }
        .mt-xl-0,
        .my-xl-0 {
            margin-top: 0 !important;
        }
        .mr-xl-0,
        .mx-xl-0 {
            margin-right: 0 !important;
        }
        .mb-xl-0,
        .my-xl-0 {
            margin-bottom: 0 !important;
        }
        .ml-xl-0,
        .mx-xl-0 {
            margin-left: 0 !important;
        }
        .m-xl-1 {
            margin: 0.25rem !important;
        }
        .mt-xl-1,
        .my-xl-1 {
            margin-top: 0.25rem !important;
        }
        .mr-xl-1,
        .mx-xl-1 {
            margin-right: 0.25rem !important;
        }
        .mb-xl-1,
        .my-xl-1 {
            margin-bottom: 0.25rem !important;
        }
        .ml-xl-1,
        .mx-xl-1 {
            margin-left: 0.25rem !important;
        }
        .m-xl-2 {
            margin: 0.5rem !important;
        }
        .mt-xl-2,
        .my-xl-2 {
            margin-top: 0.5rem !important;
        }
        .mr-xl-2,
        .mx-xl-2 {
            margin-right: 0.5rem !important;
        }
        .mb-xl-2,
        .my-xl-2 {
            margin-bottom: 0.5rem !important;
        }
        .ml-xl-2,
        .mx-xl-2 {
            margin-left: 0.5rem !important;
        }
        .m-xl-3 {
            margin: 1rem !important;
        }
        .mt-xl-3,
        .my-xl-3 {
            margin-top: 1rem !important;
        }
        .mr-xl-3,
        .mx-xl-3 {
            margin-right: 1rem !important;
        }
        .mb-xl-3,
        .my-xl-3 {
            margin-bottom: 1rem !important;
        }
        .ml-xl-3,
        .mx-xl-3 {
            margin-left: 1rem !important;
        }
        .m-xl-4 {
            margin: 1.5rem !important;
        }
        .mt-xl-4,
        .my-xl-4 {
            margin-top: 1.5rem !important;
        }
        .mr-xl-4,
        .mx-xl-4 {
            margin-right: 1.5rem !important;
        }
        .mb-xl-4,
        .my-xl-4 {
            margin-bottom: 1.5rem !important;
        }
        .ml-xl-4,
        .mx-xl-4 {
            margin-left: 1.5rem !important;
        }
        .m-xl-5 {
            margin: 3rem !important;
        }
        .mt-xl-5,
        .my-xl-5 {
            margin-top: 3rem !important;
        }
        .mr-xl-5,
        .mx-xl-5 {
            margin-right: 3rem !important;
        }
        .mb-xl-5,
        .my-xl-5 {
            margin-bottom: 3rem !important;
        }
        .ml-xl-5,
        .mx-xl-5 {
            margin-left: 3rem !important;
        }
        .p-xl-0 {
            padding: 0 !important;
        }
        .pt-xl-0,
        .py-xl-0 {
            padding-top: 0 !important;
        }
        .pr-xl-0,
        .px-xl-0 {
            padding-right: 0 !important;
        }
        .pb-xl-0,
        .py-xl-0 {
            padding-bottom: 0 !important;
        }
        .pl-xl-0,
        .px-xl-0 {
            padding-left: 0 !important;
        }
        .p-xl-1 {
            padding: 0.25rem !important;
        }
        .pt-xl-1,
        .py-xl-1 {
            padding-top: 0.25rem !important;
        }
        .pr-xl-1,
        .px-xl-1 {
            padding-right: 0.25rem !important;
        }
        .pb-xl-1,
        .py-xl-1 {
            padding-bottom: 0.25rem !important;
        }
        .pl-xl-1,
        .px-xl-1 {
            padding-left: 0.25rem !important;
        }
        .p-xl-2 {
            padding: 0.5rem !important;
        }
        .pt-xl-2,
        .py-xl-2 {
            padding-top: 0.5rem !important;
        }
        .pr-xl-2,
        .px-xl-2 {
            padding-right: 0.5rem !important;
        }
        .pb-xl-2,
        .py-xl-2 {
            padding-bottom: 0.5rem !important;
        }
        .pl-xl-2,
        .px-xl-2 {
            padding-left: 0.5rem !important;
        }
        .p-xl-3 {
            padding: 1rem !important;
        }
        .pt-xl-3,
        .py-xl-3 {
            padding-top: 1rem !important;
        }
        .pr-xl-3,
        .px-xl-3 {
            padding-right: 1rem !important;
        }
        .pb-xl-3,
        .py-xl-3 {
            padding-bottom: 1rem !important;
        }
        .pl-xl-3,
        .px-xl-3 {
            padding-left: 1rem !important;
        }
        .p-xl-4 {
            padding: 1.5rem !important;
        }
        .pt-xl-4,
        .py-xl-4 {
            padding-top: 1.5rem !important;
        }
        .pr-xl-4,
        .px-xl-4 {
            padding-right: 1.5rem !important;
        }
        .pb-xl-4,
        .py-xl-4 {
            padding-bottom: 1.5rem !important;
        }
        .pl-xl-4,
        .px-xl-4 {
            padding-left: 1.5rem !important;
        }
        .p-xl-5 {
            padding: 3rem !important;
        }
        .pt-xl-5,
        .py-xl-5 {
            padding-top: 3rem !important;
        }
        .pr-xl-5,
        .px-xl-5 {
            padding-right: 3rem !important;
        }
        .pb-xl-5,
        .py-xl-5 {
            padding-bottom: 3rem !important;
        }
        .pl-xl-5,
        .px-xl-5 {
            padding-left: 3rem !important;
        }
        .m-xl-n1 {
            margin: -0.25rem !important;
        }
        .mt-xl-n1,
        .my-xl-n1 {
            margin-top: -0.25rem !important;
        }
        .mr-xl-n1,
        .mx-xl-n1 {
            margin-right: -0.25rem !important;
        }
        .mb-xl-n1,
        .my-xl-n1 {
            margin-bottom: -0.25rem !important;
        }
        .ml-xl-n1,
        .mx-xl-n1 {
            margin-left: -0.25rem !important;
        }
        .m-xl-n2 {
            margin: -0.5rem !important;
        }
        .mt-xl-n2,
        .my-xl-n2 {
            margin-top: -0.5rem !important;
        }
        .mr-xl-n2,
        .mx-xl-n2 {
            margin-right: -0.5rem !important;
        }
        .mb-xl-n2,
        .my-xl-n2 {
            margin-bottom: -0.5rem !important;
        }
        .ml-xl-n2,
        .mx-xl-n2 {
            margin-left: -0.5rem !important;
        }
        .m-xl-n3 {
            margin: -1rem !important;
        }
        .mt-xl-n3,
        .my-xl-n3 {
            margin-top: -1rem !important;
        }
        .mr-xl-n3,
        .mx-xl-n3 {
            margin-right: -1rem !important;
        }
        .mb-xl-n3,
        .my-xl-n3 {
            margin-bottom: -1rem !important;
        }
        .ml-xl-n3,
        .mx-xl-n3 {
            margin-left: -1rem !important;
        }
        .m-xl-n4 {
            margin: -1.5rem !important;
        }
        .mt-xl-n4,
        .my-xl-n4 {
            margin-top: -1.5rem !important;
        }
        .mr-xl-n4,
        .mx-xl-n4 {
            margin-right: -1.5rem !important;
        }
        .mb-xl-n4,
        .my-xl-n4 {
            margin-bottom: -1.5rem !important;
        }
        .ml-xl-n4,
        .mx-xl-n4 {
            margin-left: -1.5rem !important;
        }
        .m-xl-n5 {
            margin: -3rem !important;
        }
        .mt-xl-n5,
        .my-xl-n5 {
            margin-top: -3rem !important;
        }
        .mr-xl-n5,
        .mx-xl-n5 {
            margin-right: -3rem !important;
        }
        .mb-xl-n5,
        .my-xl-n5 {
            margin-bottom: -3rem !important;
        }
        .ml-xl-n5,
        .mx-xl-n5 {
            margin-left: -3rem !important;
        }
        .m-xl-auto {
            margin: auto !important;
        }
        .mt-xl-auto,
        .my-xl-auto {
            margin-top: auto !important;
        }
        .mr-xl-auto,
        .mx-xl-auto {
            margin-right: auto !important;
        }
        .mb-xl-auto,
        .my-xl-auto {
            margin-bottom: auto !important;
        }
        .ml-xl-auto,
        .mx-xl-auto {
            margin-left: auto !important;
        }
    }

    .text-monospace {
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
            "Courier New", monospace !important;
    }

    .text-justify {
        text-align: justify !important;
    }

    .text-wrap {
        white-space: normal !important;
    }

    .text-nowrap {
        white-space: nowrap !important;
    }

    .text-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .text-left {
        text-align: left !important;
    }

    .text-right {
        text-align: right !important;
    }

    .text-center {
        text-align: center !important;
    }

    @media (min-width: 576px) {
        .text-sm-left {
            text-align: left !important;
        }
        .text-sm-right {
            text-align: right !important;
        }
        .text-sm-center {
            text-align: center !important;
        }
    }

    @media (min-width: 768px) {
        .text-md-left {
            text-align: left !important;
        }
        .text-md-right {
            text-align: right !important;
        }
        .text-md-center {
            text-align: center !important;
        }
    }

    @media (min-width: 992px) {
        .text-lg-left {
            text-align: left !important;
        }
        .text-lg-right {
            text-align: right !important;
        }
        .text-lg-center {
            text-align: center !important;
        }
    }

    @media (min-width: 1200px) {
        .text-xl-left {
            text-align: left !important;
        }
        .text-xl-right {
            text-align: right !important;
        }
        .text-xl-center {
            text-align: center !important;
        }
    }

    .text-lowercase {
        text-transform: lowercase !important;
    }

    .text-uppercase,
    .dropdown .dropdown-menu .dropdown-header,
    .sidebar .sidebar-heading {
        text-transform: uppercase !important;
    }

    .text-capitalize {
        text-transform: capitalize !important;
    }

    .font-weight-light {
        font-weight: 300 !important;
    }

    .font-weight-lighter {
        font-weight: lighter !important;
    }

    .font-weight-normal {
        font-weight: 400 !important;
    }

    .font-weight-bold {
        font-weight: 700 !important;
    }

    .font-weight-bolder {
        font-weight: bolder !important;
    }

    .font-italic {
        font-style: italic !important;
    }

    .text-white {
        color: var(--white) !important;
    }

    .text-primary {
        color: var(--primary) !important;
    }

    a.text-primary:hover,
    a.text-primary:focus {
        color: var(--warning) !important;
    }

    .text-secondary {
        color: var(--secondary) !important;
    }

    a.text-secondary:hover,
    a.text-secondary:focus {
        color: var(--warning) !important;
    }

    .text-success {
        color: var(--success) !important;
    }

    a.text-success:hover,
    a.text-success:focus {
        color: var(--warning) !important;
    }

    .text-info {
        color: var(--info) !important;
    }

    a.text-info:hover,
    a.text-info:focus {
        color: #258391 !important;
    }

    .text-warning {
        color: var(--warning) !important;
    }

    a.text-warning:hover,
    a.text-warning:focus {
        color: #dda20a !important;
    }

    .text-danger {
        color: var(--danger) !important;
    }

    a.text-danger:hover,
    a.text-danger:focus {
        color: var(--warning) !important;
    }

    .text-light {
        color: var(--light) !important;
    }

    a.text-light:hover,
    a.text-light:focus {
        color: var(--warning) !important;
    }

    .text-dark {
        color: var(--gray-dark) !important;
    }

    a.text-dark:hover,
    a.text-dark:focus {
        color: var(--warning) !important;
    }

    .text-body {
        color: var(--secondary) !important;
    }

    .text-muted {
        color: var(--secondary) !important;
    }

    .text-black-50 {
        color: rgba(0, 0, 0, 0.5) !important;
    }

    .text-white-50 {
        color: rgba(255, 255, 255, 0.5) !important;
    }

    .text-hide {
        font: 0/0 a;
        color: transparent;
        text-shadow: none;
        background-color: transparent;
        border: 0;
    }

    .text-decoration-none {
        text-decoration: none !important;
    }

    .text-break {
        word-break: break-word !important;
        overflow-wrap: break-word !important;
    }

    .text-reset {
        color: inherit !important;
    }

    .visible {
        visibility: visible !important;
    }

    .invisible {
        visibility: hidden !important;
    }

    @media print {
        *,
        *::before,
        *::after {
            text-shadow: none !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }
        a:not(.btn) {
            text-decoration: underline;
        }
        abbr[title]::after {
            content: " (" attr(title) ")";
        }
        pre {
            white-space: pre-wrap !important;
        }
        pre,
        blockquote {
            border: 1px solid #b7b9cc;
            page-break-inside: avoid;
        }
        thead {
            display: table-header-group;
        }
        tr,
        img {
            page-break-inside: avoid;
        }
        p,
        h2,
        h3 {
            orphans: 3;
            widows: 3;
        }
        h2,
        h3 {
            page-break-after: avoid;
        }
        @page {
            size: a3;
        }
        body {
            min-width: 992px !important;
        }
        .container {
            min-width: 992px !important;
        }
        .navbar {
            display: none;
        }
        .badge {
            border: 1px solid #000;
        }
        .table td,
        .table th {
            background-color: var(--white) !important;
        }
        .table-bordered th,
        .table-bordered td {
            border: 1px solid #dddfeb !important;
        }
        .table-dark {
            color: inherit;
        }
        .table-dark th,
        .table-dark td,
        .table-dark thead th,
        .table-dark tbody + tbody {
            border-color: #e3e6f0;
        }
        .table .thead-dark th {
            color: inherit;
            border-color: #e3e6f0;
        }
    }

    html {
        position: relative;
        min-height: 100%;
    }

    body {
        height: 100%;
    }

    a:focus {
        outline: none;
    }

    #wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    #wrapper #content-wrapper {
        background-color: var(--light);
        width: 100%;
        overflow-x: hidden;
    }

    #wrapper #content-wrapper #content {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
    }

    .container,
    .container-fluid {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .scroll-to-top {
        position: fixed;
        right: 1rem;
        bottom: 1rem;
        display: none;
        width: 2.75rem;
        height: 2.75rem;
        text-align: center;
        color: var(--white);
        background: rgba(90, 92, 105, 0.5);
        line-height: 46px;
    }

    .scroll-to-top:focus,
    .scroll-to-top:hover {
        color: white;
    }

    .scroll-to-top:hover {
        background: var(--gray-dark);
    }

    .scroll-to-top i {
        font-weight: 800;
    }

    @-webkit-keyframes growIn {
        0% {
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
            opacity: 0;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes growIn {
        0% {
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
            opacity: 0;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }

    .animated--grow-in,
    .sidebar .nav-item .collapse {
        -webkit-animation-name: growIn;
        animation-name: growIn;
        -webkit-animation-duration: 200ms;
        animation-duration: 200ms;
        -webkit-animation-timing-function: transform
                cubic-bezier(0.18, 1.25, 0.4, 1),
            opacity cubic-bezier(0, 1, 0.4, 1);
        animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
            opacity cubic-bezier(0, 1, 0.4, 1);
    }

    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .animated--fade-in {
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        -webkit-animation-duration: 200ms;
        animation-duration: 200ms;
        -webkit-animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
        animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
    }

    .bg-gradient-primary {
        background-color: var(--gray-dark);
        background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(10%, var(--primary)),
            to(#224abe)
        );
        background-image: linear-gradient(
            180deg,
            var(--primary) 10%,
            #224abe 100%
        );
        background-size: cover;
    }

    .bg-gradient-success {
        background-color: var(--success);
        background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(10%, var(--success)),
            to(#13855c)
        );
        background-image: linear-gradient(
            180deg,
            var(--success) 10%,
            #13855c 100%
        );
        background-size: cover;
    }

    .bg-gradient-info {
        background-color: var(--info);
        background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(10%, var(--info)),
            to(#258391)
        );
        background-image: linear-gradient(
            180deg,
            var(--info) 10%,
            #258391 100%
        );
        background-size: cover;
    }

    .bg-gradient-warning {
        background-color: var(--warning);
        background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(10%, var(--warning)),
            to(#dda20a)
        );
        background-image: linear-gradient(
            180deg,
            var(--warning) 10%,
            #dda20a 100%
        );
        background-size: cover;
    }

    .bg-gradient-danger {
        background-color: var(--danger);
        background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(10%, var(--danger)),
            to(#be2617)
        );
        background-image: linear-gradient(
            180deg,
            var(--danger) 10%,
            #be2617 100%
        );
        background-size: cover;
    }

    .bg-gray-100 {
        background-color: var(--light) !important;
    }

    .bg-gray-200 {
        background-color: #eaecf4 !important;
    }

    .bg-gray-300 {
        background-color: #dddfeb !important;
    }

    .bg-gray-400 {
        background-color: #d1d3e2 !important;
    }

    .bg-gray-500 {
        background-color: #b7b9cc !important;
    }

    .bg-gray-600 {
        background-color: var(--secondary) !important;
    }

    .bg-gray-700 {
        background-color: #6e707e !important;
    }

    .bg-gray-800 {
        background-color: var(--gray-dark) !important;
    }

    .bg-gray-900 {
        background-color: #3a3b45 !important;
    }

    .o-hidden {
        overflow: hidden !important;
    }

    .text-xs {
        font-size: 0.7rem;
    }

    .text-lg {
        font-size: 1.2rem;
    }

    .text-gray-100 {
        color: var(--light) !important;
    }

    .text-gray-200 {
        color: #eaecf4 !important;
    }

    .text-gray-300 {
        color: #dddfeb !important;
    }

    .text-gray-400 {
        color: #d1d3e2 !important;
    }

    .text-gray-500 {
        color: #b7b9cc !important;
    }

    .text-gray-600 {
        color: var(--secondary) !important;
    }

    .text-gray-700 {
        color: #6e707e !important;
    }

    .text-gray-800 {
        color: var(--gray-dark) !important;
    }

    .text-gray-900 {
        color: #3a3b45 !important;
    }

    .icon-circle {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .border-left-primary {
        border-left: 0.25rem solid var(--primary) !important;
    }

    .border-left-success {
        border-left: 0.25rem solid var(--success) !important;
    }

    .border-left-info {
        border-left: 0.25rem solid var(--info) !important;
    }

    .border-left-warning {
        border-left: 0.25rem solid var(--warning) !important;
    }

    .border-left-danger {
        border-left: 0.25rem solid var(--danger) !important;
    }

    .border-bottom-primary {
        border-bottom: 0.25rem solid var(--primary) !important;
    }

    .border-bottom-success {
        border-bottom: 0.25rem solid var(--success) !important;
    }

    .border-bottom-info {
        border-bottom: 0.25rem solid var(--info) !important;
    }

    .border-bottom-warning {
        border-bottom: 0.25rem solid var(--warning) !important;
    }

    .border-bottom-danger {
        border-bottom: 0.25rem solid var(--danger) !important;
    }

    .progress-sm {
        height: 0.5rem;
    }

    .rotate-15 {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    .rotate-n-15 {
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg);
    }

    .dropdown .dropdown-menu {
        font-size: 0.85rem;
    }

    .dropdown .dropdown-menu .dropdown-header {
        font-weight: 800;
        font-size: 0.65rem;
        color: #b7b9cc;
    }

    .dropdown.no-arrow .dropdown-toggle::after {
        display: none;
    }

    .sidebar .nav-item.dropdown .dropdown-toggle::after,
    .topbar .nav-item.dropdown .dropdown-toggle::after {
        width: 1rem;
        text-align: center;
        float: right;
        vertical-align: 0;
        border: 0;
        font-weight: 900;
        content: "\\f105";
        font-family: "Font Awesome 5 Free";
    }

    .sidebar .nav-item.dropdown.show .dropdown-toggle::after,
    .topbar .nav-item.dropdown.show .dropdown-toggle::after {
        content: "\\f107";
    }

    .sidebar .nav-item .nav-link,
    .topbar .nav-item .nav-link {
        position: relative;
    }

    .sidebar .nav-item .nav-link .badge-counter,
    .topbar .nav-item .nav-link .badge-counter {
        position: absolute;
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
        -webkit-transform-origin: top right;
        transform-origin: top right;
        right: 0.25rem;
        margin-top: -0.25rem;
    }

    .sidebar .nav-item .nav-link .img-profile,
    .topbar .nav-item .nav-link .img-profile {
        height: 2rem;
        width: 2rem;
    }

    .topbar {
        height: 4.375rem;
    }

    .topbar #sidebarToggleTop {
        height: 2.5rem;
        width: 2.5rem;
    }

    .topbar #sidebarToggleTop:hover {
        background-color: #eaecf4;
    }

    .topbar #sidebarToggleTop:active {
        background-color: #dddfeb;
    }

    .topbar .navbar-search {
        width: 25rem;
    }

    .topbar .navbar-search input {
        font-size: 0.85rem;
    }

    .topbar .topbar-divider {
        width: 0;
        border-right: 1px solid #e3e6f0;
        height: calc(4.375rem - 2rem);
        margin: auto 1rem;
    }

    .topbar .nav-item .nav-link {
        height: 4.375rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 0.75rem;
    }

    .topbar .nav-item .nav-link:focus {
        outline: none;
    }

    .topbar .nav-item:focus {
        outline: none;
    }

    .topbar .dropdown {
        position: static;
    }

    .topbar .dropdown .dropdown-menu {
        width: calc(100% - 1.5rem);
        right: 0.75rem;
    }

    .topbar .dropdown-list {
        padding: 0;
        border: none;
        overflow: hidden;
    }

    .topbar .dropdown-list .dropdown-header {
        background-color: var(--primary);
        border: 1px solid var(--primary);
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        color: var(--white);
    }

    .topbar .dropdown-list .dropdown-item {
        white-space: normal;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-left: 1px solid #e3e6f0;
        border-right: 1px solid #e3e6f0;
        border-bottom: 1px solid #e3e6f0;
        line-height: 1.3rem;
    }

    .topbar .dropdown-list .dropdown-item .dropdown-list-image {
        position: relative;
        height: 2.5rem;
        width: 2.5rem;
    }

    .topbar .dropdown-list .dropdown-item .dropdown-list-image img {
        height: 2.5rem;
        width: 2.5rem;
    }

    .topbar
        .dropdown-list
        .dropdown-item
        .dropdown-list-image
        .status-indicator {
        background-color: #eaecf4;
        height: 0.75rem;
        width: 0.75rem;
        border-radius: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        border: 0.125rem solid var(--white);
    }

    .topbar .dropdown-list .dropdown-item .text-truncate {
        max-width: 10rem;
    }

    .topbar .dropdown-list .dropdown-item:active {
        background-color: #eaecf4;
        color: #3a3b45;
    }

    @media (min-width: 576px) {
        .topbar .dropdown {
            position: relative;
        }
        .topbar .dropdown .dropdown-menu {
            width: auto;
            right: 0;
        }
        .topbar .dropdown-list {
            width: 20rem !important;
        }
        .topbar .dropdown-list .dropdown-item .text-truncate {
            max-width: 13.375rem;
        }
    }

    .topbar.navbar-light .navbar-nav .nav-item .nav-link {
        color: #d1d3e2;
    }

    .topbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
        color: #b7b9cc;
    }

    .topbar.navbar-light .navbar-nav .nav-item .nav-link:active {
        color: var(--secondary);
    }

    .sidebar {
        width: 6.5rem;
        min-height: 100vh;
    }

    .sidebar .nav-item {
        position: relative;
    }

    .sidebar .nav-item:last-child {
        margin-bottom: 1rem;
    }

    .sidebar .nav-item .nav-link {
        text-align: center;
        padding: 0.75rem 1rem;
        width: 6.5rem;
    }

    .sidebar .nav-item .nav-link span {
        font-size: 0.65rem;
        display: block;
    }

    .sidebar .nav-item.active .nav-link {
        font-weight: 700;
    }

    .sidebar .nav-item .collapse {
        position: absolute;
        left: calc(6.5rem + 1.5rem / 2);
        z-index: 1;
        top: 2px;
    }

    .sidebar .nav-item .collapse .collapse-inner {
        border-radius: 1.35rem;
        -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
        box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    }

    .sidebar .nav-item .collapsing {
        display: none;
        -webkit-transition: none;
        transition: none;
    }

    .sidebar .nav-item .collapse .collapse-inner,
    .sidebar .nav-item .collapsing .collapse-inner {
        padding: 0.5rem 0;
        min-width: 10rem;
        font-size: 0.85rem;
        margin: 0 0 1rem 0;
    }

    .sidebar .nav-item .collapse .collapse-inner .collapse-header,
    .sidebar .nav-item .collapsing .collapse-inner .collapse-header {
        margin: 0;
        white-space: nowrap;
        padding: 0.5rem 1.5rem;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 0.65rem;
        color: #b7b9cc;
    }

    .sidebar .nav-item .collapse .collapse-inner .collapse-item,
    .sidebar .nav-item .collapsing .collapse-inner .collapse-item {
        padding: 0.5rem 1rem;
        margin: 0 0.5rem;
        display: block;
        color: #3a3b45;
        text-decoration: none;
        border-radius: 1.35rem;
        white-space: nowrap;
    }

    .sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
    .sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
        background-color: #eaecf4;
    }

    .sidebar .nav-item .collapse .collapse-inner .collapse-item:active,
    .sidebar .nav-item .collapsing .collapse-inner .collapse-item:active {
        background-color: #dddfeb;
    }

    .sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
    .sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
        color: var(--primary);
        font-weight: 700;
    }

    .sidebar #sidebarToggle {
        width: 2.5rem;
        height: 2.5rem;
        text-align: center;
        margin-bottom: 1rem;
        cursor: pointer;
    }

    .sidebar #sidebarToggle::after {
        font-weight: 900;
        content: "\\f104";
        font-family: "Font Awesome 5 Free";
        margin-right: 0.1rem;
    }

    .sidebar #sidebarToggle:hover {
        text-decoration: none;
    }

    .sidebar #sidebarToggle:focus {
        outline: none;
    }

    .sidebar.toggled {
        width: 0 !important;
        overflow: hidden;
    }

    .sidebar.toggled #sidebarToggle::after {
        content: "\\f105";
        font-family: "Font Awesome 5 Free";
        margin-left: 0.25rem;
    }

    .sidebar .sidebar-brand {
        height: 4.375rem;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 800;
        padding: 1.5rem 1rem;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
        z-index: 1;
    }

    .sidebar .sidebar-brand .sidebar-brand-icon i {
        font-size: 2rem;
    }

    .sidebar .sidebar-brand .sidebar-brand-text {
        display: none;
    }

    .sidebar hr.sidebar-divider {
        margin: 0 1rem 1rem;
    }

    .sidebar .sidebar-heading {
        text-align: center;
        padding: 0 1rem;
        font-weight: 800;
        font-size: 0.65rem;
    }

    @media (min-width: 900px) {
        .sidebar {
            width: 14rem !important;
        }
        .sidebar .nav-item .collapse {
            position: relative;
            left: 0;
            z-index: 1;
            top: 0;
            -webkit-animation: none;
            animation: none;
        }
        .sidebar .nav-item .collapse .collapse-inner {
            border-radius: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
        .sidebar .nav-item .collapsing {
            display: block;
            -webkit-transition: height 0.15s ease;
            transition: height 0.15s ease;
        }
        .sidebar .nav-item .collapse,
        .sidebar .nav-item .collapsing {
            margin: 0 1rem;
        }
        .sidebar .nav-item .nav-link {
            display: block;
            width: 100%;
            text-align: left;
            padding: 1rem;
            width: 14rem;
        }
        .sidebar .nav-item .nav-link i {
            font-size: 0.85rem;
            margin-right: 0.25rem;
        }
        .sidebar .nav-item .nav-link span {
            font-size: 0.85rem;
            display: inline;
        }
        .sidebar .nav-item .nav-link[data-toggle="collapse"]::after {
            width: 1rem;
            text-align: center;
            float: right;
            vertical-align: 0;
            border: 0;
            font-weight: 900;
            content: "\\f107";
            font-family: "Font Awesome 5 Free";
        }
        .sidebar .nav-item .nav-link[data-toggle="collapse"].collapsed::after {
            content: "\\f105";
        }
        .sidebar .sidebar-brand .sidebar-brand-icon i {
            font-size: 2rem;
        }
        .sidebar .sidebar-brand .sidebar-brand-text {
            display: inline;
        }
        .sidebar .sidebar-heading {
            text-align: left;
        }
        .sidebar.toggled {
            overflow: visible;
            width: 6.5rem !important;
        }
        .sidebar.toggled .nav-item .collapse {
            position: absolute;
            left: calc(6.5rem + 1.5rem / 2);
            z-index: 1;
            top: 2px;
            -webkit-animation-name: growIn;
            animation-name: growIn;
            -webkit-animation-duration: 200ms;
            animation-duration: 200ms;
            -webkit-animation-timing-function: transform
                    cubic-bezier(0.18, 1.25, 0.4, 1),
                opacity cubic-bezier(0, 1, 0.4, 1);
            animation-timing-function: transform
                    cubic-bezier(0.18, 1.25, 0.4, 1),
                opacity cubic-bezier(0, 1, 0.4, 1);
        }
        .sidebar.toggled .nav-item .collapse .collapse-inner {
            -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
            box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
            border-radius: 1.35rem;
        }
        .sidebar.toggled .nav-item .collapsing {
            display: none;
            -webkit-transition: none;
            transition: none;
        }
        .sidebar.toggled .nav-item .collapse,
        .sidebar.toggled .nav-item .collapsing {
            margin: 0;
        }
        .sidebar.toggled .nav-item:last-child {
            margin-bottom: 1rem;
        }
        .sidebar.toggled .nav-item .nav-link {
            text-align: center;
            padding: 0.75rem 1rem;
            width: 6.5rem;
        }
        .sidebar.toggled .nav-item .nav-link span {
            font-size: 0.65rem;
            display: block;
        }
        .sidebar.toggled .nav-item .nav-link i {
            margin-right: 0;
        }
        .sidebar.toggled .nav-item .nav-link[data-toggle="collapse"]::after {
            display: none;
        }
        .sidebar.toggled .sidebar-brand .sidebar-brand-icon i {
            font-size: 2rem;
        }
        .sidebar.toggled .sidebar-brand .sidebar-brand-text {
            display: none;
        }
        .sidebar.toggled .sidebar-heading {
            text-align: center;
        }
    }

    @media (max-width: 550px) {
        .sidebar {
            width: 0px !important;
            overflow: hidden;
        }
        .sidebar.open {
            width: 6.5rem !important;
        }
    }
    @media (max-width: 900px) {
        .sidebar.close {
            width: 0px !important;
            overflow: hidden;
        }
        .sidebar.open {
            width: 6.5rem !important;
        }
    }

    .sidebar-light .sidebar-brand {
        color: #6e707e;
    }

    .sidebar-light hr.sidebar-divider {
        border-top: 1px solid #eaecf4;
    }

    .sidebar-light .sidebar-heading {
        color: #b7b9cc;
    }

    .sidebar-light .nav-item .nav-link {
        color: var(--secondary);
    }

    .sidebar-light .nav-item .nav-link i {
        color: #d1d3e2;
    }

    .sidebar-light .nav-item .nav-link:active,
    .sidebar-light .nav-item .nav-link:focus,
    .sidebar-light .nav-item .nav-link:hover {
        color: #6e707e;
    }

    .sidebar-light .nav-item .nav-link:active i,
    .sidebar-light .nav-item .nav-link:focus i,
    .sidebar-light .nav-item .nav-link:hover i {
        color: #6e707e;
    }

    .sidebar-light .nav-item .nav-link[data-toggle="collapse"]::after {
        color: #b7b9cc;
    }

    .sidebar-light .nav-item.active .nav-link {
        color: #6e707e;
    }

    .sidebar-light .nav-item.active .nav-link i {
        color: #6e707e;
    }

    .sidebar-light #sidebarToggle {
        background-color: #eaecf4;
    }

    .sidebar-light #sidebarToggle::after {
        color: #b7b9cc;
    }

    .sidebar-light #sidebarToggle:hover {
        background-color: #dddfeb;
    }

    .sidebar-dark .sidebar-brand {
        color: var(--white);
    }

    .sidebar-dark hr.sidebar-divider {
        border-top: 1px solid rgba(255, 255, 255, 0.15);
    }

    .sidebar-dark .sidebar-heading {
        color: rgba(255, 255, 255, 0.4);
    }

    .sidebar-dark .nav-item .nav-link {
        color: rgba(255, 255, 255, 0.8);
    }

    .sidebar-dark .nav-item .nav-link i {
        color: rgba(255, 255, 255, 0.3);
    }

    .sidebar-dark .nav-item .nav-link:active,
    .sidebar-dark .nav-item .nav-link:focus,
    .sidebar-dark .nav-item .nav-link:hover {
        color: var(--white);
    }

    .sidebar-dark .nav-item .nav-link:active i,
    .sidebar-dark .nav-item .nav-link:focus i,
    .sidebar-dark .nav-item .nav-link:hover i {
        color: var(--white);
    }

    .sidebar-dark .nav-item .nav-link[data-toggle="collapse"]::after {
        color: rgba(255, 255, 255, 0.5);
    }

    .sidebar-dark .nav-item.active .nav-link {
        color: var(--white);
    }

    .sidebar-dark .nav-item.active .nav-link i {
        color: var(--white);
    }

    .sidebar-dark #sidebarToggle {
        background-color: rgba(255, 255, 255, 0.2);
    }

    .sidebar-dark #sidebarToggle::after {
        color: rgba(255, 255, 255, 0.5);
    }

    .sidebar-dark #sidebarToggle:hover {
        background-color: rgba(255, 255, 255, 0.25);
    }

    .sidebar-dark.toggled #sidebarToggle::after {
        color: rgba(255, 255, 255, 0.5);
    }

    .btn-circle {
        border-radius: 100%;
        height: 2.5rem;
        width: 2.5rem;
        font-size: 1rem;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .btn-circle.btn-sm,
    .btn-group-sm > .btn-circle.btn {
        height: 1.8rem;
        width: 1.8rem;
        font-size: 0.75rem;
    }

    .btn-circle.btn-lg,
    .btn-group-lg > .btn-circle.btn {
        height: 3.5rem;
        width: 3.5rem;
        font-size: 1.35rem;
    }

    .btn-icon-split {
        padding: 0;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        overflow: hidden !important;
        height: 38px !important;
        max-height: 38px !important;
        white-space: nowrap;
        min-width: 250px !important;
        width: 270px !important;
        max-width: 270px !important;
    }

    .btn-icon-split .icon {
        background: rgba(0, 0, 0, 0.15);
        display: inline-block;
        padding: 0.375rem 0.75rem;
        border-bottom-left-radius: 1.35rem;
        border-top-left-radius: 1.35rem;
    }

    .btn-icon-split .text {
        display: inline-block;
        padding: 0.375rem 0.75rem;
        text-overflow: ellipsis;
    }

    .btn-icon-split.btn-sm .icon,
    .btn-group-sm > .btn-icon-split.btn .icon {
        padding: 0.25rem 0.5rem;
    }

    .btn-icon-split.btn-sm .text,
    .btn-group-sm > .btn-icon-split.btn .text {
        padding: 0.25rem 0.5rem;
    }

    .btn-icon-split.btn-lg .icon,
    .btn-group-lg > .btn-icon-split.btn .icon {
        padding: 0.5rem 1rem;
    }

    .btn-icon-split.btn-lg .text,
    .btn-group-lg > .btn-icon-split.btn .text {
        padding: 0.5rem 1rem;
    }

    .card .card-header .dropdown {
        line-height: 1;
    }

    .card .card-header .dropdown .dropdown-menu {
        line-height: 1.5;
    }

    .card .card-header[data-toggle="collapse"] {
        text-decoration: none;
        position: relative;
        padding: 0.75rem 3.25rem 0.75rem 1.25rem;
    }

    .card .card-header[data-toggle="collapse"]::after {
        position: absolute;
        right: 0;
        top: 0;
        padding-right: 1.725rem;
        line-height: 51px;
        font-weight: 900;
        content: "\\f107";
        font-family: "Font Awesome 5 Free";
        color: #d1d3e2;
    }

    .card .card-header[data-toggle="collapse"].collapsed {
        border-radius: 1.35rem;
    }

    .card .card-header[data-toggle="collapse"].collapsed::after {
        content: "\\f105";
    }

    .chart-area {
        position: relative;
        height: 10rem;
        width: 100%;
    }

    @media (min-width: 768px) {
        .chart-area {
            height: 20rem;
        }
    }

    .chart-bar {
        position: relative;
        height: 10rem;
        width: 100%;
    }

    @media (min-width: 768px) {
        .chart-bar {
            height: 20rem;
        }
    }

    .chart-pie {
        position: relative;
        height: 15rem;
        width: 100%;
    }

    @media (min-width: 768px) {
        .chart-pie {
            height: calc(20rem - 43px) !important;
        }
    }

    .bg-impressum-image {
        background: url("/images/login.svg");
        background-position: center;
        background-size: cover;
    }

    .bg-login-image {
        background: url("/images/login.svg");
        background-position: center;
        background-size: cover;
    }

    .error {
        color: var(--danger);
        font-size: 7rem;
        position: relative;
        line-height: 1;
        width: 12.5rem;
    }

    @-webkit-keyframes noise-anim {
        0% {
            clip: rect(89px, 9999px, 5px, 0);
        }
        5% {
            clip: rect(92px, 9999px, 71px, 0);
        }
        10% {
            clip: rect(63px, 9999px, 67px, 0);
        }
        15% {
            clip: rect(85px, 9999px, 47px, 0);
        }
        20% {
            clip: rect(76px, 9999px, 50px, 0);
        }
        25% {
            clip: rect(99px, 9999px, 10px, 0);
        }
        30% {
            clip: rect(76px, 9999px, 83px, 0);
        }
        35% {
            clip: rect(5px, 9999px, 45px, 0);
        }
        40% {
            clip: rect(3px, 9999px, 11px, 0);
        }
        45% {
            clip: rect(1px, 9999px, 87px, 0);
        }
        50% {
            clip: rect(56px, 9999px, 79px, 0);
        }
        55% {
            clip: rect(12px, 9999px, 54px, 0);
        }
        60% {
            clip: rect(38px, 9999px, 21px, 0);
        }
        65% {
            clip: rect(5px, 9999px, 15px, 0);
        }
        70% {
            clip: rect(20px, 9999px, 75px, 0);
        }
        75% {
            clip: rect(14px, 9999px, 33px, 0);
        }
        80% {
            clip: rect(70px, 9999px, 79px, 0);
        }
        85% {
            clip: rect(68px, 9999px, 78px, 0);
        }
        90% {
            clip: rect(96px, 9999px, 69px, 0);
        }
        95% {
            clip: rect(16px, 9999px, 95px, 0);
        }
        100% {
            clip: rect(96px, 9999px, 60px, 0);
        }
    }

    @keyframes noise-anim {
        0% {
            clip: rect(89px, 9999px, 5px, 0);
        }
        5% {
            clip: rect(92px, 9999px, 71px, 0);
        }
        10% {
            clip: rect(63px, 9999px, 67px, 0);
        }
        15% {
            clip: rect(85px, 9999px, 47px, 0);
        }
        20% {
            clip: rect(76px, 9999px, 50px, 0);
        }
        25% {
            clip: rect(99px, 9999px, 10px, 0);
        }
        30% {
            clip: rect(76px, 9999px, 83px, 0);
        }
        35% {
            clip: rect(5px, 9999px, 45px, 0);
        }
        40% {
            clip: rect(3px, 9999px, 11px, 0);
        }
        45% {
            clip: rect(1px, 9999px, 87px, 0);
        }
        50% {
            clip: rect(56px, 9999px, 79px, 0);
        }
        55% {
            clip: rect(12px, 9999px, 54px, 0);
        }
        60% {
            clip: rect(38px, 9999px, 21px, 0);
        }
        65% {
            clip: rect(5px, 9999px, 15px, 0);
        }
        70% {
            clip: rect(20px, 9999px, 75px, 0);
        }
        75% {
            clip: rect(14px, 9999px, 33px, 0);
        }
        80% {
            clip: rect(70px, 9999px, 79px, 0);
        }
        85% {
            clip: rect(68px, 9999px, 78px, 0);
        }
        90% {
            clip: rect(96px, 9999px, 69px, 0);
        }
        95% {
            clip: rect(16px, 9999px, 95px, 0);
        }
        100% {
            clip: rect(96px, 9999px, 60px, 0);
        }
    }

    .error:after {
        content: attr(data-text);
        position: absolute;
        left: 2px;
        text-shadow: -1px 0 var(--danger);
        top: 0;
        color: var(--gray-dark);
        background: var(--light);
        overflow: hidden;
        clip: rect(0, 900px, 0, 0);
        animation: noise-anim 2s infinite linear alternate-reverse;
    }

    @-webkit-keyframes noise-anim-2 {
        0% {
            clip: rect(50px, 9999px, 32px, 0);
        }
        5% {
            clip: rect(32px, 9999px, 76px, 0);
        }
        10% {
            clip: rect(28px, 9999px, 55px, 0);
        }
        15% {
            clip: rect(64px, 9999px, 11px, 0);
        }
        20% {
            clip: rect(23px, 9999px, 53px, 0);
        }
        25% {
            clip: rect(69px, 9999px, 48px, 0);
        }
        30% {
            clip: rect(84px, 9999px, 66px, 0);
        }
        35% {
            clip: rect(66px, 9999px, 70px, 0);
        }
        40% {
            clip: rect(21px, 9999px, 86px, 0);
        }
        45% {
            clip: rect(70px, 9999px, 85px, 0);
        }
        50% {
            clip: rect(40px, 9999px, 4px, 0);
        }
        55% {
            clip: rect(62px, 9999px, 47px, 0);
        }
        60% {
            clip: rect(17px, 9999px, 97px, 0);
        }
        65% {
            clip: rect(57px, 9999px, 94px, 0);
        }
        70% {
            clip: rect(78px, 9999px, 85px, 0);
        }
        75% {
            clip: rect(97px, 9999px, 48px, 0);
        }
        80% {
            clip: rect(8px, 9999px, 31px, 0);
        }
        85% {
            clip: rect(14px, 9999px, 17px, 0);
        }
        90% {
            clip: rect(92px, 9999px, 92px, 0);
        }
        95% {
            clip: rect(95px, 9999px, 63px, 0);
        }
        100% {
            clip: rect(49px, 9999px, 11px, 0);
        }
    }

    @keyframes noise-anim-2 {
        0% {
            clip: rect(50px, 9999px, 32px, 0);
        }
        5% {
            clip: rect(32px, 9999px, 76px, 0);
        }
        10% {
            clip: rect(28px, 9999px, 55px, 0);
        }
        15% {
            clip: rect(64px, 9999px, 11px, 0);
        }
        20% {
            clip: rect(23px, 9999px, 53px, 0);
        }
        25% {
            clip: rect(69px, 9999px, 48px, 0);
        }
        30% {
            clip: rect(84px, 9999px, 66px, 0);
        }
        35% {
            clip: rect(66px, 9999px, 70px, 0);
        }
        40% {
            clip: rect(21px, 9999px, 86px, 0);
        }
        45% {
            clip: rect(70px, 9999px, 85px, 0);
        }
        50% {
            clip: rect(40px, 9999px, 4px, 0);
        }
        55% {
            clip: rect(62px, 9999px, 47px, 0);
        }
        60% {
            clip: rect(17px, 9999px, 97px, 0);
        }
        65% {
            clip: rect(57px, 9999px, 94px, 0);
        }
        70% {
            clip: rect(78px, 9999px, 85px, 0);
        }
        75% {
            clip: rect(97px, 9999px, 48px, 0);
        }
        80% {
            clip: rect(8px, 9999px, 31px, 0);
        }
        85% {
            clip: rect(14px, 9999px, 17px, 0);
        }
        90% {
            clip: rect(92px, 9999px, 92px, 0);
        }
        95% {
            clip: rect(95px, 9999px, 63px, 0);
        }
        100% {
            clip: rect(49px, 9999px, 11px, 0);
        }
    }

    .error:before {
        content: attr(data-text);
        position: absolute;
        left: -2px;
        text-shadow: 1px 0 var(--primary);
        top: 0;
        color: var(--danger);
        background: var(--light);
        overflow: hidden;
        clip: rect(0, 900px, 0, 0);
        animation: noise-anim-2 3s infinite linear alternate-reverse;
    }

    footer.sticky-footer {
        padding: 2rem 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }

    footer.sticky-footer .copyright {
        line-height: 1;
        font-size: 0.8rem;
    }

    body.sidebar-toggled footer.sticky-footer {
        width: 100%;
    }

    .dataTables_length {
        padding-top: 5px;
        float: left;
    }
    .overlay {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgb(255 255 255 / 50%);
        z-index: 100;
    }

    .overlay_inner {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 105;
    }

    .overlay_content {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 110;
    }
`;
