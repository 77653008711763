import { showToast } from "../algo-app";
import errorHandler from "../errorHandling.js";
import { translate } from "../../../../src/i18n.js";
export const FETCH_BAUSTELLE_RESET_SUCCESS = "FETCH_BAUSTELLE_RESET_SUCCESS";

//#region Baustelle
export const FETCH_BAUSTELLENAUSWEIS_EINSTELLUNGEN_ACTION_BEGIN =
    "FETCH_BAUSTELLENAUSWEIS_EINSTELLUNGEN_ACTION_BEGIN";
export const FETCH_BAUSTELLENAUSWEIS_EINSTELLUNGEN_ACTION_FAIL =
    "FETCH_BAUSTELLENAUSWEIS_EINSTELLUNGEN_ACTION_FAIL";
export const FETCH_BAUSTELLENAUSWEIS_EINSTELLUNGEN_SUCCESS =
    "FETCH_BAUSTELLENAUSWEIS_EINSTELLUNGEN_SUCCESS";
//#endregion

//#region Gesperrtes Personal
export const FETCH_BAUSTELLE_GESPERRTES_PERSONAL_ACTION_BEGIN =
    "FETCH_BAUSTELLE_GESPERRTES_PERSONAL_ACTION_BEGIN";
export const FETCH_BAUSTELLE_GESPERRTES_PERSONAL_ACTION_FAIL =
    "FETCH_BAUSTELLE_GESPERRTES_PERSONAL_ACTION_FAIL";
export const FETCH_BAUSTELLE_GESPERRTES_PERSONAL_SUCCESS =
    "FETCH_BAUSTELLE_GESPERRTES_PERSONAL_SUCCESS";
//#endregion

//#region Abgleaufene Baustellenasuweise
export const FETCH_BAUSTELLE_ABGELAUFENE_BAUSTELLENAUSWEISE_ACTION_BEGIN =
    "FETCH_BAUSTELLE_ABGELAUFENE_BAUSTELLENAUSWEISE_ACTION_BEGIN";
export const FETCH_BAUSTELLE_ABGELAUFENE_BAUSTELLENAUSWEISE_ACTION_FAIL =
    "FETCH_BAUSTELLE_ABGELAUFENE_BAUSTELLENAUSWEISE_ACTION_FAIL";
export const FETCH_BAUSTELLE_ABGELAUFENE_BAUSTELLENAUSWEISE_ACTION_SUCCESS =
    "FETCH_BAUSTELLE_ABGELAUFENE_BAUSTELLENAUSWEISE_ACTION_SUCCESS";
export const VERLAENGERE_BAUSTELLENAUSWEIS_ACTION_BEGIN =
    "VERLAENGERE_BAUSTELLENAUSWEIS_ACTION_BEGIN";
export const VERLAENGERE_BAUSTELLENAUSWEIS_ACTION_SUCCESS =
    "VERLAENGERE_BAUSTELLENAUSWEIS_ACTION_SUCCESS";
export const VERLAENGERE_BAUSTELLENAUSWEIS_ACTION_FAIL =
    "VERLAENGERE_BAUSTELLENAUSWEIS_ACTION_FAIL";
//#endregion

//#region BaustellenasuweiseSucehn
export const FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_SUCHEN_ACTION_BEGIN =
    "FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_SUCHEN_ACTION_BEGIN";
export const FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_SUCHEN_ACTION_FAIL =
    "FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_SUCHEN_ACTION_FAIL";
export const FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_SUCHEN_SUCCESS =
    "FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_SUCHEN_PERSONAL_SUCCESS";
//#endregion

//#region BaustellenasuweiseGesamt
export const FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_GESAMT_ACTION_BEGIN =
    "FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_GESAMT_ACTION_BEGIN";
export const FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_GESAMT_ACTION_FAIL =
    "FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_GESAMT_ACTION_FAIL";
export const FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_GESAMT_SUCCESS =
    "FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_GESAMT_SUCCESS";
//#endregion

//#region Zutrittspunkte
export const FETCH_BAUSTELLE_ZUTRITTSPUNKTE_ACTION_BEGIN =
    "FETCH_BAUSTELLE_ZUTRITTSPUNKTE_ACTION_BEGIN";
export const FETCH_BAUSTELLE_ZUTRITTSPUNKTE_ACTION_FAIL =
    "FETCH_BAUSTELLE_ZUTRITTSPUNKTE_ACTION_FAIL";
export const FETCH_BAUSTELLE_ZUTRITTSPUNKTE_SUCCESS =
    "FETCH_BAUSTELLE_ZUTRITTSPUNKTE_SUCCESS";
//#endregion

//#region Zeitzonen
export const FETCH_BAUSTELLE_ZEITZONEN_ACTION_BEGIN =
    "FETCH_BAUSTELLE_ZEITZONEN_ACTION_BEGIN";
export const FETCH_BAUSTELLE_ZEITZONEN_ACTION_FAIL =
    "FETCH_BAUSTELLE_ZEITZONEN_ACTION_FAIL";
export const FETCH_BAUSTELLE_ZEITZONEN_SUCCESS =
    "FETCH_BAUSTELLE_ZEITZONEN_SUCCESS";
//#endregion

//#region AktuelleBesetzung
export const FETCH_BAUSTELLE_AKTUELLE_BESETZUNG_ACTION_BEGIN =
    "FETCH_BAUSTELLE_AKTUELLE_BESETZUNG_ACTION_BEGIN";
export const FETCH_BAUSTELLE_AKTUELLE_BESETZUNG_ACTION_FAIL =
    "FETCH_BAUSTELLE_AKTUELLE_BESETZUNG_ACTION_FAIL";
export const FETCH_BAUSTELLE_AKTUELLE_BESETZUNG_SUCCESS =
    "FETCH_BAUSTELLE_AKTUELLE_BESETZUNG_SUCCESS";
//#endregion

//#region PersonalAuswertungen
export const FETCH_BAUSTELLE_PERSONAL_AUSWERTUNGEN_ACTION_BEGIN =
    "FETCH_BAUSTELLE_PERSONAL_AUSWERTUNGEN_ACTION_BEGIN";
export const FETCH_BAUSTELLE_PERSONAL_AUSWERTUNGEN_ACTION_FAIL =
    "FETCH_BAUSTELLE_PERSONAL_AUSWERTUNGEN_ACTION_FAIL";
export const FETCH_BAUSTELLE_PERSONAL_AUSWERTUNGEN_SUCCESS =
    "FETCH_BAUSTELLE_PERSONAL_AUSWERTUNGEN_SUCCESS";
//#endregion

//#region FirmenAuswertungen
export const FETCH_BAUSTELLE_FIRMEN_AUSWERTUNGEN_ACTION_BEGIN =
    "FETCH_BAUSTELLE_FIRMEN_AUSWERTUNGEN_ACTION_BEGIN";
export const FETCH_BAUSTELLE_FIRMEN_AUSWERTUNGEN_ACTION_FAIL =
    "FETCH_BAUSTELLE_FIRMEN_AUSWERTUNGEN_ACTION_FAIL";
export const FETCH_BAUSTELLE_FIRMEN_AUSWERTUNGEN_SUCCESS =
    "FETCH_BAUSTELLE_FIRMEN_AUSWERTUNGEN_SUCCESS";
//#endregion

//#region FirmenAuswertungen
export const FETCH_BAUSTELLE_BERECHTIGUNGSGRUPPEN_ACTION_BEGIN =
    "FETCH_BAUSTELLE_BERECHTIGUNGSGRUPPEN_ACTION_BEGIN";
export const FETCH_BAUSTELLE_BERECHTIGUNGSGRUPPEN_ACTION_FAIL =
    "FETCH_BAUSTELLE_BERECHTIGUNGSGRUPPEN_ACTION_FAIL";
export const FETCH_BAUSTELLE_BERECHTIGUNGSGRUPPEN_SUCCESS =
    "FETCH_BAUSTELLE_BERECHTIGUNGSGRUPPEN_SUCCESS";
//#endregion

//#region Bildverifizierung
export const FETCH_BAUSTELLE_BILDVERIFIZIERUNG_ACTION_BEGIN =
    "FETCH_BAUSTELLE_BILDVERIFIZIERUNG_ACTION_BEGIN";
export const FETCH_BAUSTELLE_BILDVERIFIZIERUNG_ACTION_FAIL =
    "FETCH_BAUSTELLE_BILDVERIFIZIERUNG_ACTION_FAIL";
export const FETCH_BAUSTELLE_BILDVERIFIZIERUNG_SUCCESS =
    "FETCH_BAUSTELLE_BILDVERIFIZIERUNG_SUCCESS";
//#endregion

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;
let abortBaustellenausweisEinstellungen,
    abortGesperrtesPersonal,
    abortAbgelaufeneBaustellenausweise,
    abortVerlaengereBaustellenausweisCommand,
    abortBaustellenausweisSuchen,
    abortBaustellenausweisGesamt,
    abortZutrittspunkte,
    abortZeitzonen,
    abortAktuelleBesetzung,
    abortPersonalAuswertungen,
    abortFirmenAuswertungen,
    abortBerechtigungsgruppen,
    abortBildverifizierung;

export const cancelBaustelleRequests = () => (dispatch) => {
    try {
        abortBaustellenausweisEinstellungen?.abort(),
            abortGesperrtesPersonal?.abort(),
            abortAbgelaufeneBaustellenausweise?.abort(),
            abortVerlaengereBaustellenausweisCommand?.abort(),
            abortBaustellenausweisSuchen?.abort(),
            abortBaustellenausweisGesamt?.abort(),
            abortZutrittspunkte?.abort(),
            abortZeitzonen?.abort(),
            abortAktuelleBesetzung?.abort(),
            abortPersonalAuswertungen?.abort(),
            abortFirmenAuswertungen?.abort(),
            abortBerechtigungsgruppen?.abort(),
            abortBildverifizierung?.abort();
    } catch (e) {
        console.log(e);
    }

    dispatch({ type: FETCH_BAUSTELLE_RESET_SUCCESS });
    appInsights.trackEvent({ name: "cancelBaustelleRequests" });
};

//#region Baustellenausweiseinstellungen
export const fetchBaustellenausweisEinstellungen =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("fetchBaustellenausweisEinstellungen");
        dispatch({
            type: FETCH_BAUSTELLENAUSWEIS_EINSTELLUNGEN_ACTION_BEGIN,
        });
        abortBaustellenausweisEinstellungen =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/mitarbeiter-baustellenausweis-einstellungen?mitarbeiterId=${data.mitarbeiterId}`,
            {
                method: "GET",
                signal: abortBaustellenausweisEinstellungen.signal,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_BAUSTELLENAUSWEIS_EINSTELLUNGEN_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent(
                    "fetchBaustellenausweisEinstellungen",
                    {
                        success: json.success,
                        error: { errorMessage: json },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_BAUSTELLENAUSWEIS_EINSTELLUNGEN_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent(
                    "fetchBaustellenausweisEinstellungen",
                    {
                        success: response.ok,
                        error: { errorMessage: response },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_BAUSTELLENAUSWEIS_EINSTELLUNGEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("fetchBaustellenausweisEinstellungen", {
                success: json.success,
            });
            return json.payload;
        }
    };
//#endregion

//#region Gesperrtes Personal
export const fetchGesperrtesPersonal = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchGesperrtesPersonal");
    dispatch({ type: FETCH_BAUSTELLE_GESPERRTES_PERSONAL_ACTION_BEGIN });
    abortGesperrtesPersonal =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-baustelle-baustellenausweise-entsperren`,
        { method: "GET", signal: abortGesperrtesPersonal.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_BAUSTELLE_GESPERRTES_PERSONAL_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchGesperrtesPersonal", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_BAUSTELLE_GESPERRTES_PERSONAL_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchGesperrtesPersonal", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_BAUSTELLE_GESPERRTES_PERSONAL_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchGesperrtesPersonal", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

//#region Abgelaufene Baustellenausweise von Personal
export const fetchAbgelaufeneBaustellenausweise =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("fetchGesperrtesPersonal");
        dispatch({
            type: FETCH_BAUSTELLE_ABGELAUFENE_BAUSTELLENAUSWEISE_ACTION_BEGIN,
        });
        abortAbgelaufeneBaustellenausweise =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-baustelle-baustellenausweise-verlaengern`,
            { method: "GET", signal: abortAbgelaufeneBaustellenausweise.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_BAUSTELLE_ABGELAUFENE_BAUSTELLENAUSWEISE_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent("fetchGesperrtesPersonal", {
                    success: json.success,
                    error: { errorMessage: json },
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_BAUSTELLE_ABGELAUFENE_BAUSTELLENAUSWEISE_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent("fetchGesperrtesPersonal", {
                    success: response.ok,
                    error: { errorMessage: response },
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_BAUSTELLE_ABGELAUFENE_BAUSTELLENAUSWEISE_ACTION_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("fetchGesperrtesPersonal", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const verlaengereBaustellenausweisCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("verlaengereBaustellenausweisCommand");
        dispatch({ type: VERLAENGERE_BAUSTELLENAUSWEIS_ACTION_BEGIN });
        abortVerlaengereBaustellenausweisCommand =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${projektId}/verlaengere-mitarbeiter-gueltigkeitsdauer/`,
            {
                method: "POST",
                body: formData,
                signal: abortVerlaengereBaustellenausweisCommand.signal,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: VERLAENGERE_BAUSTELLENAUSWEIS_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "verlaengereBaustellenausweisCommand",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: VERLAENGERE_BAUSTELLENAUSWEIS_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "verlaengereBaustellenausweisCommand",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: VERLAENGERE_BAUSTELLENAUSWEIS_ACTION_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("verlaengereBaustellenausweisCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };
//#endregion

//#region BaustellenausweisSuchen
export const fetchBaustellenausweiseSuchen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchBaustellenausweiseSuchen");
    dispatch({ type: FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_SUCHEN_ACTION_BEGIN });
    abortBaustellenausweisSuchen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-baustelle-baustellenausweise-suchen`,
        { method: "GET", signal: abortBaustellenausweisSuchen.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_SUCHEN_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchBaustellenausweiseSuchen", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_SUCHEN_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchBaustellenausweiseSuchen", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_SUCHEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchBaustellenausweiseSuchen", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

//#region BaustellenausweisGesamt
export const fetchBaustellenausweiseGesamt = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchBaustellenausweiseGesamt");
    dispatch({ type: FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_GESAMT_ACTION_BEGIN });
    abortBaustellenausweisGesamt =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-baustelle-baustellenausweise`,
        { method: "GET", signal: abortBaustellenausweisGesamt.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_GESAMT_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchBaustellenausweiseGesamt", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_GESAMT_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchBaustellenausweiseGesamt", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_GESAMT_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchBaustellenausweiseGesamt", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

//#region Zutrittspunkte
export const fetchZutrittspunkte = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchZutrittspunkte");
    dispatch({ type: FETCH_BAUSTELLE_ZUTRITTSPUNKTE_ACTION_BEGIN });
    abortZutrittspunkte =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-baustelle-zutrittspunkte`,
        { method: "GET", signal: abortZutrittspunkte.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_BAUSTELLE_ZUTRITTSPUNKTE_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchZutrittspunkte", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_BAUSTELLE_ZUTRITTSPUNKTE_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchZutrittspunkte", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_BAUSTELLE_ZUTRITTSPUNKTE_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchZutrittspunkte", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

//#region Zeitzonen
export const fetchZeitzonen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchZeitzonen");
    dispatch({ type: FETCH_BAUSTELLE_ZEITZONEN_ACTION_BEGIN });
    abortZeitzonen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-baustelle-zeitzonen`,
        { method: "GET", signal: abortZeitzonen.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_BAUSTELLE_ZEITZONEN_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchZeitzonen", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_BAUSTELLE_ZEITZONEN_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchZeitzonen", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_BAUSTELLE_ZEITZONEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchZeitzonen", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

//#region
export const fetchAktuelleBezetzung = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchAktuelleBezetzung");
    dispatch({ type: FETCH_BAUSTELLE_AKTUELLE_BESETZUNG_ACTION_BEGIN });
    abortAktuelleBesetzung =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-baustelle-aktuelle-besetzung`,
        { method: "GET", signal: abortAktuelleBesetzung.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_BAUSTELLE_AKTUELLE_BESETZUNG_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchAktuelleBezetzung", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_BAUSTELLE_AKTUELLE_BESETZUNG_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchAktuelleBezetzung", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_BAUSTELLE_AKTUELLE_BESETZUNG_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchAktuelleBezetzung", {
            success: json.success,
        });
        return json.payload;
    }
};

export const fetchAktuelleBezetzungExport = (data) => (dispatch) => {
    dispatch(showToast({ text: translate("DASHBOARD.EXPORT_TOAST") }));
    const a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-baustelle-aktuelle-besetzung-export?projektName=${data.projektName}&raumzonenName=${data.raumzonenName}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "fetchAktuelleBezetzungExport" });
};
//#endregion

//#region PersonalAuswertungen
export const fetchPersonalAuswertungen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchPersonalAuswertungen");
    dispatch({ type: FETCH_BAUSTELLE_PERSONAL_AUSWERTUNGEN_ACTION_BEGIN });
    abortPersonalAuswertungen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-baustelle-personal-auswertungen?datum=${data.datum}&raumzonenName=${data.raumzonenName}`,
        { method: "GET", signal: abortPersonalAuswertungen.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_BAUSTELLE_PERSONAL_AUSWERTUNGEN_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchPersonalAuswertungen", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_BAUSTELLE_PERSONAL_AUSWERTUNGEN_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchPersonalAuswertungen", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_BAUSTELLE_PERSONAL_AUSWERTUNGEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchPersonalAuswertungen", {
            success: json.success,
        });
        return json.payload;
    }
};

export const fetchPersonalAuswertungenExport = (data) => (dispatch) => {
    dispatch(showToast({ text: translate("DASHBOARD.EXPORT_TOAST") }));
    const a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-baustelle-personal-auswertungen-export?projektName=${data.projektName}&startdatum=${data.startzeit}&enddatum=${data.endzeit}&raumzonenName=${data.raumzonenName}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "fetchPersonalAuswertungenExport" });
};
//#endregion

//#region PersonalAuswertungen
export const fetchFirmenAuswertungen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchFirmenAuswertungen");
    dispatch({ type: FETCH_BAUSTELLE_FIRMEN_AUSWERTUNGEN_ACTION_BEGIN });
    abortFirmenAuswertungen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-baustelle-firmen-auswertungen?datum=${data.datum}&raumzonenName=${data.raumzonenName}`,
        { method: "GET", signal: abortFirmenAuswertungen.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_BAUSTELLE_FIRMEN_AUSWERTUNGEN_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchFirmenAuswertungen", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_BAUSTELLE_FIRMEN_AUSWERTUNGEN_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchFirmenAuswertungen", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_BAUSTELLE_FIRMEN_AUSWERTUNGEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchFirmenAuswertungen", {
            success: json.success,
        });
        return json.payload;
    }
};

export const fetchFirmenAuswertungenExport = (data) => (dispatch) => {
    dispatch(showToast({ text: translate("DASHBOARD.EXPORT_TOAST") }));
    const a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-baustelle-firmen-auswertungen-export?projektName=${data.projektName}&startdatum=${data.startzeit}&enddatum=${data.endzeit}&raumzonenName=${data.raumzonenName}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "fetchFirmenAuswertungenExport" });
};
//#endregion
//#region Berechtigungsgruppen
export const fetchBerechtigungsgruppen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchBerechtigungsgruppen");
    dispatch({ type: FETCH_BAUSTELLE_BERECHTIGUNGSGRUPPEN_ACTION_BEGIN });
    abortBerechtigungsgruppen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dashboard-baustelle-berechtigungsgruppen`,
        { method: "GET", signal: abortBerechtigungsgruppen.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_BAUSTELLE_BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchBerechtigungsgruppen", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_BAUSTELLE_BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchBerechtigungsgruppen", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_BAUSTELLE_BERECHTIGUNGSGRUPPEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchBerechtigungsgruppen", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion
