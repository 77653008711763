import { LitElement, css } from "lit";
import { html } from "lit/html.js";
import { setPassiveTouchGestures } from "@polymer/polymer/lib/utils/settings.js";
import { connect } from "pwa-helpers/connect-mixin.js";

import { store } from "./store.js";

import { SbAdmin2Styles } from "./css/sb-admin-2-styles.js";
import { AlgoStyles } from "./css/algo-styles.js";
import { FontawesomeIcons } from "./css/fontawesome-icons.js";

import {
    navigate,
    resetToast,
    setProjektId,
    ALGO_APP_NAVIGATE_BACK,
} from "./rdx/actions/algo-app.js";
import { fetchBenutzerprofil } from "./rdx/actions/identity.js";
import { fetchProjekt } from "./rdx/actions/projekt/projekt.js";

import algoApp from "./rdx/reducers/algo-app.js";
import identity from "./rdx/reducers/identity.js";
store.addReducers({
    algoApp,
    identity,
});
import { init } from "./i18n.js";
import "@polymer/paper-toast";

import "./layouts/sb-admin-2/sb-admin-2-layout.js";
import "./layouts/blank/blank-layout.js";
import "./bs/components/bs-toast";

class AlgoApp extends connect(store)(LitElement) {
    static get styles() {
        return [
            SbAdmin2Styles,
            AlgoStyles,
            FontawesomeIcons,
            css`
                .layout {
                    display: none;
                }

                .layout[active] {
                    display: block;
                }

                paper-toast {
                    width: 300px;
                    margin-left: calc(50vw - 150px);
                }
            `,
        ];
    }

    static get properties() {
        return {
            _layout: { type: String },
            _fetchBenutzerprofilActive: { type: Boolean },
        };
    }

    render() {
        return html`
            <sb-admin-2-layout
                class="layout"
                ?active="${this._layout === "sb-admin-2-layout"}"
            ></sb-admin-2-layout>
            <blank-layout
                class="layout"
                ?active="${this._layout === "blank-layout"}"
            ></blank-layout>
            <bs-toast></bs-toast>
        `;
    }

    constructor() {
        super();

        this._layout = "";
        this._fetchBenutzerprofilActive = true;

        setPassiveTouchGestures(true);
        store.dispatch(fetchBenutzerprofil());

        window.onpopstate = (PopStateEvent) => {
            if (PopStateEvent.state === null) {
                PopStateEvent.preventDefault();
                window.onbeforeunload = function () {
                    store.dispatch(
                        navigate({
                            view: "algo-dashboard-view",
                            layout: "sb-admin-2-layout",
                        })
                    );
                    return true;
                };
            } else if (PopStateEvent.state.view === "identity-anmelden-view") {
                PopStateEvent.preventDefault();
            } else {
                store.dispatch(
                    navigate(PopStateEvent.state, ALGO_APP_NAVIGATE_BACK)
                );
            }
        };
    }

    _initState(identity) {
        if (identity.benutzerprofil.isAuthenticated) {
            const savedState = localStorage.getItem("savedState");
            if (savedState) {
                const algoApp = JSON.parse(savedState).algoApp,
                    navigator = algoApp.navigator,
                    projektId = algoApp.projektId;

                if (projektId) {
                    store.dispatch(setProjektId(projektId));
                    store.dispatch(fetchProjekt(projektId));
                }
                if (navigator.view && navigator.layout) {
                    store.dispatch(navigate(navigator));
                } else {
                    store.dispatch(
                        navigate({
                            view: "algo-dashboard-view",
                            layout: "sb-admin-2-layout",
                        })
                    );
                }
            } else {
                store.dispatch(
                    navigate({
                        view: "algo-dashboard-view",
                        layout: "sb-admin-2-layout",
                    })
                );
            }
        } else {
            store.dispatch(
                navigate({
                    view: "identity-anmelden-view",
                    layout: "blank-layout",
                })
            );
        }
    }

    firstUpdated() {
        const gifElement = document.querySelector("div.gif");
        if (gifElement) gifElement.remove();
        if (!i18next.isInitialized) {
            init();
        }
    }

    updated(changedProps) {
        if (changedProps.has("_layout")) {
            const body = document.querySelector("body"),
                bodyClass = "bg-gradient-primary";

            if (this._layout === "sb-admin-2-layout") {
                if (body.classList.contains(bodyClass))
                    body.classList.remove(bodyClass);
            } else if (this._layout === "blank-layout") {
                if (!body.classList.contains(bodyClass))
                    body.classList.add(bodyClass);
            }
        }
    }

    stateChanged(state) {
        const identity = state.identity,
            toastState = state.algoApp.toast,
            stateAsJson = JSON.stringify(state);
        this._layout = state.algoApp.navigator.layout;

        if (this._fetchBenutzerprofilActive && !identity.loading) {
            this._fetchBenutzerprofilActive = false;
            this._initState(identity);
        }

        if (!this._fetchBenutzerprofilActive) {
            try {
                localStorage.setItem("savedState", stateAsJson);
            } catch (error) {
                console.log(error);
            }
        }

        if (toastState && toastState.text) {
            const toast = this.shadowRoot.querySelector("bs-toast");
            if (toast) {
                toast.text = toastState.text;
                toast.type = toastState?.type ? toastState.type : "";
                toast.show();
                store.dispatch(resetToast());
            }
        }
    }
}

window.customElements.define("algo-app", AlgoApp);
