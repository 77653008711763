export const PROJEKTBETEILIGTER_ACTION_FAIL = "PROJEKTBETEILIGTER_ACTION_FAIL";
export const PROJEKTBETEILIGTER_ACTION_BEGIN =
    "PROJEKTBETEILIGTER_ACTION_BEGIN";
export const FETCH_PROJEKTBETEILIGTER_SUCCESS =
    "FETCH_PROJEKTBETEILIGTER_SUCCESS";
export const RESET_PROJEKTBETEILIGTER_SUCCESS =
    "RESET_PROJEKTBETEILIGTER_SUCCESS";
export const LOESCHE_PROJEKTBETEILIGTEN_COMMAND_SUCCESS =
    "LOESCHE_PROJEKTBETEILIGTEN_COMMAND_SUCCESS";
export const AKTUALISIERE_PROJEKTBETEILIGTEN_COMMAND_SUCCESS =
    "AKTUALISIERE_PROJEKTBETEILIGTEN_COMMAND_SUCCESS";

import errorHandler from "../errorHandling.js";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;

export const resetProjektbeteiligter = () => (dispatch) => {
    dispatch({ type: RESET_PROJEKTBETEILIGTER_SUCCESS });
    appInsights.trackEvent({ name: "resetProjektbeteiligter" });
};

export const fetchProjektbeteiligter = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchProjektbeteiligter");
    dispatch({ type: PROJEKTBETEILIGTER_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/projekt/v2/${data.projektId}/${data.projektbeteiligterId}/projektbeteiligter.json`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: PROJEKTBETEILIGTER_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("fetchProjektbeteiligter", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: PROJEKTBETEILIGTER_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("fetchProjektbeteiligter", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_PROJEKTBETEILIGTER_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchProjektbeteiligter", {
            success: json.success,
        });
    }
};

export const loescheProjektbeteiligtenCommand =
    (data, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("loescheProjektbeteiligtenCommand");
        dispatch({ type: PROJEKTBETEILIGTER_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/projekt/${projektId}/loesche-projektbeteiligten/`,
            {
                method: "POST",
                body: data,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: PROJEKTBETEILIGTER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent("loescheProjektbeteiligtenCommand", {
                    success: json.success,
                    error: json,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: PROJEKTBETEILIGTER_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent("loescheProjektbeteiligtenCommand", {
                    success: response.ok,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: LOESCHE_PROJEKTBETEILIGTEN_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("loescheProjektbeteiligtenCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const aktualisiereProjektbeteiligtenCommand =
    (data, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("aktualisiereProjektbeteiligtenCommand");
        dispatch({ type: PROJEKTBETEILIGTER_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/projekt/${projektId}/aktualisiere-projektbeteiligten/`,
            {
                method: "POST",
                body: data,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: PROJEKTBETEILIGTER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent(
                    "aktualisiereProjektbeteiligtenCommand",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: PROJEKTBETEILIGTER_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "aktualisiereProjektbeteiligtenCommand",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: AKTUALISIERE_PROJEKTBETEILIGTEN_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "aktualisiereProjektbeteiligtenCommand",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };
