export const TRANSLATIONS_DE_NEWS = {
    ABBRECHEN: "Abbrechen",
    ABFRAGE_NACHRICHT_ARCHIVIEREN: "Möchten Sie die Nachricht wirklich löschen",
    ALLE: "Alle",
    AVISIERUNG: "Avisierung",
    BEARBEITER: "Bearbeitende Person",
    BEARBEITERDATUM: "Bearbeitungsdatum",
    BEARBEITUNGS_MODAL: "Beim Klicken wird ein Modal zum Bearbeiten geöffnet",
    BEDEUTUNG_BLACKLIST: "Projektbeteiligten-E-Mails die der BlackList hinzugefügt werden, bekommen keine E-Mails vom News-Modul",
    BILD: "Bild",
    BITTE_MODUL_AUSWAEHLEN: "Bitte Modul auswählen",
    BITTE_NACHRICHT_EINGEBEN: "Bitte Nachricht eingeben",
    BITTE_PROJEKTBETILIGTER_AUSWAEHLEN: "Bitte Projektbeteiligter auswählen",
    BITTE_TITEL_EINGEBEN: "Bitte Titel eingeben",
    CONTAINER: "Container",
    DASHBOARD: "Dashboard",
    EINSTELLUNGEN: "Einstellungen",
    ERSTELLER: "Erstellende Person",
    ERSTELLDATUM: "Erstellungsdatum",
    ID_NOT_FOUND: "Id konnte nicht gefunden werden",
    JA: "Ja",
    KEINE_EMAIL_BLACKLIST: "Bisher wurde keine E-Mail zur Blacklist hinzugefügt.",
    KEINE_NACHRICHTEN: "Das Projekt hat noch keine Nachrichten.",
    KONFIGURATION: "Konfiguration",
    KONFIGURATION_ERFOLGREICH_AKTUALISIERT: "Konfiguration erfolgreich aktualisiert",
    LOESCH_MODAL: "Beim Klicken wird ein Modal zum Löschen geöffnet",
    MAIL: "E-Mail",
    MAIL_KONFIGURATION_SPEICHERN: "E-Mail-Konfiguration speichern",
    MODUL: "Modul",
    NACHRICHT: "Nachricht",
    NACHRICHT_AKTUALISIEREN: "Nachricht aktualisieren",
    NACHRICHT_ARCHIVIEREN: "Nachricht archivieren",
    NACHRICHT_ERFOLGREICH_AKTUALISIERT:
        "Nachricht wurde erfolgreich aktualisiert",
    NACHRICHT_ERFOLGREICH_ARCHIVIERT: "Nachricht wurde erfolgreich archiviert",
    NACHRICHT_ERFOLGREICH_ERSTELLT: "Nachricht wurde erfolgreich erstellt",
    NACHRICHT_ERSTELLEN: "Nachricht erstellen",
    NACHRICHTEN: "Nachrichten",
    NEIN: "Nein",
    NEWS: "News",
    PROJEKT: "Projekt",
    PROJEKTBETEILIGTE: "Projektbeteiligte",
    PROJEKTNAME: "Projektname",
    TITEL: "Titel",
    UEBERSICHT: "Übersicht",
    WARNUNG: "Warnung",
    ZUTRITTSKONTROLL: "Zutrittskontroll",
};