export const TRANSLATION_EN_DATENSCHUTZ = {
    BETROFFENRECHT: {
        TEIL1_LISTE1_ANFANG: "A ",
        TEIL1_LISTE1_ENDE:
            "among other things about the categories of" +
            "data processed, the processing purposes, the storage period" +
            "as well as any recipients, in accordance with Art. 15 DSGVO and § 34 BDSG.",
        TEIL1_LISTE1_TEILFETT: "Right of access to information",
        TEIL1_LISTE2_ANFANG: "A ",
        TEIL1_LISTE2_ENDE:
            "incorrect or incomplete data, in accordance with Art. 16 and 17 DSGVO and § 35 BDSG.",
        TEIL1_LISTE2_TEILFETT: "Right to rectification or deletion ",
        TEIL1_LISTE3_ANFANG:
            "Under the conditions of Art. 18 DSGVO or § 35 para. 1 p. 2 BDSG a",
        TEIL1_LISTE3_TEILFETT: "Right to restriction of processing  ",
        TEIL1_LISTE4_ANFANG: "A ",
        TEIL1_LISTE4_ENDE:
            "against processing pursuant to Art. 21" +
            "Para.1 DSGVO, insofar as the data processing was carried out on the basis of a" +
            "legitimate interest.",
        TEIL1_LISTE4_TEILFETT: "Right of objection",
        TEIL1_LISTE5_ANFANG: "A ",
        TEIL1_LISTE5_ENDE:
            "a given consent with" +
            "Effect for the future in accordance with Art. 7 (3) DSGVO.",
        TEIL1_LISTE5_TEILFETT: "Right of withdrawal  ",
        TEIL1_LISTE6_ANFANG: "A ",
        TEIL1_LISTE6_ENDE:
            "in a common format in accordance with Art. 20 DSGVO.",
        TEIL1_LISTE6_TEILFETT: "Right to data portability ",
        TEIL1_LISTE7_ANFANG: " You have according to Art. 22 DSGVO a ",
        TEIL1_LISTE7_ENDE:
            "that has legal effect against you or affects you in a similar way" +
            "significantly affects in a similar way. This also includes" +
            "profiling within the meaning of Art. 4 No. 4 DSGVO.",
        TEIL1_LISTE7_TEILFETT:
            "right not to be subject to a decision based solely on" +
            "automated processing based decision.",
        TEIL1_LISTE8_ANFANG: "You also have the right to complain to a  ",
        TEIL1_LISTE8_ENDE:
            "about the processing of your  " +
            "personal data by us, in particular in the  " +
            "Member State of your habitual residence, of your  " +
            "place of work or of the alleged infringement.",
        TEIL1_LISTE8_TEILFETT: "Data protection supervisory authority ",
        TEIL1_TEXT:
            "As a data subject, you have the following rights against us " +
            "regarding your personal data. You have:",
        TEIL1_UEBERSCHRIFT: "4.1 Your rights as a data subject",
        TEIL2_TEXT:
            "If you assert your rights under the DSGVO and the BDSG against us " +
            "the data you transmit to us in the process, we will use this data for the " +
            "process in order to fulfill your claim. Subsequently we store " +
            "the data you send to us and the data we send to you in the " +
            "countermove transmitted data for the purpose of documentation until the expiry of the " +
            "the statute of limitations under regulatory law (3 years). The " +
            "The legal basis for the processing and storage of the data is Art.. " +
            "6 para. 1 p. 1 letter f) DSGVO (Legitimate interest in the " +
            "data processing). The legitimate interest arises from our " +
            "obligation to comply with your request and the need to be able to exonerate ourselves in a  " +
            "possible fine proceedings by proving that we have " +
            "that we have properly complied with your request. The  " +
            "processing of your data on the basis of our legitimate interest  " +
            "you can object at any time under the conditions of Art. 21 DSGVO  " +
            "To do so, please use the contact details provided in the imprint " +
            "However, we would like to point out that the processing of your " +
            "data to prove compliance with the data subject rights is mandatory in the  " +
            "in the sense of Article 21 (1) of the GDPR, as other means of proof  " +
            "do not exist or are not equally suitable.",
        TEIL2_UEBERSCHRIFTT: "4.2 Procedure",
        UEBERSCHRIFT: "4. AFFECTED RIGHTS",
    },
    DATENSCHUTZMAßNAHMEN: {
        TEXT:
            "We secure our website and other systems - and thus also your  " +
            "data - by technical and organizational measures against loss, " +
            "destruction, access, modification or distribution by unauthorized parties " +
            "In particular, your personal data will be encrypted  " +
            "when transmitted through the Internet. We make use of the " +
            "coding system TLS (Transport Layer Security). However, the  " +
            "transmission of information over the Internet is never completely secure, " +
            "which is why we cannot guarantee the security of the " +
            "data transmitted by our website.",
        UEBERSCHRIFT: "5. DATA PROTECTION MEASURES",
    },
    DATENSCHUTZ_ALLGEMEINES: {
        INFORMATION: "I. Privacy information",
        TEXT:
            "Compliance with data protection laws is for cpc baulogistik GmbH  " +
            "not only a legal obligation, but also an important  " +
            "trust factor. With the following data protection provisions  " +
            "to inform you transparently about the nature, scope and purpose of the " +
            "collected and processed personal data within this " +
            "website and your rights. ",
        UEBERSCHRIFT: "1. GENERAL",
    },
    DATENSCHUTZ_BEAUFTRAGTER: {
        TEIL0: "The responsible data protection officer is",
        TEIL1: "Süddeutsche Datenschutzgesellschaft GmbH",
        TEIL2: "Contact person",
        UEBERSCHRIFT: "3. DATA PROTECTION OFFICER",
    },
    DATENSCHUTZ_VERANTWORTLICHKEIT: {
        TEIL1: "The cpc baulogistik GmbH, Hauptstraße 65, 12159 Berlin, Germany (hereinafter referred to as 'We') operates the web portal.",
        TEIL2: ", responsible party according to Article 4(7) of the EU General Data Protection Regulation (GDPR). If you have any questions, you can contact",
        TEIL3: ".",
        UEBERSCHRIFT: "2. RESPONSIBILITY FOR DATA PROCESSING",
    },
    DATENVERARBEITUNG: {
        TEIL1_TEXT:
            "We process your personal data insofar as it is necessary for the  " +
            "establishment, content or amendment of a  " +
            "contractual relationship between us and you." +
            "(inventory data). Inventory data can be in particular: Name, title, " +
            "contact data (postal address, telephone, e-mail address), date of birth, etc. " +
            "Furthermore, we process your usage data. Usage data is data that " +
            "through your behavior when using our web offer and our " +
            "services, in particular your IP address, the beginning and end of your " +
            "visit to our Website and information about what content you " +
            "accessed on our Website. The aforementioned data is collected " +
            "either directly from you (e.g., by visiting the Website) " +
            "or, insofar as this is permissible under data protection laws, from third parties " +
            "or from publicly accessible sources (e.g. commercial register, " +
            "register of associations, press, media, internet).",
        TEIL1_UEBERSCHRIFT: "6.1 Sources and categories of personal data",
        TEIL2_TEXT:
            "All information we receive from you or about you is generally processed " +
            "on servers within the European Union. The transmission of your data to or " +
            "processing of your data in third countries occurs without your explicit " +
            "consent only if it is legally provided or permitted, and if the third country " +
            "ensures an adequate level of data protection, or if there are contractual obligations " +
            "through the so-called Standard Contractual Clauses of the EU. Regarding data transfers " +
            "to the USA, the European Commission has issued an adequacy decision known as the EU-U.S. " +
            "Data Privacy Framework, ensuring an adequate level of protection for the transfer of " +
            "personal data by companies participating in the EU-US Data Privacy Framework. When we use " +
            "services that transmit personal data to the USA, it is indicated by the respective service " +
            "whether the company is certified under the EU-U.S. Data Privacy Framework.",
        TEIL2_UEBERSCHRIFT:
            "6.2 Data transfer to third countries outside the EU",
        TEIL3_TEXT:
            "We never disclose your personal data to third parties without authorization " +
            " from you. However, we may disclose your data to third parties, " +
            "if you have consented to the data transfer, if the transfer is necessary " +
            "to fulfill our legal obligations, or if we are authorized or " +
            "obligated to disclose the data due to legal provisions or governmental or " +
            "judicial orders. This may include providing information for purposes of law enforcement, " +
            "emergency response, or enforcement of intellectual property rights. Additionally, " +
            "we may transmit your data to external service providers who process data on our behalf " +
            "and under our instructions (data processors) to simplify or relieve our own " +
            "data processing. Each data processor is obligated by a contract in accordance with Art. 28 GDPR. This means, in particular, that the " +
            "data processor must provide sufficient guarantees that appropriate technical and organizational " +
            "measures are implemented, ensuring that the processing is in line with " +
            "the requirements of the GDPR and the protection of your rights as " +
            "the data subject is ensured. Despite engaging data processors, we remain the responsible entity for the processing of your " +
            "personal data in accordance with " +
            "data protection laws.",
        TEIL3_UEBERSCHRIFT: "6.3 Disclosure of data, order processing",
        TEIL4_TEXT:
            "As a matter of principle, we use the data only for the purpose for which the data  " +
            "was collected from you. We may process the data for another purpose  " +
            "unless this other purpose is incompatible with the original  " +
            "purpose is incompatible (Art. 5 para. 1 lit. c) DSGVO).",
        TEIL4_UEBERSCHRIFT: "6.4 Purpose of data processing",
        TEIL5_TEXT:
            "Unless otherwise specified in detail, we store data collected from  " +
            "you only as long as it is necessary for the respective purpose," +
            "unless there are legal obligations to retain data " +
            "e.g. from commercial law or tax law.",
        TEIL5_UEBERSCHRIFT: "6.5 Storage duration",
        UEBERSCHRIFT: "6. MODALITIES OF DATA PROCESSING",
    },
    DIENST_DRITTER: {
        TEIL1_TEIL1_TEXT1:
            "We use the service 'Visual Studios' provided by Microsoft Ireland Operations Limited, One " +
            "Microsoft Place, South County Business Park, Leopardstown, Dublin 18 D18 P521, Ireland. Microsoft " +
            "Ireland Operations Limited is a subsidiary of Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA.",
        TEIL1_TEIL1_TEXT2:
            "It is not excluded that Microsoft may transfer data to the USA. Microsoft Corporation complies with the requirements " +
            "of the 'EU-U.S. Data Privacy Framework.' The Privacy Framework agreement regulates the protection of personal data " +
            "transferred from a member state of the European Union to the USA. It ensures that the transferred data is subject to a " +
            "level of data protection comparable to that of the European Union. You can access the list of certified companies here:",
        TEIL1_TEIL1_TEXT3:
            "For more information on how user data is handled, please refer to Microsoft's privacy policy:",
        TEIL1_TEIL1_UEBERSCHRIFT: "8.1.1 Microsoft Visual Studios",
        TEIL1_TEIL2_TEIL1_TEXT1:
            "In order to ensure the reliability and stability of our web portal " +
            "we use the service Microsoft Insights of the company" +
            "Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA. " +
            "Through this, data is transmitted to the provider and is usually " +
            "stored there, e.g. your IP address, product and version information  " +
            "about the browser and operating system used (so-called user agent), " +
            "the Internet page from which your access took place (so-called referer), " +
            "the date and time of the request and possibly your Internet service provider." +
            "The legal basis for the use of the service is Art. 6 para. 1 p. 1 " +
            "Letter f) DSGVO (legitimate interest in data processing). " +
            "We pursue with the service the interest to constantly maintain our web portal " +
            "and to keep it available. The processing of your data on " +
            "basis of our legitimate interest, you can at any time under the " +
            "conditions of Art. 21 DSGVO. To do so, please use " +
            "please use the contact details provided in the imprint.",
        TEIL1_TEIL2_TEIL1_TEXT2:
            "More information about the " +
            "handling of user data can be found in the privacy policy " +
            "Microsoft:",
        TEIL1_TEIL2_TEIL1_TEXT3: "and",
        TEIL1_TEIL2_TEIL1_TEXT4: "Status of the data protection regulations:",
        TEIL1_TEIL2_TEIL1_TEXT5: "Source",
        TEIL1_TEIL2_TEIL1_UEBERSCHRIFT: "8.2.1 Jira Issue Collector",
        TEIL1_TEIL2_UEBERSCHRIFT: "8.2 Cloud applications",
        TEIL1_TEXT1:
            "Our website is based on a so-called website builder. This is a service that allows us, " +
            "without deep knowledge of website programming, to offer a website by simplifying the " +
            "creation and maintenance of the website through a cloud-based user interface.",
        TEIL1_TEXT2:
            "The term 'cloud-based' means that the maintenance interface is provided to us by " +
            "a third-party on their servers. When using this application, personal data is generated " +
            "at the third-party, which they can process on our behalf according to our and their privacy " +
            "policies. At the same time, the provider typically provides the infrastructure needed to operate the website.",
        TEIL1_TEXT3:
            "Every time the application is accessed, general information from your browser is automatically " +
            "transmitted to the server (so-called server log files). For more information on this, " +
            "see above under 'Server Log Files'.",
        TEIL1_TEXT2:
            "The term 'cloud-based' means that the maintenance interface is provided to us by a third party on " +
            "their servers. When using this application, personal data is generated at the third party's end, " +
            "which they can process on our behalf according to our and their privacy policies. At the same time, " +
            "the provider typically supplies the infrastructure needed to operate the website.",
        TEIL1_TEXT3:
            "Every time the application is accessed, general information is automatically transmitted from your " +
            "browser to the server (so-called server log files). For more information, see above under 'Server Log Files.'",
        TEIL1_TEXT4:
            "The legal basis for the use of cloud-based website builders with hosting services and the associated " +
            "processing of your data is Art. 6(1) sentence 1 letter f) GDPR (Legitimate interest in data processing), " +
            "unless otherwise stated for the respective service. The legitimate interest arises from our need for a " +
            "technically flawless presentation of our website, without requiring in-depth knowledge of website " +
            "programming and IT system maintenance. You can object to the processing of your data based on our " +
            "legitimate interest at any time under the conditions of Art. 21 GDPR. Please use the contact details " +
            "provided in the imprint for this purpose.",
        TEIL1_TEXT5: "We use:",
        TEIL1_UEBERSCHRIFT: "8.1 Website Builders",
        TEIL1:
            "For the simplification of our data processing and to expand the functionality of our website, " +
            "we utilize services/resources from third parties, such as plugins, external content, software, " +
            "or other external service providers (services). In this process, personal data may be transmitted " +
            "to the service provider. To protect your data, we have contractually obligated the service providers, " +
            "if necessary, in accordance with Art. 28 GDPR, to process your data only according to our instructions.",
        TEIL2:
            "We explicitly point out that we are regularly only responsible for the data collection and transmission " +
            "by the service in terms of GDPR, but not for any subsequent processing by the respective service provider.",
        TEIL2_TEXT1:
            "Our website makes use of cloud-based applications. Cloud-based applications include, for example, webshops " +
            "provided by a third party on their servers for us. When you use these applications, personal data is generated " +
            "at the third-party provider, which they can process on our behalf according to our and their privacy policies.",
        TEIL2_TEXT2:
            "Upon each use of the application, general information is automatically transmitted from your browser to " +
            "the server (so-called server log files). For more information on this, please see above under 'Server Log Files'.",
        TEIL2_TEXT3:
            "The legal basis for the use of cloud applications and the transmission of your data to them is Art. 6 (1) sentence " +
            "1 letter f) GDPR (Legitimate interest in data processing), unless otherwise stated for the respective service. " +
            "The legitimate interest arises from our need for a technically flawless and fast presentation of our website " +
            "and the relief of our IT infrastructure. You can object to the processing of your data based on our legitimate " +
            "interest at any time under the conditions of Art. 21 GDPR. Please use the contact details provided " +
            "in the imprint for this purpose.",
        TEIL2_TEXT4: "We use:",
        TEIL2_TEIL1_TEXT1:
            "Our website uses the service 'Jira Issue Collector' provided by Atlassian Pty Ltd., Level 6, 341 George " +
            "Street, Sydney NSW 2000, Australia.",
        TEIL2_TEIL1_TEXT2:
            "Jira Issue Collector is used to generate custom forms for submitting issues without users having to " +
            "leave the page to report the issue.",
        TEIL2_TEIL1_TEXT3:
            "Through this service, we can collect information about issues easily, helping us to operate our website " +
            "steadily and improve user-friendliness.",
        TEIL2_TEIL1_TEXT4:
            "We have entered into a data processing agreement with the provider. To ensure an adequate level of " +
            "data protection during the transmission of data to Australia, we have concluded the EU Standard " +
            "Contractual Clauses with Atlassian.",
        TEIL2_TEIL1_TEXT5:
            "The legal basis for using Jira Issue Collector is Art. 6(1)(f) GDPR (Legitimate interest in data " +
            "processing). The legitimate interest arises from our need for a technically flawless and secure website, " +
            "and the ability for users to easily submit issues to us. You can object to the processing of your data " +
            "based on our legitimate interest at any time under the conditions of Art. 21 GDPR. Please use the contact " +
            "details provided in the imprint for this purpose.",
        TEIL2_TEIL1_TEXT6:
            "For more information on handling user data, please refer to Atlassian's privacy policy: ",
        TEIL3: "In detail, we use the following services:",
        TEIL3_TEXT1:
            "We use certain services that record data from website visitors and provide it to us for analysis. We use " +
            "this data to improve our website, services, and offerings based on usage. In particular, the following " +
            "information about visitor behavior may be collected: your IP address, product and version information " +
            "about the browser and operating system used (so-called user agent), the website from which your access " +
            "occurred (so-called referer), date and time of the request, and possibly your Internet service provider, " +
            "approximate origin (country and city), language, clicks on content, and viewing duration.",
        TEIL3_TEXT2:
            "The legal basis for using analytics tools is Art. 6(1)(a) GDPR (Consent of the data subject). We ask for " +
            "your consent to use the service through a displayed notice text when you first visit the website. You can " +
            "withdraw your consent at any time with effect for the future by calling up the cookie banner again and " +
            "changing the settings you made.",
        TEIL3_UEBERSCHRIFT: "8.3 Analytics Tools",
        TEIL4_TEXT1:
            "Our website uses the service 'Application Insights' provided by Microsoft Ireland Operations " +
            "Limited, One Microsoft Place, South County Business Park, Leopardstown, Dublin 18 D18 P521, " +
            "Ireland. Microsoft Ireland Operations Limited is a subsidiary of Microsoft Corporation, " +
            "One Microsoft Way, Redmond, WA 98052-6399, USA.",
        TEIL4_TEXT2:
            "The service allows us to analyze user behavior on the website. Data such as IP address, visit " +
            "frequency, location, and time of the request are collected and transmitted to Microsoft servers in the " +
            "USA. We use the anonymization function, whereby all collected data is anonymized immediately after " +
            "collection.",
        TEIL4_TEXT3:
            "Microsoft Corporation complies with the requirements of the 'EU-U.S. Data Privacy Framework.' " +
            "The Privacy Framework Agreement regulates the protection of personal data transferred from a " +
            "member state of the European Union to the USA. It ensures that the transferred data is subject to " +
            "a level of data protection comparable to that of the European Union. You can access the list of certified " +
            "companies here",
        TEIL4_TEXT4:
            "When using the service, cookies are stored on the end device of the website visitor. We have " +
            "entered into a data processing agreement with the provider.",
        TEIL4_TEXT5:
            "For more information on privacy at Microsoft, please visit:",
        TEIL4_TEXT6: "Privacy Policy Status: ",
        TEIL4_UEBERSCHRIFT: "8.3.1 Microsoft Application Insights",
        UEBERSCHRIFT: "8. THIRD PARTY SERVICES",
    },
    NUTZUNGBEDINGUNGEN_UEBERSCHRITFT: "General terms of use",
    UEBERSCHRITFT: "Privacy policy",
    VERARBEITUNGSTAETIGKEITEN: {
        TABELLE_HEADER_COLUMN1: "Name",
        TABELLE_HEADER_COLUMN2: "Explanation",
        TABELLE_HEADER_COLUMN3: "Origin (Domain)",
        TABELLE_HEADER_COLUMN4: "Validity/ storage period",
        TABELLE_HEADER_COLUMN5: "Third party access",
        TABELLE_ROW1_COLUMN1: "Ai_session",
        TABELLE_ROW1_COLUMN2:
            "This cookie is used to recognize how many sessions of user activity " +
            "have involved certain pages and features of the app.",
        TABELLE_ROW1_COLUMN3: "algo.cpc-baulogistik.de",
        TABELLE_ROW1_COLUMN4: "30 minutes",
        TABELLE_ROW1_COLUMN5: "Yes, Microsoft",
        TABELLE_ROW2_COLUMN1: "Ai_user",
        TABELLE_ROW2_COLUMN2:
            "This cookie is used to determine how many people have used the app and its " +
            "features. Users are counted with anonymous IDs. ",
        TABELLE_ROW2_COLUMN3: "algo.cpc-baulogistik.de",
        TABELLE_ROW2_COLUMN4: "1 Year",
        TABELLE_ROW2_COLUMN5: "Yes, Microsoft",
        TABELLE_ROW3_COLUMN1: "ARRAffinity- SameSite",
        TABELLE_ROW3_COLUMN2:
            "This cookie is used to control the efficient loading of the website " +
            "and ensure that all user requests made during each browser session " +
            "are directed to the same server.",
        TABELLE_ROW3_COLUMN3: "algo.cpc-baulogistik.de",
        TABELLE_ROW3_COLUMN4: "Session end",
        TABELLE_ROW3_COLUMN5: "Yes, Microsoft",
        TABELLE_ROW4_COLUMN1: "Token",
        TABELLE_ROW4_COLUMN2:
            "These cookies serve the security of the website and the protection of the user " +
            "against CSRF (Cross-Site-Request-Forgery) attacks by appending a unique 'token' to " +
            "each request from the client to the server, ensuring that the request comes from the client. " +
            "The individual cookies listed here are set during the execution of the specific action " +
            "(e.g., newsletter registration, profile changes, button clicks in the form) " +
            "and serve the CSRF protection of the respective specific action.",
        TABELLE_ROW4_COLUMN3: "algo.cpc-baulogistik.de",
        TABELLE_ROW4_COLUMN4: "Session end",
        TABELLE_ROW4_COLUMN5: "No",
        TABELLE_NEW_ROW4_COLUMN1: "ARRAffinity",
        TABELLE_NEW_ROW4_COLUMN2:
            "This cookie is used to distribute traffic on the website across multiple " +
            "servers to optimize response times.",
        TABELLE_NEW_ROW4_COLUMN3: "algo.cpc-baulogistik.de",
        TABELLE_NEW_ROW4_COLUMN4: "Session end",
        TABELLE_NEW_ROW4_COLUMN5: "Yes, Microsoft",
        TEIL1_TEXT:
            "Upon each call to a web portal and upon each retrieval of data from " +
            "a server, general information is automatically transmitted to the " +
            "providing server. This data transmission occurs automatically and is a fundamental part of communication " +
            "between devices on the internet. The data transmitted by default includes, among other things, the following information: your IP address, " +
            "product and version information about the browser and " +
            "the operating system used (so-called user agent), the webpage from which " +
            "your access took place (so-called referer), date and time of the request " +
            "(so-called timestamp). Additionally, the HTTP status and the transmitted " +
            "amount of data are captured within this request. These pieces of information " +
            "are logged by the server, stored in a table, and there " +
            "temporarily stored (so-called server log files). Through the analysis " +
            "of these log files, we can identify errors on the website and " +
            "subsequently rectify them, determine the website's load at specific " +
            "times, and make adjustments or improvements based on that, as well as ensure the server's security " +
            "by being able to trace from which IP address attacks on " +
            "our server were carried out. Your IP address is only stored for the " +
            "duration of your use of the website and immediately deleted or partially obscured by shortening thereafter. " +
            "The remaining data is stored for a limited period (usually " +
            "7 days). The legal basis for the use of " +
            "server log files is Art. 6 para. 1 sentence 1 letter f) GDPR " +
            "(Legitimate interest in data processing). The legitimate interest " +
            "arises from the necessity for the operation and maintenance of our website, as explained above. " +
            "You can object to the processing of your data based on our legitimate " +
            "interest at any time under the conditions of Art. " +
            "21 GDPR. Please use the contact details mentioned in the imprint for this purpose. However, we " +
            "would like to point out in advance that the processing of your data in server log files is mandatory " +
            "in the sense of Art. 21 para. 1 GDPR, as the website otherwise " +
            "cannot be operated at all.",
        TEIL1_UEBERSCHRIFT: "7.1 Server log files",
        TEIL2_TEIL1_TEXT1: "What are cookies",
        TEIL2_TEIL1_TEXT10:
            "In detail, our web portal sets the following cookies:",
        TEIL2_TEIL1_TEXT11: "Legal basis",
        TEIL2_TEIL1_TEXT12:
            "The legal basis for the use of cookies that are strictly necessary for the functioning " +
            "of the website (e.g., shopping cart cookie, session cookie) is Art. 6(1) sentence 1 letter f) " +
            "GDPR (Legitimate interest in data processing) and §25(2) No. 2 TTDSG (unconditional necessity for " +
            "providing a telemedia service expressly requested by the user). The legitimate interest arises from our need " +
            "to offer you a functioning website. Cookies are necessary because they are an integral " +
            "part of current internet technology, and without cookies, many functions of current websites would not be available. " +
            "Therefore, we need cookies to provide you with the website upon your request. You can object to the processing of your data based on " +
            "our legitimate interest at any time under the conditions of Art. 21 GDPR. " +
            "Please use the contact information provided in the imprint for this purpose. However, we would like to point out that the processing of your data in certain cookies " +
            "is mandatory within the meaning of Art. 21(1) GDPR, as the website cannot be operated otherwise, and we do not have the technical ability " +
            "to prevent the setting of cookies on certain individual devices. However, you may be able to do this in your browser settings. " +
            "For more information on this, please refer to your browser's instructions. " +
            "The legal basis for the use of cookies that are not strictly necessary for the functioning of the website is Art. 6(1) sentence 1 letter a) " +
            "GDPR (Consent of the data subject). We ask for your consent to the use of non-essential cookies when you first visit the website via a displayed information text. " +
            "You can revoke your consent at any time with effect for the future by accessing the cookie banner again and changing the settings you made.",
        TEIL2_TEIL1_TEXT2:
            "In a very simplified manner, a cookie is a small text file that stores data " +
            "about visited websites. Cookies can be used in various ways. For example, " +
            "they can store a kind of 'user profile,' including things like your preferred " +
            "language and other page settings required by our website to offer you " +
            "certain services. The cookie file is stored on your device and can also help " +
            "recognize you when you revisit our web portal.",
        TEIL2_TEIL1_TEXT3:
            "Under certain circumstances, through cookies, we may also obtain information " +
            "about your preferred activities on our website and tailor our website to " +
            "your individual interests or even increase the speed of navigation on our website.",
        TEIL2_TEIL1_TEXT4: "How to avoid cookies",
        TEIL2_TEIL1_TEXT5:
            "You can delete the cookies in the security settings of your browser " +
            "manually at any time.",
        TEIL2_TEIL1_TEXT6:
            "However, you can also prevent the storage of cookies by a " +
            "corresponding setting of your browser from the outset. " +
            "Please note, however, that you may then not be able to use all  " +
            "functions of our website or that there may be errors in the presentation " +
            "and use of the website may occur.",
        TEIL2_TEIL1_TEXT7: "Third party cookies",
        TEIL2_TEIL1_TEXT8:
            "It is possible that third-party providers, with the help of which we design our site " +
            "design and operate, in particular through so-called plugins (see " +
            "below in the section „Third-party services“), independently store their own cookies on " +
            "your terminal device. If you only want to accept our own cookies, but do not " +
            "do not want to accept cookies from these third parties, you can disable the " +
            "storage of these cookies through the appropriate browser setting  " +
            "„Block third-party cookies” .",
        TEIL2_TEIL1_TEXT9: "Which cookies are usedWhich cookies are used",
        TEIL2_TEIL1_UEBERSCHRIFT: "7.2.1 Cookies",
        TEIL2_TEIL2_TEXT1: "What is the web storage",
        TEIL2_TEIL2_TEXT2:
            "Web storage is a technique for web applications that stores data in " +
            "in a web browser. Web storage can be simplified " +
            "as a further development of cookies, but differs " +
            "from these, however, in some points.",
        TEIL2_TEIL2_TEXT3:
            "Unlike cookies, which can be accessed by both server and client, " +
            "web storage is completely controlled by the client. This " +
            "means that data is not transferred to the server with every call to the website. " +
            "Access takes place exclusively locally via scripts " +
            "on the website. In concrete terms, this means that third party access to  " +
            "the stored information via the website is not possible. " +
            "Only you and we can access the locally stored data. ",
        TEIL2_TEIL2_TEXT4: "Legal basis",
        TEIL2_TEIL2_TEXT5:
            "The legal basis for the use of web storage, which is strictly necessary for the " +
            "functionality of the website, is Article 6(1) sentence 1 letter f) GDPR (Legitimate interest in data processing). " +
            "and §25(2) No. 2 TTDSG (unconditional necessity for providing a telemedia service expressly requested by the user). " +
            "The legitimate interest arises from our need to provide you with a functioning website. Web storage is necessary for this because it is an integral part of current internet technology, and without it, many functions of current websites would not be available. Therefore, we need web storage to be able to provide you with the website upon your request. You can object to the processing of your data based on our legitimate interest at any time under the conditions of Article 21 GDPR. Please use the contact information provided in the legal notice for this purpose. However, we would like to point out that the processing of your data in web storage may be mandatory under Article 21(1) GDPR, as otherwise the website cannot be operated at all, and we do not have the technical ability to prevent usage on specific individual devices. You may be able to do this yourself in your browser. For more information on this, please refer to your browser's instructions." +
            "The legal basis for the use of web storage, which is not " +
            "strictly necessary for the functionality of the website, is Article 6(1) sentence 1 letter a) GDPR (Consent of the data subject). " +
            "We ask for your consent when you first visit the website through a displayed notification text. You can revoke your consent at any time with effect for the future by calling up the cookie banner again and changing the settings you made.",
        TEIL2_TEIL2_UEBERSCHRIFT: "7.2.2 Webstorage",
        TEIL2_TEXT:
            "To enhance user-friendliness on our website, we use so-called 'cookies' or the 'Web Storage' of your browser.",
        TEIL2_UEBERSCHRIFT: "7.2 Cookies and web storage",
        TEIL3_TEXT1:
            "Our website gives you the opportunity to register for additional, " +
            "personalized features of the site. Registration is only possible for users who have been pre-approved by cpc baulogistik " +
            "GmbH. During registration, personal data (e.g., email address and " +
            "name) is collected and processed for the purpose of providing personalized services.",
        TEIL3_TEXT2:
            "We require the following information from you to create an account:",
        TEIL3_TEXT3: "First name, last name, email address, company.",
        TEIL3_TEXT4:
            "The legal basis for processing mandatory data is Art. 6 para. 1 lit. " +
            "b) GDPR (data processing for the performance of a contract and for pre-contractual " +
            "measures). You also have the option to change or delete the data provided during the registration process at any time.",
        TEIL3_UEBERSCHRIFT: "7.5 Registration Area",
        TEIL4_TEXT1:
            "Our web portal offers the possibilities to manage access control for " +
            "construction sites. This is done exclusively within the framework of order processing for the respective client.",
        TEIL4_TEXT2:
            "The legal basis for the transmission of those data (surname, " +
            "first name, nationality, birthday, data on the " +
            "residence permit, data on employment relationship and other if necessary " +
            "personal data) which are required for the construction site access management " +
            "are necessary, is Art. 6 para. 1 p. 1 letter b) DSGVO " +
            "(data processing for the performance of a contract). ",
        TEIL4_TEXT3:
            "The data transmitted for access management will be stored for the duration " +
            "of the construction site and then deleted, unless there are  " +
            "legal obligations to retain data prevent deletion, e.g " +
            "from commercial law, tax law, the Employee Posting Act, " +
            "the Minimum Wage Act, or the German Federal Employment Act.",
        TEIL4_UEBERSCHRIFT: "7.4 Access control",
        TEXT:
            "In the following, we would like to show you as transparently as possible , " +
            "which data we process from you on which occasion, on which basis " +
            "and for what purpose.",
        UEBERSCHRIFT: "7. INDIVIDUAL PROCESSING ACTIVITIES",
        TEIL5_TEXT1:
            "Our portal offers the possibility to register transports directly with us. This is done " +
            "exclusively within the scope of order processing for the respective client.",
        TEIL5_TEXT2: "The following data is processed as part of the form:",
        TEIL5_TEXT3:
            "First name, last name, company, email, phone number, starting location, destination, license plate, " +
            "information about the transport, freight, and loading.",
        TEIL5_TEXT4:
            "The legal basis for the use of the data transmitted to us through the transport registration is Article 6(1) " +
            "sentence 1 letter b) GDPR (data processing for the fulfillment of a contract).",
        TEIL5_TEXT5:
            "The data transmitted to us during the transport registration will be stored for the duration of the construction site " +
            "and deleted afterwards, unless legal retention obligations prevent deletion, e.g., under commercial law, tax law, " +
            "the Posted Workers Act, the Minimum Wage Act, or the Act to Combat Undeclared Work.",
        TEIL5_UEBERSCHRIFTEN: "7.3 Transport Registration",
        TEIL6_TEXT1:
            "Our website is hosted on servers provided by external providers to ensure the efficient and secure provision " +
            "of the website.",
        TEIL6_TEXT2:
            "Upon each visit to the website, general information is automatically transmitted from your browser to " +
            "the server (so-called server log files). For more information, see above under ",
        TEIL6_TEXT3: "“Server Log Files”.",
        TEIL6_TEXT4:
            "The legal basis for the use of hosting services and the associated processing of your data is Article 6(1) " +
            "sentence 1 letter f) GDPR (legitimate interest in data processing), unless otherwise stated for the respective service. " +
            "The legitimate interest arises from our need for a technically flawless presentation of our website, without requiring " +
            "in-depth knowledge of website programming and IT system maintenance. You can object to the processing of your data " +
            "based on our legitimate interest at any time under the conditions of Article 21 GDPR. Please use the contact details " +
            "provided in the imprint for this purpose.",
        TEIL6_UEBERSCHRIFTEN: "7.6 Hosting Services",
    },
};
export default TRANSLATION_EN_DATENSCHUTZ;
