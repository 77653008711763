import errorHandler from "../errorHandling";

export const ZEITZONEN_ACTION_FAIL = "ZEITZONEN_ACTION_FAIL";
export const ZEITZONEN_ACTION_BEGIN = "ZEITZONEN_ACTION_BEGIN";
export const FETCH_ZEITZONEN_SUCCESS = "FETCH_ZEITZONEN_SUCCESS";
export const RESET_ZEITZONEN_SUCCESS = "RESET_ZEITZONEN_SUCCESS";
export const LOESCHE_ZEITZONEN_SUCCESS = "LOESCHE_ZEITZONEN_SUCCESS";
export const ERSTELLE_ZEITZONEN_SUCCESS = "ERSTELLE_ZEITZONEN_SUCCESS";
export const RESET_ZEITZONEN_ADMIN_SUCCESS = "RESET_ZEITZONEN_ADMIN_SUCCESS";
export const FETCH_ZEITZONEN_ADMIN_SUCCESS = "FETCH_ZEITZONEN_ADMIN_SUCCESS";
export const AKTUALISIERE_ZEITZONEN_SUCCESS = "AKTUALISIERE_ZEITZONEN_SUCCESS";
export const SYNCHRONISIERE_ZEITZONEN_SUCCESS =
    "SYNCHRONISIERE_ZEITZONEN_SUCCESS";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;

let abortZeitzonen;

export const resetZeitzonen = () => (dispatch) => {
    try {
        abortZeitzonen?.abort();
    } catch (e) {
        console.log(e);
    }
    dispatch({ type: RESET_ZEITZONEN_SUCCESS });
    appInsights.trackEvent({ name: "resetZukoZeitzonen" });
};

export const resetZeitzonenAdmin = () => (dispatch) => {
    dispatch({ type: RESET_ZEITZONEN_ADMIN_SUCCESS });
    appInsights.trackEvent({ name: "resetZukoZeitzonen" });
};

export const fetchZeitzonen = (projektId) => async (dispatch) => {
    appInsights.startTrackEvent("fetchZeitzonen");
    dispatch({ type: ZEITZONEN_ACTION_BEGIN });
    abortZeitzonen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${projektId}/zeitzonen/`,
        {
            method: "GET",
            signal: abortZeitzonen.signal,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: ZEITZONEN_ACTION_FAIL, error: json.payload });
            appInsights.stopTrackEvent("fetchZeitzonen", {
                success: json.success,
                error: json.payload,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: ZEITZONEN_ACTION_FAIL,
                error: { errorMessage: response.toString() },
            });
            appInsights.stopTrackEvent("fetchZeitzonen", {
                success: response.ok,
                error: { errorMessage: response.toString() },
            });
        }
    }
    if (json?.success) {
        dispatch({ type: FETCH_ZEITZONEN_SUCCESS, payload: json.payload });
        appInsights.stopTrackEvent("fetchZeitzonen", { success: json.success });
    }
};

export const fetchZeitzonenAdmin = (projektId) => async (dispatch) => {
    appInsights.startTrackEvent("fetchZeitzonenAdmin");
    dispatch({ type: ZEITZONEN_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/admin/v1/${projektId}/zeitzonen/`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: ZEITZONEN_ACTION_FAIL, error: json.payload });
            appInsights.stopTrackEvent("fetchZeitzonenAdmin", {
                success: json.success,
                error: json.payload,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: ZEITZONEN_ACTION_FAIL,
                error: { errorMessage: response.toString() },
            });
            appInsights.stopTrackEvent("fetchZeitzonenAdmin", {
                success: response.ok,
                error: { errorMessage: response.toString() },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_ZEITZONEN_ADMIN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchZeitzonenAdmin", {
            success: json.success,
        });
    }
};

export const synchronisiereZeitzonenCommand =
    (projektId) => async (dispatch) => {
        appInsights.startTrackEvent("synchronisiereZeitzonenCommand");
        dispatch({ type: ZEITZONEN_ACTION_BEGIN });
        const url = `/api/zuko/admin/v1/${projektId}/synchronisiere-zeitzonen`;
        const response = await fetch(url, {
            method: "POST",
        });

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(response));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: ZEITZONEN_ACTION_FAIL, error: json.payload });
                appInsights.stopTrackEvent("synchronisiereZeitzonenCommand", {
                    success: json.success,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: ZEITZONEN_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("synchronisiereZeitzonenCommand", {
                    success: response.ok,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: SYNCHRONISIERE_ZEITZONEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("synchronisiereZeitzonenCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const erstelleZeitzoneCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("erstelleZeitzoneCommand");
        dispatch({ type: ZEITZONEN_ACTION_BEGIN });
        const url = `/api/zuko/admin/v1/${projektId}/erstelle-zeitzone`;
        const response = await fetch(url, {
            method: "POST",
            body: formData,
        });
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: ZEITZONEN_ACTION_FAIL, error: json.payload });
                appInsights.stopTrackEvent("erstelleZeitzoneCommand", {
                    success: json.success,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: ZEITZONEN_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("erstelleZeitzoneCommand", {
                    success: response.ok,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: ERSTELLE_ZEITZONEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("erstelleZeitzoneCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const aktualisiereZeitzoneCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("aktualisiereZeitzoneCommand");
        dispatch({ type: ZEITZONEN_ACTION_BEGIN });
        const url = `/api/zuko/admin/v1/${projektId}/aktualisiere-zeitzone`;
        const response = await fetch(url, {
            method: "POST",
            body: formData,
        });
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response?.ok) {
            if (json && !json?.success) {
                dispatch(errorHandler(json));
                dispatch({ type: ZEITZONEN_ACTION_FAIL, error: json.payload });
                appInsights.stopTrackEvent("aktualisiereZeitzoneCommand", {
                    success: json.success,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: ZEITZONEN_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("aktualisiereZeitzoneCommand", {
                    success: response.ok,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: AKTUALISIERE_ZEITZONEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("aktualisiereZeitzoneCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const loescheZeitzoneCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("leoscheZeitzoneCommand");
        dispatch({ type: ZEITZONEN_ACTION_BEGIN });
        const url = `/api/zuko/admin/v1/${projektId}/loesche-zeitzone`;
        const response = await fetch(url, {
            method: "POST",
            body: formData,
        });
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: ZEITZONEN_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent("loescheZeitzoneCommand", {
                    success: json.success,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: ZEITZONEN_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("loescheZeitzoneCommand", {
                    success: response.ok,
                });
            }
        } else if (json?.success) {
            dispatch({
                type: LOESCHE_ZEITZONEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("loescheZeitzoneCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };
