import {
  //#region Firma  
  FIRMA_AKTIV_SUCCESS,
  RESET_FIRMA_SUCCESS,
  FIRMA_ACTION_FAIL,
  FIRMA_ACTION_BEGIN,
  LOESCHE_FIRMA_COMMAND_SUCCESS,
  //#endregion
  FETCH_FIRMA_DASHBOARD_SUCCESS,
  //#region Stammdaten
  FETCH_FIRMA_STAMMDATEN_SUCCESS,
  //#endregion

  //#region  Unterlagen
  FIRMA_UNTERLAGEN_ACTION_FAIL,
  FIRMA_UNTERLAGEN_ACTION_BEGIN,
  FETCH_FIRMA_UNTERLAGEN_SUCCESS,
  LOESCHE_EUFEFA_COMMAND_SUCCESS,
  ERSTELLE_EUFEFA_COMMAND_SUCCESS,
  AKTUALISIERE_STAMMDATEN_SUCCESS,
  ERSTELLE_DYNAMISCHE_FIRMA_EUFEFA_COMMAND_SUCCESS,
  PRUEFE_FIRMA_EUFEFA_COMMAND_SUCCESS,
  ENTPRUEFE_FIRMA_EUFEFA_COMMAND_SUCCESS,
  //#endregion

  //#region Firmenbeziehung
  FIRMA_FIRMENBEZIEHUNG_ACTION_FAIL,
  FIRMA_FIRMENBEZIEHUNG_ACTION_BEGIN,
  FETCH_FIRMA_FIRMENBEZIEHUNG_SUCCESS,
  //#endregion

  //#region Auftraggeber
  ERSTELLE_AUFTRAGGEBER_SUCCESS,
  FIRMA_AUFTRAGGEBER_ACTION_FAIL,
  FIRMA_AUFTRAGGEBER_ACTION_BEGIN,
  LOESCHE_FIRMA_AUFTRAGGEBER_SUCCESS,
  //#endregion

  //#region Verwalter
  FIRMA_VERWALTER_ACTION_FAIL,
  FIRMA_VERWALTER_ACTION_BEGIN,
  FETCH_FIRMA_VERWALTER_SUCCESS,
  LOESCHE_FIRMA_VERWALTER_SUCCESS,
  ERSTELLE_FIRMA_VERWALTER_SUCCESS,
  //#endregion

  //#region Nachunternehmer
  FIRMA_NACHUNTERNEHMER_ACTION_FAIL,
  FIRMA_NACHUNTERNEHMER_ACTION_BEGIN,
  FETCH_FIRMA_NACHUNTERNEHMER_SUCCESS,
  LOESCHE_FIRMA_NACHUNTERNEHMER_SUCCESS,
  ERSTELLE_FIRMA_NACHUNTERNEHMER_SUCCESS,
  //#endregion
  //#region Mitarbeiter
  FETCH_FIRMA_MITARBEITER_SUCCESS,
  FIRMA_MITARBEITER_ACTION_BEGIN,
  FIRMA_MITARBEITER_ACTION_FAIL,
  //#endregion

  //#region Baustellenausweise
  FIRMA_BAUSTELLENAUSWEIS_ACTION_BEGIN,
  FIRMA_BAUSTELLENAUSWEIS_ACTION_FAIL,
  FETCH_FIRMA_BAUSTELLENAUSWEIS_SUCCESS,
  //#endregion
} from "../../actions/zuko/firma.js";

const firmaState = {
  error: null,
  loading: false,
  stammdaten: {},
  firmenbeziehungen: [],
  verwalter: [],
  mitarbeiter: [],
  baustellenausweise: [],  
  istAktiv: true,
};

const savedState =
  localStorage.getItem("savedState") || JSON.stringify({ ...firmaState });
const INITIAL_STATE = JSON.parse(savedState).firmaState || firmaState;

const firma = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_FIRMA_SUCCESS:
    case LOESCHE_FIRMA_COMMAND_SUCCESS:
      return firmaState;
    case FIRMA_ACTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FIRMA_ACTION_BEGIN:
    case FIRMA_VERWALTER_ACTION_BEGIN:
    case FIRMA_UNTERLAGEN_ACTION_BEGIN:
    case FIRMA_MITARBEITER_ACTION_BEGIN:
    case FIRMA_AUFTRAGGEBER_ACTION_BEGIN:
    case FIRMA_FIRMENBEZIEHUNG_ACTION_BEGIN:
    case FIRMA_NACHUNTERNEHMER_ACTION_BEGIN:
    case FIRMA_BAUSTELLENAUSWEIS_ACTION_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case FETCH_FIRMA_STAMMDATEN_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        stammdaten: action.payload,
      };
    case FETCH_FIRMA_FIRMENBEZIEHUNG_SUCCESS:
      return {
        ...state,
        loading: false,
        firmenbeziehungen: action.payload,
      };
    case FETCH_FIRMA_VERWALTER_SUCCESS:
      return {
        ...state,
        loading: false,
        verwalter: action.payload,
      };
    case FETCH_FIRMA_MITARBEITER_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        mitarbeiter: action.payload,
      };
    case FETCH_FIRMA_UNTERLAGEN_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        unterlagen: action.payload,
      };
    case FIRMA_AKTIV_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          istAktiv: action.payload,
        };
    case FETCH_FIRMA_BAUSTELLENAUSWEIS_SUCCESS:
      return {
        ...state,
        loading: false,
        baustellenausweise: action.payload,
      };
    case FETCH_FIRMA_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard: action.payload,
      };
    case FIRMA_VERWALTER_ACTION_FAIL:
    case FIRMA_UNTERLAGEN_ACTION_FAIL:
    case FIRMA_MITARBEITER_ACTION_FAIL:
    case FIRMA_AUFTRAGGEBER_ACTION_FAIL:
    case FIRMA_FIRMENBEZIEHUNG_ACTION_FAIL:
    case FIRMA_NACHUNTERNEHMER_ACTION_FAIL:
    case FIRMA_BAUSTELLENAUSWEIS_ACTION_FAIL:
      return {
        ...state,
        error: null,
        loading: false,
        error: action.error,
      };
    case ERSTELLE_AUFTRAGGEBER_SUCCESS:
    case LOESCHE_EUFEFA_COMMAND_SUCCESS:
    case ERSTELLE_EUFEFA_COMMAND_SUCCESS:
    case LOESCHE_FIRMA_VERWALTER_SUCCESS:
    case AKTUALISIERE_STAMMDATEN_SUCCESS:
    case ERSTELLE_FIRMA_VERWALTER_SUCCESS:
    case LOESCHE_FIRMA_AUFTRAGGEBER_SUCCESS:
    case FETCH_FIRMA_NACHUNTERNEHMER_SUCCESS:
    case PRUEFE_FIRMA_EUFEFA_COMMAND_SUCCESS:
    case LOESCHE_FIRMA_NACHUNTERNEHMER_SUCCESS:
    case ENTPRUEFE_FIRMA_EUFEFA_COMMAND_SUCCESS:
    case ERSTELLE_FIRMA_NACHUNTERNEHMER_SUCCESS:
    case ERSTELLE_DYNAMISCHE_FIRMA_EUFEFA_COMMAND_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default firma;
