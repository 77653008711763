export const DASHBOARD_EINSTELLUNGEN_BEGIN = "DASHBOARD_EINSTELLUNGEN_BEGIN";
export const FETCH_EINSTELLUNGEN_RESET_SUCCESS =
    "FETCH_EINSTELLUNGEN_RESET_SUCCESS";
export const DASHBOARD_EINSTELLUNGEN_ACTION_FAIL =
    "DASHBOARD_EINSTELLUNGEN_ACTION_FAIL";
export const FETCH_DASHBOARD_EINSTELLUNGEN_SUCCCES =
    "FETCH_DASHBOARD_EINSTELLUNGEN_SUCCCES";
export const ERSTELLE_DASHBOARD_EINSTELLUNGEN_SUCCCES =
    "ERSTELLE_DASHBOARD_EINSTELLUNGEN_SUCCCES";
export const AKTUALISIERE_DASHBOARD_EINSTELLUNGEN_SUCCCES =
    "AKTUALISIERE_DASHBOARD_EINSTELLUNGEN_SUCCCES";
export const LOESCHE_DASHBOARD_EINSTELLUNGEN_SUCCCES =
    "LOESCHE_DASHBOARD_EINSTELLUNGEN_SUCCCES";

import errorHandler from "../errorHandling";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;

let abortController;

export const cancelRequests = () => (dispatch) => {
    if (abortController) {
        abortController.abort(); // Tell the browser to abort request
    }

    dispatch({ type: FETCH_EINSTELLUNGEN_RESET_SUCCESS });
    appInsights.trackEvent({ name: "cancelRequests" });
};

export const fetchDashboardEinstellungen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchDashboardEinstellungen");
    dispatch({
        type: DASHBOARD_EINSTELLUNGEN_BEGIN,
    });
    abortController =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-einstellung`,
        getRequestOptions,
        { signal: abortController.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: DASHBOARD_EINSTELLUNGEN_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("fetchDashboardEinstellungen", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: DASHBOARD_EINSTELLUNGEN_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("fetchDashboardEinstellungen", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_DASHBOARD_EINSTELLUNGEN_SUCCCES,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchDashboardEinstellungen", {
            success: json.success,
        });
        return json.payload;
    }
};

export const erstelleDashboardEinstellung =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("erstelleDashboardEinstellung");
        dispatch({
            type: DASHBOARD_EINSTELLUNGEN_BEGIN,
        });
        abortController =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${projektId}/erstelle-dashboard-einstellungen`,
            {
                method: "POST",
                body: formData,
            },
            { signal: abortController.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: DASHBOARD_EINSTELLUNGEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent("erstelleDashboardEinstellung", {
                    success: json.success,
                    error: json,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: DASHBOARD_EINSTELLUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent("erstelleDashboardEinstellung", {
                    success: response.ok,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: ERSTELLE_DASHBOARD_EINSTELLUNGEN_SUCCCES,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("erstelleDashboardEinstellung", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const aktualisiereDashboardEinstellung =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("aktualisiereDashboardEinstellung");
        dispatch({
            type: DASHBOARD_EINSTELLUNGEN_BEGIN,
        });
        abortController =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${projektId}/aktualisiere-dashboard-einstellungen`,
            {
                method: "POST",
                body: formData,
            },
            { signal: abortController.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: DASHBOARD_EINSTELLUNGEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent("aktualisiereDashboardEinstellung", {
                    success: json.success,
                    error: json,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: DASHBOARD_EINSTELLUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent("aktualisiereDashboardEinstellung", {
                    success: response.ok,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: AKTUALISIERE_DASHBOARD_EINSTELLUNGEN_SUCCCES,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("aktualisiereDashboardEinstellung", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const loescheDashboardEinstellung =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("loescheDashboardEinstellung");
        dispatch({
            type: DASHBOARD_EINSTELLUNGEN_BEGIN,
        });
        abortController =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${projektId}/loesche-dashboard-einstellungen`,
            {
                method: "POST",
                body: formData,
            },
            { signal: abortController.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: DASHBOARD_EINSTELLUNGEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent("loescheDashboardEinstellung", {
                    success: json.success,
                    error: json,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: DASHBOARD_EINSTELLUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent("loescheDashboardEinstellung", {
                    success: response.ok,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: LOESCHE_DASHBOARD_EINSTELLUNGEN_SUCCCES,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("loescheDashboardEinstellung", {
                success: json.success,
            });
            return json.payload;
        }
    };
