import { navigate, showToast } from "./algo-app";
import { translate } from "../../i18n.js";

const errorHandler = (err) => (dispatch) => {
    let error;
    if (err && err.payload) {
        error = translate("ALLGEMEIN.ERROR_HANDLER.FOLGENDE_FEHLER") + ":\n ";
        for (const [key, value] of Object.entries(err.payload)) {
            if (value && value.length > 0) error += value + "\n";
        }
        dispatch(showToast({ text: error, type: "error" }));
        return;
    }
    if (err && err.error != null) {
        dispatch(showToast({ text: err.error?.errorMessage, type: "error" }));
        return;
    }
    if (err?.status !== null) {
        if (err?.status === 404) {
            dispatch(
                showToast({
                    text: translate("ALLGEMEIN.ERROR_HANDLER.NICHT_GEFUNDEN"),
                    type: "error",
                })
            );
            return;
        }
        if (err?.status === 401) {
            dispatch(
                navigate({
                    view: "identity-anmelden-view",
                    layout: "blank-layout",
                })
            );
            dispatch(
                showToast({
                    text: translate("ALLGEMEIN.ERROR_HANDLER.NICHT_ANGEMELDET"),
                    type: "error",
                })
            );
            return;
        }
        if (err?.status === 500) {
            dispatch(
                showToast({
                    text: translate("ALLGEMEIN.ERROR_HANDLER.SERVER_ERROR"),
                    type: "error",
                })
            );
            return;
        }
        if (err?.status === 502) {
            dispatch(
                showToast({
                    text: translate(
                        "ALLGEMEIN.ERROR_HANDLER.SERVER_NICHT_ERREICHBAR"
                    ),
                    type: "error",
                })
            );
            return;
        }
        if (err?.status === 499) {
            dispatch(
                showToast({
                    text: translate(
                        "ALLGEMEIN.ERROR_HANDLER.ZEITUEBERSCHREITUNG"
                    ),
                    type: "error",
                })
            );
            return;
        }
        dispatch(
            showToast({
                text: translate(
                    "ALLGEMEIN.ERROR_HANDLER.ES_IST_EIN_FEHLER_AUFGETRETEN"
                ),
                type: "error",
            })
        );
        return;
    } else {
        dispatch(
            showToast({
                text: translate(
                    "ALLGEMEIN.ERROR_HANDLER.ES_IST_EIN_FEHLER_AUFGETRETEN"
                ),
                type: "error",
            })
        );
        return;
    }
};

export default errorHandler;
