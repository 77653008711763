//#region LitElement, Polymer
import { LitElement, css } from "lit";
import { html } from "lit/html.js";;
import { connect } from "pwa-helpers/connect-mixin.js";
//#endregion

//#region Store, Redux
import { store } from "../../../store.js";
store.addReducers({
    firmen,
    algoApp,
});
import {
    fetchFirmenhierarchieNetzwerk,
    fetchFirmenhierarchie,
    cancelFirmenhierarchie,
} from "../../../rdx/actions/zuko/firmen.js";
import algoApp from "../../../rdx/reducers/algo-app.js";
import firmen from "../../../rdx/reducers/zuko/firmen.js";
//#endregion

//#region Styles
import { VisStyles } from "../../../vis/vis-styles.js";
import { AlgoStyles } from "../../../css/algo-styles.js";
import { SbAdmin2Styles } from "../../../css/sb-admin-2-styles.js";
import { FontawesomeIcons } from "../../../css/fontawesome-icons.js";
//#endregion

//#region Translation
import { translate } from "../../../i18n";
//#endregion

class ZukoFirmenViewFirmenhierarchiePage extends connect(store)(LitElement) {
    //#region Styles
    static get styles() {
        return [
            SbAdmin2Styles,
            AlgoStyles,
            FontawesomeIcons,
            VisStyles,
            css`
                #network {
                    width: 100%;
                    border: 1px solid lightgray;
                }
            `,
        ];
    }
    //#endregion

    //#region Properties
    static get properties() {
        return {
            _acl: { type: Object },
            _network: { type: Object },
            _loading: { type: Boolean },
            _checkboxEnabled: { type: Boolean },
            _projektId: { type: String },
            _networkData: { type: Object }, // remove

            _nodes: { type: Object },
            _edges: { type: Object },
            _firmaId: { type: String },
            _firmen: { type: Array },

            _networkOptions: { type: Object },
            _firmennetzwerk: { type: Object },
            _firmenhierarchie: { type: Object },
            active: { type: Boolean },
        };
    }
    //#endregion

    //#region render
    _renderFirmenLayout() {
        const firmen =
                this._firmen?.sort((a, b) => {
                    var x = a.name.toUpperCase();
                    var y = b.name.toUpperCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                }) || [],
            disabled = this._loading;
        return html`
            <form class="form-inline">
                <div class="input-group" style="margin-bottom:10px;">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <i class="fas fa-sort"></i>
                        </div>
                        <select
                            ?disabled="${disabled}"
                            id="firma"
                            @input="${this._onChangeFirma}"
                            class="custom-select"
                        >
                            <option
                                disabled
                                ?selected="${this._firmaId === ""}"
                                value=""
                            >
                                -- ${translate("ZUKO.FIRMA_AUSWAEHLEN")} --
                            </option>
                            ${firmen?.map(
                                (x) =>
                                    html`
                                        <option
                                            ?selected="${this._firmaId ===
                                            x.id}"
                                            value="${x.firmaId}"
                                        >
                                            ${x.name}
                                        </option>
                                    `
                            )}
                        </select>
                    </div>
                </div>
            </form>
        `;
    }
    _renderHierarchischesLayout() {
        const disabled = !this._checkboxEnabled;
        return html`
            <form class="form-inline">
                <div class="custom-control custom-checkbox my-1 mr-sm-2">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="hierarchical-checkbox"
                        @input="${this._onHierarchischesLayoutClick}"
                    />
                    <label
                        class="custom-control-label"
                        for="hierarchical-checkbox"
                        >${translate("ZUKO.HIERACHISCHES_LAYOUT")}</label
                    >
                </div>
            </form>
            <form style="margin-left: 50px;" class="form-inline">
                <div class="custom-control custom-checkbox my-1 mr-sm-2">
                    <input
                        ?disabled="${disabled}"
                        type="checkbox"
                        class="custom-control-input"
                        id="hierarchical-direction-checkbox"
                        @input="${this._onHierarchischesLayoutDirectionClick}"
                    />
                    <label
                        class="custom-control-label"
                        for="hierarchical-direction-checkbox"
                        >${translate("ZUKO.HORIZONTAL_ANORDNEN")}</label
                    >
                </div>
            </form>
        `;
    }

    render() {
        return html`
            ${this._renderFirmenLayout()} ${this._renderHierarchischesLayout()}
            <div id="network"></div>
        `;
    }
    //#endregion

    constructor() {
        super();

        this._acl = {};
        this._network = {};
        this._projektId = "";
        this._loading = false;
        this._checkboxEnabled = false;
        this._networkData = new vis.DataSet();

        this._nodes = new vis.DataSet([]);
        this._edges = new vis.DataSet([]);
        this._firmaId = "";
        this._firmen = [];

        this._networkOptions = {
            height: window.innerHeight - 300 + "px",
            layout: {
                hierarchical: {
                    enabled: false,
                    direction: "UD",
                    sortMethod: "directed",
                },
            },
            edges: {
                arrows: "to",
            },
            physics: {
                repulsion: {
                    nodeDistance: 100,
                },
            },
        };
        this.active = false;
    }

    //#region reset und changeFirma
    _resetAllNodes() {
        this._nodes.clear();
        this._edges.clear();
        this._nodes.add(this._firmennetzwerk.nodes);
        this._edges.add(this._firmennetzwerk.edges);
    }

    _onChangeFirma() {
        const select = this.shadowRoot.querySelector("#firma");
        store.dispatch(
            fetchFirmenhierarchieNetzwerk({
                projektId: this._projektId,
                firmenId: select.value,
            })
        );
    }
    //#endregion

    //#region Hierachie
    _onHierarchischesLayoutDirectionClick() {
        const input = this.shadowRoot.querySelector(
                "#hierarchical-direction-checkbox"
            ),
            enabled = input.checked;

        if (enabled) {
            this._network.setOptions({
                layout: {
                    hierarchical: {
                        direction: "LR",
                    },
                },
            });
        } else {
            this._network.setOptions({
                layout: {
                    hierarchical: {
                        direction: "UD",
                    },
                },
            });
        }
    }

    _onHierarchischesLayoutClick() {
        const input = this.shadowRoot.querySelector("#hierarchical-checkbox");
        this._checkboxEnabled = input.checked;

        this._network.setOptions({
            layout: {
                hierarchical: {
                    enabled: this._checkboxEnabled,
                },
            },
        });
    }

    //#endregion

    //#region firstUpdated, updated und stateChanged
    firstUpdated() {
        const container = this.shadowRoot.getElementById("network"),
            data = {
                nodes: this._nodes,
                edges: this._edges,
            };
        this._network = new vis.Network(container, data, this._networkOptions);
        this._network.moveTo({
            position: { x: 0, y: 0 },
            offset: { x: window.innerWidth / 5, y: window.innerHeight / 3 },
            scale: 1,
        });
    }

    updated(properties) {
        properties.forEach((oldValue, propName) => {
            if (propName === "active") {
                if (this.active !== oldValue) {
                    if (this.active) {
                        store.dispatch(
                            fetchFirmenhierarchie({
                                projektId: this._projektId,
                            })
                        );
                    } else {
                        store.dispatch(cancelFirmenhierarchie());
                    }
                }
            }
            if (propName === "_firmennetzwerk") {
                if (this._firmennetzwerk !== oldValue) {
                    if (
                        this._firmennetzwerk.nodes &&
                        this._firmennetzwerk.edges
                    ) {
                        this._resetAllNodes();
                    }
                }
            }
        });
    }

    stateChanged(state) {
        this._projektId = state.algoApp.projektId;
        this._acl = state.projekt.projekt.projektrolle || {};
        this._firmenhierarchie = state.firmen.firmenhierarchie;
        this._firmen = state.firmen.firmenhierarchie || [];
        this._firmennetzwerk = state.firmen.firmennetzwerk || {};
    }
    //#endregion
}
window.customElements.define(
    "zuko-organisation-uebersicht-view-firmenhierarchie-page",
    ZukoFirmenViewFirmenhierarchiePage
);
