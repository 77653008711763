export const TRANSLATIONS_DE_GEWERKE = {
    ABBRUCHARBEITEN: "Abbrucharbeiten",
    AUFZUGBAUER: "Aufzugbauer*in",
    AUSBAU: "Ausbau",
    AUSSENANLAGEN: "Außenanlagen",
    BAUAUFZUG: "Bauaufzug",
    BAUHERR: "Bauherr*in",
    BAULEITUNG: "Bauleitung",
    BAULOGISTIK: "Baulogistik",
    BAUSTROM__BAUWASSER: "Baustrom / Bauwasser",
    BETON__UND_STAHLBAUER: "Beton- und Stahlbauer",
    BLITZSCHUTZ: "Blitzschutz",
    BMA__ELA: "BMA / ELA",
    BODENLEGER: "Bodenleger*in",
    BRANDSCHUTZ: "Brandschutz",
    DACHDECKER: "Dachdecker*in",
    DOPPELBODEN: "Doppelboden",
    ELEKTRO: "Elektro",
    EISENFLECHTER: "Eisenflechter",
    ESTRICHARBEITEN: "Estricharbeiten",
    FASSADE: "Fassade",
    FENSTER_UND_TÜRBAUER: "Fenster und Türbauer",
    FLIESENLEGER: "Fliesenleger*in",
    GENERALUNTERNEHMER: "Generalunternehmung",
    GERÜSTBAUER: "Gerüstbauer*in",
    HEIZUNG: "Heizung",
    HOHLRAUMBODEN: "Hohlraumboden",
    HOLZBAU: "Holzbau",
    KORROSIONS__UND_OBERFLÄCHENSCHUTZ: "Korrisons- und Oberflächenschutz",
    LABORTECHNIK: "Labortechnik",
    LÜFTUNGSANLAGEN: "Lüftungsanlagen",
    MALER: "Maler*in",
    MAURER: "Maurer*in",
    METALLBAU: "Metallbau",
    MSR__GEBÄUDEAUTOMATION: "MSR / Gebäudeautomation",
    PLANER: "Planer*in",
    PUTZER: "Putzer*in",
    RLT: "RLT",
    ROHBAU: "Rohbau",
    RESTAURATOR: "Restaurator*in",
    SANITÄR: "Sanitär",
    SCHLOSSER: "Schlosser*in",
    SCHOTTUNG: "Schottung",
    SICHERHEIT: "Sicherheit",
    SPEZIALTIEFBAU: "Spezialtiefbau",
    SPRINKLER: "Sprinkler",
    STAHLTÜRENBAUER: "Stahltürenbauer*in",
    STEINMETZ: "Steinmetz",
    TEPPICHLEGER: "Teppichleger*in",
    TGA: "TGA",
    TIEFGARAGENDÄMMUNG: "Tiefgaragendämmung",
    TISCHLERARBEITEN: "Tischlerarbeiten",
    TROCKENBAU: "Trockenbau",
    VERMESSER: "Vermesser*in",
    WDVS: "WDVS",
    SONSTIGE: "Sonstige",
};
export default TRANSLATIONS_DE_GEWERKE;
