import errorHandler from "../errorHandling";
export const ZUKO_AUSWEIS_ACTION_FAIL = "ZUKO_AUSWEIS_ACTION_FAIL";
export const ZUKO_AUSWEIS_ACTION_BEGIN = "ZUKO_AUSWEIS_ACTION_BEGIN";
export const ZUKO_AKTUALISIERE_EINSTELLUNGEN_SUCCESS =
  "ZUKO_AKTUALISIERE_EINSTELLUNGEN_SUCCESS";
export const ZUKO_AKTUALISIERE_AUSWEIS_VORLAGE_SUCCESS =
  "ZUKO_AKTUALISIERE_AUSWEIS_VORLAGE_SUCCESS";
export const ZUKO_ERSTELLE_AUSWEIS_VORLAGE_SUCCESS =
  "ZUKO_ERSTELLE_AUSWEIS_VORLAGE_SUCCESS";
export const ZUKO_LOESCHE_AUSWEIS_VORLAGE_SUCCESS =
  "ZUKO_LOESCHE_AUSWEIS_VORLAGE_SUCCESS";
export const RESET_ZUKO_AUSWEIS_VORLAGE_SUCCESS =
  "RESET_ZUKO_AUSWEIS_VORLAGE_SUCCESS";
export const ZUKO_FETCH_AUSWEIS_VORLAGE_SUCCESS =
  "ZUKO_FETCH_AUSWEIS_VORLAGE_SUCCESS";
const apiHost = window.Algo.apiHost,
  appInsights = window.Algo.appInsights;

export const fetchAusweisVorlage = (data) => async (dispatch) => {
  appInsights.startTrackEvent("fetchAusweisVorlage");
  dispatch({ type: ZUKO_AUSWEIS_ACTION_BEGIN });
  const url = `${apiHost}/api/zuko/admin/v1/${data.projektId}/baustellenausweis-vorlagen`;
  const response = await fetch(url, {
    method: "GET",
  });
  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(response));
  }
  if (!response.ok) {
    if (json && !json.success) {
      dispatch(errorHandler(json));
      dispatch({ type: ZUKO_AUSWEIS_ACTION_FAIL, error: json });
      appInsights.stopTrackEvent("fetchAusweisVorlage", {
        success: json.success,
        error: json,
      });
    } else {
      dispatch(errorHandler(response));
      dispatch({ type: ZUKO_AUSWEIS_ACTION_FAIL, error: response });
      appInsights.stopTrackEvent("fetchAusweisVorlage", {
        success: response.ok,
        error: response,
      });
    }
  }
  if (json?.success) {
    dispatch({
      type: ZUKO_FETCH_AUSWEIS_VORLAGE_SUCCESS,
      payload: json.payload,
    });
    appInsights.stopTrackEvent("aktualisiereZukoEinstellungenKonfiguration", {
      success: json.success,
    });
    return json.payload;
  }
};

export const erstelleAusweisVorlage = (formData, projektId) => async (dispatch) => {
    appInsights.startTrackEvent("erstelleAusweisVorlage");
    dispatch({ type: ZUKO_AUSWEIS_ACTION_BEGIN });
    const response = await fetch(
      `${apiHost}/api/zuko/admin/v1/${projektId}/erstelle-baustellenausweis-vorlage`,
      {
        method: "POST",
        body: formData,
      }
    );
    let json;
    try {
      json = await response.json();
    } catch (error) {
      dispatch(errorHandler(error));
    }
    if (!response.ok) {
      if (json && !json.success) {
        dispatch(errorHandler(json));
        dispatch({ type: ZUKO_AUSWEIS_ACTION_FAIL, error: json });
        appInsights.stopTrackEvent("erstelleAusweisVorlage", {
          success: json.success,
          error: json,
        });
      } else {
        dispatch(errorHandler(response));
        dispatch({ type: ZUKO_AUSWEIS_ACTION_FAIL, error: response });
        appInsights.stopTrackEvent("erstelleAusweisVorlage", {
          success: response.ok,
          error: response,
        });
      }
    }
    if (json?.success) {
      dispatch({
        type: ZUKO_ERSTELLE_AUSWEIS_VORLAGE_SUCCESS,
        payload: json.payload,
      });
      appInsights.stopTrackEvent("erstelleAusweisVorlage", {
        success: json.success,
      });
      return json.payload;
    }
  };

export const aktualisiereAusweisVorlage = (formData, projektId) => async (dispatch) => {
    appInsights.startTrackEvent("aktualisiereAusweisVorlage");
    dispatch({ type: ZUKO_AUSWEIS_ACTION_BEGIN });
    const response = await fetch(
      `${apiHost}/api/zuko/admin/v1/${projektId}/aktualisiere-baustellenausweis-vorlage`,
      {
        method: "POST",
        body: formData,
      }
    );
    let json;
    try {
      json = await response.json();
      return data;
    } catch (error) {
      dispatch(errorHandler(error));
    }
    if (!response.ok) {
      if (json && !json.success) {
        dispatch(errorHandler(json));
        dispatch({ type: ZUKO_AUSWEIS_ACTION_FAIL, error: json });
        appInsights.stopTrackEvent("aktualisiereAusweisVorlage", {
          success: json.success,
          error: json,
        });
      } else {
        dispatch(errorHandler(response));
        dispatch({ type: ZUKO_AUSWEIS_ACTION_FAIL, error: response });
        appInsights.stopTrackEvent("aktualisiereAusweisVorlage", {
          success: response.ok,
          error: response,
        });
      }
    }
    if (json?.success) {
      dispatch({
        type: ZUKO_AKTUALISIERE_AUSWEIS_VORLAGE_SUCCESS,
        payload: json.payload,
      });
      appInsights.stopTrackEvent("erstelleAusweisVorlage", {
        success: json.success,
      });
      return json.payload;
    }
  };

export const loescheAusweisVorlage = (e) => async (dispatch) => {
  appInsights.startTrackEvent("loescheAusweisVorlage");
  dispatch({ type: ZUKO_AUSWEIS_ACTION_BEGIN });
  const form = e.srcElement || e.target;
  const url = form.dataset.url;
  const formData = new FormData(form);

  const response = await fetch(url, {
    method: "POST",
    body: formData,
  });
  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(error));
  }
  if (!response.ok) {
    if (json & !json.success) {
      dispatch(errorHandler(json));
      dispatch({ type: ZUKO_AUSWEIS_ACTION_FAIL, error: json });
      appInsights.stopTrackEvent("loescheAusweisVorlage", {
        success: json.success,
        error: json,
      });
    } else {
      dispatch(errorHandler(response));
      dispatch({ type: ZUKO_AUSWEIS_ACTION_FAIL, error: response });
      appInsights.stopTrackEvent("loescheAusweisVorlage", {
        success: response.ok,
        error: response,
      });
    }
  } else if (json?.success) {
    dispatch({
      type: ZUKO_LOESCHE_AUSWEIS_VORLAGE_SUCCESS,
      payload: json.payload,
    });
    appInsights.stopTrackEvent("loescheAusweisVorlage", {
      success: json.success,
    });
    return json.payload;
  }
};
