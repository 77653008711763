export const TRANSLATIONS_EN_DASHBOARD = {
    AKTIVE_AUSWEISE: "Active permits total",
    ANWESENHEIT_GESAMT: "Total attendance",
    ANWESENHEIT_IM_MONATSVERLAUF: "Attendance over the course of the month",
    ANWESENHEIT_IM_TAGESABLAUF: "Attendace over the course of a day",
    ANWESENHEIT_IM_WOCHENVERLAUF: "Attendance over the course of a week",
    ANWESENHEIT_MITARBEITENE_MO_FR: "Ø-Attendance employees (Mo - Fr)",
    ANWESENHEIT_MITARBEITENE_MO_FR_TOP_5:
        "Ø-Attendance employees Mo - Fr (Top 5 companies)",
    ANWESENHEIT_MITARBEITENDE_TOP_10: "Ø-Attendance employees (Top 10 companies)",
    ANWESENHEIT_MITARBEITENDE_TOP_5: "Attendance employees (Top 5 companies)",
    ANWESENHEIT_OHNE_WOCHENENDE: "Ø-Attendance employees (without weekends)",
    ANZAHL_MITARBEITERNDE: "Number employees",
    APRIL: "April",
    AUGUST: "August",
    AUSWERTUNGEN: "Reports",
    DATEN_IN_KUERZE: "Data available soon",
    DATEN_NICHT_VERFUEGBAR: "No data available",
    DECEMBER: "December",
    EINSTELLUNGEN: "Settings",
    EMAIL: "Mail address",
    EXPORT_TOAST:
        "Download has been started as a background operation during your work in ALGO. Please do not close ALGO until the download is completed. ",
    FEBRUARY: "February",
    FEHLDRUCKE: "of them {{anzahl}} wrong prints ",
    FRIDAY: "Fri",
    GESPERRTE_AUSWEISE_GESAMT: "Suspended permits total",
    GESPERRTE_MITARBEITENDE_TOP_10: "Suspended employees (Top 10 companies)",
    GESPERRTE_MITARBEITER_GESAMT: "Suspended employees total",
    JA: "Yes",
    JANUARY: "Januaray",
    JUNE: "June",
    JULY: "July",
    LEAN_ANWESENHEIT: "LEAN attendance target/actual",
    LEAN_SOLL_ANWESENHEIT: "Expected attendance LEAN",
    KEIN_PROJEKTBETEILIGTER: "No project participant configured",
    KW: "CW",
    MARCH: "March",
    MAY: "May",
    MONAT: "Month",
    MONATS_AUSWERTUNG_EXPORT: "Export monthly report",
    MONDAY: "Mon",
    NEIN: "No",
    NEU_AUSGESTELLTE_AUSWEISE: "Newly issued permits total",
    NOVEMBER: "November",
    OCTOBER: "October",
    PROJEKTBETEILITGEN_BEARBEITEN: "Edit project participant",
    PROJEKTBETEILIGTER_HINZUFUEGEN: "Add project participant",
    PROJEKTBETEILIGTER_LOESCHEN: "Delete project participant",
    PROJEKTBETEILIGTEN_LOESCHEN_MODAL:
        "Do you really want to delete this project participant?",
    TAG: "Day",
    TAGE: "Days",
    TAGES_AUSWERTUNG_EXPORT: "Export daily report",
    THURSDAY: "Thur",
    TUESDAY: "Tue",
    SATURDAY: "Sat",
    SEPTEMBER: "September",
    SPERRGRUENDE_MONATSVERLAUF_TOP_5:
        "Reasons for suspension over the course of the month (Top 5)",
    SPERRGRUENDE_TAGESABLAUF_TOP_5:
        "Reasons of suspension over the course of a day (Top 5)",
    SPERRGRUENDE_WOCHENVERLAUF_TOP_5:
        "Reasons of suspension over the course of a week (Top 5)",
    SUNDAY: "Sun",
    WEDNESDAY: "Wed",
    WOCHE: "Week",
    WOCHEN_AUSWERTUNG_EXPORT: "Export weekly report",
    UHRZEIT: "Time",
};
export default TRANSLATIONS_EN_DASHBOARD;
