import {
    FETCH_ORGANISATION_RESET_SUCCESS,
    FETCH_ORGANISATION_FIRMEN_ACTION_BEGIN,
    FETCH_ORGANISATION_FIRMEN_ACTION_FAIL,
    FETCH_FIRMEN_ORANISATION_SUCCESS,
    FETCH_ORGANISATION_PERSONAL_ACTION_BEGIN,
    FETCH_ORGANISATION_PERSONAL_ACTION_FAIL,
    FETCH_PERSONAL_ORANISATION_SUCCESS,
    FETCH_ORGANISATION_FIRMENHIERACHIE_ACTION_BEGIN,
    FETCH_ORGANISATION_FIRMENHIERACHIE_ACTION_FAIL,
    FETCH_FIRMENHIERACHIE_ORANISATION_SUCCESS,
    FETCH_GESAMTES_PERSONAL_ACTION_BEGIN,
    FETCH_GESAMTES_PERSONAL_ACTION_FAIL,
    FETCH_GESAMTES_PERSONAL_SUCCESS,
    RESET_GESAMTES_PERSONAL_SUCCESS,
    FETCH_ALLE_FIRMEN_ACTION_BEGIN,
    FETCH_ALLE_FIRMEN_ACTION_FAIL,
    FETCH_ALLE_FETCH_FIRMEN_SUCCESS,
    RESET_ALLE_FIRMEN_SUCCESS,
} from "../../actions/zuko/organisation";

const organisationState = {
    error: null,
    firmenIstLoading: false,
    personalIstLoading: false,
    firmenhierachieIstLoading: false,
    gesamtesPersonalIstLoading: false,
    alleFirmenIstLoading: false,
};

const savedState =
    localStorage.getItem("savedState") ||
    JSON.stringify({ ...organisationState });
const INITIAL_STATE =
    JSON.parse(savedState).organisationState || organisationState;

const organisation = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_GESAMTES_PERSONAL_SUCCESS:
            return {
                ...state,
                error: null,
                gesamtesPersonalIstLoading: false,
                gesamtesPersonal: {},
            };
        case RESET_ALLE_FIRMEN_SUCCESS:
            return {
                ...state,
                error: null,
                alleFirmenIstLoading: false,
            };
        case FETCH_ORGANISATION_RESET_SUCCESS:
            return {
                ...state,
                error: null,
                firmenIstLoading: false,
                personalIstLoading: false,
                firmenhierachieIstLoading: false,
            };
        case FETCH_ORGANISATION_FIRMEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                firmenIstLoading: true,
            };
        case FETCH_ORGANISATION_FIRMEN_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                firmenIstLoading: false,
                firmen: {},
            };
        case FETCH_FIRMEN_ORANISATION_SUCCESS:
            return {
                ...state,
                error: null,
                firmenIstLoading: false,
                firmen: action.payload,
            };
        case FETCH_ORGANISATION_PERSONAL_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                personalIstLoading: true,
            };
        case FETCH_ORGANISATION_PERSONAL_ACTION_FAIL:
            return {
                ...state,
                error: null,
                personalIstLoading: false,
            };
        case FETCH_PERSONAL_ORANISATION_SUCCESS:
            return {
                ...state,
                error: null,
                personalIstLoading: false,
            };
        case FETCH_ORGANISATION_FIRMENHIERACHIE_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                firmenhierachieIstLoading: true,
            };
        case FETCH_ORGANISATION_FIRMENHIERACHIE_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                firmenhierachieIstLoading: false,
            };
        case FETCH_FIRMENHIERACHIE_ORANISATION_SUCCESS:
            return {
                ...state,
                error: null,
                firmenhierachieIstLoading: false,
            };
        case FETCH_GESAMTES_PERSONAL_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                gesamtesPersonalIstLoading: true,
            };
        case FETCH_GESAMTES_PERSONAL_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                gesamtesPersonalIstLoading: false,
            };
        case FETCH_GESAMTES_PERSONAL_SUCCESS:
            return {
                ...state,
                error: null,
                gesamtesPersonalIstLoading: false,
            };
        case FETCH_ALLE_FIRMEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                alleFirmenIstLoading: true,
            };
        case FETCH_ALLE_FIRMEN_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                alleFirmenIstLoading: false
            };
        case FETCH_ALLE_FETCH_FIRMEN_SUCCESS:
            return {
                ...state,
                error: null,
                alleFirmenIstLoading: false,
            };
        default:
            return state;
    }
};

export default organisation;
