export const FIRMA_ERSTELLEN_COMMAND_SUCCESS =
  "FIRMA_ERSTELLEN_COMMAND_SUCCESS";
export const FIRMA_ERSTELLEN_VIEW_DATA_ACTION_FAIL =
  "FIRMA_ERSTELLEN_VIEW_DATA_ACTION_FAIL";
export const FIRMA_ERSTELLEN_VIEW_DATA_ACTION_BEGIN =
  "FIRMA_ERSTELLEN_VIEW_DATA_ACTION_BEGIN";
export const FETCH_FIRMA_ERSTELLEN_VIEW_DATA_SUCCESS =
  "FETCH_FIRMA_ERSTELLEN_VIEW_DATA_SUCCESS";
export const RESET_FIRMA_ERSTELLEN_VIEW_DATA_SUCCESS =
  "RESET_FIRMA_ERSTELLEN_VIEW_DATA_SUCCESS";
export const EXISTIERT_FIRMA_SUCCESS = "EXISTIERT_FIRMA_SUCCESS";

import errorHandler from "../errorHandling.js";

const apiHost = window.Algo.apiHost,
  appInsights = window.Algo.appInsights,
  getRequestOptions = window.Algo.getRequestOptions;

export const fetchFirmaErstellenViewData = (data) => async (dispatch) => {
  appInsights.startTrackEvent("fetchFirmaErstellenViewData");
  dispatch({ type: FIRMA_ERSTELLEN_VIEW_DATA_ACTION_BEGIN });
  const response = await fetch(
    `${apiHost}/api/zuko/v3/${data.projektId}/firma-erstellen-view-data/`,
    getRequestOptions
  );

  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(error));
  }
  if (!response.ok) {
    if (json && !json.success) {
      dispatch(errorHandler(json));
      dispatch({
        type: FIRMA_ERSTELLEN_VIEW_DATA_ACTION_FAIL,
        error: json,
      });
      appInsights.stopTrackEvent("fetchFirmaErstellenViewData", {
        success: json.success,
        error: json,
      });
    } else {
      dispatch(errorHandler(response));
      dispatch({
        type: FIRMA_ERSTELLEN_VIEW_DATA_ACTION_FAIL,
        error: { errorMessage: response.toString() },
      });
      appInsights.stopTrackEvent("fetchFirmaErstellenViewData", {
        success: false,
        error: { errorMessage: response.toString() },
      });
    }
  }
  if (json?.success) {
    dispatch({
      type: FETCH_FIRMA_ERSTELLEN_VIEW_DATA_SUCCESS,
      payload: json.payload,
    });
    appInsights.stopTrackEvent("fetchFirmaErstellenViewData", {
      success: json.success,
    });
  }
};

export const existiertFirma = (data, projektId) => async (dispatch) => {
  appInsights.startTrackEvent("existiertFirma");
  dispatch({ type: FIRMA_ERSTELLEN_VIEW_DATA_ACTION_BEGIN });
  const response = await fetch(
    `${apiHost}/api/zuko/v3/${projektId}/existiert-firma`,
    {
      method: "POST",
      body: data,
    }
  );
  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(error));
  }
  if (!response.ok) {
    if (json && !json.success) {
      dispatch(errorHandler(json));
      dispatch({
        type: FIRMA_ERSTELLEN_VIEW_DATA_ACTION_FAIL,
        error: json,
      });
      appInsights.stopTrackEvent("existiertFirma", {
        success: json.success,
        error: json,
      });
    } else {
      dispatch(errorHandler(response));
      dispatch({
        type: FIRMA_ERSTELLEN_VIEW_DATA_ACTION_FAIL,
        error: { errorMessage: response.toString() },
      });
      appInsights.stopTrackEvent("existiertFirma", {
        success: false,
        error: { errorMessage: response.toString() },
      });
    }
  }
  if (json?.success) {
    dispatch({ type: EXISTIERT_FIRMA_SUCCESS, payload: json.payload });
    appInsights.stopTrackEvent("existiertFirma", { success: json.success });
    return json.payload;
  }
};

export const firmaErstellenCommand = (data, projektId, unterlagen) => async (dispatch) => {
    appInsights.startTrackEvent("firmaErstellenCommand");
    dispatch({ type: FIRMA_ERSTELLEN_VIEW_DATA_ACTION_BEGIN });

    let headers = new Headers();
    headers.append("Accept", "application/json");
    unterlagen.forEach((x) => {
      if (x.file?.name) data.append(x.eufefaId, x.file, x.file.name);
    });

    let request = new Request(
      `${apiHost}/api/zuko/v3/${projektId}/erstelle-firma`,
      {
        method: "POST",
        body: data,
        headers: headers,
        credentials: "include",
      }
    );
    const response = await fetch(request);
    let json;
    try {
      json = await response.json();
    } catch (error) {
      dispatch(errorHandler(error));
    }
    if (!response.ok) {
      if (json && !json.success) {
        dispatch(errorHandler(json));
        dispatch({
          type: FIRMA_ERSTELLEN_VIEW_DATA_ACTION_FAIL,
          error: json,
        });
        appInsights.stopTrackEvent("firmaErstellenCommand", {
          success: json.success,
          error: json,
        });
      } else {
        dispatch(errorHandler(response));
        dispatch({
          type: FIRMA_ERSTELLEN_VIEW_DATA_ACTION_FAIL,
          error: { errorMessage: response.toString() },
        });
        appInsights.stopTrackEvent("firmaErstellenCommand", {
          success: false,
          error: { errorMessage: response.toString() },
        });
      }
    }
    if (json?.success) {
      dispatch({
        type: FIRMA_ERSTELLEN_COMMAND_SUCCESS,
        payload: json.payload,
      });
      appInsights.stopTrackEvent("firmaErstellenCommand", {
        success: json.success,
      });
      return json.payload;
    }
  };

export const resetFirmaErstellenViewData = () => (dispatch) => {
  dispatch({ type: RESET_FIRMA_ERSTELLEN_VIEW_DATA_SUCCESS });
  appInsights.trackEvent({ name: "resetFirmaErstellenViewData" });
};

export const fetchEufefaVorlage = (data) => () => {
  const a = document.createElement("a");
  a.setAttribute(
    "href",
    `${apiHost}/api/zuko/v3/${data.projektId}/eufefa-vorlage/?eufefaId=${data.eufefaId}`
  );
  a.dispatchEvent(new MouseEvent("click"));
  appInsights.trackEvent({ name: "fetchEufefaVorlage" });
};
