export const PROJEKT_ACTION_FAIL = "PROJEKT_ACTION_FAIL";
export const PROJEKT_ACTION_BEGIN = "PROJEKT_ACTION_BEGIN";
export const FETCH_PROJEKT_SUCCESS = "FETCH_PROJEKT_SUCCESS";
export const RESET_PROJEKT_SUCCESS = "RESET_PROJEKT_SUCCESS";
export const POST_PROJEKT_ERSTELLEN_SUCCESS = "POST_PROJEKT_ERSTELLEN_SUCCESS";

//#region fetchProjektStammdaten
export const FETCH_PROJEKT_KONFIGURATION_ACTION_BEGIN =
    "FETCH_PROJEKT_KONFIGURATION_ACTION_BEGIN";
export const FETCH_PROJEKT_KONFIGURATION_ACTION_FAIL =
    "FETCH_PROJEKT_KONFIGURATION_ACTION_FAIL";
export const FETCH_PROJEKT_KONFIGURATION_ACTION_SUCCESS =
    "FETCH_PROJEKT_KONFIGURATION_ACTION_SUCCESS";
export const RESET_PROJEKT_STAMMDATEN_SUCCESS =
    "RESET_PROJEKT_STAMMDATEN_SUCCESS";
//#endregion
import errorHandler from "../errorHandling.js";
import moment from "moment";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;
let abortProjektKonfiguration;

export const resetProjekt = () => (dispatch) => {
    dispatch({ type: RESET_PROJEKT_SUCCESS });
    appInsights.trackEvent({ name: "resetProjekt" });
};

export const cancelProjektStammdatenRequests = () => (dispatch) => {
    try {
        abortProjektKonfiguration?.abort();
    } catch (e) {
        console.log(e);
    }

    dispatch({ type: RESET_PROJEKT_STAMMDATEN_SUCCESS });
    appInsights.trackEvent({ name: "cancelProjektStammdatenRequests" });
};

export const fetchProjekt = (projektId) => async (dispatch) => {
    appInsights.startTrackEvent("fetchProjekt");
    dispatch({ type: PROJEKT_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/projekt/v2/${projektId}/projekt.json/`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: PROJEKT_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("fetchProjekt", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: PROJEKT_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("fetchProjekt", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({ type: FETCH_PROJEKT_SUCCESS, payload: json.payload });
        appInsights.stopTrackEvent("fetchProjekt", { success: json.success });
        return json.payload;
    }
};

export const post_projektErstellenAsync = (e) => async (dispatch) => {
    appInsights.startTrackEvent("post_projektErstellenAsync");
    dispatch({ type: PROJEKT_ACTION_BEGIN });
    const form = e.srcElement || e.target;
    const url = form.dataset.url;
    const formData = new FormData(form);
    const response = await fetch(url, {
        method: "POST",
        body: formData,
    });
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: PROJEKT_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("fetchProjekt", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: PROJEKT_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("fetchProjekt", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: POST_PROJEKT_ERSTELLEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchProjekt", { success: json.success });
        return json.payload;
    }
};

export const fetchProjektKonfiguration = (projektId) => async (dispatch) => {
    appInsights.startTrackEvent("fetchProjektKonfiguration");
    dispatch({
        type: FETCH_PROJEKT_KONFIGURATION_ACTION_BEGIN,
    });
    abortProjektKonfiguration =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/projekt/admin/${projektId}/projekt-konfiguration`,
        {
            method: "GET",
            signal: abortProjektKonfiguration.signal,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_PROJEKT_KONFIGURATION_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchProjektKonfiguration", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_PROJEKT_KONFIGURATION_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchProjektKonfiguration", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_PROJEKT_KONFIGURATION_ACTION_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchProjektKonfiguration", {
            success: json.success,
        });
        return json.payload;
    }
};
export const aktualisiereProjektKonfiguration = (e) => async (dispatch) => {
    appInsights.startTrackEvent("aktualisiereProjektKonfiguration");
    dispatch({ type: FETCH_PROJEKT_KONFIGURATION_ACTION_BEGIN });
    const form = e.srcElement || e.target,
        url = form.dataset.url,
        formData = new FormData(form);
    formData?.forEach((value, key) => {
        if (key?.indexOf("Oeffnung") > -1 || key?.indexOf("Schliessung") > -1) {
            formData.set(key, moment(value, "HH:mm").utc().format("HH:mm"));
        }
    });

    const response = await fetch(url, {
        method: "POST",
        body: formData,
    });
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_PROJEKT_KONFIGURATION_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("aktualisiereProjektKonfiguration", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_PROJEKT_KONFIGURATION_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("aktualisiereProjektKonfiguration", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_PROJEKT_KONFIGURATION_ACTION_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("aktualisiereProjektKonfiguration", {
            success: json.success,
        });
        return json.payload;
    }
};

export const holeDSGVO =
    (projektId, datenschutzverordnungFielName) => async (dispatch) => {
        const response = await fetch(
            `${apiHost}/api/projekt/admin/${projektId}/download-dsgvo?datenschutzverordnungFielName=${datenschutzverordnungFielName}`,
            getRequestOptions
        );
        let json;
        try {
            json = await response.blob();
            return json;
        } catch (error) {
            dispatch(errorHandler(error));
        }
    };
