const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;
    export const fetchModulKontaktCard = async (sprache, projektId) => {
        appInsights.startTrackEvent("fetchModulKontaktCard");
        const response = await fetch(
            `${apiHost}/api/container/v1/${sprache}/${projektId}/modul-kontakt-card`,
            {
                method: "GET",
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            return error;
        }
        if (!response.ok) {
            if (json && !json.success) {
                appInsights.stopTrackEvent("fetchModulKontaktCard", {
                    success: json.success,
                    error: json,
                });
                return json;
            } else {
                appInsights.stopTrackEvent("fetchModulKontaktCard", {
                    success: response.ok,
                    error: response,
                });
                return response;
            }
        }
        if (json?.success) {
            appInsights.stopTrackEvent("fetchModulKontaktCard", {
                success: json.success,
            });
            return json;
        }
    };
    
    export const fetchUnterlagenCard = async (sprache, projektId) => {
        appInsights.startTrackEvent("fetchUnterlagenCard");
        const response = await fetch(
            `${apiHost}/api/container/v1/${sprache}/${projektId}/unterlagen-card`,
            {
                method: "GET",
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            return error;
        }
        if (!response.ok) {
            if (json && !json.success) {
                appInsights.stopTrackEvent("fetchUnterlagenCard", {
                    success: json.success,
                    error: json,
                });
                return json;
            } else {
                appInsights.stopTrackEvent("fetchUnterlagenCard", {
                    success: response.ok,
                    error: response,
                });
                return response;
            }
        }
        if (json?.success) {
            appInsights.stopTrackEvent("fetchUnterlagenCard", {
                success: json.success,
            });
            return json;
        }
    };

    export const fetchContainerUnterlageExport = async (
        language,
        projektId,
        unterlageId
    ) => {
        let a = document.createElement("a");
        a.setAttribute(
            "href",
            `${apiHost}/api/container/v1/${language}/${projektId}/download-unterlage/?unterlageId=${unterlageId}`
        );
        a.dispatchEvent(new MouseEvent("click"));
        appInsights.trackEvent({ name: "fetchContainerUnterlageExport" });
    };
