export const TRANSLATIONS_DE = {
    ALLE: "Alle",
    ANMELDEN: {
        ANGEMELDET_BLEIBEN: "Angemeldet bleiben",
        PASSWORT: "Passwort",
        WILLKOMMEN: "Willkommen",
    },
    DATEN_LADEN: "Daten werden geladen...",
    DATUM: "Datum",
    DE: "Deutsch",
    EN: "Englisch",
    ERROR: "Fehler",
    ERROR_HANDLER: {
        ES_IST_EIN_FEHLER_AUFGETRETEN: "Es ist ein Fehler aufgetreten",
        FEHLER_AUFGETRETEN: "Es ist ein Fehler aufgetreten",
        FOLGENDE_FEHLER: "Folgende Fehler sind aufgetreten",
        NICHT_ANGEMELDET: "Sie sind nicht mehr angemeldet.",
        NICHT_GEFUNDEN: "Anfrage konnte nicht gefunden werden",
        SERVER_ERROR: "Server Fehler",
        SERVER_NICHT_ERREICHBAR: "Server aktuell nicht erreichbar",
        ZEITUEBERSCHREITUNG:
            "Der Vorgang wurde wegen Zeitüberschreitung abgebrochen, bitte versuchen Sie es später erneut.",
    },
    EXCEL_EXPORT: "Excel-Tabelle exportieren",
    FIRMA: "Firma",
    GESAMT: "Gesamtes",
    IMPRESSUM: {
        ANGABEN: "Angaben gemäß § 5 TMG",
        ANGABEN_BERUFSHAFTPFLICHT: "Name und Sitz der Gesellschaft",
        EINTRAGUNG: "Eintragung im Handelsregister",
        GELTUNGSRAUM: "Geltungsraum der Versicherung",
        KONTAKT: "Kontakt",
        REGISTEREINTRAG: "Registereintrag",
        REGISTERGERICHT: "Registergericht",
        REGISTERNUMMER: "Registernummer",
        TITEL_ANGABEN_BERUFSHAFTPFLICHT:
            " Angaben zur Berufshaftpflichtversicherung",
        UMSATZSTEUER_ID: "Umsatzsteuer-ID",
        UMSATZSTEUER_IDENTIFIKATIONSNUMMER:
            " Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz",
        VERTRETEN: "Vertreten durch",
    },
    INFO: "Info",
    LANGUAGE: {
        MODAL_TITLE: "Sprache in ALGO aktualisieren",
        MODAL_BODY:
            "Möchten Sie in ALGO neuladen um die Sprache vollständig anzuwenden?",
    },
    MAILADRESSE: "E-Mailadresse",
    MEINE: "Meine",
    MEINE_PROJEKTE: {
        DSGVO: "Datenschutzeinwilligung",
        ERSTELLEN: "Projekte erstellen",
        JETZT_ZUSTIMMEN: "Jetzt zustimmen",
        KEINE_DSGVO_VORHANDEN:
            "Keine projektspezifische Datenschutzeinwilligung vorhanden",
        PROJEKT: "Projekt",
        PROJEKTBETEILIGTE: "Projektbeteiligte",
        PROJEKTROLLE: "Projektrolle",
        SCHNELLZUGRIFF: "Schnellzugriff",
        TITEL: "Projekte",
        ZUSTIMMUNG_WIDERRUFEN: "Zustimmung widerrufen",
    },
    NACHNAME: "Nachname",
    NAVIGATIONS_ELEMENTE: {
        AVISIERUNG: "Avisierung",
        ABBRECHEN: "Abbrechen",
        ABMELDEN: "Abmelden",
        ABSCHICKEN: "Abschicken",
        ANMELDEN: "Anmelden",
        ARCHIV: "Archiv",
        BAUSTELLE: "Baustelle",
        BUCHUNGEN: "Buchungen",
        CONTAINER: "Container",
        CONTAINER_BUCHUNGEN:
            "Container - Buchungen (Hier kann die Containerbuchung angefragt werden)",
        CONTAINER_TOGGLE: "Container",
        DASHBOARD: "Dashboard",
        DASHBOARD_AUSWERTUNGEN: "Dashboard - Auswertungen",
        DATENSCHUTZINFORMATION: "Datenschutzinformation und AGB",
        EINSTELLUNGEN: "Einstellungen",
        FIRMEN: "Firmen",
        HANDBUCH_AG: "Handbuch für Auftraggeber",
        HANDBUCH_ANMELDUNG_UND_REGISTRATION:
            "Handbuch für Anmeldung & Registration",
        HANDBUCH_FIRMEN: "Handbuch für Firmen",
        IMPRESSUM: "Impressum",
        INFORMATION: "Information",
        JA: "Ja",
        LOESCHEN: "Löschen",
        MEINE_PROJEKTE: "Meine Projekte",
        MITARBEITER: "Mitarbeitende",
        NEWS: "News",
        NEWS_UEBERSICHT: "Übersicht - News-Modul",
        ORGANISATION: "Organisation",
        PASSWORT_VERGESSEN: "Passwort vergessen",
        PROJEKTBETEILIGTE: "Projektbeteiligte",
        PROJEKT_PROJEKTBETEILIGTE: "Projekt - Projektbeteiligte",
        PROJEKTROLLEN: "Projektrollen",
        REGISTRIEREN: "Registrieren",
        RELOAD: "Neuladen",
        SCHLIESSEN: "Schließen",
        TRANSPORTE: "Transporte",
        TRANSPORTE_AVISIERUNG:
            "Avisierung - Transporte (Hier können Anlieferungen & Abholungen angemeldet werden)",
        UEBERSICHT: "Übersicht",
        UNTERLAGEN: "Unterlagen",
        WEITER: "Weiter",
        ZUKO_ORGANSIATION:
            "Zutrittskontrolle - Organisation (Hier kann die Personal- & Firmenerstellung erfolgen)",
        ZUKO_SERVER: "Zuko Server",
        ZURUECK_ZU_PROJEKTEN: "Zurück zu Projekte",
        ZURUECK: "Zurück",
        ZURUECKSETZEN: "Zurücksetzen",
        ZUTRITTSKONTROLLE: "Zutrittskontrolle",
        ZUTRITTSPUNKTE: "Zutrittspunkte",
    },
    PASSWORT_VERGESSEN: {
        PASSWORT: "Passwort",
        PASSWORT_VERGESSEN_TITEL: "Haben Sie Ihr Passwort vergessen?",
        PASSWORT_WIEDERHOLEN: "Passwort wiederholen",
        PASSWORT_ZURUECKSETZEN: "Passwort zurücksetzen:",
        SCHLÜSSEL: "Schlüssel",
        SCHLÜSSEL_TITEL: "Bitte geben Sie den zugeschickten Schlüssel ein:",
        ERFOLGREICH_ZURUECKGESETZT: "Passwort wurde erfolgreich zurückgesetzt.",
    },
    PDF_EXPORT: "Pdf-Liste exportieren",
    REGISTRIEREN: {
        DATENSCHUTZ_TEIL1: "Informationen zur Datenverarbeitung können Sie unserer",
        DATENSCHUTZ_TEIL2: "Datenschutzerklärung",
        DATENSCHUTZ_TEIL3: "entnehmen.",
        DATENSCHUTZ_AKZEPTIEREN_TEIL1: "Ich stimme den",
        DATENSCHUTZ_AKZEPTIEREN_TEIL2:
            "allgemeinen Nutzungsbedingungen",
        DATENSCHUTZ_AKZEPTIEREN_TEIL3: "zu",
        PASSWORT: "Passwort",
        PASSWORT_WIEDERHOLEN: "Passwort wiederholen",
        REGISTRIEREN: "Registrieren",
        UNTERNEHMEN: "Unternehmen",
    },
    TOPBAR: {
        ABMELDUNG_ERFOLGREICH: "Sie wurden erfolgreich abgemeldet",
        MODAL_BODY:
            "Wählen Sie unten 'Abmelden', wenn Sie bereit sind, Ihre aktuelle Sitzung zu beenden.",
        MODAL_TITLE: "Wollen Sie sich wirklich abmelden?",
    },
    VORNAME: "Vorname",
    SPALTEN_FILTERN: "Spalten filtern",
    SUCCESS: "Erfolgreich",
    SUPPORT_NUMMER_KOPIERT: "Die Telefonnummer des Supports wurde kopiert",
};

export default TRANSLATIONS_DE;
