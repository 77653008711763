const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;

export const fetchAbrechnungsdetailsUebersichtExport = (
    sprache,
    projektId,
    projektName
) => {
    let a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/container/v1/${sprache}/${projektId}/abrechnungsdetails-uebersicht-export?projektName=${projektName}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "fetchAbrechnungsdetailsUebersichtExport" });
};

export const fetchAbrechnungsdetailsExport = (
    sprache,
    projektId,
    buchungId,
    projektName
) => {
    let a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/container/v1/${sprache}/${projektId}/abrechnungsdetails-export?buchungId=${buchungId}&projektName=${projektName}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "fetchAbrechnungsdetailsExport" });
};

export const fetchAbrechnungsdetail = async (sprache, projektId, buchungId) => {
    appInsights.startTrackEvent("fetchAbrechnungsdetail");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/abrechnungsdetails?buchungId=${buchungId}`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchAbrechnungsdetail", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchAbrechnungsdetail", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchAbrechnungsdetail", {
            success: json.success,
        });
        return json;
    }
};
