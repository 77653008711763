export const FIRMEN_ACTION_FAIL = "FIRMEN_ACTION_FAIL";
export const FIRMEN_ACTION_BEGIN = "FIRMEN_ACTION_BEGIN";
export const FETCH_FIRMEN_SUCCESS = "FETCH_FIRMEN_SUCCESS";
export const RESET_FIRMEN_SUCCESS = "RESET_FIRMEN_SUCCESS";
export const FETCH_FIRMEN_AUSWERTUNGEN_ACTION_BEGIN =
    "FETCH_FIRMEN_AUSWERTUNGEN_ACTION_BEGIN";
export const FETCH_FIRMEN_AUSWERTUNGEN_ACTION_FAIL =
    "FETCH_FIRMEN_AUSWERTUNGEN_ACTION_FAIL";
export const FETCH_FIRMEN_AUSWERTUNGEN_SUCCESS =
    "FETCH_FIRMEN_AUSWERTUNGEN_SUCCESS";
export const RESET_FIRMEN_AUSWERTUNGEN_SUCCESS =
    "RESET_FIRMEN_AUSWERTUNGEN_SUCCESS";
export const FETCH_FIRMENHIERARCHIE_SUCCESS = "FETCH_FIRMENHIERARCHIE_SUCCESS";
export const RESET_FIRMENHIERARCHIE_SUCCESS = "RESET_FIRMENHIERARCHIE_SUCCESS";
export const FETCH_FIRMENHIERARCHIE_NETZWERK_SUCCESS =
    "FETCH_FIRMENHIERARCHIE_NETZWERK_SUCCESS";
export const FETCH_FIRMENHIERACHIE_ACTION_BEGIN =
    "FETCH_FIRMENHIERACHIE_ACTION_BEGIN";
export const FETCH_FIRMENHIERACHIE_ACTION_FAIL =
    "FETCH_FIRMENHIERACHIE_ACTION_FAIL";

import errorHandler from "./../errorHandling.js";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;
let abortFirmenHierachie,
    abortFirmenAuswertungen,
    abortFirmenAuswertungenSpalten;

export const resetFirmen = () => (dispatch) => {
    dispatch({ type: RESET_FIRMEN_SUCCESS });
    appInsights.trackEvent({ name: "resetFirmen" });
};

export const cancelFirmenhierarchie = () => (dispatch) => {
    dispatch({ type: RESET_FIRMENHIERARCHIE_SUCCESS });
    try {
        abortFirmenHierachie?.abort();
    } catch (e) {
        console.log(e);
    }
    appInsights.trackEvent({ name: "cancelFirmenhierarchie" });
};

export const fetchFirmen = (projektId) => async (dispatch) => {
    appInsights.startTrackEvent("fetchFirmen");
    dispatch({ type: FIRMEN_ACTION_BEGIN });

    const response = await fetch(
        `${apiHost}/api/zuko/v3/${projektId}/firmen/`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }

    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FIRMEN_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("fetchFirmen", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FIRMEN_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("fetchFirmen", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_FIRMEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchFirmen", { success: json.success });
    }
};

export const resetFirmenhierarchie = () => (dispatch) => {
    dispatch({ type: RESET_FIRMENHIERARCHIE_SUCCESS });
    appInsights.trackEvent({ name: "resetFirmenhierarchie" });
};

export const fetchFirmenhierarchie = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchFirmenhierarchie");

    dispatch({ type: FETCH_FIRMENHIERACHIE_ACTION_BEGIN });
    abortFirmenHierachie =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/firmenhierarchie-firmen`,
        { method: "GET", signal: abortFirmenHierachie.signal }
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_FIRMENHIERACHIE_ACTION_FAIL,

                type: FIRMEN_ACTION_FAIL,

                error: json,
            });
            appInsights.stopTrackEvent("fetchFirmenhierarchie", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_FIRMENHIERACHIE_ACTION_FAIL,

                type: FIRMEN_ACTION_FAIL,

                error: response,
            });
            appInsights.stopTrackEvent("fetchFirmenhierarchie", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_FIRMENHIERARCHIE_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchFirmenhierarchie", {
            success: json.success,
        });
    }
};

export const fetchFirmenhierarchieNetzwerk = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchFirmenhierarchieNetzwerk");
    dispatch({ type: FIRMEN_ACTION_BEGIN });

    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/firmenhierarchie-netzwerk?firmaId=${data.firmenId}`,
        getRequestOptions
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FIRMEN_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("fetchFirmenhierarchieNetzwerk", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FIRMEN_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("fetchFirmenhierarchieNetzwerk", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_FIRMENHIERARCHIE_NETZWERK_SUCCESS,
            payload: json.payload,
        });

        appInsights.stopTrackEvent("fetchFirmenhierarchieNetzwerk", {
            success: json.success,
        });
    }
};

export const cancelFirmenauswertungen = () => (dispatch) => {
    dispatch({ type: RESET_FIRMEN_AUSWERTUNGEN_SUCCESS });
    try {
        abortFirmenAuswertungen?.abort();
        abortFirmenAuswertungenSpalten?.abort();
    } catch (e) {
        console.log(e);
    }
    appInsights.trackEvent({ name: "cancelFirmenhierarchie" });
};

export const fetchFirmenAuswertungen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchFirmenAuswertungen");
    dispatch({ type: FETCH_FIRMEN_AUSWERTUNGEN_ACTION_BEGIN });
    abortFirmenAuswertungen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/firmen-zutrittsereignisse-auswertungen?startdatum=${data.startzeit}&enddatum=${data.endzeit}&raumzonenName=${data.raumzonenName}`,
        { method: "GET", signal: abortFirmenAuswertungen.signal }
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_FIRMEN_AUSWERTUNGEN_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("fetchFirmenAuswertungen", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_FIRMEN_AUSWERTUNGEN_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("fetchFirmenAuswertungen", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_FIRMEN_AUSWERTUNGEN_SUCCESS,
            payload: json.payload,
        });

        appInsights.stopTrackEvent("fetchFirmenAuswertungen", {
            success: json.success,
        });
        return json.payload;
    }
};

export const fetchFirmenZutrittsereignisseAuswertungenSpalten =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchFirmenZutrittsereignisseAuswertungenSpalten"
        );
        dispatch({ type: FIRMEN_ACTION_BEGIN });
        abortFirmenAuswertungenSpalten =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/firmen-zutrittsereignisse-auswertungen-spalten?startdatum=${data.startzeit}&enddatum=${data.endzeit}&raumzonenName=${data.raumzonenName}`,
            { method: "GET", signal: abortFirmenAuswertungenSpalten.signal }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FIRMEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchFirmenZutrittsereignisseAuswertungenSpalten",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FIRMEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchFirmenZutrittsereignisseAuswertungenSpalten",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_FIRMEN_AUSWERTUNGEN_SUCCESS,
                payload: json.payload,
            });

            appInsights.stopTrackEvent(
                "fetchFirmenZutrittsereignisseAuswertungenSpalten",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchFirmenZutrittsereignisseAuswertungenFilter =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchFirmenZutrittsereignisseAuswertungenFilter"
        );
        dispatch({ type: FIRMEN_ACTION_BEGIN });
        abortFirmenAuswertungenSpalten =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/firmen-zutrittsereignisse-auswertungen-filter`,
            { method: "GET", signal: abortFirmenAuswertungenSpalten.signal }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FIRMEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchFirmenZutrittsereignisseAuswertungenFilter",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FIRMEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchFirmenZutrittsereignisseAuswertungenFilter",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_FIRMEN_AUSWERTUNGEN_SUCCESS,
                payload: json.payload,
            });

            appInsights.stopTrackEvent(
                "fetchFirmenZutrittsereignisseAuswertungenFilter",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const resetAuswertungen = () => (dispatch) => {
    try {
        abortFirmenAuswertungen?.abort();
    } catch (e) {
        console.log(e);
    }

    dispatch({ type: RESET_AUSWERTUNGEN_SUCCESS });
    appInsights.trackEvent({ name: "resetAuswertungen" });
};
