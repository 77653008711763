//#region ALLE
export const MEHRERE_MITARBEITER_ACTION_FAIL =
    "MEHRERE_MITARBEITER_ACTION_FAIL";
export const MEHRERE_MITARBEITER_ACTION_BEGIN =
    "MEHRERE_MITARBEITER_ACTION_BEGIN";
//#endregion

//#region Übersicht Mehrere Mitarbeiter
export const FETCH_MEHRERE_MITARBEITER_SUCCESS =
    "FETCH_MEHRERE_MITARBEITER_SUCCESS";
export const RESET_MEHRERE_MITARBEITER_SUCCESS =
    "RESET_MEHRERE_MITARBEITER_SUCCESS";
//#endregion

//#region Auswertungen Zutrittsereignsisse
export const FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SPALTEN_BEGIN =
    "FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SPALTEN_BEGIN";
export const FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SPALTEN_FAIL =
    "FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SPALTEN_FAIL";
export const FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SPALTEN_SUCCESS =
    "FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SPALTEN_SUCCESS";
export const PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_ACTION_FAIL =
    "PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_ACTION_FAIL";
export const PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_ACTION_BEGIN =
    "PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_ACTION_BEGIN";
export const FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SUCCESS =
    " FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SUCCESS";
export const RESET_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SUCCESS =
    "RESET_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SUCCESS";
//#endregion

//#region Aktuelle Besetzung
export const FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_ACTION_BEGIN =
    "FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_ACTION_BEGIN";
export const FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_ACTION_FAIL =
    "FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_ACTION_FAIL";
export const FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_SUCCESS =
    "FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_SUCCESS";
export const RESET_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_SUCCESS =
    "RESET_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_SUCCESS";
//#endregion

//#region Baustellenausweise
export const FETCH_MEHRERE_MITARBEITER_BAUSTELLENAUSWEISE_SUCCESS =
    "FETCH_MEHRERE_MITARBEITER_BAUSTELLENAUSWEISE_SUCCESS";
export const RESET_MEHRERE_MITARBEITER_BAUSTELLENAUSWEISE_SUCCESS =
    "RESET_MEHRERE_MITARBEITER_BAUSTELLENAUSWEISE_SUCCESS";
//#endregion

//#region Mindestlohnerklaerung
export const MINDESTLOHNPFLICHT_AKTIVIERT_ACTION_BEGIN =
    "MINDESTLOHNPFLICHT_AKTIVIERT_ACTION_BEGIN";
export const MINDESTLOHNPFLICHT_AKTIVIERT_ACTION_SUCCESS =
    "MINDESTLOHNPFLICHT_AKTIVIERT_ACTION_SUCCESS";
export const MINDESTLOHNPFLICHT_AKTIVIERT_ACTION_FAIL =
    "MINDESTLOHNPFLICHT_AKTIVIERT_ACTION_FAIL";
//#endregion

import errorHandler from "../errorHandling.js";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;
let abortMehrereMitarbeiterBaustellenausweise,
    abortAktuelleBesetzung,
    abortPersonalAuswertungen,
    abortPersonalAuswertungenSpalten;

//#region Baustellenausweis

export const fetchMehrereMitarbeiterBaustellenausweise =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchMehrereMitarbeiterBaustellenausweise"
        );
        dispatch({ type: MEHRERE_MITARBEITER_ACTION_BEGIN });
        abortMehrereMitarbeiterBaustellenausweise =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${data.projektId}/mehrere-mitarbeiter-baustellenausweise?raumzonenName=${data.raumzonenName}`,
            {
                method: "GET",
                signal: abortMehrereMitarbeiterBaustellenausweise.signal,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: MEHRERE_MITARBEITER_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchMehrereMitarbeiterBaustellenausweise",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: MEHRERE_MITARBEITER_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchMehrereMitarbeiterBaustellenausweise",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_MEHRERE_MITARBEITER_BAUSTELLENAUSWEISE_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchMehrereMitarbeiterBaustellenausweise",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchMehrereMitarbeiterBaustellenausweiseFilter =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchMehrereMitarbeiterBaustellenausweiseFilter"
        );
        dispatch({ type: MEHRERE_MITARBEITER_ACTION_BEGIN });
        abortMehrereMitarbeiterBaustellenausweise =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/mehrere-mitarbeiter-baustellenausweise-filter/`,
            {
                method: "GET",
                signal: abortMehrereMitarbeiterBaustellenausweise.signal,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: MEHRERE_MITARBEITER_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchMehrereMitarbeiterBaustellenausweiseFilter",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: MEHRERE_MITARBEITER_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchMehrereMitarbeiterBaustellenausweiseFilter",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_MEHRERE_MITARBEITER_BAUSTELLENAUSWEISE_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchMehrereMitarbeiterBaustellenausweiseFilter",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const resetMehrereMitarbeiterBaustellenausweise = () => (dispatch) => {
    try {
        abortMehrereMitarbeiterBaustellenausweise?.abort();
    } catch (e) {
        console.log(e);
    }
    dispatch({ type: RESET_MEHRERE_MITARBEITER_BAUSTELLENAUSWEISE_SUCCESS });
    appInsights.trackEvent({
        name: "resetMehrereMitarbeiterBaustellenausweise",
    });
};

//#endregion

//#region Zutrittsereignisse Auswertungen
export const cancelPersonalAuswertungen = () => (dispatch) => {
    try {
        abortPersonalAuswertungen?.abort();
        abortPersonalAuswertungenSpalten?.abort();
    } catch (e) {
        console.log(e);
    }
    dispatch({
        type: RESET_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SUCCESS,
    });
    appInsights.trackEvent({ name: "resetMehrereMitarbeiterAuswertungen" });
};

export const fetchPersonalAuswertungen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchPersonalAuswertungen");
    dispatch({ type: PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_ACTION_BEGIN });

    abortPersonalAuswertungen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/personal-zutrittsereignisse-auswertungen?startdatum=${data.startzeit}&enddatum=${data.endzeit}&filter=${data.option}&raumzonenName=${data.raumzonenName}`,
        {
            method: "GET",
            signal: abortPersonalAuswertungen.signal,
        }
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("fetchPersonalAuswertungen", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(json));
            dispatch({
                type: PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("fetchPersonalAuswertungen", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchMehrereMitarbeiterAuswertungen", {
            success: json.success,
        });
        return json.payload;
    }
};

export const fetchPersonalZutrittsereignisseAuswertungenSpalten =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchPersonalZutrittsereignisseAuswertungenSpalten"
        );
        dispatch({
            type: FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SPALTEN_BEGIN,
        });
        abortPersonalAuswertungenSpalten =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/personal-zutrittsereignisse-auswertungen-spalten?startdatum=${data.startzeit}&enddatum=${data.endzeit}&raumzonenName=${data.raumzonenName}`,
            { method: "GET", signal: abortPersonalAuswertungenSpalten.signal }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SPALTEN_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchPersonalZutrittsereignisseAuswertungenSpalten",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SPALTEN_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchPersonalZutrittsereignisseAuswertungenSpalten",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SPALTEN_SUCCESS,
                payload: json.payload,
            });

            appInsights.stopTrackEvent(
                "fetchPersonalZutrittsereignisseAuswertungenSpalten",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchPersonalZutrittsereignisseAuswertungenFilter =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchPersonalZutrittsereignisseAuswertungenFilter"
        );
        dispatch({
            type: PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_ACTION_BEGIN,
        });

        abortPersonalAuswertungen =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/personal-zutrittsereignisse-auswertungen-filter`,
            {
                method: "GET",
                signal: abortPersonalAuswertungen.signal,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchPersonalZutrittsereignisseAuswertungenFilter",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(json));
                dispatch({
                    type: PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchPersonalZutrittsereignisseAuswertungenFilter",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchPersonalZutrittsereignisseAuswertungenFilter",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };
//#endregion

//#region AktuelleBesetzung
export const resetMehrereMitarbeiterAktuelleBesetzung = () => (dispatch) => {
    try {
        abortAktuelleBesetzung?.abort();
    } catch (e) {
        console.log(e);
    }
    dispatch({ type: RESET_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_SUCCESS });
    appInsights.trackEvent({
        name: "resetMehrereMitarbeiterAktuelleBesetzung",
    });
};

export const fetchMehrereMitarbeiterAktuelleBesetzung =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("fetchMehrereMitarbeiterAktuelleBesetzung");
        dispatch({
            type: FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_ACTION_BEGIN,
        });
        abortAktuelleBesetzung =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${data.projektId}/mehrere-mitarbeiter-aktuelle-besetzung?filter=${data.option}&raumzonenName=${data.raumzonenName}`,
            {
                method: "GET",
                signal: abortAktuelleBesetzung.signal,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchMehrereMitarbeiterAktuelleBesetzung",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchMehrereMitarbeiterAktuelleBesetzung",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchMehrereMitarbeiterAktuelleBesetzung",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchMehrereMitarbeiterAktuelleBesetzungFilter =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchMehrereMitarbeiterAktuelleBesetzungFilter"
        );
        dispatch({
            type: FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_ACTION_BEGIN,
        });
        abortAktuelleBesetzung =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${data.projektId}/mehrere-mitarbeiter-aktuelle-besetzung-filter`,
            {
                method: "GET",
                signal: abortAktuelleBesetzung.signal,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchMehrereMitarbeiterAktuelleBesetzungFilter",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchMehrereMitarbeiterAktuelleBesetzungFilter",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchMehrereMitarbeiterAktuelleBesetzungFilter",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const MindestlohnpflichtAktiviert = (projektId) => async (dispatch) => {
    appInsights.startTrackEvent("MindestlohnpflichtAktiviert");
    dispatch({ type: MINDESTLOHNPFLICHT_AKTIVIERT_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${projektId}/mindestlohnpflicht-aktiviert`,
        getRequestOptions
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: MINDESTLOHNPFLICHT_AKTIVIERT_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("MindestlohnpflichtAktiviert", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: MINDESTLOHNPFLICHT_AKTIVIERT_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("MindestlohnpflichtAktiviert", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: MINDESTLOHNPFLICHT_AKTIVIERT_ACTION_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("MindestlohnpflichtAktiviert", {
            success: json.success,
        });
        return json.payload;
    }
};

//#endregion
