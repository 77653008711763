export const FETCH_TAGESAUSWERTUNG_RESET_SUCCESS =
    "FETCH_TAGESAUSWERTUNG_RESET_SUCCESS";
export const TAGESAUSWERTUNG_ACTION_FAIL = "TAGESAUSWERTUNG_ACTION_FAIL";
//#region Anwesenheit gesamt
export const TAGESAUSWERTUNG_ANWESENHEIT_GESAMT_ACTION_BEGIN =
    "TAGESAUSWERTUNG_ANWESENHEIT_GESAMT_ACTION_BEGIN";
export const FETCH_TAGESAUSWERTUNG_ANWESENHEIT_GESAMT_SUCCESS =
    "FETCH_TAGESAUSWERTUNG_ANWESENHEIT_GESAMT_SUCCESS";
//#endregion
//#region gesperrte Ausweise gesamt
export const TAGESAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_ACTION_BEGIN =
    "TAGESAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_ACTION_BEGIN";
export const FETCH_TAGESAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_SUCCESS =
    "FETCH_TAGESAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_SUCCESS";
//#endregion

//#region Anwesenheit Firmen
export const TAGESAUSWERTUNG_ANWESENHEIT_FIRMEN_ACTION_BEGIN =
    "TAGESAUSWERTUNG_ANWESENHEIT_FIRMEN_ACTION_BEGIN";
export const FETCH_TAGESAUSWERTUNG_ANWESENHEIT_FIRMEN_SUCCESS =
    "FETCH_TAGESAUSWERTUNG_ANWESENHEIT_FIRMEN_SUCCESS";
//#endregion

//#region Sperrgründe Tagesablauf
export const TAGESAUSWERTUNG_SPERRGRUENDE_TAGESABLAUF_ACTION_BEGIN =
    "TAGESAUSWERTUNG_SPERRGRUENDE_TAGESABLAUF_ACTION_BEGIN";
export const FETCH_TAGESAUSWERTUNG_SPERRGRUENDE_TAGESABLAUF_SUCCESS =
    "FETCH_TAGESAUSWERTUNG_SPERRGRUENDE_TAGESABLAUF_SUCCESS";
//#endregion

//#region Anwesenheit im Tagesablauf
export const TAGESAUSWERTUNG_ANWESENHEIT_TAGESABLAUF_ACTION_BEGIN =
    "TAGESAUSWERTUNG_ANWESENHEIT_TAGESABLAUF_ACTION_BEGIN";
export const FETCH_TAGESAUSWERTUNG_ANWESENHEIT_TAGESABLAUF_SUCCESS =
    "FETCH_TAGESAUSWERTUNG_ANWESENHEIT_TAGESABLAUF_SUCCESS";
//#endregion
import { showToast } from "../algo-app";
import errorHandler from "../errorHandling.js";
import { translate } from "../../../../src/i18n.js";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;

let abortAnwesenheitGesamt,
    abortGesperrteAusweiseGesamt,
    abortAnwesenheitFirmen,
    abortSperrgründeTagesablauf,
    abortAnwesenheitTagesablauf;

export const cancelTagesRequests = () => (dispatch) => {
    try {
        abortAnwesenheitGesamt?.abort(),
            abortGesperrteAusweiseGesamt?.abort(),
            abortAnwesenheitFirmen?.abort(),
            abortSperrgründeTagesablauf?.abort(),
            abortAnwesenheitTagesablauf?.abort();
    } catch (error) {
        console.log(error);
    }
    dispatch({ type: FETCH_TAGESAUSWERTUNG_RESET_SUCCESS });
    appInsights.trackEvent({ name: "cancelTagesRequests" });
};

export const fetchTagesauwertungAnwesenheitGesamt =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("fetchTagesauwertungAnwesenheitGesamt");
        dispatch({ type: TAGESAUSWERTUNG_ANWESENHEIT_GESAMT_ACTION_BEGIN });
        abortAnwesenheitGesamt =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-tagesauswertung-anwesenheit-gesamt/?datum=${data.datum}`,
            { method: "GET", signal: abortAnwesenheitGesamt.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: TAGESAUSWERTUNG_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchTagesauwertungAnwesenheitGesamt",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: TAGESAUSWERTUNG_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchTagesauwertungAnwesenheitGesamt",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_TAGESAUSWERTUNG_ANWESENHEIT_GESAMT_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("fetchTagesauwertungAnwesenheitGesamt", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const fetchTagesauwertungGesperrterMitarbeiterGesamt =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchTagesauwertungGesperrterMitarbeiterGesamt"
        );
        dispatch({
            type: TAGESAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_ACTION_BEGIN,
        });
        abortGesperrteAusweiseGesamt =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-tagesauswertung-anzahl-gesperrter-mitarbeiter/?datum=${data.datum}`,
            { method: "GET", signal: abortGesperrteAusweiseGesamt.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: TAGESAUSWERTUNG_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchTagesauwertungGesperrterMitarbeiterGesamt",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: TAGESAUSWERTUNG_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchTagesauwertungGesperrterMitarbeiterGesamt",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_TAGESAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchTagesauwertungGesperrterMitarbeiterGesamt",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchTagesauwertungAnwesenheitFirmen =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("fetchTagesauwertungAnwesenheitFirmen");
        dispatch({ type: TAGESAUSWERTUNG_ANWESENHEIT_FIRMEN_ACTION_BEGIN });
        abortAnwesenheitFirmen =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-tagesauswertung-anwesenheit-firmen/?datum=${data.datum}&anzahl=${data.anzahl}`,
            { method: "GET", signal: abortAnwesenheitFirmen.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: TAGESAUSWERTUNG_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchTagesauwertungAnwesenheitFirmen",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: TAGESAUSWERTUNG_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchTagesauwertungAnwesenheitFirmen",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_TAGESAUSWERTUNG_ANWESENHEIT_FIRMEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchTagesauwertungGesperrterMitarbeiterGesamt",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchTagesauwertungSperrgruendeTagesablauf =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchTagesauwertungSperrgruendeTagesablauf"
        );
        dispatch({
            type: TAGESAUSWERTUNG_SPERRGRUENDE_TAGESABLAUF_ACTION_BEGIN,
        });
        abortSperrgründeTagesablauf =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-tagesauswertung-sperrgruende/?datum=${data.datum}&anzahl=${data.anzahl}`,
            { method: "GET", signal: abortSperrgründeTagesablauf.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: TAGESAUSWERTUNG_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchTagesauwertungSperrgruendeTagesablauf",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: TAGESAUSWERTUNG_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchTagesauwertungSperrgruendeTagesablauf",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_TAGESAUSWERTUNG_SPERRGRUENDE_TAGESABLAUF_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchTagesauwertungGesperrterMitarbeiterGesamt",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchTagesauwertungAnwesenheitTagesablauf =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchTagesauwertungAnwesenheitTagesablauf"
        );
        dispatch({
            type: TAGESAUSWERTUNG_ANWESENHEIT_TAGESABLAUF_ACTION_BEGIN,
        });
        abortAnwesenheitTagesablauf =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-tagesauswertung-anwesenheit-tagesablauf/?datum=${data.datum}`,
            { method: "GET", signal: abortAnwesenheitTagesablauf.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: TAGESAUSWERTUNG_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchTagesauwertungAnwesenheitTagesablauf",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: TAGESAUSWERTUNG_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchTagesauwertungAnwesenheitTagesablauf",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_TAGESAUSWERTUNG_ANWESENHEIT_TAGESABLAUF_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchTagesauwertungAnwesenheitTagesablauf",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const exportDashboardTagesauswertung = (data) => (dispatch) => {
    const a = document.createElement("a");
    dispatch(showToast({ text: translate("DASHBOARD.EXPORT_TOAST") }));
    a.setAttribute(
        "href",
        `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-tagesauswertung-export/?projektName=${data.projektName}&datum=${data.datum}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "exportDashboardTagesauswertung" });
};
