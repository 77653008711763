const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;
export const erstelleContainerBuchungEufecb = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("erstelleContainerBuchungEufecb");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/erstelle-container-buchung-eufecb`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("erstelleContainerBuchungEufecb", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("erstelleContainerBuchungEufecb", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("erstelleContainerBuchungEufecb", {
            success: json.success,
        });
        return json;
    }
};

export const fetchEufecb = async (sprache, projektId, eufecbId) => {

    const response = await fetch(`${apiHost}/api/container/v1/${sprache}/${projektId}/download-container-buchung-eufecb/?containerBuchungEufecbId=${eufecbId}`,
        {
            method: "GET"
        }
    );
    let json;
    try {
        json = await response.blob();
        return json;
    } catch (error) {
        dispatch(errorHandler(error));
    }
    return response;
};

export const fetchEufecbVorlage = (sprache, projektId, eufecbId) => {
    const a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/container/v1/${sprache}/${projektId}/download-eufecb-vorlage/?eufecbId=${eufecbId}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "fetchEufecbVorlage" });
};

export const erstelleWeitereContainerBuchungEufecbCommand = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("erstelleWeitereContainerBuchungEufecbCommand");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/erstelle-weitere-container-buchung-eufecb`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("erstelleWeitereContainerBuchungEufecbCommand", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("erstelleWeitereContainerBuchungEufecbCommand", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("erstelleWeitereContainerBuchungEufecbCommand", {
            success: json.success,
        });
        return json;
    }
};

export const archiviereContainerBuchungEufecb = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("archiviereContainerBuchungEufecb");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/archiviere-container-buchung-eufecb`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("archiviereContainerBuchungEufecb", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("archiviereContainerBuchungEufecb", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("archiviereBuchungEufecb", {
            success: json.success,
        });
        return json;
    }
};