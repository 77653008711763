import errorHandler from "../errorHandling";

export const FETCH_EUFEFAS_SUCCESS = "FETCH_EUFEFAS_SUCCESS";
export const RESET_EUFEFAS_SUCCESS = "RESET_EUFEFAS_SUCCESS";
export const LOESCHE_EUFEFAS_SUCCESS = "LOESCHE_EUFEFAS_SUCCESS";
export const ZUKO_EUFEFAS_ACTION_FAIL = "ZUKO_EUFEFAS_ACTION_FAIL";
export const ERSTELLE_EUFEFAS_SUCCESS = "ERSTELLE_EUFEFAS_SUCCESS";
export const ZUKO_EUFEFAS_ACTION_BEGIN = "ZUKO_EUFEFAS_ACTION_BEGIN";
export const AKTUALISIERE_EUFEFAS_SUCCESS = "AKTUALISIERE_EUFEFAS_SUCCESS";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;

export const resetEufefas = () => (dispatch) => {
    dispatch({ type: RESET_EUFEFAS_SUCCESS });
    appInsights.trackEvent({ name: "resetEufefas" });
};

export const fetchEufefas = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchEufefas");
    dispatch({ type: ZUKO_EUFEFAS_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/admin/v1/${data.projektId}/eufefas/`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: ZUKO_EUFEFAS_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("fetchEufefas", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: ZUKO_EUFEFAS_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("fetchEufefas", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({ type: FETCH_EUFEFAS_SUCCESS, payload: json.payload });
        appInsights.stopTrackEvent("fetchEufefas", { success: json.success });
    }
};

export const loescheEufefa = (formData, projektId) => async (dispatch) => {
    appInsights.startTrackEvent("loescheEufefa");
    dispatch({ type: ZUKO_EUFEFAS_ACTION_BEGIN });
    const url = `/api/zuko/admin/v1/${projektId}/loesche-eufefa`;
    const response = await fetch(url, {
        method: "POST",
        body: formData,
    });

    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: ZUKO_EUFEFAS_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("loescheEufefa", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: ZUKO_EUFEFAS_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("loescheEufefa", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({ type: LOESCHE_EUFEFAS_SUCCESS, payload: json.payload });
        appInsights.stopTrackEvent("loescheEufefa", { success: json.success });
        return json.payload;
    }
};

export const aktualisiereEufefa = (formData, projektId) => async (dispatch) => {
    appInsights.startTrackEvent("aktualisiereEufefa");
    dispatch({ type: ZUKO_EUFEFAS_ACTION_BEGIN });
    const url = `/api/zuko/admin/v1/${projektId}/aktualisiere-eufefa`;
    const response = await fetch(url, {
        method: "POST",
        body: formData,
    });
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: ZUKO_EUFEFAS_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("aktualisiereEufefa", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: ZUKO_EUFEFAS_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("aktualisiereEufefa", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({ type: AKTUALISIERE_EUFEFAS_SUCCESS, payload: json.payload });
        appInsights.stopTrackEvent("aktualisiereEufefa", {
            success: json.success,
        });
        return json.payload;
    }
};

export const erstelleEufefa = (formData, projektId) => async (dispatch) => {
    appInsights.startTrackEvent("erstelleEufefa");
    dispatch({ type: ZUKO_EUFEFAS_ACTION_BEGIN });
    const url = `/api/zuko/admin/v1/${projektId}/erstelle-eufefa`;
    const response = await fetch(url, {
        method: "POST",
        body: formData,
    });
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }

    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: ZUKO_EUFEFAS_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("erstelleEufefa", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: ZUKO_EUFEFAS_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("erstelleEufefa", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({ type: ERSTELLE_EUFEFAS_SUCCESS, payload: json.payload });
        appInsights.stopTrackEvent("erstelleEufefa", { success: json.success });
        return json.payload;
    }
};
