const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;

export const alleContainerburgen = async (language, projektId) => {
    appInsights.startTrackEvent("alleContainerburgen");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/alle-containerburgen`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("alleContainerburgen", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("alleContainerburgen", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("alleContainerburgen", {
            success: json.success,
        });
        return json;
    }
};

export const fetchContainerburgUebersicht = async (
    language,
    projektId,
    containerburgId,
    containerburgVon,
    containerburgBis
) => {
    appInsights.startTrackEvent("fetchContainerburgUebersicht");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/containerburg-uebersicht?ContainerburgId=${containerburgId}&ContainerburgVon=${containerburgVon}&ContainerburgBis=${containerburgBis}`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchContainerburgUebersicht", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchContainerburgUebersicht", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchContainerburgUebersicht", {
            success: json.success,
        });
        return json;
    }
};

export const fetchContainerburgUebersichtExport = async (
    language,
    projektId,
    containerburgId,
    projektName,
    containerburgVon,
    containerburgBis
) => {
    let a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/container/v1/${language}/${projektId}/containerburg-uebersicht-export?ContainerburgId=${containerburgId}&ProjektName=${projektName}&ContainerburgVon=${containerburgVon}&ContainerburgBis=${containerburgBis}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "fetchContainerburgUebersichtExport" });
};
