const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;

export const erstelleEufecb = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("erstelleEufecb");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/erstelle-eufecb`,
        { method: "POST", body: formData }
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }

    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("erstelleEufecb", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("erstelleEufecb", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("erstelleEufecb", { success: json.success });
        return json;
    }
};

export const archiviereEufecb = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("archiviereEufecb");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/archiviere-eufecb`,
        {
            method: "POST",
            body: formData,
        }
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }

    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("archiviereEufecb", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("archiviereEufecb", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("archiviereEufecb", {
            success: json.success,
        });
        return json;
    }
};

export const aktualisiereEufecb = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("aktualisiereEufecb");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/aktualisiere-eufecb`,
        {
            method: "POST",
            body: formData,
        }
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }

    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("aktualisiereEufecb", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("aktualisiereEufecb", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("aktualisiereEufecb", {
            success: json.success,
        });
        return json;
    }
};

export const fetchEufecbVorlage = async (sprache, projektId, eufecbId) => {
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/download-eufecb-vorlage/?eufecbId=${eufecbId}`,
        {
            method: "GET",
        }
    );
    return response;
};
