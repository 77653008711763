import {
    PROJEKTBETEILIGTE_ACTION_FAIL,
    PROJEKTBETEILIGTE_ACTION_BEGIN,
    FETCH_PROJEKTBETEILIGTE_SUCCESS,
    RESET_PROJEKTBETEILIGTE_SUCCESS,
} from '../../actions/projekt/projektbeteiligte.js';

const projektbeteiligteState = {
    error: null,
    loading: false,
    projektbeteiligte: [],
};

const savedState = localStorage.getItem('savedState') || JSON.stringify({ ...projektbeteiligteState });
const INITIAL_STATE = JSON.parse(savedState).projektbeteiligteState || projektbeteiligteState;

const projektbeteiligte = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PROJEKTBETEILIGTE_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case PROJEKTBETEILIGTE_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FETCH_PROJEKTBETEILIGTE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                projektbeteiligte: action.payload
            };
        case RESET_PROJEKTBETEILIGTE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                projektbeteiligte: [],
            };
        default:
            return state;
    }
};

export default projektbeteiligte;