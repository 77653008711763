import "../../../helper/asd-helper.js";
import { store } from "../../../store.js";
import "../../../bs/components/bs-radio.js";
import { LitElement, css } from "lit";
import { html } from "lit/html.js";;
import { connect } from "pwa-helpers/connect-mixin.js";
import { AlgoStyles } from "../../../css/algo-styles.js";
import { SbAdmin2Styles } from "../../../css/sb-admin-2-styles.js";
import { FontawesomeIcons } from "../../../css/fontawesome-icons.js";
import {
    erstelleMindestlohnKonfiguration,
    fetchFetchMindestlohnKonfiguration,
    aktualisiereMindestlohnKonfiguration,
    loescheMindestlohnerklaerungVorlage,
} from "../../../rdx/actions/zuko/zuko-mindestlohnkonfiguration.js";
import { showToast } from "../../../rdx/actions/algo-app.js";

class ZukoEinstellungenUebersichtViewMindestlohnPage extends connect(store)(
    LitElement
) {
    static get styles() {
        return [
            SbAdmin2Styles,
            AlgoStyles,
            FontawesomeIcons,
            css`
                .info-box {
                    width: 90%;
                    position: relative;
                    box-sizing: border-box;
                    margin: 0 auto;
                    padding: 16px;
                    text-align: left;
                    border-radius: 5px;
                    border: rgb(133 135 150);
                    box-shadow: rgb(133 135 150) 0px 0.15rem 0.75rem 0px !important;
                    background: #f5f5f5;
                    margin-bottom: 15px;
                }
                .icon {
                    margin: -6px 0 0 0px;
                    padding-right: 15px;
                    font-size: 24px;
                }
                .info {
                    top: 30%;
                    font-weight: bold;
                    font-size: 12px;
                }
            `,
        ];
    }

    static get properties() {
        return {
            active: { type: Boolean },
            _form: { type: Object },
            _projektId: { type: String },
            _loading: { type: Boolean },
            _selectedIndex: { type: String },
            _stichtagSperrung: { type: Number },
            _vorlagenMindestlohn: { type: Array },
            _stichtagFirmenverwalter: { type: Number },
            _formData: { tpye: FormData },
            _rechtMindestlohnKonfigurationBearbeiten: { type: Boolean },
        };
    }

    constructor() {
        super();
        this._form = {};
        this._loading = false;
        this._vorlagenMindestlohn = [];
        this._formData = new FormData();
    }

    _renderUebersichtSperrungBenachrichtigungen() {
        const istSuccessFirmenverwalter =
                !this._form?.istSperrungAutomatisiertAktiviert ||
                (this._form?.istSperrungAutomatisiertAktiviert &&
                    this._form.istBenachrichtigungFirmenverwalterAktiviert),
            istSuccessPruefverantwortliche =
                !this._form?.istSperrungAutomatisiertAktiviert ||
                (this._form?.istSperrungAutomatisiertAktiviert &&
                    this._form
                        ?.istBenachtrichtigungPruefveranwortlicherAktiviert);

        if (this._form.istZeitintervallEinmaligAktiviert) return html``;
        if (!this._form?.istSperrungAutomatisiertAktiviert) {
            return html`<div class="row">
                <label class="col-sm-6 font-weight-bold"
                    >Automatische Sperrung</label
                >
                <div class="col-sm-6">nicht aktiviert</div>
            </div>`;
        }
        const benachrichtigungen = [
            {
                label: "Stichtag der Sperrung",
                value: this._form.stichtagSperrung,
            },
            {
                label: "Stichtag hochzuladener Mindestlohnerklärungen",
                value: this._form?.stichtagFirmenverwalter,
            },
            {
                label: "Fristerrinnerung fehlender Mindestlohnerklärungen",
                value: this._form?.fristerrinnerungFirmenverwalter,
            },
            {
                label: "Erinnerung zu prüfender Mindestlohnerklärungen",
                value: this._form?.errinnerungPruefverantworlicher,
            },
        ];
        benachrichtigungen.sort((a, b) =>
            a.value > b.value ? 1 : b.value > a.value ? -1 : 0
        );
        return html`<div class="row">
        <label class="col-sm-6 font-weight-bold">Automatische Sperrung</label>
        <div class="col-sm-6">aktiviert</div>
      </div>
      <div class="row">
        <label class="col-sm-6 font-weight-bold">E-Mailadresse des Prüfverantwortlichen</label>
        <div class="col-sm-6">
          ${this._form?.pruefverantwortlicherEmail}
        </div>
      </div> 
      <div class="row">
        <label class="col-sm-6 font-weight-bold">Automatische Sperrung</label>
        <div class="col-sm-6">aktiviert</div>
      </div>
      <div class="row ${istSuccessFirmenverwalter ? "" : "text-danger"}">
        <label class="col-sm-6 font-weight-bold">Benachrichtigungen an Firmenverwalter</label>
        <div class="col-sm-6">
        ${
            this._form.istBenachrichtigungFirmenverwalterAktiviert
                ? html`aktiviert`
                : html`nicht aktiviert`
        }</div>
        </div>
      </div>
      <div class="row ${istSuccessPruefverantwortliche ? "" : "text-danger"}">
        <label class="col-sm-6 font-weight-bold">Benachrichtigungen an Prüfverantwortliche</label>
        <div class="col-sm-6">
        ${
            this._form.istBenachtrichtigungPruefveranwortlicherAktiviert
                ? html`aktiviert`
                : html`nicht aktiviert`
        }</div>
        </div>
      </div>
      <hr class="fading-hr" />
      <div class="row">
        <h5>Übersicht der konfigurierten Tage</h5>
      </div>
      ${benachrichtigungen?.map((x) =>
          x.value !== 0
              ? html`
                    <div class="row">
                        <label class="col-sm-6 font-weight-bold"
                            >${x.label}</label
                        >
                        <div class="col-sm-6">${x.value}</div>
                    </div>
                `
              : html``
      )}
      `;
    }
    _renderSicherheitsabfraggeModal() {
        const istSuccess =
            !this._form?.istSperrungAutomatisiertAktiviert ||
            (this._form?.istSperrungAutomatisiertAktiviert &&
                this._form?.istBenachrichtigungFirmenverwalterAktiviert &&
                this._form?.istBenachtrichtigungPruefveranwortlicherAktiviert);
        return html`
            <bs-modal
                id="sicherheitsabfrage"
                large
                modalTitle="Eingabe bitte bestätigen"
                ?progressActive="${this._loading}"
            >
                ${this._form?.istMindestlohnpflichtabfrageAktiviert
                    ? html` <div slot="modal-body">
                          <div class="container">
                              <div class="row">
                                  <label class="col-sm-6 font-weight-bold"
                                      >Mindestlohn</label
                                  >
                                  <div class="col-sm-6">aktiviert</div>
                              </div>
                              ${!this._form?.istSystemvorlageAktiviert
                                  ? html` <hr class="fading-hr" />
                                        <div class="row">
                                            <label
                                                class="col-sm-6 font-weight-bold"
                                                >Vorlagen</label
                                            >
                                            <div class="col-sm-6">
                                                ${this._vorlagenMindestlohn?.map(
                                                    (x) =>
                                                        html`<div class="row">
                                                            ${x.name}
                                                        </div>`
                                                )}
                                            </div>
                                        </div>`
                                  : html``}
                              <hr class="fading-hr" />
                              <div class="row">
                                  <label class="col-sm-6 font-weight-bold"
                                      >Zeitintervall</label
                                  >
                                  <div class="col-sm-6">
                                      ${this._form
                                          ?.istZeitintervallEinmaligAktiviert
                                          ? html`einmalig`
                                          : html`monatlich`}
                                  </div>
                              </div>
                              <hr class="fading-hr" />
                              ${this._renderUebersichtSperrungBenachrichtigungen()}
                          </div>
                      </div>`
                    : html` <div slot="modal-body">
                          <div class="container">
                              <div class="row">
                                  <label class="col-sm-6 font-weight-bold"
                                      >Mindestlohn</label
                                  >
                                  <div class="col-sm-6">nicht aktiviert</div>
                              </div>
                          </div>
                      </div>`}
                <div slot="modal-footer">
                    <button
                        slot="modal-footer"
                        class="btn btn-danger"
                        role="button"
                        @click="${this._closeModal}"
                    >
                        Abbrechen
                    </button>
                    <button
                        slot="modal-footer"
                        class="${istSuccess
                            ? "btn btn-success"
                            : "btn btn-warning"}"
                        role="button"
                        @click="${!this._form.id
                            ? this._erstelleMindestlohnkonfiguration
                            : this._aktualisiereMindestlohnkonfiguration}"
                    >
                        Speichern
                    </button>
                </div>
            </bs-modal>
        `;
    }

    _renderVorlagenLoeschenModal() {
        return html`
            <bs-modal
                id="vorlagen-loeschen-modal"
                modalTitle="Vorlage löschen"
                ?progressActive="${this._loading}"
            >
                <p slot="modal-body">
                    Wollen Sie die Vorlage wirklich löschen?
                </p>
                <a
                    slot="modal-footer"
                    class="btn btn-danger"
                    role="button"
                    @click="${this._loescheVorlage}"
                    >Löschen</a
                >
            </bs-modal>
        `;
    }

    _renderPruefverantworlichen() {
        let rechtMindestlohnKonfigurationBearbeiten =
            this._rechtMindestlohnKonfigurationBearbeiten;
        let raido = [
            {
                id: "emailPruefverantwortlichenVersenden_ja",
                label: "Ja",
                value: "true",
                checked:
                    this._form
                        .istBenachtrichtigungPruefveranwortlicherAktiviert ===
                    true,
            },
            {
                id: "emailPruefverantwortlichenVersenden_nein",
                label: "Nein",
                value: "false",
                checked:
                    this._form
                        .istBenachtrichtigungPruefveranwortlicherAktiviert ===
                    false,
            },
        ];
        return html`
            <div slot="asd-col-1-count-17">
                <label class="asd-sub-col-label"
                    >E-Mail an Prüfverantwortlichen</label
                >
            </div>
            <div slot="asd-col-2-count-17">
                <bs-radio
                    class="float-right"
                    .radios="${raido}"
                    required="true"
                    groupName="IstBenachtrichtigungPruefveranwortlicherAktiviert"
                    ?loading="${this._loading}"
                    ?disabled="${!rechtMindestlohnKonfigurationBearbeiten}"
                    @change="${this._change}"
                ></bs-radio>
            </div>
            ${!this._form.istBenachtrichtigungPruefveranwortlicherAktiviert
                ? html``
                : html`<div slot="asd-col-1-count-18">
                          <label class="asd-sub-sub-col-label"
                              >Erinnerung zu prüfende
                              Mindestlohnerklärungen</label
                          >
                      </div>
                      <div slot="asd-col-2-count-18">
                          <div class="input-group">
                              <div class="input-group-prepend float-right">
                                  <div class="input-group-text">
                                      <i class="fas"> 1-31 </i>
                                  </div>
                                  <input
                                      ?disabled="${!rechtMindestlohnKonfigurationBearbeiten ||
                                      this._loading}"
                                      required
                                      type="number"
                                      placeholder="Tag"
                                      min="1"
                                      max="31"
                                      value="${this._form
                                          .errinnerungPruefverantworlicher
                                          ? this._form
                                                .errinnerungPruefverantworlicher
                                          : ""}"
                                      class="form-control number"
                                      name="ErrinnerungPruefverantworlicher"
                                      id="errinnerungPruefverantworlicher"
                                      data-toggle="tooltip"
                                      title="Tag des Monat z.B. 5 jeden Monats"
                                      @change="${this
                                          ._changePruefverantowrlicheStichtag}"
                                  />
                              </div>
                          </div>
                      </div>
                      ${this._form.istSperrungAutomatisiertAktiviert
                          ? html` <div slot="asd-info-box-count-18">
                                <div class="info-box">
                                    <i class="fas fa-info icon"></i>
                                    <span class="info">
                                        Die E-Mails werden an die konfigurierte
                                        E-Mailadresse des Prüfverantwortlichen
                                        ${this._form.pruefverantwortlicherEmail}
                                        versendet.
                                    </span>
                                </div>
                            </div>`
                          : html`
                                <div slot="asd-col-1-count-19">
                                    <label class="asd-sub-sub-col-label"
                                        >E-Mail des Prüfverantwortlichen</label
                                    >
                                </div>
                                <div slot="asd-col-2-count-19">
                                    <input
                                        ?disabled="${!rechtMindestlohnKonfigurationBearbeiten ||
                                        this._loading}"
                                        required
                                        type="email"
                                        placeholder="max.mustermann@mustermail.de"
                                        class="form-control"
                                        name="PruefverantwortlicherEmail"
                                        id="pruefverantwortlicherEmail"
                                        value="${this._form
                                            .pruefverantwortlicherEmail
                                            ? this._form
                                                  .pruefverantwortlicherEmail
                                            : ""}"
                                    />
                                </div>
                            `}`}
        `;
    }

    _renderSubFirmenverwalter() {
        let rechtMindestlohnKonfigurationBearbeiten =
            this._rechtMindestlohnKonfigurationBearbeiten;
        if (!this._form.istBenachrichtigungFirmenverwalterAktiviert)
            return html``;

        return html`
            <div slot="asd-col-1-count-14">
                <label class="asd-sub-sub-col-label"
                    >Errinnerung hochzuladener Mindestlohnerklärungen</label
                >
            </div>
            <div slot="asd-col-2-count-14">
                <div class="input-group">
                    <div class="input-group-prepend float-right">
                        <div class="input-group-text">
                            <i class="fas"> 1-31 </i>
                        </div>
                        <input
                            ?disabled="${!rechtMindestlohnKonfigurationBearbeiten ||
                            this._loading}"
                            required
                            type="number"
                            min="1"
                            max="31"
                            placeholder="Tag"
                            class="form-control number"
                            name="StichtagFirmenverwalter"
                            id="stichtagFirmenverwalter"
                            data-toggle="tooltip"
                            title="Tag des Monat z.B. 5 jeden Monats"
                            @change="${this._changeFirmenverwalterStichtag}"
                            value="${this._form.stichtagFirmenverwalter
                                ? this._form.stichtagFirmenverwalter
                                : ""}"
                        />
                    </div>
                </div>
            </div>
            <div slot="asd-col-1-count-15">
                <label class="asd-sub-sub-col-label pl-20"
                    >Fristerrinnerung hochzuladende
                    Mindestlohnerklärungen</label
                >
            </div>
            <div slot="asd-col-2-count-15">
                <div class="input-group">
                    <div class="input-group-prepend float-right">
                        <div class="input-group-text">
                            <i class="fas"> 1-31 </i>
                        </div>
                        <input
                            ?disabled="${!rechtMindestlohnKonfigurationBearbeiten ||
                            this._loading}"
                            required
                            type="number"
                            min="1"
                            max="31"
                            placeholder="Tag"
                            class="form-control number"
                            name="FristerrinnerungFirmenverwalter"
                            id="fristerrinnerungFirmenverwalter"
                            data-toggle="tooltip"
                            title="Tag des Monat z.B. 5 jeden Monats"
                            @change="${this
                                ._changeFristerrinnerungFirmenverwalterStichtag}"
                            value="${this._form.fristerrinnerungFirmenverwalter
                                ? this._form.fristerrinnerungFirmenverwalter
                                : ""}"
                        />
                    </div>
                </div>
            </div>
            <div slot="asd-info-box-count-16">
                <div class="info-box">
                    <i class="fas fa-info icon"></i>
                    <span class="info">
                        Die E-Mailadresse des Firmenverwalters wird in den
                        Stammdaten der Firma konfiguriert.
                    </span>
                </div>
            </div>
        `;
    }

    _renderBenachrichtigungen() {
        let rechtMindestlohnKonfigurationBearbeiten =
            this._rechtMindestlohnKonfigurationBearbeiten;
        let raido = [
            {
                id: "emailFirmenverwalterVersenden_ja",
                label: "Ja",
                value: "true",
                checked:
                    this._form.istBenachrichtigungFirmenverwalterAktiviert ===
                    true,
            },
            {
                id: "emailFirmenverwalterVersenden_nein",
                label: "Nein",
                value: "false",
                checked:
                    this._form.istBenachrichtigungFirmenverwalterAktiviert ===
                    false,
            },
        ];
        return html`
      <div slot="asd-col-1-count-12">
        <label class="font-weight-bold">Weitere Benachrichtigungen</label>
      </div>
      <div slot="asd-col-3-count-12">
        <i class="far fa-question-circle"> </i>
      </div>
      <div slot="asd-helper-text-count-12">
        <label class="asd-helper-text-title">Weitere Benachrichtigungen</label>
        <br />
        <span>
          Wählen Sie hier, ob der Firmenverwalter und Prüfverantwortliche
          monatliche Erinnerungs-E-Mails erhalten soll, oder nicht. 
          Nach Aktivierung müssen Sie in das Eingabefeld den Tag eingeben, 
          zum Beispiel wird bei 1 jeden Monat am 1. eine Benachrichtigung versendet.
          <br />
        </span>
        <ul>
          <li>
            <strong>Erinnerung hochzuladende Mindestlohnerklärungen:</strong> 
            Ab dem Tag können 
            Mindestlohnerklärungen für die Mitarbeiter
            hochgeladen werden.
          </li>
          <li>
            <strong>Fristerinnerung hochzuladende Mindestlohnerklärungen:</strong>
            Der Firmenverwalter erhält an diesem Tag eine Erinnerungs-E-Mail, 
            sofern noch nicht alle Mindestlohnerklärungen hochgeladen wurden. 
          </li>
          <li>
          <strong>Erinnerung zu prüfende Mindestlohnerklärungen:</strong> 
          Die Prüfverantwortlichen werden daran erinnert, 
          dass ab diesem Tag Mindestlohnerklärungen geprüft werden können. 
          </li>       
        </ul>      
      </div>
      </div>
      <div slot="asd-col-1-count-13">
        <label class="asd-sub-col-label">E-Mail an Firmenverwalter</label>
      </div>
      <div slot="asd-col-2-count-13">
        <bs-radio
          class="float-right"
          .radios="${raido}"
          required="true"
          groupName="IstBenachrichtigungFirmenverwalterAktiviert"
          ?loading="${this._loading}"
          ?disabled="${!rechtMindestlohnKonfigurationBearbeiten}"
          @change="${this._change}"
        ></bs-radio>
      </div>
      ${this._renderSubFirmenverwalter()} ${this._renderPruefverantworlichen()}
    `;
    }

    _renderSubSperrung() {
        let rechtMindestlohnKonfigurationBearbeiten =
            this._rechtMindestlohnKonfigurationBearbeiten;
        if (!this._form.istSperrungAutomatisiertAktiviert) return html``;

        return html`
            <div slot="asd-col-1-count-9">
                <label class="asd-sub-col-label">Stichtag der Sperrung</label>
            </div>
            <div slot="asd-col-2-count-9">
                <div class="input-group">
                    <div class="input-group-prepend float-right">
                        <div class="input-group-text">
                            <i class="fas"> 1-31 </i>
                        </div>
                        <input
                            ?disabled="${!rechtMindestlohnKonfigurationBearbeiten ||
                            this._loading}"
                            required
                            type="number"
                            min="1"
                            max="31"
                            placeholder="Tag"
                            class="form-control number"
                            name="StichtagSperrung"
                            id="stichtagSperrung"
                            data-toggle="tooltip"
                            title="Tag des Monat z.B. 5 jeden Monats"
                            @change="${this._changeStichtagSperrung}"
                            value="${this._form.stichtagSperrung
                                ? this._form.stichtagSperrung
                                : ""}"
                        />
                    </div>
                </div>
            </div>
            <div slot="asd-col-1-count-10">
                <label class="asd-sub-col-label"
                    >E-Mail des Prüfverantwortlichen</label
                >
            </div>
            <div slot="asd-col-2-count-10">
                <div class="input-group">
                    <div class="input-group-prepend float-right">
                        <div class="input-group-text">
                            <i class="fas fa-at"></i>
                        </div>
                        <input
                            ?disabled="${!rechtMindestlohnKonfigurationBearbeiten ||
                            this._loading}"
                            required
                            type="email"
                            placeholder="max.mustermann@mustermail.de"
                            class="form-control"
                            name="PruefverantwortlicherEmail"
                            id="pruefverantwortlicherEmail"
                            value="${this._form.pruefverantwortlicherEmail
                                ? this._form.pruefverantwortlicherEmail
                                : ""}"
                            @change="${this._change}"
                        />
                    </div>
                </div>
            </div>
            <div slot="asd-info-box-count-11">
                <div class="info-box">
                    <i class="fas fa-info icon"></i>
                    <span class="info">
                        Der Prüfverantwortliche erhält 3 Tage vor Sperrung der
                        Baustellenausweise eine E-Mail, welche über die davon
                        betroffenen Mitarbeiter informiert.
                    </span>
                </div>
            </div>
        `;
    }

    _renderSperrung() {
        let rechtMindestlohnKonfigurationBearbeiten =
            this._rechtMindestlohnKonfigurationBearbeiten;
        if (
            !this._form.istMindestlohnpflichtabfrageAktiviert ||
            this._form.istZeitintervallEinmaligAktiviert !== false
        )
            return html``;

        let raido = [
            {
                id: "sperrung_ja",
                label: "Ja",
                value: "true",
                checked: this._form.istSperrungAutomatisiertAktiviert === true,
            },
            {
                id: "sperrung_nein",
                label: "Nein",
                value: "false",
                checked: this._form.istSperrungAutomatisiertAktiviert === false,
            },
        ];
        return html`
            <div slot="asd-col-1-count-7">
                <label class="font-weight-bold"
                    >Sperrung Baustellenausweise</label
                >
            </div>
            <div slot="asd-col-3-count-7">
                <i class="far fa-question-circle"> </i>
            </div>
            <div slot="asd-helper-text-count-7">
                <label class="asd-helper-text-title"
                    >Sperrung Baustellenausweise</label
                >
                <br />
                <span>
                    Wählen Sie hier, ob die Sperrung der Baustellenausweise, bei
                    fehlenden Mindestlohnerklärungen, automatisch vom System
                    oder manuell vom Prüfverantwortlichen erfolgen soll.
                    <br />
                    Ihre Auswahloptionen
                </span>
                <br />
                <ul>
                    <li>
                        <strong>„Ja“</strong> – Bedeutet, dass die Sperrung
                        <strong>automatisch</strong> durch das System
                        vorgenommen wird.
                    </li>
                    <li>
                        <strong>„Nein“</strong> – Bedeutet, dass die Sperrung
                        <strong>manuell</strong> vom Prüfverantwortlichen
                        vorgenommen wird.
                    </li>
                </ul>
                <span>
                    Wenn Sie die Baustellenausweise automatisch vom System
                    sperren lassen möchten, sind hierfür Angaben zum Stichtag
                    der Sperrung zu machen. Des Weiteren ist die E-Mailadresse
                    des Prüfverantwortlichen anzugeben, da diese 3 Tage vor der
                    Sperrung nochmals per Mail darüber informiert werden soll.
                </span>
            </div>
            <div slot="asd-col-1-count-8">
                <label class="asd-sub-col-label"
                    >Soll der Baustellenausweis automatisch vom System gesperrt
                    werden?</label
                >
            </div>
            <div slot="asd-col-2-count-8">
                <bs-radio
                    class="float-right"
                    .radios="${raido}"
                    required="true"
                    groupName="IstSperrungAutomatisiertAktiviert"
                    ?loading="${this._loading}"
                    ?disabled="${!rechtMindestlohnKonfigurationBearbeiten}"
                    @change="${this._change}"
                ></bs-radio>
            </div>
            ${this._renderSubSperrung()} ${this._renderBenachrichtigungen()}
        `;
    }

    _renderZeitintervall() {
        let rechtMindestlohnKonfigurationBearbeiten =
            this._rechtMindestlohnKonfigurationBearbeiten;
        if (!this._form.istMindestlohnpflichtabfrageAktiviert) return html``;
        let raido = [
            {
                id: "zeitintervall_einmalig",
                label: "Einmalig",
                value: "true",
                checked: this._form.istZeitintervallEinmaligAktiviert === true,
            },
            {
                id: "zeitintervall_monatlich",
                label: "Monatlich",
                value: "false",
                checked: this._form.istZeitintervallEinmaligAktiviert === false,
            },
        ];

        return html`
            <div slot="asd-col-1-count-5">
                <label class="font-weight-bold">Zeitintervall</label>
            </div>
            <div slot="asd-col-3-count-5">
                <i class="far fa-question-circle"> </i>
            </div>
            <div slot="asd-helper-text-count-5">
                <label class="asd-helper-text-title">Zeitintervall</label>
                <br />
                <span
                    >Wählen Sie hier, in welchem Zeitintervall die
                    Mindestlohnerklärungen von Ihren Beschäftigten angefordert
                    werden sollen.
                    <br />
                    Ihre Auswahloptionen:
                </span>
                <br />
                <ul>
                    <li>
                        <strong>„Einmalig“</strong> – Die Mindestlohnerklärung
                        wird <strong>einmalig</strong> zum Datum des
                        Beschäftigungsbeginns, als erforderliche
                        Mitarbeiterunterlage, angefordert.
                    </li>
                    <li>
                        <strong>„Monatlich“</strong> – Die Mindestlohnerklärung
                        wird zum Ersten eines
                        <strong>jeden Monats</strong> automatisch vom System
                        angefordert. Voraussetzung hierfür ist, dass die
                        beschäftigte Person im Vormonat auf der Baustelle tätig
                        war und die
                        <strong>„Mindestlohnpflichtabfrage“</strong> aktiviert
                        ist.
                    </li>
                </ul>
                <span
                    >Wenn die Mindestlohnabfrage monatlich erfolgen soll, sind
                    weitere Optionen bezüglich den
                    <strong>„Benachrichtigungen“</strong> und der
                    <strong>„automatischen Sperrung“</strong> einstellbar (siehe
                    zugehörige Infotafeln).
                </span>
            </div>
            <div slot="asd-col-1-count-6">
                <label class="asd-sub-col-label"
                    >Welchen Zeitintervall möchten Sie für die Abfrage
                    wählen?</label
                >
            </div>
            <div slot="asd-col-2-count-6">
                <bs-radio
                    class="float-right"
                    .radios="${raido}"
                    required="true"
                    groupName="IstZeitintervallEinmaligAktiviert"
                    ?loading="${this._loading}"
                    ?disabled="${!rechtMindestlohnKonfigurationBearbeiten}"
                    @change="${this._change}"
                ></bs-radio>
            </div>
            ${this._renderSperrung()}
        `;
    }

    _renderSubVorlage() {
        let rechtMindestlohnKonfigurationBearbeiten =
            this._rechtMindestlohnKonfigurationBearbeiten;
        if (this._form.istSystemvorlageAktiviert !== false) return html``;
        return html`
            <div slot="asd-col-1-count-4">
                <span class="fas fa-file-upload link-icon"></span
                ><label class="asd-col-link" for="vorlage-mindestlohnerklaerung"
                    >Vorlage hochladen</label
                >
                <input
                    class="file-input"
                    hidden
                    type="file"
                    id="vorlage-mindestlohnerklaerung"
                    accept="application/pdf"
                    multiple
                    @input="${this._changeVorlage}"
                />
            </div>
            <div slot="asd-info-box-count-4">
                ${this._vorlagenMindestlohn?.map(
                    (x, i) => html`
                        <div class="form-group row">
                            <div class="col-sm-2"></div>
                            <div class="col-sm-6">
                                <label
                                    class="asd-col-link"
                                    for="vorlage-mindestlohnerklaerung-bearbeitet${i}"
                                    >${x.name}</label
                                >
                                <input
                                    class="file-input"
                                    hidden
                                    name="MindestlohnerklaerungVorlagen"
                                    type="file"
                                    id="vorlage-mindestlohnerklaerung-bearbeitet${i}"
                                    accept="application/pdf"
                                    @input="${() => this._changeVorlage(i)}"
                                />
                            </div>
                            <select
                                id="vorlage-sprache${i}"
                                required=""
                                class="form-control col-sm-2"
                                @change="${(e) =>
                                    this._changeSpracheVorlage(e, i)}"
                            >
                                <option value="" disabled selected>
                                    Sprache
                                </option>
                                <option
                                    value="Albanisch"
                                    ?selected=${x.sprache === "Albanisch"}
                                >
                                    Albanisch
                                </option>
                                <option
                                    value="Arabisch"
                                    ?selected=${x.sprache === "Arabisch"}
                                >
                                    Arabisch
                                </option>
                                <option
                                    value="Bosnisch"
                                    ?selected=${x.sprache === "Bosnisch"}
                                >
                                    Bosnisch
                                </option>
                                <option
                                    value="Bulgarisch"
                                    ?selected=${x.sprache === "Bulgarisch"}
                                >
                                    Bulgarisch
                                </option>
                                <option
                                    value="Deutsch"
                                    ?selected=${x.sprache === "Deutsch"}
                                >
                                    Deutsch
                                </option>
                                <option
                                    value="Englisch"
                                    ?selected=${x.sprache === "Englisch"}
                                >
                                    Englisch
                                </option>
                                <option
                                    value="Französisch"
                                    ?selected=${x.sprache === "Französisch"}
                                >
                                    Französisch
                                </option>
                                <option
                                    value="Griechisch"
                                    ?selected=${x.sprache === "Griechisch"}
                                >
                                    Griechisch
                                </option>
                                <option
                                    value="Italienisch"
                                    ?selected=${x.sprache === "Italienisch"}
                                >
                                    Italienisch
                                </option>
                                <option
                                    value="Kroatisch"
                                    ?selected=${x.sprache === "Kroatisch"}
                                >
                                    Kroatisch
                                </option>
                                <option
                                    value="Lettisch"
                                    ?selected=${x.sprache === "Lettisch"}
                                >
                                    Lettisch
                                </option>
                                <option
                                    value="Mazedonisch"
                                    ?selected=${x.sprache === "Mazedonisch"}
                                >
                                    Mazedonisch
                                </option>
                                <option
                                    value="Niederländisch"
                                    ?selected=${x.sprache === "Niederländisch"}
                                >
                                    Niederländisch
                                </option>
                                <option
                                    value="Persisch"
                                    ?selected=${x.sprache === "Persisch"}
                                >
                                    Persisch
                                </option>
                                <option
                                    value="Polnisch"
                                    ?selected=${x.sprache === "Polnisch"}
                                >
                                    Polnisch
                                </option>
                                <option
                                    value="Portugiesisch"
                                    ?selected=${x.sprache === "Portugiesisch"}
                                >
                                    Portugiesisch
                                </option>
                                <option
                                    value="Rumänisch"
                                    ?selected=${x.sprache === "Rumänisch"}
                                >
                                    Rumänisch
                                </option>
                                <option
                                    value="Russisch"
                                    ?selected=${x.sprache === "Russisch"}
                                >
                                    Russisch
                                </option>
                                <option
                                    value="Serbisch"
                                    ?selected=${x.sprache === "Serbisch"}
                                >
                                    Serbisch
                                </option>
                                <option
                                    value="Slowakisch"
                                    ?selected=${x.sprache === "Slowakisch"}
                                >
                                    Slowakisch
                                </option>
                                <option
                                    value="Slowenisch"
                                    ?selected=${x.sprache === "Slowenisch"}
                                >
                                    Slowenisch
                                </option>
                                <option
                                    value="Spanisch"
                                    ?selected=${x.sprache === "Spanisch"}
                                >
                                    Spanisch
                                </option>
                                <option
                                    value="Tschechisch"
                                    ?selected=${x.sprache === "Tschechisch"}
                                >
                                    Tschechisch
                                </option>
                                <option
                                    value="Türkisch"
                                    ?selected=${x.sprache === "Türkisch"}
                                >
                                    Türkisch
                                </option>
                                <option
                                    value="Ungarisch"
                                    ?selected=${x.sprache === "Ungarisch"}
                                >
                                    Ungarisch
                                </option>
                            </select>
                            <i
                                class="fas fa-trash"
                                style="color:red;padding:0.375rem 0.75rem;line-height: 1.5;"
                                @click="${(e) =>
                                    x.id
                                        ? this._openDeleteModal(i)
                                        : this._entferneVorlage(e, i)}"
                                data-toggle="tooltip"
                                title="Vorlage löschen"
                                ?disabled="${!rechtMindestlohnKonfigurationBearbeiten ||
                                this._loading}"
                            >
                            </i>
                        </div>
                    `
                )}
            </div>
        `;
    }

    _renderVorlage() {
        let rechtMindestlohnKonfigurationBearbeiten =
            this._rechtMindestlohnKonfigurationBearbeiten;
        if (!this._form.istMindestlohnpflichtabfrageAktiviert) return html``;
        let raido = [
            {
                id: "standardVorlageVerwenden_ja",
                label: "Ja",
                value: "true",
                checked: this._form.istSystemvorlageAktiviert === true,
            },
            {
                id: "standardVorlageVerwenden_nein",
                label: "Nein",
                value: "false",
                checked: this._form.istSystemvorlageAktiviert === false,
            },
        ];

        return html` <div slot="asd-col-1-count-2">
                <label class="font-weight-bold">Vorlage</label>
            </div>
            <div slot="asd-col-3-count-2">
                <i class="far fa-question-circle"> </i>
            </div>
            <div slot="asd-helper-text-count-2">
                <label class="asd-helper-text-title">Vorlage</label>
                <br />
                <span
                    >Wollen Sie unsere Systemvorlagen für die Abfrage der
                    Mindestlohnerklärungen verwenden?</span
                >
                <br />
                <span
                    >Wählen Sie hier, ob die von uns erstellten Systemvorlagen
                    oder Ihre eigenen Vorlagen zur Abfrage der
                    Mindestlohnerklärung genutzt werden sollen.
                </span>
                <br />
                <span>Ihre Auswahloptionen:</span>
                <ul>
                    <li>
                        „Ja“ – Bedeutet, dass Sie unsere Systemvorlagen für die
                        mindestlohnpflichtigen Mitarbeiter Ihres Projekts nutzen
                        möchten.
                    </li>
                    <li>
                        „Nein“ – Bedeutet, dass Sie Ihre eigenen Vorlagen für
                        mindestlohnpflichtigen Mitarbeiter Ihres Projekts nutzen
                        möchten.
                    </li>
                </ul>
                <span
                    >Um eigene Vorlagen verwenden zu können, müssen diese
                    hochgeladen werden.
                    <br />
                    Klicken Sie hierfür auf das Symbol
                    <span class="fas fa-file-upload link-icon"></span>
                    <br />
                    oder den Link
                    <span
                        style="text-decoration-line:underline; font-weight:bold"
                        >Vorlage hochladen</span
                    ></span
                >
            </div>
            <div slot="asd-col-1-count-3">
                <label class="asd-sub-col-label"
                    >Wollen Sie die Systemvorlage verwenden?</label
                >
            </div>
            <div slot="asd-col-2-count-3">
                <bs-radio
                    class="float-right"
                    .radios="${raido}"
                    required="true"
                    groupName="IstSystemvorlageAktiviert"
                    ?loading="${this._loading}"
                    ?disabled="${!rechtMindestlohnKonfigurationBearbeiten}"
                    @change="${this._change}"
                ></bs-radio>
            </div>
            ${this._renderSubVorlage()}`;
    }

    _renderMindestlohn() {
        let rechtMindestlohnKonfigurationBearbeiten =
            this._rechtMindestlohnKonfigurationBearbeiten;
        let raido = [
            {
                id: "mindestlohn_ja",
                label: "Ja",
                value: "true",
                checked:
                    this._form.istMindestlohnpflichtabfrageAktiviert === true,
            },
            {
                id: "mindestlohn_nein",
                label: "Nein",
                value: "false",
                checked:
                    this._form.istMindestlohnpflichtabfrageAktiviert === false,
            },
        ];

        return html`
            <div slot="asd-col-1-count-0">
                <label class="font-weight-bold"
                    >Mindestlohnpflichtabfrage</label
                >
            </div>
            <div slot="asd-col-3-count-0">
                <i class="far fa-question-circle"> </i>
            </div>
            <div slot="asd-helper-text-count-0">
                <label class="asd-helper-text-title"
                    >Mindestlohnpflichtabfrage</label
                >
                <br />
                <span
                    >Wählen Sie hier, ob die Abfrage der Mindestlohnpflicht in
                    diesem Projekt erfolgen soll, oder nicht.
                    <br />
                    Ihre Auswahloptionen:
                </span>
                <br />
                <ul>
                    <li>
                        <strong>„Ja“</strong> – Bedeutet, dass die
                        Mindestlohnpflicht <strong>aktiviert</strong> ist.
                    </li>
                    <li>
                        <strong>„Nein“</strong> – Bedeutet, dass die
                        Mindestlohnpflicht <strong>deaktiviert</strong> ist.
                    </li>
                </ul>
                <span
                    >Nach dem Aktivieren können weitere Einstellungen zur
                    Abfrage der Mindestlohnerklärung vorgenommen werden.</span
                >
            </div>
            <div slot="asd-col-1-count-1">
                <label class="asd-sub-col-label"
                    >Möchten Sie eine Mindestlohnpflichtabfrage?</label
                >
            </div>
            <div slot="asd-col-2-count-1">
                <bs-radio
                    class="float-right"
                    .radios="${raido}"
                    required="true"
                    groupName="IstMindestlohnpflichtabfrageAktiviert"
                    ?loading="${this._loading}"
                    ?disabled="${!rechtMindestlohnKonfigurationBearbeiten}"
                    @change="${this._change}"
                ></bs-radio>
            </div>
            ${this._renderVorlage()} ${this._renderZeitintervall()}
        `;
    }

    render() {
        let rechtMindestlohnKonfigurationBearbeiten =
            this._rechtMindestlohnKonfigurationBearbeiten;
        return html`
            ${this._renderSicherheitsabfraggeModal()}
            ${this._renderVorlagenLoeschenModal()}
            <form
                id="mindestlohn"
                class="container"
                @submit="${this._openModal}"
            >
                <input value="${this._form.id}" name="Id" hidden />
                ${rechtMindestlohnKonfigurationBearbeiten
                    ? html` <button
                          type="submit"
                          class="btn btn-success float-right"
                          form="mindestlohn"
                          ?disabled="${this._loading}"
                      >
                          Speichern
                      </button>`
                    : html``}
                <asd-helper count="20">
                    ${this._renderMindestlohn()}
                </asd-helper>
            </form>
            <div></div>
        `;
    }

    _openDeleteModal(index) {
        this._selectedIndex = index;
        this.shadowRoot.querySelector("#vorlagen-loeschen-modal").show();
    }

    async _loescheVorlage(e) {
        this._loading = true;
        this.shadowRoot.querySelector("#vorlagen-loeschen-modal").close();
        const id = this._vorlagenMindestlohn[this._selectedIndex].id;

        const formData = new FormData();
        formData.append("Id", id);
        const data = await store.dispatch(
            loescheMindestlohnerklaerungVorlage(formData, this._projektId)
        );

        if (data) {
            store.dispatch(
                showToast({
                    text: "Vorlage erfolgreich gelöscht",
                })
            );
            this._loadingMindestlohnKonfiguration();
            this._selectedIndex = -1;
            this._loading = false;
        }
    }

    _entferneVorlage(e, index) {
        e.preventDefault();
        if (this._vorlagenMindestlohn.length > 0) {
            this._vorlagenMindestlohn.splice(index, 1);
            this.requestUpdate();
        }
    }

    _change(e) {
        const input = e.srcElement;
        switch (input.name) {
            case "IstMindestlohnpflichtabfrageAktiviert":
                {
                    this._form.istMindestlohnpflichtabfrageAktiviert =
                        input.value === "true" ? true : false;
                }
                break;
            case "IstSystemvorlageAktiviert":
                {
                    this._form.istSystemvorlageAktiviert =
                        input.value === "true" ? true : false;
                }
                break;
            case "IstSperrungAutomatisiertAktiviert":
                {
                    if (input.value === "true")
                        this._form.istSperrungAutomatisiertAktiviert = true;
                    else {
                        this._form.istSperrungAutomatisiertAktiviert = false;
                        this._form.stichtagSperrung = 0;
                    }
                }
                break;
            case "IstZeitintervallEinmaligAktiviert":
                {
                    this._form.istZeitintervallEinmaligAktiviert =
                        input.value === "true" ? true : false;
                }
                break;
            case "IstBenachtrichtigungPruefveranwortlicherAktiviert":
                {
                    this._form.istBenachtrichtigungPruefveranwortlicherAktiviert =
                        input.value === "true" ? true : false;
                    this._form.errinnerungPruefverantworlicher = 0;
                }
                break;
            case "IstBenachrichtigungFirmenverwalterAktiviert":
                {
                    this._form.istBenachrichtigungFirmenverwalterAktiviert =
                        input.value === "true" ? true : false;
                    this._form.fristerrinnerungFirmenverwalter = 0;
                    this._form.stichtagFirmenverwalter = 0;
                }
                break;
            case "PruefverantwortlicherEmail":
                {
                    this._form.pruefverantwortlicherEmail = input.value;
                }
                break;
        }
        this.requestUpdate();
        this.shadowRoot.querySelector("asd-helper").requestUpdate();
    }

    _changePruefverantowrlicheStichtag() {
        const errinnerungPruefverantworlicher = this.shadowRoot.querySelector(
            "#errinnerungPruefverantworlicher"
        )?.value
            ? this.shadowRoot.querySelector("#errinnerungPruefverantworlicher")
                  .value
            : 1;

        if (errinnerungPruefverantworlicher) {
            this._form.errinnerungPruefverantworlicher =
                errinnerungPruefverantworlicher;
            this.requestUpdate();
        }
    }

    _changeFristerrinnerungFirmenverwalterStichtag() {
        const stichtagfristerinnerungFirmenverwalter =
            this.shadowRoot.querySelector("#fristerrinnerungFirmenverwalter")
                ?.value
                ? this.shadowRoot.querySelector(
                      "#fristerrinnerungFirmenverwalter"
                  ).value
                : 1;

        if (stichtagfristerinnerungFirmenverwalter) {
            this._form.fristerrinnerungFirmenverwalter =
                stichtagfristerinnerungFirmenverwalter;
            this.requestUpdate();
        }
    }

    _changeFirmenverwalterStichtag() {
        const stichtagFirmenverwalter = this.shadowRoot.querySelector(
            "#stichtagFirmenverwalter"
        )?.value
            ? this.shadowRoot.querySelector("#stichtagFirmenverwalter").value
            : 1;

        if (stichtagFirmenverwalter) {
            this._form.stichtagFirmenverwalter = stichtagFirmenverwalter;
            this.requestUpdate();
        }
    }

    _changeStichtagSperrung() {
        const stichtagSperrung =
            this.shadowRoot.querySelector("#stichtagSperrung")?.value;

        if (stichtagSperrung) {
            this._form.stichtagSperrung = stichtagSperrung;
            this.requestUpdate();
        }
    }

    _changeSpracheVorlage(e, index) {
        const src = e.srcElement || e.target;
        const sprache = src.value;
        this._vorlagenMindestlohn[index].sprache = sprache;
        this._vorlagenMindestlohn = [...this._vorlagenMindestlohn];
    }

    _vorlageSpeichern(e) {
        e.preventDefault();
        const modal = this.shadowRoot.querySelector("#file-modal");
        modal.close();
    }

    _changeVorlage(index) {
        const input = this.shadowRoot.querySelector(
            "#vorlage-mindestlohnerklaerung"
        );
        var files = input.files;

        if (index >= 0) {
            const bearbeiteteFile = this.shadowRoot.querySelector(
                "#vorlage-mindestlohnerklaerung-bearbeitet" + index
            ).files[0];
            this._vorlagenMindestlohn[index].file = bearbeiteteFile;
            (this._vorlagenMindestlohn[index].name = bearbeiteteFile.name),
                this.requestUpdate();
        } else {
            for (let file of files) {
                this._vorlagenMindestlohn = [
                    ...this._vorlagenMindestlohn,
                    { file: file, name: file.name, sprache: "" },
                ];
            }
        }
    }

    async _erstelleMindestlohnkonfiguration(e) {
        e.preventDefault();
        this.shadowRoot.querySelector("#sicherheitsabfrage").close();
        this._loading = true;

        for (let vorlage of this._vorlagenMindestlohn) {
            if (vorlage.name) {
                this._formData.append(
                    "MindestlohnerklaerungVorlagen/" + vorlage.sprache,
                    vorlage.file,
                    vorlage.name
                );
            }
        }

        const data = await store.dispatch(
            erstelleMindestlohnKonfiguration(this._formData, this._projektId)
        );

        if (data) {
            store.dispatch(
                showToast({
                    text: "Mindestlohnkonfiguration erfolgreich aktualisiert",
                })
            );
            this._loadingMindestlohnKonfiguration();
            this._loading = false;
        }
    }
    _openModal(e) {
        e.preventDefault();
        const src = e.srcElement || e.target;
        this._formData = new FormData(src);
        this.shadowRoot.querySelector("#sicherheitsabfrage").show();
    }

    _closeModal(e) {
        e.preventDefault();
        this.shadowRoot.querySelector("#sicherheitsabfrage").close();
    }
    async _aktualisiereMindestlohnkonfiguration(e) {
        e.preventDefault();
        this.shadowRoot.querySelector("#sicherheitsabfrage").close();
        this._loading = true;

        const vorlagen = [];

        for (var i = 0; i < this._vorlagenMindestlohn.length; i++) {
            if (this._vorlagenMindestlohn[i].id) {
                this._formData.append(
                    `Vorlagen[${i}].Id`,
                    this._vorlagenMindestlohn[i].id
                );
                this._formData.append(
                    `Vorlagen[${i}].Sprache`,
                    this._vorlagenMindestlohn[i].sprache
                );
                this._formData.append(
                    `Vorlagen[${i}].Name`,
                    this._vorlagenMindestlohn[i].name
                );
                if (this._vorlagenMindestlohn[i].file instanceof Blob) {
                    this._formData.append(
                        "MindestlohnerklaerungVorlagen/Id/" +
                            this._vorlagenMindestlohn[i].id,
                        this._vorlagenMindestlohn[i].file,
                        this._vorlagenMindestlohn[i].name
                    );
                }
            } else {
                if (this._vorlagenMindestlohn[i].file instanceof Blob) {
                    this._formData.append(
                        "MindestlohnerklaerungVorlagen/" +
                            this._vorlagenMindestlohn[i].sprache,
                        this._vorlagenMindestlohn[i].file,
                        this._vorlagenMindestlohn[i].name
                    );
                }
            }
        }

        const data = await store.dispatch(
            aktualisiereMindestlohnKonfiguration(
                this._formData,
                this._projektId
            )
        );

        if (data) {
            store.dispatch(
                showToast({
                    text: "Mindestlohnkonfiguration erfolgreich aktualisiert",
                })
            );
            this._loadingMindestlohnKonfiguration();
            this._loading = false;
        }
    }

    async _loadingMindestlohnKonfiguration() {
        this._loading = true;
        const data = await store.dispatch(
            fetchFetchMindestlohnKonfiguration({
                projektId: this._projektId,
            })
        );
        if (data) {
            this._form = data;
            this._vorlagenMindestlohn =
                data.mindestlohnerklaerungVorlagen || [];
            this._formData = {};
            this.requestUpdate();
            this.shadowRoot.querySelector("asd-helper").requestUpdate();
            this._loading = false;
        }
    }

    updated(properties) {
        properties.forEach((oldValue, propName) => {
            if (propName === "active") {
                if (this.active !== oldValue) {
                    if (this.active && this._projektId) {
                        this._loadingMindestlohnKonfiguration();
                    } else {
                        this.shadowRoot.querySelector("#mindestlohn").reset();
                        this._form = {};
                        this._vorlagenMindestlohn = [];
                        this._formData = {};
                        this.requestUpdate();
                    }
                }
            }
        });
    }

    stateChanged(state) {
        this._projektId = state.algoApp.projektId;
        this._loading = state.zukoEinstellungen.loading;
        this._rechtMindestlohnKonfigurationBearbeiten =
            state.projekt.projekt?.projektrolle?.zutrittskontrolle?.zukoMindestlohnKonfigurationBearbeiten;
    }
}

window.customElements.define(
    "zuko-einstellungen-uebersicht-view-mindestlohn-page",
    ZukoEinstellungenUebersichtViewMindestlohnPage
);
