import {
    //#region Mitarbeiter
    MITARBEITER_ACTION_FAIL,
    MITARBEITER_ACTION_BEGIN,
    RESET_MITARBEITER_SUCCESS,
    LOESCHE_MITARBEITER_COMMAND_SUCCESS,
    FETCH_MITARBEITER_SUCCESS,
    VERWARNE_MITARBEITER_COMMAND_SUCCESS,
    MITARBEITER_AKTIV_SUCCESS,
    //#endregion

    //#region Dashboard
    FETCH_MITARBEITER_DASHBOARD_SUCCESS,
    //#endregion

    //#region Stammdaten
    FETCH_MITARBEITER_STAMMDATEN_SUCCESS,
    AKTUALISIERE_MITARBEITER_STAMMDATEN_COMMAND_SUCCESS,
    //#endregion

    //#region Qualifikationen
    AKTUALISIERE_MITARBEITER_QUALIFIKATIONEN_COMMAND_SUCCESS,
    //#endregion

    //#region Profilbild
    FETCH_MITARBEITER_PROFILBILD_SUCCESS,
    LADE_MITARBEITER_PROFILBILD_HOCH_SUCCESS,
    //#endregion

    //#region Baustellenausweise
    RESET_DRUCK_VORLAGE_SUCCESS,
    FETCH_MITARBEITER_DRUCKEN_SUCCESS,
    EXISTIERT_BAUSTELLENAUSWEIS_SUCCESS,
    MITARBEITER_DRUCKVORLAGE_ACTION_BEGIN,
    DRUCKE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS,
    SYNCHRONISIERE_BAUSTELLENAUSWEIS_SUCCESS,
    SPERRE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS,
    LOESCHE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS,
    FETCH_MITARBEITER_AUSWEIS_VORLAGE_SUCCESS,
    MITARBEITER_BAUSTELLENAUSWEIS_DRUCKEN_FAIL,
    ERSTELLE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS,
    FETCH_MITARBEITER_BAUSTELLENAUSWEIS_SUCCESS,
    ENTSPERRE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS,
    SPERRE_ALLE_BAUSTELLENAUSWEISE_COMMAND_SUCCESS,
    SETZTE_MITARBEITER_EINSTELLUNGEN_BAUSTELLENAUSWEIS_SUCCESS,
    SYNCHRONISIERE_MITARBEITER_EINSTELLUNGEN_BAUSTELLENAUSWEIS_SUCCESS,
    //#endregion

    //#region Unterlagen
    FETCH_MITARBEITER_UNTERLAGEN_SUCCESS,
    //#endregion

    //#region Mitarbeiterunterlagen
    PRUEFE_MITARBEITER_EUFEMA_COMMAND_SUCCESS,
    LOESCHE_MITARBEITER_EUFEMA_COMMAND_SUCCESS,
    ERSTELLE_MITARBEITER_EUFEMA_COMMAND_SUCCESS,
    ENTPRUEFE_MITARBEITER_EUFEMA_COMMAND_SUCCESS,
    ERSTELLE_WEITERE_MITARBEITER_EUFEMA_COMMAND_SUCCESS,
    //#endregion

    //#region Mindestlohnerklaerung
    PRUEFE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS,
    LOESCHE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS,
    ERSTELLE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS,
    FETCH_MITARBEITER_MINDESTLOHNERLAERUNG_SUCCESS,
    ENTPRUEFE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS,
    //#endregion
} from "../../actions/zuko/mitarbeiter.js";

const mitarbeiterState = {
    error: null,
    loading: false,
    profilbild: "",
    dashboard: [],
    stammdaten: [],
    unterlagen: [],
    druckVorlage: [],
    baustellenausweise: [],
    mindestlohnerklaerungen: [],
    istVerwarnt: false,
    istAktiv: true,
    exisitiertBaustellenausweis: false,
};

const savedState =
    localStorage.getItem("savedState") ||
    JSON.stringify({ ...mitarbeiterState });
const INITIAL_STATE =
    JSON.parse(savedState).mitarbeiterState || mitarbeiterState;

const mitarbeiter = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MITARBEITER_ACTION_FAIL:
        case MITARBEITER_BAUSTELLENAUSWEIS_DRUCKEN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case MITARBEITER_DRUCKVORLAGE_ACTION_BEGIN:
        case MITARBEITER_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FETCH_MITARBEITER_STAMMDATEN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                stammdaten: action.payload,
            };
        case MITARBEITER_AKTIV_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                istAktiv: action.payload,
            };
        case FETCH_MITARBEITER_BAUSTELLENAUSWEIS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                baustellenausweise: action.payload,
            };
        case FETCH_MITARBEITER_UNTERLAGEN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                unterlagen: action.payload,
            };
        case FETCH_MITARBEITER_MINDESTLOHNERLAERUNG_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                mindestlohnerklaerungen: action.payload,
            };
        case FETCH_MITARBEITER_PROFILBILD_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                profilbild: action.payload,
            };
        case FETCH_MITARBEITER_AUSWEIS_VORLAGE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                druckVorlage: action.payload,
            };
        case FETCH_MITARBEITER_DASHBOARD_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                dashboard: action.payload,
            };
        case RESET_MITARBEITER_SUCCESS:
            return mitarbeiterState;
        case RESET_DRUCK_VORLAGE_SUCCESS:
            return {
                ...state,
                druckVorlage: {},
            };
        case FETCH_MITARBEITER_SUCCESS:
            return {
                ...state,
                mitarbeiter: action.payload,
                error: null,
                loading: false,
            };
        case LOESCHE_MITARBEITER_COMMAND_SUCCESS:
            return {
                ...state,
                mitarbeiter: {},
                error: null,
                loading: false,
            };
        case VERWARNE_MITARBEITER_COMMAND_SUCCESS:
            return {
                ...state,
                istVerwarnt: true,
                error: null,
                loading: false,
            };
        case AKTUALISIERE_MITARBEITER_STAMMDATEN_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case AKTUALISIERE_MITARBEITER_QUALIFIKATIONEN_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case FETCH_MITARBEITER_DRUCKEN_SUCCESS:
        case SYNCHRONISIERE_BAUSTELLENAUSWEIS_SUCCESS:
        case ERSTELLE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS:
        case SETZTE_MITARBEITER_EINSTELLUNGEN_BAUSTELLENAUSWEIS_SUCCESS:
        case SYNCHRONISIERE_MITARBEITER_EINSTELLUNGEN_BAUSTELLENAUSWEIS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case SPERRE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case SPERRE_ALLE_BAUSTELLENAUSWEISE_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case ENTSPERRE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case DRUCKE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                ausweis: action.payload,
            };
        case LOESCHE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case EXISTIERT_BAUSTELLENAUSWEIS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                exisitiertBaustellenausweis: action.payload.result,
            };
        case ERSTELLE_WEITERE_MITARBEITER_EUFEMA_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case LOESCHE_MITARBEITER_EUFEMA_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case ERSTELLE_MITARBEITER_EUFEMA_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case LADE_MITARBEITER_PROFILBILD_HOCH_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case ERSTELLE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case LOESCHE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case PRUEFE_MITARBEITER_EUFEMA_COMMAND_SUCCESS:
        case ENTPRUEFE_MITARBEITER_EUFEMA_COMMAND_SUCCESS:
        case PRUEFE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS:
        case ENTPRUEFE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        default:
            return state;
    }
};

export default mitarbeiter;
