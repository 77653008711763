const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;

export const fetchTransportAnmeldungViewData = async (sprache, projektId) => {
    appInsights.startTrackEvent("fetchTransportAnmeldungViewData");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/transportanmeldung-view-data`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchTransportAnmeldungViewData", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchTransportAnmeldungViewData", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchTransportAnmeldungViewData", {
            success: json.success,
        });
        return json;
    }
};

export const avisTransportAnmelden = async (
    formData,
    sprache,
    projektId
) => {
    appInsights.startTrackEvent("avisTransportAnmelden");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/melde-transport-an`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("avisTransportAnmelden", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("avisTransportAnmelden", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("avisTransportAnmelden", {
            success: json.success,
        });
        return json;
    }
};

export const avisTransportAnmeldenBundeskanzleramt = async (
    formData,
    sprache,
    projektId
) => {
    appInsights.startTrackEvent("avisTransportAnmeldenBundeskanzleramt");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/melde-transport-an-bundeskanzleramt`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("avisTransportAnmeldenBundeskanzleramt", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("avisTransportAnmeldenBundeskanzleramt", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("avisTransportAnmeldenBundeskanzleramt", {
            success: json.success,
        });
        return json;
    }
};
