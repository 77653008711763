export const FETCH_ZUTRITTSPUNKTE_ADMIN_SUCCESS =
    "FETCH_ZUTRITTSPUNKTE_ADMIN_SUCCESS";
export const ZUTRITTSPUNKTE_ACTION_FAIL = "ZUTRITTSPUNKTE_ACTION_FAIL";
export const ZUTRITTSPUNKTE_ACTION_BEGIN = "ZUTRITTSPUNKTE_ACTION_BEGIN";
export const FETCH_ZUTRITTSPUNKTE_SUCCESS = "FETCH_ZUTRITTSPUNKTE_SUCCESS";
export const RESET_ZUTRITTSPUNKTE_SUCCESS = "RESET_ZUTRITTSPUNKTE_SUCCESS";
export const LOESCHE_ZUTRITTSPUNKT_SUCCESS = "LOESCHE_ZUTRITTSPUNKT_SUCCESS";
export const ERSTELLE_ZUTRITTSPUNKT_SUCCESS = "ERSTELLE_ZUTRITTSPUNKT_SUCCESS";
export const AKTUALISIERE_ZUTRITTSPUNKT_SUCCESS =
    "AKTUALISIERE_ZUTRITTSPUNKT_SUCCESS";
//#region Bildverifizierung
export const FETCH_BILDVERIFIZIERUNG_SUCCESS =
    "FETCH_BILDVERIFIZIERUNG_SUCCESS";
export const RESET_BILLDVERIFIZIERUNG = "RESET_BILLDVERIFIZIERUNG";
export const FETCH_MITARBEITER_BILLDVERIFIZIERUNG_PROFILBILD_BASE64_SUCCESS =
    "FETCH_MITARBEITER_BILLDVERIFIZIERUNG_PROFILBILD_BASE64_SUCCESS";
export const RESET_MITARBEITER_BILLDVERIFIZIERUNG_PROFILBILD_BASE64_SUCCESS =
    "RESET_MITARBEITER_BILLDVERIFIZIERUNG_PROFILBILD_BASE64_SUCCESS";
//#endregion

import errorHandler from "../errorHandling.js";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;
let abortZutrittspunkte;

export const resetZutrittspunkte = () => (dispatch) => {
    try {
        abortZutrittspunkte?.abort();
    } catch (e) {
        console.log(e);
    }
    dispatch({ type: RESET_ZUTRITTSPUNKTE_SUCCESS });
    appInsights.trackEvent({ name: "resetZukoZutrittspunkte" });
};

export const fetchZutrittspunkte = (projektId) => async (dispatch) => {
    appInsights.startTrackEvent("fetchZutrittspunkte");
    dispatch({ type: ZUTRITTSPUNKTE_ACTION_BEGIN });
    abortZutrittspunkte =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v2/${projektId}/zutrittspunkte/`,
        {
            method: "GET",
            signal: abortZutrittspunkte.signal,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: ZUTRITTSPUNKTE_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("fetchZutrittspunkte", {
                success: json.success,
                error: json.error,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: ZUTRITTSPUNKTE_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("fetchZutrittspunkte", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({ type: FETCH_ZUTRITTSPUNKTE_SUCCESS, payload: json.payload });
        appInsights.stopTrackEvent("fetchZutrittspunkte", {
            success: json.success,
        });
    }
};

export const fetchZutrittspunkteAdmin = (projektId) => async (dispatch) => {
    appInsights.startTrackEvent("fetchZutrittspunkteAdmin");
    dispatch({ type: ZUTRITTSPUNKTE_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/admin/v1/${projektId}/zutrittspunkte/`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: ZUTRITTSPUNKTE_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("fetchZutrittspunkteAdmin", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: ZUTRITTSPUNKTE_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("fetchZutrittspunkteAdmin", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_ZUTRITTSPUNKTE_ADMIN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchZutrittspunkteAdmin", {
            success: json.success,
        });
    }
};

export const erstelleZutrittspunktCommand =
    (url, formData) => async (dispatch) => {
        appInsights.startTrackEvent("erstelleZutrittspunktCommand");
        dispatch({ type: ZUTRITTSPUNKTE_ACTION_BEGIN });

        const response = await fetch(url, {
            method: "POST",
            body: formData,
        });
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: ZUTRITTSPUNKTE_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent("erstelleZutrittspunktCommand", {
                    success: json.success,
                    error: json,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: ZUTRITTSPUNKTE_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("erstelleZutrittspunktCommand", {
                    success: response.ok,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: ERSTELLE_ZUTRITTSPUNKT_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("erstelleZutrittspunktCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };
export const aktualisiereZutrittspunktCommand = (e) => async (dispatch) => {
    appInsights.startTrackEvent("aktualisiereZutrittspunktCommand");
    dispatch({ type: ZUTRITTSPUNKTE_ACTION_BEGIN });
    const form = e.srcElement || e.target;
    const url = form.dataset.url;
    const formData = new FormData(form);
    const response = await fetch(url, {
        method: "POST",
        body: formData,
    });
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: ZUTRITTSPUNKTE_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("aktualisiereZutrittspunktCommand", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: ZUTRITTSPUNKTE_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("aktualisiereZutrittspunktCommand", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: AKTUALISIERE_ZUTRITTSPUNKT_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("aktualisiereZutrittspunktCommand", {
            success: json.success,
        });
        return json.payload;
    }
};

export const loescheZutrittspunktCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("loescheZutrittspunktCommand");
        dispatch({ type: ZUTRITTSPUNKTE_ACTION_BEGIN });
        const url = `/api/zuko/admin/v1/${projektId}/loesche-zutrittspunkt`;
        const response = await fetch(url, {
            method: "POST",
            body: formData,
        });
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: ZUTRITTSPUNKTE_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent("loescheZutrittspunktCommand", {
                    success: json.success,
                    error: json,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: ZUTRITTSPUNKTE_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("loescheZutrittspunktCommand", {
                    success: response.ok,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: LOESCHE_ZUTRITTSPUNKT_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("loescheZutrittspunktCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

//#region Bildverifizierung

export const fetchMitarbeiterBildverifizierungProfilbildBase64 =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchMitarbeiterBildverifizierungProfilbildBase64"
        );
        dispatch({ type: ZUTRITTSPUNKTE_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${data.projektId}/mitarbeiter-profilbild-base64/?mitarbeiterId=${data.mitarbeiterId}`,
            getRequestOptions
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: ZUTRITTSPUNKTE_ACTION_FAIL,
                    error: { errorMessage: json.toString() },
                });
                appInsights.stopTrackEvent(
                    "fetchMitarbeiterBildverifizierungProfilbildBase64",
                    {
                        success: false,
                        error: { errorMessage: json.toString() },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: ZUTRITTSPUNKTE_ACTION_FAIL,
                    error: { errorMessage: response.toString() },
                });
                appInsights.stopTrackEvent(
                    "fetchMitarbeiterBildverifizierungProfilbildBase64",
                    {
                        success: false,
                        error: { errorMessage: response.toString() },
                    }
                );
            }
        }
        if (json) {
            dispatch({
                type: FETCH_MITARBEITER_BILLDVERIFIZIERUNG_PROFILBILD_BASE64_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchMitarbeiterBildverifizierungProfilbildBase64",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const resetMitarbeiterBildverifizierungProfilbildBase64 =
    () => (dispatch) => {
        dispatch({
            type: RESET_MITARBEITER_BILLDVERIFIZIERUNG_PROFILBILD_BASE64_SUCCESS,
        });
        appInsights.trackEvent({
            name: "resetMitarbeiterBildverifizierungProfilbildBase64",
        });
    };

export const resetBildverifizierung = () => (dispatch) => {
    dispatch({
        type: RESET_BILLDVERIFIZIERUNG,
    });
    appInsights.trackEvent({
        name: "resetBildverifizierung",
    });
};
//#endregion
