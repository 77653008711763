const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;

export const holeBuchungsUebersicht = async (
    language,
    projektId,
    containerburgId,
    status
) => {
    appInsights.startTrackEvent("holeBuchungsUebersicht");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/buchungen-uebersicht/?containerburgId=${containerburgId}&status=${status}`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("holeBuchungsUebersicht", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("holeBuchungsUebersicht", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("holeBuchungsUebersicht", {
            success: json.success,
        });
        return json;
    }
};

export const fetchContainerDetails = async (
    language,
    projektId,
    containerId
) => {
    appInsights.startTrackEvent("fetchContainerDetails");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/container-details?containerId=${containerId}`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchContainerDetails", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchContainerDetails", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchContainerDetails", {
            success: json.success,
        });
        return json;
    }
};

export const fetchContainerbuchungenDetails = async (
    language,
    projektId,
    containerBuchungId
) => {
    appInsights.startTrackEvent("fetchContainerbuchungenDetails");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/container-buchung-details?containerBuchungId=${containerBuchungId}`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchContainerbuchungenDetails", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchContainerbuchungenDetails", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchContainerbuchungenDetails", {
            success: json.success,
        });
        return json;
    }
};

export const erstelleBuchung = async (language, projektId, formData) => {
    appInsights.startTrackEvent("erstelleBuchung");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/erstelle-buchung`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("erstelleBuchung", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("erstelleBuchung", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("erstelleBuchung", {
            success: json.success,
        });
        return json;
    }
};

export const storniereBuchung = async (language, projektId, formData) => {
    appInsights.startTrackEvent("storniereBuchung");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/storniere-buchung`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("storniereBuchung", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("storniereBuchung", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("storniereBuchung", {
            success: json.success,
        });
        return json;
    }
};

export const genehmigeBuchung = async (language, projektId, formData) => {
    appInsights.startTrackEvent("genehmigeBuchung");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/genehmige-buchung`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("genehmigeBuchung", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("genehmigeBuchung", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("genehmigeBuchung", {
            success: json.success,
        });
        return json;
    }
};

export const aktualisiereBuchung = async (language, projektId, formData) => {
    appInsights.startTrackEvent("aktualisiereBuchung");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/bearbeite-buchung`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("aktualisiereBuchung", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("aktualisiereBuchung", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("aktualisiereBuchung", {
            success: json.success,
        });
        return json;
    }
};

export const verlaengereBuchung = async (language, projektId, formData) => {
    appInsights.startTrackEvent("verlaengereBuchung");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/verlaengere-buchung`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("verlaengereBuchung", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("verlaengereBuchung", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("verlaengereBuchung", {
            success: json.success,
        });
        return json;
    }
};

export const fetchViewDataBuchungsanfrage = async (language, projektId) => {
    appInsights.startTrackEvent("fetchViewDataBuchungsanfrage");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/buchungsanfrage-view-data`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchViewDataBuchungsanfrage", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchViewDataBuchungsanfrage", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchViewDataBuchungsanfrage", {
            success: json.success,
        });
        return json;
    }
};

export const fetchBuchungDetails = async (language, projektId, buchungId) => {
    appInsights.startTrackEvent("fetchBuchungDetails");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/buchung-details?buchungId=${buchungId}`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchBuchungDetails", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchBuchungDetails", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchBuchungDetails", {
            success: json.success,
        });
        return json;
    }
};

export const erstelleBuchungsanfrage = async (
    language,
    projektId,
    formData
) => {
    appInsights.startTrackEvent("erstelleBuchungsanfrage");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/erstelle-buchung`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("erstelleBuchungsanfrage", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("erstelleBuchungsanfrage", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("erstelleBuchungsanfrage", {
            success: json.success,
        });
        return json;
    }
};

export const fetchBuchungGenehmigenViewData = async (
    language,
    projektId,
    buchungId,
    buchungVon,
    buchungBis
) => {
    appInsights.startTrackEvent("fetchBuchungGenehmigenViewData");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/genehmige-buchung-view-data?buchungId=${buchungId}&buchungVon=${buchungVon}&buchungBis=${buchungBis}`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchBuchungGenehmigenViewData", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchBuchungGenehmigenViewData", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchBuchungGenehmigenViewData", {
            success: json.success,
        });
        return json;
    }
};

export const fetchBuchungKuendigenViewData = async (
    language,
    projektId,
    buchungId
) => {
    appInsights.startTrackEvent("fetchBuchungKuendigenViewData");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/kuendige-buchung-view-data?buchungId=${buchungId}`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchBuchungKuendigenViewData", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchBuchungKuendigenViewData", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchBuchungKuendigenViewData", {
            success: json.success,
        });
        return json;
    }
};

export const kuendigeBuchung = async (language, projektId, formData) => {
    appInsights.startTrackEvent("kuendigeBuchung");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/kuendige-containerbuchung`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("kuendigeBuchung", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("kuendigeBuchung", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("kuendigeBuchung", {
            success: json.success,
        });
        return json;
    }
};

export const fetchContainerVerlangernViewData = async (
    language,
    projektId,
    buchungId
) => {
    appInsights.startTrackEvent("fetchContainerVerlangernViewData");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/verlaengere-buchung-view-data?buchungId=${buchungId}`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchContainerVerlangernViewData", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchContainerVerlangernViewData", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchContainerVerlangernViewData", {
            success: json.success,
        });
        return json;
    }
};