import {LitElement} from 'lit';
import { html } from "lit/html.js"

import { SbAdmin2Styles } from '../../css/sb-admin-2-styles.js';
import { FontawesomeIcons } from '../../css/fontawesome-icons.js';

class BsViewTitle extends LitElement {
    static get styles() {
        return [
            SbAdmin2Styles,
            FontawesomeIcons
        ];
    }

    static get properties() {
        return {
            title: { type: String }
        };
    }

    render() {
        return html`
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">${this.title}</h1>
                <slot></slot>
            </div>
        `;
    }

    constructor() {
        super();
        this.title = '';
    }
}

window.customElements.define('bs-view-title', BsViewTitle);