export const TRANSLATIONS_EN_PROJEKT = {
    ABRECHNUNGSKONFIGURATOR: "Billingconfigurator",
    ABRECHNUNGSKONFIGURATOR_GETRENNT: "Billing-configurator",
    ABRECHNUNGSMODELL: {
        BUCHHALTUNG_ODER_AUFTRAGGEBER:
            "Choose the type of recipient client or accounting.",
        TITEL: "Billing model",
        HELPER_1:
            "Select here whether the project should be billed according to the license or flat-rate model?",
        HELPER_2:
            "Registered e-mail addresses receive monthly e-mails listing the active ID cards.",
        LABEL_1: "Which billing model would you like to choose?",
        MAIL_EXISTIERT: "Mail has already been added",
        MAILTYP: "Typ of mail",
    },
    AKTIVIEREN: "Activate",
    ALLE: "All",
    AUFTRAGGEBER: "Recipient client",
    AUSWAEHLEN_PROJEKTROLLE: "Choose a project role",
    AUSWERTUNGEN: "Evaluations",
    BENUTZER: "User",
    BITTE_AUSWAEHLEN: "Choose please",
    BUCHHALTUNG: "Accounting",
    CONTAINER: "Shipping",
    CONTAINER_HELPER_TEXT_1:
        "Here you can set rights to the shipping module („navigation point Container“).",
    CONTAINER_HELPER_TEXT_2: "View bookings",
    CONTAINER_HELPER_TEXT_TITLE_2:
        "Select „All“ if the role should be able to view all bookings.",
    CONTAINER_HELPER_TEXT_3:
        "View bookings in detail select „Yes“, then you have further options that you can assign to the role.",
    CONTAINER_HELPER_TEXT_TITLE_3: "View bookings in detail",
    CONTAINER_HELPER_TEXT_TITLE_4:
        "Select „Yes“ if the role will view module files. If you choose „Yes“, you can then continue to assign rights.",
    CONTAINER_HELPER_TEXT_TEXT_4: "View module files",
    CONTAINER_HELPER_TEXT_TITLE_5:
        "Select „Yes“ when viewing the role accounting documents configuration. If you choose „Yes“, you can then continue to assign rights.",
    CONTAINER_HELPER_TEXT_TEXT_5: "View accounting documents configuration",
    CONTAINER_HELPER_TEXT_TITLE_6:
        "Select „Yes“ when viewing the role container castle configuration. If you choose „Yes“, you can then continue to assign rights.",
    CONTAINER_HELPER_TEXT_TEXT_6: "View container castle",
    CONTAINER_HELPER_TEXT_TITLE_7:
        "Select „Yes“ when viewing the role container castle configuration. If you choose „Yes“, you can then continue to assign rights.",
    CONTAINER_HELPER_TEXT_TEXT_7: "View container configuration",
    DASHBOARD: "Dashboard",
    DASHBOARD_HELPER_TEXT_1:
        "In this area you can set rights to the dashboard module (“dashboard navigation point”).",
    DASHBOARD_HELPER_TEXT_TITLE_2: "View evaluations",
    DASHBOARD_HELPER_TEXT_2:
        "SELECT „Yes“ if the role should be able to view the Evaluations navigation point with its content and to export it.",
    DASHBOARD_HELPER_TEXT_TITLE_3: "View export configuration",
    DASHBOARD_HELPER_TEXT_TEXT_3:
        "Select „Yes“, if you want the role to be able to use the settings navigation item" +
        " with the configured email addresses..",
    DASHBOARD_HELPER_TEXT_TEXT_4:
        "If you selected  „Yes“, you have further options",
    DASHBOARD_HELPER_TEXT_TITLE_5: "Export Konfiguration bearbeiten",
    DASHBOARD_HELPER_TEXT_TEXT_5:
        "Select „Yes“ if the role should be able to edit the configured email addresses as well as add new ones and remove existing ones.",
    DEAKTIVIEREN: "Deactivate",
    DSGVO_OEFFNEN: "Show data protection consent",
    EINSTELLUNGEN: "Settings",
    HELPER_TEXT_VERERBUNG:
        "If an answer option is not clickable, the parent role from which this role inherits does not have the corresponding right.",
    JA: "Yes",
    KEINE_MAIL_HINZUGEFUEGT: "No Mail added the distributor",
    KONFIGURATION: "Configuration",
    KONFIGURATION_AKTUALISIEREN: "Update configuration",
    KONFIGURATION_ERFOLGREICH_AKTUALSIIERT:
        "Configuration updated successfully",
    KONFIGURATION_ERFOLGREICH_GESPEICHERT: "Configuration created successfully",
    KONFIGURATION_ERSTELLEN: "Create configuration",
    LIZENZMODELL: "License model",
    MAIL: "Mail",
    MAIL_EINGEBEN: "Enter a mail",
    MAILVERTEILER: "Mail distributor",
    MEINE: "My",
    MEINE_PROJEKTE: "My projects",
    MEINE_UND_GEERBTE: "Mine & inherited",
    MIT_ALLEN_FIRMEN: "with all companies including the entire staff",
    MIT_MEINEN_FIRMEN: "with my companies including the entire staff",
    NACHNAME: "Surname",
    NAME: "Name",
    NAME_EXISTIERT: "Name already exists",
    NAME_PROJEKTBETEILIGTER: "Name project participants persons",
    NEIN: "No",
    NEWS: "News",
    PAUSCHALMODELL: "Flat-rate model",
    PARENT_ROLE: "Parent project role",
    PARENT_PROJEKTROLLE_AUSGEWAEHLT:
        "Warning.You choose a parent project role.",
    PROJEKT: "Project",
    PFLICHTFELDER_AUSWAEHLEN: "Reciered fields missing a value",
    PROJEKT_AKTUALISIEREN: "Update project",
    PROJEKTBETEILIGTEN_EINLADEN: "Invite project participants",
    PROJEKTBETEILIGTER_ERFOLGREICH_HINZUGEFUEGT:
        "Project participants was successfully invited",
    PROJEKTBETEILIGTEN_MODAL_BODY: "What is your next step?",
    PROJEKTBETEILIGTEN_TITEL: "Project participants person",
    PROJEKTBETEILIGTEN_UEBERSICHT: "Project participants overview",
    PROJEKTBETEILIGTER_AKTUALISIEREN: "Update project participants",
    PROJEKTBETEILIGTER_GELOESCHT: "Project participant deleted",
    PROJEKTBETEILIGTER_ERFOLGREICH_ERSTELLT:
        "Project participants successfully invited",
    PROJEKTBETEILIGTER_LOESCHEN: "Delete project participants",
    PROJEKTBETEILIGTER_MODAL_BODY:
        "Do you really want to delete this project participant?",
    PROJEKTBETEILIGTER_MODAL_TITEL: "Delete project participant",
    PROJEKTBETEILIGTE_BETRACHTEN: "Detailed view on project participants",
    PROJEKTBETEILIGTE_EINLADEN: "Invite project participants",
    PROJEKTBETEILIGTE_LOESCHEN: "Delete project participants",
    PROJEKTBETEILIGTE_TITEL: "Project participants",
    PROJEKTE: "Projects",
    PROJEKTNAME: "Project name",
    PROJEKTNAME_EINGABE: "Please enter project name",
    PROJEKTROLLE_AKTUALISIEREN: "Update project role",
    PROJEKTROLLE_AKTUALISIERT: "Project role updated",
    PROJEKTROLLE_ERSTELLEN: "Create project role",
    PROJEKTROLLE_GELOESCHT: "Project role deleted",
    PROJEKTROLLE_MIT_BENUTZERN_FEHLERHAFT:
        "Project role with users cannot be deleted.",
    PROJEKTROLLE_LOESCHEN: "Delete project role",
    PROJEKTROLLE_MODAL_BODY: "Do you really want to delete this project role?",
    PROJEKTROLLE_MODAL_TITEL: "Overview of your entered data",
    PROJEKTROLLE_TITEL: "Project role",
    PROJEKTROLLE_ZUWEISEN: "Assign project role to a project participant",
    PROJEKTROLLEN: "Project roles",
    PROJEKTROLLEN_BEARBEITEN: "Edit project roles",
    PROJEKTROLLEN_BETRACHTEN: "View all project roles",
    PROJEKTROLLEN_DETAILIERT_BETRACHTEN: "Detailed view on project roles",
    PROJEKTROLLEN_ERSTELLEN: "Create project roles",
    PROJEKTROLLEN_LOESCHEN: "Delete project roles",
    PROJEKTROLLEN_NAME_EINGEBEN: "Please enter a name of projekt role",
    PROJEKT_ERSTELLEN: "Create project",
    PROJEKT_ERSTELLEN_MODAL_BODY_TEXT1: "What is your next step?",
    PROJEKT_ERSTELLEN_MODAL_BODY_TEXT2: "Create another project role",
    PROJEKT_ERSTELLEN_MODAL_BODY_TEXT3: "View the created project role",
    PROJEKT_ERSTELLEN_MODAL_BODY_TEXT4: "Project role overview",
    PROJEKT_ERSTELLEN_MODAL_TITEL: "Project role has been created successfully",
    PROJEKT_ERSTELLEN_VIEW: {
        AVIS_MODUL: "Notification system",
        BAUSTELLENGROESSE: "Construction site size",
        BAUSTELLE_OEFFNUNGSZEITEN: "Construction site opening hours",
        BRUTTORAUMINHALT: "Gross area contents",
        BRUTTORAUMINHALT_EINGEBEN: "Please enter gross volume",        
        BRUTTOGESCHLOSSFLAECHE: "Gross floor area",        
        BRUTTOGESCHLOSSFLAECHE_EINGEBEN: "Please enter gross floor area",
        CONTAINER_MODUL: "Shipping",
        CONTAINERBURG_ADMIN_GRAFIK_BETRACHTEN: "View management container castle illustration",
        DASHBOARD_MODUL: "Dashboard",
        DATENSCHUTZ: "Data protection",
        DATENSCHUTZVERORDNUNGFILEDOWNLOADNAME:
            "Data protection regulationFileDownloadName",
        DEBITORENNUMMER: "Debtor number",
        DEBITORENNUMMER_EINGEBEN: "Enter the debtor number",
        DSGVO: "Data protection consent",
        DIENSTAG: "Tuesday",
        DONNERSTAG: "Thursday",
        ENDDATUM: "Date of End",
        FREITAG: "Friday",
        FUENF_ZEICHEN_FORMAT: "Format have to be 5 numbers",
        KOSTENSTELLE: "Cost centre",
        KOSTENSTELLE_EINGEBEN: "Enter the cost centre",
        LIEFERADRESSE: "Delivery address",
        MITTWOCH: "Wednesday",
        MODULE: "Modul",
        MONTAG: "Monday",
        NEWS_MODUL: "News",
        OEFFNUNG: "Opening",
        PLZ: "Zip code",
        PLZ_EINGEBEN: "Please enter zip code",
        PLZ_ERROR: "Please enter a zip code with five numbers",
        PROJEKTADRESSE: "Project address",
        PROJEKTBILD: "Project picture",
        PROJEKTLEITER: "Project manager",
        PROJEKTLEITER_EINGABE: "Please enter project manager",
        PROJEKTSTAMMDATEN: "Project master data",
        PROJEKTZEITRAUM: "Project-time",
        SAMSTAG: "Saturday",
        SCHLIESSUNG: "Closing",
        SECHS_ZEICHEN_FORMAT: "Format have to be 6 numbers",
        SONNTAG: "Sunday",
        STARTDATUM: "Date of Start",
        STADT: "City",
        STADT_EINGEBEN: "Please enter city",
        STRASSE: "Road",
        STRASSE_EINGEBEN: "Please enter road",
        ZUKO_MODUL: "Access-Control",
    },
    PROJEKT_HELPER_TEXT_1:
        "In this area you can set rights to the project module („navigation point project“).",
    PROJEKT_HELPER_TEXT_TITLE_2: "View people involved in the project.",
    PROJEKT_HELPER_TEXT_2:
        "In this area you can choose between „All“ and „My“, all the following points then refer to the selected option.",
    PROJEKT_HELPER_TEXT_TITLE_3: "View project roles",
    PROJEKT_HELPER_TEXT_3:
        "In this area you can choose between „My & Inherited“ and „My“, the following points relate to the selected option.",
    PROJEKT_HELPER_TEXT_TITLE_3: "View project configuration",
    PROJEKT_HELPER_TEXT_3:
        "Select „Yes“ if you want the role to be able to see the project configuration. If you choose  „Yes“, you have another option so that the role can edit the project configurator.",
    PROJEKT_HELPER_TEXT_TITLE_4: "View billing configuration",
    PROJEKT_HELPER_TEXT_4:
        "Choose„Yes“  if you want the role to be able to see the billing configuration. If you choose „Yes“ , you have another option to allow the role to edit the billing configurator.",
    PROJEKT_RECHTE: {
        ABRECHNUNG_KONFIGURATION_BETRACHTEN: "View billing configurator",
        ABRECHNUNG_KONFIGURATION_BEARBEITEN: "Edit billing configurator",
        ABTRANSPORT_ANMELDEN: "Register evacuation",
        ANLIEFERUNG_ANMELDEN: "Register deliviery",
        ABRECHNUNGSDETAILS_BETRACHTEN: "View of billingdetails",
        AKTUELLE_BESETZUNG_BETRACHTEN: "View current cast",
        ALLE_BAUSTELLENAUSWEISE_SPERREN_BEARBEITEN:
            "Block all construction site passes",
        ALLE_PROJEKTROLLEN_BETRACHTEN: "View all project roles",
        ALLE_TRANSPORTE_BETRACHTEN: "View transports",
        AUSSTATTUNG_KONFIGURATION_BETRACHTEN: "View of equipment configuration",
        AUSSTATTUNG_KONFIGURATION_BEARBEITEN: "Edit of equipment configuration",
        AUSWEISDESIGN_KONFIGURATION_BEARBEITEN:
            "Edit Id card design configuration",
        AUSWEISDESIGN_KONFIGURATION_BETRACHTEN:
            "View Id card design configuration",
        AUSWEISGUELTIGKEIT_KONFIGURATION_BEARBEITEN:
            "Edit Id card validity configuration",
        AUSWEISGUELTIGKEIT_KONFIGURATION_BETRACHTEN:
            "View Id card validity configuration",
        AUSWERTUNGEN_BETRACHTEN: "View evaluations",
        BAUSTELLENAUSWEIS_ENTSPERREN: "Unlock construction site permits",
        BAUSTELLENAUSWEIS_EINSTELLUNGEN_SETZEN:
            "Set construction site settings",
        BAUSTELLENAUSWEIS_ERSTELLEN_UND_DRUCKEN:
            "Create & print construction site permit",
        BAUSTELLENAUSWEIS_LOESCHEN: "Delete construction site permits",
        BAUSTELLENAUSWEISE_UEBERSICHT_BETRACHTEN: "View construction site",
        BAUSTELLENAUSWEIS_SPERREN: "Lock construction site",
        BERECHTIGUNGSGRUPPEN_KONFIGURATION_BEARBEITEN:
            "Edit authorization groups configuration",
        BERECHTIGUNGSGRUPPEN_KONFIGURATION_BETRACHTEN:
            "View authorization groups configuration",
        BESTIMMUNGSORT_KONFIGURATION_BEARBEITEN: "Edit destination zone",
        BESTIMMUNGSORT_KONFIGURATION_BETRACHTEN: "View destination zone",
        BILDVERIFIZIERUNG_BETRACHTEN: "View image verification",
        BUCHUNGEN_ARCHIVIEREN: "Archive bookings",
        BUCHUNGEN_BETRACHTEN: "View bookings",
        BUCHUNGEN_BEARBEITEN: "Edit bookings",
        BUCHUNGEN_ERSTELLEN: "Add bookings",
        BUCHUNGEN_DETAILLIERT_BETRACHTEN: "Detailed view on bookings",
        BUCHUNGEN_GENEHMIGEN_UND_STORNIEREN: "Check & cancel bookings",
        BUCHUNGSUNTERLAGEN_ARCHIVIEREN: "Archive documents of bookings",
        BUCHUNGSUNTERLAGEN_HOCHLADEN: "Upload documents of bookings",
        BUCHUNGSUNTERLAGEN_KONFIGURATION_BEARBEITEN:
            "Edit documents of booking configuratin",
        BUCHUNGSUNTERLAGEN_KONFIGURATION_BETRACHTEN:
            "View documents of booking configuratin",
        BURG_KONFIGURATION_BEARBEITEN: "Edit configuration of shipping castle",
        BURG_KONFIGURATION_BETRACHTEN: "View configuration of shipping castle",
        CONTAINER_KONFIGURATION_BEARBEITEN: "Edit configuration of shipping ",
        CONTAINER_KONFIGURATION_BETRACHTEN: "View configuration of shipping",
        CONTAINERBUCHUNGSUNTERLAGEN_ARCHIVIEREN:
            "Delete of container booking document configuration",
        CONTAINERBUCHUNGSUNTERLAGEN_HOCHLADEN:
            "Update of container booking document configuration",
        CONTAINER_BUCHUNGSUNTERLAGEN_KONFIGURATION_BETRACHTEN:
            "View of container booking document configuration",
        CONTAINER_BUCHUNGSUNTERLAGEN_KONFIGURATION_BEARBEITEN:
            "Edit of container booking document configuration",
        CONTAIENRBURG_ADMIN_GRAFIK_BETRACHTEN:
            "View graphical admin container castle",
        CONTAINERBURG_BILD_BETRACHTEN: "View container castle image",
        DISPOSITIONEN_ABSCHLIESSEN: "Close dispositions",
        DISPOSITIONEN_BETRACHTEN: "View dispositions",
        EIGENE_PROJEKTROLLEN_BETRACHTEN: "View own project roles",
        EIGENE_UND_GEERBTE_PROJEKTROLLEN_BETRACHTEN:
            "View own & inherit project roles",
        EINSTELLUNGEN_BETRACHTEN: "View settings",
        EINSTELLUNGEN_BEARBEITEN: "Edit settings",
        EXPORT_KONFIGURATION_BEARBEITEN: "Edit export configuration",
        EXPORT_KONFIGURATION_BETRACHTEN: "View export configuration",
        FIRMEN_BEARBEITEN: "Edit companies",
        FIRMEN_DETAILLIERT_BETRACHTEN: "Detailed view on companies",
        FIRMEN_ERSTELLEN: "Create companies",
        FIRMEN_LOESCHEN: "Delete companies",
        FIRMENHIERARCHIE_BETRACHTEN: "View company hierarchy",
        FIRMEN_MIT_PERSONAL_BETRACHTEN: "companies including the entire staff ",
        FIRMEN_UNTERLAGEN_ARCHIVIEREN: "Archive company documents",
        FIRMEN_UNTERLAGEN_HOCHLADEN: "Upload company documents",
        FIRMEN_UNTERLAGEN_PRUEFEN_UND_WIDERRUFEN:
            "Check & uncheck company documents",
        FIRMEN_UNTERLAGEN_KONFIGURATION_BEARBEITEN:
            "Edit company documents configuration",
        FIRMEN_UNTERLAGEN_KONFIGURATION_BETRACHTEN:
            "View company documents configuration",
        FIRMEN_UNTERLAGEN_UEBERSICHT_BETRACHTEN: "View company documents",
        FIRMEN_UNTERLAGEN_WIEDERHERSTELLEN: "Revert company documents",
        FIRMEN_ZUTRITTSAUSWERTUNGEN_BETRACHTEN:
            "View evalutions of companies access events",
        KONFIGURATION_BETRACHTEN: "View project configuration",
        KONFIGURATION_BEARBEITEN: "Edit project configuration",
        NACHRICHTEN_BETRACHTEN: "View message",
        NACHRICHT_ERSTELLEN: "Create message",
        NACHRICHT_BEARBEITEN: "Edit message",
        NACHRICHT_ARCHIVIEREN: "Archive message",
        MINDESTLOHN_KONFIGURATION_BEARBEITEN: "Edit minimum wage configuration",
        MINDESTLOHN_KONFIGURATION_BETRACHTEN: "View minimum wage configuration",
        MINDESTLOHNERKLAERUNGEN_HOCHLADEN: "Upload minimum wage ",
        MINDESTLOHNERKLAERUNGEN_PRUEFEN_UND_WIDERRUFEN:
            "Check & uncheck minimum wage",
        MINDESTLOHNERKLAERUNGEN_ARCHIVIEREN: "Archieve minimum wage",
        MINDESTLOHNERKLAERUNGEN_UEBERSICHT_BETRACHTEN: "Edit minimum wage",
        MINDESTLOHNERKLAERUNGEN_WIEDERHERSTELLEN: "Revert minimum wage",
        MODULANGABEN_KONFIGURATION_BEARBEITEN: "Edit module data",
        MODULANGABEN_KONFIGURATION_BETRACHTEN: "View module data",
        MODULUNTERLAGEN_BETRACHTEN: "View module documents",
        MODULUNTERLAGEN_KONFIGURATION_BEARBEITEN: "Edit module documents",
        MODULUNTERLAGEN_KONFIGURATION_BETRACHTEN: "View module documents",
        MODULVERANTWORTLICHER_KONFIGURATION_BEARBEITEN:
            "Edit module responsible",
        MODULVERANTWORTLICHER_KONFIGURATION_BETRACHTEN:
            "Consider module responsible",
        MODUL_DATEIEN_ARCHIVIEREN: "Archive modul files",
        MODUL_DATEIEN_BETRACHTEN: "View modul files",
        MODUL_DATEIEN_HOCHLADEN: "Upload modul files",
        MODUL_DATEIEN_KONFIGURATION_BEARBEITEN:
            "Edit configuration of modul files",
        MODUL_DATEIEN_KONFIGURATION_BETRACHTEN:
            "View configuration of modul files",
        PERSONAL_BEARBEITEN: "Edit employee",
        PERSONAL_DETAILLIERT_BETRACHTEN: "Conside employee in detail",
        PERSONAL_ERSTELLEN: "Create employees",
        PERSONAL_LOESCHEN: "Delete employee",
        PERSONAL_UNTERLAGEN_ARCHIVIEREN: "Archieve employee document",
        PERSONAL_UNTERLAGEN_HOCHLADEN: "Upload employee document",
        PERSONAL_UNTERLAGEN_KONFIGURATION_BEARBEITEN:
            "Edit employee documents configuration",
        PERSONAL_UNTERLAGEN_KONFIGURATION_BETRACHTEN:
            "View employee documents configuration",
        PERSONAL_UNTERLAGEN_PRUEFEN_UND_WIDERRUFEN:
            "Check & uncheck employee documents",
        PERSONAL_UNTERLAGEN_UEBERSICHT_BETRACHTEN: "View employee documents",
        PERSONAL_UNTERLAGEN_WIEDERHERSTELLEN: "Revert employee documents",
        PERSONAL_ZUTRITTSAUSWERTUNGEN_BETRACHTEN: "View personal access events",
        PROJEKTBETEILIGTE_BEARBEITEN: "Edit participant persons",
        PROJEKTBETEILIGTE_BETRACHTEN: "View participant person",
        PROJEKTBETEILIGTEN_BETRACHTEN: "View participipant persons",
        PROJEKTBETEILIGTE_DETAILLIERT_BETRACHTEN:
            "Detailed view on project participants persons",
        PROJEKTBETEILIGTE_EINLADEN: "Invite project participants",
        PROJEKTBETEILIGTE_HINZUFUEGEN: "Add project participants persons",
        PROJEKTBETEILIGTE_LOESCHEN: "Delete project participants persons",
        PROJEKTBETEILIGTE_ROLLENZUWEISUNG_AENDERN:
            "Change role assignment of project participants persons",
        PROJEKTROLLEN_BETRACHTEN: "View project roles",
        PROJEKTROLLEN_DETAILLIERT_BETRACHTEN: "Detailed view on project roles",
        PROJEKTBETEILIGTE_DETALLIERT_BETRACHTEN:
            "Detailed view on project participants",
        PROJEKTROLLEN_ERSTELLEN: "Create project roles",
        PROJEKTROLLEN_BEARBEITEN: "Edit project roles",
        PROJEKTROLLEN_BETRACHTEN: "View all project roles",
        PROJEKTROLLEN_DETAILLIERT_BETRACHTEN: "Detailed view on project roles",
        PROJEKTROLLEN_LOESCHEN: "Delete project roles",
        RESSOURCEN_KONFIGURATION_BETRACHTEN: "View resources",
        RESSOURCEN_KONFIGURATION_BEARBEITEN: "Edit resources",
        TRANSPORT_ALLE_BETRACHTEN: "View transports",
        TRANSPORT_BEARBEITEN: "Edit transport",
        TRANSPORT_DETAILLIERT_BETRACHTEN: "View detailed transports",
        TRANSPORT_GENEHMIGEN_UND_STORNIEREN: "Approve and cancel transports",
        UNTERLAGEN_BETRACHTEN: "Documents view",
        UNTERLAGEN_KONFIGURATION_BEARBEITEN: "Edit configuration of documents",
        UNTERLAGEN_KONFIGURATION_BETRACHTEN: "View document configuration",
        ZEITSLOTS_KONFIGURATION_BETRACHTEN:  "View time slot configuration",
        ZEITSLOTS_KONFIGURATION_BEARBEITEN:  "Edit time slot configuration",
        ZEITZONEN_KONFIGURATION_BETRACHTEN: "View time zone configuration",
        ZEITZONEN_KONFIGURATION_BEARBEITEN: "Edit time zone configuration",
        ZIELORT_KONFIGURATION_BEARBEITEN: "Edit destination",
        ZIELORT_KONFIGURATION_BETRACHTEN: "View destination",
        ZUKO_SERVER_KONFIGURATION_BEARBEITEN: "Edit zuko server",
        ZUKO_SERVER_KONFIGURATION_BETRACHTEN: "View zuko server",
        ZUTRITTSEREIGNISSE_SYNCHRONISIEREN_BEARBEITEN: "Sync access events",
        ZUTRITTSPUNKTE_KONFIGURATION_BEARBEITEN:
            "Edit access point configuration",
        ZUTRITTSPUNKTE_KONFIGURATION_BETRACHTEN:
            "View access point configuration",
        ZUTRITTSPUNKTE_BETRACHTEN: "View entry points",
    },
    STAMMDATEN: "Master Data",
    STAMMDATEN_ERFOLGREICH_AKTUALISIERT: "Master data updated succesfully",
    STAMMDATEN_HELPER_TEXT_1:
        "Note that the project role name does not yet exist.",
    STAMMDATEN_HELPER_TEXT_2:
        "The role to be created inherits from this role. This means the role to be created has fewer rights than the parent project role.",
    STAMMDATEN_HELPER_TEXT_3:
        "If you have selected a system role, you can only view it and not update it.",
    UEBERSICHTSSEITEN: "Overview pages",
    UNTERNEHMEN: "Company",
    VORNAME: "First name",
    WARNUNG_PARENT_PROJEKTROLLE_BEFUELLEN:
        "Do you really want the project role to inherit from the specified project role? Then choose Yes.",
    WEITEREN_BETEILIGTEN_EINLADEN: "Invite another project participant",
    ZUKO_HELPER_TEXT_1:
        "In this area you can set rights to the access control module („access control navigation point“).",
    ZUKO_HELPER_TEXT_TITLE_2: "Consider companies with associated staff",
    ZUKO_HELPER_TEXT_2:
        "In this area you can choose between „All“ and „My“, all of the following points relate to this selection.",
    ZUKO_HELPER_TEXT_UEBERSICHTSSEITEN:
        "Overview pages with my companies including staff",
    ZUKO_HELPER_TEXT_UEBERSICHTSSEITEN_1:
        "In this area you can set the rights for all overview pages, no detailed view of employees or companies.",
    ZUKO_HELPER_TEXT_UEBERSICHTSSEITEN_2:
        "Displayed values depend on „My“ or „All“ companies with associated personnel.",
    ZUKO_HELPER_TEXT_EINSTELLUNGEN:
        "This is the configuration of the access control module and the assignment of these rights should be checked carefully.",
    ZUTRITTSKONTROLLE: "Access control",
};

export default TRANSLATIONS_EN_PROJEKT;
