export const PROJEKT_EINSTELLUNGEN_ACTION_FAIL =
    "PROJEKT_EINSTELLUNGEN_ACTION_FAIL";
export const PROJEKT_EINSTELLUNGEN_ACTION_BEGIN =
    "PROJEKT_EINSTELLUNGEN_ACTION_BEGIN";
export const FETCH_PROJEKT_EINSTELLUNGEN_SUCCESS =
    "FETCH_PROJEKT_EINSTELLUNGEN_SUCCESS";
export const RESET_PROJEKT_EINSTELLUNGEN_SUCCESS =
    "RESET_PROJEKT_EINSTELLUNGEN_SUCCESS";

//#region Abrechnungskonfigurator
export const PROJEKT_ABRECHNUNG_KONFIGURATION_ACTION_BEGIN =
    "PROJEKT_ABRECHNUNG_KONFIGURATION_ACTION_BEGIN";
export const PROJEKT_ABRECHNUNG_KONFIGURATION_ACTION_FAIL =
    "PROJEKT_ABRECHNUNG_KONFIGURATION_ACTION_FAIL";
export const FETCH_PROJEKT_ABRECHNUNG_KONFIGURATION_SUCCESS =
    "FETCH_PROJEKT_ABRECHNUNG_KONFIGURATION_SUCCESS";
export const RESET_PROJEKT_ABRECHNUNG_KONFIGURATION_SUCCESS =
    "RESET_PROJEKT_ABRECHNUNG_KONFIGURATION_SUCCESS";
//#endregion

import errorHandler from "../errorHandling.js";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;
let abortAbrechnungsKonfigurationController;

export const resetProjektEinstellungen = () => (dispatch) => {
    dispatch({ type: RESET_PROJEKT_EINSTELLUNGEN_SUCCESS });
    appInsights.trackEvent({ name: "resetProjektEinstellungen" });
};

export const fetchProjektEinstellungen = (projektId) => async (dispatch) => {
    appInsights.startTrackEvent("fetchProjektEinstellungen");
    dispatch({ type: PROJEKT_EINSTELLUNGEN_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/projekt/v2/${projektId}/projekteinstellungen.json/`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: PROJEKT_EINSTELLUNGEN_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("fetchProjektEinstellungen", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: PROJEKT_EINSTELLUNGEN_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("fetchProjektEinstellungen", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_PROJEKT_EINSTELLUNGEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchProjektEinstellungen", {
            success: json.success,
        });
    }
};


export const fetchAbrechnungsKonfiguration = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchAbrechnungsKonfiguration");
    dispatch({ type: PROJEKT_ABRECHNUNG_KONFIGURATION_ACTION_BEGIN });
    abortAbrechnungsKonfigurationController =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/projekt/v1/${data.projektId}/abrechnung-konfiguration`,
        { signal: abortAbrechnungsKonfigurationController.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: PROJEKT_ABRECHNUNG_KONFIGURATION_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("fetchAbrechnungsKonfiguration", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: PROJEKT_ABRECHNUNG_KONFIGURATION_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("fetchAbrechnungsKonfiguration", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_PROJEKT_ABRECHNUNG_KONFIGURATION_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchAbrechnungsKonfiguration", {
            success: json.success,
        });
        return json.payload;
    }
};

export const erstelleAbrechnungsKonfiguration = (projektId, formData) => async (dispatch) => {
    appInsights.startTrackEvent("erstelleAbrechnungsKonfiguration");
    dispatch({ type: PROJEKT_ABRECHNUNG_KONFIGURATION_ACTION_BEGIN });
    abortAbrechnungsKonfigurationController =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/projekt/v1/${projektId}/erstelle-abrechnung-konfiguration`,
        {
            method: "POST",
            body: formData,
        },
        { signal: abortAbrechnungsKonfigurationController.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: PROJEKT_ABRECHNUNG_KONFIGURATION_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("erstelleAbrechnungsKonfiguration", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: PROJEKT_ABRECHNUNG_KONFIGURATION_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("erstelleAbrechnungsKonfiguration", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_PROJEKT_ABRECHNUNG_KONFIGURATION_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("erstelleAbrechnungsKonfiguration", {
            success: json.success,
        });
        return json.payload;
    }
};

export const aktualisiereAbrechnungsKonfiguration =
    (projektId, formData) => async (dispatch) => {
        appInsights.startTrackEvent("aktualisiereAbrechnungsKonfiguration");
        dispatch({ type: PROJEKT_ABRECHNUNG_KONFIGURATION_ACTION_BEGIN });
        abortAbrechnungsKonfigurationController =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/projekt/v1/${projektId}/aktualisiere-abrechnung-konfiguration`,
            {
                method: "POST",
                body: formData,
            },
            { signal: abortAbrechnungsKonfigurationController.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: PROJEKT_ABRECHNUNG_KONFIGURATION_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent("fetchAbrechnungsKonfiguration", {
                    success: json.success,
                    error: json,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: PROJEKT_ABRECHNUNG_KONFIGURATION_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "aktualisiereAbrechnungsKonfiguration",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_PROJEKT_ABRECHNUNG_KONFIGURATION_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("aktualisiereAbrechnungsKonfiguration", {
                success: json.success,
            });
            return json.payload;
        }
    };
