import errorHandler from "../errorHandling";
export const BAUSTELLENAUSWEIS_KONFIGURATION_ACTION_BEGIN =
    "BAUSTELLENAUSWEIS_KONFIGURATION_ACTION_BEGIN";
export const BAUSTELLENAUSWEIS_KONFIGURATION_ACTION_FAIL =
    "BAUSTELLENAUSWEIS_KONFIGURATION_ACTION_FAIL";
export const BAUSTELLENAUSWEIS_KONFIGURATION_SUCCESS =
    "BAUSTELLENAUSWEIS_KONFIGURATION_SUCCESS";
export const AKTUALISIERE_BAUSTELLENAUSWEIS_KONFIGURATION_SUCCESS =
    "AKTUALISIERE_BAUSTELLENAUSWEIS_KONFIGURATION_SUCCESS";
export const ERSTELLE_BAUSTELLENAUSWEIS_KONFIGURATION_SUCCESS =
    "ERSTELLE_BAUSTELLENAUSWEIS_KONFIGURATION_SUCCESS";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;

export const fetchBaustellenausweisKonfiguration =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("fetchFetchBaustellenausweisKonfiguration");
        dispatch({ type: BAUSTELLENAUSWEIS_KONFIGURATION_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/admin/${data.projektId}/hole-baustellenausweis-konfiguration/`,
            getRequestOptions
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: BAUSTELLENAUSWEIS_KONFIGURATION_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchFetchBaustellenausweisKonfiguration",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: BAUSTELLENAUSWEIS_KONFIGURATION_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchFetchBaustellenausweisKonfiguration",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: BAUSTELLENAUSWEIS_KONFIGURATION_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchFetchBaustellenausweisKonfiguration",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const erstelleBaustellenausweisKonfiguration =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("erstelleBaustellenausweisKonfiguration");
        dispatch({ type: BAUSTELLENAUSWEIS_KONFIGURATION_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/admin/${projektId}/erstelle-baustellenausweis-konfiguration`,
            {
                method: "POST",
                body: formData,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }

        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: BAUSTELLENAUSWEIS_KONFIGURATION_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "erstelleBaustellenausweisKonfiguration",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: BAUSTELLENAUSWEIS_KONFIGURATION_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "erstelleBaustellenausweisKonfiguration",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }

        if (json?.success) {
            dispatch({
                type: ERSTELLE_BAUSTELLENAUSWEIS_KONFIGURATION_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "erstelleBaustellenausweisKonfiguration",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const aktualisiereBaustellenausweisKonfiguration =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent(
            "aktualisiereBaustellenausweisKonfiguration"
        );
        dispatch({ type: BAUSTELLENAUSWEIS_KONFIGURATION_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/admin/${projektId}/aktualisiere-baustellenausweis-konfiguration`,
            {
                method: "POST",
                body: formData,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(errorHandler));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: BAUSTELLENAUSWEIS_KONFIGURATION_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "aktualisiereBaustellenausweisKonfiguration",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: BAUSTELLENAUSWEIS_KONFIGURATION_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "aktualisiereBaustellenausweisKonfiguration",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }

        if (json?.success) {
            dispatch({
                type: AKTUALISIERE_BAUSTELLENAUSWEIS_KONFIGURATION_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "aktualisiereBaustellenausweisKonfiguration",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };
