export const TRANSLATIONS_EN_AVIS = {
    ABFALLSCHLUESSELNUMMER: "Waste key number",
    ABFRAGE_BESTIMMUNGSORT_ARCHIVIEREN:
        "Do you really want to delete the destination zone?",
    ABFRAGE_MODULUNTERLAGE_ARCHIVIEREN:
        "Do you really want to delete the module document?",
    ABFRAGE_RESSOURCEN_ARCHIVIEREN:
        "Do you really want to delete the resource?",
    ABFRAGE_TRANSPORT_GENEHMIGEN:
        "Do you really want to approve the transport?",
    ABFRAGE_TRANSPORT_STONIEREN: "Do you really want to cancel the transport?",
    ABHOL_DATEN: "Pick-up data",
    ABHOLFAHRZEUG: "Pick-up vehicle",
    ABHOLTERMIN: "Pick-up date",
    ABFRAGE_ZIELORT_ARCHIVIEREN: "Do you really want to deleted the zielort",
    ABLIEFERUNGSZEITEN: "Time of collection",
    ABSENDER: "Recipient",
    ABTRANSPORT: "Removal",
    ABTRANSPORTZONE: "Removal zone",
    ADRESSE: "Adress",
    ANGEMELDET_AM: "Registered on",
    ANGEMELDET_VON: "Registered by",
    ANLIEFERUNG: "Delivery",
    ANLIEFERUNGSZEITEN: "Time of Delivery",
    ANLIEFERUNGSZONE: "Delivery zone",
    ANMELDEN: "Register",
    AKTUALISIEREN: "To update",
    ALLE_ANGEMELDETEN_TRANSPORTE_GENEHMIGEN:
        "Approve all registered transport declarations",
    AUSGEWAEHLTE_RESSOURCE: "Selected resource",
    AUSGEWAEHLTE_UNTERLAGE: "Selected document",
    AVISIERUNGSART: "Notification type",
    AVIS_MODUL: "Notification Module",
    BAUSTELLEINRICHTUNGSPLAN: "Site layout plan",
    BAUSTELLENOEFFNUNGSZEITEN: "Opening hours of site",
    BEARBEITET_AM: "Edited on",
    BEARBEITET_VON: "Edited by",
    BELADUNG: "Loading",
    BELADUNGSDAUER: "Loading time (in minutes)",
    BENUTZEREMAIL: "Mail",
    BENUTZERNACHNAME: "Last name",
    BENUTZERUNTERNEHMEN: "Company",
    BENUTZERTELEFONNUMMER: "Phonenumber",
    BENUTZERVORNAME: "First name",
    BEP_WIRD_GELADEN: "Site layout plan is loading",
    BESTIMMUNGSORT: "Destination zone",
    BESTIMMUNGSORT_AKTUALISIEREN: "Update destination zone",
    BESTIMMUNGSORT_AENDERN: "Edit destination zone",
    BESTIMMUNGSORT_ARCHIVIEREN: "Delete destination zone",
    BESTIMMUNGSORT_ERFOLGREICH_ARCHIVIERT:
        "Destination zone successfully deleted",
    BESTIMMUNGSORT_ERFOLGREICH_AKTUALISIERT:
        "Destination zone successfully updated",
    BESTIMMUNGSORT_ERFOLGREICH_ERSTELLT:
        "Destination zone successfully created",
    BESTIMMUNGSORT_HINZUFUEGEN: "Add destination zone",
    BESTIMMUNGSORT_ID_NICHT_GEFUNDEN: "Destination zone-ID not found",
    BESTIMMUNGSZONE: "Destination zone",
    BITTE_ABFALLSCHLUESSELNUMMER_EINGEBEN: "Please enter the waste key number",
    BITTE_ABTRANSPORT_FAHRZEUG_WAEHLEN: "Please enter your evacuation vehicle",
    BITTE_ABTRANSPORTZONE_WAEHLEN: "Please enter your evacuation zone",
    BITTE_ADRESSE_EINGEBEN: "Please enter your adress",
    BITTE_ANLIEFERUNGS_FAHRZEUG_EINGEBEN: "Please enter your delivery vehicle",
    BITTE_ANLIEFERUNGSZONE_EINGEBEN: "Please enter your delivery zone",
    BITTE_BELADUNGSZEIT_EINGEBEN: "Please enter loading time",
    BITTE_ENGLISCHER_UEBERSETZUNG: "Please enter the english translation",
    BITTE_ENTLADEZEIT_EINGEBEN: "Please enter time of discharge",
    BITTE_EUROKLASSE_EINGEBEN: "Please enter the euroclass",
    BITTE_FAHRZEUGANZAHL_EINGEBEN: "Please enter the count of vehicle",
    BITTE_FRAKTION_WAEHLEN: "Please enter the fraction",
    BITTE_FRANZOESICHE_UEBERSETZUNG: "Please enter the french translation",
    BITTE_DEUTSCHER_UEBERSETZUNG: "Please enter the german translation",
    BITTE_GEBURTSORT_EINGEBEN: "Please enter your place of birth",
    BITTE_GEWICHT_EINGEBEN: "Please enter weight",
    BITTE_GEWICHTEINHEIT_WAEHLEN: "Please enter select unit",
    BITTE_KENNZEICHEN_EINGEBEN: "Please enter the mark",
    BITTE_LEISTUNGSVERZEICHNISPOSITIONSNUMMER_EINGEBEN:
        "Please enter the list of services postions number",
    BITTE_MAIL_EINGEBEN: "Please enter the mail",
    BITTE_MATERIAL_EINGEBEN: "Please enter material",
    BITTE_MATERIALEINHEIT_WAEHLEN: "Please enter select transport unit",
    BITTE_MENGE_EINGEBEN: "Please enter quantity",
    BITTE_NACHNAME_EINGEBEN: "Please enter the lastname",
    BITTE_NAME_EINGEBEN: "Please enter the name",
    BITTE_POLNISCHE_UEBERSETZUNG: "Please enter the polish translation",
    BITTE_POSITIONSNUMMER_EINGEBEN: "Please enter the positon number",
    BITTE_PROJEKTBETILIGTER_AUSWAEHLEN: "Please enter the project participants",
    BITTE_RESSOURCE_AUSWAEHLEN: "Please enter resource",
    BITTE_RUSSISCHE_UEBERSETZUNG: "Please enter the russian translation",
    BITTE_SPANISCHE_UEBERSETZUNG: "Please enter the spanish translation",
    BITTE_STARTORT_EINGEBEN: "Please enter your starting loaction",
    BITTE_TAG_AUSWAEHLEN: "Plese choose your day",
    BITTE_TELEFONNUMMER_EINGEBEN: "Please enter your phonenummber",
    BITTE_TUERKISCHER_UEBERSETZUNG: "Please enter turkish translation",
    BITTE_UNTERNEHMEN_EINGEBEN: "Please enter your organisation",
    BITTE_VORNAME_EINGEBEN: "Please enter your first name",
    BITTE_VOR_UND_NACHNAME_EINGEBEN: "Please enter your first and last name",
    BITTE_WIEDERHOLUNGEN_EINGEBEN: "Please enter repeats",
    BITTE_ZEITABSCHNITT_WAEHLEN: "Please select time period",
    BITTE_ZIELORT_WAEHLEN: "Please choose your destination",
    CO2AEQUVIALENT: "Co2Aequvialent",
    DATEN_IN_KUERZE: "Data available soon",
    DEINE_NAECHSTEN_TRANSPORTE: "Your next transports",
    DETAILS: "Details",
    DETAILS_ZUR_FRAKTION: "Details of fraction",
    DIENSTAG: "Thursday",
    DONNERTAG: "Thuesday",
    EINHEIT: "Unit",
    EINSTELLUNGEN: "Settings",
    EINZEL: "Single",
    EMPFAENGER: "Receiver",
    EMPFAENGER_DATEN: "Data of receiver",
    ENDZEIT: "Time of end",
    ENGLISCH: "English",
    ENTLADE_DATEN: "Data of discharge",
    ENTLADUNG: "Discharge",
    ENTLADEDAUER: "Discharge duration (in minutes)",
    ENTLADEZEIT: "Discharge time",
    ENTSORGUNGSART: "Disposal type",
    EUROKLASSE: "Euroclass",
    EXPORT_TOAST:
        "The download has started and is continuing in the background while you are working in ALGO. Please do not close ALGO in the meantime",
    FAHRZEUGANZAHL: "Count of vehicle",
    FCFS_VERFAHREN: "FCFS-procedure",
    FCFS_HELPER_TEXT_1:
        "If you want to allow only one transport per time slot, please select  „Yes“ here.",
    FEHLENDE_RECHTE: "Missing permissions",
    FORMULAR_NICHT_VALIDE: "Form is invalid",
    FRACHT: "Freight",
    FRACHT_DATEN: "Data of Freight",
    FRAKTION: "Fraction",
    FRANZOESICH: "French",
    FREITAG: "Friday",
    FUELLE_FELD_AUS: "Fill this field",
    GEBURTSORT: "Place of birth",
    GEBURTSDATUM: "Date of birth",
    GENEHMIGEN: "Approve",
    GESPERRT: "Locked",
    GEWICHT: "Weight of transport",
    GEWICHTEINHEIT: "Unit of weight",
    HEUTIGE_TAGESDISPOSTIONEN: "Today's daily arrangements",
    HOCHGELADEN_AM: "Uploaded on",
    HOCHGELADENE_UNTERLAGE: "Uploaded document",
    ID_NICHT_GEFUNDEN: "Id was couldn't found",
    INFORMATIONEN: "Informations",
    JA: "Yes",
    KEIN_KOMMENTAR: "No comment was given",
    KEINE_BESTIMMUNGSORT_ZUGEWIESEN:
        "Select a resource to add it to the resource list.",
    KEINE_EMAIL_WHITELIST: "No email has been added to the whitelist yet.",
    KEINE_TRANSPORTE_ANGEMELDET: "No registered transports",
    KEINE_UNTERLAGEN: "No documents",
    KEIN_VERANTWORTLICHE: "No responsible person available",
    KENNZEICHEN: "Mark",
    KFZ_KENNZEICHEN: "Vehicle licence plate",
    KOMMENTAR: "Comment (non-binding notice)",
    KOMMENTAR_EINGABEFELD: "You can enter a comment here (non-binding notice)",
    KONTAKTDATEN: "Contact details of the responsible person",
    LEISTUNGSVERZEICHNISPOSITIONSNUMMER: "List of services position number",
    LFD_NR: "serialNo.",
    LIEFERORT: "Place of deliviery",
    LIEFERTEMIN_LIEGT_AUSSER_ANLIEFERUNGSZEITEN:
        "Time of Delivery ",
    LIEFERTERMIN: "Date of delivery",
    LIEFERZEITEN: "End of delivery",
    LIEFERTEMIN_LIEGT_AUSSER_OEFFNUNGSZEITEN:
        "Delivery date & unloading time must be within the delivery/delivery times. The date and unloading time must be within the delivery/delivery time.",
    MAIL: "Mail",
    MATERIAL: "Material",
    MATERIALEINHEIT: "Unit of transport",
    MENGE: "Quantity",
    MODULANGABEN: "Module information",
    MODULANGABEN_ERFOLGREICH_AKTUALISIERT:
        "Module details successfully updated",
    MODULUNTERLAGE_ERFOLGREICH_AKTUALISIERT:
        "Module document was successfully updated",
    MODULUNTERLAGE_ERFOLGREICH_ARCHIVIERT:
        "Module document was successfully delete",
    MODULUNTERLAGE_ERFOLGREICH_ERSTELLT:
        "Module document was successfully created",
    MODULANGABEN_SPEICHERN: "Save module details",
    MODULUNTERLAGE_AENDERN: "Edit of module document",
    MODULUNTERLAGE_AKTUALISIEREN: "Update module document",
    MODULUNTERLAGE_ANZEIGEN: "Show module document",
    MODULUNTERLAGE_ARCHIVIEREN: "Archive module document",
    MODULUNTERLAGE_HINZUFUEGEN: "Add module document",
    MODULUNTERLAGEN: "Module documents",
    MODULVERANTWORTLICHER_MAIL: "Mail of the responsible person",
    MODULVERANTWORTLICHER_MAIL_EINGABE:
        "Please enter mail of responsible person",
    MODULVERANTWORTLICHER_TELEFONNUMMER:
        "Phone number of the responsible person",
    MODULVERANTWORTLICHER_TELEFONNUMMER_EINGABE:
        "Please enter the telephone number of the person responsible",
    MONAT: "Monat",
    MONTAG: "Monday",
    MITTWOCH: "Wednesday",
    NACHNAME: "Lastname",
    NAME: "Name",
    NAME_DER_RESSOURCE: "Resource name",
    NEIN: "No",
    PDF_DATEI_ANZEIGEN: "Show document as PDF file",
    POLNISCH: "Polish",
    POSITIONSNUMMER: "Position number",
    PREIS: "Preis",
    RESSOURCE: "Resource",
    RESSOURCEN: "Resources",
    RESSOURCENAUSWAHL: "Ressource selection",
    RESSOURCEN_AENDERN: "Edit resource",
    RESSOURCEN_AKTUALISIEREN: "Update resource",
    RESSOURCEN_ARCHIVIEREN: "Archive resource",
    RESSOURCEN_ERFOLGREICH_AKTUALISIERT: "Resource was successfully updated",
    RESSOURCEN_ERFOLGREICH_ARCHIVIERT: "Resource was successfully delete",
    RESSOURCEN_ERFOLGREICH_ERSTELLT: "Resource was successfully created",
    RESSOURCEN_ID_NICHT_GEFUNDEN: "Ressource-Id not found",
    RESSOURCEN_HINZUFUEGEN: "Add resource",
    RUSSISCH: "Russian",
    SAMMEL: "Collective",
    SAMSTAG: "Saturday",
    SERIE: "Serial",
    SONNTAG: "Sonday",
    SPANISCH: "Spanish",
    SPEZIAL: "Special",
    STARTORT: "Starting location",
    TAG: "Tag",
    TAG_0: "Sunday",
    TAG_1: "Monday",
    TAG_2: "Thuesday",
    TAG_3: "Wednesdey",
    TAG_4: "Thursday",
    TAG_5: "Friday",
    TAG_6: "Saturday",
    STARTZEIT: "Time of start",
    STORNIEREN: "Cancel",
    TAGABSCHLIESSEN: "Close the day",
    TAGABSCHLIESSEN_SICHERHEITSABFRAGE: "Do you really want to end the day",
    TAGABSCHLIESSEN_TITLE: "Close the day",
    TAG_BEREITS_ABGESCHLOSSEN: "Day already completed",
    TAG_ERFOLGREICH_ABGESCHLOSSEN: "Day was successfully closed",
    TELEFONNUMMER: "Phonenumber",
    TELEFONNUMMER: "Telefonnummer",
    TRACKING_HELPER_TEXT_1:
        "Select “Yes” if you want to activate tracking of a transport.",
    TRANSPORT: "Transport",
    TRANSPORTTYP: "Transport type",
    TRANSPORT_ABTRANSPORT_LINK:
        "Collection from {{transportFirma}} for {{empfaengerFirma}} - status {{status}}",
    TRANSPORT_ANLIEFERUNG_LINK:
        "{{transportFirma}} delivers {{material} to {{empfaengerFirma}} - status {{status}}",
    TRANSPORT_ANMELDEN: "Registration transport",
    TRANSPORT_ANMELDEN_FRISTENDE: "Transport registration deadline",
    TRANSPORT_ANMELDEN_FRISTENDE_EINGABE:
        "Please enter the deadline of the Transport registration",
    TRANSPORT_ANMELDEN_FRISTENDE_HELPER_TEXT_1:
        "Please specify the deadline for registering the transport by indicating the number of days.",
    TRANSPORT_ANMELDEN_FRISTENDE_HELPER_TEXT_2:
        "Example: If you enter „2“, the transport registration will be blocked 2 days in advance.",
    TRANSPORTE: "Transport",
    TRANSPORT_ANZEIGEN: "Show transport",
    TRANSPORT_DATEN: "Data of transport",
    TRANSPORT_ERFOLGREICH_ANGEMELDET: "Transport was successfully registered",
    TRANSPORT_ERFOLGREICH_GENEHMIGT: "Transport was successfully approved",
    TRANSPORT_ERFOLGREICH_STORNIERT: "Transport was succesfully canceled",
    TRANSPORT_GENEHMEN: "Approve transportation",
    TRANSPORT_UEBERSICHT: "Overview of transport",
    TRANSPORT_TRACKING: "Transport-Tracking",
    TRANSPORTEMAIL: "Mail",
    TRANSPORTUNTERNEHMEN: "Company",
    TRANSPORTTELEFONNUMMER: "Phonenumber",
    TRANSPORT_STORNIEREN: "Cancel transport",
    TRANSPORTSTARTORT: "Place of start",
    TRANSPORTZIELORT: "Place of target",
    TRANSPORTEUR: "Carrier",
    TRANSPORTTYP: "Transport type",
    TUERKISCH: "Turkish",
    TYP: "Type",
    UNTERNEHMEN: "Organisation",
    UNTERLAGE: "Document",
    UNTERLAGEN: "Documents",
    UNTERLAGEN_ID_NICHT_GEFUNDEN: "Not found document id",
    VORNAME: "Firstname",
    VORNACHNAME: "First and surname",
    VOR_UND_NACHNAME: "First and surname",
    VORSCHAU: "Preview",
    WEITER: "Next",
    WHITELIST: "Whitelist",
    WIEDERHOLUNGEN: "Repetitions",
    WOCHE: "Woche",
    ZEITABSCHNITT: "Time period",
    ZIELORT: "Destination",
    ZIELORT_AKTUALISIEREN: "Update destination",
    ZIELORT_AENDERN: "Edit destination",
    ZIELORT_ARCHIVIEREN: "Archive destination",
    ZIELORT_ERFOLGREICH_AKTUALISIERT: "Destination was updated successfully",
    ZIELORT_ERFOLGREICH_ARCHIVIERT: "Destination was delete successfully",
    ZIELORT_ERFOLGREICH_ERSTELLT: "Destination was created successfully",
    ZIELORT_HINZUFUEGEN: "Add destination",
    ZIELORT_ID_NICHT_GEFUNDEN: "Destination-Id not found",
    ZU_DEN_TRANSPORTEN: "To the overview of transports",
    ZURUECK: "Return",
    ZUSAMMENFASSUNG_TRANSPORT_ANMELDUNG:
        "Summary of the data you entered for the transport registration",
};
