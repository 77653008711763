import {LitElement} from 'lit';
import { html } from "lit/html.js"

import { SbAdmin2Styles } from '../../css/sb-admin-2-styles.js';
import { FontawesomeIcons } from '../../css/fontawesome-icons.js';

class BsDropdown extends LitElement {
    static get styles() {
        return [
            SbAdmin2Styles,
            FontawesomeIcons
        ];
    }

    static get properties() {
        return {
            _dropdownButtonClick: Boolean
        };
    }

    render() {
        return html`
            <div class="dropdown">
                <slot name="dropdown-button"></slot>
                <slot name="dropdown-menu"></slot>
            </div>
        `;
    }

    constructor() {
        super();
        this._dropdownButtonClick = false;
    }

    firstUpdated() {
        const dropdownButton = this.querySelector('[slot="dropdown-button"]'),
            dropdownMenu = this.querySelector('[slot="dropdown-menu"]'),
            dropdown = this.shadowRoot.querySelector('.dropdown'),
            _this = this;

        dropdownButton.addEventListener('click', () => {
            _this._dropdownButtonClick = true;
            dropdownMenu.classList.toggle('show');
            dropdown.classList.toggle('show');
        });

        document.addEventListener('click', () => {
            if (!_this._dropdownButtonClick) {
                dropdownMenu.classList.remove('show');
                dropdown.classList.remove('show');
            }
            _this._dropdownButtonClick = false;
        });
    }

}

window.customElements.define('bs-dropdown', BsDropdown);