import {
    FETCH_BAUSTELLE_RESET_SUCCESS,
    FETCH_BAUSTELLENAUSWEIS_EINSTELLUNGEN_SUCCESS,
    FETCH_BAUSTELLENAUSWEIS_EINSTELLUNGEN_ACTION_FAIL,
    FETCH_BAUSTELLENAUSWEIS_EINSTELLUNGEN_ACTION_BEGIN,
    FETCH_BAUSTELLE_GESPERRTES_PERSONAL_ACTION_BEGIN,
    FETCH_BAUSTELLE_GESPERRTES_PERSONAL_ACTION_FAIL,
    FETCH_BAUSTELLE_GESPERRTES_PERSONAL_SUCCESS,
    FETCH_BAUSTELLE_ABGELAUFENE_BAUSTELLENAUSWEISE_ACTION_BEGIN,
    FETCH_BAUSTELLE_ABGELAUFENE_BAUSTELLENAUSWEISE_ACTION_FAIL,
    FETCH_BAUSTELLE_ABGELAUFENE_BAUSTELLENAUSWEISE_ACTION_SUCCESS,
    VERLAENGERE_BAUSTELLENAUSWEIS_ACTION_BEGIN,
    VERLAENGERE_BAUSTELLENAUSWEIS_ACTION_FAIL,
    VERLAENGERE_BAUSTELLENAUSWEIS_ACTION_SUCCESS,
    FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_SUCHEN_ACTION_BEGIN,
    FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_SUCHEN_ACTION_FAIL,
    FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_SUCHEN_SUCCESS,
    FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_GESAMT_ACTION_BEGIN,
    FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_GESAMT_ACTION_FAIL,
    FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_GESAMT_SUCCESS,
    FETCH_BAUSTELLE_ZUTRITTSPUNKTE_ACTION_BEGIN,
    FETCH_BAUSTELLE_ZUTRITTSPUNKTE_ACTION_FAIL,
    FETCH_BAUSTELLE_ZUTRITTSPUNKTE_SUCCESS,
    FETCH_BAUSTELLE_ZEITZONEN_ACTION_BEGIN,
    FETCH_BAUSTELLE_ZEITZONEN_ACTION_FAIL,
    FETCH_BAUSTELLE_ZEITZONEN_SUCCESS,
    FETCH_BAUSTELLE_AKTUELLE_BESETZUNG_ACTION_BEGIN,
    FETCH_BAUSTELLE_AKTUELLE_BESETZUNG_ACTION_FAIL,
    FETCH_BAUSTELLE_AKTUELLE_BESETZUNG_SUCCESS,
    FETCH_BAUSTELLE_PERSONAL_AUSWERTUNGEN_ACTION_BEGIN,
    FETCH_BAUSTELLE_PERSONAL_AUSWERTUNGEN_ACTION_FAIL,
    FETCH_BAUSTELLE_PERSONAL_AUSWERTUNGEN_SUCCESS,
    FETCH_BAUSTELLE_FIRMEN_AUSWERTUNGEN_ACTION_BEGIN,
    FETCH_BAUSTELLE_FIRMEN_AUSWERTUNGEN_ACTION_FAIL,
    FETCH_BAUSTELLE_FIRMEN_AUSWERTUNGEN_SUCCESS,
    FETCH_BAUSTELLE_BERECHTIGUNGSGRUPPEN_ACTION_BEGIN,
    FETCH_BAUSTELLE_BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
    FETCH_BAUSTELLE_BERECHTIGUNGSGRUPPEN_SUCCESS,
    FETCH_BAUSTELLE_BILDVERIFIZIERUNG_ACTION_BEGIN,
    FETCH_BAUSTELLE_BILDVERIFIZIERUNG_ACTION_FAIL,
    FETCH_BAUSTELLE_BILDVERIFIZIERUNG_SUCCESS
} from "../../actions/zuko/baustelle";

const baustelleState = {
    error: null,
    baustellenausweisEinstellungenIstLoading: false,
    baustellenausweisEinstellungen: {},
    gesperrtesPersonalIstLoading: false,
    gesperrtesPersonal: {},
    abgelaufeneBaustellenausweise: {},
    abgelaufeneBaustellenausweiseIstLoading: false,
    baustellenausweiseSuchenIstLoading: false,
    baustellenausweiseSuchen: {},
    baustellenausweiseGesamtIstLoading: false,
    baustellenausweiseGesamt: {},
    zutrittspunkteIstLoading: false,
    zutrittspunkte: {},
    zeitzonenIstLoading: false,
    zeitzonen: {},
    aktuelleBesetzungIstLoading: false,
    aktuelleBesetzung: {},
    personalAuswertungenIstLoading: false,
    personalAuswertungen: {},
    firmenAuswertungenIstLoading: false,
    firmenAuswertungen: {},
    berechtigungsgruppenIstLoading: false,
    berechtigungsgruppen: {},
};

const savedState =
    localStorage.getItem("savedState") || JSON.stringify({ ...baustelleState });
const INITIAL_STATE = JSON.parse(savedState).baustelleState || baustelleState;

const baustelle = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_BAUSTELLE_RESET_SUCCESS:
            return baustelleState;
        case FETCH_BAUSTELLENAUSWEIS_EINSTELLUNGEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                baustellenausweisEinstellungenIstLoading: true,
            };
        case FETCH_BAUSTELLENAUSWEIS_EINSTELLUNGEN_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                baustellenausweisEinstellungenIstLoading: false,
                baustellenausweisEinstellungen: {},
            };
        case FETCH_BAUSTELLENAUSWEIS_EINSTELLUNGEN_SUCCESS:
            return {
                ...state,
                error: null,
                baustellenausweisEinstellungenIstLoading: false,
                baustellenausweisEinstellungen: action.payload,
            };
        case FETCH_BAUSTELLE_GESPERRTES_PERSONAL_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                gesperrtesPersonalIstLoading: true,
            };
        case FETCH_BAUSTELLE_GESPERRTES_PERSONAL_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                gesperrtesPersonalIstLoading: false,
                gesperrtesPersonal: {},
            };
        case FETCH_BAUSTELLE_GESPERRTES_PERSONAL_SUCCESS:
            return {
                ...state,
                error: null,
                gesperrtesPersonalIstLoading: false,
                gesperrtesPersonal: action.payload,
            };
        case FETCH_BAUSTELLE_ABGELAUFENE_BAUSTELLENAUSWEISE_ACTION_BEGIN:
        case VERLAENGERE_BAUSTELLENAUSWEIS_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                abgelaufeneBaustellenausweiseIstLoading: true,
            };
        case FETCH_BAUSTELLE_ABGELAUFENE_BAUSTELLENAUSWEISE_ACTION_FAIL:
        case VERLAENGERE_BAUSTELLENAUSWEIS_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                abgelaufeneBaustellenausweiseIstLoading: false,
                abgelaufeneBaustellenausweise: {},
            };
        case FETCH_BAUSTELLE_ABGELAUFENE_BAUSTELLENAUSWEISE_ACTION_SUCCESS:
        case VERLAENGERE_BAUSTELLENAUSWEIS_ACTION_SUCCESS:
            return {
                ...state,
                error: null,
                abgelaufeneBaustellenausweiseIstLoading: false,
                abgelaufeneBaustellenausweise: action.payload,
            };
        case FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_SUCHEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                baustellenausweiseSuchenIstLoading: true,
            };
        case FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_SUCHEN_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                baustellenausweiseSuchenIstLoading: false,
                baustellenausweiseSuchen: {},
            };
        case FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_SUCHEN_SUCCESS:
            return {
                ...state,
                error: null,
                baustellenausweiseSuchenIstLoading: false,
                baustellenausweiseSuchen: action.payload,
            };
        case FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_GESAMT_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                baustellenausweiseGesamtIstLoading: true,
                baustellenausweiseGesamt: {},
            };
        case FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_GESAMT_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                baustellenausweiseGesamtIstLoading: false,
                baustellenausweiseGesamt: {},
            };
        case FETCH_BAUSTELLE_BAUSTELLENAUSWEISE_GESAMT_SUCCESS:
            return {
                ...state,
                error: null,
                baustellenausweiseGesamtIstLoading: false,
                baustellenausweiseGesamt: action.payload,
            };
        case FETCH_BAUSTELLE_ZUTRITTSPUNKTE_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                zutrittspunkteIstLoading: true,
                zutrittspunkte: {},
            };
        case FETCH_BAUSTELLE_ZUTRITTSPUNKTE_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                zutrittspunkteIstLoading: false,
                zutrittspunkte: {},
            };
        case FETCH_BAUSTELLE_ZUTRITTSPUNKTE_SUCCESS:
            return {
                ...state,
                error: null,
                zutrittspunkteIstLoading: false,
                zutrittspunkte: action.payload,
            };
        case FETCH_BAUSTELLE_ZEITZONEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                zeitzonenIstLoading: true,
                zeitzonen: {},
            };
        case FETCH_BAUSTELLE_ZEITZONEN_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                zeitzonenIstLoading: false,
                zeitzonen: {},
            };
        case FETCH_BAUSTELLE_ZEITZONEN_SUCCESS:
            return {
                ...state,
                error: null,
                zeitzonenIstLoading: false,
                zeitzonen: action.payload,
            };
        case FETCH_BAUSTELLE_AKTUELLE_BESETZUNG_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                aktuelleBesetzungIstLoading: true,
                aktuelleBesetzung: {},
            };
        case FETCH_BAUSTELLE_AKTUELLE_BESETZUNG_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                aktuelleBesetzungIstLoading: false,
                aktuelleBesetzung: {},
            };
        case FETCH_BAUSTELLE_AKTUELLE_BESETZUNG_SUCCESS:
            return {
                ...state,
                error: null,
                aktuelleBesetzungIstLoading: false,
                aktuelleBesetzung: action.payload,
            };
        case FETCH_BAUSTELLE_PERSONAL_AUSWERTUNGEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                personalAuswertungenIstLoading: true,
                personalAuswertungen: {},
            };
        case FETCH_BAUSTELLE_PERSONAL_AUSWERTUNGEN_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                personalAuswertungenIstLoading: false,
                personalAuswertungen: {},
            };
        case FETCH_BAUSTELLE_PERSONAL_AUSWERTUNGEN_SUCCESS:
            return {
                ...state,
                error: null,
                personalAuswertungenIstLoading: false,
                personalAuswertungen: {},
            };
        case FETCH_BAUSTELLE_FIRMEN_AUSWERTUNGEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                firmenAuswertungenIstLoading: true,
                firmenAuswertungen: {},
            };
        case FETCH_BAUSTELLE_FIRMEN_AUSWERTUNGEN_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                firmenAuswertungenIstLoading: false,
                firmenAuswertungen: {},
            };
        case FETCH_BAUSTELLE_FIRMEN_AUSWERTUNGEN_SUCCESS:
            return {
                ...state,
                error: null,
                firmenAuswertungenIstLoading: false,
                firmenAuswertungen: action.payload,
            };
        case FETCH_BAUSTELLE_BERECHTIGUNGSGRUPPEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                berechtigungsgruppenIstLoading: true,
                berechtigungsgruppen: {},
            };
        case FETCH_BAUSTELLE_BERECHTIGUNGSGRUPPEN_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                berechtigungsgruppenIstLoading: false,
                berechtigungsgruppen: {},
            };
        case FETCH_BAUSTELLE_BERECHTIGUNGSGRUPPEN_SUCCESS:
            return {
                ...state,
                error: null,
                berechtigungsgruppenIstLoading: false,
                berechtigungsgruppen: action.payload,
            };
        case FETCH_BAUSTELLE_BILDVERIFIZIERUNG_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                bildverifizierungIstLoading: true,
                bildverifizierung: {},
            };
        case FETCH_BAUSTELLE_BILDVERIFIZIERUNG_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                bildverifizierungIstLoading: false,
                bildverifizierung: {},
            };
        case FETCH_BAUSTELLE_BILDVERIFIZIERUNG_SUCCESS:
            return {
                ...state,
                error: null,
                bildverifizierungIstLoading: false,
                bildverifizierung: action.payload,
            };
        default:
            return state;
    }
};
export default baustelle;
