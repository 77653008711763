import { LitElement, css } from "lit";
import { html } from "lit/html.js";

import { AlgoStyles } from "../css/algo-styles.js";
import { SbAdmin2Styles } from "../css/sb-admin-2-styles.js";
import { FontawesomeIcons } from "../css/fontawesome-icons.js";
import { DataTablesBootstrap4Style } from "./data-tables-bootstrap4-style.js";

class DataTable extends LitElement {
    static get styles() {
        return [
            DataTablesBootstrap4Style,
            SbAdmin2Styles,
            FontawesomeIcons,
            AlgoStyles,
            css`
                :root {
                    color: initial;
                }

                td.cursor-pointer {
                    cursor: pointer;
                }

                th {
                    text-align: left;
                }

                td.details-control::before {
                    content: "\\f107";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    font-size: 1.5rem !important;
                }

                td.details-control.open::before {
                    content: "\\f106";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    font-size: 1.5rem !important;
                }
                .details-control {
                    text-align: center !important;
                }
            `,
        ];
    }

    static get properties() {
        return {
            _table: { type: Object },
            label: { type: Object },
            options: { type: Object },
            dataTableId: { type: String },
            collapsedGroups: { type: Object },
        };
    }

    render() {
        return html`
            <div
                class="table-responsive"
                style="margin-bottom:10px !important;"
            >
                ${this.label
                ? html`<label
                          class="float-right"
                          data-toggle="tooltip"
                          title="${this.label.tooltip}"
                          >${this.label.text}</label
                      >`
                : html``}

                <table
                    class="table display nowrap"
                    id="${this.dataTableId ? this.dataTableId : "table"}"
                    style="width: 98%"
                    cellspacing="0"
                ></table>
            </div>
        `;
    }

    constructor() {
        super();
        this._table = null;
        this.options = null;
        this.label = "";
        this.dataTableId = "";
        this.collapsedGroups = {};
    }

    updated(changedProperties) {
        const _this = this;
        changedProperties.forEach((oldValue, propName) => {
            if (propName === "options") {
                if (this.options) {
                    var table = this.dataTableId
                        ? this.shadowRoot.querySelector("#" + this.dataTableId)
                        : this.shadowRoot.querySelector("#table");
                    let language = localStorage.getItem("language");
                    if (!language)
                        language =
                            navigator?.language || navigator?.userLanguage;
                    var lang = language?.split("-")[0];
                    this.options.language = {
                        url: "/locales/" + lang + "/datatable.json",
                    };

                    if (this.options.group) {
                        this.options.drawCallback = function (settings) {
                            var api = this.api();
                            var rows = api.rows({ page: "current" }).nodes();
                            var last = null;
                            api.column(_this.options.group.column, {
                                page: "current",
                            })
                                .data()
                                ?.each(function (group, i) {
                                    if (last !== group) {
                                        $(rows)
                                            .eq(i)
                                            .before(
                                                '<tr class="group"><td colspan=' +
                                                _this.options.columns
                                                    .length +
                                                ">" +
                                                group +
                                                "</td></tr>"
                                            );
                                        last = group;
                                    }
                                });
                        };
                    }
                    if (this.options.subHeader) {
                        this.options.headerCallback = function (
                            row,
                            data,
                            start,
                            end,
                            display
                        ) {
                            let api = this.api();
                            let subHeader = "<tr class='subHeader'>";
                            _this.options?.columns?.forEach((column, index) => {
                                try {
                                    if (api?.column(index)?.visible()) {
                                        if (column.subtitle)
                                            subHeader +=
                                                "<th>" +
                                                column.subtitle +
                                                "</th>";
                                    }
                                } catch (error) { }
                            });
                            subHeader += "</tr>";

                            if (row?.parentElement) {
                                let subHeaderElement =
                                    _this.shadowRoot.querySelectorAll(
                                        ".subHeader"
                                    );
                                if (
                                    subHeaderElement === null ||
                                    subHeaderElement === undefined
                                ) {
                                    $(row.parentElement).append(subHeader);
                                } else {
                                    _this.shadowRoot.querySelectorAll(".subHeader")?.forEach(x => x.remove());
                                    $(row.parentElement)?.append(subHeader);
                                }
                            }
                        };
                        _this._table?.columns?.adjust()?.draw(false);
                    }

                    if (this.options.footerConfig) {
                        this.options.footerCallback = function (
                            row,
                            data,
                            start,
                            end,
                            display
                        ) {
                            let column = "",
                                footerCount = 0,
                                api = this.api(),
                                visible,
                                colspan = 0;
                            _this.options?.columns?.forEach((x, index) => {
                                if (
                                    _this.options.footerConfig.columns.length >
                                    0 &&
                                    index <
                                    _this.options.footerConfig.columns[0] &&
                                    api.column(index).visible()
                                ) {
                                    colspan++;
                                }
                                if (
                                    _this.options.footerConfig?.columns?.findIndex(
                                        (y) => y === index
                                    ) > -1 &&
                                    api.column(index)?.visible()
                                ) {
                                    var hochgeladenCount = 0,
                                        geprueftCount = 0,
                                        sollCount = 0,
                                        abgelaufenCount = 0,
                                        istAbgelaufenDeaktiviert = false;
                                    api.column(index, { page: "current" })
                                        .nodes()
                                        ?.each(function (node) {
                                            if (
                                                node?.children[0]?.getAttribute(
                                                    "data-istabgelaufen-deaktiviert"
                                                ) === "true"
                                            ) {
                                                istAbgelaufenDeaktiviert = true;
                                            }
                                            if (
                                                node?.children[0]?.getAttribute(
                                                    "data-keine-unterlage"
                                                ) === "true"
                                            ) {
                                                return;
                                            }
                                            if (
                                                node?.children[0]?.getAttribute(
                                                    "data-isthochgeladen"
                                                ) === "true"
                                            ) {
                                                hochgeladenCount++;
                                            }
                                            if (
                                                node?.children[0]?.getAttribute(
                                                    "data-istgeprueft"
                                                ) === "true"
                                            ) {
                                                geprueftCount++;
                                            }
                                            if (
                                                node?.children[0]?.getAttribute(
                                                    "data-istabgelaufen"
                                                ) === "true"
                                            ) {
                                                abgelaufenCount++;
                                            }
                                            sollCount++;
                                        });
                                    footerCount += 1;
                                    if (istAbgelaufenDeaktiviert) {
                                        column +=
                                            "<th class=text-right><span title=" +
                                            _this.options.footerConfig?.title +
                                            ">" +
                                            geprueftCount +
                                            " / " +
                                            hochgeladenCount +
                                            " / " +
                                            sollCount +
                                            "</span></th>";
                                    } else {
                                        column +=
                                            "<th class=text-right><span title=" +
                                            _this.options.footerConfig?.title +
                                            ">" +
                                            geprueftCount +
                                            " / " +
                                            hochgeladenCount +
                                            " / " +
                                            sollCount +
                                            " / " +
                                            abgelaufenCount +
                                            "</span></th>";
                                    }
                                } else if (
                                    _this.options.footerConfig?.columns?.findIndex(
                                        (y) => y === index
                                    ) === -1 &&
                                    api.column(index).visible() &&
                                    index >
                                    _this.options.footerConfig?.columns[
                                    _this.options.footerConfig?.columns
                                        ?.length - 1
                                    ]
                                ) {
                                    column += "<th></th>";
                                } else {
                                    column += "";
                                }
                            });

                            let footer =
                                colspan > 0
                                    ? "<th title=" +
                                    _this.options.footerConfig?.title +
                                    " colspan=" +
                                    colspan +
                                    ">" +
                                    _this.options.footerConfig?.title +
                                    "</th>" +
                                    column
                                    : column;

                            if (row.children.length === 0) {
                                $(row).append(row, footer);
                            } else {
                                $(row.children).remove();
                                $(row).append(row, footer);
                            }
                        };

                        $(table).append(
                            "<tfoot><tr class='" +
                            this.dataTableId +
                            "_footer'></tr></tfoot>"
                        );
                    }
                    if (!oldValue) {
                        this._table = $(table).DataTable(this.options);
                    } else {
                        this._table.draw();
                    }

                    table.addEventListener("click", (event) => {
                        let td = null,
                            tr = null;
                        if (event.originalTarget) {
                            // firefox
                            td = event.originalTarget;
                            tr = event.originalTarget.parentElement;
                        } else if (event.path) {
                            // chrome
                            td = event.path.find((x) => x.nodeName === "TD");
                            tr = event.path.find((x) => x.nodeName === "TR");
                        } else if (event.srcElement) {
                            // edge
                            td = event.srcElement;
                            tr = event.srcElement.parentElement;
                        } else {
                            console.error("table.addEventListener");
                        }
                        if (td && td.classList.contains("clickable")) {
                            let data = this._table.row(tr).data();
                            if (data) {
                                let event = new CustomEvent("td-click", {
                                    detail: { data: data },
                                });
                                _this.dispatchEvent(event);
                            } else {
                                console.warn("data");
                            }
                        } else if (
                            td &&
                            td.classList.contains("details-control")
                        ) {
                            var row = this._table.row(tr);
                            if (row.data()) {
                                if (row.child.isShown()) {
                                    // This row is already open - close it
                                    row.child.hide();
                                    event?.target?.classList.toggle("open");
                                } else {
                                    // Open this row
                                    let evt = new CustomEvent(
                                        "td-details-control",
                                        {
                                            detail: { row: row },
                                        }
                                    );
                                    this.dispatchEvent(evt);
                                    row.child().show();
                                    event?.target?.classList.toggle("open");
                                }
                            }
                        }
                    });
                }
            }
        });

        this._table?.on("draw", (event) => {
            const customEvent = new CustomEvent(event.type, {
                bubbles: true,
                composed: true,
            });
            this.dispatchEvent(customEvent);
        });
        this._table?.on(
            "column-visibility.dt",
            function (e, settings, column, state) {
                _this._table.draw();
            }
        );
    }

    updateData(data) {
        const _this = this;
        let index = this._table?.page();
        if (this._table && data) {
            this._table.clear().draw();
            if (data.length > 0) {
                data.forEach(function (d) {
                    _this._table.row.add(d);
                });
                this._table.columns.adjust().draw(false);
                this._table.page(index)?.draw("page");
            }
        }
    }

    addRow(data) {
        if (this._table && data) {
            this._table.row.add(data);
            this._table.draw();
        }
    }

    destroy() {
        this._table?.destroy(true);
        if (
            this.shadowRoot.querySelector(".table-responsive")
                ?.childElementCount === 0
        ) {
            this.shadowRoot.querySelector(
                ".table-responsive"
            ).innerHTML = `<table
                        class="table"
                        id="${this.dataTableId ? this.dataTableId : "table"}"
                        style="overflow-x: auto;"
                        width="100%"
                        cellspacing="0"
                    ></table>`;
        }
    }

    clear() {
        this._table.clear().draw();
    }

    changeVisibility(index, isVisible) {
        this._table.columns(index).visible(isVisible);
    }

    colvis(e, dt, node, config) {
        this._table?.ext?.buttons?.collection?.action.call(
            this,
            e,
            dt,
            node,
            config
        );
    }

    reload(url) {
        if (this._table) this._table?.ajax?.url(url)?.load();
    }
}

window.customElements.define("data-table", DataTable);
