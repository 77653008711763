import {
    TAGESAUSWERTUNG_ACTION_FAIL,
    FETCH_TAGESAUSWERTUNG_RESET_SUCCESS,
    TAGESAUSWERTUNG_ANWESENHEIT_GESAMT_ACTION_BEGIN,
    FETCH_TAGESAUSWERTUNG_ANWESENHEIT_GESAMT_SUCCESS,
    TAGESAUSWERTUNG_ANWESENHEIT_FIRMEN_ACTION_BEGIN,
    FETCH_TAGESAUSWERTUNG_ANWESENHEIT_FIRMEN_SUCCESS,
    TAGESAUSWERTUNG_ANWESENHEIT_TAGESABLAUF_ACTION_BEGIN,
    FETCH_TAGESAUSWERTUNG_ANWESENHEIT_TAGESABLAUF_SUCCESS,
    TAGESAUSWERTUNG_SPERRGRUENDE_TAGESABLAUF_ACTION_BEGIN,
    FETCH_TAGESAUSWERTUNG_SPERRGRUENDE_TAGESABLAUF_SUCCESS,
    TAGESAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_ACTION_BEGIN,
    FETCH_TAGESAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_SUCCESS,
} from "../../actions/dashboard/tagesauswertungen.js";

const tagesAuswertungenState = {
    error: null,
    loading: false,
    tagesauswertung: {
        anwesenheitGesamtIstLoading: false,
        anzahlGesperrterMitarbeiterIstLoading: false,
        anwesenheitFirmenIstLoading: false,
        sperrgruendeIstLoading: false,
        tagesablaufIstLoading: false,
    },
};

const savedState =
    localStorage.getItem("savedState") ||
    JSON.stringify({ ...tagesAuswertungenState });
const INITIAL_STATE =
    JSON.parse(savedState).meineProjekteState || tagesAuswertungenState;

const tagesauswertung = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_TAGESAUSWERTUNG_RESET_SUCCESS:
            return tagesAuswertungenState;
        case TAGESAUSWERTUNG_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                anwesenheitGesamtIstLoading: false,
                anwesenheitGesamt: {},
                anzahlGesperrterMitarbeiterIstLoading: false,
                anzahlGesperrterMitarbeiter: {},
                anwesenheitFirmenIstLoading: false,
                anwesenheitFirmen: {},
                sperrgruendeIstLoading: false,
                sperrgruende: {},
                tagesablaufIstLoading: false,
                tagesablauf: {},
            };
        case TAGESAUSWERTUNG_ANWESENHEIT_GESAMT_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
                anwesenheitGesamtIstLoading: true,
            };
        case FETCH_TAGESAUSWERTUNG_ANWESENHEIT_GESAMT_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                anwesenheitGesamtIstLoading: false,
                anwesenheitGesamt: action.payload,
            };
        case TAGESAUSWERTUNG_ANWESENHEIT_FIRMEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
                anwesenheitFirmenIstLoading: true,
            };
        case FETCH_TAGESAUSWERTUNG_ANWESENHEIT_FIRMEN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                anwesenheitFirmenIstLoading: false,
                anwesenheitFirmen: action.payload,
            };
        case TAGESAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
                anzahlGesperrterMitarbeiterIstLoading: true,
            };
        case FETCH_TAGESAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                anzahlGesperrterMitarbeiterIstLoading: false,
                anzahlGesperrterMitarbeiter: action.payload,
            };
        case TAGESAUSWERTUNG_SPERRGRUENDE_TAGESABLAUF_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
                sperrgruendeIstLoading: true,
            };
        case FETCH_TAGESAUSWERTUNG_SPERRGRUENDE_TAGESABLAUF_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                sperrgruendeIstLoading: false,
                sperrgruende: action.payload,
            };
        case TAGESAUSWERTUNG_ANWESENHEIT_TAGESABLAUF_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
                tagesablaufIstLoading: true,
            };
        case FETCH_TAGESAUSWERTUNG_ANWESENHEIT_TAGESABLAUF_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                tagesablaufIstLoading: false,
                tagesablauf: action.payload,
            };
        default:
            return state;
    }
};

export default tagesauswertung;
