export const MEINE_PROJEKTE_ACTION_FAIL = "MEINE_PROJEKTE_ACTION_FAIL";
export const MEINE_PROJEKTE_ACTION_BEGIN = "MEINE_PROJEKTE_ACTION_BEGIN";
export const FETCH_MEINE_PROJEKTE_SUCCESS = "FETCH_MEINE_PROJEKTE_SUCCESS";
export const RESET_MEINE_PROJEKTE_SUCCESS = "RESET_MEINE_PROJEKTE_SUCCESS";
export const STIMME_DSGVO_ZU_BEGIN = "STIMME_DSGVO_ZU_BEGIN";
export const STIMME_DSGVO_ZU_FAIL = "STIMME_DSGVO_ZU_FAIL";
export const STIMME_DSGVO_ZU_SUCCESS = "STIMME_DSGVO_ZU_SUCCESS";

import errorHandler from "../errorHandling.js";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;

export const fetchMeineProjekte = () => async (dispatch) => {
    appInsights.startTrackEvent("fetchMeineProjekte");
    dispatch({ type: MEINE_PROJEKTE_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/projekt/v2/meine-projekte.json/`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: MEINE_PROJEKTE_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("fetchMeineProjekte", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: MEINE_PROJEKTE_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("fetchMeineProjekte", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({ type: FETCH_MEINE_PROJEKTE_SUCCESS, payload: json.payload });
        appInsights.stopTrackEvent("fetchMeineProjekte", {
            success: json.success,
        });
        return json.payload;
    }
};

export const stimmeDsgvoZu = (projektId, formData) => async (dispatch) => {
    appInsights.startTrackEvent("stimmeDsgvoZu");
    dispatch({ type: STIMME_DSGVO_ZU_BEGIN });
    const response = await fetch(`${apiHost}/api/projekt/${projektId}/dsgvo-zustimmen`, {
        method: "POST",
        body: formData
    });
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: STIMME_DSGVO_ZU_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("stimmeDsgvoZu", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: STIMME_DSGVO_ZU_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("stimmeDsgvoZu", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: STIMME_DSGVO_ZU_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("stimmeDsgvoZu", {
            success: json.success,
        });
        return json.payload;
    }
};

export const resetMeineProjekte = () => (dispatch) => {
    dispatch({ type: RESET_MEINE_PROJEKTE_SUCCESS });
    appInsights.trackEvent({ name: "resetMeineProjekte" });
};
