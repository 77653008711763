import {
    LOGIN_SUCCESS,
    ABMELDEN_SUCCESS,
    IDENTITY_ACTION_FAIL,
    REGISTRIEREN_SUCCESS,
    IDENTITY_ACTION_BEGIN,
    RESET_PASSWORD_SUCCESS,
    FETCH_BENUTZERPROFIL_SUCCESS,
    GENERATE_PASSWORD_RESET_TOKEN_SUCCESS
} from '../actions/identity';

const identityState = {
    error: null,
    loading: false,
    benutzerprofil: {},
};

const savedState = localStorage.getItem('savedState') || JSON.stringify({ ...identityState });
const INITIAL_STATE = JSON.parse(savedState).identityState || identityState;

const identity = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                benutzerprofil: action.payload
            };
        case REGISTRIEREN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false
            };
        case GENERATE_PASSWORD_RESET_TOKEN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                benutzerprofil: action.payload
            };
        case ABMELDEN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                benutzerprofil: action.payload
            };
        case IDENTITY_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case IDENTITY_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FETCH_BENUTZERPROFIL_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                benutzerprofil: action.payload
            };
        default:
            return state;
    }
};

export default identity;