import { css } from "lit";

export const DataTablesBootstrap4Style = css`
    table.dataTable {
        clear: both;
        margin-top: 10px !important;
        margin-bottom: 6px !important;
        max-width: none !important;
        width: 98% !important;
        margin-left: 16px !important;
    }
    thead > tr > th {
        text-align: center !important;
    }
    
    .table-responsive {
        margin-top: 10px !important;
    }

    .subHeader {
        box-shadow: none;
        border-radius: 0px !important;
        background-color: white !important;
        color: rgb(64, 64, 64);
    }

    .subHeader > th {
        background-color: white !important;
        border-radius: 0px !important;
        text-align: center !important;
    }

    .svg-inline--fa.fa-download.fa-w-16 {
        min-width: 2.5em !important;
        max-width: 2.5em !important;
    }

    .dtfh-floatingparent.dtfh-floatingparenthead {
        table.dataTable.fixedHeader-floating {
            thead > tr {
                background: linear-gradient(
                    60deg,
                    "#2e2e2d",
                    "#2e2e2d"
                ) !important;
                background-color: #2e2e2d !important;
                box-shadow: "#2e2e2d";
                -moz-box-shadow: "#2e2e2d";
                -webkit-box-shadow: "#2e2e2d";
                color: white;
                border-radius: 1.35rem;
                margin-top: -20px;
                border-color: "rgb(227, 240, 240) 10%";
            }
            .svg-inline--fa.fa-download.fa-w-16 {
                width: 2.5em;
                min-width: 2.5em !important;
                max-width: 2.5em !important;
            }
        }
    }

    .dtr-data {
        text-align: left !important;
    }

    .dtr-title {
        font-weight: bold;
        hyphens: none;
    }

    .dtr-data > br {
        display: none;
    }

    .dt-button-down-arrow {
        display: none;
    }

    .separate > br {
        content: "-<br>";
    }

    .subHeader-text {
        height: 20px !important;
        width: 50% !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    div.dataTables_scrollHead table.dataTable {
        top: 0px;
        width: 100% !important;
        display: block;
    }

    .dataTables_scrollBody > .table.dataTable > thead > tr {
        display: none;
    }

    .table-responsive {
        overflow-x: calc(100vw - 300px);
    }

    table.dataTable td,
    table.dataTable th {
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        // border-collapse: collapse;
    }

    .dataTables_length > label > .custom-select {
        border-top-left-radius: 1.35rem !important;
        border-bottom-left-radius: 1.35rem !important;
    }

    .dataTables_filter > label > .form-control {
        border-top-left-radius: 1.35rem !important;
        border-bottom-left-radius: 1.35rem !important;
    }

    th.dtr-control.dtr-hidden + th {
        border-top-left-radius: 1.35rem !important;
        border-bottom-left-radius: 1.35rem !important;
    }

    table.dataTable th:first-child {
        border-top-left-radius: 1.35rem !important;
        border-bottom-left-radius: 1.35rem !important;
    }

    table.dataTable th:last-child {
        border-top-right-radius: 1.35rem !important;
        border-bottom-right-radius: 1.35rem !important;
    }

    table.dataTable td.dataTables_empty,
    table.dataTable th.dataTables_empty {
        text-align: center;
    }

    table.dataTable.nowrap th,
    table.dataTable.nowrap td {
        white-space: nowrap;
    }

    table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
    table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
        height: 1em;
        width: 1em;
        display: block;
        color: white;
        margin: auto;
        border: 0.15em solid white;
        border-radius: 1em;
        box-shadow: 0 0 0.2em #444;
        box-sizing: content-box;
        text-align: center;
        text-indent: 0 !important;
        line-height: 1em;
        content: "+";
        background-color: #3ca085;
    }

    table.dataTable.dtr-inline.collapsed
        > tbody
        > tr.parent
        > td.dtr-control:before,
    table.dataTable.dtr-inline.collapsed
        > tbody
        > tr.parent
        > th.dtr-control:before {
        content: "-";
        background-color: #d34a4aff;
    }

    div.dataTables_wrapper div.dataTables_length label {
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        border-radius: 1.35rem;
        border-radius: 1.35rem;
    }

    div.dataTables_wrapper div.dataTables_length select {
        width: auto;
        display: inline-block;
        border-radius: 1.35rem;
    }

    div.dataTables_wrapper div.dataTables_filter {
        text-align: right;
    }

    div.dataTables_wrapper div.dataTables_filter label {
        font-weight: normal;
        white-space: nowrap;
        text-align: left;
    }

    div.dataTables_wrapper div.dataTables_filter input {
        margin-left: 0.5em;
        display: inline-block;
        width: auto;
        border-radius: 1.35rem;
    }

    div.dataTables_wrapper div.dataTables_info {
        padding-top: 0.85em;
        white-space: nowrap;
    }

    div.dataTables_wrapper div.dataTables_paginate {
        margin: 0;
        white-space: nowrap;
        text-align: right;
        border-radius: 1.35rem;
    }

    div.dataTables_wrapper div.dataTables_paginate ul.pagination {
        margin: 2px 0;
        white-space: nowrap;
        justify-content: flex-end;
    }

    div.dataTables_wrapper div.dataTables_processing {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200px;
        margin-left: -100px;
        margin-top: -26px;
        text-align: center;
        padding: 1em 0;
    }

    table.dataTable thead > th {
        border-bottom: 5px solid rgb(227, 230, 240) !important;
    }

    tr.group {
        line-height: 10px !important;
        background: rgb(64, 64, 64 / 20%) !important;
        color: white !important;
        background-color: rgb(64, 64, 64) !important;
    }

    table.dataTable thead > tr[role="row"] {
        border-spacing: 100px;
    }

    table.dataTable thead > tr > th.sorting_asc,
    table.dataTable thead > tr > th.sorting_desc,
    table.dataTable thead > tr > th.sorting,
    table.dataTable thead > tr > td.sorting_asc,
    table.dataTable thead > tr > td.sorting_disabled.sorting_desc,
    table.dataTable thead > tr > td.sorting {
        padding-right: 75px;
    }

    table.dataTable thead > tr > th:active,
    table.dataTable thead > tr > td:active {
        outline: none;
    }

    table.dataTable thead .sorting,
    table.dataTable thead .sorting_asc,
    table.dataTable thead .sorting_desc,
    table.dataTable thead .sorting_asc_disabled,
    table.dataTable thead .sorting_disabled .sorting_desc {
        cursor: pointer;
        position: relative;
    }

    table.dataTable thead .sorting::before,
    table.dataTable thead .sorting::after,
    table.dataTable thead .sorting_desc::before,
    table.dataTable thead .sorting_desc::after,
    table.dataTable thead .sorting_asc_disabled::before,
    table.dataTable thead .sorting_asc_disabled::after,
    table.dataTable thead .sorting_disabled .sorting_desc::before,
    table.dataTable thead .sorting_disabled .sorting_desc::after {
        position: absolute;
        display: block;
        opacity: 0.3;
        bottom: 10px;
    }

    table.dataTable thead .sorting_desc:before,
    table.dataTable thead .sorting_desc_disabled:before {
        content: url("../images/fontawesome-v6/arrow-up-wide-short-solid.svg") !important;
        right: 1em;
        filter: invert(100);
        height: 28px;
        width: 28px;
    }

    table.dataTable thead .sorting::before,
    table.dataTable thead .sorting::after,
    table.dataTable thead .sorting_desc::before,
    table.dataTable thead .sorting_desc::after,
    table.dataTable thead .sorting_disabled .sorting_desc::before,
    table.dataTable thead .sorting_disabled .sorting_desc::after {
        color: white;
    }

    table.dataTable thead .sorting::before,
    table.dataTable thead .sorting_asc::before,
    table.dataTable thead .sorting_disabled .sorting_asc::before {
        right: 1em;
        content: url("../images/fontawesome-v6/arrow-down-short-wide-solid.svg");
        filter: invert(100);
        height: 28px;
        width: 28px;
    }

    table.dataTable thead .sorting:after,
    table.dataTable thead .sorting_asc:after,
    table.dataTable thead .sorting_disabled .sorting_asc:after {
        right: 0.5em;
    }

    table.dataTable thead .sorting_asc::before,
    table.dataTable thead .sorting_desc::before {
        opacity: 1;
    }

    table.dataTable thead .sorting_disabled .sorting_asc:before,
    table.dataTable thead .sorting_disabled .sorting_desc:after {
        opacity: 0;
    }

    div.dataTables_scrollHead table.dataTable {
        margin-bottom: 0 !important;
    }
    div.dataTables_scrollHeadInner {
        padding-right: 0 !important;
    }
    div.dataTables_scrollBody table {
        border-top: none;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        margin-right: 0 !important;
    }

    @media screen and (max-width: 767px) {
        div.dataTables_wrapper div.dataTables_length,
        div.dataTables_wrapper div.dataTables_filter,
        div.dataTables_wrapper div.dataTables_info,
        div.dataTables_wrapper div.dataTables_paginate {
            text-align: center;
        }
    }
    table.dataTable.table-sm > thead > tr > th {
        padding-right: 20px;
    }

    table.dataTable.table-sm .sorting:before,
    table.dataTable.table-sm .sorting_asc:before,
    table.dataTable.table-sm .sorting_desc:before {
        top: 5px;
        right: 0.85em;
    }

    table.dataTable.table-sm .sorting:after,
    table.dataTable.table-sm .sorting_asc:after,
    table.dataTable.table-sm .sorting_desc:after {
        top: 5px;
    }

    tr:first-child > td:last-child {
        border-top-right-radius: 1.35rem;
        border-right: 0px solid rgb(227, 230, 240) !important;
        border-top: 0px solid rgb(227, 230, 240) !important;
    }

    tr:first-child > td.dtr-control,
    tr:first-child > td:first-child {
        border-top-left-radius: 1.35rem !important;
        border-left: 0px solid rgb(227, 230, 240) !important;
        border-top: 0px solid rgb(227, 230, 240) !important;
    }

    tr:last-child > td:last-child {
        border-bottom-right-radius: 1.35rem !important;
    }

    tr:last-child > td.dtr-control,
    tr:last-child > td:first-child {
        border-bottom-left-radius: 1.35rem !important;
    }

    div.table-responsive > div.dataTables_wrapper > div.row {
        margin: 0;
    }

    div.table-responsive
        > div.dataTables_wrapper
        > div.row
        > div[class^="col-"]:first-child {
        padding-left: 0;
    }

    div.table-responsive
        > div.dataTables_wrapper
        > div.row
        > div[class^="col-"]:last-child {
        padding-right: 0;
    }

    thead > tr {
        background: linear-gradient(60deg, "#2e2e2d", "#2e2e2d") !important;
        background-color: #2e2e2d !important;
        box-shadow: "#2e2e2d";
        -moz-box-shadow: "#2e2e2d";
        -webkit-box-shadow: "#2e2e2d";
        color: white;
        border-radius: 1.35rem;
        margin-top: -20px;
        border-color: "rgb(227, 240, 240) 10%";
    }

    tr:nth-child(even) > td {
        border: 1px solid rgb(227, 230, 240) 10%;
    }

    tr:nth-child(even) {
        background: #fff;
    }

    tr:nth-child(odd) > td {
        border: rgb(233, 233, 233) !important;
    }

    tr:nth-child(odd) {
        background-color: rgb(227, 230, 240);
    }

    table {
        //    border-collapse: collapse;
        margin: 10px;
    }
    td,
    th {
        padding: 0.5em 1em;
    }

    thead tr {
        box-shadow: 0 1px 5px rgb(46, 46, 45);
    }
    th {
        background-color: rgb(64, 64, 64);
    }
    tr.even td {
        background-color: white;
        color: rgb(64, 64, 64) !important;
    }

    td {
        border: 1px solid rgb(227, 230, 240) !important;
    }

    tr.odd td {
        background-color: rgb(233, 233, 233);
        color: rgb(64, 64, 64) !important;
    }

    th.sorting_disabled.sorting_desc::before {
        content: url(../images/fontawesome-v6/arrow-up-wide-short-solid.svg);
        font-size: 1.5em;
        width: 24px;
        color: white;
    }

    th.sorting .sorting_asc::before {
        content: url(../images/fontawesome-v6/arrow-down-short-wide-solid.svg);
        font-size: 1.5em;
        width: 24px;
        color: white;
    }

    tbody:before {
        line-height: 1em;
        content: ".";
        color: white;
        display: block;
    }

    table.dataTable tbody {
        border-radius: 1.35rem;
        border: 1px solid rgb(227, 230, 240);
    }

    .dt-buttons {
        white-space: break-spaces;
        padding-left: 10px;
        height: 50px;
    }

    .dt-buttons > .dt-button {
        margin-bottom: 10px !important;
        margin-right: 5px;
    }

    .dataTables_length {
        padding-left: 10px;
        width: 50%;
        float: left;
        text-align: left;
    }

    .table_filter {
        width: 50%;
        text-align: right;
        float: right;
        padding-right: 10px;
    }

    tfoot {
        color: white;
    }
`;
