export const TRANSLATIONS_EN_ZUKO = {
    ABGELAUFEN_AM: "Expired on",
    ABGELAUFENE_UNTERLAGEN: "Expired documents",
    A1_PFLICHT_GETRENNT: "A1<br>compulsory",
    A1_PFLICHT_UNTERLAGEN: "A1 compulsory documents",
    A1_PFLICHT_UNTERLAGEN_NICHT_VOLLSTAENDIG:
        "A1 compulsory documents incomplete",
    ABFRAGE_DRUCK_MODAL_BODY:
        "Would you like to print the construction site pass? Then select a print template from the dropdown and click on Print construction site pass. " +
        "A1 compulsory documents incomplete",
    A1_UNTERLAGEN: "A1 documents",
    ADMINISTRATION: "Administration",
    AKTION: "Action",
    AKTIONEN: "Actions",
    AKTIONSNACHRICHT: "Action message",
    AKTIV: "Active",
    AKTIVIERT: "Activated",
    AKTUALISIEREN: "Update",
    AKTUELLE_BESETZUNG: "Current occupation",
    AKTUELLE_BESETZUNG_DOWNLOAD: "Download current occupation",
    AKTUELLE_BESETZUNG_TEIL1: "Current",
    AKTUELLE_BESETZUNG_TEIL2: "construction site",
    ALLE_FIRMEN_UNTERLAGEN_GEPRUEFT: "All companies documents checked",
    ALLE_MINDESTLOHNERKLAERUNGEN_GEPRUEFT:
        "All minimum wage declarations checked",
    ALLE_PERSONAL_UNTERLAGEN_GEPRUEFT: "All companies documents checked",
    ALLERERSTER_EINTRITT_GETRENNT: "First <br> event of entry",
    AM: "on",
    ANTIPASSBACK: "Antipassback",
    ANTIPASSBACK_AKTIVIEREN: "Activate antipassback",
    ANWESENDE_FIRMEN_GESAMT: "Present companies (total)",
    ANWESENDE_FIRMEN_TOP_5: "Present companies (Top 5)",
    ANWESENDE_MITARBEITER_GES: "Employees present (total)",
    ANWESENDE_MITARBEITER_LIVE: "Employees present (live)",
    ANWESENDES_PERSONAL: "Present employees",
    ANWESENDES_PERSONAL_GESAMT: "Present employees (total)",
    ANZAHL: "Quantity",
    ANZAHL_AUFTRAGGEBER: "Quantity of client",
    ANZAHL_AUSWEISGUELTIGKEIT_KONFIGURATOR: "Count of days Id card validity",
    ANZAHL_FIRMEN: "Quantity of Companies",
    ANZAHL_GEWERKE: "Quantity of trades",
    ANZAHL_MITARBEITER: "Quantity of employees",
    ANZAHL_NACHUNTERNEHMER: "Quantity of subcontractor",
    ANZAHL_ERSTHELFER: "Quantity of first responder",
    ANZAHL_SANITAETER: "Quantity of paramedic",
    ANZAHL_BRANDSCHUTZBEAUFTRAGTER: "Quantity of fire protection officer",
    ANZAHL_TAGE: "Quantity of days",
    ANZAHL_TAGE_ABLAUF_GUELTIGKEIT: "Number of days of validity of the cycle",
    ANZAHL_TAGE_TITLE: "For example. 5 days after creation.",
    ARBEITSERLAUBNIS: "Work permit",
    ARBEITSVERHAELTNIS: "Employment relationship",
    ARBEITSVERHAELTNIS_ART: {
        VOLLZEIT: "Full-time",
        TEILZEIT: "Part-time",
        AUSBILDUNG: "Education",
        SELBSTSTÄNDIG: "Self-employed",
        ALTERSTEILZEIT: "Partial retirement",
        FREIE_MITARBEIT: "Freelance",
        GERINGFÜGIGE_BESCHÄFTIGUNG: "Marginal employment",
        KETTENARBEITSVERHÄLTNIS: "Chain employment relationship",
        LEIHARBEIT: "Contract work",
        ZEITARBEIT: "Time-work",
        PRAKTIKUM: "Internship",
        STUDENTENJOB: "Student job",
        AUSHILFSTÄTIGKEITEN: "Temporary jobs",
        SAISONJOB: "Seasonal job",
        SONSTIGE: "Other",
    },
    ARBEITSVERHAELTNIS_AUSWAEHLEN: "Select employment relationship",
    ARBEITSZEITEN: "Working hours",
    ARCHIV: "Archive",
    AUFENTHALTSERLAUBNIS: "Residence permit",
    AUFENTHALTSTITEL: "Residence permit",
    AUTOMATISCHE_SPERRUNG: "Automatical block",
    AUFTRAGGEBER: "Client",
    AUFTRAGGEBER_GELOESCHT: "Client deleted",
    AUFTRAGGEBER_HINZUFUEGEN: "Add client",
    AUFTRAGGEBER_HINZUGEFUEGT: "Client added",
    AUFTRAGGEBER_LOESCHEN: "Delete client",
    AUFTRAGGEBER_MODAL_LOESCHEN: "Do you really want to delete this client?",
    AUFTRAGGEBER_MUSS_HINZUGEFUEGT_WERDEN: "Add a client.",
    AUSGEWAEHLTE_UNTERLAGEN: "Selected documents",
    AUSTRITT: "Exit",
    AUSWEISE: "ID cards",
    AUSWEIS_GUELTIGKEIT_KONFIGURATOR: "Id card validity configurator",
    AUSWEIS_GUELTIGKEIT_NICHT_EINGESTELLT: "Id card validity not configured.",
    AUSWEIS_INFO_MODAL: {
        TITEL: "First steps",
        VERSCHIEDENE_VORLAGE: "Various templates can be selected here",
        DESIGN_ERSTELLEN: "Create ID card design",
        BUTTON_FUER_SYSTEMVORLAGE:
            "With this button you can create a system template",
        DESIGN_LOESCHEN: "Delete ID card template",
        BUTTON_FUER_LOESCHEN:
            "If a template is selected, the template can be deleted with this button",
        TEXT_VORDERSEITE:
            "Click on the images and text in the header to edit them. Click the header or footer to change the color. " +
            "The color of the texts can be changed in the footer area, but not the texts, which are automatically filled when printing",
        TEXT_RUECKSEITE:
            "By clicking on the texts and on the symbols " +
            "can these be changed. By clicking on the picture in " +
            "This header area can be edited.",
    },
    AUSWEIS_KONFIGURIEREN: "Configure ID card validity",
    AUSWEIS_VORLAGE: "Design ID card",
    AUSWEIS_VORLAGEN: "ID cards",
    AUSWEISBILD: "ID picture",
    AUSWEISE_ERFOLGREICH_GESPERRT:
        "Construction site permits have been successfully disabled",
    AUSWERTUNGEN_AKTUALISIEREN: "Update evaluations",
    AUSWERTUNGEN_ZUTRITTSEREIGNISSE: "Evaluation of access events",
    AUSWERTUNGEN_ZUTRITTSEREIGNISSE_TEIL1: "Evaluations of",
    AUSWERTUNGEN_ZUTRITTSEREIGNISSE_TEIL2: "access events",
    AUSWIRKUNGEN_AUF_AUSWEISGUELTIGKEIT: "Effects on ID </br> card validity",
    BAUSTELLE: "Construction site",
    BAUSTELLEN: "Construction sites",
    BAUSTELLENAUSWEISANZAHL: "Construction site permits count",
    BAUSTELLENAUSWEISE: "Construction site permits",
    BAUSTELLENAUSWEISE_GESAMT: "Total permits",
    BAUSTELLENAUSWEISE_GETRENNT: "Construction<br/> site permits",
    BAUSTELLENAUSWEISE_GESPERRT: "Construction site permits disabled",
    BAUSTELLENAUSWEIS_ANZAHL: "Count of construction site permits",
    BAUSTELLENAUSWEIS_AKTIV_ANZAHL: "Count of active contruction site permits",
    BAUSTELLENAUSWEIS_AUSWAEHLEN: "Select construction site permit",
    BAUSTELLENAUSWEIS_DRUCKEN: "Print construction site permit",
    BAUSTELLENAUSWEIS_ENTSPERREN: "Unlock construction site permit",
    BAUSTELLENAUSWEIS_ENTSPERREN_MODAL:
        "Do you really want to unlock this construction site permit?",
    BAUSTELLENAUSWEIS_EINSTELLUNGEN: "Construction site permit settings",
    BAUSTELLENAUSWEIS_EINSTELLUNGEN_NICHT_SYNCHRONISIERT:
        "Construction site permit settings are not synchronized",
    BAUSTELLENAUSWEIS_EINSTELLUNGEN_AKTUALISIEREN_UND: "Update settings and ",
    BAUSTELLENAUSWEIS_EINSTELLUNGEN_SETZEN:
        "Construction site permit settings must first be set.",
    BAUSTELLENAUSWEIS_ENTSPERREN: "Unlock Construction site permit",
    BAUSTELLENAUSWEIS_ERFOLGREICH_ERSTELLT:
        "Construction site permit successfully created",
    BAUSTELLENAUSWEIS_ERFOLGREICH_GESPERRT:
        "Construction site permit has been blocked successfully",
    BAUSTELLENAUSWEIS_ERFOLGREICH_SYNCHRONISIERT:
        "Construction site permit successfully synchronized.",
    BAUSTELLENAUSWEIS_ERSTELLEN: "Create a construction site permit",
    BAUSTELLENAUSWEIS_ERSTELLEN_DRUCKEN:
        "Create/print a construction site permit",
    BAUSTELLENAUSWEIS_ERSTELLT_WERDEN:
        "A construction site permit must first be issued.",
    BAUSTELLENAUSWEIS_EXISTIERT: "Construction site permit already exists!",
    BAUSTELLENAUSWEIS_GESPERRT: "Construction site permit is blocked",
    BAUSTELLENAUSWEIS_GUELTIG_AB: "Construction site permit valid from",
    BAUSTELLENAUSWEIS_GUELTIG_BIS: "Construction site permit valid until",
    BAUSTELLENAUSWEIS_GRUND_AUSWAEHLEN: "Choose reason on construction site",
    BAUSTELLENAUSWEIS_LOESCHEN: "Delete construction site permit",
    BAUSTELLENAUSWEIS_LOESCHEN_SONSITGE_GRUND: "Other reason",
    BAUSTELLENAUSWEIS_LOESCHGRUND_FEHLDRUCK: "Fail print",
    BAUSTELLENAUSWEIS_LOESCHEN_LOESCHGRUND: "Reason of delete",
    BAUSTELLENAUSWEIS_LOESCHGRUND_SONSTIGE: "Other",
    BAUSTELLENAUSWEIS_LOESCHGRUND_VERLASSEN: "Leave the construction site",
    BAUSTELLENAUSWEIS_LOESCHGRUND_VERWEIS: "Construction site banned",
    BAUSTELLENAUSWEIS_LOESCHEN_BODY:
        "Do you really want to delete the construction site permit",
    BAUSTELLENAUSWEIS_LOESCHEN_LOESCHGRUNG: "Deletion reason",
    BAUSTELLENAUSWEIS_NICHT_MEHR_GUELTIG:
        "Construction site permit isn't valid",
    BAUSTELLENAUSWEIS_NICHT_SYNCRONISIERT:
        "Construction site permit isn't synced",
    BAUSTELLENAUSWEIS_NOCH_NICHT_GUELTIG:
        "Construction site permit not valid yet",
    BAUSTELLENAUSWEIS_OHNE_BILD_TEIL1:
        "Do you really want to print the construction site permit",
    BAUSTELLENAUSWEIS_OHNE_BILD_TEIL2: "without a picture",
    BAUSTELLENAUSWEIS_OHNE_BILD_TEIL3: "",
    BAUSTELLENAUSWEIS_SCANNEN: "Scan/write construction site permit/name",
    BAUSTELLENAUSWEIS_SPERREN: "Block construction site permit",
    BAUSTELLENAUSWEIS_SUCHEN: "Search construction site permit",
    BAUSTELLENAUSWEIS_SYNCHRONISIEREN: "Synchronize construction site permits",
    BAUSTELLENAUSWEIS_WURDE_GEDRUCKT:
        "Construction site permit with card number {{kartennummer}} was printed",
    BAUSTELLENAUSWEIS_VERLAENGERN: "Extend construction site permit",
    BAUSTELLENAUSWEIS_VERLAENGERN_MODAL:
        "Do you really want to extend the construction site permit?",
    BEDINGUNG_STARTZEIT:
        "The start time cannot be greater than or equal to the end time!",
    BENACHRICHTIGUNGEN: "Notifications",
    BENACHRICHTIUNG_FIRMENVERWALTER:
        "Notifications of company administration (days of expiry)",
    BENACHRICHTIGUNGEN_HELP_1:
        "When activating the e-mail to the company administration, this will receive a reminder e-mail that the construction site pass validity " +
        "expires. ",
    BENACHRICHTIGUNGEN_HELP_2:
        "When the e-mail is activated, the test administration receives an e-mail which informs about the validity of the construction site ID " +
        "informed.",
    BENACHRICHTIUNG_PRUEFVERWALTUNG:
        "Notifications of management of test (days of expiry)",
    BERECHTIGUNGEN: "Permissions",
    BERECHTIGUNGSGRUPPE: "Authorization group",
    BERECHTIGUNGSGRUPPE_ERFOLGREICH_HINZUGEFÜGT:
        "The authorization group add successfully",
    BERECHTIGUNGSGRUPPE_HINZUFUEGEN: "Add authorization group",
    BERECHTIGUNGSGRUPPEN: "Authorization groups",
    BERECHTIGUNGSGRUPPEN_ID_KOPIEREN: "Copy authorization groups",
    BERECHTIGUNGSGRUPPEN_VERWALTEN: "Manage authorization groups",
    BERECHTIGUNGSGRUPPEN_TEIL1: "Authorization",
    BERECHTIGUNGSGRUPPEN_TEIL2: "groups",
    BERECHTIGUNGSGRUPPEN_VERWALTEN_TEIL1: "Manage ",
    BERECHTIGUNGSGRUPPEN_VERWALTEN_TEIL2: "authorization groups",
    BERECHTIGUNGSGRUPPEN_SYNCHRONISIEREN: "Refresh authorization groups",
    BERECHTIGUNGSGRUPPE_AKTUALISIEREN: "Update authorization group",
    BERECHTIGUNGSGRUPPE_AUSWAEHLEN: "Select authorization group",
    BERECHTIGUNGSGRUPPE_GETRENNT: "Authorization-<br>group",
    BERECHTIGUNGSGRUPPE_LOESCHEN: "Delete authorization group",
    BERECHTIGUNGSGRUPPE_NICHT_ZUGEWIESEN: "Authorization group isn't assigned",
    BESCHREIBUNG: "Description",
    BETRIEBSSANITAETER: "Company paramedic",
    BETRIEBSSANITAETER_GETRENNT: "Company<br>paramedic",
    BEZEICHNUNG: "Designation",
    BILD_MODAL: {
        TITEL: "Add/remove image",
        BODY: "Would you like to add or remove an image?",
        BILD_HINZUFUEGEN: "Add a picture",
        ENTFERNE_BILD: "Remove image",
    },
    BILDVERIFIZIERUNG: "Image verification",
    BILDVERIFIZIERUNG_AKTIVIEREN: "Activate image verification",
    BILDVERIFIZIERUNG_DEAKTIVIEREN: "Deactivate image verification",
    BIS: "till",
    BODY_ZUTRITTSPUNKT_LOESCHEN:
        "Do you really want to delete the access point?",
    BRANDSCHUTZBEAUFTRAGTER: "Fire protection officer",
    BRANDSCHUTZBEAUFTRAGTE_PERSONEN: "Fire protection officers",
    DATEI: "File",
    DATEI_AUSWAEHLEN: "Choose a file",
    DATEI_TYPEN: {
        ERFORDERLICHE_UNTERLAGEN: "Required documents",
        WEITERE_UNTERLAGEN: "Other documents",
        NICHT_EU_BÜRGER_UNTERLAGEN: "Non EU citizen documents",
        A1_UNTERLAGEN: "A1 documents",
        WERKVERTRAGSARBEITNEHMERKARTE_UNTERLAGEN:
            "Work contract employee card documents",
    },
    DATEI_VORHANDEN: "File exists?",
    DATUM_AUSWAEHLEN: "Choose a date",
    DAVON_AKTIV: "of them {{anzahl}} active ",
    DEAKTIVIERT: "Deactivated",
    DIENSTAG: "Tuesday",
    DOKUMENT_ENTPRUEFEN: "Check the document",
    DOKUMENT_ERSETZEN: "Replace document",
    DOKUMENT_ERFOLGREICH_ERSETZT: "Document replaced successfully.",
    DOKUMENT_GELOESCHT: "Deleted document",
    DOKUMENT_LOESCHEN: "Delete document",
    DOKUMENT_MODAL_ENTPRUEFEN:
        "Are you sure you want to mark this document as no longer reviewed?",
    DOKUMENT_MODAL_LOESCHEN: "Do you really want to delete this document?",
    DOKUMENT_MODAL_PRUEFEN:
        "Are you sure you want to mark this document as reviewed?",
    DOKUMENT_PRUEFEN: "Check document",
    DOKUMENT_WIRD_GELADEN: "Document is loading",
    DONNERSTAG: "Thursday",
    DOWNLOAD_NAME: "Name of download",
    DREHKREUZ: "Turnstile XY container",
    DRUCKEN: "Print",
    DRUCKVORLAGE: "Artwork",
    DRUCKVORSCHAU: "Print preview",
    DURCHGEFUEHRT_AM: "Carried out on",
    DURCHGEFUEHRT_VON: "Carried out by",
    EINGABE_ENDPOINT: "Enter endpoint here",
    EINSTELLUNGEN: "Settings",
    EINSTELLUNGEN_ERFOLGREICH_GESETZT:
        "The settings for the construction site permits have been set successfully.",
    EINSTELLUNGEN_GESETZT_WERDEN:
        "The settings for the construction site permits must be set.",
    EINSTELLUNGEN_SETZEN: "Set settings",
    EINSTELLUNGEN_SYNCHRONISIEREN: "Synchronize settings",
    EINTRITT: "Entry",
    ENDDATUM: "Enddate",
    ENDPUNKT: "Endpoint",
    ENDPUNKT_FEHLT: "Please enter an endpoint.",
    ENDPUNKT_VERBINDUNG_ERFOLGREICH: "The endpoint was addressed successfully.",
    ENTNPRUEFE_MINDESTLOHNERKLAERUNG_MODAL: {
        BODY: "Do you really want to mark this minimum wage declaration as not audited?",
        BUTTON: "Mark as not checked",
        TITEL: "Revoke minimum wage declaration examination",
    },
    ENTPRUEFT_MARKIEREN: "Mark as not checked",
    ENTSPERREN: "Unlock",
    ENTSPERRGRUND: "Unlock Reason",
    ERFORDERLICH: "Required",
    ERFORDERLICHE_UNTERLAGEN: "Required documents",
    ERREICHBAR: "available",
    ERREICHBARKEIT_LOG: "Availability Log",
    ERSTELLT_SYNCHRONISIERT_BERECHTUNGSGRUPPELIMIT:
        "Created/Synced/Permission Group Limit",
    ERSTELLT_SYNCHRONISIERT_ZEITZONENLIMIT: "Created/Synced/Time Zone Limit",
    ERSTELLUNGSDATUM: "Creation Date",
    ERST_MIT_SERVER_VERBINDEN: "Please connect to the server first",
    ERSTES_EINTRITTS_EREIGNIS: "First entry event",
    ERSTHELFER: "First responder",
    ERSTHELFER_MEHRZAHL: "First responders",
    ERSTHELFER_GETRENNT: "First <br>responder",
    EU_BUERGER: "EU citizens",
    EU_BUERGER_GETRENNT: "EU<br>citizens",
    EU_MITGLIEDSSTAATEN: "EU member states including 4 EFTA stats",
    FEHLENDE_RECHTE: "Missing permissions",
    FEHLENDE_RECHTE_WÄHLE_PERSONAL: "Missing permissions. Choose a employee.",
    FIRMA_AKTIVIEREN: "Activate company",
    FIRMA_AKTIVIEREN_MODAL: {
        BODY: "Do you really want to activate the selected company?",
    },
    FIRMA_AUSWAEHLEN: "Select company",
    FIRMA_EINGEBEN: "Please enter a companyname",
    FIRMA_ERFOLGREICH_ERSTELLT: {
        BODY: "What do you want to do next?",
        NAVIGATIONS_TEIL1: "Create another company",
        NAVIGATIONS_TEIL2: "Create employees",
        NAVIGATIONS_TEIL3: "Look at created company",
        NAVIGATIONS_TEIL4: "Company overview",
        FIRMA_ERFOLGREICH_ERSTELLT: "Company created successfully",
    },
    FIRMA_ERFOLGREICH_GELOESCHT: {
        BODY: "What do you want to do next?",
        NAVIGATIONS_TEIL1: "Create new company",
        NAVIGATIONS_TEIL2: "Company overview",
        NAVIGATIONS_TEIL3: "Dashboard organisation",
        NAVIGATIONS_TEIL4: "Dahboard documents",
        NAVIGATIONS_TEIL5: "Dashboard construction site",
        NAVIGATIONS_TEIL6: "Go back",
        TITEL: "Company was deleted successfully",
    },
    FIRMA_ERSTELLEN: "Create company",
    FIRMA_EXISTIERT: "Company already exists!",
    FIRMA_DEAKTIVIEREN: "Deactivate company",
    FIRMA_DEAKTIVIEREN_MODAL: {
        BODY: "All of the employees' site passes will be deleted. Do you really want to deactivate the selected company?",
    },
    FIRMA_DOKUMENT_FILTERN: "Filter by documents or by company name",
    FIRMA_LOESCHEN: "Delete company",
    FIRMA: "Companyname",
    FIRMANAMEN: "Company name",
    FIRMA_WURDE_ERFOLGREICH_AKTIVIERT: "Company was successfully activated",
    FIRMA_WURDE_ERFOLGREICH_DEAKTIVIERT: "Company has been successfully deactivated",
    FIRMEN: "Companies",
    FIRMEN_AUSWERTUNGEN: "Companies analysis",
    FIRMEN_DATEN: "Company data",
    FIRMEN_FILTERN: "Filter by company name",
    FIRMEN_UEBERSICHT_DOWNLOAD: "Download company overview",
    FIRMEN_ZUTRITTSEREIGNISSE_AUSWERTUNGEN: "Evaluation comany access events",
    FIRMENBEZIEHUNGEN: "Corporate relationships",
    FIRMENHIERACHIE: "Company hierarchy",
    FIRMENNAME: "Company name",
    FIRMENVERWALTER: "Company administrator",
    FIRMENVERWALTER_BEREITS_HINZUGEFUEGT:
        "Company manager has already been added",
    FIRMENVERWALTER_GELOESCHT: "Company administrator deleted",
    FIRMENVERWALTER_HINZUFUEGEN: "Add company manager",
    FIRMENVERWALTER_HINZUGEFUEGT: "Company administrator added",
    FIRMENVERWALTER_LOESCHEN: "Delete company manager",
    FIRMENVERWALTER_MODAL_LOESCHEN:
        "Do you really want to delete the company manager?",
    FIRMEN_UNTERLAGEN: "Company documents",
    FIRMEN_UNTERLAGEN_AUSWERTUNGEN: "Company documents analysis",
    FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DOWNLOAD:
        "Download company documents analysis",
    FIRMEN_UNTERLAGEN_HOCHLADEN: "Upload company documents",
    FIRMEN_UNTERLAGEN_HINZUFUEGEN: "Add company documents",
    FIRMEN_UNTERLAGEN_KONFIGURIEREN: "Configure company documents",
    FIRMEN_UNTERLAGEN_MODAL_AKTUALISIEREN: {
        TITEL: "Update company documents",
        BODY: "Do you really want to update the company documents?",
        CARD_HEADER:
            "Attention: If the properties of the documents are changed, the files that have already been uploaded remain stored with the companies.",
    },
    FIRMEN_UNTERLAGEN_MODAL_LOESCHEN: {
        TITEL: "Delete company documents",
        BODY: "Do you really want to delete the company documents?",
        CARD_HEADER:
            "Attention: There files that have already been uploaded remain stored with the companies.",
    },
    FIRMEN_UNTERLAGEN_PRUEFEN: "Check company documents",
    FOTO_AUFNEHMEN: "Capture picture",
    FOTO_BENUTZEN: "Use picture",
    FOTO_HOCHLADEN: "Upload picture",
    FOTO_MACHEN: "Take picture",
    FUNKTION_IN_ARBEIT:
        "It is currently not possible to state the validity date.",
    FREITAG: "Friday",
    GEBURTSTAG: "Birthday",
    GELOESCHT: "Deleted",
    GEPRUEFT: "Checked",
    GEPRUEFT_GUELTIG: "Checked/Validity",
    GEPRUEFT_HOCHGELADEN: "Checked/Uploaded",
    GEPRUEFT_HOCHGELADEN_SOLL_ABGELAUFEN: "Checked/Uploaded/Target/Expire",
    GEPRUEFT_MARKIEREN: "Mark as checked",
    GESAMT_HOCHGELADEN_GEPRUEFT_SOLL: "Total(Checked/Uploaded/Should count)",
    GESAMT_GEPRUEFT_HOCHGELADEN_SOLL_ABGELAUFEN:
        "Total(Checked/Uploaded/Should count/Expire)",
    GESCANNTE_KARTENNUMMER_STIMMT_NICHT:
        "Scanned card number {{eingegebeneKartennummer}} isn't correct",
    GESPERRT: "Locked",
    GEWERBLICHER_MITARBEITER: "Commercial employee",
    GEWERK: "Trade",
    GEWERK_AUSWAEHLEN: "Select trades",
    GUELTIG_BIS: "valid until",
    GUELTIG_VON: "valid from",
    GUELTIGKEIT: "Validity",
    GUELTIGKEIT_DOKUMENT: "Validity of documents",
    GUELTIGKEITSDATUM: "Validity date",
    GUELTIGKEIT_FEHLENDER_UNTLERAGEN: "Validity missing documents",
    GUELTIGKEIT_FEHLENDER_UNTLERAGEN_HELP:
        "Select after how many days the validity of the card expires, " +
        "if documents are missing. Enter a number in the input field.",
    GUELTIGKEIT_HINZUFUEGEN: "Add validity",
    GUELTIGKEIT_VERLAENGERN: "Extend validity",
    GUELTIGKEITSDATUM: "Validity date",
    HAEUFIG: "Often",
    HAT_GUELTIKEIT: "Validity activated",
    HIERACHIE: "hierachy",
    HIERACHISCHES_LAYOUT: "Hierarchical layout",
    HISTORIE: "History",
    HOCHGELADEN: "Uploaded",
    HOCHGELADEN_GEPRUEFT: "Uploaded / checked",
    HOCHGELADEN_GEPRUEFT_GUELTIGKEIT_ABGELAUFENE:
        "Uploaded / Checked / Validity / Expired",
    HOCHGELADEN_GEPRUEFT_SOLL: "Uploaded / checked / target number",
    HOCHGELADEN_GEPRUEFT_SOLL_ABGELAUFENE:
        "Uploaded / checked / target number / expired ",
    HOCHGELADEN_GEPRUEFT_SOLL_ABGELAUFENE_GUELTIGKEIT:
        "Uploaded / checked / target number / expired / validity",
    HOCHGELADENE_UNTERLAGEN: "Uploaded <br/> documents",
    HORIZONTAL_ANORDNEN: "Arrange horizontally",
    ID_KOPIEREN: "Copy Id",
    ID_KOPIERT: "I'd copy Id",
    INAKTIV: "inactive",
    INFO_ABGELAUFENE_UNTERLAGEN: "There have documents who is expired",
    INFO_BA_GESPERRT: "There are blocked construction site permits \n",
    INFO_BA_NICHT_SYNCHRONISIERT:
        "Not all construction site IDs are synchronized \n",
    INFO_KEIN_EFTA_LAND:
        "The country of origin of this company or the nationality of the employee is not an EU member state  " +
        "Employee is not an EU member state or one of the 4 " +
        "EFTA countries. Please contact the responsible person " +
        "site management before you create the site pass.",
    INFO_UNTERLAGEN_NICHT_VOLLSTAENDIG:
        "Required documents are not complete \n",
    INFO_UNTERLAGEN_NICHT_VOLLSTAENDIG_GEPRUEFT:
        "Required documents have not been fully checked \n",
    INFO_WEITERE_UNTERLAGEN_NICHT_VOLLSTAENDIG:
        "Required documents are not complete \n",
    IN_RAUMZONE: "Go into space zone",
    ISTAKTIV: "Is active?",
    IST_SYNCHRONISIERT_GETRENNT: "Is<br>synchronized",
    JA: "Yes",
    KARTENNUMMER: "Card number",
    KARTENNUMMER_PERSONAL: "Card number of employee",
    KEIN_AUFTRAGGEBER: "No client selected",
    KEIN_AUSWEIS_VORHANDEN: "No ID available",
    KEIN_BAUSTELLENAUSWEIS_GEWAEHLT: "No construction site permit selected",
    KEIN_GUELTIGKEITSDATUM: "No validity date",
    KEIN_NACHUNTERNEHMER: "No subcontractor selected",
    KEIN_PERSONAL_GEWAEHLT: "No employee selected",
    KEIN_PDF_FORMAT: "File format is not a PDF.",
    KEIN_RECHT_BAUSTELLENAUSWEIS_ERSTELLEN:
        "You do not have the right to issue a construction site permit.",
    KEIN_VERWALTER: "No administrator selected",
    KEINE_AKTION_VORHANDEN: "No action available",
    KEINE_BAUSTELLENAUSWEISEINSTELLUNGEN_ABGELAUFEN:
        "There are no expired construction site permits",
    KEINE_BERECHTIGUNSGRUPPEN_KONFIGURIERT:
        "No authorization groups are configured",
    KEINE_ERGEBNISSE_GEFUNDEN: "Sorry, we couldn't find any results",
    KEINE_FIRMENUNTERLAGEN_ANGEFORDERT: "No company documents are requested",
    KEINE_GESPERRTEN_BAUSTELLENAUSWEISE_VORHANDEN:
        "No blocked construction site permits are available",
    KEINE_BAUSTELLENAUSWEISE_ABGELAUFEN:
        "No construction site permits are expired",
    KEINE_BAUSTELLENAUSWEISEINSTELLUNGEN_ABGELAUFEN:
        "No construction site permit settings are expired",
    KEINE_FIRMA_WHITELIST: "No company added.",
    KEINE_FIRMEN_UNTERLAGEN_KONFIGURIERT: "No company documents are configured",
    KEINE_MAIL_HINZUGEFUEGT: "No email was added",
    KEINE_MITARBEITERUNTERLAGEN_ANGEFORDERT:
        "No employee documents are requested",
    KEINE_LESEEINHEIT_GEWAEHLT: "No employee unit selected",
    KEINE_PERSONAL_UNTERLAGEN_KONFIGURIERT:
        "No company documents are configured",
    KEINE_RAUMZONE: "No access point configured",
    KEINE_VORHANDEN: "Not available",
    KEINE_VORLAGE_VORHANDEN: "No template available",
    KEINE_ZUGEWIESEN: "None assigned",
    KEINE_SERVER_VERBINDUNG: "No connection with the server",
    KEINER_AM_DREHKREUZ: "No one on the hook",
    KEINER_VORHANDEN: "None available",
    KONFIGURATION: "Configuration",
    KONFIGURATION_ERFOLGREICH_AKTUALSIIERT:
        "Configuration successfully updated",
    KONFIGURATION_AKTUALISIEREN: "Update configuration",
    KONFIGURATION_ERFOLGREICH_GESPEICHERT: "Configuration successfully saved",
    KONFIGURATION_ERSTELLEN: "Create configuartion",
    LAND: "Country",
    LAND_AUSWAEHLEN: "Choose country",
    LAUFT_IN_DER_MONAT_AB: "Expires this month",
    LEEREN: "Clear",
    LESEEINHEIT: "Reading unit",
    LESEEINHEIT_AUSTRITT: "Reading unit exit",
    LESEEINHEIT_EINTRITT: "Reading unit entry",
    LESEEINHEITEN: "Reading units",
    LESEEINHEIT_AUSWAEHLEN: "Select reading unit",
    LETZTE_AENDERUNG_GETRENNT: "Last<br>change",
    LETZTE_SYNCRONISIERUNG_GETRENNT: "Last<br>sync",
    LETZTER_AUSTRITT_GETRENNT: "Last<br>Exit event",
    LETZTER_EINTRITT_GETRENNT: "Last<br> Entry Event",
    LETZTER_FEHLENDER_MONAT: "Last missing month",
    LETZTES_AUSTRITTS_EREIGNIS: "First exit event",
    LETZTES_EINTRITTS_EREIGNIS: "Last entry event",
    LOESCHE_FIRMENUNTERLAGE: "Delete company document",
    LOESCHE_FIRMENUNTERLAGE_BODY: "Do you really want to delete this document?",
    LOESCHE_MINDESTLOHNERKLAERUNG_MODAL: {
        BODY: "Do you really want to delete this minimum wage declaration?",
        TITEL: "Delete minimum wage declaration",
    },
    LOESCHEN: "Delete",
    MAIL_AN_FIRMENVERWALTUNG: "Mail to the company management",
    MAIL_AN_PRUEFVERANTWORTLICHEN: "Mail to the responsible person",
    MAIL_INFO_FIRMENVERWALTER:
        "The company administrator's email address is included in the " +
        "company master data configured",
    MAIL_PRUEFVERANWORTLICHEN: "Mail to the responsible person",
    MINDESTLOHN: "Minimum wage",
    MINDESTLOHN_KONFIGURATION: "Minimum wage configuration",
    MINDESTLOHNERKLAERUNG_DOWNLOAD: "Download minimum wage declaration",
    MINDESTLOHNERKLAERUNG_ERFOLGREICH_GEPRUEFT:
        "Minimum wage declaration was successfully checked",
    MINDESTLOHNERKLAERUNG_ERFOLGREICH_HOCHGELADEN:
        "Minimum wage declaration was succesfully uploaded",
    MINDESTLOHNERKLAERUNG_EROLGREICH_GELOESCHT:
        "Minimum wage declaration was successfully deleted",
    MINDESTLOHNERKLAERUNG_VOM_MONAT: "Minimum wage of the month ",
    MINDESTLOHNERKLAERUNGEN: "Minimum wage declarations",
    MINDESTLOHNERKLAERUNGEN_FILTERN:
        "Filter minimum wage or employee name declarations",
    MINDESTLOHNERKLAERUNGEN_GESAMT: "Minimum wage (total)",
    MINDESTLOHNERKLAERUNGEN_GETRENNT: "Minimum wage declarations",
    MINDESTLOHNERKLAERUNGEN_PRUEFEN: "Check minimum wage declarations",
    MINDESTLOHNKONFIGURATOR: "Minimum wage",
    MINDESTLOHNKONFIGURATOR_NICHT_EINGESTELLT:
        "Minimum wage configurator has not yet been configured",
    MINDESTLOHNPFLICHTIG: "Minimum wage obligation",
    MITARBEITER: "Employees",
    MITARBEITER_AKTIVIEREN: "Activate employee",
    MITARBEITER_AKTIVIEREN_MODAL: {
        BODY: "Do you really want to activate the selected employee?",
    },
    MITARBEITER_DEAKTIVIEREN: "Deactivate employee",
    MITARBEITER_DEAKTIVIEREN_MODAL: {
        BODY: "Employee construction site ID card will be deleted. Do you really want to deactivate?",
    },
    MITARBEITER_ERSTELLT_MODAL: {
        AUSWAHL_1: "Create another employee",
        AUSWAHL_2: "Look at created employees",
        AUSWAHL_3: "Look at associated company",
        AUSWAHL_4: "Employee overview",
        AUSWAHL_5: "Organsisation",
        AUSWAHL_6: "Consturction site",
        BODY: "What do you want to do next?",
        TITEL: "Employees created successfully",
    },
    MITARBEITER_GELOESCHT: "Employees deleted",
    MITARBEITER_LOESCHEN: "Delete employee",
    MITARBEITER_LOESCHEN_MODAL: {
        BODY: "Do you really want to delete this employee?",
    },
    MITARBEITER_UNTERLAGEN: "Employee documents",
    MITARBEITER_UNTERLAGEN_MODAL_AKTUALISIEREN: {
        TITEL: "Update employee documents",
        BODY: "Do you really want to update this employee documents?",
        CARD_HEADER:
            "Attention: If the properties of the documents are changed, files that have already been uploaded remain stored with the employees.",
    },
    MITARBEITER_UNTERLAGEN_MODAL_HINZUFUEGEN: {
        DATEI_HINZUFUEGEN: "Added file",
        NAME_EINGEBEN: "Enter a name",
        A1PFLICHT: "A1 requirement",
        AUSWIRKUNGEN_AUF_AUSWEISGUELTIGKEIT: "Effects on ID card validity",
    },
    MITARBEITER_UNTERLAGEN_MODAL_LOESCHEN: {
        TITEL: "Delete employee documents",
        BODY: "Do you really want to delete this employee documents?",
        CARD_HEADER: "Warning: There are already uploaded documents",
    },
    MITTWOCH: "Wednesday",
    MODAL_AUSWEIS_LOESCHEN: {
        TITEL: "Delete ID card template",
        BODY: "Do you really want to delete the construction site permit template?",
        INFO_CARD_HEADER: "Warning",
        INFO_CARD_CONTENT:
            "After the template has been deleted, it is no longer available as a print template for Construction site permits.",
        PLACEHOLDER: "Enter the name of the template",
    },
    MODAL_BODY_FIRMA_LOESCHEN: "Do you really want to delete the company?",
    MODAL_TEXT: {
        TITEL: "Edit text",
        SCHRIFTFARBE: "Font color",
        SCHRIFT_FETT: "Font in bold",
        TEXT_AUSBLENDEN: "Hide text",
        AENDERN: "Change",
    },
    MODAL_VORLAGE_HOCHLADEN: {
        TITEL: "Upload template",
        VORLAGE_ERSTELLEN: "Create template",
    },
    MONAT_FILTERN: "Filter by month",
    MONATLICH: "Monthly",
    MONTAG: "Monday",
    MUSTERFIRMA: "Sample company",
    MUSTERSTADT: "Sample city",
    MUSTERSTRASSE: "Sample street 123",
    MUSTERTITEL: "Example title",
    NACHNAME: "Surname",
    NACHREICHEN: "Subsequent filing",
    NACHUNTERNEHMER: "Subcontractors",
    NACHUNTERNEHMER_GELOESCHT: "Subcontractor deleted",
    NACHUNTERNEHMER_HINZUFUEGEN: "Add subcontractors",
    NACHUNTERNEHMER_HINZUGEFUEGT: "Subcontractor added",
    NACHUNTERNEHMER_LOESCHEN: "Delete subcontractors",
    NACHUNTERNEHMER_MODAL_LOESCHEN:
        "Do you really want to delete the subcontractor?",
    NAME: "Name",
    NEIN: "No",
    NEUE_VORLAGAE: "New template",
    NEUE_VORLAGE_ERSTELLEN: "Create new template",
    NICHT_ERFORDERLICH: "Not required",
    NICHT_EU: "Not EU",
    NICHT_EU_BUERGER: "Not EU citizen",
    NICHT_EU_BUERGERUNTERLAGEN_UNVOLLSTAENDIG:
        "Non-EU citizens documents are not complete",
    NICHT_EU_BUERGER_UNTERLAGEN: "Non-EU citizens documents",
    NICHT_HOCHGELADEN: "Not uploaded",
    NICHT_GEPRUEFT: "Not checked",
    NICHT_VORHANDEN: "not present",
    NOCH_NICHT_GEPRUEFT: "Not yet checked",
    NOCH_NICHT_HOCHGELADEN: "Not yet uploaded",
    OEFFNE_BILDVERIFIZIERUNG: "Open image verification",
    OFFLINE: "offline",
    OHNE_BILD_DRUCKEN: "Print without picture",
    OHNE_DRUCKEN_FORTFAHREN: "Continue without printing",
    OHNE_SYNCRONISIERUNG: "Construction site permit no synchronization",
    ONLINE: "online",
    OPTION_AUSWEIS_KONFIGURATION: {
        TITEL: "Warning! Existing staff",
        BODY: "Would you like to define your settings for already existing personnel?",
        AUSWAHL_MOEGLICHKEIT_1: "Yes, also for existing staff",
        AUSWAHL_MOEGLICHKEIT_2: "No, only for newly created staff",
    },
    ORGANISATION: "Organisation",
    ORT: "Location",
    PERSONAL: "Employees",
    PERSONAL_AKTIVIEREN: "Activate employee",
    PERSONALANZAHL: "Employee count",
    PERSONAL_AUSWERTUNGEN: "Employee documents analysis",
    PERSONAL_AUSWERTUNGEN_DOWNLOAD: "Download employee documents analysis",
    PERSONAL_DATEN: "Employee data",
    PERSONAL_DEAKTIVIEREN: "Deactivate employee",
    PERSONAL_DOKUMENT_FILTERN: "Filter by document or employee",
    PERSONAL_ERSTELLEN: "Create employee",
    PERSONAL_EINGEBEN: "Please enter a name of employee",
    PERSONAL_ERFOLGREICH_GELOESCHT: {
        BODY: "What do you want to do next?",
        NAVIGATIONS_TEIL1: "Create new employeee",
        NAVIGATIONS_TEIL2: "Company overview",
        NAVIGATIONS_TEIL3: "Dashboard organisation",
        NAVIGATIONS_TEIL4: "Dahboard documents",
        NAVIGATIONS_TEIL5: "Dashboard construction site",
        NAVIGATIONS_TEIL6: "Go back",
        TITEL: "Employee was deleted successfully",
    },
    PERSONAL_FILTERN: "Filter employees",
    PERSONAL_WURDE_ERFOLGREICH_AKTIVIERT: "Employee has been successfully activated",
    PERSONAL_WURDE_ERFOLGREICH_DEAKTIVIERT: "Employee has been successfully deactivated",
    PERSONAL_WURDE_ERFOLGREICH_VERWARNT: "Employee was warned successfully",
    PERSONAL_VERWARNEN: "Warn employees",
    PERSONAL_VERWARNEN_MODAL: "Do you really want to warn the employee?",
    PERSONAL_UEBERSICHT_DOWNLOAD: "Download employee overview",
    PERSONAL_UNTERLAGEN: "Employee documents",
    PERSONAL_UNTERLAGEN_AUSWERTUNGEN: "Employee documents analysis",
    PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DOWNLOAD:
        "Download employee documents analysis",
    PERSONAL_UNTERLAGEN_KONFIGURIEREN: "Employee documents configuration",
    PERSONAL_UNTERLAGEN_PRUEFEN: "Check employee documents",
    PERSONALNAME: "Name of employee",
    PDF_BESTAETIGUNG_WIDERRUFEN: "Revoke PDF file confirmation",
    PDF_DATEI: "PDF file",
    PDF_DATEI_ANZEIGEN: "Show PDF file",
    PDF_DATEI_ARCHIVIEREN: "Archive PDF file",
    PDF_DATEI_HOCHLADEN: "Upload PDF file",
    PDF_DATEI_LOESCHEN: "Permanently delete PDF file",
    PDF_DATEI_VORLAGE_HERUNTERLADEN: "Download PDF file template",
    PDF_ECHTHEIT_BESTAETIGEN: "Confirm PDF file authenticity",
    PLZ: "Zip code",
    PROFILBILD_ERFOLGREICH_HOCHGELADEN: "Profile picture uploaded successfully",
    PROFILBILD_WIRD_HOCHGELADEN: "Profile picture is uploaded",
    PROJEKTZEITEN_AKTIVIERT: "Project times activate",
    PROJEKTZEITEN_DEAKTIVIERT: "Project times deactivate",
    PRUEFE_MINDESTLOHNERKLAERUNG_MODAL: {
        BODY: "Do you really want to mark this minimum wage declaration as checked?",
        BUTTON: "Mark as not checked",
        TITEL: "Check minimum wage declaration",
    },
    PRUEFER: "Tester",
    PRUEFUNG_WIEDERRUFEN: "Cancel check",
    PRUEFVERANTWORTLICHE: "Responsible person",
    PRUEFVERWALTUNG_MAIL: "Mail of responsible person",
    RAUMZONEN: "Space zones",
    RESTLICHE_LAENDER: "Rest of the world",
    RICHTUNG: "Direction",
    SAMSTAG: "Saturday",
    SANITAETER: "Paramedic",
    SIDEBAR: {
        PROJEKTROLLEN: "Create, manage and assign project roles",
        PROJEKTBETEILIGTE: "Invite and manage project participants",
        AUSWERTUNGEN: "View and export project evaluations",
        AUSWERTUNGEN_EINSTELLUNGEN:
            "Manage automatic mail delivery of evaluations",
        ORGANISATION: "Create and manage employees and companies",
        UNTERLAGEN: "Manage companies, employees and minimum wage documents",
        BAUSTELLE:
            "Manage construction site ID card, turnstiles and access points",
        ZUKO_EINSTELLUNGEN: "Manage access control administrative settings",
    },
    SONNTAG: "Sunday",
    SPEICHERN: "Save",
    SPERRE_MODAL: {
        BODY: "Do you really want to block all of the employee's construction site IDs?",
        BUTTON: "Block",
        EINGABE_SPERRGRUND: "Enter the reason for blocking here",
        SPERRGRUND: "Blocking reason",
        TITEL: "Block construction site IDs",
    },
    SPERREN: "Block",
    SPERRUNG_AM: ", Block on the {{stichtag}} every month",
    SPRACHE: "Language",
    STAATSANGEHOERIGKEIT: "Nationality",
    STAATSANGEHOERIGKEIT_AUSWAEHLEN: "Select nationality",
    STAMMDATEN: "Base data",
    STAMMDATEN_AKTUALISIEREN: "Update master data",
    STAMMDATEN_AKTUALISIERT: "Master data updated",
    STAMMDATEN_ERFOLGREICH_AKTUALISIERT: "Master data updated successfully",
    STARTDATUM: "Startdate",
    STARTDATUM_VOR_ENDDATUM: "The start date cannot be later than the end date",
    STATUS: "Status",
    STICHTAG_AM: "Deadline of",
    STRASSE: "Street",
    SYNCHRONISIERE_ZUTRITTSEREIGNISSE: "Synchronizing access events",
    SYNCHRONISIERT: "Synchronized",
    SYSTEMGUELTIGKEIT: "System validity until",
    SYSTEMVORLAGE: "System template",
    TAG: "Day",
    TOAST_NEUER_STATUS_ZUTRITTSZEITEN_AKTIVIERT:
        "Status of access events was succesfully updated. New status: activated",
    TOAST_NEUER_STATUS_ZUTRITTSZEITEN_DEAKTIVIERT:
        "Status of access events was succesfully updated. New status: deaktivated",
    TOAST_NEUER_STATUS_BILDVERIFIZIERUNG_AKTIVIERT: "Status of image verification was succesfully updated. New status: activated",
    TOAST_NEUER_STATUS_BILDVERIFIZIERUNG_DEAKTIVIERT: "Status of image verification was succesfully updated. New status: deactivated",
    TOOLTIP_BAUSTELLENAUSWEISE: "Synced / Locked / Total",
    TRENNEN: "Disconnect",
    TYP: "Type",
    UEBERSICHT: "Overview",
    UEBERSICHT_EINGEBENER_DATEN: "Overview of your entered data",
    UHRZEIT: "Timestamp",
    UNBEFRISTET: "unlimited",
    UNBEGRENZT: "Unlimited",
    UNERREICHBAR: "unreachable",
    UNTERLAGEN: "Documents",
    UNTERLAGEN_AUSWERTUNGEN: "Evaluations of documents",
    UNTERLAGEN_AUSWERTUNGEN_TEIL1: "Evaluations of",
    UNTERLAGEN_AUSWERTUNGEN_TEIL2: "documents",
    UNTERLAGEN_DATEN: "Documents data",
    UNTERLAGEN_HINZUFUEGEN: "Add documents",
    UNTERLAGEN_NAME: "Document name",
    UNTERLAGE_ENTPRUEFT_MARKIEREN: "Document marked as not checked",
    UNTERLAGE_ERFOLGREICH_ENTPRUEFT:
        "Examination for document was successfully revoked",
    UNTERLAGE_ERFOLGREICH_GELOESCHT: "Document was succesfully remove",
    UNTERLAGE_ERFOLGREICH_WIEDERHERGESTELLT: "Document was succesfully revert",
    UNTERLAGE_GEPRUEFT_MARKIEREN: "Document marked as checked",
    UNTERLAGE_HINZUFUEGEN: "Add Document",
    UNTERLAGE_HINZUGEFUEGT: "Added Document",
    UNTERLAGEN_MODAL_HINZUFUEGEN: {
        DATEI_HINZUFUEGEN: "Add file",
        GUELTIGKEIT: "Validity",
        NAME_EINGEBEN: "Please enter a name",
    },
    VERBINDEN: "Connect",
    VERBINDUNG: "Connection",
    VERBINDUNG_MIT_DEM_SERVER: "Connected to the server",
    VERBINDUNG_TESTEN: "Test connection",
    VERLAENGERUNGSGRUND: "Extension reason",
    VERWALTER: "Administrator",
    VERWALTER_HINZUFUEGEN: "Add administrator",
    VERWENDUNG_IN_BERECHTIGUNGSGRUPPEN: "Use in authorization groups",
    VON: "by",
    VORNAME: "First name",
    VORHANDEN: "present",
    VORLAGE: "Template",
    VORLAGE_ANZEIEGEN: "Show template",
    VORLAGE_AKTUALISIEREN: "Update the template",
    VORLAGE_AUSWAEHLEN: "Select template",
    VORLAGE_BEARBEITEN: "Edit template",
    VORLAGE_EROFLGREICH_AKTUALISIERT: "Vorlage wurde erfolgreich aktualisiert",
    VORLAGE_EROFLGREICH_ERSTELLT: "Vorlage wurde erfolgreich erstellt",
    VORLAGE_EROFLGREICH_GELOESCHT: "Vorlage wurde erfolgreich gelöscht",
    VORLAGE_LOESCHEN: "Delete template",
    VORLAGE_SPEICHERN: "Save template",
    VORLAGEN: "Templates",
    VORLAGENNAME: "Templatename",
    VORLAGE_AUSWAEHLEN: "Select template",
    WEITERE_MITARBEITERUNTERLAGE_HINZUGEFUEGT:
        "Additional employee documentation was successfully added.",
    WEITERE_UNTERLAGE_NAME_EXISITIERT: "Exist further document name",
    WEITERE_UNTERLAGEN: "More documents",
    WEITERE_UNTERLAGEN_GETRENNT: "Further <br> documents",
    WEITERE_UNTERLAGEN_HINZUFUEGEN: "Add further documents",
    WEITERE_UNTERLAGE_HINZUFUEGEN: "Add another document",
    WERKVERTRAGSARBEITNEHMERKARTE: "Work contract employee card",
    WERKVERTRAGSARBEITNEHMERKARTE_NICHT_VOLLSTAENDIG:
        "Work contract employee card is not complete",
    WEVAK_PFLICHT: "WeVAK requirement",
    WEVAK_UNTERLAGEN: "WeVAK documents",
    WHITELIST: "Ausgenommene Firmenliste",
    WIEDERHERSTELLEN: "Revert documents",
    WIRD_NACHGEREICHT: "will be given later",
    WURDE_NICHT_GEPRUEFT: "was not checked",
    WURDE_NICHT_HOCHGELADEN: "wasn't uploaded",
    ZEITABSCHNITTE: "Periods of time",
    ZEITINTERVALL: "Interval of time",
    ZEITSTEMPEL: "Time stamp",
    ZEITZONE_AKTUALISIERT: "Time zone updated successfully",
    ZEITZONE_AKTUALISIEREN: "Update time zone",
    ZEITZONE_AUSWAEHLEN: "Select time zone",
    ZEITZONE_HINZUFUEGEN: "Add time zone",
    ZEITZONE_HINZUGEFUEGT: "Time zone was successfully added",
    ZEITZONE_GELOESCHT: "Time zone was successfully deleted",
    ZEITZONE_ID: "Time zone ID",
    ZEITZONE_LOESCHEN: "Delete time zone",
    ZEITZONE_SYNCHRONISIERT: "Synced time zones",
    ZEITZONE_WIRKLICH_LOESCHEN: "Do you really want to delete the time zone?",
    ZEITZONEN: "Time zones",
    ZEITZONEN_LEER: "No time zones",
    ZEITZONEN_NAME_SYNCHRONISIERT: "Time zone name already exists!",
    ZEITZONEN_VERWALTEN: "Manage time zones",
    ZEITZONEN_VERWALTEN_TEIL1: "Manage",
    ZEITZONEN_VERWALTEN_TEIL2: "time zones",
    ZU_DEM_AUSWEIS_DESIGNER: "To the Id card designer",
    ZU_DEM_AUSWEISGUELTIGKEIT_KONFIGURATOR:
        "To the Id card validity configurator",
    ZU_DEM_BAUSTELLENAUSWEIS: "To the construction site IDs {{(mitarbeiter)}}",
    ZU_DEM_MINDESTLOHNKONFIGURATOR: "To the minimum wage configurator",
    ZU_DEM_PERSONAL: "To the employees",
    ZU_DEN_BAUSTELLENAUSWEISEN: "To the construction site IDs",
    ZU_DEN_BERECHTIGUNGSGRUPPEN: "To the authorization groups",
    ZU_DEN_FIRMEN: "To the companies",
    ZU_DEN_FIRMEN_AUSWERTUNGEN: "To the company evaluations",
    ZU_DEN_FIRMEN_UNTERLAGEN: "To the company documents {{firma}}",
    ZU_DEN_FIRMENBEZIEHUNGEN: "To the company relations",
    ZU_DEN_MINDESTLOHNERKLAERUNGEN: "To the minimum wage declarations",
    ZU_DEN_MITARBEITERN: "To the employees",
    ZU_DEN_PERSONAL_UNTERLAGEN: "To the employee documents {{mitarbeiter}}",
    ZU_DEN_PERSONALAUSWERTUNGEN: "To the employee analysis",
    ZU_DEN_STAMMDATEN: "To the master data",
    ZU_DEN_UNTERLAGEN: "To the documents",
    ZU_DEN_VERWALTERN: "To the administrators",
    ZU_DEN_ZEITZONEN: "To the time zones",
    ZU_DEN_ZUKO_SERVERN: "To the Zuko-Server",
    ZU_DEN_ZUTRITTSEREIGNIS_AUSWERTUNGEN: "To the attendance analysis",
    ZU_DEN_ZUTRITTSPUNKTEN: "To the access points",
    ZU_DER_AKTUELLEN_BESETZUNG: "To the current settlement",
    ZU_DER_BILDVERIFIZIERUNG: "To the image verification",
    ZU_DER_FIRMA: "To the company {{firma}}",
    ZU_DER_FIRMENHIERACHIE: "To the company hierarchy",
    ZUKO_KONFIGURIEREN: "Configure ZuKo",
    ZUKO_SERVER: "ZuKo Server",
    ZUKO_SERVER_AUSWAEHLEN: "Select ZuKo Server",
    ZUKO_SERVER_ERFOLGREICH_HINZUGEFUEGT: "ZuKo Server was added successfully",
    ZUKO_SERVER_ERFOLGREICH_KONFIGURIERT:
        "ZuKo has been successfully configured",
    ZUKO_SERVER_HINZUFUEGEN: "Add ZuKo Server",
    ZUKO_SERVER_KONFIGURIEREN: "Configure ZuKo Server",
    ZUTRITTSEREIGNISSE: "Access events",
    ZUTRITTSEREIGNISSE_SYNCHRONISIERT:
        "{{input}} access events have been synchronized.",
    ZUTRITTSPUNKT: "Entry point",
    ZUTRITTSPUNKT_AKTUALISIEREN: "Update access point",
    ZUTRITTSPUNKT_AKTUALISIERT: "Access point has been successfully added",
    ZUTRITTSPUNKT_GELOESCHT: "Access point has been successfully deleted",
    ZUTRITTSPUNKT_HINZUFUEGEN: "Add access point",
    ZUTRITTSPUNKT_RICHTUNG: "Access point - direction",
    ZUTRITTSPUNKTE_LEER: "No access points",
    ZUTRITTSPUNKT_LOESCHEN: "Delete access point",
    ZUTRITTSPUNKT_NAME_AKTUALISIEREN: "Update access point name",
    ZUTRITTSPUNKT_NAME_AKTUALISIERT: "Access point name updated successfully",
    ZUTRITTSPUNKTE: "Entry points",
    ZUTRITTSPUNKTE_VERWALTEN: "Manage access points",
};
export default TRANSLATIONS_EN_ZUKO;
