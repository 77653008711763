const apiHost = window.Algo.apiHost,
appInsights = window.Algo.appInsights;

export const erstelleContainer = async (formData, language, projektId) => {
    appInsights.startTrackEvent("erstelleContainer");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/erstelle-container`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("erstelleContainer", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("erstelleContainer", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("erstelleContainer", {
            success: json.success,
        });
        return json;
    }
};

export const aktualisiereContainer = async (formData, language, projektId) => {
    appInsights.startTrackEvent("aktualisiereContainer");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/aktualisiere-container`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("aktualisiereContainer", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("aktualisiereContainer", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("aktualisiereContainer", {
            success: json.success,
        });
        return json;
    }
};

export const fetchViewContainerData = async (language, projektId) => {
    appInsights.startTrackEvent("fetchViewContainerData");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/container-view-data`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchViewContainerData", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchViewContainerData", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchViewContainerData", {
            success: json.success,
        });
        return json;
    }
};

export const archiviereContainer = async (language, projektId, formData) => {
    appInsights.startTrackEvent("archiviereContainer");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/archiviere-container`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("archiviereContainer", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("archiviereContainer", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("archiviereContainer", {
            success: json.success,
        });
        return json;
    }
};

export const fetchContainerBild = async (sprache, projektId, containerId) => {
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/download-container-bild/?containerId=${containerId}`,
        {
            method: "GET",
        }
    );
    return response;
};
