export const FETCH_MONATAUSWERTUNGEN_RESET_SUCCESS =
    "FETCH_MONATAUSWERTUNGEN_RESET_SUCCESS";
export const DASHBOARD_MONATSAUSWERTUNGEN_ACTION_FAIL =
    "DASHBOARD_MONATSAUSWERTUNGEN_ACTION_FAIL";
//#region neu ausgestellte Ausweise
export const MONATAUSWERTUNG_NEU_AUSGESTELLTE_AUSWEISE_ACTION_BEGIN =
    "MONATAUSWERTUNG_NEU_AUSGESTELLTE_AUSWEISE_ACTION_BEGIN";
export const FETCH_MONATSAUSWERTUNG_NEU_AUSGESTELLTE_AUSWEISE_SUCCESS =
    "FETCH_MONATSAUSWERTUNG_NEU_AUSGESTELLTE_AUSWEISE_SUCCESS";
//#endregion

//#region Aktive Ausweise gesamt
export const MONATSAUSWERTUNG_AKTIVE_AUSWEISE_GESAMT_ACTION_BEGIN =
    "MONATSAUSWERTUNG_AKTIVE_AUSWEISE_GESAMT_ACTION_BEGIN";
export const FETCH_MONATSAUSWERTUNG_AKTIVE_AUSWEISE_GESAMT_SUCCESS =
    "FETCH_MONATSAUSWERTUNG_AKTIVE_AUSWEISE_GESAMT_SUCCESS";
//#endregion

//#region gesperrte Mitarbeiter gesamt
export const MONATSAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_ACTION_BEGIN =
    "MONATSAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_ACTION_BEGIN";
export const FETCH_MONATSAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_SUCCESS =
    "FETCH_MONATSAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_SUCCESS";
//#endregion

//#region Anwesenheit im Monatsverlauf
export const MONATSAUSWERTUNG_ANWESENHEIT_MONATSVERLAUF_ACTION_BEGIN =
    "MONATSAUSWERTUNG_ANWESENHEIT_MONATSVERLAUF_ACTION_BEGIN ";
export const FETCH_MONATSAUSWERTUNG_ANWESENHEIT_MONATSVERLAUF_SUCCESS =
    "FETCH_MONATSAUSWERTUNG_ANWESENHEIT_MONATSVERLAUF_SUCCESS";
//#endregion

//#region Sperrgründe
export const MONATSAUSWERTUNG_SPERRGRUENDE_MONATSVERLAUF_ACTION_BEGIN =
    "MONATSAUSWERTUNG_SPERRGRUENDE_MONATSVERLAUF_ACTION_BEGIN";
export const FETCH_MONATSAUSWERTUNG_SPERRGRUENDE_MONATSVERLAUF_SUCCESS =
    "FETCH_MONATSAUSWERTUNG_SPERRGRUENDE_MONATSVERLAUF_SUCCESS";
//#endregion

//#region Anwesenheit Mitarbeitende Top 5 Firmen
export const MONATSAUSWERTUNG_ANWESENHEIT_MITARBEITENDE_FIRMEN_ACTION_BEGIN =
    "MONATSAUSWERTUNG_ANWESENHEIT_MITARBEITENDE_FIRMEN_ACTION_BEGIN";
export const FETCH_MONATSAUSWERTUNG_ANWESENHEIT_MITARBEITENDE_FIRMEN_SUCCESS =
    "FETCH_MONATSAUSWERTUNG_ANWESENHEIT_MITARBEITENDE_FIRMEN_SUCCESS";
//#endregion
import { showToast } from "../algo-app";
import errorHandler from "../errorHandling.js";
import { translate } from "../../../../src/i18n.js";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;

let abortNeuAusgestellteAusweise,
    abortAktiveAuswesieGesamt,
    abortGesperrteAusweiseGesamt,
    abortAnwesenheitMonatsverlauf,
    abortSperrgruendeMonatsverlauf,
    abortAnwesenheitMitarbeitendeFirmen;

export const cancelMonatsRequests = () => (dispatch) => {
    try {
        abortNeuAusgestellteAusweise?.abort(),
            abortAktiveAuswesieGesamt?.abort(),
            abortGesperrteAusweiseGesamt?.abort(),
            abortAnwesenheitMonatsverlauf?.abort(),
            abortSperrgruendeMonatsverlauf?.abort(),
            abortAnwesenheitMitarbeitendeFirmen?.abort();
    } catch (err) {
        console.log(err);
    }
    dispatch({ type: FETCH_MONATAUSWERTUNGEN_RESET_SUCCESS });
    appInsights.trackEvent({ name: "cancelTagesRequests" });
};

export const fetchMonatsauswertungNeuAusgestellteAusweise =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchMonatsauswertungNeuAusgestellteAusweise"
        );
        dispatch({
            type: MONATAUSWERTUNG_NEU_AUSGESTELLTE_AUSWEISE_ACTION_BEGIN,
        });
        abortNeuAusgestellteAusweise =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-monatsauswertung-ausgestellte-baustellenausweise/?startDatum=${data.startDatum}&endDatum=${data.endDatum}`,
            { method: "GET", signal: abortNeuAusgestellteAusweise.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: DASHBOARD_MONATSAUSWERTUNGEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchMonatsauswertungNeuAusgestellteAusweise",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: DASHBOARD_MONATSAUSWERTUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchMonatsauswertungNeuAusgestellteAusweise",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_MONATSAUSWERTUNG_NEU_AUSGESTELLTE_AUSWEISE_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchMonatsauswertungNeuAusgestellteAusweise",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchMonatsauswertungAktiveAusweiseGesamt =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchMonatsauswertungAktiveAusweiseGesamt"
        );
        dispatch({
            type: MONATSAUSWERTUNG_AKTIVE_AUSWEISE_GESAMT_ACTION_BEGIN,
        });
        abortAktiveAuswesieGesamt =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-monatsauswertung-aktive-baustellenausweise/?startDatum=${data.startDatum}&endDatum=${data.endDatum}`,
            { method: "GET", signal: abortAktiveAuswesieGesamt.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: DASHBOARD_MONATSAUSWERTUNGEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchMonatsauswertungAktiveAusweiseGesamt",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: DASHBOARD_MONATSAUSWERTUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchMonatsauswertungAktiveAusweiseGesamt",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_MONATSAUSWERTUNG_AKTIVE_AUSWEISE_GESAMT_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchMonatsauswertungAktiveAusweiseGesamt",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchMonatsauswertungGesperrteAusweiseGesamt =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchMonatsauswertungGesperrteAusweiseGesamt"
        );
        dispatch({
            type: MONATSAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_ACTION_BEGIN,
        });
        abortGesperrteAusweiseGesamt =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-monatsauswertung-anzahl-gesperrter-mitarbeiter/?startDatum=${data.startDatum}&endDatum=${data.endDatum}`,
            { method: "GET", signal: abortGesperrteAusweiseGesamt.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: DASHBOARD_MONATSAUSWERTUNGEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchMonatsauswertungGesperrteAusweiseGesamt",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: DASHBOARD_MONATSAUSWERTUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchMonatsauswertungGesperrteAusweiseGesamt",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_MONATSAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchMonatsauswertungGesperrteAusweiseGesamt",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchMonatsauswertungAnwesenheitMonatsverlauf =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchMonatsauswertungAnwesenheitMonatsverlauf"
        );
        dispatch({
            type: MONATSAUSWERTUNG_ANWESENHEIT_MONATSVERLAUF_ACTION_BEGIN,
        });
        abortAnwesenheitMonatsverlauf =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-monatsauswertung-anwesenheit-pro-tag/?startDatum=${data.startDatum}&endDatum=${data.endDatum}`,
            { method: "GET", signal: abortAnwesenheitMonatsverlauf.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: DASHBOARD_MONATSAUSWERTUNGEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchMonatsauswertungAnwesenheitMonatsverlauf",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: DASHBOARD_MONATSAUSWERTUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchMonatsauswertungAnwesenheitMonatsverlauf",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_MONATSAUSWERTUNG_ANWESENHEIT_MONATSVERLAUF_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchMonatsauswertungAnwesenheitMonatsverlauf",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchMonatsauswertungSperrgruendeMonatsverlauf =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchMonatsauswertungSperrgruendeMonatsverlauf"
        );
        dispatch({
            type: MONATSAUSWERTUNG_SPERRGRUENDE_MONATSVERLAUF_ACTION_BEGIN,
        });
        abortSperrgruendeMonatsverlauf =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-monatsauswertung-sperrgruende/?startDatum=${data.startDatum}&endDatum=${data.endDatum}&anzahl=${data.anzahl}`,
            { method: "GET", signal: abortSperrgruendeMonatsverlauf.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: DASHBOARD_MONATSAUSWERTUNGEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchMonatsauswertungSperrgruendeMonatsverlauf",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: DASHBOARD_MONATSAUSWERTUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchMonatsauswertungSperrgruendeMonatsverlauf",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_MONATSAUSWERTUNG_SPERRGRUENDE_MONATSVERLAUF_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchMonatsauswertungSperrgruendeMonatsverlauf",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchMonatsauswertungAnwesenheitMitarbeitendeFirmen =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchMonatsauswertungAnwesenheitMitarbeitendeFirmen"
        );
        dispatch({
            type: MONATSAUSWERTUNG_ANWESENHEIT_MITARBEITENDE_FIRMEN_ACTION_BEGIN,
        });
        abortAnwesenheitMitarbeitendeFirmen =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-monatsauswertung-durchschnittliche-anwesenheit-firmen/?startDatum=${data.startDatum}&endDatum=${data.endDatum}&anzahl=${data.anzahl}`,
            {
                method: "GET",
                signal: abortAnwesenheitMitarbeitendeFirmen.signal,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: DASHBOARD_MONATSAUSWERTUNGEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchMonatsauswertungAnwesenheitMitarbeitendeFirmen",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: DASHBOARD_MONATSAUSWERTUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchMonatsauswertungAnwesenheitMitarbeitendeFirmen",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_MONATSAUSWERTUNG_ANWESENHEIT_MITARBEITENDE_FIRMEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchMonatsauswertungAnwesenheitMitarbeitendeFirmen",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const exportDashboardMonatsauswertung = (data) => (dispatch) => {
    dispatch(showToast({ text: translate("DASHBOARD.EXPORT_TOAST") }));
    const a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-monatsauswertung-export/?projektName=${data.projektName}&startDatum=${data.startDatum}&endDatum=${data.endDatum}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "exportDashboardMonatsauswertung" });
};
