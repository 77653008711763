export const PROJEKTROLLE_ACTION_FAIL = "PROJEKTROLLE_ACTION_FAIL";
export const PROJEKTROLLE_ACTION_BEGIN = "PROJEKTROLLE_ACTION_BEGIN";
export const FETCH_PROJEKTROLLE_SUCCESS = "FETCH_PROJEKTROLLE_SUCCESS";
export const RESET_PROJEKTROLLE_SUCCESS = "RESET_PROJEKTROLLE_SUCCESS";
export const LOESCHE_PROJEKTROLLE_COMMAND_SUCCESS =
  "LOESCHE_PROJEKTROLLE_COMMAND_SUCCESS";
export const AKTUALISIERE_PROJEKTROLLE_COMMAND_SUCCESS =
  "AKTUALISIERE_PROJEKTROLLE_COMMAND_SUCCESS";

import errorHandler from "../errorHandling.js";

const apiHost = window.Algo.apiHost,
  appInsights = window.Algo.appInsights,
  getRequestOptions = window.Algo.getRequestOptions;

export const resetProjektrolle = () => (dispatch) => {
  dispatch({ type: RESET_PROJEKTROLLE_SUCCESS });
  appInsights.trackEvent({ name: "resetProjektrolle" });
};

export const fetchProjektrolle = (data) => async (dispatch) => {
  appInsights.startTrackEvent("fetchProjektrolle");
  dispatch({ type: PROJEKTROLLE_ACTION_BEGIN });
  const response = await fetch(
    `${apiHost}/api/projekt/v2/${data.projektId}/${data.projektrolleId}/projektrolle.json/`,
    getRequestOptions
  );
  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(error));
  }
  if (!response.ok) {
    if (json && !json.success) {
      dispatch(errorHandler(json));
      dispatch({ type: PROJEKTROLLE_ACTION_FAIL, error: json });
      appInsights.stopTrackEvent("fetchProjektrolle", {
        success: json.success,
        error: json,
      });
    } else {
      dispatch(errorHandler(response));
      dispatch({ type: PROJEKTROLLE_ACTION_FAIL, error: response });
      appInsights.stopTrackEvent("fetchProjektrolle", {
        success: response.ok,
        error: response,
      });
    }
  }
  if (json?.success) {
    dispatch({ type: FETCH_PROJEKTROLLE_SUCCESS, payload: json.payload });
    appInsights.stopTrackEvent("fetchProjektrolle", { success: json.success });
  }
};

export const loescheProjektrolleCommand =
  (data, projektId) => async (dispatch) => {
    appInsights.startTrackEvent("loescheProjektrolleCommand");
    dispatch({ type: PROJEKTROLLE_ACTION_BEGIN });
    const response = await fetch(
      `${apiHost}/api/projekt/${projektId}/loesche-projektrolle/`,
      {
        method: "POST",
        body: data,
      }
    );
    let json;
    try {
      json = await response.json();
    } catch (error) {
      dispatch(errorHandler(error));
    }
    if (!response.ok) {
      if (json && !json.success) {
        dispatch({ type: PROJEKTROLLE_ACTION_FAIL, error: json });
        appInsights.stopTrackEvent("loescheProjektrolleCommand", {
          success: json.success,
          error: json,
        });
      } else {
        dispatch({ type: PROJEKTROLLE_ACTION_FAIL, error: response });
        appInsights.stopTrackEvent("loescheProjektrolleCommand", {
          success: response.ok,
          error: response,
        });
      }
    }
    if (json?.success) {
      dispatch({
        type: LOESCHE_PROJEKTROLLE_COMMAND_SUCCESS,
        payload: json.payload,
      });
      appInsights.stopTrackEvent("loescheProjektrolleCommand", {
        success: json.success,
      });
      return json.payload;
    }
  };

export const aktualisiereProjektrolleCommand =
  (formData, projektId) => async (dispatch) => {
    appInsights.startTrackEvent("aktualisiereProjektrolleCommand");
    dispatch({ type: PROJEKTROLLE_ACTION_BEGIN });
    const response = await fetch(
      `${apiHost}/api/projekt/${projektId}/aktualisiere-projektrolle/`,
      {
        method: "POST",
        body: formData,
      }
    );
    let json;
    try {
      json = await response.json();
    } catch (error) {
      dispatch(errorHandler(error));
    }
    if (!response.ok) {
      if (json && !json.success) {
        dispatch(errorHandler(json));
        dispatch({ type: PROJEKTROLLE_ACTION_FAIL, error: json });
        appInsights.stopTrackEvent("aktualisiereProjektrolleCommand", {
          success: json.success,
          error: json,
        });
      } else {
        dispatch(errorHandler(response));
        dispatch({ type: PROJEKTROLLE_ACTION_FAIL, error: response });
        appInsights.stopTrackEvent("aktualisiereProjektrolleCommand", {
          success: response.ok,
          error: response,
        });
      }
    }
    if (json?.success) {
      dispatch({
        type: AKTUALISIERE_PROJEKTROLLE_COMMAND_SUCCESS,
        payload: json.payload,
      });
      appInsights.stopTrackEvent("aktualisiereProjektrolleCommand", {
        success: json.success,
      });
      return json.payload;
    }
  };
