import {
    ERSTELLE_PROJEKTROLLE_COMMAND_SUCCESS,
    PROJEKT_PROJEKTROLLE_ERSTELLEN_VIEW_DATA_ACTION_FAIL,
    PROJEKT_PROJEKTROLLE_ERSTELLEN_VIEW_DATA_ACTION_BEGIN,
    PROJEKT_EXISTIERT_PROJEKTROLLE_ACTION_BEGIN,
    PROJEKT_EXISTIERT_PROJEKTROLLE_ACTION_FAIL,
    PROJEKT_EXISTIERT_PROJEKTROLLE_SUCCESS,
} from "../../actions/projekt/projekt-projektrolle-erstellen-view-data.js";

const projektProjektrolleErstellenViewDataState = {
    error: null,
    loading: false,
    projektrolleId: "",
};

const savedState =
    localStorage.getItem("savedState") ||
    JSON.stringify({ ...projektProjektrolleErstellenViewDataState });
const INITIAL_STATE =
    JSON.parse(savedState).projektProjektrolleErstellenViewDataState ||
    projektProjektrolleErstellenViewDataState;

const projektProjektrolleErstellenViewData = (
    state = INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case ERSTELLE_PROJEKTROLLE_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                projektrolleId: action.payload,
            };
        case PROJEKT_PROJEKTROLLE_ERSTELLEN_VIEW_DATA_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case PROJEKT_PROJEKTROLLE_ERSTELLEN_VIEW_DATA_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case PROJEKT_EXISTIERT_PROJEKTROLLE_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case PROJEKT_EXISTIERT_PROJEKTROLLE_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case PROJEKT_EXISTIERT_PROJEKTROLLE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        default:
            return state;
    }
};

export default projektProjektrolleErstellenViewData;
