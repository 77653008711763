export const TRANSLATIONS_EN_GEWERKE =  {
    ABBRUCHARBEITEN: "Demolition work",
    AUFZUGBAUER: "Elevator builder",
    AUSBAU: "Expansion",
    AUSSENANLAGEN: "Outdoor facilities",
    BAUAUFZUG: "Construction elevator",
    BAUHERR: "Builder",
    BAULEITUNG: "Site management",
    BAULOGISTIK: "Construction logistics",
    BAUSTROM__BAUWASSER: "Site electricity / site water",
    BETON__UND_STAHLBAUER: "Concrete and steel workers",
    BLITZSCHUTZ: "Lightning protection",
    BMA__ELA: "BMA / ELA",
    BODENLEGER: "Floor layer",
    BRANDSCHUTZ: "Fire Protection",
    DACHDECKER: "Roof Decker",
    DOPPELBODEN: "Raised floor",
    EISENFLECHTER: "Iron weaver",
    ELEKTRO: "Electro",
    ESTRICHARBEITEN: "Screed work",
    FASSADE: "Facade",
    FENSTER_UND_TÜRBAUER: "Window and door builders",
    FLIESENLEGER: "Tiler",
    GENERALUNTERNEHMER: "General contractor",
    GERÜSTBAUER: "Scaffolder",
    HEIZUNG: "Heater",
    HOHLRAUMBODEN: "Cavity floor",
    HOLZBAU: "Timber construction",
    KORROSIONS__UND_OBERFLÄCHENSCHUTZ:"Corrosion and surface protection",
    LABORTECHNIK: "Laboratory technology", 
    LÜFTUNGSANLAGEN: "Ventilation systems",
    MALER: "Painter",
    MAURER: "Mason",
    METALLBAU: "Metal construction",
    MSR__GEBÄUDEAUTOMATION: "MSR / building automation",
    PLANER: "Planner",
    PUTZER: "Cleaner",
    RLT: "RLT",
    ROHBAU: "Shell",
    RESTAURATOR: "Restorer",
    SANITÄR: "Sanitary",
    SCHLOSSER: "Locksmith",
    SCHOTTUNG: "Containment",
    SICHERHEIT: "Safety",
    SPEZIALTIEFBAU: "Foundation engineering",
    SPRINKLER: "Sprinkler",
    STAHLTÜRENBAUER: "Steel door builder",
    STEINMETZ: "Stonemason",
    TEPPICHLEGER: "Carpet fitter",
    TGA: "TGA",
    TIEFGARAGENDÄMMUNG: "Garage insulation",
    TISCHLERARBEITEN: "Carpentry",
    TROCKENBAU: "Drywall",
    VERMESSER: "Surveyor",
    WDVS: "WDVS",
    SONSTIGE: "Other",
};
export default TRANSLATIONS_EN_GEWERKE;