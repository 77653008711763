import errorHandler from "./errorHandling";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const ABMELDEN_SUCCESS = "ABMELDEN_SUCCESS";
export const REGISTRIEREN_SUCCESS = "REGISTRIEREN_SUCCESS";
export const IDENTITY_ACTION_FAIL = "IDENTITY_ACTION_FAIL";
export const IDENTITY_ACTION_BEGIN = "IDENTITY_ACTION_BEGIN";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const FETCH_BENUTZERPROFIL_SUCCESS = "FETCH_BENUTZERPROFIL_SUCCESS";
export const GENERATE_PASSWORD_RESET_TOKEN_SUCCESS =
  "GENERATE_PASSWORD_RESET_TOKEN_SUCCESS";

const apiHost = window.Algo.apiHost,
  appInsights = window.Algo.appInsights,
  getRequestOptions = window.Algo.getRequestOptions,
  postRequestOptions = window.Algo.postRequestOptions;

export const abmelden = () => async (dispatch) => {
  appInsights.startTrackEvent("abmelden");
  dispatch({ type: IDENTITY_ACTION_BEGIN });
  const response = await fetch(
    `${apiHost}/api/identity/v3/abmelden/`,
    postRequestOptions
  );
  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(error));
  }
  if (!response.ok) {
    if (json && !json.success) {
      dispatch(errorHandler(json));
      dispatch({ type: IDENTITY_ACTION_FAIL, error: json });
      appInsights.stopTrackEvent("abmelden", {
        success: json.success,
        error: json,
      });
    } else {
      dispatch(errorHandler(response));
      dispatch({ type: IDENTITY_ACTION_FAIL, error: response });
      appInsights.stopTrackEvent("abmelden", {
        success: response.ok,
        error: response,
      });
    }
  }
  if (json?.success) {
    dispatch({ type: ABMELDEN_SUCCESS, payload: json.payload });
    appInsights.stopTrackEvent("abmelden", { success: json.success });
  }
};

export const anmelden = (data) => async (dispatch) => {
  appInsights.startTrackEvent("anmelden");
  dispatch({ type: IDENTITY_ACTION_BEGIN });
  const response = await fetch(`${apiHost}/api/identity/v3/anmelden/`, {
    method: "POST",
    body: data,
  });
  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(error));
  }
  if (!response.ok) {
    if (json && !json.success) {
      dispatch(errorHandler(json));
      dispatch({ type: IDENTITY_ACTION_FAIL, error: json });
      appInsights.stopTrackEvent("anmelden", {
        success: json.success,
        error: json,
      });
    } else {
      dispatch({ type: IDENTITY_ACTION_FAIL, error: response });
      appInsights.stopTrackEvent("anmelden", {
        success: response.ok,
        error: response,
      });
    }
  }
  if (json?.success) {
    dispatch({ type: LOGIN_SUCCESS, payload: json.payload });
    appInsights.stopTrackEvent("anmelden", { success: json.success });
  }
};

export const registrieren = (data) => async (dispatch) => {
  appInsights.startTrackEvent("registrieren");
  dispatch({ type: IDENTITY_ACTION_BEGIN });
  const response = await fetch(`${apiHost}/api/identity/v3/registrieren/`, {
    method: "POST",
    body: data,
  });
  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(error));
  }
  if (!response.ok) {
    if (json && !json.success) {
      dispatch(errorHandler(json));
      dispatch({ type: IDENTITY_ACTION_FAIL, error: json });
      appInsights.stopTrackEvent("registrieren", {
        success: json.success,
        error: json,
      });
    } else {
      dispatch(errorHandler(response));
      dispatch({ type: IDENTITY_ACTION_FAIL, error: response });
      appInsights.stopTrackEvent("registrieren", {
        success: response.ok,
        error: response,
      });
    }
  }
  if (json?.success) {
    dispatch({ type: REGISTRIEREN_SUCCESS, payload: json.payload });
    appInsights.stopTrackEvent("registrieren", { success: json.success });
  }
};

export const kennwortzuruecksetzung = (data) => async (dispatch) => {
  appInsights.startTrackEvent("kennwortzuruecksetzung");
  dispatch({ type: IDENTITY_ACTION_BEGIN });
  const response = await fetch(
    `${apiHost}/api/identity/v3/kennwortzuruecksetzung/`,
    {
      method: "POST",
      body: data,
    }
  );
  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(error));
  }
  if (!response.ok) {
    if (json && !json.success) {
      dispatch(errorHandler(json));
      dispatch({ type: IDENTITY_ACTION_FAIL, error: json });
      appInsights.stopTrackEvent("kennwortzuruecksetzung", {
        success: json.success,
        error: json,
      });
    } else {
      dispatch(errorHandler(response));
      dispatch({ type: IDENTITY_ACTION_FAIL, error: response });
      appInsights.stopTrackEvent("kennwortzuruecksetzung", {
        success: response.ok,
        error: response,
      });
    }
  }
  if (json?.success) {
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: json.payload });
    appInsights.stopTrackEvent("kennwortzuruecksetzung", {
      success: json.success,
    });
  }
};

export const fetchBenutzerprofil = () => async (dispatch) => {
  appInsights.startTrackEvent("fetchBenutzerprofil");
  dispatch({ type: IDENTITY_ACTION_BEGIN });
  const response = await fetch(
    `${apiHost}/api/identity/v3/benutzerprofil/`,
    getRequestOptions
  );
  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(error));
  }
  if (!response.ok) {
    if (json && !json.success) {
      dispatch({ type: IDENTITY_ACTION_FAIL, error: json });
      appInsights.stopTrackEvent("fetchBenutzerprofil", {
        success: json.success,
        error: json,
      });
    } else {
      dispatch({ type: IDENTITY_ACTION_FAIL, error: response });
      appInsights.stopTrackEvent("fetchBenutzerprofil", {
        success: response.ok,
        error: response,
      });
    }
  }
  if (json?.success) {
    dispatch({ type: FETCH_BENUTZERPROFIL_SUCCESS, payload: json.payload });
    appInsights.stopTrackEvent("fetchBenutzerprofil", {
      success: json.success,
    });
  }
};

export const kennwortzuruecksetzungsAnfrage = (data) => async (dispatch) => {
  appInsights.startTrackEvent("kennwortzuruecksetzungsAnfrage");
  dispatch({ type: IDENTITY_ACTION_BEGIN });
  const response = await fetch(
    `${apiHost}/api/identity/v3/kennwortzuruecksetzungs-anfrage`,
    {
      method: "POST",
      body: data,
    }
  );
  let json;
  try{
    json = await response.json();
  }catch(error){
    dispatch(errorHandler(error));
  }
  if (!response.ok) {
    if (json && !json.success) {
      dispatch(errorHandler(json));
      dispatch({ type: IDENTITY_ACTION_FAIL, error: json });
      appInsights.stopTrackEvent("kennwortzuruecksetzungsAnfrage", {
        success: json.success,
        error: json,
      });
    } else {
      dispatch(errorHandler(response));
      dispatch({ type: IDENTITY_ACTION_FAIL, error: response });
      appInsights.stopTrackEvent("kennwortzuruecksetzungsAnfrage", {
        success: response.ok,
        error: response,
      });
    }
  }
  if (json?.success) {
    dispatch({ type: GENERATE_PASSWORD_RESET_TOKEN_SUCCESS });
    appInsights.stopTrackEvent("kennwortzuruecksetzungsAnfrage", {
      success: json.payload,
    });
  }
};
