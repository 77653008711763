export const TRANSLATIONS_DE_DASHBOARD = {
    AKTIVE_AUSWEISE: "Aktive Ausweise gesamt",
    ANWESENHEIT_GESAMT: "Anwesenheit gesamt",
    ANWESENHEIT_IM_MONATSVERLAUF: "Anwesenheit im Monatsverlauf",
    ANWESENHEIT_IM_TAGESABLAUF: "Anwesenheit im Tagesablauf",
    ANWESENHEIT_IM_WOCHENVERLAUF: "Anwesenheit im Wochenverlauf",
    ANWESENHEIT_MITARBEITENE_MO_FR: "Ø-Anwesenheit Mitarbeitende (Mo - Fr)",
    ANWESENHEIT_MITARBEITENE_MO_FR_TOP_5:
    "Ø-Anwesenheit Mitarbeitende Mo - Fr (Top 5 Firmen",
    ANWESENHEIT_MITARBEITENDE_TOP_10:
    "Ø-Anwesenheit Mitarbeitende (Top 10 Firmen)",
    ANWESENHEIT_MITARBEITENDE_TOP_5: "Anwesenheit Mitarbeitende (Top 5 Firmen)",
    ANWESENHEIT_OHNE_WOCHENENDE: "Ø-Anwesenheit (ohne Wochenende)",
    ANZAHL_MITARBEITERNDE: "Anzahl Mitarbeitende",
    APRIL: "April",
    AUGUST: "August",
    AUSWERTUNGEN: "Auswertungen",
    DATEN_IN_KUERZE: "Daten in Kürze verfügbar",
    DATEN_NICHT_VERFUEGBAR: "Keine Daten verfügbar.",
    DECEMBER: "Dezember",
    EINSTELLUNGEN: "Einstellungen",
    EMAIL: "Email-Adresse",
    EXPORT_TOAST:
        "Der Download wurde gestartet und läuft im Hintergrund während Ihrer Tätigkeiten in ALGO weiter. Bitte schließen Sie derweil ALGO nicht.",
    FEBRUARY: "Februar",
    FEHLDRUCKE: "davon {{anzahl}} Fehldrucke ",
    FRIDAY: "Fr",
    GESPERRTE_AUSWEISE_GESAMT: "Gesperrte Ausweise gesamt",
    GESPERRTE_MITARBEITENDE_TOP_10: "Gesperrte Mitarbeitende (Top 10 Firmen)",
    GESPERRTE_MITARBEITER_GESAMT: "Gesperrte Mitarbeiter gesamt",
    JA: "Ja",
    JANUARY: "Januar",
    JUNE: "Juni",
    JULY: "Juli",
    LEAN_ANWESENHEIT: "LEAN Anwesenheit Soll/Ist",
    LEAN_SOLL_ANWESENHEIT: "Sollanwesenheit LEAN",
    KEIN_PROJEKTBETEILIGTER: "Keine Projektbeteiligte Person konfiguriert.",
    KW: "KW",
    MARCH: "März",
    MAY: "Mai",
    MONAT: "Monat",
    MONATS_AUSWERTUNG_EXPORT: "Monatsauswertung exportieren",
    MONDAY: "Mo",
    NEIN: "Nein",
    NEU_AUSGESTELLTE_AUSWEISE: "Neu ausgestellte Ausweise gesamt",
    NOVEMBER: "November",
    OCTOBER: "Oktober",
    PROJEKTBETEILITGEN_BEARBEITEN: "Projektbeteiligte Person aktualisieren",
    PROJEKTBETEILIGTER_HINZUFUEGEN: "Projektbeteiligte Person hinzufügen",
    PROJEKTBETEILIGTER_LOESCHEN: "Projektbeteiligte Person löschen",
    PROJEKTBETEILIGTEN_LOESCHEN_MODAL:
        "Wollen Sie diese projektbeteiligte Person wirklich löschen?",
    TAG: "Tag",
    TAGE: "Tage",
    TAGES_AUSWERTUNG_EXPORT: "Tagesauswertung exportieren",
    THURSDAY: "Do",
    TUESDAY: "Di",
    SATURDAY: "Sa",
    SEPTEMBER: "September",
    SPERRGRUENDE_MONATSVERLAUF_TOP_5:
        "Sperrgründe im Monatsverlauf (Top 5)",
    SPERRGRUENDE_TAGESABLAUF_TOP_5: "Sperrgründe im Tagesablauf (Top 5)",
    SPERRGRUENDE_WOCHENVERLAUF_TOP_5:
        "Sperrgründe im Wochenverlauf (Top 5)",
    SUNDAY: "So",
    WEDNESDAY: "Mi",
    WOCHE: "Woche",
    WOCHEN_AUSWERTUNG_EXPORT: "Wochenauswertung exportieren",
    UHRZEIT: "Uhrzeit",
};
export default TRANSLATIONS_DE_DASHBOARD;
