//#region Mindestlohnerklärungen-Archiv
export const FETCH_RESET_MINDESTLOHNERKLAERUNGEN_ARCHIV_SUCCESS =
    "FETCH_RESET_MINDESTLOHNERKLAERUNGEN_ARCHIV_SUCCESS";
export const FETCH_MINDESTLOHNERKLAERUNGEN_ARCHIV_ACTION_BEGIN =
    "FETCH_MINDESTLOHNERKLAERUNGEN_ARCHIV_ACTION_BEGIN";
export const FETCH_MINDESTLOHNERKLAERUNGEN_ARCHIV_ACTION_FAIL =
    "FETCH_MINDESTLOHNERKLAERUNGEN_ARCHIV_ACTION_FAIL";
export const FETCH_MINDESTLOHNERKLAERUNGEN_ARCHIV_SUCCESS =
    "FETCH_MINDESTLOHNERKLAERUNGEN_ARCHIV_SUCCESS";
//#endregion

//#region Eufefa-Archiv
export const FETCH_RESET_EUFEFA_ARCHIV_SUCCESS =
    "FETCH_RESET_EUFEFA_ARCHIV_SUCCESS";
export const FETCH_EUFEFA_ARCHIV_ACTION_BEGIN =
    "FETCH_EUFEFA_ARCHIV_ACTION_BEGIN";
export const FETCH_EUFEFA_ARCHIV_ACTION_FAIL =
    "FETCH_EUFEFA_ARCHIV_ACTION_FAIL";
export const FETCH_EUFEFA_ARCHIV_SUCCESS = "FETCH_EUFEFA_ARCHIV_SUCCESS";
//#endregion

//#region Eufema-Archiv
export const FETCH_RESET_EUFEMA_ARCHIV_SUCCESS =
    "FETCH_RESET_EUFEMA_ARCHIV_SUCCESS";
export const FETCH_EUFEMA_ARCHIV_ACTION_BEGIN =
    "FETCH_EUFEMA_ARCHIV_ACTION_BEGIN";
export const FETCH_EUFEMA_ARCHIV_ACTION_FAIL =
    "FETCH_EUFEMA_ARCHIV_ACTION_FAIL";
export const FETCH_EUFEMA_ARCHIV_SUCCESS = "FETCH_EUFEMA_ARCHIV_SUCCESS";
//#endregion

//#region Mindestlohnerklärung wieder herstellen
export const MINDESTLOHNERKLAERUNG_WIEDERHERSTELLEN_ACTION_BEGIN =
    "MINDESTLOHNERKLAERUNG_WIEDERHERSTELLEN_ACTION_BEGIN";
export const MINDESTLOHNERKLAERUNG_WIEDERHERSTELLEN_ACTION_FAIL =
    "MINDESTLOHNERKLAERUNG_WIEDERHERSTELLEN_ACTION_FAIL";
export const MINDESTLOHNERKLAERUNG_WIEDERHERSTELLEN_SUCCESS =
    "MINDESTLOHNERKLAERUNG_WIEDERHERSTELLEN_SUCCESS";
//#endregion

//#region Eufefa-Archiv
export const EUFEFA_WIEDERHERSTELLEN_ACTION_BEGIN =
    "EUFEFA_WIEDERHERSTELLEN_ACTION_BEGIN";
export const EUFEFA_WIEDERHERSTELLEN_ACTION_FAIL =
    "EUFEFA_WIEDERHERSTELLEN_ACTION_FAIL";
export const EUFEFA_WIEDERHERSTELLEN_SUCCESS =
    "EUFEFA_WIEDERHERSTELLEN_SUCCESS";
//#endregion

//#region Eufema-Archiv
export const EUFEMA_WIEDERHERSTELLEN_ACTION_BEGIN =
    "EUFEMA_WIEDERHERSTELLEN_ACTION_BEGIN";
export const EUFEMA_WIEDERHERSTELLEN_ACTION_FAIL =
    "EUFEMA_WIEDERHERSTELLEN_ACTION_FAIL";
export const EUFEMA_WIEDERHERSTELLEN_SUCCESS =
    "EUFEMA_WIEDERHERSTELLEN_SUCCESS";
//#endregion

import errorHandler from "../errorHandling";
const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;
let abortMindestlohnerklaerungenArchiv, abortEufefaArchiv, abortEufemaArchiv;

//#region Mindestlohnerklärungen-Archiv
export const cancelArchivMindestlohnerklaerungen = () => (dispatch) => {
    try {
        abortMindestlohnerklaerungenArchiv?.abort();
    } catch (e) {
        console.log(e);
    }
    dispatch({ type: FETCH_RESET_MINDESTLOHNERKLAERUNGEN_ARCHIV_SUCCESS });
    appInsights.trackEvent({ name: "cancelArchiveMindestlohnerklaerungen" });
};

export const fetchArchivMindestlohnerklaerungen =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("fetchArchiveMindestlohnerklaerungen");
        dispatch({
            type: FETCH_MINDESTLOHNERKLAERUNGEN_ARCHIV_ACTION_BEGIN,
        });
        abortMindestlohnerklaerungenArchiv =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/archiv-personal-mindestlohnerklaerungen`,
            {
                method: "GET",
                signal: abortMindestlohnerklaerungenArchiv.signal,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_MINDESTLOHNERKLAERUNGEN_ARCHIV_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent(
                    "fetchArchiveMindestlohnerklaerungen",
                    {
                        success: json.success,
                        error: { errorMessage: json },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_MINDESTLOHNERKLAERUNGEN_ARCHIV_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent(
                    "fetchArchiveMindestlohnerklaerungen",
                    {
                        success: response.ok,
                        error: { errorMessage: response },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_MINDESTLOHNERKLAERUNGEN_ARCHIV_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("fetchArchiveMindestlohnerklaerungen", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const stelleMindestlohnerklaerungWiederHer =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("stelleMindestlohnerklaerungWiederHer");
        dispatch({
            type: MINDESTLOHNERKLAERUNG_WIEDERHERSTELLEN_ACTION_BEGIN,
        });
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${projektId}/stelle-mindestlohnerklaerung-wieder-her`,
            {
                method: "POST",
                body: formData
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            console.log(error);
            dispatch(errorHandler(error));
        }
       
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: MINDESTLOHNERKLAERUNG_WIEDERHERSTELLEN_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent(
                    "stelleMindestlohnerklaerungWiederHer",
                    {
                        success: json.success,
                        error: { errorMessage: json },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: MINDESTLOHNERKLAERUNG_WIEDERHERSTELLEN_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent(
                    "stelleMindestlohnerklaerungWiederHer",
                    {
                        success: response.ok,
                        error: { errorMessage: response },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: MINDESTLOHNERKLAERUNG_WIEDERHERSTELLEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("stelleMindestlohnerklaerungWiederHer", {
                success: json.success,
            });
            return json.payload;
        }
    };
//#endregion

//#region Eufefa-Archiv
export const cancelArchivEufefa = () => (dispatch) => {
    try {
        abortEufefaArchiv?.abort();
    } catch (e) {
        console.log(e);
    }
    dispatch({ type: FETCH_RESET_EUFEFA_ARCHIV_SUCCESS });
    appInsights.trackEvent({ name: "cancelArchivEufefa" });
};

export const fetchArchivEufefa = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchArchivEufefa");
    dispatch({
        type: FETCH_EUFEFA_ARCHIV_ACTION_BEGIN,
    });
    abortEufefaArchiv =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/archiv-firmen-unterlagen`,
        {
            method: "GET",
            signal: abortEufefaArchiv.signal,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_EUFEFA_ARCHIV_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchArchivEufefa", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_EUFEFA_ARCHIV_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchArchivEufefa", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_EUFEFA_ARCHIV_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchArchivEufefa", {
            success: json.success,
        });
        return json.payload;
    }
};

export const stelleEufefaWiederHer =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("stelleEufefaWiederHer");
        dispatch({
            type: EUFEFA_WIEDERHERSTELLEN_ACTION_BEGIN,
        });
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${projektId}/stelle-firma-eufefa-wieder-her`,
            {
                method: "POST",
                body: formData,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: EUFEFA_WIEDERHERSTELLEN_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent("stelleEufefaWiederHer", {
                    success: json.success,
                    error: { errorMessage: json },
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: EUFEFA_WIEDERHERSTELLEN_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent("stelleEufefaWiederHer", {
                    success: response.ok,
                    error: { errorMessage: response },
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: EUFEFA_WIEDERHERSTELLEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("stelleEufefaWiederHer", {
                success: json.success,
            });
            return json.payload;
        }
    };
//#endregion

//#region Eufefa-Archiv
export const cancelArchivEufema = () => (dispatch) => {
    try {
        abortEufemaArchiv?.abort();
    } catch (e) {
        console.log(e);
    }
    dispatch({ type: FETCH_RESET_EUFEMA_ARCHIV_SUCCESS });
    appInsights.trackEvent({ name: "cancelArchivEufema" });
};

export const fetchArchivEufema = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchArchivEufefa");
    dispatch({
        type: FETCH_EUFEMA_ARCHIV_ACTION_BEGIN,
    });
    abortEufemaArchiv =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/archiv-personal-unterlagen`,
        {
            method: "GET",
            signal: abortEufemaArchiv.signal,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_EUFEMA_ARCHIV_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchArchivEufema", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_EUFEMA_ARCHIV_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchArchivEufema", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_EUFEMA_ARCHIV_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchArchivEufema", {
            success: json.success,
        });
        return json.payload;
    }
};

export const stelleEufemaWiederHer =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("stelleEufemaWiederHer");
        dispatch({
            type: EUFEFA_WIEDERHERSTELLEN_ACTION_BEGIN,
        });
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${projektId}/stelle-personal-eufema-wieder-her`,
            {
                method: "POST",
                body: formData,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: EUFEMA_WIEDERHERSTELLEN_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent("stelleEufemaWiederHer", {
                    success: json.success,
                    error: { errorMessage: json },
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: EUFEMA_WIEDERHERSTELLEN_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent("stelleEufemaWiederHer", {
                    success: response.ok,
                    error: { errorMessage: response },
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: EUFEMA_WIEDERHERSTELLEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("stelleEufemaWiederHer", {
                success: json.success,
            });
            return json.payload;
        }
    };
//#endregion
