export const PROJEKTROLLEN_ACTION_FAIL = "PROJEKTROLLEN_ACTION_FAIL";
export const PROJEKTROLLEN_ACTION_BEGIN = "PROJEKTROLLEN_ACTION_BEGIN";
export const FETCH_PROJEKTROLLEN_SUCCESS = "FETCH_PROJEKTROLLEN_SUCCESS";
export const RESET_PROJEKTROLLEN_SUCCESS = "RESET_PROJEKTROLLEN_SUCCESS";

export const PROJEKTROLLENBEZIEHUNGEN_ACTION_FAIL = "PROJEKTROLLENBEZIEHUNGEN_ACTION_FAIL";
export const PROJEKTROLLENBEZIEHUNGEN_ACTION_BEGIN = "PROJEKTROLLENBEZIEHUNGEN_ACTION_BEGIN";
export const FETCH_PROJEKTROLLENBEZIEHUNGEN_SUCCESS = "FETCH_PROJEKTROLLENBEZIEHUNGEN_SUCCESS";
import errorHandler from "../errorHandling.js";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;

export const resetProjektrollen = () => (dispatch) => {
    dispatch({ type: RESET_PROJEKTROLLEN_SUCCESS });
    appInsights.trackEvent({ name: "resetProjektrollen" });
};

export const fetchProjektrollen = (projektId) => async (dispatch) => {
    appInsights.startTrackEvent("fetchProjektrollen");
    dispatch({ type: PROJEKTROLLEN_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/projekt/v2/${projektId}/projektrollen.json/`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: PROJEKTROLLEN_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("fetchProjektrollen", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: PROJEKTROLLEN_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("fetchProjektrollen", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({ type: FETCH_PROJEKTROLLEN_SUCCESS, payload: json.payload });
        appInsights.stopTrackEvent("fetchProjektrollen", {
            success: json.success,
        });
        return json.payload;
    }
};

export const fetchProjektrollenbeziehungen = (projektId) => async (dispatch) => {
    appInsights.startTrackEvent("fetchProjektrollenbeziehungen");
    dispatch({ type: PROJEKTROLLENBEZIEHUNGEN_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/projekt/v1/${projektId}/projektrollenbeziehungen/`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: PROJEKTROLLENBEZIEHUNGEN_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("fetchProjektrollenbeziehungen", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: PROJEKTROLLENBEZIEHUNGEN_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("fetchProjektrollenbeziehungen", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({ type: FETCH_PROJEKTROLLENBEZIEHUNGEN_SUCCESS, payload: json.payload });
        appInsights.stopTrackEvent("fetchProjektrollenbeziehungen", {
            success: json.success,
        });
        return json.payload;
    }
};

