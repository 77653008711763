import {LitElement } from 'lit';
import { html } from "lit/html.js"

import { SbAdmin2Styles } from '../../css/sb-admin-2-styles.js';
import { AlgoStyles } from '../../css/algo-styles.js';
import { FontawesomeIcons } from '../../css/fontawesome-icons.js';

class BsInfoCard extends LitElement {
    static get styles() {
        return [
            SbAdmin2Styles,
            AlgoStyles,
            FontawesomeIcons
        ];
    }

    static get properties() {
        return {
            color: String,
            icon: String,
            header: String,
            content: String,
            content2: String
        };
    }

    render() {
        return html`
            <div class="card border-left-${this.color} shadow h-100 py-2">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1 text-${this.color}">
                                ${this.header}
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">
                                ${this.content}
                                <br>
                                ${this.content2}
                            </div>
                        </div>
                        <div class="col-auto">
                            <i class="fas fa-${this.icon} fa-2x text-${this.color}"></i>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    constructor() {
        super();
        this.color = '';
        this.icon = '';
        this.header = '';
        this.content = '';
        this.content2 = '';
    }
}

window.customElements.define('bs-info-card', BsInfoCard);