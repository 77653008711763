export const TRANSLATION_DE_NUTZUNGSBEDINGUNGEN = {
    AUSSERGERICHTLICHES: {
        TEIL1_TEXT:
            "CPC nimmt nicht an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil.",
        TEIL1_UEBERSCHRIFT: "16.1 Streitbeilegung",
        TEIL2_TEXT1:
            "Pflichtgemäß nach der EU-Verordnung Nr. 524/2013 wird jedoch darauf hingewiesen, dass die Europäische Kommission unter",
        TEIL2_TEXT2:
            "eine Plattform zur außergerichtlichen Online-Streitbeilegung (sog. OS-Plattform) betreibt.",
        TEIL2_UEBERSCHRIFT: "16.2 Online-Streitbeilegung",
        UEBERSCHRIFT: "16. AUßERGERICHTLICHE STREITBEILEGUNG",
    },
    HAFTUNG: {
        TEIL1_TEXT:
            "Soweit sich aus Teil I nichts anderes ergibt haftet CPC bei einer etwaigen Pflichtverletzung nach den gesetzlichen Vorschriften.",
        TEIL1_UEBERSCHRIFT: "13.1 Pflichtverletzungen",
        TEIL2_TEXT:
            "Auf Schadensersatz haftet CPC – gleich aus welchem Rechtsgrund – im Rahmen der Verschuldenshaftung bei Vorsatz und grober Fahrlässigkeit. Bei einfacher Fahrlässigkeit haftet CPC vorbehaltlich eines milderen Haftungsmaßstabs nach gesetzlichen Vorschriften (z.B. für Sorgfalt in eigenen Angelegenheiten) nur ",
        TEIL2_UEBERSCHRIFT: "13.2 Schadenersatz",
        TEIL3_TEXT:
            "Die sich aus Punkt I ergebenden Haftungsbeschränkungen gelten auch bei Pflichtverletzungen durch bzw. zugunsten von Personen, deren Verschulden CPC nach gesetzlichen Vorschriften zu vertreten hat. Sie gelten nicht, soweit CPC einen Mangel arglistig verschwiegen oder eine Garantie für die Beschaffenheit übernommen hat und für etwaige Ansprüche nach dem Produkthaftungsgesetz.",
        TEIL3_UEBERSCHRIFT: "13.3 Haftungsbeschränkungen",
        UEBERSCHRIFT: "13. HAFTUNG",
    },
    KOMMUNIKATION: {
        TEXT: "Mitteilungen von CPC an die Nutzenden erfolgen per E-Mail.",
        UEBERSCHRIFT: "14. KOMMUNIKATION",
    },
    REGISTRIERUNG: {
        TEIL1_LISTE1: "Name, Vorname",
        TEIL1_LISTE2: "E-Mail-Adresse",
        TEIL1_LISTE3: "Firma",
        TEIL1_TEXT1:
            "Die Nutzung der ALGO-Webanwendung und/oder ALGO-App ist nur nach persönlicher Registrierung der nutzenden Person unter Angabe folgender Pflichtdaten möglich:",
        TEIL1_UEBERSCHRIFT: "10.1 Pflichtdaten",
        TEIL2_TEXT:
            "Die nutzende Person muss einen Nutzernamen und ein Passwort festlegen. Die Nutzung ALGO-Webanwendung und/oder ALGO-App ist nur unter Eingabe des persönlichen Nutzernamens und Passwortes möglich.",
        TEIL2_UEBERSCHRIFT: "10.2 Nutzername/Passwort",
        TEIL3_TEXT:
            "Die nutzende Person kann sich innerhalb der Anwendung bzw. App zu den jeweiligen Baustellenprojekten unter Angabe der in der Anmeldemaske als Pflichtdaten gekennzeichneten Daten anmelden:",
        TEIL3_UEBERSCHRIFT: "10.3 Anmeldemaske",
        UEBERSCHRIFT: "10. REGISTRIERUNG",
    },
    SORGFALTSPFLICHTEN: {
        TEIL1_TEXT:
            "Die nutzende Person darf das Passwort Dritten nicht offenbaren und hat es sorgfältig zu verwahren, um Missbräuche auszuschließen.",
        TEIL1_UEBERSCHRIFT: "11.1 Passwort",
        TEIL2_TEXT:
            "Die nutzende Person ist verpflichtet, den Baustellenbetreiber oder CPC unverzüglich zu informieren, wenn das Passwort verloren gegangen ist oder wenn ihm/ihr bekannt wird, dass von ihm/ihr nicht befugte Dritte von dem Passwort Kenntnis erlangt haben. Sofern die nutzende Person nicht den Beweis erbringt, dass ein Dritter den Zugang zur Plattform ohne seine/ihre Zustimmung genutzt hat, werden alle über den Zugang getätigten Eingaben der nutzenden Person zugerechnet.",
        TEIL2_UEBERSCHRIFT:
            "11.2 Informationspflicht über verlorengeganges Passwort",
        TEIL3_TEXT:
            "Die nutzende Person haftet für jeden Missbrauch Dritter, soweit er/sie nicht den Beweis erbringt, dass ihn/ihr hieran kein Verschulden trifft.",
        TEIL3_UEBERSCHRIFT: "11.3 Haftung",
        TEIL4_TEXT:
            "CPC hat das Recht, die Registrierung jeder nutzenden Person zu deaktivieren, sollte es zu einer missbräuchlichen oder in betrügerischer Absicht vorgenommenen Verwendung der Zugriffsberechtigung kommen.",
        TEIL4_UEBERSCHRIFT: "11.4 Deaktivierung des Nutzerkontos",
        TEIL5_TEXT:
            "Die eigenden Registrierungs-Daten kann der/die Nutzer*in unter der Rubrik [„Mein Profil - Stammdaten“] jederzeit einsehen und dort korrigieren/ändern.",
        TEIL5_UEBERSCHRIFT: "11.5 Registrierungs-Daten",
        UEBERSCHRIFT: "11. SORGFALTSPFLICHTEN DER NUTZENDEN Person",
    },
    UEBERSCHRIFT: "II. ALLGEMEINE NUTZUNGSBEDINGUNGEN",
    VERHAELTNISSE: {
        TEXT: "CPC hat sich keinen speziellen Verhaltenskodizes unterworfen.",
        UEBERSCHRIFT: "15. VERHÄLTNISSE",
    },
    VERTRAGSVERHAELTNIS: {
        TEXT: "CPC stellt die ALGO-Webanwendung und die ALGO-App nur im Auftrag und im Rahmen der Weisungen der baustellenbetreibenden Person dem/der Nutzer*in zur Verfügung. Zwischen der nutzenden Person und der ALGO-Webanwendung bzw. der ALGO-App und CPC besteht kein Vertragsverhältnis. Die Nutzung der App erfolgt durch die nutzende Person ausschließlich zur Umsetzung bzw. Erfüllung des Vertrags- oder sonstigen Rechtsverhältnisses zwischen der baustellenbetreibenden Person des Bauprojekts, für das die Registrierung erfolgt, und dem nutzenden Person.",
        UEBERSCHRIFT: "9. VERTRAGSVERHÄLTNIS",
    },
    ZUGRIFFSBERECHTIGUNG: {
        TEIL1_TEXT:
            "Den Umfang der Zugriffsberechtigung legt die baustellenbetreibende Person nach Projektrollen (z.B. Bauherr*in, Bauleitung, Projektkoordination, Nachunternehmer*in) gestaffelt fest. Die nutzende Person hat gegenüber CPC ausdrücklich keinen Anspruch auf Gewährung einer Zugangsberechtigung.",
        TEIL1_UEBERSCHRIFT: "12.1 Umfang",
        TEIL2_TEXT:
            "Die baustellenbetreibende Person kann CPC jederzeit formlos und ohne Angabe von Gründen anweisen, die Zugangsberechtigung einer nutzenden Person zu löschen. In diesem Fall erlischt die Registrierung der betreffenden nutzenden Person und somit seine/ihre Nutzungsberechtigung mit sofortiger Wirkung oder ggf. mit Wirkung zu dem Zeitpunkt, zu dem die baustellenbetreibende Person die Nutzungsberechtigung entzieht.",
        TEIL2_UEBERSCHRIFT: "12.2 Erlöschen",
        UEBERSCHRIFT: "12. UMFANG UND ERLÖSCHEN DER ZUGRIFFSBERECHTIGUNG",
    },
};
export default TRANSLATION_DE_NUTZUNGSBEDINGUNGEN;
