import {
    MITARBEITER_ERSTELLEN_COMMAND_SUCCESS,
    MITARBEITER_ERSTELLEN_VIEW_DATA_ACTION_FAIL,
    MITARBEITER_ERSTELLEN_VIEW_DATA_ACTION_BEGIN,
    FETCH_MITARBEITER_ERSTELLEN_VIEW_DATA_SUCCESS,
    RESET_MITARBEITER_ERSTELLEN_VIEW_DATA_SUCCESS
} from '../../actions/zuko/mitarbeiter-erstellen-view-data.js';

const mitarbeiterErstellenViewDataState = {
    mitarbeiterId: '',
    error: null,
    loading: false,
    mitarbeiterErstellenViewData: {},
};

const savedState = localStorage.getItem('savedState') || JSON.stringify({ ...mitarbeiterErstellenViewDataState });
const INITIAL_STATE = JSON.parse(savedState).mitarbeiterErstellenViewDataState || mitarbeiterErstellenViewDataState;

const mitarbeiterErstellenViewData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MITARBEITER_ERSTELLEN_COMMAND_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                mitarbeiterId: action.payload
            };
        case MITARBEITER_ERSTELLEN_VIEW_DATA_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case MITARBEITER_ERSTELLEN_VIEW_DATA_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FETCH_MITARBEITER_ERSTELLEN_VIEW_DATA_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                mitarbeiterErstellenViewData: action.payload
            };
        case RESET_MITARBEITER_ERSTELLEN_VIEW_DATA_SUCCESS:
            return mitarbeiterErstellenViewDataState;
        default:
            return state;
    }
};

export default mitarbeiterErstellenViewData;