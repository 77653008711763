const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;

export const fetchTransportDetails = async (sprache, projektId, id) => {
    appInsights.startTrackEvent("fetchTransportDetails");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/transport-details?transportId=${id}`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchTransportDetails", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchTransportDetails", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchTransportDetails", {
            success: json.success,
        });
        return json;
    }
};

export const fetchTransportDetailsBundeskanzleramt = async (sprache, projektId, id) => {
    appInsights.startTrackEvent("fetchTransportDetailsBundeskanzleramt");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/transport-details-bundeskanzleramt?transportId=${id}`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchTransportDetailsBundeskanzleramt", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchTransportDetailsBundeskanzleramt", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchTransportDetailsBundeskanzleramt", {
            success: json.success,
        });
        return json;
    }
};

export const fetchBaustelleneinrichtungsPlan = async (sprache, projektId) => {
    appInsights.startTrackEvent("fetchBaustelleneinrichtungsPlan");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/download-baustelleneinrichtungsplan/`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchBaustelleneinrichtungsPlan", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchBaustelleneinrichtungsPlan", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchBaustelleneinrichtungsPlan", {
            success: json.success,
        });
        return json;
    }
};

export const storniereTransport = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("storniereTransport");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/storniere-transport`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("storniereTransport", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("storniereTransport", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("storniereTransport", {
            success: json.success,
        });
        return json;
    }
};
export const storniereTransportBundeskanzleramt = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("storniereTransportBundeskanzleramt");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/storniere-transport-bundeskanzleramt`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("storniereTransportBundeskanzleramt", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("storniereTransportBundeskanzleramt", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("storniereTransportBundeskanzleramt", {
            success: json.success,
        });
        return json;
    }
};

export const aktualisiereTransport = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("aktualisiereTransport");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/aktualisiere-transport`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("aktualisiereTransport", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("aktualisiereTransport", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("aktualisiereTransport", {
            success: json.success,
        });
        return json;
    }
};

export const aktualisiereTransportBundeskanzleramt = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("aktualisiereTransportBundeskanzleramt");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/aktualisiere-transport-bundeskanzleramt`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("aktualisiereTransportBundeskanzleramt", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("aktualisiereTransportBundeskanzleramt", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("aktualisiereTransportBundeskanzleramt", {
            success: json.success,
        });
        return json;
    }
};

export const genehmigeTransport = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("genehmigeTransport");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/genehmige-transport`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("genehmigeTransport", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("genehmigeTransport", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("genehmigeTransport", {
            success: json.success,
        });
        return json;
    }
};

export const genehmigeTransportBundeskanzleramt = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("genehmigeTransportBundeskanzleramt");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/genehmige-transport-bundeskanzleramt`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("genehmigeTransportBundeskanzleramt", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("genehmigeTransportBundeskanzleramt", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("genehmigeTransportBundeskanzleramt", {
            success: json.success,
        });
        return json;
    }
};

export const fetchDispositionen = async (
    sprache,
    projektId,
    startDatum,
    endDatum
) => {
    appInsights.startTrackEvent("fetchDispositionen");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/dispositionen/?startDatum=${startDatum}&endDatum=${endDatum}`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchDispositionen", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchDispositionen", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchDispositionen", {
            success: json.success,
        });
        return json;
    }
};

export const fetchDispositionsVorschauEinzel = async (
    startDatum,
    entladezeit,
    bestimmungsortId
) => {
    appInsights.startTrackEvent("fetchDispositionsVorschau");
    let projektId = localStorage.getItem("projektId"),
        sprache = localStorage.getItem("language") || navigator.language;
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/disposition-einzel-vorschau/?startDatum=${startDatum}&bestimmungsortId=${bestimmungsortId}&entladezeit=${entladezeit}`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchDispositionsVorschau", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchDispositionsVorschau", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchDispositionsVorschau", {
            success: json.success,
        });
        return json;
    }
};

export const fetchDispositionsVorschauSammel = async (
    startDatum,
    bestimmungsortId,
    entladezeit,
    fahrzeugAnzahl
) => {
    appInsights.startTrackEvent("fetchDispositionsVorschauSammel");
    let projektId = localStorage.getItem("projektId"),
        sprache = localStorage.getItem("language") || navigator.language;
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/disposition-sammel-vorschau/?startDatum=${startDatum}&bestimmungsortId=${bestimmungsortId}&entladezeit=${entladezeit}&fahrzeugAnzahl=${fahrzeugAnzahl}`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchDispositionsVorschauSammel", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchDispositionsVorschauSammel", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchDispositionsVorschauSammel", {
            success: json.success,
        });
        return json;
    }
};

export const fetchDispositionsVorschauSerie = async (
    startDatum,
    entladezeit,
    bestimmungsortId,
    wiederholungen,
    zeitabschnitt
) => {
    appInsights.startTrackEvent("fetchDispositionsVorschauSerie");
    let projektId = localStorage.getItem("projektId"),
        sprache = localStorage.getItem("language") || navigator.language;
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/disposition-serie-vorschau/?startDatum=${startDatum}&bestimmungsortId=${bestimmungsortId}&entladezeit=${entladezeit}&wiederholungen=${wiederholungen}&zeitabschnitt=${zeitabschnitt}`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchDispositionsVorschauSerie", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchDispositionsVorschauSerie", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchDispositionsVorschauSerie", {
            success: json.success,
        });
        return json;
    }
};

export const tagesDispostionenAbschliessen = async (
    projektId,
    sprache,
    formData
) => {
    appInsights.startTrackEvent("tagesDispostionenAbschliessen");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/tages-dispo-abschliessen/`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("tagesDispostionenAbschliessen", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("tagesDispostionenAbschliessen", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("tagesDispostionenAbschliessen", {
            success: json.success,
        });
        return json;
    }
};
