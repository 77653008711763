import {
    FETCH_MONATAUSWERTUNGEN_RESET_SUCCESS,
    DASHBOARD_MONATSAUSWERTUNGEN_ACTION_FAIL,
    MONATSAUSWERTUNG_AKTIVE_AUSWEISE_GESAMT_ACTION_BEGIN,
    FETCH_MONATSAUSWERTUNG_AKTIVE_AUSWEISE_GESAMT_SUCCESS,
    MONATAUSWERTUNG_NEU_AUSGESTELLTE_AUSWEISE_ACTION_BEGIN,
    FETCH_MONATSAUSWERTUNG_NEU_AUSGESTELLTE_AUSWEISE_SUCCESS,
    MONATSAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_ACTION_BEGIN,
    FETCH_MONATSAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_SUCCESS,
    MONATSAUSWERTUNG_ANWESENHEIT_MONATSVERLAUF_ACTION_BEGIN,
    FETCH_MONATSAUSWERTUNG_ANWESENHEIT_MONATSVERLAUF_SUCCESS,
    MONATSAUSWERTUNG_SPERRGRUENDE_MONATSVERLAUF_ACTION_BEGIN,
    FETCH_MONATSAUSWERTUNG_SPERRGRUENDE_MONATSVERLAUF_SUCCESS,
    MONATSAUSWERTUNG_ANWESENHEIT_MITARBEITENDE_FIRMEN_ACTION_BEGIN,
    FETCH_MONATSAUSWERTUNG_ANWESENHEIT_MITARBEITENDE_FIRMEN_SUCCESS,
} from "../../actions/dashboard/monatsauswertungen";

const monatsAuswertungenState = {
    error: null,
    loading: false,
    neuAusgestellteAusweise: {},
    neuAusgestellteAusweiseIstLoading: false,
    aktiveAusweiseGesamt: {},
    aktiveAusweiseGesamtIstLoading: false,
    gesperrteAusweiseGesamt: {},
    gesperrteAusweiseGesamtIstLoading: false,
    sperrgruende: {},
    sperrgruendeMonatsverlaufIstLoading: false,
    anwesenheitMitarbeitendeFirmen: {},
    anwesenheitMitarbeitendeFirmenIstLoading: false,
};

const savedState =
    localStorage.getItem("savedState") ||
    JSON.stringify({ ...monatsAuswertungenState });
const INITIAL_STATE =
    JSON.parse(savedState).meineProjekteState || monatsAuswertungenState;

const monatauswertung = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_MONATAUSWERTUNGEN_RESET_SUCCESS:
            return monatsAuswertungenState;
        case DASHBOARD_MONATSAUSWERTUNGEN_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                neuAusgestellteAusweiseIstLoading: false,
            };
        case MONATAUSWERTUNG_NEU_AUSGESTELLTE_AUSWEISE_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
                neuAusgestellteAusweiseIstLoading: true,
            };
        case FETCH_MONATSAUSWERTUNG_NEU_AUSGESTELLTE_AUSWEISE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                neuAusgestellteAusweise: {},
                neuAusgestellteAusweiseIstLoading: false,
            };
        case MONATSAUSWERTUNG_AKTIVE_AUSWEISE_GESAMT_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
                aktiveAusweiseGesamtIstLoading: true,
            };
        case FETCH_MONATSAUSWERTUNG_AKTIVE_AUSWEISE_GESAMT_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                aktiveAusweiseGesamt: {},
                aktiveAusweiseGesamtIstLoading: false,
            };
        case MONATSAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
                gesperrteAusweiseGesamtIstLoading: true,
            };
        case FETCH_MONATSAUSWERTUNG_GESPERRTE_AUSWEISE_GESAMT_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                gesperrteAusweiseGesamt: {},
                gesperrteAusweiseGesamtIstLoading: false,
            };
        case MONATSAUSWERTUNG_ANWESENHEIT_MONATSVERLAUF_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
                anwesenheitMonatsverlaufIstLoading: true,
            };
        case FETCH_MONATSAUSWERTUNG_ANWESENHEIT_MONATSVERLAUF_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                anwesenheitMonatsverlauf: {},
                anwesenheitMonatsverlaufIstLoading: false,
            };
        case MONATSAUSWERTUNG_SPERRGRUENDE_MONATSVERLAUF_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
                sperrgruendeMonatsverlaufIstLoading: true,
            };
        case FETCH_MONATSAUSWERTUNG_SPERRGRUENDE_MONATSVERLAUF_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                sperrgruende: {},
                sperrgruendeMonatsverlaufIstLoading: false,
            };
        case MONATSAUSWERTUNG_ANWESENHEIT_MITARBEITENDE_FIRMEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
                anwesenheitMitarbeitendeFirmenIstLoading: true,
            };
        case FETCH_MONATSAUSWERTUNG_ANWESENHEIT_MITARBEITENDE_FIRMEN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                anwesenheitMitarbeitendeFirmen: {},
                anwesenheitMitarbeitendeFirmenIstLoading: false,
            };
        default:
            return state;
    }
};

export default monatauswertung;
