const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;
export const erstelleBuchungEufeb = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("erstelleContainerEufeb");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/erstelle-buchung-eufeb`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("erstelleContainerEufeb", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("erstelleContainerEufeb", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("erstelleContainerEufeb", {
            success: json.success,
        });
        return json;
    }
};

export const fetchEufeb = async (sprache, projektId, eufebId) => {
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/download-buchung-eufeb/?buchungEufebId=${eufebId}`,
        {
            method: "GET"
        }
    );
    let json;
    try {
        json = await response.blob();
        return json;
    } catch (error) {
        dispatch(errorHandler(error));
    }
    return response;
};

export const fetchEufebVorlage = async (sprache, projektId, eufebId) => {
    const a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/container/v1/${sprache}/${projektId}/download-eufeb-vorlage/?eufebId=${eufebId}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "fetchEufebVorlage" });
};

export const erstelleWeitereBuchungEufebCommand = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("erstelleWeitereBuchungEufebCommand");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/erstelle-weitere-buchung-eufeb`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("erstelleWeitereBuchungEufebCommand", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("erstelleWeitereBuchungEufebCommand", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("erstelleWeitereBuchungEufebCommand", {
            success: json.success,
        });
        return json;
    }
};

export const archiviereBuchungEufeb = async (sprache, projektId, formData) => {
    appInsights.startTrackEvent("archiviereBuchungEufeb");
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/archiviere-buchung-eufeb`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("archiviereBuchungEufeb", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("archiviereBuchungEufeb", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("archiviereBuchungEufeb", {
            success: json.success,
        });
        return json;
    }
};