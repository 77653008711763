import errorHandler from "../errorHandling";

export const FETCH_EUFEMAS_SUCCESS = "FETCH_EUFEMAS_SUCCESS";
export const RESET_EUFEMAS_SUCCESS = "RESET_EUFEMAS_SUCCESS";
export const LOESCHE_EUFEMAS_SUCCESS = "LOESCHE_EUFEMAS_SUCCESS";
export const ZUKO_EUFEMAS_ACTION_FAIL = "ZUKO_EUFEMAS_ACTION_FAIL";
export const ERSTELLE_EUFEMAS_SUCCESS = "ERSTELLE_EUFEMAS_SUCCESS";
export const ZUKO_EUFEMAS_ACTION_BEGIN = "ZUKO_EUFEMAS_ACTION_BEGIN";
export const AKTUALISIERE_EUFEMAS_SUCCESS = "AKTUALISIERE_EUFEMAS_SUCCESS";

const apiHost = window.Algo.apiHost,
  appInsights = window.Algo.appInsights,
  getRequestOptions = window.Algo.getRequestOptions,
  postRequestOptions = window.Algo.postRequestOptions;

export const resetEufemas = () => (dispatch) => {
  dispatch({ type: RESET_EUFEMAS_SUCCESS });
  appInsights.trackEvent({ name: "resetEufemas" });
};

export const fetchEufemas = (data) => async (dispatch) => {
  appInsights.startTrackEvent("fetchEufemas");
  dispatch({ type: ZUKO_EUFEMAS_ACTION_BEGIN });
  const response = await fetch(
    `${apiHost}/api/zuko/admin/v1/${data.projektId}/eufemas/`,
    getRequestOptions
  );
  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(error));
  }
  if (!response.ok) {
    if (json && !json.success) {
      dispatch(errorHandler(json));
      dispatch({ type: ZUKO_EUFEMAS_ACTION_FAIL, error: json });
      appInsights.stopTrackEvent("fetchEufemas", {
        success: json.success,
        error: json,
      });
    } else {
      dispatch(errorHandler(response));
      dispatch({ type: ZUKO_EUFEMAS_ACTION_FAIL, error: response });
      appInsights.stopTrackEvent("fetchEufemas", {
        success: response.ok,
        error: response,
      });
    }
  }
  if (json?.success) {
    dispatch({ type: FETCH_EUFEMAS_SUCCESS, payload: json.payload });
    appInsights.stopTrackEvent("fetchEufemas", { success: json.success });
  }
};

export const erstelleEufemas = (formData, projektId) => async (dispatch) => {
  appInsights.startTrackEvent("erstelleEufemas");
  dispatch({ type: ZUKO_EUFEMAS_ACTION_BEGIN });
  const url = `/api/zuko/admin/v1/${projektId}/erstelle-eufema`;
  const response = await fetch(url, {
    method: "POST",
    body: formData,
  });

  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(error));
  }
  if(!response.ok){
    if(json && !json.success){
      dispatch(errorHandler(json));
      dispatch({ type: ZUKO_EUFEMAS_ACTION_FAIL, error: json });
      appInsights.stopTrackEvent("erstelleEufemas", {
        success: json.success,
        error: json,
      });
    }else{
      dispatch(errorHandler(response));
      dispatch({ type: ZUKO_EUFEMAS_ACTION_FAIL, error: response });
      appInsights.stopTrackEvent("erstelleEufemas", {
        success: response.ok,
        error: response,
      });
    }
  }
  if(json?.success){
    dispatch({ type: ERSTELLE_EUFEMAS_SUCCESS, payload: json.payload });
    appInsights.stopTrackEvent("erstelleEufemas", { success: json.success });
    return json.payload;
  }
};

export const loescheEufema = (formData, projektId) => async (dispatch) => {
  appInsights.startTrackEvent("loescheEufema");
  dispatch({ type: ZUKO_EUFEMAS_ACTION_BEGIN });
  const url = `/api/zuko/admin/v1/${projektId}/loesche-eufema`;
  const response = await fetch(url, {
    method: "POST",
    body: formData,
  });

  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(error));
  }
  if(!response.ok){
    if(json && !json.success){
      dispatch(errorHandler(json));
      dispatch({ type: ZUKO_EUFEMAS_ACTION_FAIL, error: json });
      appInsights.stopTrackEvent("loescheEufema", {
        success: json.success,
        error: json,
      });
    }else{
      dispatch(errorHandler(response));
      dispatch({ type: ZUKO_EUFEMAS_ACTION_FAIL, error: response });
      appInsights.stopTrackEvent("loescheEufema", {
        success: response.ok,
        error: response,
      });
    }
  }
  if(json?.success){
    dispatch({ type: LOESCHE_EUFEMAS_SUCCESS, payload: json.payload });
    appInsights.stopTrackEvent("loescheEufema", { success: json.success });
    return json.payload;
  }
};

export const aktualisiereEufema = (formData, projektId) => async (dispatch) => {
  appInsights.startTrackEvent("aktualisiereEufema");
  dispatch({ type: ZUKO_EUFEMAS_ACTION_BEGIN });
  const url = `/api/zuko/admin/v1/${projektId}/aktualisiere-eufema`;
  const response = await fetch(url, {
    method: "POST",
    body: formData,
  });
  let json;
  try {
    json = await response.json();
  } catch (error) {
    dispatch(errorHandler(error));
  }
  if(!response.ok){
    if(json && !json.success){
      dispatch(errorHandler(json));
      dispatch({ type: ZUKO_EUFEMAS_ACTION_FAIL, error: json });
      appInsights.stopTrackEvent("aktualisiereEufema", {
        success: json.success,
        error: json,
      });
    }else{
      dispatch(errorHandler(response));
      dispatch({ type: ZUKO_EUFEMAS_ACTION_FAIL, error: response });
      appInsights.stopTrackEvent("aktualisiereEufema", {
        success: response.ok,
        error: response,
      });
    }
  }
  if(json?.success){
    dispatch({ type: AKTUALISIERE_EUFEMAS_SUCCESS, payload: json.payload });
    appInsights.stopTrackEvent("aktualisiereEufema", { success: json.success });
    return json.payload;
  }
};
