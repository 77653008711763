export const TRANSLATIONS_DE_ZUKO = {
    ABGELAUFEN_AM: "Abgelaufen am",
    ABGELAUFENE_UNTERLAGEN: "Abgelaufene Unterlagen",
    A1_PFLICHT_GETRENNT: "A1<br>Pflicht",
    A1_PFLICHT_UNTERLAGEN: "A1-Pflicht-Unterlagen",
    A1_PFLICHT_UNTERLAGEN_NICHT_VOLLSTAENDIG:
        "A1-Pflicht Unterlagen sind nicht vollständig",
    ABFRAGE_DRUCK_MODAL_BODY:
        "Möchten Sie den Baustellenausweis drucken? Dann wählen Sie im Dropdown einen Druckvorlage aus und klicken auf Baustellenausweis drucken.",
    A1_UNTERLAGEN: "A1 Unterlagen",
    ADMINISTRATION: "Administration",
    AKTION: "Aktion",
    AKTIONEN: "Aktionen",
    AKTIONSNACHRICHT: "Aktionsnachricht",
    AKTIV: "aktiv",
    AKTIVIERT: "Aktiviert",
    AKTUALISIEREN: "Aktualisieren",
    AKTUELLE_BESETZUNG: "Aktuelle Besetzung",
    AKTUELLE_BESETZUNG_DOWNLOAD: "Aktuelle Besetzung herunterladen",
    AKTUELLE_BESETZUNG_TEIL1: "Aktuelle",
    AKTUELLE_BESETZUNG_TEIL2: "Besetzung",
    ALLE_FIRMEN_UNTERLAGEN_GEPRUEFT:
        "Aktuell sind alle Firmenunterlagen geprüft",
    ALLE_MINDESTLOHNERKLAERUNGEN_GEPRUEFT:
        "Aktuell sind alle Mindestlohnerklärungen geprüft",
    ALLE_PERSONAL_UNTERLAGEN_GEPRUEFT:
        "Aktuell sind alle Personalunterlagen geprüft",
    ALLERERSTER_EINTRITT_GETRENNT: "Allererstes<br>Eintrittsereignis",
    AM: "am",
    ANTIPASSBACK: "Antipassback",
    ANTIPASSBACK_AKTIVIEREN: "Antipassback aktivieren",
    ANWESENDE_FIRMEN_GESAMT: "Anwesende Firmen",
    ANWESENDE_FIRMEN_TOP_5: "Anwesende Firmen (Top 5)",
    ANWESENDE_MITARBEITER_GES: "Anwesendes Personal (gesamt)",
    ANWESENDE_MITARBEITER_LIVE: "Anwesende Personal (live)",
    ANWESENDES_PERSONAL: "Anwesendes Personal",
    ANWESENDES_PERSONAL_GESAMT: "Anwesendes Personal (gesamt)",
    ANZAHL: "Anzahl",
    ANZAHL_AUFTRAGGEBER: "Anzahl Auftraggeber",
    ANZAHL_AUSWEISGUELTIGKEIT_KONFIGURATOR: "Anzahl Tage der Ausweisgültikgeit",
    ANZAHL_FIRMEN: "Anzahl Firmen",
    ANZAHL_GEWERKE: "Anzahl Gewerke",
    ANZAHL_MITARBEITER: "Anzahl Personal",
    ANZAHL_NACHUNTERNEHMER: "Anzahl Nachunternehmer",
    ANZAHL_ERSTHELFER: "Anzahl Ersthelfer*in",
    ANZAHL_SANITAETER: "Anzahl Sanitäter*in",
    ANZAHL_BRANDSCHUTZBEAUFTRAGTER: "Anzahl Brandschutzbeauftragter*in",
    ANZAHL_TAGE: "Anzahl Tage",
    ANZAHL_TAGE_ABLAUF_GUELTIGKEIT: "Anzahl der Tage vor Ablauf der Gültigkeit",
    ANZAHL_TAGE_TITLE: "z.B. 5 Tage nach Erstellung",
    ARBEITSERLAUBNIS: "Arbeitserlaubnis",
    ARBEITSVERHAELTNIS: "Arbeitsverhältnis",
    ARBEITSVERHAELTNIS_ART: {
        VOLLZEIT: "Vollzeit",
        TEILZEIT: "Teilzeit",
        AUSBILDUNG: "Ausbildung",
        SELBSTSTÄNDIG: "Selbstständig",
        ALTERSTEILZEIT: "Altersteilzeit",
        FREIE_MITARBEIT: "Freie Mitarbeit",
        GERINGFÜGIGE_BESCHÄFTIGUNG: "Geringfügige Beschäftigung",
        KETTENARBEITSVERHÄLTNIS: "Kettenarbeitsverhältnis",
        LEIHARBEIT: "Leiharbeit",
        ZEITARBEIT: "Zeitarbeit",
        PRAKTIKUM: "Praktikum",
        STUDENTENJOB: "Studentenjob",
        AUSHILFSTÄTIGKEITEN: "Aushilfstätigkeiten",
        SAISONJOB: "Saisonjob",
        SONSTIGE: "Sonstige",
    },
    ARBEITSVERHAELTNIS_AUSWAEHLEN: "Arbeitsverhältnis auswählen",
    ARBEITSZEITEN: "Arbeitszeiten",
    ARCHIV: "Archiv",
    AUFENTHALTSERLAUBNIS: "Aufenthaltserlaubnis",
    AUFENTHALTSTITEL: "Aufenthaltstitel",
    AUTOMATISCHE_SPERRUNG: "Automatische Sperrung",
    AUFTRAGGEBER: "Auftraggeber*in",
    AUFTRAGGEBER_GELOESCHT: "Auftraggeber*in gelöscht",
    AUFTRAGGEBER_HINZUFUEGEN: "Auftraggeber*in hinzufügen",
    AUFTRAGGEBER_HINZUGEFUEGT: "Auftraggeber*in hinzugefügt",
    AUFTRAGGEBER_LOESCHEN: "Auftraggeber*in löschen",
    AUFTRAGGEBER_MODAL_LOESCHEN:
        "Wollen Sie den/die Auftraggeber*in wirklich löschen?",
    AUFTRAGGEBER_MUSS_HINZUGEFUEGT_WERDEN:
        "Fügen Sie einen Auftraggeber hinzu.",
    AUSGENOMMEN_FIRMEN: "Ausgenommene Firmen",
    AUSGENOMMEN_FIRMEN_HELP_1:
        "Möchten Sie das eine Firma von der Systemgültigkeit unberührt bleibt, " +
        "dann fügen Sie eine geben Sie auf der linken Seite eine Firma ein und klicken auf den Pfeil um Sie der WhiteList hinzufügen.",
    AUSGEWAEHLTE_UNTERLAGEN: "Ausgewählte Unterlagen",
    AUSTRITT: "Austritt",
    AUSWEISE: "ausweise",
    AUSWEIS_GUELTIGKEIT_KONFIGURATOR: "Ausweisgültigkeit-Konfigurator",
    AUSWEIS_GUELTIGKEIT_NICHT_EINGESTELLT:
        "Ausweisgültigkeit noch nicht aktiviert.",
    AUSWEIS_INFO_MODAL: {
        TITEL: "Erste Schritte",
        VERSCHIEDENE_VORLAGE:
            "Hier können verschiedene Vorlagen ausgewählt werden",
        DESIGN_ERSTELLEN: "Ausweisdesign erstellen",
        BUTTON_FUER_SYSTEMVORLAGE:
            "Mit diesem Button kann man eine Systemvorlage erstellen",
        DESIGN_LOESCHEN: "Ausweisvorlage löschen",
        BUTTON_FUER_LOESCHEN:
            "Ist eine Vorlage ausgewählt, kann mit diesem Button die Vorlage gelöscht werden",
        TEXT_VORDERSEITE:
            "Die Bilder und der Text im Kopfbereich anklicken, um " +
            "diese zu bearbeiten. Klicken Sie den Kopf/Fußbalken " +
            "an um die Farbe zu ändern. Im Fußbereich lässt sich " +
            "die Farbe der Texte ändern, die Texte allerdings " +
            "nicht, die werden beim Druck automatisch gefüllt.",
        TEXT_RUECKSEITE:
            " Mit Klick auf die Texte, sowie auf die Symbole " +
            "lassen sich diese ändern. Mit Klick auf das Bild im " +
            "Kopfbereich lässt sich dieses bearbeiten.",
    },
    AUSWEIS_KONFIGURIEREN: "Ausweisgültigkeit konfigurieren",
    AUSWEIS_VORLAGE: "Ausweisvorlage designen",
    AUSWEIS_VORLAGEN: "Ausweis-Vorlagen",
    AUSWEISBILD: "Ausweisbild",
    AUSWEISE_ERFOLGREICH_GESPERRT: "Ausweise wurden erfolgreich gesperrt",
    AUSWERTUNGEN_AKTUALISIEREN: "Auswertungen aktualisieren",
    AUSWERTUNGEN_ZUTRITTSEREIGNISSE: "Auswertungen Zutrittsereignisse",
    AUSWERTUNGEN_ZUTRITTSEREIGNISSE_TEIL1: "Auswertungen",
    AUSWERTUNGEN_ZUTRITTSEREIGNISSE_TEIL2: "Zutrittsereignisse",
    AUSWIRKUNGEN_AUF_AUSWEISGUELTIGKEIT:
        "Auswirkungen </br>  auf </br> Ausweisgültigkeit",
    BAUSTELLE: "Baustelle",
    BAUSTELLEN: "Baustellen-",
    BAUSTELLENAUSWEISANZAHL: "Baustellenausweisanzahl",
    BAUSTELLENAUSWEISE: "Baustellenausweise",
    BAUSTELLENAUSWEISE_GESAMT: "Baustellenausweise gesamt",
    BAUSTELLENAUSWEISE_GETRENNT: "Baustellen-<br/>ausweise",
    BAUSTELLENAUSWEISE_GESPERRT: "Gesperrte Baustellenausweise",
    BAUSTELLENAUSWEIS_ANZAHL: "Anzahl Baustellenausweise",
    BAUSTELLENAUSWEIS_AKTIV_ANZAHL: "Anzahl aktiver Baustellenausweise",
    BAUSTELLENAUSWEIS_AUSWAEHLEN: "Bitte einen Baustellenausweis auswählen",
    BAUSTELLENAUSWEIS_DRUCKEN: "Baustellenausweis drucken",
    BAUSTELLENAUSWEIS_ENTSPERREN: "Baustellenausweis entsperren",
    BAUSTELLENAUSWEIS_EINSTELLUNGEN: "Baustellenausweis-Einstellungen",
    BAUSTELLENAUSWEIS_EINSTELLUNGEN_AKTUALISIEREN_UND:
        "Einstellungen aktualisieren und ",
    BAUSTELLENAUSWEIS_EINSTELLUNGEN_NICHT_SYNCHRONISIERT:
        "Baustellenweis Einstellungen sind nicht synchronisiert",
    BAUSTELLENAUSWEIS_EINSTELLUNGEN_SETZEN:
        "Baustellenausweis-Einstellungen müssen erst gesetzt werden.",
    BAUSTELLENAUSWEIS_ENTSPERREN: "Baustellenausweis entsperren",
    BAUSTELLENAUSWEIS_ENTSPERREN_MODAL:
        "Möchten Sie den Baustellenausweis wirklich entsperren?",
    BAUSTELLENAUSWEIS_ERFOLGREICH_ERSTELLT:
        "Baustellenausweis erfolgreich erstellt",
    BAUSTELLENAUSWEIS_ERFOLGREICH_GESPERRT:
        "Baustellenausweis wurde erfolgreich gesperrt",
    BAUSTELLENAUSWEIS_ERFOLGREICH_SYNCHRONISIERT:
        "Baustellenausweis erfolgreich synchronisiert.",
    BAUSTELLENAUSWEIS_ERSTELLEN: "Baustellenausweis erstellen",
    BAUSTELLENAUSWEIS_ERSTELLEN_DRUCKEN: "Baustellenausweis erstellen/drucken",
    BAUSTELLENAUSWEIS_ERSTELLT_WERDEN:
        "Es muss erst ein Baustellenausweis erstellt werden.",
    BAUSTELLENAUSWEIS_EXISTIERT: "Baustellenausweis existiert bereits!",
    BAUSTELLENAUSWEIS_GESPERRT: "Baustellenausweis ist gesperrt",
    BAUSTELLENAUSWEIS_GUELTIG_AB: "Baustellenausweise gültig ab",
    BAUSTELLENAUSWEIS_GUELTIG_BIS: "Baustellenausweise gültig bis",
    BAUSTELLENAUSWEIS_GRUND_AUSWAEHLEN: "Löschgrund auswählen",
    BAUSTELLENAUSWEIS_LOESCHEN: "Baustellenausweis löschen",
    BAUSTELLENAUSWEIS_LOESCHEN_SONSITGE_GRUND: "Sonstige Grund",
    BAUSTELLENAUSWEIS_LOESCHGRUND_FEHLDRUCK: "Fehldruck",
    BAUSTELLENAUSWEIS_LOESCHEN_LOESCHGRUND: "Löschgrund",
    BAUSTELLENAUSWEIS_LOESCHGRUND_SONSTIGE: "Sonstige",
    BAUSTELLENAUSWEIS_LOESCHGRUND_VERLASSEN: "Baustelle verlassen",
    BAUSTELLENAUSWEIS_LOESCHGRUND_VERWEIS: "Baustellenverweis",
    BAUSTELLENAUSWEIS_LOESCHEN_BODY:
        "Wollen Sie den Baustellenausweis wirklich löschen",
    BAUSTELLENAUSWEIS_LOESCHEN_LOESCHGRUNG: "Löschgrund",
    BAUSTELLENAUSWEIS_NICHT_MEHR_GUELTIG:
        "Baustellenweis ist nicht mehr gültig",
    BAUSTELLENAUSWEIS_NICHT_SYNCRONISIERT:
        "Baustellenausweis ist nicht synchronisiert",
    BAUSTELLENAUSWEIS_NOCH_NICHT_GUELTIG:
        "Baustellenweis ist noch nicht gültig",
    BAUSTELLENAUSWEIS_OHNE_BILD_TEIL1:
        "Wollen Sie den Baustellenausweis wirklich",
    BAUSTELLENAUSWEIS_OHNE_BILD_TEIL2: "ohne Bild",
    BAUSTELLENAUSWEIS_OHNE_BILD_TEIL3: "drucken?",
    BAUSTELLENAUSWEIS_SPERREN: "Baustellenausweis sperren",
    BAUSTELLENAUSWEIS_SCANNEN: "Baustellenausweis/Name scannen/eingeben",
    BAUSTELLENAUSWEIS_SUCHEN: "Baustellenausweis suchen",
    BAUSTELLENAUSWEIS_SYNCHRONISIEREN: "Baustellenausweise synchronisieren",
    BAUSTELLENAUSWEIS_WURDE_GEDRUCKT:
        "Baustellenausweis mit der Kartennummer {{kartennummer}} wurde gedruckt",
    BAUSTELLENAUSWEIS_VERLAENGERN: "Baustellenausweis verlängern",
    BAUSTELLENAUSWEIS_VERLAENGERN_MODAL:
        "Möchten Sie den Baustellenausweis wirklich verlängern?",
    BEDINGUNG_STARTZEIT:
        " Die Startzeit darf nicht größer oder gleich der Endzeit sein!",
    BENACHRICHTIGUNGEN: "Benachrichtigungen",
    BENACHRICHTIUNG_FIRMENVERWALTER:
        "Benachrichtung an Firmenverwaltung (Tage vor Ablauf)",
    BENACHRICHTIGUNGEN_HELP_1:
        "Bei Aktivierung der E-Mail an die Firmenverwaltung, erhält diese eine Informationsmail, " +
        "dass die Gültigkeit des Baustellenausweises wegen fehlender Unterlagen, in der von Ihnen " +
        "gesetzten Anzahl an Tagen, abläuft.",
    BENACHRICHTIGUNGEN_HELP_2:
        "Bei Aktivierung der E-Mail an Prüfverwaltung bekommt eine E-Mail, erhält diese eine Informationsmail, " +
        "dass die Gültigkeit des Baustellenausweises wegen fehlender Unterlagen, in der von Ihnen gesetzten Anzahl an Tagen, abläuft. ",
    BENACHRICHTIUNG_PRUEFVERWALTUNG:
        "Benachrichtung an Prüfverwaltung (Tage vor Ablauf)",
    BERECHTIGUNGEN: "Berechtigungen",
    BERECHTIGUNGSGRUPPE: "Berechtigungsgruppe",
    BERECHTIGUNGSGRUPPE_ERFOLGREICH_HINZUGEFÜGT:
        "Berechtigungsgruppe wurde erfolgreich hinzugefügt",
    BERECHTIGUNGSGRUPPE_HINZUFUEGEN: "Berechtigungsgruppe hinzufügen",
    BERECHTIGUNGSGRUPPEN_ID_KOPIEREN: "Berechtigungsgruppen-ID kopieren",
    BERECHTIGUNGSGRUPPEN: "Berechtigungsgruppen",
    BERECHTIGUNGSGRUPPEN_VERWALTEN: "Berechtigungsgruppen verwalten",
    BERECHTIGUNGSGRUPPEN_TEIL1: "Berechtigungs-",
    BERECHTIGUNGSGRUPPEN_TEIL2: "gruppen",
    BERECHTIGUNGSGRUPPEN_VERWALTEN_TEIL1: "Berechtigungs-",
    BERECHTIGUNGSGRUPPEN_VERWALTEN_TEIL2: "gruppen verwalten",
    BERECHTIGUNGSGRUPPEN_SYNCHRONISIEREN: "Berechtigunsgruppen synchronisieren",
    BERECHTIGUNGSGRUPPE_AKTUALISIEREN: "Berechtigungsgruppe aktualisieren",
    BERECHTIGUNGSGRUPPE_AUSWAEHLEN: "Berechtigungsgruppe auswählen",
    BERECHTIGUNGSGRUPPE_GETRENNT: "Berechtigungs-<br>gruppe",
    BERECHTIGUNGSGRUPPE_LOESCHEN: "Berechtigungsgruppe löschen",
    BERECHTIGUNGSGRUPPE_NICHT_ZUGEWIESEN:
        "Berechtigungsgruppe ist nicht zugewiesen",
    BESCHREIBUNG: "Beschreibung",
    BETRIEBSSANITAETER: "Betriebssanitäter*in",
    BETRIEBSSANITAETER_GETRENNT: "Betriebs-<br>sanitäter*in",
    BEZEICHNUNG: "Bezeichnung",
    BILD_MODAL: {
        TITEL: "Bild hinzufügen/entfernen",
        BODY: "Möchten Sie ein Bild hinzufügen oder entfernen?",
        BILD_HINZUFUEGEN: "Bild hinzufügen",
        ENTFERNE_BILD: "Entferne Bild",
    },
    BILDVERIFIZIERUNG: "Bildverifizierung",
    BILDVERIFIZIERUNG_AKTIVIEREN: "Bildverifizierung aktivieren",
    BILDVERIFIZIERUNG_DEAKTIVIEREN: "Bildverifizierung deaktivieren",
    BIS: "bis",
    BODY_ZUTRITTSPUNKT_LOESCHEN:
        "Wollen Sie den Zutrittspunkt wirklich löschen?",
    BRANDSCHUTZBEAUFTRAGTER: "Brandschutzbeauftragter*in",
    BRANDSCHUTZBEAUFTRAGTE_PERSONEN: "Brandschutzbeauftragte Personen",
    DATEI: "Datei",
    DATEI_AUSWAEHLEN: "Wählen Sie eine Datei aus",
    DATEI_TYPEN: {
        ERFORDERLICHE_UNTERLAGEN: "Erforderliche Unterlagen",
        WEITERE_UNTERLAGEN: "Weitere Unterlagen",
        NICHT_EU_BÜRGER_UNTERLAGEN: "Nicht EU-Bürger Unterlagen",
        A1_UNTERLAGEN: "A1 Unterlagen",
        WERKVERTRAGSARBEITNEHMERKARTE_UNTERLAGEN:
            "Werkvertragsarbeitnehmerkarte Unterlagen",
    },
    DATEI_VORHANDEN: "Datei vorhanden?",
    DATUM_AUSWAEHLEN: "Datum auswählen",
    DAVON_AKTIV: "davon {{anzahl}} aktiv ",
    DEAKTIVIERT: "Deaktiviert",
    DIENSTAG: "Dienstag",
    DOKUMENT_ENTPRUEFEN: "Prüfung Dokument widerrufen",
    DOKUMENT_ERSETZEN: "Dokument ersetzen",
    DOKUMENT_ERFOLGREICH_ERSETZT: "Dokument erfolgreich ersetzt.",
    DOKUMENT_GELOESCHT: "Dokument gelöscht",
    DOKUMENT_LOESCHEN: "Dokument löschen",
    DOKUMENT_MODAL_ENTPRUEFEN:
        "Wollen Sie dieses Dokument wirklich als nicht mehr geprüft markieren?",
    DOKUMENT_MODAL_LOESCHEN: "Wollen Sie dieses Dokument wirklich löschen?",
    DOKUMENT_MODAL_PRUEFEN:
        "Wollen Sie dieses Dokument wirklich als geprüft markieren?",
    DOKUMENT_PRUEFEN: "Dokument prüfen",
    DOKUMENT_WIRD_GELADEN: "Dokument wird geladen",
    DONNERSTAG: "Donnerstag",
    DOWNLOAD_NAME: "Downloadname",
    DREHKREUZ: "Drehkreuz XY Container",
    DRUCKEN: "Drucken",
    DRUCKVORLAGE: "Druckvorlage",
    DRUCKVORSCHAU: "Druckvorschau",
    DURCHGEFUEHRT_AM: "Durchgeführt am",
    DURCHGEFUEHRT_VON: "Durchgeführt von",
    EINGABE_ENDPOINT: "Hier Endpoint eingeben",
    EINSTELLUNGEN: "Einstellungen",
    EINSTELLUNGEN_ERFOLGREICH_GESETZT:
        "Die Einstellungen für die Baustellenausweise wurden erfolgreich gesetzt.",
    EINSTELLUNGEN_GESETZT_WERDEN:
        "Die Einstellungen für die Baustellenausweise müssen gesetzt sein.",
    EINSTELLUNGEN_SETZEN: "Einstellungen setzen",
    EINSTELLUNGEN_SYNCHRONISIEREN: "Einstellungen synchronisieren",
    EINTRITT: "Eintritt",
    ENDDATUM: "Enddatum",
    ENDPUNKT: "Endpunkt",
    ENDPUNKT_FEHLT: "Bitte geben Sie einen Endpunkt ein.",
    ENDPUNKT_VERBINDUNG_ERFOLGREICH: "Endpunkt wurde erfolgreich angesprochen.",
    ENTNPRUEFE_MINDESTLOHNERKLAERUNG_MODAL: {
        BODY: "Wollen Sie diese Mindestlohnerklärung wirklich als nicht geprüft markieren?",
        BUTTON: "Als nicht geprüft markieren",
        TITEL: "Mindestlohnerklärung Prüfung widerrufen",
    },
    ENTPRUEFT_MARKIEREN: "Als nicht geprüft markieren",
    ENTSPERREN: "Entsperren",
    ENTSPERRGRUND: "Grund der Entsperrung",
    ERFORDERLICH: "Erforderlich",
    ERFORDERLICHE_UNTERLAGEN: "Erforderliche Unterlagen",
    ERREICHBAR: "erreichbar",
    ERREICHBARKEIT_LOG: "Erreichbarkeit Log",
    ERST_MIT_SERVER_VERBINDEN:
        "Stellen Sie erst eine Verbindung zum Server her.",
    ERSTELLT_SYNCHRONISIERT_BERECHTUNGSGRUPPELIMIT:
        "Erstellt/Synchronisiert/Berechtigungsgruppenlimit",
    ERSTELLT_SYNCHRONISIERT_ZEITZONENLIMIT:
        "Erstellt/Synchronisiert/Zeitzonenlimit",
    ERSTELLUNGSDATUM: "Erstellungsdatum",
    ERSTES_EINTRITTS_EREIGNIS: "Erstes Eintritts Ereignis",
    ERSTHELFER: "Ersthelfende Person",
    ERSTHELFER_GETRENNT: "Erst-<br>helfende",
    ERSTHELFER_MEHRZAHL: "Ersthelfende Personen",
    EU_BUERGER: "EU-Bürger*in",
    EU_BUERGER_GETRENNT: "EU<br>Bürger*in",
    EU_MITGLIEDSSTAATEN: "EU-Mitgliedstaaten inkl. 4 EFTA-Staaten",
    FEHLENDE_RECHTE: "Fehlende Berechtigungen",
    FEHLENDE_RECHTE_WÄHLE_PERSONAL:
        "Fehlende Berechtigung. Wählen Sie ein Personal aus.",
    FIRMA_AKTIVIEREN: "Firma aktivieren",
    FIRMA_AKTIVIEREN_MODAL: {
        BODY: "Wollen Sie die ausgewählte Firma wirklich aktivieren?",
    },
    FIRMA_AUSWAEHLEN: "Firma auswählen",
    FIRMA_EINGEBEN: "Bitte Firmenname eingeben",
    FIRMA_ERFOLGREICH_ERSTELLT: {
        BODY: "Was möchten Sie als Nächstes tun?",
        NAVIGATIONS_TEIL1: "Weitere Firma erstellen",
        NAVIGATIONS_TEIL2: "Personal erstellen",
        NAVIGATIONS_TEIL3: "Erstellte Firma betrachten",
        NAVIGATIONS_TEIL4: "Firmenübersicht",
        TITEL: "Firma erfolgreich erstellt",
    },
    FIRMA_ERFOLGREICH_GELOESCHT: {
        BODY: "Was möchten Sie als Nächstes tun?",
        NAVIGATIONS_TEIL1: "Neue Firma erstellen",
        NAVIGATIONS_TEIL2: "Firmenübersicht",
        NAVIGATIONS_TEIL3: "Organisations-Dashboard",
        NAVIGATIONS_TEIL4: "Unterlagen-Dashboard",
        NAVIGATIONS_TEIL5: "Baustellen-Dashboard",
        NAVIGATIONS_TEIL6: "Zurück",
        TITEL: "Firma wurde erfolgreich gelöscht",
    },
    FIRMA_ERSTELLEN: "Firma erstellen",
    FIRMA_EXISTIERT: "Firma existiert bereits!",
    FIRMA_DEAKTIVIEREN: "Firma deaktivieren",
    FIRMA_DEAKTIVIEREN_MODAL: {
        BODY: "Die gesamten Baustellenausweise des Personals weden gelöscht. Wollen Sie die ausgewählte Firma wirklich deaktivieren?",
    },
    FIRMA_DOKUMENT_FILTERN: "Nach Firma bzw. Dokument filtern",
    FIRMA_LOESCHEN: "Firma löschen",
    FIRMA: "Firmenname",
    FIRMANAMEN: "Firmennamen",
    FIRMA_WURDE_ERFOLGREICH_AKTIVIERT: "Firma wurde erfolgreich aktiviert",
    FIRMA_WURDE_ERFOLGREICH_DEAKTIVIERT: "Firma wurde erfolgreich deaktiviert",
    FIRMEN: "Firmen",
    FIRMEN_AUSWERTUNGEN: "Firmen-Auswertungen",
    FIRMEN_DATEN: "Firmendaten",
    FIRMEN_FILTERN: "Nach Firmennamen filtern",
    FIRMEN_UEBERSICHT_DOWNLOAD: "Firmenübersicht herunterladen",
    FIRMEN_ZUTRITTSEREIGNISSE_AUSWERTUNGEN:
        "Auswertungen Firmenzutrittsereignisse",
    FIRMENBEZIEHUNGEN: "Firmenbeziehungen",
    FIRMENHIERACHIE: "Firmenhierarchie",
    FIRMENNAME: "Firmenname",
    FIRMENVERWALTER: "Firmenverwaltung",
    FIRMENVERWALTER_BEREITS_HINZUGEFUEGT:
        "Firmenverwaltung wurde bereits hinzugefügt",
    FIRMENVERWALTER_GELOESCHT: "Firmenverwaltung gelöscht",
    FIRMENVERWALTER_HINZUFUEGEN: "Firmenverwaltung hinzufügen",
    FIRMENVERWALTER_HINZUGEFUEGT: "Firmenverwaltung hinzugefügt",
    FIRMENVERWALTER_LOESCHEN: "Firmenverwaltung löschen",
    FIRMENVERWALTER_MODAL_LOESCHEN:
        "Wollen Sie die Firmenverwaltung wirklich löschen?",
    FIRMEN_UNTERLAGEN: "Firmen-Unterlagen",
    FIRMEN_UNTERLAGEN_AUSWERTUNGEN: "Firmen-Auswertungen",
    FIRMEN_UNTERLAGEN_AUSWERTUNGEN_DOWNLOAD:
        "Auswertung der Firmenunterlagen als PDF-Datei herunterladen",
    FIRMEN_UNTERLAGEN_HOCHLADEN: "Firmenunterlage hochladen",
    FIRMEN_UNTERLAGEN_HINZUFUEGEN: "Firmenunterlage hinzufügen",
    FIRMEN_UNTERLAGEN_KONFIGURIEREN: "Firmenunterlagen konfigurieren",
    FIRMEN_UNTERLAGEN_MODAL_AKTUALISIEREN: {
        TITEL: "Firmenunterlagen aktualisieren",
        BODY: "Wollen Sie die Firmenunterlagen wirklich aktualisieren?",
        CARD_HEADER:
            "Achtung: Bei Änderungen an den Eigenschaften der Unterlagen, bleiben bereits hochgeladenen Dateien bei den Firmen hinterlegt.",
    },
    FIRMEN_UNTERLAGEN_MODAL_LOESCHEN: {
        TITEL: "Firmenunterlagen löschen",
        BODY: "Wollen Sie die Firmenunterlagen wirklich löschen?",
        CARD_HEADER: "Warnung: Es exisiteren bereits hochgeladene Dateien",
    },
    FIRMEN_UNTERLAGEN_PRUEFEN: "Firmen-Unterlagen prüfen",
    FOTO_AUFNEHMEN: "Foto aufnehmen",
    FOTO_BENUTZEN: "Foto benutzen",
    FOTO_HOCHLADEN: "Foto hochladen",
    FOTO_MACHEN: "Foto machen",
    FUNKTION_IN_ARBEIT:
        "Aktuell ist die Angabe des Gültigkeitsdatums noch nicht möglich.",
    FREITAG: "Freitag",
    GEBURTSTAG: "Geburtstag",
    GELOESCHT: "Gelöscht",
    GEPRUEFT: "Geprüft",
    GEPRUEFT_GUELTIG: "Geprüft/Gültig",
    GEPRUEFT_HOCHGELADEN: "Geprüft/Hochgeladen",
    GEPRUEFT_HOCHGELADEN_SOLL_ABGELAUFEN:
        "Geprüft/Hochgeladen/Soll-Anzahl/Abgelaufen",
    GEPRUEFT_MARKIEREN: "Als geprüft markieren",
    GESAMT_HOCHGELADEN_GEPRUEFT_SOLL: "Gesamt(Geprüft/Hochgeladen/Soll-Anzahl)",
    GESAMT_GEPRUEFT_HOCHGELADEN_SOLL_ABGELAUFEN:
        "Gesamt(Geprüft/Hochgeladen/Soll-Anzahl/Abgelaufen)",
    GESCANNTE_KARTENNUMMER_STIMMT_NICHT:
        "Gescannte Kartennummer {{eingegebeneKartennummer}} ist nicht korrekt",
    GESPERRT: "Gesperrt",
    GEWERBLICHER_MITARBEITER: "Gewerbliches Personal",
    GEWERK: "Gewerk",
    GEWERK_AUSWAEHLEN: "Gewerke auswählen",
    GUELTIG_BIS: "gültig bis",
    GUELTIG_VON: "gültig von",
    GUELTIGKEIT: "Gültigkeit",
    GUELTIGKEIT_DOKUMENT: "Gültigkeit des Dokuments",
    GUELTIGKEITSDATUM: "Gültigkeitsdatum",
    GUELTIGKEIT_FEHLENDER_UNTLERAGEN: "Gültigkeit bei fehlender Unterlagen",
    GUELTIGKEIT_FEHLENDER_UNTLERAGEN_HELP:
        "Wählen Sie nach wie vielen Tagen die Gültigkeit des Ausweis abläuft, " +
        "wenn Unterlagen fehlen. Geben Sie dazu eine Zahl in das Eingebefeld ein. ",
    GUELTIGKEIT_HINZUFUEGEN: "Gültigkeitsdatum angeben",
    GUELTIGKEIT_VERLAENGERN: "Gültigkeit verlängern",
    HAEUFIG: "Häufig",
    HAT_GUELTIKEIT: "Gültigkeit aktiviert",
    HIERACHIE: "hierachie",
    HIERACHISCHES_LAYOUT: "Hierarchisches Layout",
    HISTORIE: "Historie",
    HOCHGELADEN: "Hochgeladen",
    HOCHGELADEN_GEPRUEFT: "Hochgeladen/Geprüft",
    HOCHGELADEN_GEPRUEFT_GUELTIGKEIT_ABGELAUFENE:
        "Hochgeladen/Geprüft/Gültigkeit/Abgelaufen",
    HOCHGELADEN_GEPRUEFT_SOLL: "Hochgeladen/Geprüft/Soll-Anzahl",
    HOCHGELADEN_GEPRUEFT_SOLL_ABGELAUFENE:
        "Hochgeladen/Geprüft/Soll-Anzahl/Abgelaufene",
    HOCHGELADEN_GEPRUEFT_SOLL_ABGELAUFENE_GUELTIGKEIT:
        "Hochgeladen/Geprüft/Soll-Anzahl/Abgelaufene/Gültigkeit",
    HOCHGELADENE_UNTERLAGEN: "Anzahl <br/> hochgeladene <br/> Unterlagen",
    HORIZONTAL_ANORDNEN: "Horizontal anordnen",
    ID_KOPIEREN: "ID kopieren",
    ID_KOPIERT: "ID kopiert",
    INAKTIV: "inaktiv",
    INFO_ABGELAUFENE_UNTERLAGEN: "Es sind Unterlagen abgelaufen",
    INFO_BA_GESPERRT: "Es gibt gesperrte Baustellenausweise \n",
    INFO_BA_NICHT_SYNCHRONISIERT:
        "Es sind nicht alle Baustellenausweise synchronisiert \n",
    INFO_KEIN_EFTA_LAND:
        "Das Herkunftsland dieser Firma oder die Staatsangehörigkeit der " +
        "mitarbeitenden Person ist kein EU-Mitgliedsstaat oder eines der 4 " +
        "EFTA-Länder. Nehmen Sie bitte Kontakt mit der zuständigen " +
        "Bauleitung auf, bevor Sie den Baustellenausweis erstellen.",
    INFO_UNTERLAGEN_NICHT_VOLLSTAENDIG:
        "Erforderliche Unterlagen sind nicht vollständig \n",
    INFO_UNTERLAGEN_NICHT_VOLLSTAENDIG_GEPRUEFT:
        "Erforderliche Unterlagen sind nicht vollständig geprüft \n",
    INFO_WEITERE_UNTERLAGEN_NICHT_VOLLSTAENDIG:
        "Erforderliche Unterlagen sind nicht vollständig \n",
    IN_RAUMZONE: "Geht in Raumzone",
    INFO_ZUTRITTSPUNKTE_OFFLINE: "Zutrittspunkte {{name}} offline",
    ISTAKTIV: "Ist Aktiv?",
    IST_SYNCHRONISIERT_GETRENNT: "Ist<br>Synchronisiert",
    JA: "Ja",
    KARTENNUMMER: "Kartennummer",
    KARTENNUMMER_PERSONAL: "Kartennummer des Personals",
    KEIN_AUFTRAGGEBER: "Kein*e Auftraggeber*in ausgewählt",
    KEIN_AUSWEIS_VORHANDEN: "Kein Ausweis vorhanden",
    KEIN_BAUSTELLENAUSWEIS_GEWAEHLT: "Kein Baustellenausweis ausgewählt",
    KEIN_NACHUNTERNEHMER: "Kein*e Nachunternehmer*in ausgewählt",
    KEIN_PERSONAL_GEWAEHLT: "Kein Personal ausgewählt",
    KEIN_PDF_FORMAT: "Datei-Format ist kein PDF.",
    KEIN_RECHT_BAUSTELLENAUSWEIS_ERSTELLEN:
        "Sie haben nicht das Recht einen Baustellenausweis zu erstellen.",
    KEIN_VERWALTER: "Kein*e Verwalter*in ausgewählt",
    KEINE_AKTION_VORHANDEN: "Keine Aktion vorhanden",
    KEINE_BAUSTELLENAUSWEISE_ABGELAUFEN: "Keine Baustellenausweise abgelaufen",
    KEINE_BAUSTELLENAUSWEISEINSTELLUNGEN_ABGELAUFEN:
        "Keine Baustellenausweiseinstellungen abgelaufen",
    KEINE_BERECHTIGUNSGRUPPEN_KONFIGURIERT:
        "Es wurden keine Berechtigungsgruppen konfiguriert",
    KEINE_ERGEBNISSE_GEFUNDEN:
        "Es tut uns Leid, für die Eingabe wurden keine Ergebnisse gefunden...",
    KEINE_FIRMA_WHITELIST:
        "Wähle eine Firma aus, um sie der Whitelist hinzufügen.",
    KEINE_FIRMENUNTERLAGEN_ANGEFORDERT:
        "Es werden keine Firmenunterlagen angefordert",
    KEINE_FIRMEN_UNTERLAGEN_KONFIGURIERT:
        "Es wurden keine Firmenunterlagen konfiguriert",
    KEINE_GESPERRTEN_BAUSTELLENAUSWEISE_VORHANDEN:
        "Keine gesperrten Baustellenausweise vorhanden",
    KEIN_GUELTIGKEITSDATUM: "Kein Gültigkeitsdatum",
    KEINE_MAIL_HINZUGEFUEGT: "Es wurde keine E-Mail hinzugefügt",
    KEINE_MITARBEITERUNTERLAGEN_ANGEFORDERT:
        "Es werden keine Unterlagen des Personals angefordert",
    KEINE_LESEEINHEIT_GEWAEHLT: "Es wurde noch keine Leseeinheit ausgewählt.",
    KEINE_PERSONAL_UNTERLAGEN_KONFIGURIERT:
        "Es wurden keine Personalunterlagen konfiguriert",
    KEINE_RAUMZONE: "Sie müssen zuerst einen Zutrittspunkt konfiguieren.",
    KEINE_VORHANDEN: "Keine vorhanden",
    KEINE_VORLAGE_VORHANDEN: "Keine Vorlage konfiguriert",
    KEINE_ZUGEWIESEN: "Keine zugewiesen",
    KEINE_SERVER_VERBINDUNG: "Keine Verbindung mit dem Server",
    KEINER_AM_DREHKREUZ:
        "Es wurde noch kein Personal am Drehkreuz registriert.",
    KEINER_VORHANDEN: "Keiner vorhanden",
    KONFIGURATION_AKTUALISIEREN: "Konfiguration aktualisieren",
    KONFIGURATION_ERFOLGREICH_AKTUALSIIERT:
        "Konfiguration erfolgreich aktualisiert",
    KONFIGURATION_ERFOLGREICH_GESPEICHERT:
        "Konfiguration erfolgreich gespeichert",
    KONFIGURATION_ERSTELLEN: "Konfiguration erstellen",
    LAND: "Land",
    LAND_AUSWAEHLEN: "Land auswählen",
    LAUFT_IN_DER_MONAT_AB: "Läuft in diesem Monat ab",
    LEEREN: "Leeren",
    LESEEINHEIT: "Leseeinheit",
    LESEEINHEIT_AUSTRITT: "Leseeinheit Austritt",
    LESEEINHEIT_EINTRITT: "Leseeinheit Eintritt",
    LESEEINHEITEN: "Leseeinheiten",
    LESEEINHEIT_AUSWAEHLEN: "Leseeinheit auswählen",
    LETZTE_AENDERUNG_GETRENNT: "Letzte<br>Änderung",
    LETZTE_SYNCRONISIERUNG_GETRENNT: "Letzte<br>Synchronisierung",
    LETZTER_AUSTRITT_GETRENNT: "Letztes<br>Austrittsereignis",
    LETZTER_EINTRITT_GETRENNT: "Letztes<br>Eintrittsereignis",
    LETZTER_FEHLENDER_MONAT: "Letzter fehlender Monat",
    LETZTES_AUSTRITTS_EREIGNIS: "Erstes Austritts Ereignis",
    LETZTES_EINTRITTS_EREIGNIS: "Letztes Eintritts Ereignis",
    LOESCHE_FIRMENUNTERLAGE: "Firmenunterlage löschen",
    LOESCHE_FIRMENUNTERLAGE_BODY:
        "Sind Sie sicher, dass Sie diese Firmenunterlage löschen möchten?",
    LOESCHE_MINDESTLOHNERKLAERUNG_MODAL: {
        BODY: "Wollen Sie diese Mindestlohnerklärung wirklich löschen?",
        TITEL: "Mindestlohnerklärung löschen",
    },
    LOESCHEN: "Löschen",
    MAIL_AN_FIRMENVERWALTUNG: "E-Mail an Firmenverwaltung",
    MAIL_AN_PRUEFVERANTWORTLICHEN: "E-Mail an Prüfverwaltung",
    MAIL_INFO_FIRMENVERWALTER:
        "Die E-Mailadresse der Firmenverwaltung wird in den " +
        "Stammdaten der Firma konfiguriert.",
    MAIL_PRUEFVERANWORTLICHEN: "E-Mail der Prüfverwaltung",
    MINDESTLOHN: "Mindestlohn",
    MINDESTLOHN_KONFIGURATION: "Mindestlohn konfigurieren",
    MINDESTLOHNERKLAERUNG_DOWNLOAD: "Mindestlohnerklärung herunterladen",
    MINDESTLOHNERKLAERUNG_ERFOLGREICH_GEPRUEFT:
        "Mindestlohnerklaerung wurde erfolgreich geprüft",
    MINDESTLOHNERKLAERUNG_EROLGREICH_GELOESCHT:
        "Mindestlohnerklaerung wurde erfolgreich gelöscht",
    MINDESTLOHNERKLAERUNG_ERFOLGREICH_HOCHGELADEN:
        "Mindestlohnerklärung erfolgreich hochgeladen",
    MINDESTLOHNERKLAERUNG_VOM_MONAT: "Mindestlohnerklärung vom Monat",
    MINDESTLOHNERKLAERUNGEN: "Mindestlohnerklärungen",
    MINDESTLOHNERKLAERUNGEN_FILTERN:
        "Mindestlohnerklärungen oder Personal filtern",
    MINDESTLOHNERKLAERUNGEN_GESAMT: "Mindestlohnerklärungen (gesamt)",
    MINDESTLOHNERKLAERUNGEN_GETRENNT: "Mindestlohn-erklärungen",
    MINDESTLOHNERKLAERUNGEN_PRUEFEN: "Mindestlohnerklärungen prüfen",
    MINDESTLOHNKONFIGURATOR: "Mindestlohnkonfigurator",
    MINDESTLOHNKONFIGURATOR_NICHT_EINGESTELLT:
        "Mindestlohnkonfigurator wurde noch nicht eingestellt",
    MINDESTLOHNPFLICHTIG: "Mindestlohnpflichtig",
    MITARBEITER: "Personal",
    MITARBEITER_AKTIVIEREN: "Personal aktivieren",
    MITARBEITER_AKTIVIEREN_MODAL: {
        BODY: "Wollen Sie das ausgewählte Personal wirklich aktivieren?",
    },
    MITARBEITER_DEAKTIVIEREN: "Personal deaktivieren",
    MITARBEITER_DEAKTIVIEREN_MODAL: {
        BODY: "Baustellenausweis des Personals wird gelöscht. Wollen Sie wirklich deaktivieren?",
    },
    MITARBEITER_ERSTELLT_MODAL: {
        AUSWAHL_1: "Weiteres Personal erstellen",
        AUSWAHL_2: "Erstelltes Personal betrachten",
        AUSWAHL_3: "Zugehörige Firma betrachten",
        AUSWAHL_4: "Übersicht des Personals",
        AUSWAHL_5: "Organsisation",
        AUSWAHL_6: "Baustelle",
        BODY: "Was möchten Sie als Nächstes tun?",
        TITEL: "Personal erfolgreich erstellt",
    },
    MITARBEITER_GELOESCHT: "Personal wurde gelöscht",
    MITARBEITER_LOESCHEN: "Personal löschen",
    MITARBEITER_LOESCHEN_MODAL: {
        BODY: "Wollen Sie das ausgewählte Personal wirklich löschen?",
    },
    MITARBEITER_UNTERLAGEN: "Personalunterlagen",
    MITARBEITER_UNTERLAGEN_HINZUFUEGEN: "Personalunterlage hinzufügen",
    MITARBEITER_UNTERLAGEN_HOCHLADEN: "Personalunterlagen hochladen",
    MITARBEITER_UNTERLAGEN_MODAL_AKTUALISIEREN: {
        TITEL: "Personalunterlagen aktualisieren",
        BODY: "Wollen Sie die Personalunterlagen wirklich aktualisieren?",
        CARD_HEADER:
            "Achtung: Bei Änderungen an den Eigenschaften der Unterlagen, bleiben bereits hochgeladenen Dateien bei dem Personal hinterlegt.",
    },
    MITARBEITER_UNTERLAGEN_MODAL_HINZUFUEGEN: {
        DATEI_HINZUFUEGEN: "Datei hinzufügen",
        NAME_EINGEBEN: "Name eingeben",
        A1PFLICHT: "A1-Pflicht",
        GUELTIGKEIT: "Gültigkeit",
        AUSWIRKUNGEN_AUF_AUSWEISGUELTIGKEIT:
            "Auswirkungen auf Ausweisgültigkeit",
    },
    MITARBEITER_UNTERLAGEN_MODAL_LOESCHEN: {
        TITEL: "Personalunterlage löschen",
        BODY: "Wollen Sie die Personalunterlage wirklich löschen?",
        CARD_HEADER: "Warnung: Es exisiteren bereits hochgeladene Dateien",
    },
    MITTWOCH: "Mittwoch",
    MODAL_AUSWEIS_LOESCHEN: {
        TITEL: "Ausweisvorlage löschen",
        BODY: " Wollen Sie die Ausweisvorlage wirklich löschen?",
        INFO_CARD_HEADER: "Warnung",
        INFO_CARD_CONTENT:
            "Nachdem Löschen der Vorlage steht diese nicht mehr als Druckvorlage für Baustellenausweise zur Verfügung.",
        PLACEHOLDER: "Name der Vorlage eingeben",
    },
    MODAL_BODY_FIRMA_LOESCHEN: "Wollen Sie die Firma wirklich löschen?",
    MODAL_TEXT: {
        TITEL: "Text bearbeiten",
        SCHRIFTFARBE: "Schriftfarbe",
        SCHRIFT_FETT: "Schrift in Fett",
        TEXT_AUSBLENDEN: "Text ausblenden",
        AENDERN: "Ändern",
    },
    MODAL_VORLAGE_HOCHLADEN: {
        TITEL: "Vorlage hochladen",
        VORLAGE_ERSTELLEN: "Vorlage erstellen",
    },
    MONAT_FILTERN: "Monat filtern",
    MONATLICH: "Monatlich",
    MONTAG: "Montag",
    MUSTERFIRMA: "Musterfirma",
    MUSTERMANN: "Mustermann",
    MUSTERSTADT: "Musterstadt",
    MUSTERSTRASSE: "Musterstraße 123",
    MUSTERTITEL: "Mustertitel",
    NACHNAME: "Nachname",
    NACHREICHEN: "Nachreichen",
    NACHUNTERNEHMER: "Nachunternehmer*in",
    NACHUNTERNEHMER_GELOESCHT: "Nachunternehmer*in gelöscht",
    NACHUNTERNEHMER_HINZUFUEGEN: "Nachunternehmer*in hinzufügen",
    NACHUNTERNEHMER_HINZUGEFUEGT: "Nachunternehmer*in hinzugefügt",
    NACHUNTERNEHMER_LOESCHEN: "Nachunternehmer*in löschen",
    NACHUNTERNEHMER_MODAL_LOESCHEN:
        "Wollen Sie den/die Nachunternehmer*in wirklich löschen?",
    NAME: "Name",
    NEIN: "Nein",
    NEUE_VORLAGE: "Neue Vorlage",
    NEUE_VORLAGE_ERSTELLEN: "Neue Vorlage erstellen",
    NICHT_ERFORDERLICH: "Nicht erforderlich",
    NICHT_EU: "Nicht EU",
    NICHT_EU_BUERGER: "Nicht-EU-Bürger",
    NICHT_EU_BUERGERUNTERLAGEN_UNVOLLSTAENDIG:
        "Unterlagen der Nicht EU-Bürger*innen sind nicht vollständig",
    NICHT_EU_BUERGER_UNTERLAGEN: "Nicht EU-Unterlagen",
    NICHT_HOCHGELADEN: "Nicht Hochgeladen",
    NICHT_GEPRUEFT: "Nicht geprüft",
    NICHT_VORHANDEN: "nicht vorhanden",
    NOCH_NICHT_GEPRUEFT: "Noch nicht geprüft",
    NOCH_NICHT_HOCHGELADEN: "Noch nicht hochgeladen",
    OEFFNE_BILDVERIFIZIERUNG: "Bildverifizierung öffnen",
    OFFLINE: "offline",
    OHNE_BILD_DRUCKEN: "Ohne Bild drucken",
    OHNE_DRUCKEN_FORTFAHREN: "Ohne drucken fortfahren",
    OHNE_SYNCRONISIERUNG: "Ohne Synchronisierung",
    ONLINE: "online",
    OPTION_AUSWEIS_KONFIGURATION: {
        TITEL: "Warnung! Bereits bestehendes Personal.",
        BODY: "Möchten Sie Ihre Einstellungen für bereits existierendes Personal übernehmen?",
        AUSWAHL_MOEGLICHKEIT_1: "Ja, auch für existierende Personal.",
        AUSWAHL_MOEGLICHKEIT_2: "Nein, nur für neu erstelltes Personal",
    },
    ORGANISATION: "Organisation",
    ORT: "Ort",
    PERSONAL: "Personal",
    PERSONAL_AKTIVIEREN: "Personal aktivieren",
    PERSONALANZAHL: "Personalanzahl",
    PERSONAL_AUSWERTUNGEN: "Personal-Auswertungen",
    PERSONAL_AUSWERTUNGEN_DOWNLOAD: "Personalauswertungen herunterladen",
    PERSONAL_DATEN: "Personaldaten",
    PERSONAL_DEAKTIVIEREN: "Personal deaktivieren",
    PERSONAL_DOKUMENT_FILTERN: "Nach Personal oder Dokument filtern",
    PERSONAL_ERSTELLEN: "Personal erstellen",
    PERSONAL_EINGEBEN: "Bitte Personalname eingeben",
    PERSONAL_ERFOLGREICH_GELOESCHT: {
        BODY: "Was möchten Sie als Nächstes tun?",
        NAVIGATIONS_TEIL1: "Neues Personal erstellen",
        NAVIGATIONS_TEIL2: "Personalübersicht",
        NAVIGATIONS_TEIL3: "Organisations-Dashboard",
        NAVIGATIONS_TEIL4: "Unterlagen-Dashboard",
        NAVIGATIONS_TEIL5: "Baustellen-Dashboard",
        NAVIGATIONS_TEIL6: "Zurück",
        TITEL: "Personal wurde erfolgreich gelöscht",
    },
    PERSONAL_FILTERN: "Nach Personal filtern",
    PERSONAL_WURDE_ERFOLGREICH_AKTIVIERT: "Personal wurde erfolgreich aktiviert",
    PERSONAL_WURDE_ERFOLGREICH_DEAKTIVIERT: "Personal wurde erfolgreich deaktiviert",
    PERSONAL_WURDE_ERFOLGREICH_VERWARNT: "Personal wurde erfolgreich verwarnt",
    PERSONAL_VERWARNEN: "Personal verwarnen",
    PERSONAL_VERWARNEN_MODAL: "Möchten Sie das Personal wirklich verwarnen?",
    PERSONAL_UEBERSICHT_DOWNLOAD: "Personalübersicht herunterladen",
    PERSONAL_UNTERLAGEN: "Personal-Unterlagen",
    PERSONAL_UNTERLAGEN_AUSWERTUNGEN: "Personal-Auswertungen",
    PERSONAL_UNTERLAGEN_AUSWERTUNGEN_DOWNLOAD:
        "Auswertung der Personalunterlagen als PDF-Datei herunterladen",
    PERSONAL_UNTERLAGEN_KONFIGURIEREN: "Personalunterlagen konfigurieren",
    PERSONAL_UNTERLAGEN_PRUEFEN: "Personal-Unterlagen prüfen",
    PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN:
        "Auswertungen Personalzutrittsereignisse",
    PERSONALNAME: "Personalname",
    PDF_BESTAETIGUNG_WIDERRUFEN: "PDF-Datei Bestätigung widerrufen",
    PDF_DATEI: "PDF-Datei",
    PDF_DATEI_ANZEIGEN: "PDF-Datei anzeigen",
    PDF_DATEI_ARCHIVIEREN: "PDF-Datei archivieren",
    PDF_DATEI_HOCHLADEN: "PDF-Datei hochladen",
    PDF_DATEI_LOESCHEN: "PDF-Datei endgültig löschen",
    PDF_DATEI_VORLAGE_HERUNTERLADEN: "PDF-Datei Vorlage herunterladen",
    PDF_ECHTHEIT_BESTAETIGEN: "PDF-Datei Echtheit bestätigen",
    PLZ: "Postleitzahl",
    PROFILBILD_ERFOLGREICH_HOCHGELADEN: "Profilbild erfolgreich hochgeladen",
    PROFILBILD_WIRD_HOCHGELADEN: "Profilbild wird hochgeladen",
    PROJEKTZEITEN_AKTIVIEREN: "Projektzeiten aktivieren",
    PROJEKTZEITEN_DEAKTIVIEREN: "Projektzeiten deaktivieren",
    PRUEFE_MINDESTLOHNERKLAERUNG_MODAL: {
        BODY: "Wollen Sie diese Mindestlohnerklärung wirklich als geprüft markieren?",
        BUTTON: "Als geprüft markieren",
        TITEL: "Mindestlohnerklärung prüfen",
    },
    PRUEFER: "Prüfer",
    PRUEFUNG_WIEDERRUFEN: "Prüfung widerrufen",
    PRUEFVERANTWORTLICHE: "Prüfverantwortliche Person",
    PRUEFVERWALTUNG_MAIL: "Mail der Prüfverwaltung",
    RAUMZONEN: "Raumzonen",
    RESTLICHE_LAENDER: "Restliche Länder",
    RICHTUNG: "Richtung",
    SAMSTAG: "Samstag",
    SANITAETER: "Sanitäter*in",
    SIDEBAR: {
        PROJEKTROLLEN: "Projektrollen erstellen, verwalten und zuweisen",
        PROJEKTBETEILIGTE: "Projektbeteiligte einladen und verwalten",
        AUSWERTUNGEN: "Projektauswertungen betrachten und exportieren",
        AUSWERTUNGEN_EINSTELLUNGEN:
            "Automatischen Mailversand von Auswertungen verwalten",
        ORGANISATION: "Firmen,- und Personal erstellen und verwalten",
        UNTERLAGEN: "Firmen,- Personal und Mindestlohnunterlagen verwalten",
        BAUSTELLE:
            "Baustellenausweise, Drehkreuze und Zutrittspunkte verwalten",
        ZUKO_EINSTELLUNGEN:
            "Adminstrative Einstellungen der Zutrittskontrolle verwalten",
    },
    SONNTAG: "Sonntag",
    SPEICHERN: "Speichern",
    SPERRE_MODAL: {
        BODY: "Wollen Sie wirklich alle Baustellenausweise der mitarbeitenden Person sperren?",
        BUTTON: "Alle Baustellenausweise sperren",
        EINGABE_SPERRGRUND: "Hier Sperrgrund eingeben",
        SPERRGRUND: "Sperrgrund",
        TITEL: "Baustellenausweise sperren",
    },
    SPERREN: "Sperren",
    SPERRUNG_AM: ", Sperrung jeden Monat am {{stichtag}}.",
    SPRACHE: "Sprache",
    STAATSANGEHOERIGKEIT: "Staatsangehörigkeit",
    STAATSANGEHOERIGKEIT_AUSWAEHLEN: "Staatsangehörigkeit auswählen",
    STAMMDATEN: "Stammdaten",
    STAMMDATEN_AKTUALISIEREN: "Stammdaten aktualisieren",
    STAMMDATEN_AKTUALISIERT: "Stammdaten aktualisiert",
    STAMMDATEN_ERFOLGREICH_AKTUALISIERT: "Stammdaten erfolgreich aktualisiert",
    STARTDATUM: "Startdatum",
    STARTDATUM_VOR_ENDDATUM:
        "Das Startdatum darf nicht über dem Enddatum liegen",
    STATUS: "Status",
    STICHTAG_AM: "Stichtag am",
    STRASSE: "Straße",
    SYNCHRONISIERE_ZUTRITTSEREIGNISSE: "Synchronisiere Zutrittsereignisse",
    SYNCHRONISIERT: "Synchronisiert",
    SYSTEMGUELTIGKEIT: "Systemgültigkeit gültig bis",
    SYSTEMVORLAGE: "Systemvorlage",
    TAG: "Tag",
    TOAST_NEUER_STATUS_ZUTRITTSZEITEN_AKTIVIERT:
        "Status der Zutrittszeiten wurden erforlgreich geändert. Neuer Status: aktiviert",
    TOAST_NEUER_STATUS_ZUTRITTSZEITEN_DEAKTIVIERT:
        "Status der Zutrittszeiten wurden erforlgreich geändert. Neuer Status: deaktiviert",
    TOAST_NEUER_STATUS_BILDVERIFIZIERUNG_AKTIVIERT: "Status der Bildverifizierung wurden erforlgreich geändert. Neuer Status: aktiviert",
    TOAST_NEUER_STATUS_BILDVERIFIZIERUNG_DEAKTIVIERT: "Status der Bildverifizierung wurden erforlgreich geändert. Neuer Status: deaktiviert",
    TOOLTIP_BAUSTELLENAUSWEISE: "Synchronisiert/Gesperrt/Gesamtanzahl",
    TRENNEN: "Trennen",
    TYP: "Typ",
    UEBERSICHT: "Übersicht",
    UEBERSICHT_EINGEBENER_DATEN: "Übersicht Ihrer eingegeben Daten",
    UHRZEIT: "Uhrzeit",
    UNBEFRISTET: "unbefristet",
    UNBEGRENZT: "unbegrenzt",
    UNERREICHBAR: "unerreichbar",
    UNTERLAGEN: "Unterlagen",
    UNTERLAGEN_AUSWERTUNGEN: "Auswertungen Unterlagen",
    UNTERLAGEN_AUSWERTUNGEN_TEIL1: "Auswertungen",
    UNTERLAGEN_AUSWERTUNGEN_TEIL2: "Unterlagen",
    UNTERLAGEN_DATEN: "Unterlagendaten",
    UNTERLAGEN_HINZUFUEGEN: "Unterlagen hinzufügen",
    UNTERLAGEN_NAME: "Unterlagenname",
    UNTERLAGE_ENTPRUEFT_MARKIEREN: "Unterlage als nicht geprüft markiert",
    UNTERLAGE_ERFOLGREICH_ENTPRUEFT:
        "Prüfung für Unterlage wurde erfolgreich widerrufen",
    UNTERLAGE_ERFOLGREICH_GELOESCHT: "Unterlage erfolgreich gelöscht",
    UNTERLAGE_GEPRUEFT_MARKIEREN: "Unterlage als geprüft markiert",
    UNTERLAGE_HINZUFUEGEN: "Unterlage hinzufügen",
    UNTERLAGE_HINZUGEFUEGT: "Unterlage hinzugefügt",
    UNTERLAGE_ERFOLGREICH_WIEDERHERGESTELLT:
        "Unterlage erfolgreich wiederhergestellt",
    UNTERLAGEN_MODAL_HINZUFUEGEN: {
        DATEI_HINZUFUEGEN: "Datei hinzufügen",
        GUELTIGKEIT: "Gültigkeit",
        NAME_EINGEBEN: "Bitte Namen eingeben",
        AUSWIRKUNGEN_AUF_AUSWEISGUELTIGKEIT:
            "Auswirkungen auf Ausweisgültigkeit",
    },
    VERBINDEN: "Verbinden",
    VERBINDUNG: "Verbindung",
    VERBINDUNG_MIT_DEM_SERVER: "Mit dem Server verbunden",
    VERBINDUNG_TESTEN: "Verbindung testen",
    VERLAENGERUNGSGRUND: "Verlängerungsgrund",
    VERWALTER: "Verwaltung",
    VERWALTER_HINZUFUEGEN: "Verwaltung hinzufügen",
    VERWENDUNG_IN_BERECHTIGUNGSGRUPPEN: "Verwendung in Berechtigunsgruppen",
    VON: "von",
    VORNAME: "Vorname",
    VORHANDEN: "Vorhanden",
    VORLAGE: "Vorlage",
    VORLAGE_ANZEIEGEN: "Vorlage anzeigen",
    VORLAGE_AKTUALISIEREN: "Vorlage aktualisieren",
    VORLAGE_AUSWAEHLEN: "Vorlage auswählen",
    VORLAGE_BEARBEITEN: "Vorlage bearbeiten",
    VORLAGE_LOESCHEN: "Vorlage loeschen",
    VORLAGE_EROFLGREICH_AKTUALISIERT: "Vorlage wurde erfolgreich aktualisiert",
    VORLAGE_EROFLGREICH_ERSTELLT: "Vorlage wurde erfolgreich erstellt",
    VORLAGE_EROFLGREICH_GELOESCHT: "Vorlage wurde erfolgreich gelöscht",
    VORLAGE_SPEICHERN: "Vorlage speichern",
    VORLAGE_VORHANDEN: "Vorlage <br/> vorhanden?",
    VORLAGEN: "Vorlagen",
    VORLAGENNAME: "Vorlagenname",
    WEITERE_MITARBEITERUNTERLAGE_HINZUGEFUEGT:
        "Weitere Unterlage dem Personal wurde erfolgreich hinzugefügt.",
    WEITERE_UNTERLAGE_NAME_EXISITIERT:
        "Weiter Unterlagenname existiert bereits.",
    WEITERE_UNTERLAGEN: "Weitere Unterlagen",
    WEITERE_UNTERLAGEN_GETRENNT: "Weitere<br>Unterlagen",
    WEITERE_UNTERLAGEN_HINZUFUEGEN: "Weitere Unterlagen hinzufügen",
    WEITERE_UNTERLAGE_HINZUFUEGEN: "Weitere Unterlage hinzufügen",
    WERKVERTRAGSARBEITNEHMERKARTE:
        "Karte der Werkvertragsarbeitnehmenden Person",
    WERKVERTRAGSARBEITNEHMERKARTE_NICHT_VOLLSTAENDIG:
        "Karte der Werkvertragsarbeitnehmenden Person ist nicht vollständig",
    WEVAK_PFLICHT: "WeVAK-Pflicht",
    WEVAK_UNTERLAGEN: "WeVAK-Unterlagen",
    WHITELIST: "Whitelist",
    WIEDERHERSTELLEN: "Unterlage wiederherstellen",
    WIRD_NACHGEREICHT: "wird nachgereicht",
    WURDE_NICHT_GEPRUEFT: "wurde nicht geprüft",
    WURDE_NICHT_HOCHGELADEN: "wurde nicht hochgeladen",
    ZEITABSCHNITTE: "Zeitabschnitte",
    ZEITINTERVALL: "Zeitintervall",
    ZEITSTEMPEL: "Zeitstempel",
    ZEITZONE_AKTUALISIEREN: "Zeitzone aktualisieren",
    ZEITZONE_AKTUALISIERT: "Zeitzone wurde erfolgreich aktualisiert",
    ZEITZONE_AUSWAEHLEN: "Zeitzone auswählen",
    ZEITZONE_HINZUFUEGEN: "Zeitzone hinzufügen",
    ZEITZONE_HINZUGEFUEGT: "Zeitzone wurde erfolgreich hinzugefügt",
    ZEITZONE_GELOESCHT: "Zeitzone wurde erfolgreich gelöscht",
    ZEITZONE_ID: "Zeitzone Id",
    ZEITZONE_LOESCHEN: "Zeitzone löschen",
    ZEITZONE_SYNCHRONISIERT: "Zeitzonen synchronisieren",
    ZEITZONE_WIRKLICH_LOESCHEN: "Wollen Sie die Zeitzone wirklich löschen?",
    ZEITZONEN: "Zeitzonen",
    ZEITZONEN_ERFOLGREICH_SYNCHRONISIERT:
        "Zeitzonen wurden erfolgreich synchronisiert",
    ZEITZONEN_LEER: "Keine Zeitzonen vorhanden",
    ZEITZONEN_NAME_SYNCHRONISIERT: "Zeitzonenname existiert bereits!",
    ZEITZONEN_VERWALTEN: "Zeitzonen verwalten",
    ZEITZONEN_VERWALTEN_TEIL1: "Zeitzonen",
    ZEITZONEN_VERWALTEN_TEIL2: "verwalten",
    ZU_DEM_BAUSTELLENAUSWEIS: "Zu dem Baustellenausweis ({{mitarbeiter}})",
    ZU_DEM_PERSONAL: "Zu dem Personal",
    ZU_DEM_AUSWEIS_DESIGNER: "Zu dem Ausweis-Desingner",
    ZU_DEM_AUSWEISGUELTIGKEIT_KONFIGURATOR:
        "Zu dem Ausweisgültigkeit-Konfigurator",
    ZU_DEM_MINDESTLOHNKONFIGURATOR: "Zu dem Mindestlohnkonfigurator",
    ZU_DEN_BAUSTELLENAUSWEISEN: "Zu den Baustellenausweisen",
    ZU_DEN_BERECHTIGUNGSGRUPPEN: "Zu den Berechtigungsgruppen",
    ZU_DEN_FIRMEN: "Zu den Firmen",
    ZU_DEN_FIRMEN_AUSWERTUNGEN: "Zu den Firmen-Auswertungen",
    ZU_DEN_FIRMEN_UNTERLAGEN: "Zu den Firmen-Unterlagen {{firma}}",
    ZU_DEN_FIRMENBEZIEHUNGEN: "Zu den Firmenbeziehungen",
    ZU_DEN_MINDESTLOHNERKLAERUNGEN: "Zu den Mindestlohnerklärungen",
    ZU_DEN_MITARBEITERN: "Zu dem Personal",
    ZU_DEN_PERSONAL_UNTERLAGEN: "Zu den Personal-Unterlagen {{mitarbeiter}}",
    ZU_DEN_PERSONALAUSWERTUNGEN: "Zu den Personal-Auswertungen",
    ZU_DEN_STAMMDATEN: "Zu den Stammdaten",
    ZU_DEN_UNTERLAGEN: "Zu den Unterlagen",
    ZU_DEN_VERWALTERN: "Zu der Verwaltung",
    ZU_DEN_ZEITZONEN: "Zu den Zeitzonen",
    ZU_DEN_ZUKO_SERVERN: "Zu den Zuko-Server",
    ZU_DEN_ZUTRITTSEREIGNIS_AUSWERTUNGEN:
        "Zu den Auswertungen der Zutrittsereignisse",
    ZU_DEN_ZUTRITTSPUNKTEN: "Zu den Zutrittspunkten",
    ZU_DER_AKTUELLEN_BESETZUNG: "Zu der aktuellen Besetzung",
    ZU_DER_BILDVERIFIZIERUNG: "Zu der Bildverifizierung",
    ZU_DER_FIRMA: "Zu der Firma {{firma}}",
    ZU_DER_FIRMENHIERACHIE: "Zu der Firmenhierarchie",
    ZUKO_KONFIGURIEREN: "Zuko konfigurieren",
    ZUKO_SERVER: "Zuko Server",
    ZUKO_SERVER_AUSWAEHLEN: "Zuko Server auswählen",
    ZUKO_SERVER_ERFOLGREICH_HINZUGEFUEGT:
        "Zuko Server wurde erfolgreich hinzugefügt",
    ZUKO_SERVER_ERFOLGREICH_KONFIGURIERT: "Zuko wurde erfolgreich konfiguriert",
    ZUKO_SERVER_HINZUFUEGEN: "Zuko Server hinzufügen",
    ZUKO_SERVER_KONFIGURIEREN: "Zuko Server konfigurieren",
    ZUTRITTSEREIGNISSE: "Zutrittsereignisse",
    ZUTRITTSEREIGNISSE_SYNCHRONISIERT:
        "Es wurden {{input}} Zutrittsereignisse synchronisiert.",
    ZUTRITTSPUNKT: "Zutrittspunkt",
    ZUTRITTSPUNKT_AKTUALISIEREN: " Zutrittspunkt aktualisieren",
    ZUTRITTSPUNKT_AKTUALISIERT: "Zutrittspunkt wurde erfolgreich hinzugefügt",
    ZUTRITTSPUNKT_GELOESCHT: "Zutrittspunkt wurde erfolgreich gelöscht",
    ZUTRITTSPUNKT_HINZUFUEGEN: "Zutrittspunkt hinzufügen",
    ZUTRITTSPUNKT_RICHTUNG: "Zutrittspunkt - Richtung",
    ZUTRITTSPUNKTE_LEER: "Keine Zutrittspunkte vorhanden",
    ZUTRITTSPUNKT_LOESCHEN: "Zutrittspunkt löschen",
    ZUTRITTSPUNKT_NAME_AKTUALISIEREN: "Zutrittspunktname aktualisieren",
    ZUTRITTSPUNKT_NAME_AKTUALISIERT:
        "Zutrittspunktname wurde erfolgreich aktualisiert",
    ZUTRITTSPUNKTE: "Zutrittspunkte",
    ZUTRITTSPUNKTE_VERWALTEN: "Zutrittspunkte verwalten",
};
export default TRANSLATIONS_DE_ZUKO;
