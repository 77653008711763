import {
  BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
  BERECHTIGUNGSGRUPPEN_ACTION_BEGIN,
  FETCH_BERECHTIGUNGSGRUPPEN_SUCCESS,
  RESET_BERECHTIGUNGSGRUPPEN_SUCCESS,
  LOESCHE_BERECHTIGUNGSGRUPPE_SUCCESS,
  ERSTELLE_BERECHTIGUNGSGRUPPE_SUCCESS,
  AKTUALISIERE_BERECHTIGUNGSGRUPPE_SUCCESS,
  FETCH_BERECHTIGUNGSGRUPPEN_ADMIN_SUCCESS,
  RESET_BERECHTIGUNGSGRUPPEN_ADMIN_SUCCESS,
  SYNCHRONISIERE_BERECHTIGUNGSGRUPPEN_SUCCESS,
} from "../../actions/zuko/berechtigungsgruppen.js";

const berechtigungsgruppenState = {
  error: null,
  loading: false,
  berechtigungsgruppenAdmin: {},
  berechtigungsgruppen: [],
};

const savedState =
  localStorage.getItem("savedState") ||
  JSON.stringify({ ...berechtigungsgruppenState });
const INITIAL_STATE =
  JSON.parse(savedState).berechtigungsgruppenState || berechtigungsgruppenState;

const berechtigungsgruppen = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BERECHTIGUNGSGRUPPEN_ACTION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case BERECHTIGUNGSGRUPPEN_ACTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_BERECHTIGUNGSGRUPPEN_ADMIN_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        berechtigungsgruppenAdmin: action.payload,
      };
    case FETCH_BERECHTIGUNGSGRUPPEN_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        berechtigungsgruppen: action.payload,
      };
    case RESET_BERECHTIGUNGSGRUPPEN_ADMIN_SUCCESS:
      return {
        ...state,
        error: null,
        berechtigungsgruppenAdmin: {},
        loading: false,
      };
    case RESET_BERECHTIGUNGSGRUPPEN_SUCCESS:
      return {
        ...state,
        error: null,
        berechtigungsgruppen: [],
        loading: false,
      };
    case LOESCHE_BERECHTIGUNGSGRUPPE_SUCCESS:
    case ERSTELLE_BERECHTIGUNGSGRUPPE_SUCCESS:
    case AKTUALISIERE_BERECHTIGUNGSGRUPPE_SUCCESS:
    case SYNCHRONISIERE_BERECHTIGUNGSGRUPPEN_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default berechtigungsgruppen;
