//#region LitElement, Polymer
import {LitElement, css} from 'lit';
import { html } from "lit/html.js"
import { connect } from "pwa-helpers/connect-mixin.js";
//#endregion

//#region Store, Redux
import { store } from "../../store.js";
//#endregion

//#region Styles
import { VisStyles } from "../../vis/vis-styles.js";
import { AlgoStyles } from "../../css/algo-styles.js";
import { SbAdmin2Styles } from "../../css/sb-admin-2-styles.js";
import { FontawesomeIcons } from "../../css/fontawesome-icons.js";
//#endregion

//#region Translation
import { translate } from "../../i18n";
//#endregion

export class VisComponent extends connect(store)(LitElement) {
    //#region Styles
    static get styles() {
        return [
            SbAdmin2Styles,
            AlgoStyles,
            FontawesomeIcons,
            VisStyles,
            css`
                #network {
                    width: 100%;
                    border: 1px solid lightgray;
                }
            `,
        ];
    }
    //#endregion

    //#region Properties
    static get properties() {
        return {
            _network: { type: Object },
            _checkboxEnabled: { type: Boolean },
            _networkOptions: { type: Object },
            _nodes: { type: Object },
            _edges: { type: Object },
            height: { type: String },
            netzwerk: { type: Object },
            loading: { type: Boolean },
        };
    }
    //#endregion

    //#region render
    _renderHierarchischesLayout() {
        const disabled = !this._checkboxEnabled;
        return html`
            <form class="form-inline">
                <div class="custom-control custom-checkbox my-1 mr-sm-2">
                    <input
                        type="checkbox"
                        ?disabled="${this.loading}"
                        class="custom-control-input"
                        id="hierarchical-checkbox"
                        @input="${this._onHierarchischesLayoutClick}"
                    />
                    <label
                        class="custom-control-label"
                        for="hierarchical-checkbox"
                        >${translate("ZUKO.HIERACHISCHES_LAYOUT")}</label
                    >
                </div>
            </form>
            <form style="margin-left: 50px;" class="form-inline">
                <div class="custom-control custom-checkbox my-1 mr-sm-2">
                    <input
                        ?disabled="${disabled}"
                        type="checkbox"
                        class="custom-control-input"
                        id="hierarchical-direction-checkbox"
                        @input="${this._onHierarchischesLayoutDirectionClick}"
                    />
                    <label
                        class="custom-control-label"
                        for="hierarchical-direction-checkbox"
                        >${translate("ZUKO.HORIZONTAL_ANORDNEN")}</label
                    >
                </div>
            </form>
        `;
    }

    render() {
        return html`
            ${this._renderHierarchischesLayout()}
            <div id="network"></div>
        `;
    }
    //#endregion

    constructor() {
        super();
        this.loading = false;
        this.height = "";
        this._network = {};
        this._checkboxEnabled = false;

        this._nodes = new vis.DataSet([]);
        this._edges = new vis.DataSet([]);
        this._networkOptions = {
            height: this.height,
            layout: {
                hierarchical: {
                    enabled: false,
                    direction: "UD",
                    sortMethod: "directed",
                },
            },
            edges: {
                arrows: "to",
            },
            physics: {
                repulsion: {
                    nodeDistance: 100,
                },
            },
        };
    }

    //#region Hierachie
    _onHierarchischesLayoutDirectionClick() {
        const input = this.shadowRoot.querySelector(
                "#hierarchical-direction-checkbox"
            ),
            enabled = input.checked;

        if (enabled) {
            this._network.setOptions({
                layout: {
                    hierarchical: {
                        direction: "LR",
                    },
                },
            });
        } else {
            this._network.setOptions({
                layout: {
                    hierarchical: {
                        direction: "UD",
                    },
                },
            });
        }
    }

    _onHierarchischesLayoutClick() {
        const input = this.shadowRoot.querySelector("#hierarchical-checkbox");
        this._checkboxEnabled = input.checked;

        this._network.setOptions({
            layout: {
                hierarchical: {
                    enabled: this._checkboxEnabled,
                },
            },
        });
    }

    _resetAllNodes() {
        this._nodes.clear();
        this._edges.clear();
        if (this.netzwerk) {
            this._nodes.add(this.netzwerk.nodes);
            this._edges.add(this.netzwerk.edges);
        }
        this._networkOptions.height = this.height;
        const container = this.shadowRoot.getElementById("network"),
            data = {
                nodes: this._nodes,
                edges: this._edges,
            };
        this._network = new vis.Network(container, data, this._networkOptions);
        this._network.moveTo({
            position: { x: 0, y: 0 },
            offset: {
                x: window.innerWidth / 5,
                y: window.innerHeight / 3,
            },
            scale: 1,
        });
    }

    //#endregion firstUpdated
    firstUpdated() {
        const container = this.shadowRoot.getElementById("network"),
            data = {
                nodes: this._nodes,
                edges: this._edges,
            };
        this._network = new vis.Network(container, data, this._networkOptions);
        this._network.moveTo({
            position: { x: 0, y: 0 },
            offset: {
                x: window.innerWidth / 5,
                y: window.innerHeight / 3,
            },
            scale: 1,
        });
    }

    updated(properties) {
        properties.forEach((oldValue, propName) => {
            if (propName === "netzwerk") {
                if (this.netzwerk !== oldValue) {
                    if (this.netzwerk.nodes && this.netzwerk.edges) {
                        this._resetAllNodes();
                    }
                }
            }
        });
    }
    //#endregion
}
window.customElements.define("vis-component", VisComponent);
