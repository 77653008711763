import {LitElement, css} from 'lit';
import { html } from "lit/html.js"
import { AlgoStyles } from "../css/algo-styles.js";
import { FontawesomeIcons } from "../css/fontawesome-icons.js";
import { SbAdmin2Styles } from "../css/sb-admin-2-styles.js";

class AsdHelper extends LitElement {
    static get styles() {
        return [
            AlgoStyles,
            SbAdmin2Styles,
            FontawesomeIcons,
            css`
                .asd-row {
                    margin-bottom: 10px;
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    -webkit-box-pack: justify;
                    justify-content: space-between;
                }
                .asd-col-1 {
                    display: flex;
                    align-self: flex-start;
                    min-width: 30%;
                    max-width: 40%;
                    padding: 0px 15px 0px 0px;
                    flex-direction: column;
                    box-sizing: border-box;
                    -webkit-box-pack: center;
                    justify-content: center;
                    -webkit-box-flex: 1;
                    flex-grow: 1;
                    margin-top: 5px;
                }
                .asd-col-2 {
                    min-width: 40%;
                    max-width: 40%;
                    box-sizing: border-box;
                    position: relative;
                    margin-top: 5px;
                }
                .asd-col-3 {
                    min-width: 10%;
                    max-width: 10%;
                    text-align: right;
                    margin-top: 5px;
                    font-size: 24px !important;
                }
                .helper-text {
                    position: absolute;
                    color: rgb(87, 87, 87);
                    font-size: 10px;
                    padding: 9px 12px 12px;
                    left: 105.5%;
                    top: -55px;
                    width: 28%;
                    max-width: calc(30% - 15px);
                }
                .arrow {
                    margin-top: 5px;
                    left: -25px;
                    top: 50px;
                    position: absolute;
                    width: 15px;
                    z-index: 33;
                    background-color: rgb(250, 250, 250);
                    height: 15px;
                    border: none;
                    transform: rotate(45deg);
                    box-shadow: rgb(0 0 0 / 20%) 0px 1px 4px 0px inset;
                    box-sizing: content-box !important;
                }
                .arrow::after {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    top: -10px;
                    left: 5px;
                    z-index: 34;
                    transform: rotate(-45deg);
                    background: rgb(255, 255, 255);
                }
                .content {
                    padding: 30px;
                    background-color: rgb(255, 255, 255);
                    box-shadow: rgb(247 247 247) 3px 0px 0px 0px;
                    margin-bottom: 20px;
                    box-sizing: border-box;
                    height: auto;
                    transition: height 0.5s ease-in-out 0s;
                    transform-origin: center top;
                    width: 70%;
                }
                .info-content {
                    display: flow-root;
                    justify-content: center;
                    align-items: center;
                }

                @media (max-width: 3200px) {
                    .arrow {
                        left: -80px;
                    }
                }
                @media (max-width: 2800px) {
                    .arrow {
                        left: -70px;
                    }
                }
                @media (max-width: 2600px) {
                    .arrow {
                        left: -60px;
                    }
                }
                @media (max-width: 2500px) {
                    .arrow {
                        left: -55px;
                    }
                }
                @media (max-width: 2300px) {
                    .arrow {
                        left: -50px;
                    }
                }
                @media (max-width: 2200px) {
                    .arrow {
                        left: -45px;
                    }
                }
                @media (max-width: 2100px) {
                    .arrow {
                        left: -40px;
                    }
                }
                @media (max-width: 2000px) {
                    .arrow {
                        left: -35px;
                    }
                }
                @media (max-width: 1900px) {
                    .arrow {
                        left: -29px;
                    }
                }

                @media (max-width: 1650px) {
                    .arrow {
                        left: -23px;
                    }
                }
                @media (max-width: 1600px) {
                    .arrow {
                        left: -20px;
                    }
                }
                @media (max-width: 1500px) {
                    .arrow {
                        left: -18px;
                    }
                }
                @media (max-width: 1450px) {
                    .arrow {
                        left: -15px;
                    }
                }

                @media (max-width: 1380px) {
                    .arrow {
                        display: none;
                    }
                    .arrow-2 {
                        display: block;
                        left: auto;
                        right: 0px;
                        top: -11px;
                        position: absolute;
                        width: 0px;
                        z-index: 33;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid rgb(0, 94, 168);
                        box-sizing: content-box !important;
                    }
                    .arrow-2::after {
                        content: "";
                        position: absolute;
                        width: 0px;
                        height: 0px;
                        top: 1px;
                        left: -10px;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid rgb(250, 250, 250);
                    }
                    .helper-text {
                        position: relative;
                        left: 0px;
                        width: 100%;
                        max-width: none;
                        background: rgb(250, 250, 250);
                        padding: 15px;
                        margin: 5px 0px 0px;
                        border: 1px solid rgb(0, 94, 168);
                        box-sizing: border-box;
                        float: left;
                        top: 0px !important;
                    }
                }
            `,
        ];
    }

    static get properties() {
        return {
            _id: String,
            count: Number,
            _selectedId: String,
            _counter: { type: Array },
            _loading: Boolean,
        };
    }

    render() {
        return html` <div class="content">
            ${this._counter?.map(
                (x, i) => html`
                    ${this.checkSlotEmpty(i)
                        ? html``
                        : html` <div class="asd-row">
                                  <div class="asd-col-1">
                                      <slot name="asd-col-1-count-${i}"></slot>
                                  </div>
                                  <div class="asd-col-2">
                                      <slot name="asd-col-2-count-${i}"></slot>
                                  </div>
                                  <div
                                      class="asd-col-3"
                                      .style="${this._selectedId === x.id
                                          ? "color:#0d6efdff;"
                                          : ""}"
                                  >
                                      <slot
                                          name="asd-col-3-count-${i}"
                                          @click="${(e) =>
                                              this._showText(e, x.id)}"
                                      ></slot>
                                  </div>
                                  <div
                                      class="helper-text"
                                      .style="${this._selectedId === x.id
                                          ? "Visibility: visible;"
                                          : "display:none"}"
                                  >
                                      <span class="arrow"></span>
                                      <span class="arrow-2"></span>
                                      <slot
                                          name="asd-helper-text-count-${i}"
                                          @slotchange="${this._slotchange}"
                                      >
                                      </slot>
                                  </div>
                              </div>
                              <div class="info-content">
                                  <slot name="asd-info-box-count-${i}"></slot>
                              </div>`}
                `
            )}
        </div>`;
    }

    constructor() {
        super();
        this._counter = new Array();
        this._selectedId = "";
    }

    firstUpdated() {
        this._loading = true;
        if (this.count > 0) {
            for (var i = 0; i < this.count; i++)
                this._counter.push({
                    id: Math.random().toString(36).substr(2, 9),
                });
        }
        this._loading = false;
    }

    checkSlotEmpty(index) {
        if (
            !this.querySelector("[slot=asd-col-1-count-" + index + "]") &&
            !this.querySelector("[slot=asd-col-2-count-" + index + "]") &&
            !this.querySelector("[slot=asd-col-3-count-" + index + "]") &&
            !this.querySelector("[slot=asd-helper-text-count-" + index + "]") &&
            !this.querySelector("[slot=asd-info-box-count-" + index + "]")
        )
            return true;
        return false;
    }

    _showText(e, id) {
        e.preventDefault();
        if (this._selectedId === id) this._selectedId = "";
        else this._selectedId = id;
    }

    _slotchange(e) {
        e.preventDefault();
        this._selectedId = "";
    }
}
customElements.define("asd-helper", AsdHelper);
