import errorHandler from "../errorHandling";

export const ERSTELLE_PROJEKTBETEILIGTEN_COMMAND_SUCCESS =
    "ERSTELLE_PROJEKTBETEILIGTEN_COMMAND_SUCCESS";
export const PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_FAIL =
    "PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_FAIL";
export const PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_BEGIN =
    "PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_BEGIN";
export const FETCH_PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_SUCCESS =
    "FETCH_PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_SUCCESS";
export const RESET_PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_SUCCESS =
    "RESET_PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_SUCCESS";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;

export const erstelleProjektbeteiligtenCommand =
    (data, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("erstelleProjektbeteiligtenCommand");
        dispatch({
            type: PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_BEGIN,
        });
        const response = await fetch(
            `${apiHost}/api/projekt/${projektId}/erstelle-projektbeteiligten/`,
            {
                method: "POST",
                body: data,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "erstelleProjektbeteiligtenCommand",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "erstelleProjektbeteiligtenCommand",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: ERSTELLE_PROJEKTBETEILIGTEN_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("erstelleProjektbeteiligtenCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const resetProjektProjektbeteiligtenErstellenViewData =
    () => (dispatch) => {
        dispatch({
            type: RESET_PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_SUCCESS,
        });
        appInsights.trackEvent({
            name: "resetProjektProjektbeteiligtenErstellenViewData",
        });
    };

export const fetchProjektProjektbeteiligtenErstellenViewData =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchProjektProjektbeteiligtenErstellenViewData"
        );
        dispatch({
            type: PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_BEGIN,
        });
        const response = await fetch(
            `${apiHost}/api/projekt/${data.projektId}/projektbeteiligten-erstellen-view-data.json/`,
            getRequestOptions
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchProjektProjektbeteiligtenErstellenViewData",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchProjektProjektbeteiligtenErstellenViewData",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_PROJEKT_PROJEKTBETEILIGTEN_ERSTELLEN_VIEW_DATA_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchProjektProjektbeteiligtenErstellenViewData",
                { success: json.success }
            );
            return json.payload;
        }
    };
