export const ZUKO_MINDESTLOHN_KONFIGURATION_ACTION_FAIL =
    "ZUKO_MINDESTLOHN_KONFIGURATION_ACTION_FAIL";
export const ZUKO_MINDESTLOHN_KONFIGURATION_ACTION_BEGIN =
    "ZUKO_MINDESTLOHN_KONFIGURATION_ACTION_BEGIN";
export const ZUKO_FETCH_MINDESTLOHN_KONFIGURATION_SUCCESS =
    "ZUKO_FETCH_MINDESTLOHN_KONFIGURATION_SUCCESS";
export const RESET_ZUKO_MINDESTLOHN_KONFIGURATION_SUCCESS =
    "RESET_ZUKO_MINDESTLOHN_KONFIGURATION_SUCCESS";
export const ZUKO_ERSTELLE_MINDESTLOHN_KONFIGURATION_SUCCESS =
    "ZUKO_ERSTELLE_MINDESTLOHN_KONFIGURATION_SUCCESS";
export const ZUKO_AKTUALISIERE_MINDESTLOHN_KONFIGURATION_SUCCESS =
    "ZUKO_AKTUALISIERE_MINDESTLOHN_KONFIGURATION_SUCCESS";
export const ZUKO_LOESCHE_MINDESTLOHN_VORLAGE_SUCCESS =
    "ZUKO_LOESCHE_MINDESTLOHN_VORLAGE_SUCCESS";
    
import errorHandler from "../errorHandling";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;

export const fetchFetchMindestlohnKonfiguration =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("fetchFetchMindestlohnKonfiguration");
        dispatch({ type: ZUKO_MINDESTLOHN_KONFIGURATION_ACTION_BEGIN });
        const url = `${apiHost}/api/zuko/admin/${data.projektId}/hole-mindestlohn-konfiguration`;
        const response = await fetch(url, {
            method: "GET",
        });

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: ZUKO_MINDESTLOHN_KONFIGURATION_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchFetchMindestlohnKonfiguration",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: ZUKO_MINDESTLOHN_KONFIGURATION_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchFetchMindestlohnKonfiguration",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: ZUKO_FETCH_MINDESTLOHN_KONFIGURATION_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("fetchFetchMindestlohnKonfiguration", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const erstelleMindestlohnKonfiguration =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("erstelleMindestlohnKonfiguration");
        dispatch({ type: ZUKO_MINDESTLOHN_KONFIGURATION_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/admin/${projektId}/erstelle-mindestlohn-konfiguration`,
            {
                method: "POST",
                body: formData,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }

        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: ZUKO_MINDESTLOHN_KONFIGURATION_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent("erstelleMindestlohnKonfiguration", {
                    success: json.success,
                    error: json,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: ZUKO_MINDESTLOHN_KONFIGURATION_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent("erstelleMindestlohnKonfiguration", {
                    success: response.ok,
                    error: response,
                });
            }
        }

        if (json?.success) {
            dispatch({
                type: ZUKO_ERSTELLE_MINDESTLOHN_KONFIGURATION_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("erstelleMindestlohnKonfiguration", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const aktualisiereMindestlohnKonfiguration =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("aktualisiereMindestlohnKonfiguration");
        dispatch({ type: ZUKO_MINDESTLOHN_KONFIGURATION_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/admin/${projektId}/aktualisiere-mindestlohn-konfiguration`,
            {
                method: "POST",
                body: formData,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(errorHandler));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: ZUKO_MINDESTLOHN_KONFIGURATION_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "aktualisiereMindestlohnKonfiguration",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: ZUKO_MINDESTLOHN_KONFIGURATION_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "aktualisiereMindestlohnKonfiguration",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }

        if (json?.success) {
            dispatch({
                type: ZUKO_AKTUALISIERE_MINDESTLOHN_KONFIGURATION_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("aktualisiereMindestlohnKonfiguration", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const loescheMindestlohnerklaerungVorlage =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("loescheMindestlohnerklaerungVorlage");
        dispatch({ type: ZUKO_MINDESTLOHN_KONFIGURATION_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/admin/${projektId}/loesche-mindestlohn-vorlage`,
            {
                method: "POST",
                body: formData,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: ZUKO_MINDESTLOHN_KONFIGURATION_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "loescheMindestlohnerklaerungVorlage",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: ZUKO_MINDESTLOHN_KONFIGURATION_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "loescheMindestlohnerklaerungVorlage",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }

        if (json?.success) {
            dispatch({
                type: ZUKO_LOESCHE_MINDESTLOHN_VORLAGE_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("loescheMindestlohnerklaerungVorlage", {
                success: json.success,
            });
            return json.payload;
        }
    };
