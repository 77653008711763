export const BERECHTIGUNGSGRUPPEN_ACTION_FAIL =
    "BERECHTIGUNGSGRUPPEN_ACTION_FAIL";
export const BERECHTIGUNGSGRUPPEN_ACTION_BEGIN =
    "BERECHTIGUNGSGRUPPEN_ACTION_BEGIN";
export const FETCH_BERECHTIGUNGSGRUPPEN_SUCCESS =
    "FETCH_BERECHTIGUNGSGRUPPEN_SUCCESS";
export const RESET_BERECHTIGUNGSGRUPPEN_SUCCESS =
    "RESET_BERECHTIGUNGSGRUPPEN_SUCCESS";
export const ERSTELLE_BERECHTIGUNGSGRUPPE_SUCCESS =
    "ERSTELLE_BERECHTIGUNGSGRUPPE_SUCCESS";
export const LOESCHE_BERECHTIGUNGSGRUPPE_SUCCESS =
    "LOESCHE_BERECHTIGUNGSGRUPPE_SUCCESS";
export const AKTUALISIERE_BERECHTIGUNGSGRUPPE_SUCCESS =
    "AKTUALISIERE_BERECHTIGUNGSGRUPPE_SUCCESS";
export const FETCH_BERECHTIGUNGSGRUPPEN_ADMIN_SUCCESS =
    "FETCH_BERECHTIGUNGSGRUPPEN_ADMIN_SUCCESS";
export const RESET_BERECHTIGUNGSGRUPPEN_ADMIN_SUCCESS =
    "RESET_BERECHTIGUNGSGRUPPEN_ADMIN_SUCCESS";
export const SYNCHRONISIERE_BERECHTIGUNGSGRUPPEN_SUCCESS =
    "SYNCHRONISIERE_BERECHTIGUNGSGRUPPEN_SUCCESS";

import errorHandler from "./../errorHandling.js";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;

let abortBerechtigungsgruppen, abortBerechtigungsgruppenAdmin;

export const resetBerechtigungsgruppen = () => async (dispatch) => {
    try {
        abortBerechtigungsgruppen?.abort();
    } catch (e) {
        console.log(e);
    }
    dispatch({ type: RESET_BERECHTIGUNGSGRUPPEN_SUCCESS });
    appInsights.trackEvent({ name: "resetZukoBerechtigungsgruppen" });
};

export const resetBerechtigungsgruppenAdmin = () => async (dispatch) => {
    try {
        abortBerechtigungsgruppenAdmin?.abort();
    } catch (e) {
        console.log(e);
    }
    dispatch({ type: RESET_BERECHTIGUNGSGRUPPEN_ADMIN_SUCCESS });
    appInsights.trackEvent({ name: "resetZukoBerechtigungsgruppen" });
};

export const fetchBerechtigungsgruppen = (projektId) => async (dispatch) => {
    appInsights.startTrackEvent("fetchBerechtigungsgruppen");
    dispatch({ type: BERECHTIGUNGSGRUPPEN_ACTION_BEGIN });
    abortBerechtigungsgruppen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${projektId}/berechtigungsgruppen/`,
        {
            method: "GET",
            signal: abortBerechtigungsgruppen.signal,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch({
                type: BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
                error: json.error,
            });
            appInsights.stopTrackEvent("fetchBerechtigungsgruppen", {
                success: json.success,
                error: json.error,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
                error: { errorMessage: response.toString() },
            });
            appInsights.stopTrackEvent("fetchBerechtigungsgruppen", {
                success: false,
                error: { errorMessage: response.toString() },
            });
        }
    }

    if (json?.success) {
        dispatch({
            type: FETCH_BERECHTIGUNGSGRUPPEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchBerechtigungsgruppen", {
            success: json.success,
        });
    }
};

export const fetchBerechtigungsgruppenAdmin =
    (projektId) => async (dispatch) => {
        appInsights.startTrackEvent("fetchBerechtigungsgruppenAdmin");
        dispatch({ type: BERECHTIGUNGSGRUPPEN_ACTION_BEGIN });
        abortBerechtigungsgruppenAdmin =
        typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/admin/v1/${projektId}/berechtigungsgruppen/`,
            {
                method: "GET",
                signal: abortBerechtigungsgruppenAdmin.signal,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
                    error: json.error,
                });
                appInsights.stopTrackEvent("fetchBerechtigungsgruppenAdmin", {
                    success: json.success,
                    error: json.error,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
                    error: { errorMessage: response.toString() },
                });
                appInsights.stopTrackEvent("fetchBerechtigungsgruppenAdmin", {
                    success: false,
                    error: { errorMessage: response.toString() },
                });
            }
        }

        if (json?.success) {
            dispatch({
                type: FETCH_BERECHTIGUNGSGRUPPEN_ADMIN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("fetchBerechtigungsgruppenAdmin", {
                success: json.success,
            });
        }
    };

export const synchronisiereBerechtigungsgruppenCommand =
    (projektId) => async (dispatch) => {
        appInsights.startTrackEvent(
            "synchronisiereBerechtigungsgruppenCommand"
        );
        dispatch({ type: BERECHTIGUNGSGRUPPEN_ACTION_BEGIN });
        const url = `/api/zuko/admin/v1/${projektId}/synchronisiere-berechtigungsgruppen`;
        const response = await fetch(url, {
            method: "POST",
        });
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
                    error: json.error,
                });
                appInsights.stopTrackEvent(
                    "synchronisiereBerechtigungsgruppenCommand",
                    {
                        success: json.success,
                        error: json.error,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
                    error: { errorMessage: response.toString() },
                });
                appInsights.stopTrackEvent(
                    "synchronisiereBerechtigungsgruppenCommand",
                    {
                        success: false,
                        error: { errorMessage: response.toString() },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: SYNCHRONISIERE_BERECHTIGUNGSGRUPPEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "synchronisiereBerechtigungsgruppenCommand",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const erstelleBerechtigungsgruppeCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("erstelleBerechtigungsgruppe");
        dispatch({ type: BERECHTIGUNGSGRUPPEN_ACTION_BEGIN });
        const url = `/api/zuko/admin/v1/${projektId}/erstelle-berechtigungsgruppe`;
        const response = await fetch(url, {
            method: "POST",
            body: formData,
        });
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "erstelleBerechtigungsgruppeCommand",
                    {
                        success: json.success,
                        error: json.error,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
                    error: { errorMessage: response.toString() },
                });
                appInsights.stopTrackEvent(
                    "erstelleBerechtigungsgruppeCommand",
                    {
                        success: false,
                        error: { errorMessage: response.toString() },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: ERSTELLE_BERECHTIGUNGSGRUPPE_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("erstelleBerechtigungsgruppeCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const aktualisiereBerechtigungsgruppeCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("aktualisiereBerechtigungsgruppeCommand");
        dispatch({ type: BERECHTIGUNGSGRUPPEN_ACTION_BEGIN });
        const url = `/api/zuko/admin/v1/${projektId}/aktualisiere-berechtigungsgruppe`;
        const response = await fetch(url, {
            method: "POST",
            body: formData,
        });
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "aktualisiereBerechtigungsgruppeCommand",
                    {
                        success: json.success,
                        error: json.error,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
                    error: { errorMessage: response.toString() },
                });
                appInsights.stopTrackEvent(
                    "aktualisiereBerechtigungsgruppeCommand",
                    {
                        success: false,
                        error: { errorMessage: response.toString() },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: AKTUALISIERE_BERECHTIGUNGSGRUPPE_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "aktualisiereBerechtigungsgruppeCommand",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const loescheBerechtigungsgruppeCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("loescheBerechtigungsgruppeCommand");
        dispatch({ type: BERECHTIGUNGSGRUPPEN_ACTION_BEGIN });
        const url = `/api/zuko/admin/v1/${projektId}/loesche-berechtigungsgruppe`;
        const response = await fetch(url, {
            method: "POST",
            body: formData,
        });
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "loescheBerechtigungsgruppeCommand",
                    {
                        success: json.success,
                        error: json.error,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
                    error: { errorMessage: response.toString() },
                });
                appInsights.stopTrackEvent(
                    "loescheBerechtigungsgruppeCommand",
                    {
                        success: false,
                        error: { errorMessage: response.toString() },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: LOESCHE_BERECHTIGUNGSGRUPPE_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("loescheBerechtigungsgruppeCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };
