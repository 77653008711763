export const TRANSLATIONS_EN_NEWS = {
    ABBRECHEN: "Cancel",
    ABFRAGE_NACHRICHT_ARCHIVIEREN: "Do you really want to delete the message",
    ALLE: "All",
    AVISIERUNG: "Notification system",
    BEARBEITER: "Person of edit",
    BEARBEITERDATUM: "Date of edit",
    BEARBEITUNGS_MODAL: "Clicking will open a modal for editing",
    BEDEUTUNG_BLACKLIST:
        "Emails of project participants added to the blacklist will not receive emails from the News module",
    BILD: "Image",
    BITTE_MODUL_AUSWAEHLEN: "Please select module",
    BITTE_NACHRICHT_EINGEBEN: "Please enter a message",
    BITTE_PROJEKTBETILIGTER_AUSWAEHLEN: "Please enter a Project participants",
    BITTE_TITEL_EINGEBEN: "Please enter a title",
    CONTAINER: "Shipping",
    DASHBOARD: "Dashboard",
    EINSTELLUNGEN: "Settings",
    ERSTELLER: "Person of create",
    ERSTELLDATUM: "Date of create",
    ID_NOT_FOUND: "Id could not be found",
    JA: "Yes",
    KEINE_EMAIL_BLACKLIST: "No email has been added to the blacklist yet.",
    KEINE_NACHRICHTEN: "The project has no messages yet.",
    KONFIGURATION: "Configuration",
    KONFIGURATION_ERFOLGREICH_AKTUALISIERT: "Configuration was successfully updated",
    LOESCH_MODAL: "Clicking will open a modal for deletion",
    MAIL: "Mail",
    MAIL_KONFIGURATION_SPEICHERN: "Save configuration of mail",
    MODUL: "Module",
    NACHRICHT: "Message",
    NACHRICHT_AKTUALISIEREN: "Update message",
    NACHRICHT_ARCHIVIEREN: "Archive message",
    NACHRICHT_ERFOLGREICH_AKTUALISIERT: "Message has been successfully updated",
    NACHRICHT_ERFOLGREICH_ARCHIVIERT: "Message has been successfully archived",
    NACHRICHT_ERFOLGREICH_ERSTELLT: "Message has been successfully created",
    NACHRICHT_ERSTELLEN: "Create message",
    NACHRICHTEN: "News",
    NEIN: "No",
    NEWS: "News",
    PROJEKT: "Project",
    PROJEKTBETEILIGTE: "Project participants",
    PROJEKTNAME: "Project Name",
    TITEL: "Title",
    UEBERSICHT: "Overview",
    WARNUNG: "Warning",
    ZUTRITTSKONTROLL: "Access-Control",
};
