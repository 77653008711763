export const MITARBEITER_ACTION_FAIL = "MITARBEITER_ACTION_FAIL";
export const MITARBEITER_ACTION_BEGIN = "MITARBEITER_ACTION_BEGIN";
export const RESET_MITARBEITER_SUCCESS = "RESET_MITARBEITER_SUCCESS";
export const RESET_DRUCK_VORLAGE_SUCCESS = "RESET_DRUCK_VORLAGE_SUCCESS";
export const LOESCHE_MITARBEITER_COMMAND_SUCCESS =
    "LOESCHE_MITARBEITER_COMMAND_SUCCESS";
export const VERWARNE_MITARBEITER_COMMAND_SUCCESS =
    "VERWARNE_MITARBEITER_COMMAND_SUCCESS";
export const FETCH_MITARBEITER_PROFILBILD_SUCCESS =
    "FETCH_MITARBEITER_PROFILBILD_SUCCESS";

export const LADE_MITARBEITER_PROFILBILD_HOCH_SUCCESS =
    "LADE_MITARBEITER_PROFILBILD_HOCH_SUCCESS";
export const LOESCHE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS =
    "LOESCHE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS";

export const PRUEFE_MITARBEITER_EUFEMA_COMMAND_SUCCESS =
    "PRUEFE_MITARBEITER_EUFEMA_COMMAND_SUCCESS";
export const LOESCHE_MITARBEITER_EUFEMA_COMMAND_SUCCESS =
    "LOESCHE_MITARBEITER_EUFEMA_COMMAND_SUCCESS";

export const ENTPRUEFE_MITARBEITER_EUFEMA_COMMAND_SUCCESS =
    "ENTPRUEFE_MITARBEITER_EUFEMA_COMMAND_SUCCESS";
export const ERSTELLE_MITARBEITER_EUFEMA_COMMAND_SUCCESS =
    "ERSTELLE_MITARBEITER_EUFEMA_COMMAND_SUCCESS";

export const PRUEFE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS =
    "PRUEFE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS";
export const LOESCHE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS =
    "LOESCHE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS";
export const ERSTELLE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS =
    "ERSTELLE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS";
export const ENTPRUEFE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS =
    "ENTPRUEFE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS";
export const AKTUALISIERE_MITARBEITER_STAMMDATEN_COMMAND_SUCCESS =
    "AKTUALISIERE_MITARBEITER_STAMMDATEN_COMMAND_SUCCESS";
export const ERSTELLE_WEITERE_MITARBEITER_EUFEMA_COMMAND_SUCCESS =
    "ERSTELLE_WEITERE_MITARBEITER_EUFEMA_COMMAND_SUCCESS";
export const AKTUALISIERE_MITARBEITER_QUALIFIKATIONEN_COMMAND_SUCCESS =
    "AKTUALISIERE_MITARBEITER_QUALIFIKATIONEN_COMMAND_SUCCESS";

//#region Mitarbeiter
export const FETCH_MITARBEITER_SUCCESS = "FETCH_MITARBEITER_SUCCESS";
//#endregion

//#region Dashboard
export const FETCH_MITARBEITER_DASHBOARD_SUCCESS =
    "FETCH_MITARBEITER_DASHBOARD_SUCCESS";
export const MITARBEITER_AKTIV_SUCCESS = "MITARBEITER_AKTIV_SUCCESS";
//#endregion

//#region Stammdaten
export const FETCH_MITARBEITER_STAMMDATEN_SUCCESS =
    "FETCH_MITARBEITER_STAMMDATEN_SUCCESS";
//#endregion

//#region Baustellenausweis
export const EXISTIERT_BAUSTELLENAUSWEIS_SUCCESS =
    "EXISTIERT_BAUSTELLENAUSWEIS_SUCCESS";
export const MITARBEITER_DRUCKVORLAGE_ACTION_BEGIN =
    "MITARBEITER_DRUCKVORLAGE_ACTION_BEGIN";
export const DRUCKE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS =
    "DRUCKE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS";
export const SPERRE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS =
    "SPERRE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS";
export const FETCH_MITARBEITER_AUSWEIS_VORLAGE_SUCCESS =
    "FETCH_MITARBEITER_AUSWEIS_VORLAGE_SUCCESS";
export const FETCH_MITARBEITER_DRUCKEN_SUCCESS =
    "FETCH_MITARBEITER_DRUCKEN_SUCCESS";
export const SYNCHRONISIERE_BAUSTELLENAUSWEIS_SUCCESS =
    "SYNCHRONISIERE_BAUSTELLENAUSWEIS_SUCCESS";
export const ERSTELLE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS =
    "ERSTELLE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS";
export const MITARBEITER_BAUSTELLENAUSWEIS_DRUCKEN_FAIL =
    "MITARBEITER_BAUSTELLENAUSWEIS_DRUCKEN_FAIL";
export const FETCH_MITARBEITER_BAUSTELLENAUSWEIS_SUCCESS =
    "FETCH_MITARBEITER_BAUSTELLENAUSWEIS_SUCCESS";
export const ENTSPERRE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS =
    "ENTSPERRE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS";
export const SPERRE_ALLE_BAUSTELLENAUSWEISE_COMMAND_SUCCESS =
    "SPERRE_ALLE_BAUSTELLENAUSWEISE_COMMAND_SUCCESS";
export const SETZTE_MITARBEITER_EINSTELLUNGEN_BAUSTELLENAUSWEIS_SUCCESS =
    "SETZTE_MITARBEITER_EINSTELLUNGEN_BAUSTELLENAUSWEIS_SUCCESS";
export const SYNCHRONISIERE_MITARBEITER_EINSTELLUNGEN_BAUSTELLENAUSWEIS_SUCCESS =
    "SYNCHRONISIERE_MITARBEITER_EINSTELLUNGEN_BAUSTELLENAUSWEIS_SUCCESS";
//#endregion

//#region Unterlagen
export const FETCH_MITARBEITER_UNTERLAGEN_SUCCESS =
    "FETCH_MITARBEITER_UNTERLAGEN_SUCCESS";
//#endregion

//#region Mindestlohnerklaerung
export const FETCH_MITARBEITER_MINDESTLOHNERLAERUNG_SUCCESS =
    "FETCH_MITARBEITER_MINDESTLOHNERLAERUNG_SUCCESS";
import errorHandler from "../errorHandling.js";
//#endregion

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;

//#region Dashboard
export const fetchMitarbeiter = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchMitarbeiter");
    dispatch({ type: MITARBEITER_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/fetchMitarbeiter/`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: MITARBEITER_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchMitarbeiter", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: MITARBEITER_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchMitarbeiter", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_MITARBEITER_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchMitarbeiter", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

//#region Dashboard
export const fetchMitarbeiterDashboard = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchMitarbeiterDashboard");
    dispatch({ type: MITARBEITER_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/mitarbeiter-dashboard/?mitarbeiterId=${data.mitarbeiterId}`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: MITARBEITER_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchMitarbeiterDashboard", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: MITARBEITER_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchMitarbeiterDashboard", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_MITARBEITER_DASHBOARD_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchMitarbeiterDashboard", {
            success: json.success,
        });
        return json.payload;
    }
};

export const setzePersonalAktivitaet =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("setzePersonalAktivitaet");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });

        const response = await fetch(
            `${apiHost}/api/zuko/v1/${projektId}/setze-personal-aktivitaet/`,
            {
                method: "POST",
                body: formData,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent("setzePersonalAktivitaet", {
                    success: json.success,
                    error: json.payload,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("setzePersonalAktivitaet", {
                    success: response.success,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: MITARBEITER_AKTIV_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("setzePersonalAktivitaet", {
                success: json.success,
            });
            return { success: true, payload: json.payload,};
        }
    };

//#endregion

//#region Stammdaten
export const fetchMitarbeiterStammdaten = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchMitarbeiterStammdaten");
    dispatch({ type: MITARBEITER_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/mitarbeiter-stammdaten/?mitarbeiterId=${data.mitarbeiterId}`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: MITARBEITER_ACTION_FAIL,
                error: { errorMessage: json.payload.toString() },
            });
            appInsights.stopTrackEvent("fetchMitarbeiterStammdaten", {
                success: json.success,
                error: { errorMessage: json.payload.toString() },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: MITARBEITER_ACTION_FAIL,
                error: { errorMessage: response.toString() },
            });
            appInsights.stopTrackEvent("fetchMitarbeiterStammdaten", {
                success: response.ok,
                error: { errorMessage: response.toString() },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_MITARBEITER_STAMMDATEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchMitarbeiterStammdaten", {
            success: json.success,
        });
    }
};

export const aktualisiereMitarbeiterStammdatenCommand =
    (data, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("aktualisiereMitarbeiterStammdatenCommand");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });

        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/aktualisiere-mitarbeiter/`,
            {
                method: "POST",
                body: data,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent(
                    "aktualisiereMitarbeiterStammdatenCommand",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent(
                    "aktualisiereMitarbeiterStammdatenCommand",
                    {
                        success: response.success,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: AKTUALISIERE_MITARBEITER_STAMMDATEN_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "aktualisiereMitarbeiterStammdatenCommand",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

//#endregion

//#region Mitarbeiter Baustellenausweise
export const fetchMitarbeiterBaustellenausweise =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("fetchMitarbeiterBaustellenausweise");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/mitarbeiter-baustellenausweise/?mitarbeiterId=${data.mitarbeiterId}`,
            getRequestOptions
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent(
                    "fetchMitarbeiterBaustellenausweise",
                    {
                        success: json.success,
                        error: json.payload,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent(
                    "fetchMitarbeiterBaustellenausweise",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_MITARBEITER_BAUSTELLENAUSWEIS_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("fetchMitarbeiterBaustellenausweise", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const existiertBaustellenausweis = (data) => async (dispatch) => {
    appInsights.startTrackEvent("existiertBaustellenausweis");
    dispatch({ type: MITARBEITER_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/v3/${data.projektId}/existiert-baustellenausweis?kartennummer=${data.kartennummer}`,
        getRequestOptions
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("existiertBaustellenausweis", {
                success: json.success,
                error: json.payload,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("existiertBaustellenausweis", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: EXISTIERT_BAUSTELLENAUSWEIS_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("existiertBaustellenausweis", {
            success: json.success,
        });
        return json.payload;
    }
};

export const synchronisiereBaustellenausweise =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("synchronisiereBaustellenausweise");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${projektId}/synchronisiere-baustellenausweise`,
            {
                method: "POST",
                body: formData,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: MITARBEITER_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent("synchronisiereBaustellenausweise", {
                    success: json.success,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: MITARBEITER_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent("synchronisiereBaustellenausweise", {
                    success: response.ok,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: SYNCHRONISIERE_BAUSTELLENAUSWEIS_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("synchronisiereBaustellenausweise", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const erstelleBaustellenausweisCommand =
    (data, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("erstelleBaustellenausweisCommand");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });

        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/erstelle-baustellenausweis/`,
            {
                method: "POST",
                body: data,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent("erstelleBaustellenausweisCommand", {
                    success: json.success,
                    error: json.payload,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("erstelleBaustellenausweisCommand", {
                    success: response.ok,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: ERSTELLE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("erstelleBaustellenausweisCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const loescheBaustellenausweisCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("loescheBaustellenausweisCommand");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/loesche-baustellenausweis/`,
            {
                method: "POST",
                body: formData,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent("loescheBaustellenausweisCommand", {
                    success: json.success,
                    error: json,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("loescheBaustellenausweisCommand", {
                    success: response.success,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: LOESCHE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("loescheBaustellenausweisCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const setzeMitarbeiterEinstellungenFuerBaustellenausweise =
    (projektId, formData) => async (dispatch) => {
        appInsights.startTrackEvent(
            "setzeMitarbeiterEinstellungenFuerBaustellenausweise"
        );
        dispatch({ type: MITARBEITER_ACTION_BEGIN });

        const response = await fetch(
            `${apiHost}/api/zuko/v2/${projektId}/setze-mitarbeiter-einstellungen-fuer-baustellenausweise`,
            {
                method: "POST",
                body: formData,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: MITARBEITER_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "setzeMitarbeiterEinstellungenFuerBaustellenausweise",
                    { success: json.success }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: MITARBEITER_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "setzeMitarbeiterEinstellungenFuerBaustellenausweise",
                    { success: response.ok }
                );
            }
        } else if (json?.success) {
            dispatch({
                type: SETZTE_MITARBEITER_EINSTELLUNGEN_BAUSTELLENAUSWEIS_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "setzeMitarbeiterEinstellungenFuerBaustellenausweise",
                { success: json.success }
            );
            return json.payload;
        }
    };

export const synchronisiereBaustellenausweisEinstellungen =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent(
            "synchronisiereBaustellenausweisEinstellungen"
        );
        dispatch({ type: MITARBEITER_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${projektId}/synchronisiere-mitarbeiter-einstellungen-fuer-baustellenausweise`,
            {
                method: "POST",
                body: formData,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: MITARBEITER_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "synchronisiereBaustellenausweisEinstellungen",
                    {
                        success: json.success,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: MITARBEITER_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "synchronisiereBaustellenausweisEinstellungen",
                    {
                        success: response.ok,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: SYNCHRONISIERE_MITARBEITER_EINSTELLUNGEN_BAUSTELLENAUSWEIS_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "synchronisiereBaustellenausweisEinstellungen",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const sperreBaustellenausweisCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("sperreBaustellenausweisCommand");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/sperre-baustellenausweis/`,
            {
                method: "POST",
                body: formData,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent("sperreBaustellenausweisCommand", {
                    success: json.success,
                    error: json.error,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("sperreBaustellenausweisCommand", {
                    success: response.ok,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: SPERRE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("sperreBaustellenausweisCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const sperreAlleBaustellenausweiseCommand =
    (e, personalId) => async (dispatch) => {
        const form = e.srcElement || e.target;
        const url = form.dataset.url;
        const formData = new FormData(form);
        formData.append("MitarbeiterId", personalId);
        const response = await fetch(url, {
            method: "POST",
            body: formData,
        });
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: MITARBEITER_ACTION_FAIL,
                    error: { errorMessage: json.toString() },
                });
                appInsights.stopTrackEvent(
                    "sperreAlleBaustellenausweiseCommand",
                    {
                        success: json.success,
                        error: { errorMessage: json.toString() },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: MITARBEITER_ACTION_FAIL,
                    error: { errorMessage: response.toString() },
                });
                appInsights.stopTrackEvent(
                    "sperreAlleBaustellenausweiseCommand",
                    {
                        success: response.ok,
                        error: { errorMessage: response.toString() },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: SPERRE_ALLE_BAUSTELLENAUSWEISE_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("sperreAlleBaustellenausweiseCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const entsperreBaustellenausweisCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("entsperreBaustellenausweisCommand");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/entsperre-baustellenausweis/`,
            {
                method: "POST",
                body: formData,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent(
                    "entsperreBaustellenausweisCommand",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent(
                    "entsperreBaustellenausweisCommand",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: ENTSPERRE_BAUSTELLENAUSWEIS_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("entsperreBaustellenausweisCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };
//#endregion

//#region Mitarbeiter Unterlagen
export const fetchMitarbeiterUnterlagen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchMitarbeiterUnterlagen");
    dispatch({ type: MITARBEITER_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/mitarbeiter-unterlagen/?mitarbeiterId=${data.mitarbeiterId}`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("fetchMitarbeiterUnterlagen", {
                success: json.success,
                error: json.payload,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("fetchMitarbeiterUnterlagen", {
                success: response.success,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_MITARBEITER_UNTERLAGEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchMitarbeiterUnterlagen", {
            success: json.success,
        });
        return json.payload;
    }
};

export const sperreAlleBaustellenausweiseProjektCommand =
    (data) => async (dispatch) => {
        const response = await fetch(
            `${apiHost}/api/zuko/admin/${data}/sperre-alle-baustellenausweise-projekt`,
            {
                method: "POST",
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: MITARBEITER_ACTION_FAIL,
                    error: { errorMessage: json.toString() },
                });
                appInsights.stopTrackEvent(
                    "sperreAlleBaustellenausweiseCommand",
                    {
                        success: json.success,
                        error: { errorMessage: json.toString() },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: MITARBEITER_ACTION_FAIL,
                    error: { errorMessage: response.toString() },
                });
                appInsights.stopTrackEvent(
                    "sperreAlleBaustellenausweiseCommand",
                    {
                        success: response.ok,
                        error: { errorMessage: response.toString() },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: SPERRE_ALLE_BAUSTELLENAUSWEISE_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("sperreAlleBaustellenausweiseCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const fetchEufema = (data) => async (dispatch) => {
    const response = await fetch(
        `${apiHost}/api/zuko/v3/${data.projektId}/mitarbeiter-eufema/?mitarbeiterEufemaId=${data.eufemaId}`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.blob();
        return json;
    } catch (error) {
        dispatch(errorHandler(error));
    }
};

export const fetchEufemaVorlage = (data) => async (dispatch) => {
    const response = await fetch(
        `${apiHost}/api/zuko/v3/${data.projektId}/eufema-vorlage/?eufemaId=${data.eufemaId}`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.blob();
        return json;
    } catch (error) {
        dispatch(errorHandler(error));
    }
};

export const downlaodEufemaVorlage = (data) => () => {
    const a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/zuko/v3/${data.projektId}/eufema-vorlage/?eufemaId=${data.eufemaId}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "downlaodEufemaVorlage" });
};

export const fetchMitarbeiterEufema = (data) => async (dispatch) => {
    const response = await fetch(
        `${apiHost}/api/zuko/v3/${data.projektId}/mitarbeiter-eufema/?mitarbeiterEufemaId=${data.mitarbeiterEufemaId}`
    );
    let json;
    try {
        json = await response.blob();
        return json;
    } catch (error) {
        dispatch(errorHandler(error));
    }
};

export const druckeBaustellenausweisCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("fetchMitarbeiterUnterlagen");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });
        const url = `${apiHost}/api/zuko/v3/${projektId}/drucke-baustellenausweis`;

        const response = await fetch(url, {
            method: "POST",
            body: formData,
        });
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: MITARBEITER_BAUSTELLENAUSWEIS_DRUCKEN_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent("fetchMitarbeiterUnterlagen", {
                    success: json.success,
                    error: json.payload,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: MITARBEITER_BAUSTELLENAUSWEIS_DRUCKEN_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent("fetchMitarbeiterUnterlagen", {
                    success: response.success,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_MITARBEITER_DRUCKEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("fetchMitarbeiterUnterlagen", {
                success: json.success,
            });
            return json.payload;
        }
    };
//#endregion

//#region Mindestlohnerklaerungen
export const fetchMitarbeiterMindestlohnerklaerung =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("fetchMitarbeiterMindestlohnerklaerung");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v1/${data.projektId}/mitarbeiter-mindestlohnerklaerungen/?mitarbeiterId=${data.mitarbeiterId}`,
            getRequestOptions
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent(
                    "fetchMitarbeiterMindestlohnerklaerung",
                    {
                        success: json.success,
                        error: json.payload,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent(
                    "fetchMitarbeiterMindestlohnerklaerung",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_MITARBEITER_MINDESTLOHNERLAERUNG_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchMitarbeiterMindestlohnerklaerung",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchMindestlohnerklaerung = (data) => () => {
    const a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/zuko/v2/${data.projektId}/mindestlohnerklaerung/?mindestlohnerklaerungId=${data.mindestlohnerklaerungId}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "fetchMindestlohnerklaerung" });
};

export const fetchMindestlohnerklaerungVorlage = (data) => () => {
    const a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/zuko/v2/mindestlohnerklaerung-vorlage/?fileDownloadName=${data.datei}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "fetchMindestlohnerklaerungVorlage" });
};

export const fetchProjektspezifischeMindestlohnerklaerungVorlage =
    (data) => () => {
        const a = document.createElement("a");
        a.setAttribute(
            "href",
            `${apiHost}/api/zuko/${data.projektId}/projektspezifische-mindestlohnerklaerung-vorlage/?fileDownloadName=${data.datei}&vorlagenId=${data.vorlagenId}`
        );
        a.dispatchEvent(new MouseEvent("click"));
        appInsights.trackEvent({
            name: "fetchProjektspezifischeMindestlohnerklaerungVorlage",
        });
    };

//#endregion

//#region Profilbild
export const fetchMitarbeiterProfilbild = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchMitarbeiterProfilbild");
    dispatch({ type: MITARBEITER_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/v3/${data.projektId}/mitarbeiter-profilbild/?mitarbeiterId=${data.mitarbeiterId}`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.blob();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json & !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: MITARBEITER_ACTION_FAIL,
                error: { errorMessage: json.toString() },
            });
            appInsights.stopTrackEvent("fetchMitarbeiterProfilbild", {
                success: json.success,
                error: { errorMessage: json.toString() },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: MITARBEITER_ACTION_FAIL,
                error: { errorMessage: response.toString() },
            });
            appInsights.stopTrackEvent("fetchMitarbeiterProfilbild", {
                success: response.ok,
                error: { errorMessage: response.toString() },
            });
        }
    }
    if (json) {
        dispatch({
            type: FETCH_MITARBEITER_PROFILBILD_SUCCESS,
            payload: json,
        });
        appInsights.stopTrackEvent("fetchMitarbeiterProfilbild", {
            success: json.success,
        });
        return json;
    }
};

export const ladeMitarbeiterProfilbildHoch = (data) => async (dispatch) => {
    const headers = new Headers(),
        formData = new FormData();

    headers.append("Accept", "application/json");
    formData.append("file", data.file, data.file.name);
    formData.append("ProjektId", data.projektId);
    formData.append("MitarbeiterId", data.mitarbeiterId);

    const request = new Request(
        `${apiHost}/api/zuko/v3/${data.projektId}/lade-mitarbeiter-profilbild-hoch/`,
        {
            method: "POST",
            body: formData,
            headers: headers,
            credentials: "include",
        }
    );

    const abortController = new AbortController();

    function uploadTimeout(ms, promise) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                reject(new Error("timeout"));
            }, ms);
            promise.then(resolve, reject);
        });
    }

    appInsights.startTrackEvent("ladeMitarbeiterProfilbildHoch");
    dispatch({ type: MITARBEITER_ACTION_BEGIN });
    const response = await uploadTimeout(
        60000,
        fetch(request, { signal: abortController.signal })
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: MITARBEITER_ACTION_FAIL,
                error: { errorMessage: json.toString() },
            });

            appInsights.stopTrackEvent("ladeMitarbeiterProfilbildHoch", {
                success: false,
                error: { errorMessage: json.toString() },
            });
        } else {
            dispatch(errorHandler(response));
            if (response.message === "timeout") {
                abortController.abort();
                response.message =
                    "Zeitlimit für den Upload wurde überschritten";
            }
            dispatch({
                type: MITARBEITER_ACTION_FAIL,
                error: { errorMessage: response.toString() },
            });
        }
    }

    if (json?.success) {
        dispatch({
            type: LADE_MITARBEITER_PROFILBILD_HOCH_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("ladeMitarbeiterProfilbildHoch", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

export const resetMitarbeiter = () => (dispatch) => {
    dispatch({ type: RESET_MITARBEITER_SUCCESS });
    appInsights.trackEvent({ name: "resetMitarbeiter" });
};

export const verwarnePersonalCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("verwarnePersonalCommand");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });

        const response = await fetch(
            `${apiHost}/api/zuko/v1/${projektId}/verwarne-mitarbeiter/`,
            {
                method: "POST",
                body: formData,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent("verwarnePersonalCommand", {
                    success: json.success,
                    error: json.payload,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("verwarnePersonalCommand", {
                    success: response.success,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: VERWARNE_MITARBEITER_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("verwarnePersonalCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const loescheMitarbeiterCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("loescheMitarbeiterCommand");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });

        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/loesche-mitarbeiter/`,
            {
                method: "POST",
                body: formData,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent("loescheMitarbeiterCommand", {
                    success: json.success,
                    error: json.payload,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("loescheMitarbeiterCommand", {
                    success: response.success,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: LOESCHE_MITARBEITER_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("loescheMitarbeiterCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const resetDruckvorlage = () => (dispatch) => {
    dispatch({ type: RESET_DRUCK_VORLAGE_SUCCESS });
    appInsights.trackEvent({ name: "resetDruckvorlage" });
};

export const loescheMitarbeiterEufemaCommand =
    (data, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("loescheMitarbeiterEufemaCommand");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/loesche-mitarbeiter-eufema/`,
            {
                method: "POST",
                body: data,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent("loescheMitarbeiterEufemaCommand", {
                    success: json.success,
                    error: json,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("loescheMitarbeiterEufemaCommand", {
                    success: response.ok,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: LOESCHE_MITARBEITER_EUFEMA_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("loescheMitarbeiterEufemaCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const pruefeMitarbeiterEufemaCommand =
    (data, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("pruefeMitarbeiterEufemaCommand");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/pruefe-mitarbeiter-eufema/`,
            {
                method: "POST",
                body: data,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent("pruefeMitarbeiterEufemaCommand", {
                    success: json.success,
                    error: json,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("pruefeMitarbeiterEufemaCommand", {
                    success: response.ok,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: PRUEFE_MITARBEITER_EUFEMA_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("pruefeMitarbeiterEufemaCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const entpruefeMitarbeiterEufemaCommand =
    (data, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("entpruefeMitarbeiterEufemaCommand");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/entpruefe-mitarbeiter-eufema/`,
            {
                method: "POST",
                body: data,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && json?.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent(
                    "entpruefeMitarbeiterEufemaCommand",
                    {
                        success: json.success,
                        error: json.payload,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent(
                    "entpruefeMitarbeiterEufemaCommand",
                    {
                        success: json.success,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: ENTPRUEFE_MITARBEITER_EUFEMA_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("entpruefeMitarbeiterEufemaCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const erstelleMitarbeiterEufemaCommand =
    (data, projektId) => async (dispatch) => {
        const headers = new Headers();
        headers.append("Accept", "application/json");
        const request = new Request(
            `${apiHost}/api/zuko/v3/${projektId}/erstelle-mitarbeiter-eufema/`,
            {
                method: "POST",
                body: data,
                headers: headers,
                credentials: "include",
            }
        );

        appInsights.startTrackEvent("erstelleMitarbeiterEufemaCommand");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });
        const response = await fetch(request);
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent("erstelleMitarbeiterEufemaCommand", {
                    success: json.success,
                    error: json,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("erstelleMitarbeiterEufemaCommand", {
                    success: response.ok,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: ERSTELLE_MITARBEITER_EUFEMA_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("erstelleMitarbeiterEufemaCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const loescheMindestlohnerklaerungCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("loescheMindestlohnerklaerungCommand");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/loesche-mindestlohnerklaerung/`,
            {
                method: "POST",
                body: formData,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent(
                    "loescheMindestlohnerklaerungCommand",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent(
                    "loescheMindestlohnerklaerungCommand",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: LOESCHE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("loescheMindestlohnerklaerungCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const pruefeMindestlohnerklaerungCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("pruefeMindestlohnerklaerungCommand");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });

        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/pruefe-mindestlohnerklaerung/`,
            {
                method: "POST",
                body: formData,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent(
                    "pruefeMindestlohnerklaerungCommand",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent(
                    "pruefeMindestlohnerklaerungCommand",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: PRUEFE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("pruefeMindestlohnerklaerungCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const entpruefeMindestlohnerklaerungCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("entpruefeMindestlohnerklaerungCommand");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });

        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/entpruefe-mindestlohnerklaerung`,
            {
                method: "POST",
                body: formData,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json & !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent(
                    "entpruefeMindestlohnerklaerungCommand",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent(
                    "entpruefeMindestlohnerklaerungCommand",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: ENTPRUEFE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "entpruefeMindestlohnerklaerungCommand",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const erstelleMindestlohnerklaerungCommand =
    (data) => async (dispatch) => {
        const headers = new Headers(),
            formData = new FormData(),
            requestUrl = `${apiHost}/api/zuko/v3/${data.projektId}/erstelle-mindestlohnerklaerung/`;

        headers.append("Accept", "application/json");
        formData.append("file", data.file, data.file.name);
        formData.append(
            "MindestlohnerklaerungId",
            data.mindestlohnerklaerungId
        );

        const request = new Request(requestUrl, {
            method: "POST",
            body: formData,
            headers: headers,
            credentials: "include",
        });

        appInsights.startTrackEvent("erstelleMindestlohnerklaerungCommand");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });
        const response = await fetch(request);
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json & !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent(
                    "erstelleMindestlohnerklaerungCommand",
                    {
                        success: json.success,
                        error: json.payload,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent(
                    "erstelleMindestlohnerklaerungCommand",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: ERSTELLE_MINDESTLOHNERKLAERUNG_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("erstelleMindestlohnerklaerungCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const erstelleWeitereMitarbeiterEufemaCommand =
    (data) => async (dispatch) => {
        const headers = new Headers(),
            formData = new FormData(),
            requestUrl = `${apiHost}/api/zuko/v3/${data.projektId}/erstelle-weitere-mitarbeiter-eufema/`;

        headers.append("Accept", "application/json");
        formData.append("file", data.file, data.file.name);
        formData.append("Name", data.eufemaName);
        formData.append("MitarbeiterId", data.mitarbeiterId);

        const request = new Request(requestUrl, {
            method: "POST",
            body: formData,
            headers: headers,
            credentials: "include",
        });

        appInsights.startTrackEvent("erstelleWeitereMitarbeiterEufemaCommand");
        dispatch({ type: MITARBEITER_ACTION_BEGIN });
        const response = await fetch(request);
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent(
                    "erstelleWeitereMitarbeiterEufemaCommand",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent(
                    "erstelleWeitereMitarbeiterEufemaCommand",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: ERSTELLE_WEITERE_MITARBEITER_EUFEMA_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "erstelleWeitereMitarbeiterEufemaCommand",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const aktualisiereMitarbeiterQualifikationenCommand =
    (data, projektId) => async (dispatch) => {
        appInsights.startTrackEvent(
            "aktualisiereMitarbeiterQualifikationenCommand"
        );
        dispatch({ type: MITARBEITER_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/aktualisiere-mitarbeiter-qualifikationen/`,
            {
                method: "POST",
                body: data,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent(
                    "erstelleWeitereMitarbeiterEufemaCommand",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: MITARBEITER_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent(
                    "aktualisiereMitarbeiterQualifikationenCommand",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: AKTUALISIERE_MITARBEITER_QUALIFIKATIONEN_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "aktualisiereMitarbeiterQualifikationenCommand",
                { success: json.success }
            );
            return json.payload;
        }
    };

export const fetchDruckvorlage = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchDruckvorlage");
    dispatch({ type: MITARBEITER_DRUCKVORLAGE_ACTION_BEGIN });
    const url = `${apiHost}/api/zuko/v2/${data.projektId}/baustellenausweis-druckvorlage/?baustellenausweisId=${data.baustellenausweisId}&projektname=${data.projektname}`;
    const response = await fetch(url, {
        method: "GET",
    });
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: MITARBEITER_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("fetchDruckvorlage", {
                success: json.success,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: MITARBEITER_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("fetchDruckvorlage", {
                success: response.ok,
            });
        }
    } else if (json?.success) {
        appInsights.stopTrackEvent("fetchDruckvorlage", {
            success: json.success,
        });
        dispatch({
            type: FETCH_MITARBEITER_AUSWEIS_VORLAGE_SUCCESS,
            payload: json.payload,
        });
        return json.payload;
    }
};
