export const TRANSLATIONS_DE_LAENDER = {
    DEUTSCHLAND: "Deutschland",
    POLEN: "Polen",
    TSCHECHIEN: "Tschechien",
    DEUTSCHLAND: "Deutschland",
    POLEN: "Polen",
    TSCHECHIEN: "Tschechien",
    RUMÄNIEN: "Rumänien",
    ALBANIEN: "Albanien",
    BULGARIEN: "Bulgarien",
    GRIECHENLAND: "Griechenland",
    ITALIEN: "Italien",
    KROATIEN: "Kroatien",
    LETTLAND: "Lettland",
    MAZEDONIEN: "Mazedonien",
    NIEDERLANDE: "Niederlande",
    PORTUGAL: "Portugal",
    FRANKREICH: "Frankreich",
    RUSSISCHE_FÖDERATION: "Russische Förderation",
    TÜRKEI: "Türkei",
    AFGHANISTAN: " Afghanistan",
    ÄGYPTEN: "Ägypten",
    ALGERIEN: "Algerien",
    ANDORRA: "Andorra",
    ANGOLA: "Angola",
    ANTIGUA_UND_BARBUDA: "Antigua und Barbuda",
    ARABISCHE_EMIRATE: "Arabische Emirate",
    ÄQUATORIALGUINEA: "Äquatorialguinea",
    ARGENTINIEN: "Argentinien",
    ARMENIEN: "Armenien",
    ASERBAIDSCHAN: "Aserbaidschan",
    ÄTHIOPIEN: "Äthiopien",
    AUSTRALIEN: "Australien",
    BAHAMAS: "Bahamas",
    BAHRAIN: "Bahrain",
    BANGLADESCH: "Bangladesch",
    BARBADOS: "Barbados",
    BELGIEN: "Belgien",
    BELIZE: " Belize",
    BENIN: "Benin",
    BHUTAN: "Bhutan",
    BOLIVIEN: "Bolivien",
    BOSNIEN_UND_HERZEGOWINA: "Bosnien und Herzegowina",
    BOTSUANA: "Botsuana",
    BRASILIEN: "Brasilien",
    BRUNEI_DARUSSALAM: "Brunei Darussalam",
    BULGARIEN: "Bulgarien",
    BURKINA_FASO: "Burkina Faso",
    BURUNDI: "Burundi",
    CABO_VERDE: "Cabo Verde",
    CHILE: "Chile",
    CHINA: "China",
    COOKINSELN: "Cookinseln",
    COSTA_RICA: "Costa Rica",
    CÔTE_DIVOIRE: "Côte d’Ivoire",
    DEMOKRATISCHE_VOLKSREPUBLIK_LAOS: "Demokratische Volksrepublik Laos",
    DOMINICA: " Dominica",
    DOMINIKANISCHE_REPUBLIK: "Dominikanische Republik",
    DSCHIBUTI: "Dschibuti",
    DÄNEMARK: "Dänemark",
    ECUADOR: "Ecuador",
    EL_SALVADOR: "El Salvador",
    ERITREA: "Eritrea",
    ESTLAND: "Estland",
    ESWATINI: "Eswatini",
    FIDSCHI: "Fidschi",
    FINNLAND: "Finnland",
    FÖDERIERTE_STAATEN_VON_MIKRONESIEN: "Föderierte Staaten von Mikronesien",
    GABUN: "Gabun",
    GAMBIA: "Gambia",
    GEORGIEN: "Georgien",
    GHANA: "Ghana",
    GRENADA: "Grenada",
    GUATEMALA: "Guatemala",
    GUINEA: "Guinea",
    GUINEA_BISSAU: "Guinea-Bissau",
    GUYANA: "Guyana",
    HAITI: "Haiti",
    HONDURAS: "Honduras",
    INDIEN: "Indien",
    INDONESIEN: "Indonesien",
    IRAK: "Irak",
    IRAN: "Iran",
    IRLAND: "Irland",
    ISLAND: "Island",
    ISRAEL: "Israel",
    ITALIEN: "Italien",
    JAMAIKA: "Jamaika",
    JAPAN: "Japan",
    JEMEN: "Jemen",
    JORDANIEN: "Jordanien",
    KAMBODSCHA: "Kambodscha",
    KAMERUN: "Kamerun",
    KANADA: "Kanada",
    KASACHSTAN: "Kasachstan",
    KATAR: "Katar",
    KENIA: "Kenia",
    KIRGISISTAN: "Kirgisistan",
    KIRIBATI: "Kiribati",
    KOLUMBIEN: "Kolumbien",
    KOMOREN: "Komoren",
    KONGO_DEMOKRATISCHE_REPUBLIK: "Kongo, Demokratische Republik",
    KONGO_REPUBLIK: "Kongo, Republik",
    KOREA_DEMOKRATISCHE_VOLKSREPUBLIK: "Korea, Demokratische Volksrepublik",
    KOREA_REPUBLIK: "Korea, Republik",
    KOSOVO: "Kosovo",
    KROATIEN: "Kroatien",
    KUBA: "Kuba",
    KUWAIT: "Kuwait",
    LESOTHO: "Lesotho",
    LETTLAND: "Lettland",
    LIBANON: "Libanon",
    LIBERIA: "Liberia",
    LIBYEN: "Libyen",
    LIECHTENSTEIN: "Liechtenstein",
    LITAUEN: "Litauen",
    LUXEMBURG: "Luxemburg",
    MADAGASKAR: "Madagaskar",
    MALAWI: "Malawi",
    MALAYSIA: "Malaysia",
    MALEDIVEN: "Malediven",
    MALI: "Mali",
    MALTA: "Malta",
    MAROKKO: "Marokko",
    MARSHALLINSELN: "Marshallinseln",
    MAURETANIEN: "Mauretanien",
    MAURITIUS: "Mauritius",
    MAZEDONIEN: "Mazedonien",
    MEXIKO: "Mexiko",
    MOLDAWISCH: "Moldawisch",
    MONACO: "Monaco",
    MONGOLEI: "Mongolei",
    MONTENEGRO: "Montenegro",
    MOSAMBIK: "Mosambik",
    MYANMAR: "Myanmar",
    NAMIBIA: "Namibia",
    NAURU: "Nauru",
    NEPAL: "Nepal",
    NEUSEELAND: "Neuseeland",
    NICARAGUA: "Nicaragua",
    NIGER: "Niger",
    NIGERIA: "Nigeria",
    NIUE: "Niue",
    NORWEGEN: "Norwegen",
    ÖSTERREICH: "Österreich",
    OMAN: "Oman",
    PAKISTAN: "Pakistan",
    PALAU: "Palau",
    PALÄSTINA: "Palästina",
    PANAMA: "Panama",
    PAPUA_NEUGUINEA: "Papua-Neuguinea",
    PARAGUAY: "Paraguay",
    PERU: "Peru",
    PHILIPPINEN: "Philippinen",
    POLEN: "Polen",
    RUANDA: "Ruanda",
    SALOMONEN: "Salomonen",
    SAMBIA: "Sambia",
    SAMOA: "Samoa",
    SAN_MARINO: "San Marino",
    SAUDI_ARABIEN: "Saudi-Arabien",
    SCHWEDEN: "Schweden",
    SCHWEIZ: "Schweiz",
    SENEGAL: "Senegal",
    SERBIEN: "Serbien",
    SEYCHELLEN: "Seychellen",
    SIERRA_LEONE: "Sierra Leone",
    SIMBABWE: "Simbabwe",
    SINGAPUR: "Singapur",
    SLOWAKEI: "Slowakei",
    SLOWENIEN: "Slowenien",
    SOMALIA: "Somalia",
    SPANIEN: "Spanien",
    SRI_LANKA: "Sri Lanka",
    ST_KITTS_UND_NEVIS: "St. Kitts und Nevis",
    ST_LUCIA: "St. Lucia",
    ST_VINCENT_UND_DIE_GRENADINEN: "St. Vincent und die Grenadinen",
    STAATENLOS: "Staatenlos",
    SUDAN: "Sudan",
    SURINAME: "Suriname",
    SYRIEN: "Syrien",
    SÃO_TOMÉ_UND_PRÍNCIPE: "São Tomé und Príncipe",
    SÜDAFRIKA: "Südafrika",
    SÜDSUDAN: "Südsudan",
    TADSCHIKISTAN: "Tadschikistan",
    TANSANIA: "Tansania",
    THAILAND: "Thailand",
    TIMOR_LESTE: "Timor-Leste",
    TOGO: "Togo",
    TONGA: "Tonga",
    TRINIDAD_UND_TOBAGO: "Trinidad und Tobago",
    TSCHAD: "Tschad",
    TUNESIEN: "Tunesien",
    TURKMENISTAN: "Turkmenistan",
    TUVALU: "Tuvalu",
    TÜRKEI: "Türkei",
    UGANDA: "Uganda",
    UKRAINE: "Ukraine",
    UNGARN: "Ungarn",
    URUGUAY: "Uruguay",
    USBEKISTAN: "Usbekistan",
    VANUATU: "Vanuatu",
    VATIKANSTADT: "Vatikanstadt",
    VENEZUELA: "Venezuela",
    VEREINIGTE_STAATEN_VON_AMERIKA: "Vereinigte Staaten von Amerika",
    VEREINIGTES_KÖNIGREICH: "Vereinigtes Königreich",
    VIETNAM: "Vietnam",
    WEISSRUSSLAND: "Weißrussland",
    ZENTRALAFRIKANISCHE_REPUBLIK: " Zentralafrikanische Republik",
    ZYPERN: "Zypern",
    SPRACHEN: {
        ALBANISCH: "Albanisch",
        ARABISCH: "Arabisch",
        BOSNISCH: "Bosnisch",
        BULGARISCH: "Bulgarisch",
        DEUTSCH: "Deutsch",
        ENGLISCH: "Englisch",
        FRANZÖSISCH: "Französisch",
        GRIECHISCH: "Griechisch",
        ITALIENISCH: "Italienisch",
        KROATISCH: "Kroatisch",
        LETTISCH: "Lettisch",
        MAZEDONISCH: "Mazedonisch",
        NIEDERLÄNDISCH: "Niederländisch",
        PERSISCH: "Persisch",
        POLNISCH: "Polnisch",
        PORTUGIESISCH: "Portugiesisch",
        RUMÄNISCH: "Rumänisch",
        RUSSISCH: "Russisch",
        SERBISCH: "Serbisch",
        SLOWAKISCH: "Slowakisch",
        SLOWENISCH: "Slowenisch",
        SPANISCH: "Spanisch",
        TSCHECHISCH: "Tschechisch",
        TÜRKISCH: "Türkisch",
        UNGARISCH: "Ungarisch",
    },
};
export default TRANSLATIONS_DE_LAENDER;
