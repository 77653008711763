import { TRANSLATIONS_EN } from "./locales/en/translation.js";
import { TRANSLATIONS_DE } from "./locales/de/translation.js";
import { TRANSLATIONS_DE_ZUKO } from "./locales/de/translation-zuko.js";
import { TRANSLATIONS_EN_ZUKO } from "./locales/en/translation-zuko.js";
import { TRANSLATIONS_DE_PROJEKT } from "./locales/de/translation-projekt.js";
import { TRANSLATIONS_EN_PROJEKT } from "./locales/en/translation-projekt.js";
import { TRANSLATION_EN_DATENSCHUTZ } from "./locales/en/translation-datenschutz.js";
import { TRANSLATION_DE_DATENSCHUTZ } from "./locales/de/translation-datenschutz.js";
import { TRANSLATION_EN_NUTZUNGSBEDINGUNGEN } from "./locales/en/translation-agb.js";
import { TRANSLATION_DE_NUTZUNGSBEDINGUNGEN } from "./locales/de/translation-agb.js";
import { TRANSLATIONS_EN_LAENDER } from "./locales/en/translation-laender.js";
import { TRANSLATIONS_DE_LAENDER } from "./locales/de/translation-laender.js";
import { TRANSLATIONS_EN_GEWERKE } from "./locales/en/translation-gewerke";
import { TRANSLATIONS_DE_GEWERKE } from "./locales/de/translation-gewerke.js";
import { TRANSLATIONS_EN_DASHBOARD } from "./locales/en/translation-dashboard.js";
import { TRANSLATIONS_DE_DASHBOARD } from "./locales/de/translation-dashboard.js";
import { TRANSLATIONS_EN_CONTAINER } from "./locales/en/translation-container.js";
import { TRANSLATIONS_DE_CONTAINER } from "./locales/de/translation-container.js";
import { TRANSLATIONS_EN_AVIS } from "./locales/en/translation-avis.js";
import { TRANSLATIONS_DE_AVIS } from "./locales/de/translation-avis.js";
import { TRANSLATIONS_EN_NEWS } from "./locales/en/translation-news.js";
import { TRANSLATIONS_DE_NEWS } from "./locales/de/translation-news.js";
import i18next from "i18next";

export const init = async () => {
    var language = localStorage.getItem("language") || navigator.language;
    var lang = language?.split("-")[0];
    await i18next.init({
        debug: false,
        fallbackLng: "de",
        initImmediate: false,
        keySeparator: ".",
        resources: {
            de: {
                translation: {
                    ALLGEMEIN: TRANSLATIONS_DE,
                    AVIS: TRANSLATIONS_DE_AVIS,
                    CONTAINER: TRANSLATIONS_DE_CONTAINER,
                    DASHBOARD: TRANSLATIONS_DE_DASHBOARD,
                    DATENSCHUTZ: TRANSLATION_DE_DATENSCHUTZ,
                    GEWERKE: TRANSLATIONS_DE_GEWERKE,
                    LAENDER: TRANSLATIONS_DE_LAENDER,
                    NEWS: TRANSLATIONS_DE_NEWS,
                    NUTZUNGSBEDINGUNGEN: TRANSLATION_DE_NUTZUNGSBEDINGUNGEN,
                    PROJEKT: TRANSLATIONS_DE_PROJEKT,
                    ZUKO: TRANSLATIONS_DE_ZUKO,
                },
            },
            en: {
                translation: {
                    ALLGEMEIN: TRANSLATIONS_EN,
                    AVIS: TRANSLATIONS_EN_AVIS,
                    CONTAINER: TRANSLATIONS_EN_CONTAINER,
                    DASHBOARD: TRANSLATIONS_EN_DASHBOARD,
                    DATENSCHUTZ: TRANSLATION_EN_DATENSCHUTZ,
                    GEWERKE: TRANSLATIONS_EN_GEWERKE,
                    LAENDER: TRANSLATIONS_EN_LAENDER,
                    NEWS: TRANSLATIONS_EN_NEWS,
                    NUTZUNGSBEDINGUNGEN: TRANSLATION_EN_NUTZUNGSBEDINGUNGEN,
                    PROJEKT: TRANSLATIONS_EN_PROJEKT,
                    ZUKO: TRANSLATIONS_EN_ZUKO,
                },
            },
        },
        lng: lang,
        saveMissing: true,
    });
};

export const changeLanguage = async (language) => {
    i18next.changeLanguage(language);
};

export function translate(name, object) {
    return i18next.t(name, object);
}

export function replaceSonderzeichen(name) {
    const result = name
        .split(" ")
        .join("_")
        .split("-")
        .join("_")
        .replace(/[´`~!@#$%^&*()|+\=?;:'"^,’.<>\{\}\[\]\\\/]/gi, "");
    return result;
}
