import errorHandler from "../errorHandling";

export const ZUKO_EINSTELLUNGEN_ACTION_FAIL = "ZUKO_EINSTELLUNGEN_ACTION_FAIL";
export const ZUKO_EINSTELLUNGEN_ACTION_BEGIN =
    "ZUKO_EINSTELLUNGEN_ACTION_BEGIN";
export const FETCH_ZUKO_EINSTELLUNGEN_SUCCESS =
    "FETCH_ZUKO_EINSTELLUNGEN_SUCCESS";
export const ZUKO_AKTUALISIERE_EINSTELLUNGEN_SUCCESS =
    "ZUKO_AKTUALISIERE_EINSTELLUNGEN_SUCCESS";
export const RESET_ZUKO_EINSTELLUNGEN_SUCCESS =
    "RESET_ZUKO_EINSTELLUNGEN_SUCCESS";
export const ZUKO_EINSTELLUNGEN_SYNCHRONISIERE_ZUTRITTSEREIGNISSE_SUCCESS =
    "ZUKO_EINSTELLUNGEN_SYNCHRONISIERE_ZUTRITTSEREIGNISSE_SUCCESS";

//#region Dashboard
//#region Zutrittspunkte
export const FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_SUCCESS =
    "FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_SUCCESS";
export const FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ACTION_FAIL =
    "FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ACTION_FAIL";
export const FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ACTION_BEGIN =
    "FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ACTION_BEGIN";
//#endregion

//#region Zeitzonen
export const FETCH_EINSTELLUNGEN_ZEITZONEN_ACTION_BEGIN =
    "FETCH_EINSTELLUNGEN_ZEITZONEN_ACTION_BEGIN";
export const FETCH_EINSTELLUNGEN_ZEITZONEN_ACTION_FAIL =
    "FETCH_EINSTELLUNGEN_ZEITZONEN_ACTION_FAIL";
export const FETCH_EINSTELLUNGEN_ZEITZONEN_SUCCESS =
    "FETCH_EINSTELLUNGEN_ZEITZONEN_SUCCESS";
//#endregion

//#region Berechtigungsgruppen
export const FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPEN_ACTION_BEGIN =
    "FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPEN_ACTION_BEGIN";
export const FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPEN_ACTION_FAIL =
    "FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPEN_ACTION_FAIL";
export const FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPEN_SUCCESS =
    "FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPEN_SUCCESS";
//#endregion

//#region Mindestlohnkonfigurator
export const FETCH_EINSTELLUNGEN_MINDESTLOHNKONFIGURATOR_ACTION_BEGIN =
    "FETCH_EINSTELLUNGEN_MINDESTLOHNKONFIGURATOR_ACTION_BEGIN";
export const FETCH_EINSTELLUNGEN_MINDESTLOHNKONFIGURATOR_ACTION_FAIL =
    "FETCH_EINSTELLUNGEN_MINDESTLOHNKONFIGURATOR_ACTION_FAIL";
export const FETCH_EINSTELLUNGEN_MINDESTLOHNKONFIGURATOR_SUCCESS =
    "FETCH_EINSTELLUNGEN_MINDESTLOHNKONFIGURATOR_SUCCESS";
//#endregion

//#region Firmenunterlagen
export const FETCH_EINSTELLUNGEN_FIRMENUNTERLAGEN_ACTION_BEGIN =
    "FETCH_EINSTELLUNGEN_FIRMENUNTERLAGEN_ACTION_BEGIN";
export const FETCH_EINSTELLUNGEN_FIRMENUNTERLAGEN_ACTION_FAIL =
    "FETCH_EINSTELLUNGEN_FIRMENUNTERLAGEN_ACTION_FAIL";
export const FETCH_EINSTELLUNGEN_FIRMENUNTERLAGEN_SUCCESS =
    "FETCH_EINSTELLUNGEN_FIRMENUNTERLAGEN_SUCCESS";
//#endregion
//#region Personalunterlagen
export const FETCH_EINSTELLUNGEN_PERSONALUNTERLAGEN_ACTION_BEGIN =
    "FETCH_EINSTELLUNGEN_PERSONALUNTERLAGEN_ACTION_BEGIN";
export const FETCH_EINSTELLUNGEN_PERSONALUNTERLAGEN_ACTION_FAIL =
    "FETCH_EINSTELLUNGEN_PERSONALUNTERLAGEN_ACTION_FAIL";
export const FETCH_EINSTELLUNGEN_PERSONALUNTERLAGEN_SUCCESS =
    "FETCH_EINSTELLUNGEN_PERSONALUNTERLAGEN_SUCCESS";
//#endregion

//#region ZukoServer - Administration
export const FETCH_EINSTELLUNGEN_ZUKOSERVER_ACTION_BEGIN =
    "FETCH_EINSTELLUNGEN_ZUKOSERVER_ACTION_BEGIN";
export const FETCH_EINSTELLUNGEN_ZUKOSERVER_ACTION_FAIL =
    "FETCH_EINSTELLUNGEN_ZUKOSERVER_ACTION_FAIL";
export const FETCH_EINSTELLUNGEN_ZUKOSERVER_SUCCESS =
    "FETCH_EINSTELLUNGEN_ZUKOSERVER_SUCCESS";
//#endregion

//#region Zutrittspunkte - Administration
export const FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ADMINISTRATION_ACTION_BEGIN =
    "FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ADMINISTRATION_ACTION_BEGIN";
export const FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ADMINISTRATION_ACTION_FAIL =
    "FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ADMINISTRATION_ACTION_FAIL";
export const FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ADMINISTRATION_SUCCESS =
    "FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ADMINISTRATION_SUCCESS";
//#endregion

//#region Berechtigungsgruppe - Administration
export const FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPE_ADMINISTRATION_ACTION_BEGIN =
    "FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPE_ADMINISTRATION_ACTION_BEGIN";
export const FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPE_ADMINISTRATION_ACTION_FAIL =
    "FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPE_ADMINISTRATION_ACTION_FAIL";
export const FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPE_ADMINISTRATION_SUCCESS =
    "FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPE_ADMINISTRATION_SUCCESS";
//#endregion

//#region AusweisVorlagen - Dashboard
export const FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_VORLAGEN_ACTION_BEGIN =
    "FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_VORLAGEN_ACTION_BEGIN";
export const FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_VORLAGEN_ACTION_FAIL =
    "FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_VORLAGEN_ACTION_FAIL";
export const FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_VORLAGEN_SUCCESS =
    "FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_VORLAGEN_SUCCESS";
//#endregion

//#region AusweisGueltigkeit - Dashboard
export const FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_ACTION_BEGIN =
    "FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_ACTION_BEGIN";
export const FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_ACTION_FAIL =
    "FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_ACTION_FAIL";
export const FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_SUCCESS =
    "FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_SUCCESS";
//#endregion

//#region ausgeblendete Projektzeiten
export const AKTUALISIERE_ZUTRITTSEREIGNISSE_KONFIGURATION_ACTION_BEGIN =
    "AKTUALISIERE_ZUTRITTSEREIGNISSE_KONFIGURATION_ACTION_BEGIN";
export const AKTUALISIERE_ZUTRITTSEREIGNISSE_KONFIGURATION_ACTION_FAIL =
    "AKTUALISIERE_ZUTRITTSEREIGNISSE_KONFIGURATION_ACTION_FAIL";
export const AKTUALISIERE_ZUTRITTSEREIGNISSE_KONFIGURATION_SUCCESS =
    "AKTUALISIERE_ZUTRITTSEREIGNISSE_KONFIGURATION_SUCCESS";
//#endregion

//#region ausgeblendete Bildverifizierung
export const AKTUALISIERE_BILDVERIFIZIERUNG_KONFIGURATION_ACTION_BEGIN =
    "AKTUALISIERE_BILDVERIFIZIERUNG_KONFIGURATION_ACTION_BEGIN";
export const AKTUALISIERE_BILDVERIFIZIERUNG_KONFIGURATION_ACTION_FAIL =
    "AKTUALISIERE_BILDVERIFIZIERUNG_KONFIGURATION_ACTION_FAIL";
export const AKTUALISIERE_BILDVERIFIZIERUNG_KONFIGURATION_SUCCESS =
    "AKTUALISIERE_BILDVERIFIZIERUNG_KONFIGURATION_SUCCESS";

export const FETCH_EINSTELLUNGEN_DASHBOARD_BILDVERIFIZIERUNG_AKTIVIERT_ACTION_FAIL =
    "FETCH_EINSTELLUNGEN_DASHBOARD_BILDVERIFIZIERUNG_AKTIVIERT_ACTION_FAIL";
export const FETCH_EINSTELLUNGEN_DASHBOARD_BILDVERIFIZIERUNG_AKTIVIERT_SUCCESS =
    "FETCH_EINSTELLUNGEN_DASHBOARD_BILDVERIFIZIERUNG_AKTIVIERT_SUCCESS";
//#endregion

export const FETCH_EINSTELLUNGEN_RESET_SUCCESS =
    "FETCH_EINSTELLUNGEN_RESET_SUCCESS";
//#endregion

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;

let abortZutrittspunkte,
    abortZeitzonen,
    abortBerechtigungsgruppen,
    abortMindestlohnkonfigurator,
    abortFirmenunterlagen,
    abortPersonalunterlagen,
    abortZukoServerKonfiguration,
    abortZutrittspunkteAdministration,
    abortBerechtigungsgruppeAdministration,
    abortAusweisVorlagen,
    abortAusweisGueltigkeit,
    abortSindZutrittszeitenAktiviert,
    abortIstBildverifzierungAktualisierenAktiviert;

export const cancelDashboardEinstellungenRequests = () => (dispatch) => {
    try {
        abortZutrittspunkte?.abort();
        abortZeitzonen?.abort();
        abortBerechtigungsgruppen?.abort();
        abortMindestlohnkonfigurator?.abort();
        abortFirmenunterlagen?.abort();
        abortPersonalunterlagen?.abort();
        abortZutrittspunkteAdministration?.abort();
        abortZukoServerKonfiguration?.abort();
        abortBerechtigungsgruppeAdministration?.abort();
        abortAusweisVorlagen?.abort();
        abortAusweisGueltigkeit?.abort();
        abortSindZutrittszeitenAktiviert?.abort();
        abortIstBildverifzierungAktualisierenAktiviert?.abort();
    } catch (e) {
        console.log(e);
    }
    dispatch({ type: FETCH_EINSTELLUNGEN_RESET_SUCCESS });
    appInsights.trackEvent({ name: "cancelEinstllungenRequests" });
};

export const resetZukoEinstellungen = () => (dispatch) => {
    dispatch({ type: RESET_ZUKO_EINSTELLUNGEN_SUCCESS });
    appInsights.trackEvent({ name: "resetZukoEinstellungen" });
};

export const fetchZukoEinstellungen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchZukoEinstellungen");
    dispatch({ type: ZUKO_EINSTELLUNGEN_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/admin/v1/${data.projektId}/zuko-einstellung/`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: ZUKO_EINSTELLUNGEN_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("fetchZukoEinstellungen", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: ZUKO_EINSTELLUNGEN_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("fetchZukoEinstellungen", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_ZUKO_EINSTELLUNGEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchZukoEinstellungen", {
            success: json.success,
        });
    }
};

//#region Zeitzonen
export const fetchZeitzonen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchZeitzonen");
    dispatch({ type: FETCH_EINSTELLUNGEN_ZEITZONEN_ACTION_BEGIN });
    abortZeitzonen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/admin/v1/${data.projektId}/dashboard-einstellungen-zeitzonen`,
        { method: "GET", signal: abortZeitzonen?.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_EINSTELLUNGEN_ZEITZONEN_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchZeitzonen", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_EINSTELLUNGEN_ZEITZONEN_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchZeitzonen", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_EINSTELLUNGEN_ZEITZONEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchZeitzonen", {
            success: json.success,
        });
        return json.payload;
    }
};

//#region Zutrittspunkte
export const fetchZutrittspunkte = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchZutrittspunkte");
    dispatch({ type: FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ACTION_BEGIN });
    abortZutrittspunkte =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/admin/v1/${data.projektId}/dashboard-einstellungen-zutrittspunkte`,
        { method: "GET", signal: abortZutrittspunkte?.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchZutrittspunkte", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchZutrittspunkte", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchZutrittspunkte", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

//#region Berechtigungsgruppe
export const fetchBerechtigungsgruppen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchBerechtigungsgruppen");
    dispatch({ type: FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPEN_ACTION_BEGIN });
    abortBerechtigungsgruppen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/admin/v1/${data.projektId}/dashboard-einstellungen-berechtigungsgruppen`,
        { method: "GET", signal: abortBerechtigungsgruppen?.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchBerechtigungsgruppen", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPEN_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchBerechtigungsgruppen", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchBerechtigungsgruppen", {
            success: json.success,
        });
        return json.payload;
    }
};

//#endregion

//#region Mindestlohnkonfigurator
export const fetchMindestlohnkonfigurator = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchMindestlohnkonfigurator");
    dispatch({
        type: FETCH_EINSTELLUNGEN_MINDESTLOHNKONFIGURATOR_ACTION_BEGIN,
    });
    abortMindestlohnkonfigurator =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/admin/v1/${data.projektId}/dashboard-einstellungen-mindestlohnkonfigurator`,
        { method: "GET", signal: abortMindestlohnkonfigurator?.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_EINSTELLUNGEN_MINDESTLOHNKONFIGURATOR_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchMindestlohnkonfigurator", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_EINSTELLUNGEN_MINDESTLOHNKONFIGURATOR_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchMindestlohnkonfigurator", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_EINSTELLUNGEN_MINDESTLOHNKONFIGURATOR_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchMindestlohnkonfigurator", {
            success: json.success,
        });
        return json.payload;
    }
};

//#endregion

//#region Firmenunterlagen
export const fetchFirmenunterlagen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchFirmenunterlagen");
    dispatch({
        type: FETCH_EINSTELLUNGEN_FIRMENUNTERLAGEN_ACTION_BEGIN,
    });
    abortFirmenunterlagen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/admin/v1/${data.projektId}/dashboard-einstellungen-firmenunterlagen`,
        { method: "GET", signal: abortFirmenunterlagen?.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_EINSTELLUNGEN_FIRMENUNTERLAGEN_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchFirmenunterlagen", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_EINSTELLUNGEN_FIRMENUNTERLAGEN_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchFirmenunterlagen", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_EINSTELLUNGEN_FIRMENUNTERLAGEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchFirmenunterlagen", {
            success: json.success,
        });
        return json.payload;
    }
};

//#endregion

//#region Personalunterlagen
export const fetchPersonalunterlagen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchPersonalunterlagen");
    dispatch({
        type: FETCH_EINSTELLUNGEN_PERSONALUNTERLAGEN_ACTION_BEGIN,
    });
    abortPersonalunterlagen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/admin/v1/${data.projektId}/dashboard-einstellungen-personalunterlagen`,
        { method: "GET", signal: abortPersonalunterlagen?.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_EINSTELLUNGEN_PERSONALUNTERLAGEN_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchPersonalunterlagen", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_EINSTELLUNGEN_PERSONALUNTERLAGEN_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchPersonalunterlagen", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_EINSTELLUNGEN_PERSONALUNTERLAGEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchPersonalunterlagen", {
            success: json.success,
        });
        return json.payload;
    }
};

//#endregion

//#region ZukoServer - Administration
export const fetchZukoServerKonfiguration = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchZukoServerKonfiguration");
    dispatch({
        type: FETCH_EINSTELLUNGEN_ZUKOSERVER_ACTION_BEGIN,
    });
    abortZukoServerKonfiguration =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/admin/v1/${data.projektId}/dashboard-einstellungen-zuko-server`,
        { method: "GET", signal: abortZukoServerKonfiguration?.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_EINSTELLUNGEN_ZUKOSERVER_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchZukoServerKonfiguration", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_EINSTELLUNGEN_ZUKOSERVER_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchZukoServerKonfiguration", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_EINSTELLUNGEN_ZUKOSERVER_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchZukoServerKonfiguration", {
            success: json.success,
        });
        return json.payload;
    }
};

//#endregion

//#region Zutrittspunkte - Administration
export const fetchZutrittspunkteAdminstration = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchZutrittspunkteAdminstration");
    dispatch({
        type: FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ADMINISTRATION_ACTION_BEGIN,
    });
    abortZutrittspunkteAdministration =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/admin/v1/${data.projektId}/dashboard-einstellungen-zutrittspunkte-administration`,
        { method: "GET", signal: abortZutrittspunkteAdministration?.signal }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ADMINISTRATION_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchZutrittspunkteAdminstration", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ADMINISTRATION_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchZutrittspunkteAdminstration", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_EINSTELLUNGEN_ZUTRITTSPUNKTE_ADMINISTRATION_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchZutrittspunkteAdminstration", {
            success: json.success,
        });
        return json.payload;
    }
};

//#endregion

//#region Berechtigungsgruppe hinzufügen - Administration
export const fetchBerechtigungsgruppeAdminstration =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("fetchBerechtigungsgruppeAdminstration");
        dispatch({
            type: FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPE_ADMINISTRATION_ACTION_BEGIN,
        });
        abortBerechtigungsgruppeAdministration =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/admin/v1/${data.projektId}/dashboard-einstellungen-berechtigungsgruppen-administration`,
            {
                method: "GET",
                signal: abortBerechtigungsgruppeAdministration?.signal,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPE_ADMINISTRATION_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent(
                    "fetchBerechtigungsgruppeAdminstration",
                    {
                        success: json.success,
                        error: { errorMessage: json },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPE_ADMINISTRATION_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent(
                    "fetchBerechtigungsgruppeAdminstration",
                    {
                        success: response.ok,
                        error: { errorMessage: response },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_EINSTELLUNGEN_BERECHTIGUNGSGRUPPE_ADMINISTRATION_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchBerechtigungsgruppeAdminstration",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };
//#endregion

//#region DashboardAusweisVorlagen
export const fetchDashboardAusweisVorlagen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchDashboardAusweisVorlagen");
    dispatch({
        type: FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_VORLAGEN_ACTION_BEGIN,
    });
    abortAusweisVorlagen =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/admin/v1/${data.projektId}/dashboard-einstellungen-ausweis-vorlagen`,
        {
            method: "GET",
            signal: abortAusweisVorlagen?.signal,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_VORLAGEN_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchDashboardAusweisVorlagen", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_VORLAGEN_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchDashboardAusweisVorlagen", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_VORLAGEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchDashboardAusweisVorlagen", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

//#region DashboardAusweisGueltigkeit
export const fetchDashboardAusweisGueltigkeit = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchDashboardAusweisGueltigkeit");
    dispatch({
        type: FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_ACTION_BEGIN,
    });
    abortAusweisGueltigkeit =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/admin/v1/${data.projektId}/dashboard-einstellungen-ausweis-gueltigkeit`,
        {
            method: "GET",
            signal: abortAusweisGueltigkeit?.signal,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchDashboardAusweisGueltigkeit", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchDashboardAusweisGueltigkeit", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchDashboardAusweisGueltigkeit", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

export const fetchDashboardBildverifizierungAktiviert = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchDashboardAusweisGueltigkeit");
    dispatch({
        type: FETCH_EINSTELLUNGEN_DASHBOARD_AUSWEIS_GUELTIGKEIT_ACTION_BEGIN,
    });
    abortIstBildverifzierungAktualisierenAktiviert =
        typeof "AbortController" !== "undefined" && new AbortController();
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/dahsboard-einstellungen-bildverifizierung-betrachten`,
        {
            method: "GET",
            signal: abortIstBildverifzierungAktualisierenAktiviert
            ?.signal,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FETCH_EINSTELLUNGEN_DASHBOARD_BILDVERIFIZIERUNG_AKTIVIERT_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchDashboardBildverifizierungAktiviert", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FETCH_EINSTELLUNGEN_DASHBOARD_BILDVERIFIZIERUNG_AKTIVIERT_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchDashboardBildverifizierungAktiviert", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_EINSTELLUNGEN_DASHBOARD_BILDVERIFIZIERUNG_AKTIVIERT_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchDashboardBildverifizierungAktiviert", {
            success: json.success,
        });
        return json.payload;
    }
};


export const post_aktualisiereZukoEinstellungenKonfiguration =
    (url, formData) => async (dispatch) => {
        appInsights.startTrackEvent(
            "aktualisiereZukoEinstellungenKonfiguration"
        );
        dispatch({ type: ZUKO_EINSTELLUNGEN_ACTION_BEGIN });
        const response = await fetch(url, {
            method: "POST",
            body: formData,
        });
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }

        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: ZUKO_EINSTELLUNGEN_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent(
                    "aktualisiereZukoEinstellungenKonfiguration",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: ZUKO_EINSTELLUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "aktualisiereZukoEinstellungenKonfiguration",
                    {
                        success: response.ok,
                        error: response.payload,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: ZUKO_AKTUALISIERE_EINSTELLUNGEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "aktualisiereZukoEinstellungenKonfiguration",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const syncZutrittsereignisse = (e) => async (dispatch) => {
    appInsights.startTrackEvent("syncZutrittsereignisse");
    dispatch({ type: ZUKO_EINSTELLUNGEN_ACTION_BEGIN });
    const form = e.srcElement || e.target;
    const url = form.dataset.url;
    const formData = new FormData(form);

    const response = await fetch(url, {
        method: "POST",
        body: formData,
    });
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }

    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({ type: ZUKO_EINSTELLUNGEN_ACTION_FAIL, error: json });
            appInsights.stopTrackEvent("syncZutrittsereignisse", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({ type: ZUKO_EINSTELLUNGEN_ACTION_FAIL, error: response });
            appInsights.stopTrackEvent("syncZutrittsereignisse", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: ZUKO_EINSTELLUNGEN_SYNCHRONISIERE_ZUTRITTSEREIGNISSE_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("syncZutrittsereignisse", {
            success: json.success,
        });
        return json.payload;
    }
};

export const aktualisiereZutrittszeitenKonfiguration =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("aktualisiereZutrittszeitenKonfiguration");
        dispatch({
            type: AKTUALISIERE_ZUTRITTSEREIGNISSE_KONFIGURATION_ACTION_BEGIN,
        });
        abortSindZutrittszeitenAktiviert =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/admin/v1/${data.projektId}/aktualisiere-zutrittszeiten-konfiguration`,
            {
                method: "POST",
                signal: abortSindZutrittszeitenAktiviert?.signal,
                body: data.formData,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: AKTUALISIERE_ZUTRITTSEREIGNISSE_KONFIGURATION_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent(
                    "aktualisiereZutrittszeitenKonfiguration",
                    {
                        success: json.success,
                        error: { errorMessage: json },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: AKTUALISIERE_ZUTRITTSEREIGNISSE_KONFIGURATION_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent(
                    "aktualisiereZutrittszeitenKonfiguration",
                    {
                        success: response.ok,
                        error: { errorMessage: response },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: AKTUALISIERE_ZUTRITTSEREIGNISSE_KONFIGURATION_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "aktualisiereZutrittszeitenKonfiguration",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const aktualisiereBildverifzierungKonfiguration =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("aktualisiereBildverifzierungKonfiguration");
        dispatch({
            type: AKTUALISIERE_BILDVERIFIZIERUNG_KONFIGURATION_ACTION_BEGIN,
        });
        abortSindZutrittszeitenAktiviert =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/zuko/admin/v1/${data.projektId}/aktualisiere-bildverifizierung-konfiguration`,
            {
                method: "POST",
                signal: abortIstBildverifzierungAktualisierenAktiviert?.signal,
                body: data.formData,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: AKTUALISIERE_BILDVERIFIZIERUNG_KONFIGURATION_ACTION_FAIL,
                    error: { errorMessage: json },
                });
                appInsights.stopTrackEvent(
                    "aktualisiereBildverifzierungKonfiguration",
                    {
                        success: json.success,
                        error: { errorMessage: json },
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: AKTUALISIERE_BILDVERIFIZIERUNG_KONFIGURATION_ACTION_FAIL,
                    error: { errorMessage: response },
                });
                appInsights.stopTrackEvent(
                    "aktualisiereBildverifzierungKonfiguration",
                    {
                        success: response.ok,
                        error: { errorMessage: response },
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: AKTUALISIERE_BILDVERIFIZIERUNG_KONFIGURATION_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "aktualisiereZutrittszeitenKonfiguration",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };
