import {
    WOCHENAUSWERTUNGEN_ACTION_FAIL,
    FETCH_WOCHENAUSWERTUNGEN_RESET_SUCCESS,
    //#region Sperrgründe Top 5
    WOCHENAUSWERTUNGEN_SPERRGRUENDE_GESAMT_ACTION_BEGIN,
    FETCH_WOCHENAUSWERTUNGEN_SPERRGRUENDE_GESAMT_SUCCESS,
    //#endregion
    //#region Neu ausgestellte Ausweise
    WOCHENAUSWERTUNGEN_NEU_AUSGESTELLTE_AUSWEISE_ACTION_BEGIN,
    FETCH_WOCHENAUSWERTUNGEN_NEU_AUSGESTELLTE_AUSWEISE_SUCCESS,
    //#endregion
    //#region Anwesenheit im Wochenverlauf
    WOCHENAUSWERTUNGEN_ANWESENHEIT_WOCHENVERLAUF_ACTION_BEGIN,
    FETCH_WOCHENAUSWERTUNGEN_ANWESENHEIT_WOCHENVERLAUF_SUCCESS,
    //#endregion
    //#region gesperrte Mitarbeiter gesamt
    WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITER_GESAMT_ACTION_BEGIN,
    FETCH_WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITER_GESAMT_SUCCESS,
    //#endregion
    //#region gesperrte Mitarbeitende pro Firmen (Top 10)
    WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITENDE_FIRMEN_ACTION_BEGIN,
    FETCH_WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITENDE_FIRMEN_SUCCESS,
    //#endregion
    //#region Anwesenheit Mitarbeitende pro Firma Top 10
    WOCHENAUSWERTUNGEN_ANWESENHEIT_MITARBEITENDE_FIRMEN_ACTION_BEGIN,
    FETCH_WOCHENAUSWERTUNGEN_ANWESENHEIT_MITARBEITENDE_FIRMEN_SUCCESS,
    //#endregion
} from "../../actions/dashboard/wochenauswertungen.js";

const wochenAuswertungenState = {
    error: null,
    loading: false,
    neuAusgestellteAusweiseIstLoading: false,
    neuAusgestellteAusweise: {},
    anzahlGesperrterMitarbeiterIstLoading: false,
    anzahlGesperrterMitarbeiter: {},
    anwesenheitWochenverlaufIstLoading: false,
    anwesenheitWochenverlauf: {},
    spergruendeWochenverlaufIstLoading: false,
    spergruendeWochenverlauf: {},
    anwesenheitMitarbeitendeFirmenIstLoading: false,
    anwesenheitMitarbeitendeFirmen: {},
    gesperrteMitarbeiterFirmenIstLoading: false,
    gesperrteMitarbeiterFirmen: {},
};

const savedState =
    localStorage.getItem("savedState") ||
    JSON.stringify({ ...wochenAuswertungenState });
const INITIAL_STATE =
    JSON.parse(savedState).meineProjekteState || wochenAuswertungenState;

const wochenauswertung = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_WOCHENAUSWERTUNGEN_RESET_SUCCESS:
            return wochenAuswertungenState;
        case WOCHENAUSWERTUNGEN_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                neuAusgestellteAusweiseIstLoading: false,
                anzahlGesperrterMitarbeiterIstLoading: false,
                anwesenheitWochenverlaufIstLoading: false,
                spergruendeWochenverlaufIstLoading: false,
                anwesenheitMitarbeitendeFirmenIstLoading: false,
                gesperrteMitarbeiterFirmenIstLoading: false,
            };
        case WOCHENAUSWERTUNGEN_NEU_AUSGESTELLTE_AUSWEISE_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
                neuAusgestellteAusweiseIstLoading: true,
            };
        case FETCH_WOCHENAUSWERTUNGEN_NEU_AUSGESTELLTE_AUSWEISE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,

                neuAusgestellteAusweiseIstLoading: false,
                neuAusgestellteAusweise: action.payload,
            };
        case WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITER_GESAMT_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,

                anzahlGesperrterMitarbeiterIstLoading: true,
            };
        case FETCH_WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITER_GESAMT_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,

                anzahlGesperrterMitarbeiterIstLoading: false,
                anzahlGesperrterMitarbeiter: action.payload,
            };
        case WOCHENAUSWERTUNGEN_SPERRGRUENDE_GESAMT_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,

                spergruendeWochenverlaufIstLoading: true,
            };

        case FETCH_WOCHENAUSWERTUNGEN_SPERRGRUENDE_GESAMT_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,

                spergruendeWochenverlaufIstLoading: false,
                spergruendeWochenverlauf: action.payload,
            };

        case WOCHENAUSWERTUNGEN_ANWESENHEIT_WOCHENVERLAUF_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,

                anwesenheitWochenverlaufIstLoading: true,
            };
        case FETCH_WOCHENAUSWERTUNGEN_ANWESENHEIT_WOCHENVERLAUF_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,

                anwesenheitWochenverlaufIstLoading: false,
                anwesenheitWochenverlauf: action.payload,
            };
        case WOCHENAUSWERTUNGEN_ANWESENHEIT_MITARBEITENDE_FIRMEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,

                anwesenheitMitarbeitendeFirmenIstLoading: true,
            };
        case FETCH_WOCHENAUSWERTUNGEN_ANWESENHEIT_MITARBEITENDE_FIRMEN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,

                anwesenheitMitarbeitendeFirmenIstLoading: false,
                anwesenheitMitarbeitendeFirmen: action.payload,
            };
        case WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITENDE_FIRMEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,

                gesperrteMitarbeiterFirmenIstLoading: true,
            };
        case FETCH_WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITENDE_FIRMEN_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,

                gesperrteMitarbeiterFirmenIstLoading: false,
                gesperrteMitarbeiterFirmen: action.payload,
            };
        default:
            return state;
    }
};

export default wochenauswertung;
