import { LitElement, css } from "lit";
import { html } from "lit/html.js";

import { SbAdmin2Styles } from "../../css/sb-admin-2-styles.js";
import { AlgoStyles } from "../../css/algo-styles.js";
import { FontawesomeIcons } from "../../css/fontawesome-icons.js";

import "@polymer/paper-progress";

class BsCard extends LitElement {
    static get styles() {
        return [
            SbAdmin2Styles,
            AlgoStyles,
            FontawesomeIcons,
            css`
                paper-progress {
                    width: 98%;
                    --paper-progress-container-color: #fff;
                    --paper-progress-active-color: #f5bb00;
                    padding-right: 20px;
                    padding-left: 15px;
                    margin-left: 15px;
                    margin-right: 20px;
                }

                .font-weight-bold.text-primary {
                    color: #6d6e76;
                }

                .card-height {
                    min-height: 210px !important;
                    max-height: 210px !important;
                    height: 210px !important;
                    overflow-y: auto;
                    overflow-x: hidden;
                    margin-bottom: 5px !important;
                }

                .scrollbar-abstand {
                    height: 10px;
                }

                ::-webkit-scrollbar {
                    width: 5px;
                    height: 8px;
                    background-color: #aaa;
                }

                ::-webkit-scrollbar-thumb {
                    background: gray;
                }
            `,
        ];
    }

    static get properties() {
        return {
            cardTitle: { type: String },
            progressActive: { type: Boolean },
            hasHeight: { type: Boolean },
        };
    }

    render() {
        return html`
            <div class="card shadow mb-4">
                ${this.cardTitle !== ""
                    ? html` <div
                          class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
                      >
                          <h6 class="m-0 font-weight-bold">
                              ${this.cardTitle}
                          </h6>
                          <slot name="header-actions"></slot>
                      </div>`
                    : html``}
                <paper-progress
                    indeterminate
                    ?disabled="${!this.progressActive}"
                ></paper-progress>
                <div class="card-body ${this.hasHeight ? "card-height" : ""}">
                    <slot name="card-body"></slot>
                </div>
                <div class="scrollbar-abstand"></div>
            </div>
        `;
    }

    constructor() {
        super();
        this.cardTitle = "";
        this.progressActive = false;
    }

    firstUpdated() {
        const card = this.shadowRoot.querySelector("div.card");
        [...this.classList]?.map((x) => card.classList.add(x));
    }
}

window.customElements.define("bs-card", BsCard);
