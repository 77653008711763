import {
    MEINE_PROJEKTE_ACTION_FAIL,
    MEINE_PROJEKTE_ACTION_BEGIN,
    FETCH_MEINE_PROJEKTE_SUCCESS,
    RESET_MEINE_PROJEKTE_SUCCESS,
    STIMME_DSGVO_ZU_BEGIN,
    STIMME_DSGVO_ZU_FAIL,
    STIMME_DSGVO_ZU_SUCCESS,
} from "../../actions/projekt/meine-projekte.js";

const meineProjekteState = {
    error: null,
    loading: false,
    meineProjekte: [],
    hatNachrichtenProjektCard: false,
    istDsgvoZugestimmt: false,
};

const savedState =
    localStorage.getItem("savedState") ||
    JSON.stringify({ ...meineProjekteState });
const INITIAL_STATE =
    JSON.parse(savedState).meineProjekteState || meineProjekteState;

const meineProjekte = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case STIMME_DSGVO_ZU_FAIL:
        case MEINE_PROJEKTE_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case STIMME_DSGVO_ZU_BEGIN:
        case MEINE_PROJEKTE_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FETCH_MEINE_PROJEKTE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                hatNachrichtenProjektCard: action.payload?.hatNachrichtenProjektCard,
                meineProjekte: action.payload?.meineProjekte,
            };
        case STIMME_DSGVO_ZU_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                istDsgvoZugestimmt: action.payload,
            };
        case RESET_MEINE_PROJEKTE_SUCCESS:
            return meineProjekteState;
        default:
            return state;
    }
};

export default meineProjekte;
