const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;

export const erstelleAvisRessourcen = async (
    formData,
    language,
    projektId
) => {
    appInsights.startTrackEvent("erstelleAvisRessourcen");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${language}/${projektId}/erstelle-ressource`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("erstelleAvisRessourcen", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("erstelleAvisRessourcen", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("erstelleAvisRessourcen", {
            success: json.success,
        });
        return json;
    }
};

export const aktualisiereAvisRessourcen = async (
    formData,
    language,
    projektId
) => {
    appInsights.startTrackEvent("aktualisiereAvisRessourcen");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${language}/${projektId}/aktualisiere-ressource`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("aktualisiereAvisRessourcen", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("aktualisiereAvisRessourcen", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("aktualisiereAvisRessourcen", {
            success: json.success,
        });
        return json;
    }
};

export const archiviereAvisRessourcen = async (
    formData,
    language,
    projektId
) => {
    appInsights.startTrackEvent("archiviereAvisRessourcen");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${language}/${projektId}/archiviere-ressource`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("archiviereAvisRessourcen", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("archiviereAvisRessourcen", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("archiviereAvisRessourcen", {
            success: json.success,
        });
        return json;
    }
};
