const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;

export const fetchAvisBestimmungsort = async (sprache, projektId) => {
    appInsights.startTrackEvent("fetchAvisBestimmungsort");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/bestimmungsort-view-data`,
        {
            method: "GET",
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("fetchAvisBestimmungsort", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("fetchAvisBestimmungsort", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("fetchAvisBestimmungsort", {
            success: json.success,
        });
        return json;
    }
};

export const erstelleAvisBestimmungsort = async (
    formData,
    sprache,
    projektId
) => {
    appInsights.startTrackEvent("erstelleAvisBestimmungsort");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/erstelle-bestimmungsort`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("erstelleAvisBestimmungsort", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("erstelleAvisBestimmungsort", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("erstelleAvisBestimmungsort", {
            success: json.success,
        });
        return json;
    }
};

export const aktualisiereBestimmungsort = async (
    formData,
    language,
    projektId
) => {
    appInsights.startTrackEvent("aktualisiereBestimmungsort");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${language}/${projektId}/aktualisiere-bestimmungsort`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("aktualisiereBestimmungsort", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("aktualisiereBestimmungsort", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("aktualisiereBestimmungsort", {
            success: json.success,
        });
        return json;
    }
};

export const archiviereAvisBestimmungsort = async (
    formData,
    language,
    projektId
) => {
    appInsights.startTrackEvent("archiviereAvisBestimmungsort");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${language}/${projektId}/archiviere-bestimmungsort`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("archiviereAvisBestimmungsort", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("archiviereAvisBestimmungsort", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("archiviereAvisBestimmungsort", {
            success: json.success,
        });
        return json;
    }
};

