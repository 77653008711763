export const FETCH_WOCHENAUSWERTUNGEN_RESET_SUCCESS =
    "FETCH_WOCHENAUSWERTUNGEN_RESET_SUCCESS";
export const WOCHENAUSWERTUNGEN_ACTION_FAIL = "WOCHENAUSWERTUNGEN_ACTION_FAIL";

//#region Neu ausgestellte Ausweise
export const WOCHENAUSWERTUNGEN_NEU_AUSGESTELLTE_AUSWEISE_ACTION_BEGIN =
    "WOCHENAUSWERTUNGEN_NEU_AUSGESTELLTE_AUSWEISE_ACTION_BEGIN";
export const FETCH_WOCHENAUSWERTUNGEN_NEU_AUSGESTELLTE_AUSWEISE_SUCCESS =
    "FETCH_WOCHENAUSWERTUNGEN_NEU_AUSGESTELLTE_AUSWEISE_SUCCESS";
//#endregion

//#region gesperrte Mitarbeiter gesamt
export const WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITER_GESAMT_ACTION_BEGIN =
    "WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITER_GESAMT_ACTION_BEGIN";
export const FETCH_WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITER_GESAMT_SUCCESS =
    "FETCH_WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITER_GESAMT_SUCCESS";
//#endregion

//#region Anwesenheit im Wochenverlauf
export const WOCHENAUSWERTUNGEN_ANWESENHEIT_WOCHENVERLAUF_ACTION_BEGIN =
    "WOCHENAUSWERTUNGEN_ANWESENHEIT_WOCHENVERLAUF_ACTION_BEGIN";
export const FETCH_WOCHENAUSWERTUNGEN_ANWESENHEIT_WOCHENVERLAUF_SUCCESS =
    "FETCH_WOCHENAUSWERTUNGEN_ANWESENHEIT_WOCHENVERLAUF_SUCCESS";
//#endregion

//#region Anwesenheit Mitarbeitende pro Firma Top 10
export const WOCHENAUSWERTUNGEN_ANWESENHEIT_MITARBEITENDE_FIRMEN_ACTION_BEGIN =
    "WOCHENAUSWERTUNGEN_ANWESENHEIT_MITARBEITENDE_FIRMEN_ACTION_BEGIN";
export const FETCH_WOCHENAUSWERTUNGEN_ANWESENHEIT_MITARBEITENDE_FIRMEN_SUCCESS =
    "FETCH_WOCHENAUSWERTUNGEN_ANWESENHEIT_MITARBEITENDE_FIRMEN_SUCCESS";
//#endregion

//#region Sperrgründe Top 5
export const WOCHENAUSWERTUNGEN_SPERRGRUENDE_GESAMT_ACTION_BEGIN =
    "WOCHENAUSWERTUNGEN_SPERRGRUENDE_GESAMT_ACTION_BEGIN";
export const FETCH_WOCHENAUSWERTUNGEN_SPERRGRUENDE_GESAMT_SUCCESS =
    "FETCH_WOCHENAUSWERTUNGEN_SPERRGRUENDE_GESAMT_SUCCESS";
//#endregion

//#region gesperrte Mitarbeitende pro Firmen (Top 10)
export const WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITENDE_FIRMEN_ACTION_BEGIN =
    "WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITENDE_FIRMEN_ACTION_BEGIN";
export const FETCH_WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITENDE_FIRMEN_SUCCESS =
    "FETCH_WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITENDE_FIRMEN_SUCCESS";
//#endregion
import { showToast } from "../algo-app";
import errorHandler from "../errorHandling.js";
import { translate } from "../../../../src/i18n.js";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;

let abortNeuAusgestellteAusweise,
    abortGesperrteAusweiseGesamt,
    abortAnwesenheitWochenverlauf,
    abortAnwesenheitMitarbeitendeFirmen,
    abortSperrgründeGesamt,
    abortGesperrteMitarbeitendeFirmen;

export const cancelWochenRequests = () => (dispatch) => {
    try {
        abortNeuAusgestellteAusweise?.abort();
        abortGesperrteAusweiseGesamt?.abort();
        abortAnwesenheitWochenverlauf?.abort();
        abortAnwesenheitMitarbeitendeFirmen?.abort();
        abortSperrgründeGesamt?.abort();
        abortGesperrteMitarbeitendeFirmen?.abort();
    } catch (err) {
        console.log(err);
    }

    dispatch({ type: FETCH_WOCHENAUSWERTUNGEN_RESET_SUCCESS });
    appInsights.trackEvent({ name: "cancelWochenRequests" });
};

export const fetchWochenauswertungNeuAusgestellteAusweise =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchWochenauswertungNeuAusgestellteAusweise"
        );
        dispatch({
            type: WOCHENAUSWERTUNGEN_NEU_AUSGESTELLTE_AUSWEISE_ACTION_BEGIN,
        });
        abortNeuAusgestellteAusweise =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-wochenauswertung-ausgestellte-baustellenausweise/?startDatum=${data.startDatum}&endDatum=${data.endDatum}`,
            { method: "GET", signal: abortNeuAusgestellteAusweise.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: WOCHENAUSWERTUNGEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchWochenauswertungNeuAusgestellteAusweise",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: WOCHENAUSWERTUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchWochenauswertungNeuAusgestellteAusweise",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_WOCHENAUSWERTUNGEN_NEU_AUSGESTELLTE_AUSWEISE_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchWochenauswertungNeuAusgestellteAusweise",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchWochenauswertungGesperrteMitarbeiterGesamt =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchWochenauswertungGesperrteMitarbeiterGesamt"
        );
        dispatch({
            type: WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITER_GESAMT_ACTION_BEGIN,
        });
        abortGesperrteAusweiseGesamt =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-wochenauswertung-anzahl-gesperrter-mitarbeiter/?startDatum=${data.startDatum}&endDatum=${data.endDatum}`,
            { method: "GET", signal: abortGesperrteAusweiseGesamt.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: WOCHENAUSWERTUNGEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchWochenauswertungGesperrteMitarbeiterGesamt",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: WOCHENAUSWERTUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchWochenauswertungGesperrteMitarbeiterGesamt",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITER_GESAMT_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchWochenauswertungGesperrteMitarbeiterGesamt",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchWochenauswertungSperrgruendeWochenverlauf =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchWochenauswertungSperrgruendeWochenverlauf"
        );
        dispatch({ type: WOCHENAUSWERTUNGEN_SPERRGRUENDE_GESAMT_ACTION_BEGIN });
        abortSperrgründeGesamt =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-wochenauswertung-sperrgruende/?startDatum=${data.startDatum}&endDatum=${data.endDatum}&anzahl=${data.anzahl}`,
            { method: "GET", signal: abortSperrgründeGesamt.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: WOCHENAUSWERTUNGEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchWochenauswertungSperrgruendeWochenverlauf",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: WOCHENAUSWERTUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchWochenauswertungSperrgruendeWochenverlauf",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_WOCHENAUSWERTUNGEN_SPERRGRUENDE_GESAMT_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchWochenauswertungSperrgruendeWochenverlauf",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchWochenauswertungAnwesenheitWochenverlauf =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchWochenauswertungAnwesenheitWochenverlauf"
        );
        dispatch({
            type: WOCHENAUSWERTUNGEN_ANWESENHEIT_WOCHENVERLAUF_ACTION_BEGIN,
        });
        abortAnwesenheitWochenverlauf =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-wochenauswertung-anwesenheit-pro-tag/?startDatum=${data.startDatum}&endDatum=${data.endDatum}`,
            { method: "GET", signal: abortAnwesenheitWochenverlauf.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: WOCHENAUSWERTUNGEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchWochenauswertungAnwesenheitWochenverlauf",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: WOCHENAUSWERTUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchWochenauswertungAnwesenheitWochenverlauf",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_WOCHENAUSWERTUNGEN_ANWESENHEIT_WOCHENVERLAUF_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchWochenauswertungAnwesenheitWochenverlauf",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchWochenauswertungAnwesenheitFirmen =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent("fetchWochenauswertungAnwesenheitFirmen");
        dispatch({
            type: WOCHENAUSWERTUNGEN_ANWESENHEIT_MITARBEITENDE_FIRMEN_ACTION_BEGIN,
        });
        abortAnwesenheitMitarbeitendeFirmen =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-wochenauswertung-anwesenheit-firmen/?startDatum=${data.startDatum}&endDatum=${data.endDatum}&anzahl=${data.anzahl}`,
            {
                method: "GET",
                signal: abortAnwesenheitMitarbeitendeFirmen.signal,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: WOCHENAUSWERTUNGEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchWochenauswertungAnwesenheitFirmen",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: WOCHENAUSWERTUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchWochenauswertungAnwesenheitFirmen",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_WOCHENAUSWERTUNGEN_ANWESENHEIT_MITARBEITENDE_FIRMEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchWochenauswertungAnwesenheitFirmen",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const fetchWochenauswertungGesperrteMitarbeitendeFirmen =
    (data) => async (dispatch) => {
        appInsights.startTrackEvent(
            "fetchWochenauswertungGesperrteMitarbeitendeFirmen"
        );
        dispatch({
            type: WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITENDE_FIRMEN_ACTION_BEGIN,
        });
        abortGesperrteMitarbeitendeFirmen =
            typeof "AbortController" !== "undefined" && new AbortController();
        const response = await fetch(
            `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-wochenauswertung-anzahl-gesperrter-mitarbeiter-pro-firma/?startDatum=${data.startDatum}&endDatum=${data.endDatum}&anzahl=${data.anzahl}`,
            { method: "GET", signal: abortGesperrteMitarbeitendeFirmen.signal }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: WOCHENAUSWERTUNGEN_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent(
                    "fetchWochenauswertungGesperrteMitarbeitendeFirmen",
                    {
                        success: json.success,
                        error: json,
                    }
                );
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: WOCHENAUSWERTUNGEN_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent(
                    "fetchWochenauswertungGesperrteMitarbeitendeFirmen",
                    {
                        success: response.ok,
                        error: response,
                    }
                );
            }
        }
        if (json?.success) {
            dispatch({
                type: FETCH_WOCHENAUSWERTUNGEN_GESPERRTE_MITARBEITENDE_FIRMEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent(
                "fetchWochenauswertungGesperrteMitarbeitendeFirmen",
                {
                    success: json.success,
                }
            );
            return json.payload;
        }
    };

export const exportDashboardWochenauswertung = (data) => (dispatch) => {
    dispatch(showToast({ text: translate("DASHBOARD.EXPORT_TOAST") }));
    const a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/dashboard/v1/${data.projektId}/dashboard-wochenauswertung-export/?projektName=${data.projektName}&startDatum=${data.startDatum}&endDatum=${data.endDatum}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "exportDashboardWochenauswertung" });
};
