import {
    ALGO_APP_NAVIGATE_SUCCESS,
    ALGO_APP_SHOW_TOAST_SUCCESS,
    ALGO_APP_RESET_TOAST_SUCCESS,
    ALGO_APP_TOGGLE_SIDEBAR_SUCCESS,
    ALGO_APP_SET_PROJEKT_ID_SUCCESS,
} from '../actions/algo-app.js';

const algoAppState = {
    toast: {},
    navigator: {},
    projektId: '',
    isSidebarToggled: false,
};


const savedState = localStorage.getItem('savedState') || JSON.stringify({ ...algoAppState });
const INITIAL_STATE = JSON.parse(savedState).algoAppState || algoAppState;

const algoApp = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ALGO_APP_NAVIGATE_SUCCESS:
            return {
                ...state,
                navigator: action.payload
            };
        case ALGO_APP_SHOW_TOAST_SUCCESS:
            return {
                ...state,
                toast: action.payload
            };
        case ALGO_APP_RESET_TOAST_SUCCESS:
            return {
                ...state,
                toast: {}
            };
        case ALGO_APP_TOGGLE_SIDEBAR_SUCCESS:
            return {
                ...state,
                isSidebarToggled: !state.isSidebarToggled
            };
        case ALGO_APP_SET_PROJEKT_ID_SUCCESS:
            return {
                ...state,
                projektId: action.payload
            };
        default:
            return state;
    }
};

export default algoApp;