const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;

export const erstelleAvisModulUnterlage = async (
    formData,
    language,
    projektId
) => {
    appInsights.startTrackEvent("erstelleAvisModulUnterlage");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${language}/${projektId}/erstelle-modul-unterlage`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("erstelleAvisModulUnterlage", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("erstelleAvisModulUnterlage", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("erstelleAvisModulUnterlage", {
            success: json.success,
        });
        return json;
    }
};

export const aktualisiereAvisModulUnterlage = async (
    formData,
    language,
    projektId
) => {
    appInsights.startTrackEvent("aktualisiereAvisModulUnterlage");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${language}/${projektId}/aktualisiere-modul-unterlage`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("aktualisiereAvisModulUnterlage", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("aktualisiereAvisModulUnterlage", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("aktualisiereAvisModulUnterlage", {
            success: json.success,
        });
        return json;
    }
};

export const archiviereAvisModulUnterlage = async (
    formData,
    language,
    projektId
) => {
    appInsights.startTrackEvent("archiviereAvisModulUnterlage");
    const response = await fetch(
        `${apiHost}/api/avis/v1/${language}/${projektId}/archiviere-modul-unterlage`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("archiviereAvisModulUnterlage", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("archiviereAvisModulUnterlage", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("archiviereAvisModulUnterlage", {
            success: json.success,
        });
        return json;
    }
};

export const fetchAvisModulUnterlage = async (sprache, projektId, unterlageId) => {
    const response = await fetch(
        `${apiHost}/api/avis/v1/${sprache}/${projektId}/download-modul-unterlage/?unterlageId=${unterlageId}`,
        {
            method: "GET",
        }
    );
    return response;
};
