export const TRANSLATIONS_EN = {
    ALLE: "All",
    ANMELDEN: {
        ANGEMELDET_BLEIBEN: "Remain logged in",
        ANMELDEN: "Login",
        IMPRESSUM: "Imprint",
        PASSWORT: "Password",
        PASSWORT_VERGESSEN: "Forgot password",
        REGISTRIEREN: "Register",
        WILLKOMMEN: "Welcome",
    },
    DATEN_LADEN: "Data loading...",
    DATUM: "Date",
    ERROR: "Error",
    ERROR_HANDLER: {
        FEHLER_AUFGETRETEN: "An error has occurred",
        FOLGENDE_FEHLER: "The following errors occurred",
        NICHT_ANGEMELDET: "You are no longer logged in.",
        NICHT_GEFUNDEN: "Request couldn't found.",
        SERVER_ERROR: "Server error",
        SERVER_NICHT_ERREICHBAR: "Server currently unavailable",
        ZEITUEBERSCHREITUNG: "The operation timed out, please try again later.",
    },
    EXCEL_EXPORT: "Export Excel table",
    ES_IST_EIN_FEHLER_AUFGETRETEN: "An error has occurred",
    FIRMA: "Company",
    GESAMT: "Total",
    IMPRESSUM: {
        ANGABEN: "Entries referred to § 5 TMG",
        ANGABEN_BERUFSHAFTPFLICHT:
            "Name and registered office of the corporation",
        EINTRAGUNG: "Registration in the commercial register",
        GELTUNGSRAUM: "Scope of insurance",
        KONTAKT: "Contact",
        REGISTEREINTRAG: "Registration entry",
        REGISTERGERICHT: "Registration court",
        REGISTERNUMMER: "Registration number",
        TITEL_ANGABEN_BERUFSHAFTPFLICHT:
            "Information on professional liability insurance",
        UMSATZSTEUER_ID: "Sales tax ID",
        UMSATZSTEUER_IDENTIFIKATIONSNUMMER:
            "Sales tax ID according to §27 a sales tax law",
        VERTRETEN: "Represented by",
    },
    INFO: "Info",
    LANGUAGE: {
        MODAL_TITLE: "Update language in ALGO",
        MODAL_BODY: "Do you want to reload ALGO to apply language?",
    },
    MAILADRESSE: "E-mail address",
    MEINE: "My",
    MEINE_PROJEKTE: {
        DSGVO: "Data protection consent",
        ERSTELLEN: "Create projects",
        JETZT_ZUSTIMMEN: "Agree now",
        KEINE_DSGVO_VORHANDEN:
            "No project-specific data protection consent available",
        PROJEKT: "Project",
        PROJEKTBETEILIGTE: "Project participants",
        PROJEKTROLLE: "Project role",
        TITEL: "Projects",
        ZUSTIMMUNG_WIDERRUFEN: "Revoke consent",
    },
    NACHNAME: "Surname",
    NAVIGATIONS_ELEMENTE: {
        AVISIERUNG: "Notification",
        ABBRECHEN: "Cancel",
        ABMELDEN: "Logout",
        ABSCHICKEN: "Send",
        ANMELDEN: "Login",
        ARCHIV: "Archive",
        BAUSTELLE: "Construction site",
        BUCHUNGEN: "Bookings",
        CONTAINER: "Shipping management",
        CONTAINER: "Shipping",
        DASHBOARD: "Dashboard",
        DATENSCHUTZINFORMATION: "Information on data privacy and GTC",
        EINSTELLUNGEN: "Settings",
        FIRMEN: "Companies",
        HANDBUCH_AG: "Manualbook for clients",
        HANDBUCH_ANMELDUNG_UND_REGISTRATION:
            "Manualbook for login & registration",
        HANDBUCH_FIRMEN: "Manualbook for companies",
        IMPRESSUM: "Imprint",
        INFORMATION: "Information",
        JA: "Yes",
        LOESCHEN: "Delete",
        MEINE_PROJEKTE: "My projects",
        MITARBEITER: "Employees",
        NEWS: "News",
        ORGANISATION: "Organization",
        PASSWORT_VERGESSEN: "Forgot password",
        PROJEKTBETEILIGTE: "Project participants",
        PROJEKTROLLEN: "Project roles",
        REGISTRIEREN: "Register",
        RELOAD: "Reload",
        SCHLIESSEN: "Close",
        TRANSPORTE: "Transports",
        UEBERSICHT: "Overview",
        UNTERLAGEN: "Documents",
        WEITER: "Next",
        ZUKO_SERVER: "ZuKo server",
        ZURUECK: "Back",
        ZURUECK_ZU_PROJEKTEN: "Back to Projects",
        ZURUECKSETZEN: "Reset",
        ZUTRITTSKONTROLLE: "Access control",
        ZUTRITTSPUNKTE: "Entry points",
    },
    PASSWORT_VERGESSEN: {
        PASSWORT: "Password",
        PASSWORT_VERGESSEN_TITEL: "Forgot password?",
        PASSWORT_WIEDERHOLEN: "Repeat password",
        PASSWORT_ZURUECKSETZEN: "Reset password:",
        SCHLÜSSEL: "Key",
        SCHLÜSSEL_TITEL: "Please enter the key sent to you:",
    },
    PDF_EXPORT: "Export PDF list",
    REGISTRIEREN: {
        DATENSCHUTZ_TEIL1: "Information on data processing can be found in our",
        DATENSCHUTZ_TEIL2: "Privacy policy",
        DATENSCHUTZ_TEIL3: "",
        DATENSCHUTZ_AKZEPTIEREN_TEIL1: "I agree to the",
        DATENSCHUTZ_AKZEPTIEREN_TEIL2:
            "general terms and conditions",
        DATENSCHUTZ_AKZEPTIEREN_TEIL3: "",
        PASSWORT: "Password",
        PASSWORT_WIEDERHOLEN: "Repeat password",
        REGISTRIEREN: "Register",
        UNTERNEHMEN: "Company",
    },
    TOPBAR: {
        ABMELDUNG_ERFOLGREICH: "You have been successfully logged out",
        MODAL_BODY:
            "Select 'Log Out' below when you're ready to end your current session.",
        MODAL_TITLE: "Are you sure you want to log out?",
    },
    VORNAME: "Firstname",
    SPALTEN_FILTERN: "Filter columns",
    SUCCESS: "Successful",
    SUPPORT_NUMMER_KOPIERT:
        "The phonenummber of support was successfully copied",
};

export default TRANSLATIONS_EN;
