import {
    MEHRERE_MITARBEITER_ACTION_FAIL,
    MEHRERE_MITARBEITER_ACTION_BEGIN,
    FETCH_MEHRERE_MITARBEITER_SUCCESS,
    RESET_MEHRERE_MITARBEITER_SUCCESS,
    MINDESTLOHNPFLICHT_AKTIVIERT_ACTION_FAIL,
    MINDESTLOHNPFLICHT_AKTIVIERT_ACTION_BEGIN,
    MINDESTLOHNPFLICHT_AKTIVIERT_ACTION_SUCCESS,
    FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_ACTION_BEGIN,
    FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_ACTION_FAIL,
    FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_SUCCESS,
    RESET_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_SUCCESS,
    FETCH_MEHRERE_MITARBEITER_BAUSTELLENAUSWEISE_SUCCESS,
    RESET_MEHRERE_MITARBEITER_BAUSTELLENAUSWEISE_SUCCESS,
    PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_ACTION_FAIL,
    PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_ACTION_BEGIN,
    FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SPALTEN_BEGIN,
    FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SPALTEN_FAIL,
    FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SPALTEN_SUCCESS,
    FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SUCCESS,
    RESET_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SUCCESS,
} from "../../actions/zuko/personal.js";

const mehrereMitarbeiterState = {
    error: null,
    loading: false,
    mehrereMitarbeiter: [],
    istPersonalAuswertungenLoading: false,
    istPersonalAktuelleBesetzungLoading: false,
    mehrereMitarbeiterAuswertungen: [],
    istProjektMindestlohnAktiviert: null,
    mehrereMitarbeiterAktuelleBesetzung: [],
    mehrereMitarbeiterBaustellenausweise: [],
    mehrereMitarbeiterMindestlohnerklaerungen: [],
};

const savedState =
    localStorage.getItem("savedState") ||
    JSON.stringify({ ...mehrereMitarbeiterState });
const INITIAL_STATE =
    JSON.parse(savedState).mehrereMitarbeiterState || mehrereMitarbeiterState;

const mehrereMitarbeiter = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MINDESTLOHNPFLICHT_AKTIVIERT_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
            };
        case PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_ACTION_FAIL:
        case MEHRERE_MITARBEITER_ACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case MINDESTLOHNPFLICHT_AKTIVIERT_ACTION_BEGIN:
            return {
                ...state,
                error: null,
            };
        case MEHRERE_MITARBEITER_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FETCH_MEHRERE_MITARBEITER_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                mehrereMitarbeiter: action.payload,
            };
        case RESET_MEHRERE_MITARBEITER_SUCCESS:
            return {
                ...state,
                mehrereMitarbeiter: [],
                error: null,
            };
        case MINDESTLOHNPFLICHT_AKTIVIERT_ACTION_SUCCESS:
            return {
                ...state,
                error: null,
                istProjektMindestlohnAktiviert: action.payload,
            };
        case FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SPALTEN_BEGIN:
        case PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                istPersonalAuswertungLoading: true,
            };
        case FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SPALTEN_FAIL:
            return {
                ...state,
                error: action.error,
                istPersonalAuswertungLoading: false,
            };
        case FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SPALTEN_SUCCESS:
            return {
                ...state,
                error: null,
                istPersonalAuswertungLoading: false,
                personalAuswertungenSpalten: action.payload,
            };
        case FETCH_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SUCCESS:
            return {
                ...state,
                error: null,
                istPersonalAuswertungLoading: false,
                mehrereMitarbeiterAuswertungen: action.payload,
            };
        case RESET_PERSONAL_ZUTRITTSEREIGNISSE_AUSWERTUNGEN_SUCCESS:
            return {
                ...state,
                mehrereMitarbeiterAuswertungen: [],
                error: null,
            };
        case FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                istPersonalAktuelleBesetzungLoading: true,
            };
        case FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_ACTION_FAIL:
            return {
                ...state,
                error: action.error,
                istPersonalAktuelleBesetzungLoading: false,
            };
        case FETCH_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_SUCCESS:
            return {
                ...state,
                error: null,
                istPersonalAktuelleBesetzungLoading: false,
                mehrereMitarbeiterAktuelleBesetzung: action.payload,
            };
        case RESET_MEHRERE_MITARBEITER_AKTUELLE_BESETZUNG_SUCCESS:
            return {
                ...state,
                istPersonalAktuelleBesetzungLoading: false,
                mehrereMitarbeiterAktuelleBesetzung: [],
                error: null,
            };
        case FETCH_MEHRERE_MITARBEITER_BAUSTELLENAUSWEISE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                mehrereMitarbeiterBaustellenausweise: action.payload,
            };
        case RESET_MEHRERE_MITARBEITER_BAUSTELLENAUSWEISE_SUCCESS:
            return {
                ...state,
                mehrereMitarbeiterBaustellenausweise: [],
                error: null,
            };
        default:
            return state;
    }
};

export default mehrereMitarbeiter;
