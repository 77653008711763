import { LitElement } from "lit";
import { html } from "lit/html.js";

import { SbAdmin2Styles } from '../../css/sb-admin-2-styles.js';
import { FontawesomeIcons } from '../../css/fontawesome-icons.js';

class BsRow extends LitElement {
    static get styles() {
        return [
            SbAdmin2Styles,
            FontawesomeIcons
        ];
    }

    static get properties() {
        return {
            modalTitle: String
        };
    }

    render() {
        return html`<div class="row"><slot></slot></div>`;
    }
}

window.customElements.define('bs-row', BsRow);