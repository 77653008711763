export const ERSTELLE_PROJEKTROLLE_COMMAND_SUCCESS =
    "ERSTELLE_PROJEKTROLLE_COMMAND_SUCCESS";
export const PROJEKT_PROJEKTROLLE_ERSTELLEN_VIEW_DATA_ACTION_FAIL =
    "PROJEKT_PROJEKTROLLE_ERSTELLEN_VIEW_DATA_ACTION_FAIL";
export const PROJEKT_PROJEKTROLLE_ERSTELLEN_VIEW_DATA_ACTION_BEGIN =
    "PROJEKT_PROJEKTROLLE_ERSTELLEN_VIEW_DATA_ACTION_BEGIN";
export const PROJEKT_EXISTIERT_PROJEKTROLLE_ACTION_BEGIN =
    "PROJEKT_EXISTIERT_PROJEKTROLLE_ACTION_BEGIN";
export const PROJEKT_EXISTIERT_PROJEKTROLLE_ACTION_FAIL =
    "PROJEKT_EXISTIERT_PROJEKTROLLE_ACTION_FAIL";
export const PROJEKT_EXISTIERT_PROJEKTROLLE_SUCCESS =
    "PROJEKT_EXISTIERT_PROJEKTROLLE_SUCCESS";

import errorHandler from "../errorHandling.js";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;

export const erstelleProjektrolleCommand =
    (data, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("erstelleProjektrolleCommand");
        dispatch({
            type: PROJEKT_PROJEKTROLLE_ERSTELLEN_VIEW_DATA_ACTION_BEGIN,
        });

        const response = await fetch(
            `${apiHost}/api/projekt/${projektId}/erstelle-projektrolle/`,
            {
                method: "POST",
                body: data,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: PROJEKT_PROJEKTROLLE_ERSTELLEN_VIEW_DATA_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent("erstelleProjektrolleCommand", {
                    success: json.success,
                    error: json,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: PROJEKT_PROJEKTROLLE_ERSTELLEN_VIEW_DATA_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent("erstelleProjektrolleCommand", {
                    success: response.ok,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: ERSTELLE_PROJEKTROLLE_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("erstelleProjektrolleCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };
export const existiertProjektrolle = (data, projektId) => async (dispatch) => {
    appInsights.startTrackEvent("existiertProjektrolle");
    dispatch({ type: PROJEKT_EXISTIERT_PROJEKTROLLE_ACTION_BEGIN });

    const response = await fetch(
        `${apiHost}/api/projekt/v1/${projektId}/existiert-projektrolle/`,
        {
            method: "POST",
            body: data,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: PROJEKT_EXISTIERT_PROJEKTROLLE_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("existiertProjektrolle", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: PROJEKT_EXISTIERT_PROJEKTROLLE_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("existiertProjektrolle", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: PROJEKT_EXISTIERT_PROJEKTROLLE_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("existiertProjektrolle", {
            success: json.success,
        });
        return json.payload;
    }
};
