//#region Firma
export const FIRMA_AKTIV_SUCCESS = "FIRMA_AKTIV_SUCCESS";
export const FIRMA_ACTION_FAIL = "FIRMA_ACTION_FAIL";
export const FIRMA_ACTION_BEGIN = "FIRMA_ACTION_BEGIN";
export const RESET_FIRMA_SUCCESS = "RESET_FIRMA_SUCCESS";
export const LOESCHE_FIRMA_COMMAND_SUCCESS = "LOESCHE_FIRMA_COMMAND_SUCCESS";
//#endregion

//#region Dashboard
export const FETCH_FIRMA_DASHBOARD_SUCCESS = "FETCH_FIRMA_DASHBOARD_SUCCESS";
//#endregion

//#region Stammdaten
export const AKTUALISIERE_STAMMDATEN_SUCCESS =
    "AKTUALISIERE_STAMMDATEN_SUCCESS";
export const FETCH_FIRMA_STAMMDATEN_SUCCESS = "FETCH_FIRMA_STAMMDATEN_SUCCESS";
//#endregion

//#region Unterlagen
export const FIRMA_UNTERLAGEN_ACTION_FAIL = "FIRMA_UNTERLAGEN_ACTION_FAIL";
export const FIRMA_UNTERLAGEN_ACTION_BEGIN = "FIRMA_UNTERLAGEN_ACTION_BEGIN";
export const FETCH_FIRMA_UNTERLAGEN_SUCCESS = "FETCH_FIRMA_UNTERLAGEN_SUCCESS";
export const LOESCHE_EUFEFA_COMMAND_SUCCESS = "LOESCHE_EUFEFA_COMMAND_SUCCESS";
export const ERSTELLE_EUFEFA_COMMAND_SUCCESS =
    "ERSTELLE_EUFEFA_COMMAND_SUCCESS";
export const PRUEFE_FIRMA_EUFEFA_COMMAND_SUCCESS =
    "PRUEFE_EUFEFA_COMMAND_SUCCESS";
export const ENTPRUEFE_FIRMA_EUFEFA_COMMAND_SUCCESS =
    "ENTPRUEFE_FIRMA_EUFEFA_COMMAND_SUCCESS";
export const ERSTELLE_DYNAMISCHE_FIRMA_EUFEFA_COMMAND_SUCCESS =
    "ERSTELLE_DYNAMISCHE_FIRMA_EUFEFA_COMMAND_SUCCESS";
//#endregion

//#region Firmenbeziehung
export const FETCH_FIRMA_FIRMENBEZIEHUNG_SUCCESS =
    "FETCH_FIRMA_FIRMENBEZIEHUNG_SUCCESS";
export const FIRMA_FIRMENBEZIEHUNG_ACTION_BEGIN =
    "FIRMA_FIRMENBEZIEHUNG_ACTION_BEGIN";
export const FIRMA_FIRMENBEZIEHUNG_ACTION_FAIL =
    "FIRMA_FIRMENBEZIEHUNG_ACTION_FAIL";
//#endregion

//#region Auftraggeber
export const FIRMA_AUFTRAGGEBER_ACTION_FAIL = "FIRMA_AUFTRAGGEBER_ACTION_FAIL";
export const FIRMA_AUFTRAGGEBER_ACTION_BEGIN =
    "FIRMA_AUFTRAGGEBER_ACTION_BEGIN";
export const FETCH_FIRMA_AUFTRAGGEBER_SUCCESS =
    "FETCH_FIRMA_AUFTRAGGEBER_SUCCESS";
export const LOESCHE_FIRMA_AUFTRAGGEBER_SUCCESS =
    "LOESCHE_FIRMA_AUFTRAGGEBER_SUCCESS";
export const ERSTELLE_AUFTRAGGEBER_SUCCESS = "ERSTELLE_AUFTRAGGEBER_SUCCESS";
export const LOESCHE_AUFTRAGGEBER_COMMAND_SUCCESS =
    "LOESCHE_AUFTRAGGEBER_COMMAND_SUCCESS";
//#endregion

//#region Nachunternehmer
export const FIRMA_NACHUNTERNEHMER_ACTION_FAIL =
    " FIRMA_NACHUNTERNEHMER_ACTION_FAIL";
export const FIRMA_NACHUNTERNEHMER_ACTION_BEGIN =
    "FIRMA_NACHUNTERNEHMER_ACTION_BEGIN";
export const FETCH_FIRMA_NACHUNTERNEHMER_SUCCESS =
    "FETCH_FIRMA_NACHUNTERNEHMER_SUCCESS";
export const ERSTELLE_FIRMA_NACHUNTERNEHMER_SUCCESS =
    "ERSTELLE_FIRMA_NACHUNTERNEHMER_SUCCESS";
export const LOESCHE_FIRMA_NACHUNTERNEHMER_SUCCESS =
    "LOESCHE_FIRMA_NACHUNTERNEHMER_SUCCESS";
//#endregion

//#region Firmenverwalter
export const LOESCHE_FIRMA_VERWALTER_SUCCESS =
    "LOESCHE_FIRMA_VERWALTER_SUCCESS";
export const ERSTELLE_FIRMA_VERWALTER_SUCCESS =
    "ERSTELLE_FIRMENVERWALTER_SUCCESS";
export const FIRMA_VERWALTER_ACTION_FAIL = "FIRMA_VERWALTER_ACTION_FAIL";
export const FIRMA_VERWALTER_ACTION_BEGIN = "FIRMA_VERWALTER_ACTION_BEGIN";
export const FETCH_FIRMA_VERWALTER_SUCCESS = "FETCH_FIRMA_VERWALTER_SUCCESS";
//#endregion

//#region Mitarbeiter
export const FIRMA_MITARBEITER_ACTION_FAIL = "FIRMA_MITARBEITER_ACTION_FAIL";
export const FIRMA_MITARBEITER_ACTION_BEGIN = "FIRMA_MITARBEITER_ACTION_BEGIN";
export const FETCH_FIRMA_MITARBEITER_SUCCESS =
    "FETCH_FIRMA_MITARBEITER_SUCCESS";
//#endregion

//#region Baustellenausweis
export const FETCH_FIRMA_BAUSTELLENAUSWEIS_SUCCESS =
    "FETCH_FIRMA_BAUSTELLENAUSWEIS_SUCCESS";
export const FIRMA_BAUSTELLENAUSWEIS_ACTION_BEGIN =
    "FIRMA_BAUSTELLENAUSWEIS_ACTION_BEGIN";
export const FIRMA_BAUSTELLENAUSWEIS_ACTION_FAIL =
    "FIRMA_BAUSTELLENAUSWEIS_ACTION_FAIL";
//#endregion

import errorHandler from "./../errorHandling.js";

const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights,
    getRequestOptions = window.Algo.getRequestOptions;

export const resetFirma = () => (dispatch) => {
    dispatch({ type: RESET_FIRMA_SUCCESS });
    appInsights.trackEvent({ name: "resetFirma" });
};

//#region Dashboard
export const fetchFirmaDashboard = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchFirmaDashboard");
    dispatch({ type: FIRMA_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/firma-dashboard/?firmaId=${data.firmaId}`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FIRMA_ACTION_FAIL,
                error: { errorMessage: json },
            });
            appInsights.stopTrackEvent("fetchFirmaDashboard", {
                success: json.success,
                error: { errorMessage: json },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FIRMA_ACTION_FAIL,
                error: { errorMessage: response },
            });
            appInsights.stopTrackEvent("fetchFirmaDashboard", {
                success: response.ok,
                error: { errorMessage: response },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_FIRMA_DASHBOARD_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchFirmaDashboard", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

//#region Stammdaten
export const fetchFirmaStammdaten = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchFirma");
    dispatch({ type: FIRMA_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/firma-stammdaten/?firmaId=${data.firmaId}`,
        getRequestOptions
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }

    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FIRMA_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("fetchFirma", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FIRMA_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("fetchFirma", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_FIRMA_STAMMDATEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchFirma", { success: json.success });
    }
};

export const aktualisiereStammdatenCommand =
    (data, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("aktualisiereStammdatenCommand");
        dispatch({ type: FIRMA_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/aktualisiere-firma/`,
            {
                method: "POST",
                body: data,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }

        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FIRMA_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent("aktualisiereStammdatenCommand", {
                    success: json.success,
                    error: { errorMessage: json.toString() },
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FIRMA_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent("aktualisiereStammdatenCommand", {
                    success: response.ok,
                    error: { errorMessage: response.toString() },
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: AKTUALISIERE_STAMMDATEN_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("aktualisiereStammdatenCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };
//#endregion

export const setzeFirmaAktivitaet =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("setzeFirmaAktivitaet");
        dispatch({ type: FIRMA_ACTION_BEGIN });

        const response = await fetch(
            `${apiHost}/api/zuko/v1/${projektId}/setze-firma-aktivitaet/`,
            {
                method: "POST",
                body: formData,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: FIRMA_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent("setzeFirmaAktivitaet", {
                    success: json.success,
                    error: json.payload,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: FIRMA_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("setzeFirmaAktivitaet", {
                    success: response.success,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: FIRMA_AKTIV_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("setzeFirmaAktivitaet", {
                success: json.success,
            });
            return { success: true, payload: json.payload,};
        }
    };

//#region Firmenbeziehung
export const fetchFirmaFirmenbeziehung = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchFirmaFirmenbeziehung");
    dispatch({ type: FIRMA_FIRMENBEZIEHUNG_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/firma-firmenbeziehungen/?firmaId=${data.firmaId}`,
        getRequestOptions
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }

    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FIRMA_FIRMENBEZIEHUNG_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("fetchFirmaFirmenbeziehung", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FIRMA_FIRMENBEZIEHUNG_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("fetchFirmaFirmenbeziehung", {
                success: response.ok,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_FIRMA_FIRMENBEZIEHUNG_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchFirmaFirmenbeziehung", {
            success: json.success,
        });
    }
};
//#endregion

//#region  Auftraggeber
export const loescheAuftraggeberCommand =
    (data, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("loescheAuftraggeberCommand");
        dispatch({ type: FIRMA_AUFTRAGGEBER_ACTION_BEGIN });

        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/loesche-auftraggeber/`,
            {
                method: "POST",
                body: data,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FIRMA_AUFTRAGGEBER_ACTION_FAIL,
                    error: { errorMessage: json.toString() },
                });
                appInsights.stopTrackEvent("loescheAuftraggeberCommand", {
                    success: json.success,
                    error: json,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FIRMA_AUFTRAGGEBER_ACTION_FAIL,
                    error: { errorMessage: response.toString() },
                });
                appInsights.stopTrackEvent("loescheAuftraggeberCommand", {
                    success: response.ok,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: LOESCHE_FIRMA_AUFTRAGGEBER_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("loescheAuftraggeberCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const erstelleAuftraggeberCommand =
    (data, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("erstelleAuftraggeberCommand");
        dispatch({ type: FIRMA_AUFTRAGGEBER_ACTION_BEGIN });

        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/erstelle-auftraggeber/`,
            {
                method: "POST",
                body: data,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: FIRMA_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent("erstelleAuftraggeberCommand", {
                    success: json.success,
                    error: json,
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: FIRMA_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("erstelleAuftraggeberCommand", {
                    success: response.ok,
                    error: response,
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: ERSTELLE_AUFTRAGGEBER_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("erstelleAuftraggeberCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };
//#endregion Auftraggeber

//#region Nachunternehmer
export const fetchFirmaNachunternehmer = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchFirmaNachunternehmer");
    dispatch({ type: FIRMA_NACHUNTERNEHMER_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/firma-nachunternehmer/?firmaId=${data.firmaId}`,
        getRequestOptions
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }

    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FIRMA_NACHUNTERNEHMER_ACTION_FAIL,
                error: json,
            });
            appInsights.stopTrackEvent("fetchFirmaNachunternehmer", {
                success: json.success,
                error: json,
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FIRMA_NACHUNTERNEHMER_ACTION_FAIL,
                error: response,
            });
            appInsights.stopTrackEvent("fetchFirmaNachunternehmer", {
                success: response.success,
                error: response,
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_FIRMA_NACHUNTERNEHMER_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchFirmaNachunternehmer", {
            success: json.success,
        });
    }
};

export const erstelleNachunternehmerCommand =
    (data, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("erstelleNachunternehmerCommand");
        dispatch({ type: FIRMA_NACHUNTERNEHMER_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/erstelle-nachunternehmer/`,
            {
                method: "POST",
                body: data,
            }
        );
        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }

        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FIRMA_NACHUNTERNEHMER_ACTION_FAIL,
                    error: json,
                });
                appInsights.stopTrackEvent("erstelleNachunternehmerCommand", {
                    success: json.success,
                    error: { errorMessage: json.toString() },
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FIRMA_NACHUNTERNEHMER_ACTION_FAIL,
                    error: response,
                });
                appInsights.stopTrackEvent("erstelleNachunternehmerCommand", {
                    success: response.ok,
                    error: { errorMessage: response.toString() },
                });
            }
        }

        if (json?.success) {
            dispatch({
                type: ERSTELLE_FIRMA_NACHUNTERNEHMER_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("erstelleNachunternehmerCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const loescheNachunternehmerCommand =
    (data, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("loescheNachunternehmerCommand");
        dispatch({ type: FIRMA_NACHUNTERNEHMER_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/loesche-nachunternehmer/`,
            {
                method: "POST",
                body: data,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }

        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FIRMA_NACHUNTERNEHMER_ACTION_FAIL,
                    error: { errorMessage: json.toString() },
                });
                appInsights.stopTrackEvent("loescheNachunternehmerCommand", {
                    success: false,
                    error: { errorMessage: json.toString() },
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FIRMA_NACHUNTERNEHMER_ACTION_FAIL,
                    error: { errorMessage: response.toString() },
                });
                appInsights.stopTrackEvent("loescheNachunternehmerCommand", {
                    success: false,
                    error: { errorMessage: response.toString() },
                });
            }
        }

        if (json?.success) {
            dispatch({
                type: LOESCHE_FIRMA_NACHUNTERNEHMER_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("loescheNachunternehmerCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };
//#endregion

//#region Verwalter
export const fetchFirmaVerwalter = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchFirmaVerwalter");
    dispatch({ type: FIRMA_VERWALTER_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/firma-verwalter/?firmaId=${data.firmaId}`,
        getRequestOptions
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }

    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FIRMA_VERWALTER_ACTION_FAIL,
                error: { errorMessage: json.toString() },
            });
            appInsights.stopTrackEvent("fetchFirmaVerwalter", {
                success: false,
                error: { errorMessage: json.toString() },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FIRMA_VERWALTER_ACTION_FAIL,
                error: { errorMessage: response.toString() },
            });
            appInsights.stopTrackEvent("fetchFirmaVerwalter", {
                success: false,
                error: { errorMessage: response.toString() },
            });
        }
    }

    if (json?.success) {
        dispatch({
            type: FETCH_FIRMA_VERWALTER_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchFirmaVerwalter", {
            success: json.success,
        });
    }
};

export const erstelleFirmenverwalterCommand =
    (data, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("erstelleFirmenverwalterCommand");
        dispatch({ type: FIRMA_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/erstelle-firmenverwalter/`,
            {
                method: "POST",
                body: data,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }

        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FIRMA_ACTION_FAIL,
                    error: { errorMessage: json.toString() },
                });
                appInsights.stopTrackEvent("erstelleFirmenverwalterCommand", {
                    success: json.success,
                    error: { errorMessage: json.toString() },
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FIRMA_ACTION_FAIL,
                    error: { errorMessage: response.toString() },
                });
                appInsights.stopTrackEvent("erstelleFirmenverwalterCommand", {
                    success: false,
                    error: { errorMessage: response.toString() },
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: ERSTELLE_FIRMA_VERWALTER_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("erstelleFirmenverwalterCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const loescheFirmenverwalterCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("loescheFirmenverwalterCommand");
        dispatch({ type: FIRMA_ACTION_BEGIN });

        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/loesche-firmenverwalter/`,
            {
                method: "POST",
                body: formData,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({ type: FIRMA_ACTION_FAIL, error: json });
                appInsights.stopTrackEvent("loescheFirmenverwalterCommand", {
                    success: json.success,
                    error: { errorMessage: json.toString() },
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({ type: FIRMA_ACTION_FAIL, error: response });
                appInsights.stopTrackEvent("loescheFirmenverwalterCommand", {
                    success: false,
                    error: { errorMessage: response.toString() },
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: LOESCHE_FIRMA_VERWALTER_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("loescheFirmenverwalterCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };
//#endregion

//#region Mitarbeiter

export const fetchFirmaMitarbeiter = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchFirmaMitarbeiter");
    dispatch({ type: FIRMA_MITARBEITER_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/firma-mitarbeiter/?firmaId=${data.firmaId}`,
        getRequestOptions
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FIRMA_MITARBEITER_ACTION_FAIL,
                error: { errorMessage: json.toString() },
            });
            appInsights.stopTrackEvent("fetchFirmaMitarbeiter", {
                success: json.success,
                error: { errorMessage: json.toString() },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FIRMA_MITARBEITER_ACTION_FAIL,
                error: { errorMessage: response.toString() },
            });
            appInsights.stopTrackEvent("fetchFirmaMitarbeiter", {
                success: false,
                error: { errorMessage: response.toString() },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_FIRMA_MITARBEITER_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchFirmaMitarbeiter", {
            success: json.success,
        });
    }
};
//#endregion

//#region Unterlagen
export const fetchFirmaUnterlagen = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchFirmaUnterlagen");
    dispatch({ type: FIRMA_UNTERLAGEN_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/firma-unterlagen/?firmaId=${data.firmaId}`,
        getRequestOptions
    );

    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FIRMA_UNTERLAGEN_ACTION_FAIL,
                error: { errorMessage: json.toString() },
            });
            appInsights.stopTrackEvent("fetchFirmaUnterlagen", {
                success: false,
                error: { errorMessage: json.toString() },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FIRMA_UNTERLAGEN_ACTION_FAIL,
                error: { errorMessage: response.toString() },
            });
            appInsights.stopTrackEvent("fetchFirmaUnterlagen", {
                success: false,
                error: { errorMessage: response.toString() },
            });
        }
    }

    if (json?.success) {
        dispatch({
            type: FETCH_FIRMA_UNTERLAGEN_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchFirmaUnterlagen", {
            success: json.success,
        });
        return json.payload;
    }
};

export const fetchEufefa = (data) => async (dispatch) => {
    const response = await fetch(
        `${apiHost}/api/zuko/v3/${data.projektId}/firma-eufefa/?firmaEufefaId=${data.firmaEufefaId}`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.blob();
        return json;
    } catch (error) {
        dispatch(errorHandler(error));
    }
};

export const fetchEufefaVorlageDownload = (data) => () => {
    const a = document.createElement("a");
    a.setAttribute(
        "href",
        `${apiHost}/api/zuko/v3/${data.projektId}/eufefa-vorlage/?eufefaId=${data.eufefaId}`
    );
    a.dispatchEvent(new MouseEvent("click"));
    appInsights.trackEvent({ name: "fetchEufefaVorlageDownload" });
};

export const fetchEufefaVorlage = (data) => async (dispatch) => {
    const response = await fetch(
        `${apiHost}/api/zuko/v3/${data.projektId}/eufefa-vorlage/?eufefaId=${data.eufefaId}`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.blob();
        return json;
    } catch (error) {
        dispatch(errorHandler(error));
    }
};

export const loescheEufefaCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("loescheEufefaCommand");
        dispatch({ type: FIRMA_ACTION_BEGIN });
        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/loesche-firma-eufefa/`,
            {
                method: "POST",
                body: formData,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FIRMA_ACTION_FAIL,
                    error: { errorMessage: json.toString() },
                });
                appInsights.stopTrackEvent("loescheEufefaCommand", {
                    success: false,
                    error: { errorMessage: json.toString() },
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FIRMA_ACTION_FAIL,
                    error: { errorMessage: response.toString() },
                });
                appInsights.stopTrackEvent("loescheEufefaCommand", {
                    success: false,
                    error: { errorMessage: response.toString() },
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: LOESCHE_EUFEFA_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("loescheEufefaCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const pruefeFirmaEufefaCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("pruefeFirmaEufefaCommand");
        dispatch({ type: FIRMA_ACTION_BEGIN });

        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/pruefe-firma-eufefa/`,
            {
                method: "POST",
                body: formData,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }

        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FIRMA_ACTION_FAIL,
                    error: { errorMessage: json.toString() },
                });
                appInsights.stopTrackEvent("pruefeFirmaEufefaCommand", {
                    success: false,
                    error: { errorMessage: json.toString() },
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FIRMA_ACTION_FAIL,
                    error: { errorMessage: response.toString() },
                });
                appInsights.stopTrackEvent("pruefeFirmaEufefaCommand", {
                    success: false,
                    error: { errorMessage: response.toString() },
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: PRUEFE_FIRMA_EUFEFA_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("pruefeFirmaEufefaCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const entpruefeFirmaEufefaCommand =
    (formData, projektId) => async (dispatch) => {
        appInsights.startTrackEvent("entpruefeFirmaEufefaCommand");
        dispatch({ type: FIRMA_ACTION_BEGIN });

        const response = await fetch(
            `${apiHost}/api/zuko/v3/${projektId}/entpruefe-firma-eufefa/`,
            {
                method: "POST",
                body: formData,
            }
        );

        let json;
        try {
            json = await response.json();
        } catch (error) {
            dispatch(errorHandler(error));
        }
        if (!response.ok) {
            if (json && !json.success) {
                dispatch(errorHandler(json));
                dispatch({
                    type: FIRMA_ACTION_FAIL,
                    error: { errorMessage: json.toString() },
                });
                appInsights.stopTrackEvent("entpruefeFirmaEufefaCommand", {
                    success: false,
                    error: { errorMessage: json.toString() },
                });
            } else {
                dispatch(errorHandler(response));
                dispatch({
                    type: FIRMA_ACTION_FAIL,
                    error: { errorMessage: response.toString() },
                });
                appInsights.stopTrackEvent("entpruefeFirmaEufefaCommand", {
                    success: false,
                    error: { errorMessage: response.toString() },
                });
            }
        }
        if (json?.success) {
            dispatch({
                type: ENTPRUEFE_FIRMA_EUFEFA_COMMAND_SUCCESS,
                payload: json.payload,
            });
            appInsights.stopTrackEvent("entpruefeFirmaEufefaCommand", {
                success: json.success,
            });
            return json.payload;
        }
    };

export const erstelleEufefaCommand = (data) => async (dispatch) => {
    appInsights.startTrackEvent("erstelleEufefaCommand");
    dispatch({ type: FIRMA_ACTION_BEGIN });

    let headers = new Headers();
    headers.append("Accept", "application/json");

    let formData = new FormData();
    formData.append("file", data.file, data.file.name);
    formData.append("FirmaId", data.firmaId);
    formData.append("EufefaId", data.eufefaId);

    let request = new Request(
        `${apiHost}/api/zuko/v3/${data.projektId}/erstelle-firma-eufefa/`,
        {
            method: "POST",
            body: formData,
            headers: headers,
            credentials: "include",
        }
    );

    const response = await fetch(request);
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FIRMA_ACTION_FAIL,
                error: { errorMessage: json.toString() },
            });
            appInsights.stopTrackEvent("erstelleEufefaCommand", {
                success: false,
                error: { errorMessage: json.toString() },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FIRMA_ACTION_FAIL,
                error: { errorMessage: response.toString() },
            });
            appInsights.stopTrackEvent("erstelleEufefaCommand", {
                success: false,
                error: { errorMessage: response.toString() },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: ERSTELLE_EUFEFA_COMMAND_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("erstelleEufefaCommand", {
            success: json.success,
        });
        return json.payload;
    }
};

export const erstelleWeitereFirmaEufefaCommand = (data) => async (dispatch) => {
    appInsights.startTrackEvent("erstelleWeitereFirmaEufefaCommand");
    dispatch({ type: FIRMA_ACTION_BEGIN });

    let headers = new Headers();
    headers.append("Accept", "application/json");

    let formData = new FormData();
    formData.append("file", data.file, data.file.name);
    formData.append("FirmaId", data.firmaId);
    formData.append("ProjektId", data.projektId);
    formData.append("Name", data.firmaEufefaName);

    let request = new Request(
        `${apiHost}/api/zuko/v3/${data.projektId}/erstelle-weitere-firma-eufefa/`,
        {
            method: "POST",
            body: formData,
            headers: headers,
            credentials: "include",
        }
    );
    const response = await fetch(request);
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FIRMA_ACTION_FAIL,
                error: { errorMessage: json.toString() },
            });
            appInsights.stopTrackEvent("erstelleWeitereFirmaEufefaCommand", {
                success: false,
                error: { errorMessage: json.toString() },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FIRMA_ACTION_FAIL,
                error: { errorMessage: response.toString() },
            });
            appInsights.stopTrackEvent("erstelleWeitereFirmaEufefaCommand", {
                success: false,
                error: { errorMessage: response.toString() },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: ERSTELLE_DYNAMISCHE_FIRMA_EUFEFA_COMMAND_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("erstelleWeitereFirmaEufefaCommand", {
            success: json.success,
        });
        return json.payload;
    }
};
//#endregion

//#region Baustellenausweis

export const fetchFirmaBaustellenausweis = (data) => async (dispatch) => {
    appInsights.startTrackEvent("fetchFirmaBaustellenausweis");
    dispatch({ type: FIRMA_BAUSTELLENAUSWEIS_ACTION_BEGIN });
    const response = await fetch(
        `${apiHost}/api/zuko/v1/${data.projektId}/firma-baustellenausweise/?firmaId=${data.firmaId}`,
        getRequestOptions
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FIRMA_BAUSTELLENAUSWEIS_ACTION_FAIL,
                error: { errorMessage: json.toString() },
            });
            appInsights.stopTrackEvent("fetchFirmaBaustellenausweis", {
                success: json.success,
                error: { errorMessage: json.toString() },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FIRMA_BAUSTELLENAUSWEIS_ACTION_FAIL,
                error: { errorMessage: response.toString() },
            });
            appInsights.stopTrackEvent("fetchFirmaBaustellenausweis", {
                success: false,
                error: { errorMessage: response.toString() },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: FETCH_FIRMA_BAUSTELLENAUSWEIS_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("fetchFirmaBaustellenausweis", {
            success: json.success,
        });
    }
};

//#endregion

export const loescheFirmaCommand = (data, projektId) => async (dispatch) => {
    appInsights.startTrackEvent("loescheFirmaCommand");
    dispatch({ type: FIRMA_ACTION_BEGIN });

    const response = await fetch(
        `${apiHost}/api/zuko/v3/${projektId}/loesche-firma/`,
        {
            method: "POST",
            body: data,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        dispatch(errorHandler(error));
    }
    if (!response.ok) {
        if (json && !json.success) {
            dispatch(errorHandler(json));
            dispatch({
                type: FIRMA_ACTION_FAIL,
                error: { errorMessage: json.toString() },
            });
            appInsights.stopTrackEvent("loescheFirmaCommand", {
                success: json.success,
                error: { errorMessage: json.toString() },
            });
        } else {
            dispatch(errorHandler(response));
            dispatch({
                type: FIRMA_ACTION_FAIL,
                error: { errorMessage: response.toString() },
            });
            appInsights.stopTrackEvent("loescheFirmaCommand", {
                success: false,
                error: { errorMessage: response.toString() },
            });
        }
    }
    if (json?.success) {
        dispatch({
            type: LOESCHE_FIRMA_COMMAND_SUCCESS,
            payload: json.payload,
        });
        appInsights.stopTrackEvent("loescheFirmaCommand", {
            success: json.success,
        });
        return json.payload;
    }
};
