import {
  RESET_BILLDVERIFIZIERUNG,
  ZUTRITTSPUNKTE_ACTION_FAIL,
  ZUTRITTSPUNKTE_ACTION_BEGIN,
  FETCH_ZUTRITTSPUNKTE_SUCCESS,
  RESET_ZUTRITTSPUNKTE_SUCCESS,
  LOESCHE_ZUTRITTSPUNKT_SUCCESS,
  ERSTELLE_ZUTRITTSPUNKT_SUCCESS,
  FETCH_BILDVERIFIZIERUNG_SUCCESS,
  FETCH_ZUTRITTSPUNKTE_ADMIN_SUCCESS,
  AKTUALISIERE_ZUTRITTSPUNKT_SUCCESS,
  FETCH_MITARBEITER_BILLDVERIFIZIERUNG_PROFILBILD_BASE64_SUCCESS,
  RESET_MITARBEITER_BILLDVERIFIZIERUNG_PROFILBILD_BASE64_SUCCESS,
} from "../../actions/zuko/zutrittspunkte.js";

const zutrittspunkteState = {
  error: null,
  loading: false,
  zutrittspunkte: [],
  zutrittspunkteAdmin: {},
  bildverifizierungMitarbeiterProfilbilder: [],
  bildverifizierung: [],
};

const savedState =
  localStorage.getItem("savedState") ||
  JSON.stringify({ ...zutrittspunkteState });
const INITIAL_STATE =
  JSON.parse(savedState).zutrittspunkteState || zutrittspunkteState;

const zutrittspunkte = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ZUTRITTSPUNKTE_ACTION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ZUTRITTSPUNKTE_ACTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_ZUTRITTSPUNKTE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        zutrittspunkte: action.payload,
      };
    case AKTUALISIERE_ZUTRITTSPUNKT_SUCCESS:
    case ERSTELLE_ZUTRITTSPUNKT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        zutrittspunkte: action.payload,
      };
    case FETCH_ZUTRITTSPUNKTE_ADMIN_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        zutrittspunkteAdmin: action.payload,
      };
    case RESET_ZUTRITTSPUNKTE_SUCCESS:
      return {
        ...state,
        error: null,
        zutrittspunkte: [],
        zutrittspunkteAdmin: {},
        loading: false,
      };
    case FETCH_BILDVERIFIZIERUNG_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
        bildverifizierung: action.payload,
      };
    }
    case RESET_BILLDVERIFIZIERUNG: {
      return {
        ...state,
        error: null,
        bildverifizierung: [],
        loading: false,
      };
    }
    case FETCH_MITARBEITER_BILLDVERIFIZIERUNG_PROFILBILD_BASE64_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        bildverifizierungMitarbeiterProfilbilder: [action.payload],
      };
    case RESET_MITARBEITER_BILLDVERIFIZIERUNG_PROFILBILD_BASE64_SUCCESS:
      return {
        ...state,
        bildverifizierungMitarbeiterProfilbilder: [],
        error: null,
      };
    case LOESCHE_ZUTRITTSPUNKT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default zutrittspunkte;
