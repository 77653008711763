const apiHost = window.Algo.apiHost,
    appInsights = window.Algo.appInsights;

export const erstelleContainerunterlage = async (
    formData,
    language,
    projektId
) => {
    appInsights.startTrackEvent("erstelleContainerunterlage");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/erstelle-unterlage`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("erstelleContainerunterlage", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("erstelleContainerunterlage", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("erstelleContainerunterlage", {
            success: json.success,
        });
        return json;
    }
};

export const aktualisiereContainerunterlage = async (
    formData,
    language,
    projektId
) => {
    appInsights.startTrackEvent("aktualisiereContainerunterlage");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/aktualisiere-unterlage`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("aktualisiereContainerunterlage", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("aktualisiereContainerunterlage", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("aktualisiereContainerunterlage", {
            success: json.success,
        });
        return json;
    }
};

export const archiviereContainerunterlage = async (
    formData,
    language,
    projektId
) => {
    appInsights.startTrackEvent("archiviereContainerunterlage");
    const response = await fetch(
        `${apiHost}/api/container/v1/${language}/${projektId}/archiviere-unterlage`,
        {
            method: "POST",
            body: formData,
        }
    );
    let json;
    try {
        json = await response.json();
    } catch (error) {
        return error;
    }
    if (!response.ok) {
        if (json && !json.success) {
            appInsights.stopTrackEvent("archiviereContainerunterlage", {
                success: json.success,
                error: json,
            });
            return json;
        } else {
            appInsights.stopTrackEvent("archiviereContainerunterlage", {
                success: response.ok,
                error: response,
            });
            return response;
        }
    }
    if (json?.success) {
        appInsights.stopTrackEvent("archiviereContainerunterlage", {
            success: json.success,
        });
        return json;
    }
};

export const fetchUnterlage = async (sprache, projektId, unterlageId) => {
    const response = await fetch(
        `${apiHost}/api/container/v1/${sprache}/${projektId}/download-unterlage/?unterlageId=${unterlageId}`,
        {
            method: "GET",
        }
    );
    return response;
};
