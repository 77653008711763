import { css } from "lit";

export const StepStyles = css`
    .container {
        display: grid !important;
        grid-template-rows: 100px auto 50px;
        grid-template-columns: repeat(11, 1fr);
    }
    .steps {
        display: grid !important;
        grid-row: 1/2;
        grid-column: 1/12;
    }
    .step-item {
        grid-row: 1/2;
        z-index: 10;
    }
    .progress-empfaenger,
    .progress-transport,
    .progress-fracht,
    .progress-entladung,
    .progress-anmelden {
        height: 5px;
        margin-top: 22px !important;
        background-color: rgb(223, 217, 217);
        grid-row: 1/2;
    }
    .step-item.info {
        grid-column: 1/2 !important;
    }
    .progress-empfaenger {
        grid-column: 1/3 !important;
    }
    .step-item.empfaenger {
        grid-column: 3/4 !important;
    }
    .progress-transport {
        grid-column: 3/5 !important;
    }
    .step-item.transport {
        grid-column: 5/6 !important;
    }
    .progress-fracht {
        grid-column: 5/7 !important;
    }
    .step-item.fracht {
        grid-column: 7/8 !important;
    }
    .progress-entladung {
        grid-column: 7/9 !important;
    }
    .step-item.entladung {
        grid-column: 9/10 !important;
    }
    .progress-anmelden {
        grid-column: 9/11 !important;
    }
    .step-item.anmelden {
        grid-column: 11/12 !important;
    }
    .step-item.done > .step-button,
    .progress-empfaenger.done,
    .progress-transport.done,
    .progress-fracht.done,
    .progress-entladung.done,
    .progress-anmelden.done {
        background-color: #3ca085 !important;
    }
    .forms {
        grid-row: 2/3;
        grid-column: 2/11;
    }
    #back-button {
        grid-row: 3/4;
        grid-column: 1/2;
    }
    #next-button {
        grid-row: 3/4;
        grid-column: 11/12;
    }
    .step-button {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: none;
        background-color: rgb(223, 217, 217);
        transition: 0.4s;
        line-height: 1.4;
        text-align: center;
        padding: 10px 0;
        font-size: 12px;
    }

    @media screen and (max-width: 800px) {
        .tab-text {
            background-color:  #f8f9fc !important;
        }
    }

    @media screen and (max-width: 1100px) {
        .container {
            display: grid !important;
            grid-template-columns: 100px auto 100px;
            grid-template-rows: repeat(11, 1fr);
        }
        .steps {
            display: grid !important;
            grid-column: 1/2;
            grid-row: 1/12;
        }
        .step-item {
            grid-column: 1/2;
            z-index: 10;
        }
        .step-item > .tab-text {
            background-color: white;
        }

        .progress-empfaenger,
        .progress-transport,
        .progress-fracht,
        .progress-entladung,
        .progress-anmelden {
            width: 5px;
            background-color: rgb(223, 217, 217);
            height: auto;
            margin-left: 47px;
        }
        .step-item.info {
            grid-row: 1/2 !important;
            grid-column: 1/2 !important;
        }
        .progress-empfaenger {
            grid-row: 1/3 !important;
            grid-column: 1/2 !important;
        }
        .step-item.empfaenger {
            grid-row: 3/4 !important;
            grid-column: 1/2 !important;
        }
        .progress-transport {
            grid-row: 3/5 !important;
            grid-column: 1/2 !important;
        }
        .step-item.transport {
            grid-row: 5/6 !important;
            grid-column: 1/2 !important;
        }
        .progress-fracht {
            grid-row: 5/7 !important;
            grid-column: 1/2 !important;
        }
        .step-item.fracht {
            grid-row: 7/8 !important;
            grid-column: 1/2 !important;
        }
        .progress-entladung {
            grid-row: 7/9 !important;
            grid-column: 1/2 !important;
        }
        .step-item.entladung {
            grid-row: 9/10 !important;
            grid-column: 1/2 !important;
        }
        .progress-anmelden {
            grid-row: 9/11 !important;
            grid-column: 1/2 !important;
        }
        .step-item.anmelden {
            grid-row: 11/12 !important;
            grid-column: 1/2 !important;
        }
        .forms {
            grid-column: 2/3;
            grid-row: 2/11;
            margin-top: auto;
            margin-bottom: auto;
        }
        .step-button {
            margin-left: 25px;
        }
        .tab-text {
            text-align: center;
        }
        #back-button {
            grid-column: 3/4;
            grid-row: 1/2;
        }
        #next-button {
            grid-column: 3/4;
            grid-row: 11/12;
        }
    }
`;
