import {
  ZUKOSERVER_ACTION_FAIL,
  ZUKOSERVER_ACTION_BEGIN,
  FETCH_ZUKOSERVER_SUCCESS,
  RESET_ZUKOSERVER_SUCCESS,
  ERSTELLE_ZUKOSERVER_SUCCESS,
  FETCH_ZUKOSERVER_ENPOINT_PINGTEST_SUCCESS,
} from "../../actions/zuko/zuko-server.js";

const mehrereZukoServerState = {
  error: null,
  loading: false,
  mehrereZukoServer: [],
};

const savedState =
  localStorage.getItem("savedState") ||
  JSON.stringify({ ...mehrereZukoServerState });
const INITIAL_STATE =
  JSON.parse(savedState).mehrereZukoServerState || mehrereZukoServerState;

const mehrereZukoServer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ZUKOSERVER_ACTION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ZUKOSERVER_ACTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_ZUKOSERVER_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        mehrereZukoServer: action.payload,
      };
    case RESET_ZUKOSERVER_SUCCESS:
      return {
        ...state,
        error: null,
        mehrereZukoServer: [],
        loading: false,
      };
    case ERSTELLE_ZUKOSERVER_SUCCESS:
    case FETCH_ZUKOSERVER_ENPOINT_PINGTEST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default mehrereZukoServer;
