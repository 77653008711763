export const TRANSLATIONS_DE_AVIS = {
    ABBRECHEN: "Abbrechen",
    ABFALLSCHLUESSELNUMMER: "Abfallschlüsselnummer",
    ABFRAGE_BESTIMMUNGSORT_ARCHIVIEREN:
        "Möchten Sie den Bestimmungszone wirklich löschen?",
    ABFRAGE_MODULUNTERLAGE_ARCHIVIEREN:
        "Möchten Sie die Modulunterlage wirklich löschen?",
    ABFRAGE_RESSOURCEN_ARCHIVIEREN:
        "Möchten Sie die Ressource wirklich löschen?",
    ABFRAGE_TRANSPORT_GENEHMIGEN:
        "Möchten Sie den Transport wirklich genehmigen?",
    ABFRAGE_TRANSPORT_STONIEREN:
        "Möchten Sie den Transport wirklich stornieren?",
    ABHOL_DATEN: "Abholdaten",
    ABHOLFAHRZEUG: "Abholfahrzeug",
    ABHOLTERMIN: "Abholtermin",
    ABFRAGE_ZIELORT_ARCHIVIEREN: "Möchten Sie den Zielort wirklich löschen",
    ABLIEFERUNGSZEITEN: "Ablieferungszeiten",
    ABSENDER: "Absender",
    ABTRANSPORT: "Abtransport",
    ABTRANSPORTZONE: "Abtransportzone",
    ADRESSE: "Adresse",
    ANLIEFERUNG: "Anlieferung",
    ANLIEFERUNGSZONE: "Anlieferungszone",
    ANGEMELDET_AM: "Angemeldet am",
    ANGEMELDET_VON: "Angemeldet von",
    ANMELDEN: "Anmelden",
    ANLIEFERUNGSZEITEN: "Anlieferungszeiten",
    AKTUALISIEREN: "Aktualisieren",
    ALLE_ANGEMELDETEN_TRANSPORTE_GENEHMIGEN:
        "Alle angemeldeten Transportanmeldungen genehmigen?",
    ALLE_AVISIERUNGSARTEN_AKTIVIEREN: "Alle Avisierungsarten erlauben",
    ALLE_AVISIERUNGSARTEN_HELPER_TEXT:
        "Wählen Sie Ja, wenn alle Nutzer die das Recht „Transport anmelden“ besitzen, die Möglichkeit haben sollen, alle Avisierungsarten (Einzel, Sammel, Serie, Spezial) anmelden zu können. " +
        "Wählen Sie nein, wenn die Anmeldung von Einzelavisierung möglich sein werden. Sie können in der WhiteList, die E-Mailadressen der Projektbeteiligten auswählen, die trotz Deaktivierung, Sammel-,Serien- und Spezialavisierungen anmelden können sollen.",
    AUSGEWAEHLTE_RESSOURCE: "Ausgewählte Ressource",
    AUSGEWAEHLTE_UNTERLAGE: "Ausgewählte Unterlage",
    AVISIERUNGSART_0: "Einzel",
    AVISIERUNGSART_1: "Einzel",
    AVISIERUNGSART_2: "Sammel",
    AVISIERUNGSART_3: "Serie",
    AVISIERUNGSART: "Avisierungssart",
    AVIS_MODUL: "Avisierung-Modul",
    BAUSTELLEINRICHTUNGSPLAN: "Baustelleinrichtungsplan",
    BAUSTELLENOEFFNUNGSZEITEN: "Baustellenöffnungszeiten",
    BEARBEITET_AM: "Bearbeitet am",
    BEARBEITET_VON: "Bearbeitet von",
    BELADUNG: "Beladung",
    BELADUNGSDAUER: "Beladungsdauer (in Minuten)",
    BENUTZEREMAIL: "E-Mail",
    BENUTZERNACHNAME: "Nachname",
    BENUTZERUNTERNEHMEN: "Unternehmen",
    BENUTZERTELEFONNUMMER: "Telefonnummer",
    BENUTZERVORNAME: "Vorname",
    BEP_WIRD_GELADEN: "Baustelleneinrichtungsplan wird geladen",
    BESTIMMUNGSORT: "Bestimmungszone",
    BESTIMMUNGSORT_AENDERN: "Bestimmungszone bearbeiten",
    BESTIMMUNGSORT_AKTUALISIEREN: "Bestimmungszone aktualisieren",
    BESTIMMUNGSORT_ARCHIVIEREN: "Bestimmungszone löschen",
    BESTIMMUNGSORT_ERFOLGREICH_AKTUALISIERT:
        "Bestimmungszone erfolgreich aktualisiert",
    BESTIMMUNGSORT_ERFOLGREICH_ARCHIVIERT:
        "Bestimmungszone erfolgreich gelöscht",
    BESTIMMUNGSORT_ERFOLGREICH_ERSTELLT: "Bestimmungszone erfolgreich erstellt",
    BESTIMMUNGSORT_HINZUFUEGEN: "Bestimmungszone hinzufügen",
    BESTIMMUNGSZONE: "Bestimmungszone",
    BITTE_ABFALLSCHLUESSELNUMMER_EINGEBEN:
        "Bitte Abfallschlüsselnummer eingeben",
    BESTIMMUNGSORT_ID_NICHT_GEFUNDEN: "Bestimmungszone-id nicht gefunden",
    BITTE_ABTRANSPORT_FAHRZEUG_WAEHLEN: "Bitte Abtransportfahrzeug auswählen",
    BITTE_ABTRANSPORTZONE_WAEHLEN: "Bitte Abtransportzone auswählen",
    BITTE_ADRESSE_EINGEBEN: "Bitte Adresse eingeben",
    BITTE_ANLIEFERUNGS_FAHRZEUG_WAEHLEN: "Bitte Anlieferungsfahrzeug auswählen",
    BITTE_ANLIEFERUNGSZONE_WAEHLEN: "Bitte Anlieferungszone auswählen",
    BITTE_BELADUNGSZEIT_EINGEBEN: "Bitte Beladungsdauer (in Minuten) eingeben",
    BITTE_ENGLISCHER_UEBERSETZUNG: "Bitte englische Übersetzung",
    BITTE_EUROKLASSE_EINGEBEN: "Bitte Euroklasse des Fahrzeuges eingeben",
    BITTE_FAHRZEUGANZAHL_EINGEBEN: "Bitte Fahrzeuganzahl eingeben",
    BITTE_FRAKTION_WAEHLEN: "Bitte Fraktion eingeben",
    BITTE_FRANZOESICHE_UEBERSETZUNG: "Bitte französische Übersetzung",
    BITTE_DEUTSCHER_UEBERSETZUNG: "Bitte deutsche Übersetzung",
    BITTE_GEBURTSORT_EINGEBEN: "Bitte Geburtsort eingeben",
    BITTE_GEWICHT_EINGEBEN: "Bitte Gewicht eingeben",
    BITTE_GEWICHTEINHEIT_WAEHLEN: "Bitte Gewichteinheit auswählen",
    BITTE_NACHNAME_EINGEBEN: "Bitte Nachname eingeben",
    BITTE_ENTLADEZEIT_EINGEBEN: "Bitte Entladedauer (in Minuten) angeben",
    BITTE_NAME_EINGEBEN: "Bitte Name eingeben",
    BITTE_KENNZEICHEN_EINGEBEN: "Bitte Kennzeichen eingeben",
    BITTE_LEISTUNGSVERZEICHNISPOSITIONSNUMMER_EINGEBEN:
        "Bitte Leistungsverzeichnispositionsnummer eingeben",
    BITTE_MAIL_EINGEBEN: "Bitte Email eingeben",
    BITTE_MATERIAL_EINGEBEN: "Bitte Material eingeben",
    BITTE_MATERIALEINHEIT_WAEHLEN: "Bitte Transporteinheit auswählen",
    BITTE_MENGE_EINGEBEN: "Bitte Menge eingeben",
    BITTE_POLNISCHE_UEBERSETZUNG: "Bitte polnische Übersetzung",
    BITTE_POSITIONSNUMMER_EINGEBEN: "Bitte Positionsnummer eingeben",
    BITTE_PROJEKTBETILIGTER_AUSWAEHLEN: "Bitte Projektbeteiligter auswählen",
    BITTE_RESSOURCE_AUSWAEHLEN: "Bitte Ressource auswaehlen",
    BITTE_RUSSISCHE_UEBERSETZUNG: "Bitte russische Übersetzung",
    BITTE_SPANISCHE_UEBERSETZUNG: "Bitte spanische Übersetzung",
    BITTE_STARTORT_EINGEBEN: "Bitte Startort eingeben",
    BITTE_TAG_AUSWAEHLEN: "Bitte Tag auswählen",
    BITTE_TELEFONNUMMER_EINGEBEN: "Bitte Telefonnummer eingeben",
    BITTE_TUERKISCHER_UEBERSETZUNG: "Bitte türkische Übersetzung",
    BITTE_UNTERNEHMEN_EINGEBEN: "Bitte Unternehmen eingeben",
    BITTE_VORNAME_EINGEBEN: "Bitte Vorname eingeben",
    BITTE_VOR_UND_NACHNAME_EINGEBEN: "Bitte Vor- und Nachname eingeben",
    BITTE_WIEDERHOLUNGEN_EINGEBEN: "Bitte Wiederholungen eingeben",
    BITTE_ZEITABSCHNITT_WAEHLEN: "Bitte Zeitabschnitt auswählen",
    BITTE_ZIELORT_WAEHLEN: "Bitte Zielort auswählen",
    CO2AEQUVIALENT: "Co2Aequvialent",
    DATEN_IN_KUERZE: "Daten in Kürze verfügbar",
    DEINE_NAECHSTEN_TRANSPORTE: "Deine nächsten Transporte",
    DETAILS: "Details",
    DETAILS_ZUR_FRAKTION: "Details zur Fraktion",
    DIENSTAG: "Dienstag",
    DONNERSTAG: "Donnerstag",
    EINSTELLUNGEN: "Einstellungen",
    EINZEL: "Einzel",
    EMPFAENGER: "Empfänger",
    EMPFAENGER_DATEN: "Daten des Empfängers",
    ENDZEIT: "Endzeit",
    ENGLISCH: "Englisch",
    ENTLADE_DATEN: "Daten zur Entladung",
    EINHEIT: "Einheit",
    ENGLISCH: "Englisch",
    EINSTELLUNGEN: "Einstellungen",
    ENTLADUNG: "Entladung",
    ENTLADEDAUER: "Entladedauer (in Minuten)",
    ENTLADEZEIT: "Entladezeit",
    ENTSORGUNGSART: "Entsorgungsart",
    EMPFAENGER: "Empfänger",
    EUROKLASSE: "Euroklasse",
    EXPORT_TOAST:
        "Der Download wurde gestartet und läuft im Hintergrund während Ihrer Tätigkeiten in ALGO weiter. Bitte schließen Sie derweil ALGO nicht.",
    FAHRZEUGANZAHL: "Fahrzeuganzahl",
    FCFS_VERFAHREN: "FCFS-Verfahren",
    FCFS_HELPER_TEXT_1:
        "Möchten Sie nur ein Transport pro Zeitfenster erlauben, dann wählen Sie hier „Ja“.",
    FEHLENDE_RECHTE: "Fehlende Berechtigungen",
    FORMULAR_NICHT_VALIDE: "Formular nicht valide",
    FRACHT: "Fracht",
    FRACHT_DATEN: "Daten zur Fracht",
    FRAKTION: "Fraktion",
    FRANZOESICH: "Französisch",
    FREITAG: "Freitag",
    FUELLE_FELD_AUS: "Fülle dieses Feld aus",
    GEBURTSORT: "Geburtsort",
    GEBURTSDATUM: "Geburtsdatum",
    GENEHMIGEN: "Genehmigen",
    GESPERRT: "Gesperrt",
    GEWICHT: "Gewicht pro Transporteinheit",
    GEWICHTEINHEIT: "Gewichteinheit",
    HEUTIGE_TAGESDISPOSTIONEN: "Heutige Tagesdispositionen",
    HOCHGELADEN_AM: "Hochgeladen am",
    HOCHGELADENE_UNTERLAGE: "Hochgeladene Unterlage",
    ID_NICHT_GEFUNDEN: "Id konnte nicht gefunden werden",
    INFORMATIONEN: "Informationen",
    JA: "Ja",
    KEINE_BESTIMMUNGSORT_ZUGEWIESEN:
        "Wähle eine Ressource aus, um sie der Ressourcenlist hinzuzufügen.",
    KEINE_EMAIL_WHITELIST:
        "Bisher wurde keine E-Mail zur Whitelist hinzugefügt.",
    KEIN_KOMMENTAR: "Es wurde kein Kommentar angegeben",
    KEINE_TRANSPORTE_ANGEMELDET: "Keine Transporte angemeldet",
    KEINE_UNTERLAGEN: "Keine Unterlagen",
    KEIN_VERANTWORTLICHE: "Kein Verantwortliche vorhanden",
    KENNZEICHEN: "Kennzeichen",
    KFZ_KENNZEICHEN: "KFZ-Kennzeichen",
    KOMMENTAR: "Kommentar (unverbindlicher Hinweis)",
    KOMMENTAR_EINGABEFELD:
        "Hier können Sie einen Kommentar eingeben (unverbindlicher Hinweis)",
    KONTAKTDATEN: "Kontaktdaten des Verantwortlichen",
    LEISTUNGSVERZEICHNISPOSITIONSNUMMER: "Leistungsverzeichnispositionsnummer",
    LFD_NR: "Lfd. Nr",
    LIEFERORT: "Lieferort",
    LIEFERFAHRZEUG: "Lieferfahrzeug",
    LIEFERTERMIN: "Liefertermin",
    LIEFERTEMIN_LIEGT_AUSSER_OEFFNUNGSZEITEN:
        "Anlieferung-/Abholungstermin liegt außerhalb der An-/Ablieferungszeiten. Termin & Entladezeit muss innerhalb der An-/Ablieferungszeit liegen.",
    LIEFERZEITEN: "Lieferzeiten",
    MAIL: "Mail",
    MATERIAL: "Material",
    MATERIALEINHEIT: "Transporteinheit",
    MENGE: "Menge",
    MODULANGABEN: "Modulangaben",
    MODULANGABEN_ERFOLGREICH_AKTUALISIERT:
        "Modulangaben erfolgreich aktualisiert",
    MODULUNTERLAGE_ERFOLGREICH_AKTUALISIERT:
        "Modulunterlage erfolgreich aktualisiert",
    MODULUNTERLAGE_ERFOLGREICH_ARCHIVIERT: "Modulunterlage erfolgreich löscht",
    MODULUNTERLAGE_ERFOLGREICH_ERSTELLT: "Modulunterlage erfolgreich erstellt",
    MODULANGABEN_SPEICHERN: "Modulangaben speichern",
    MODULUNTERLAGE_AENDERN: "Modulunterlage bearbeiten",
    MODULUNTERLAGE_AKTUALISIEREN: "Modulunterlage aktualisieren",
    MODULUNTERLAGE_ANZEIGEN: "Modulunterlage anzeigen",
    MODULUNTERLAGE_ARCHIVIEREN: "Modulunterlage löschen",
    MODULUNTERLAGE_HINZUFUEGEN: "Modulunterlage hinzufügen",
    MODULUNTERLAGEN: "Modulunterlagen",
    MODULVERANTWORTLICHER_MAIL: "Mail des Verantwortlichen",
    MODULVERANTWORTLICHER_MAIL_EINGABE:
        "Bitte Mail des Verantwortlichen eingeben",
    MODULVERANTWORTLICHER_TELEFONNUMMER: "Telefonnummer des Verantwortlichen",
    MODULVERANTWORTLICHER_TELEFONNUMMER_EINGABE:
        "Bitte Telefonnummer des Verantwortlichen eingeben",
    MONAT: "Monat",
    MONTAG: "Montag",
    MITTWOCH: "Mittwoch",
    NACHNAME: "Nachname",
    NAME: "Name",
    NAME_DER_RESSOURCE: "Name der Ressource",
    NEIN: "Nein",
    PDF_DATEI_ANZEIGEN: "Document als PDF-Datei anzeigen",
    POLNISCH: "Polnisch",
    POSITIONSNUMMER: "Positionsnummer",
    PREIS: "Preis",
    RESSOURCE: "Ressource",
    RESSOURCEN: "Ressourcen",
    RESSOURCENAUSWAHL: "Ressourcenauswahl",
    RESSOURCEN_AENDERN: "Ressource bearbeiten",
    RESSOURCEN_AKTUALISIEREN: "Ressource aktualisieren",
    RESSOURCEN_ARCHIVIEREN: "Ressource löschen",
    RESSOURCEN_ERFOLGREICH_AKTUALISIERT: "Ressource erfolgreich aktualisiert",
    RESSOURCEN_ERFOLGREICH_ARCHIVIERT: "Ressource erfolgreich löscht",
    RESSOURCEN_ERFOLGREICH_ERSTELLT: "Ressource erfolgreich erstellt",
    RESSOURCEN_ID_NICHT_GEFUNDEN: "Ressource-Id konnte nicht gefunden werden",
    RESSOURCEN_HINZUFUEGEN: "Ressource hinzufügen",
    RUSSISCH: "Russisch",
    SAMMEL: "Sammel",
    SAMSTAG: "Samstag",
    SONNTAG: "Sonntag",
    SERIE: "Serie",
    SPANISCH: "Spanisch",
    SPEZIAL: "Spezial",
    STATUS: "Status",
    STARTORT: "Startort",
    STARTZEIT: "Startzeit",
    TAG: "Tag",
    STORNIEREN: "Stornieren",
    TAGABSCHLIESSEN: "Tag abschließen",
    TAGABSCHLIESSEN_SICHERHEITSABFRAGE:
        "Möchten Sie den Tag wirklich abschließen?",
    TAGABSCHLIESSEN_TITLE: "Tages-Disposition abschließen",
    TAG_0: "Sonntag",
    TAG_1: "Montag",
    TAG_2: "Dienstag",
    TAG_3: "Mittwoch",
    TAG_4: "Donnerstag",
    TAG_5: "Freitag",
    TAG_6: "Samstag",
    TAG_BEREITS_ABGESCHLOSSEN: "Tag bereits abgeschlossen",
    TAG_ERFOLGREICH_ABGESCHLOSSEN: "Tag wurde erfolgreich abgeschlossen",
    TELEFONNUMMER: "Telefonnummer",
    TRACKING_HELPER_TEXT_1:
        "Wählen Sie „Ja“, wenn Sie das Tracking eines Transportes aktivieren möchten.",
    TRANSPORT: "Transport",
    TRANSPORT_ABTRANSPORT_LINK:
        "Abholung von {{transportFirma}} für {{empfaengerFirma}} - Status {{status}}",
    TRANSPORT_ANLIEFERUNG_LINK:
        "{{transportFirma}} liefert {{material}} an {{empfaengerFirma}} - Status {{status}}",
    TRANSPORT_ANMELDEN: "Transport anmelden",
    TRANSPORT_ANMELDEN_FRISTENDE: "Fristende einer Transportanmeldung",
    TRANSPORT_ANMELDEN_FRISTENDE_EINGABE: "Bitte Fristende eingeben",
    TRANSPORT_ANMELDEN_FRISTENDE_HELPER_TEXT_1:
        "Bitte geben Sie an, bis zu welchem Zeitpunkt der Transport angemeldet werden kann, indem Sie die Anzahl der Tage angeben.",
    TRANSPORT_ANMELDEN_FRISTENDE_HELPER_TEXT_2:
        "Beispiel: Tragen Sie eine „2“ ein, ist die Transportanmeldung 2 Tage vorher gesperrt.",
    TRANSPORT_ANZEIGEN: "Transport anzeigen",
    TRANSPORT_DATEN: "Daten zum Transport",
    TRANSPORT_DISPO: "Dispositionen",
    TRANSPORT_ERFOLGREICH_ANGEMELDET: "Transport wurde erfolgreich angemeldet",
    TRANSPORT_ERFOLGREICH_GENEHMIGT: "Transport wurde erfolgreich genehmigt",
    TRANSPORT_ERFOLGREICH_STORNIERT: "Transport wurde erfolgreich storniert",
    TRANSPORT_GENEHMEN: "Genehmige Transport",
    TRANSPORT_UEBERSICHT: "Transport-Übersicht",
    TRANSPORT_TRACKING: "Transport-Tracking",
    TRANSPORTEMAIL: "E-Mail",
    TRANSPORTUNTERNEHMEN: "Unternehmen",
    TRANSPORTTELEFONNUMMER: "Telefonnummer",
    TRANSPORT_STORNIEREN: "Transport stornieren",
    TRANSPORTSTARTORT: "Startort",
    TRANSPORTZIELORT: "Zielort",
    TRANSPORTE: "Transporte",
    TRANSPORTEUR: "Transporteur",
    TRANSPORTTYP: "Transporttyp",
    TUERKISCH: "Türkisch",
    TYP: "Typ",
    UNTERNEHMEN: "Unternehmen",
    UNTERLAGE: "Unterlage",
    UNTERLAGEN: "Unterlagen",
    UNTERLAGEN_ID_NICHT_GEFUNDEN: "Unterlagen-Id konnte nicht gefunden werden",
    VORNAME: "Vorname",
    VORNACHNAME: "Vor- und Nachname",
    VOR_UND_NACHNAME: "Vor- und Nachname",
    VORSCHAU: "Vorschau",
    WEITER: "Weiter",
    WHITELIST: "Whitelist",
    WIEDERHOLUNGEN: "Wiederholungen",
    WOCHE: "Woche",
    ZEITABSCHNITT: "Zeitabschnitt",
    ZIELORT: "Zielort",
    ZIELORT_AKTUALISIEREN: "Zielort aktualisieren",
    ZIELORT_AENDERN: "Zielort ändern",
    ZIELORT_ARCHIVIEREN: "Zielort löschen",
    ZIELORT_ERFOLGREICH_AKTUALISIERT: "Zielort erfolgreich aktualisiert",
    ZIELORT_ERFOLGREICH_ARCHIVIERT: "Zielort erfolgreich löscht",
    ZIELORT_ERFOLGREICH_ERSTELLT: "Zielort erfolgreich erstellt",
    ZIELORT_HINZUFUEGEN: "Zielort hinzufügen",
    ZIELORT_ID_NICHT_GEFUNDEN: "Zielort-Id nicht gefunden",
    ZU_DEN_TRANSPORTEN: "Zu der Transport-Übersicht",
    ZURUECK: "Zurück",
    ZUSAMMENFASSUNG_TRANSPORT_ANMELDUNG:
        "Zusammenfassung Ihrer eingegeben Daten zur Transportanmeldung",
};
